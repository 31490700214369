import connection from './connections/main';

const getAll = async () => {
  const { err, data } = await connection.get(`/cancellationReasons`);

  if (err) {
    throw err;
  }

  return data;
};

const get = async (name) => {
  const { err, data } = await connection.get(`/cancellationReasons/${name}`);

  if (err) {
    throw err;
  }

  return data;
};

const create = async (payload) => {
  const { err, data } = await connection.post(`/cancellationReasons`, {
    cancellation_reasons: payload,
  });

  if (err) {
    throw err;
  }

  return data;
};

const update = async (cancellationReasonsId, payload) => {
  const { err, data } = await connection.patch(
    `/cancellationReasons/${cancellationReasonsId}`,
    {
      cancellation_reasons: payload,
    }
  );

  if (err) {
    throw err;
  }

  return data;
};

const updateLocation = async (locationId, payload) => {
  const { err, data } = await connection.patch(
    `/cancellationReasons/locations/${locationId}`,
    {
      cancellation_reasons: payload,
    }
  );

  if (err) {
    throw err;
  }

  return data;
};

const CancellationReasonsServices = {
  getAll,
  get,
  create,
  update,
  updateLocation,
};

export default CancellationReasonsServices;

import * as Yup from 'yup';
import { formErrors } from '../../../helpers/form/errors';
import { formValidations } from '../../../helpers/form/validations';

export const ProspectFormSchema = Yup.object().shape({
  name: Yup.string()
    .max(40, formErrors.maxLength(40))
    .required(formErrors.required),
  // staff_id: Yup.number().required(formErrors.required),
  // document: Yup.string()
  //   .test('cpfCnpjTest', formErrors.invalidCpfOrCnpj, (v) => {
  //     const chars = v && v.match(/\d/g);
  //     if (!chars) {
  //       return false;
  //     }

  //     return chars.length === 11 || chars.length === 14;
  //   })
  //   .required(formErrors.required),
  // email: Yup.string()
  //   .max(256, formValidations.maxLength)
  //   .email(formValidations.invalidEmail),
  // phoneNumber: formValidations.phoneNumber(),
  // city: Yup.string()
  //   .max(256, formValidations.maxLength)
  //   .matches(/[a-zA-Z]/g, formValidations.textField)
  //   .strict(),
  // estimatedElectricBill: Yup.string()
  //   .max(256, formValidations.maxLength)
  //   .strict(),
  // name: Yup.string().max(40, formErrors.maxLength(40)),
  // promocode: Yup.string().max(256, formValidations.maxLength).strict(),
  // privacyAgreement: Yup.boolean()
  //   .oneOf([true], formValidations.privacyAgreement)
  //   .required(formValidations.required),
});

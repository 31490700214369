import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

import {
  Container,
  IconWrapper,
  ItemLink,
  MenuContainer,
  MenuItemContainer,
  Name,
  ToggleButton,
} from './styles';
import { ThemeContext } from '../../contexts/themeContext';

export const Dropdown = ({
  title = '',
  options,
  onChange,
  selected = false,
  disabled = false,
  themeValue,
  styles,
}) => {
  const [dropdownState, setDropdownState] = useState('closed');
  const [optionSelected, setOptionSelected] = useState('');
  const dropdownRef = useRef(null);
  const titleRef = useRef(null);

  const handleOpenDropdown = () => {
    setDropdownState('open');
  };

  const handleCloseDropdown = () => {
    setDropdownState('closed');
  };

  const resumeAddress = (addressFull) => {
    if (!addressFull?.length) return;

    const addressMaxLength = 20;
    const addressCurrentLength = addressFull.length;

    if (addressCurrentLength <= addressMaxLength) {
      return addressFull;
    }

    const [currentAddress, number] = addressFull.split(',');
    const currentAddressWithoutNumber = currentAddress.slice(
      0,
      addressMaxLength - addressCurrentLength
    );
    const currentAddressResumed = currentAddressWithoutNumber.concat(
      ',',
      number
    );

    return number ? currentAddressResumed : currentAddressWithoutNumber;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
        return;
      }
      setDropdownState('closed');
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  useEffect(() => {
    if (!options?.length) return;

    const option = options.find(({ label }) => label === optionSelected);

    if (!option) return;

    setOptionSelected(resumeAddress(option?.label));
  }, [options, optionSelected]);

  useEffect(() => {
    if (selected && options?.length && !optionSelected) {
      onChange(options[0]);
      setOptionSelected(options[0].label);
    }
  }, [selected, options, onChange]);

  useEffect(() => {
    if (optionSelected?.length && title?.length) {
      titleRef.current.style.position = 'absolute';
      titleRef.current.style.top = '0';
      titleRef.current.style.fontSize = styles?.label?.fontSize || '14px';
      titleRef.current.style.fontWeight = styles?.label?.fontWeight || '400';
      titleRef.current.style.color =
        themeValue?.page_user_text_color || '#605D62';
      titleRef.current.style.transition = '0.2s ease';
    }
  }, [optionSelected, title?.length, styles]);

  useEffect(() => {
    setOptionSelected(resumeAddress(options[0]?.label));
  }, [options]);

  const toggleMenu = () => {
    if (dropdownState === 'closed') return handleOpenDropdown();

    handleCloseDropdown();
  };

  const handleSelect = (option) => {
    setOptionSelected(option.label);
    onChange(option);
    setDropdownState('closed');
  };

  return (
    <Container
      ref={dropdownRef}
      disabled={disabled}
      themeValue={themeValue}
    >
      {!!title.length && (
        <Name
          disabled={disabled}
          ref={titleRef}
          className='label'
          themeValue={themeValue}
        >
          {title}
        </Name>
      )}

      <ToggleButton
        onClick={toggleMenu}
        noTitle={!title}
        style={{ paddingTop: styles?.toggleButton?.paddingTop }}
      >
        <Name style={{ fontSize: styles?.value?.fontSize }}>
          {optionSelected}
        </Name>

        <IconWrapper>
          {dropdownState === 'closed' ? (
            <MdArrowDropDown size='24px' />
          ) : (
            <MdArrowDropUp size='24px' />
          )}
        </IconWrapper>
      </ToggleButton>

      {dropdownState === 'open' && (
        <MenuContainer>
          {options.map((option) => (
            <MenuItemContainer
              onClick={() => handleSelect(option)}
              key={option.key}
            >
              <ItemLink>{option.label}</ItemLink>
            </MenuItemContainer>
          ))}
        </MenuContainer>
      )}
    </Container>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.any,
  disabled: PropTypes.bool,
};

/* eslint-disable default-case */
import React, { useContext, useEffect, useState } from 'react';

import { MdOutlineHomeWork } from 'react-icons/md';

import { LuClipboardSignature, LuCalendarClock, LuSiren } from 'react-icons/lu';

import { useNavigate } from 'react-router-dom';

import { NewPageView } from '../../../containers/NewPageView';
import { PrimaryButtonRed } from '../../../components/PrimaryButtonRed';

import {
  Card,
  CardContent,
  CardsContainer,
  CardTitle,
  Container,
  Content,
  LineWithButton,
  LineWithButtonSendInvoice,
  OpenModalButton,
  OverdueContainer,
  Separator,
  SignOrToReplaceButtonContainer,
  Title,
  TitleWithDropdownContainer,
  TitleWithDropdownContent,
} from './styles';

import { WhereToFindYourAccountModal } from '../../../components/Modals/WhereToFindYourAccountModal';

import { LoadingCustomModal } from '../../../components/Modals/LoadingCustomModal';
import { FiledOCRModal } from '../../../components/Modals/FiledOCRModal';
import { UserContext } from '../../../contexts/userContext';
import { UploadCustomPendencies } from '../../../components/UploadCustomPendencies';
import { getRegisterButton } from '../../../helpers/pendencies';
import { formatDate } from '../../../helpers/date';
import { ThemeContext } from '../../../contexts/themeContext';

import PendenciesImg from '../../../assets/images/icons/monochromatic-png/pendencias 1.png';
import OverdueBillsImg from '../../../assets/images/icons/monochromatic-png/recomendacoes 2.png';
import InvoiceImg from '../../../assets/images/icons/monochromatic-png/recomendacoes 2.png';

export const Pendencies = () => {
  const { themeValue } = useContext(ThemeContext);
  const { preparedPendencies, setPreparedPendencies } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [openWhereToFindYourAccountModal, setOpenWhereToFindYourAccountModal] =
    useState(false);
  const [openFiledOCRModal, setOpenFiledOCRModal] = useState(false);
  const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'application/pdf'];

  useEffect(() => {
    if (!preparedPendencies) return;

    setLoading(false);
  }, [preparedPendencies]);

  const { register = [], attachment = [], invoice = [] } = preparedPendencies;

  const handleOpenModal = () => {
    setOpenWhereToFindYourAccountModal(true);
  };

  const handleCloseModal = () => {
    setOpenWhereToFindYourAccountModal(false);
  };

  const handleCloseFiledOCRModal = () => {
    setOpenFiledOCRModal(false);
  };

  const removePropertyFromPendencies = (location_id) => {
    const indexInPendencies = attachment.findIndex(
      (item) => item.location_id === location_id
    );

    attachment.splice(indexInPendencies, 1);

    setPreparedPendencies((prevState) => ({ ...prevState, attachment }));
  };

  return (
    <NewPageView>
      <LoadingCustomModal isLoading={loading} />
      <WhereToFindYourAccountModal
        isOpen={openWhereToFindYourAccountModal}
        onRequestClose={handleCloseModal}
      />
      <FiledOCRModal
        isOpen={openFiledOCRModal}
        onRequestClose={handleCloseFiledOCRModal}
        accept={['application/pdf']}
      />
      <Container themeValue={themeValue}>
        <Content>
          <TitleWithDropdownContainer>
            <TitleWithDropdownContent>
              <img
                src={PendenciesImg}
                style={{ width: '3.5rem', height: '3.5rem' }}
              />
              <Title>
                <h1>
                  <strong>Pendências</strong>
                </h1>
              </Title>
            </TitleWithDropdownContent>
          </TitleWithDropdownContainer>

          <Separator />

          <CardsContainer>
            {!!invoice.length && (
              <div>
                <Card>
                  <CardTitle>
                    <img
                      src={OverdueBillsImg}
                      style={{ width: '2rem', height: '2rem' }}
                    />
                    <h2>Faturas vencidas</h2>
                  </CardTitle>

                  {invoice.map(({ id, invoices, nickname }) => (
                    <CardContent key={id}>
                      <span>
                        <strong>Imóvel: </strong>
                        {nickname}
                      </span>

                      {invoices.map(({ key, due_date, secure_url }) => (
                        <LineWithButton key={key}>
                          <OverdueContainer>
                            <strong>Vencimento: </strong>
                            <p>{formatDate(due_date)}</p>
                          </OverdueContainer>
                          <a
                            href={secure_url}
                            target='_blank'
                            rel='noreferrer'
                          >
                            <PrimaryButtonRed>Pagar fatura</PrimaryButtonRed>
                          </a>
                        </LineWithButton>
                      ))}
                    </CardContent>
                  ))}
                </Card>
              </div>
            )}

            <div
              style={{
                display: 'flex',
                gap: '1.5rem',
                flexDirection: 'column',
              }}
            >
              {!!attachment.length && (
                <Card>
                  <CardTitle>
                    <img
                      src={InvoiceImg}
                      style={{ width: '2rem', height: '2rem' }}
                    />
                    <h2>Envio de conta de luz</h2>
                  </CardTitle>

                  <CardContent>
                    <div>
                      <p>
                        É necessário que nos envie sua conta de luz no{' '}
                        <strong>formato PDF </strong>
                        para a confirmação dos dados.
                      </p>

                      <p>
                        Não sabe onde encontrar?{' '}
                        <OpenModalButton onClick={handleOpenModal}>
                          Clique aqui
                        </OpenModalButton>
                        .
                      </p>
                    </div>

                    <div>
                      {attachment.map(({ nickname, location_id }) => {
                        return (
                          <CardContent key={location_id}>
                            <LineWithButtonSendInvoice>
                              <div>
                                <strong>Imóvel: </strong>
                                <p>{nickname}</p>
                              </div>

                              <UploadCustomPendencies
                                location_id={location_id}
                                removeItem={removePropertyFromPendencies}
                                accept='application/pdf'
                                className='primary'
                              />
                            </LineWithButtonSendInvoice>
                          </CardContent>
                        );
                      })}
                    </div>
                  </CardContent>
                </Card>
              )}

              {!!register.length && (
                <Card>
                  <CardTitle>
                    <LuClipboardSignature size='2rem' />
                    <h2>Cadastro</h2>
                  </CardTitle>

                  <CardContent>
                    <div>
                      <p>Termine a contratação do seu imóvel!</p>
                    </div>

                    <div>
                      {register.map(({ id, nickname, status }) => (
                        <CardContent key={id}>
                          <LineWithButtonSendInvoice
                            style={{
                              display:
                                status === 'pending_signature'
                                  ? 'block'
                                  : 'flex',
                            }}
                          >
                            <span>
                              <strong>Imóvel:</strong> {nickname}
                            </span>

                            <SignOrToReplaceButtonContainer
                              style={{
                                marginTop:
                                  status === 'pending_signature' ? '1rem' : '0',
                              }}
                            >
                              {getRegisterButton({ id, status }, navigate)}
                            </SignOrToReplaceButtonContainer>
                          </LineWithButtonSendInvoice>
                        </CardContent>
                      ))}
                    </div>
                  </CardContent>
                </Card>
              )}
            </div>
          </CardsContainer>
        </Content>
      </Container>
    </NewPageView>
  );
};

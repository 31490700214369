import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';

import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';

import { BarChart, Bar, XAxis, LabelList, Legend } from 'recharts';
import { formatCurrency } from '../../pages/Customer/NewHome/helpers';
import { ArrowButton, LegendGraphic, LegendsContainer } from './styles';
import theme from '../../theme';

const renderLegend = (props) => {
  const { payload } = props;

  const labels = {
    invoiceWithoutCompany: 'Conta sem a Flora',
    invoiceWithCompany: 'Conta com a Flora',
    economy: 'Economia',
    total: 'Total',
    energyCompany: 'Energia Flora',
    energyDistributor: 'Energia Distribuidora',
    fake: 'Total',
  };

  return (
    <LegendsContainer>
      {payload.map((item) => {
        return (
          <LegendGraphic key={item.dataKey}>
            <div
              style={{
                background: `${item.color === '#FFF' ? '#000' : item.color}`,
                width: '1rem',
                height: '1rem',
                borderRadius: '0.25rem',
              }}
            />
            <span
              style={{
                color: '#1F1B1E',
                fontSize: '1rem',
                fontWeight: 300,
              }}
            >
              {labels[item.value]}
            </span>
          </LegendGraphic>
        );
      })}
    </LegendsContainer>
  );
};

const renderLabel = (value) => {
  try {
    const valueFormatted = formatCurrency(value, true, true);
    return ' ' + valueFormatted;
  } catch (err) {
    return value;
  }
};

export const Graphic = ({ type, data }) => {
  const elementRef = useRef(null);
  const [invoicesPosition, setInvoicesPosition] = useState(null);
  const [disabledButtonsObject, setDisabledButtonsObject] = useState({
    back: true,
    forward: true,
  });
  const clientWidth = elementRef?.current?.clientWidth;
  const graphWidth = useMemo(() => clientWidth || 120 - 50, [clientWidth]);

  const setDisabledButton = useCallback((data, index) => {
    if (data.length === 1) return;
    const isFirst = index === 0;
    const isLast = data.length - 1 === index;
    if (isFirst)
      return setDisabledButtonsObject({ back: true, forward: false });
    if (isLast) return setDisabledButtonsObject({ back: false, forward: true });
    setDisabledButtonsObject({ back: false, forward: false });
  }, []);

  const setTargetData = useCallback((data, index = 0) => {
    try {
      if (!data || !data?.length) return { invoices: [], labels: [] };
      const { invoices, isTopLabel } = data[index];
      const labels = invoices.map((invoice) => invoice.label);
      return { invoices, labels, isTopLabel };
    } catch (error) {
      console.log('file: index.js || line 92 || error', error);
    }
  }, []);
  const [targetInvoicesObject, setTargetInvoicesObject] = useState(
    setTargetData(data)
  );

  const changeInvoicesIndex = useCallback(
    (mode) => {
      try {
        switch (mode) {
          case 'back':
            if (invoicesPosition === 0) return;
            setInvoicesPosition(invoicesPosition - 1);
            break;
          case 'forward':
            if (invoicesPosition === data.length - 1) return;
            setInvoicesPosition(invoicesPosition + 1);
            break;
          default:
            throw new Error('unauthorized mode');
        }
      } catch (error) {
        console.log('file: index.js || line 82 || error', error);
      }
    },
    [invoicesPosition, data]
  );

  useEffect(() => {
    if (invoicesPosition === null) return;
    if (!data || !data.length) return;
    const targetData = setTargetData(data, invoicesPosition);

    if (!targetData) return;
    setTargetInvoicesObject(targetData);
    setDisabledButton(data, invoicesPosition);
  }, [invoicesPosition]);

  useEffect(() => {
    if (invoicesPosition !== null) return;
    if (!data || !data.length) return;
    setInvoicesPosition(data.length - 1);
  }, [invoicesPosition]);

  useEffect(() => {
    if (!data || !data.length) return;
    setDisabledButtonsObject({ back: true, forward: true });
    invoicesPosition !== null
      ? setInvoicesPosition(null)
      : setInvoicesPosition(data.length - 1);
  }, [data]);

  const { invoices, labels, isTopLabel } = targetInvoicesObject;
  const { back, forward } = disabledButtonsObject;

  return (
    <div
      ref={elementRef}
      style={{
        position: 'relative',
      }}
    >
      {!!type.length && (
        <>
          <ArrowButton
            disabled={back}
            onClick={() => changeInvoicesIndex('back')}
            style={{ left: 0, marginLeft: '-0.375rem' }}
          >
            <LuChevronLeft
              size='1.5rem'
              color={back ? '#E6E1E5' : '#625D60'}
            />
          </ArrowButton>
          <ArrowButton
            disabled={forward}
            onClick={() => changeInvoicesIndex('forward')}
            style={{ right: 0, marginRight: '-0.375rem' }}
          >
            <LuChevronRight
              size='1.5rem'
              color={forward ? '#E6E1E5' : '#625D60'}
            />
          </ArrowButton>

          <BarChart
            barCategoryGap={8}
            width={graphWidth}
            height={230}
            data={invoices}
            margin={{
              top: 20,
              right: 16,
              left: 16,
              bottom: 0,
            }}
          >
            <XAxis
              type='category'
              allowDuplicatedCategory={false}
              dataKey='origin'
              domain={labels}
              allowDataOverflow
              tickLine={false}
              stroke={theme.palette.grey.light}
              style={{
                fill: theme.palette.purple.main,
                fontSize: '.8rem',
                fontWeight: 300,
              }}
            />

            <Legend content={renderLegend} />

            {type === 'real' && (
              <>
                <Bar
                  dataKey='invoiceWithoutCompany'
                  fill={theme.palette.gray.main}
                >
                  <LabelList
                    formatter={renderLabel}
                    dataKey='invoiceWithoutCompany'
                    style={{
                      fontWeight: 700,
                      fontSize: '0.875rem',
                      fill: '#FFFBFE',
                    }}
                    position='center'
                  />
                </Bar>

                <Bar
                  dataKey='invoiceWithCompany'
                  stackId='invoiceAndEconomy'
                  fill={theme.palette.primary.main}
                  minPointSize={18}
                >
                  <LabelList
                    formatter={renderLabel}
                    dataKey='invoiceWithCompany'
                    style={{
                      fontWeight: 700,
                      fontSize: '0.875rem',
                      fill: '#FFFBFE',
                    }}
                    position='center'
                  />
                </Bar>

                <Bar
                  dataKey='economy'
                  stackId='invoiceAndEconomy'
                  fill={theme.palette.secondary.main}
                >
                  <LabelList
                    formatter={renderLabel}
                    dataKey='economy'
                    style={{
                      fontWeight: 700,
                      fontSize: '0.875rem',
                      fill: '#00A300',
                    }}
                    position={isTopLabel ? 'top' : 'center'}
                  />
                </Bar>
              </>
            )}

            {type === 'kwh' && (
              <>
                <Bar
                  dataKey='energyCompany'
                  stackId='invoiceAndEconomy'
                  fill={theme.palette.primary.main}
                  minPointSize={10}
                  barSize={65}
                >
                  <LabelList
                    formatter={renderLabel}
                    style={{
                      fontWeight: 700,
                      fontSize: '0.875rem',
                      fill: '#fff',
                    }}
                    dataKey='energyCompany'
                    position='center'
                  />
                </Bar>

                <Bar
                  dataKey='energyDistributor'
                  stackId='invoiceAndEconomy'
                  fill={theme.palette.gray.main}
                  minPointSize={18}
                >
                  <LabelList
                    formatter={renderLabel}
                    dataKey='total'
                    style={{
                      fontWeight: 700,
                      fontSize: '0.875rem',
                      fill: '#000000',
                    }}
                    position='top'
                  />
                  <LabelList
                    formatter={renderLabel}
                    style={{
                      fontWeight: 700,
                      fontSize: '0.875rem',
                      fill: '#fff',
                    }}
                    dataKey='energyDistributor'
                  />
                </Bar>

                <Bar
                  dataKey='fake'
                  stackId='invoiceAndEconomy'
                  fill='#FFF'
                />
              </>
            )}
          </BarChart>
        </>
      )}
    </div>
  );
};

import React, { useCallback } from 'react';

import FinancialImpactImg from '../../assets/images/icons/negative-png/impacto financeiro.png';
import EconomyImg from '../../assets/images/icons/negative-png/economia.png';
import Co2EmissionImg from '../../assets/images/icons/negative-png/impacto em co2 2.png';
import PlantedTreesImg from '../../assets/images/icons/negative-png/arvore.png';
import ParticipantsImg from '../../assets/images/icons/negative-png/pessoas-comunidade.png';

import PropTypes from 'prop-types';
import { formatCurrency } from '../../pages/Customer/NewHome/helpers';
import {
  CardWithEconomyAndSustainabilityContainer,
  EconomyAndSustainabilityContent,
  IconContainer,
  LabelContainer,
} from '../../pages/Customer/NewHome/styles';

export const Impact = ({
  addressSelected,
  impactSelected,
  invoiceSelected,
}) => {
  const { value } = impactSelected;

  const renderDashBoard = useCallback(
    (impactSelectedValue, addressSelectedData, invoiceSelectedData) => {
      try {
        switch (impactSelectedValue) {
          case 'personal': {
            const { impact } = addressSelectedData;
            const { economy_cents } = invoiceSelectedData;

            const { cfa, pt, totalSavings } = impact;
            return (
              <>
                <EconomyAndSustainabilityContent>
                  <div>
                    <IconContainer>
                      <img
                        src={FinancialImpactImg}
                        style={{ width: '1.8rem', height: '1.8rem' }}
                      />
                    </IconContainer>

                    <LabelContainer>
                      <b>{formatCurrency(economy_cents / 100, true)}</b> de{' '}
                      <strong>economia no mês</strong>
                    </LabelContainer>
                  </div>

                  <div>
                    <IconContainer>
                      <img
                        src={EconomyImg}
                        style={{ width: '1.8rem', height: '1.8rem' }}
                      />
                    </IconContainer>

                    <LabelContainer>
                      <b>{formatCurrency(totalSavings, true)}</b> de{' '}
                      <strong>economia acumulada</strong>
                    </LabelContainer>
                  </div>
                </EconomyAndSustainabilityContent>

                <EconomyAndSustainabilityContent>
                  <div>
                    <IconContainer>
                      <img
                        src={Co2EmissionImg}
                        style={{ width: '1.8rem', height: '1.8rem' }}
                      />
                    </IconContainer>

                    <LabelContainer>
                      <strong>Menos </strong>
                      <b>
                        {cfa
                          ? parseFloat(cfa)?.toFixed(1).replace('.', ',')
                          : 0}
                      </b>{' '}
                      de emissão de CO<sub>2</sub>
                    </LabelContainer>
                  </div>

                  <div>
                    <IconContainer>
                      <img
                        src={PlantedTreesImg}
                        style={{ width: '1.8rem', height: '1.8rem' }}
                      />
                    </IconContainer>

                    <LabelContainer>
                      Equivalente a <b>{pt ? parseFloat(pt)?.toFixed(0) : 0}</b>{' '}
                      <strong>árvores plantadas</strong>
                    </LabelContainer>
                  </div>
                </EconomyAndSustainabilityContent>
              </>
            );
          }
          case 'business': {
            const { promocode } = addressSelectedData;
            const { participants, total_cfa, total_pt, total_savings } =
              promocode;

            return (
              <>
                <EconomyAndSustainabilityContent>
                  <div>
                    <IconContainer>
                      <img
                        src={ParticipantsImg}
                        style={{ width: '1.8rem', height: '1.8rem' }}
                      />
                    </IconContainer>

                    <LabelContainer>
                      <b>{participants}</b> <strong>participantes</strong>
                    </LabelContainer>
                  </div>

                  <div>
                    <IconContainer>
                      <img
                        src={EconomyImg}
                        style={{ width: '1.8rem', height: '1.8rem' }}
                      />
                    </IconContainer>

                    <LabelContainer>
                      <b>{formatCurrency(total_savings, true)}</b> de{' '}
                      <strong>economia gerada</strong>
                    </LabelContainer>
                  </div>
                </EconomyAndSustainabilityContent>

                <EconomyAndSustainabilityContent>
                  <div>
                    <IconContainer>
                      <img
                        src={Co2EmissionImg}
                        style={{ width: '1.8rem', height: '1.8rem' }}
                      />
                    </IconContainer>

                    <LabelContainer>
                      <strong>Menos </strong>
                      <b>
                        {total_cfa
                          ? `${parseFloat(total_cfa)
                              .toFixed(1)
                              .replace('.', ',')} ton`
                          : 0}
                      </b>{' '}
                      de emissão de CO<sub>2</sub>
                    </LabelContainer>
                  </div>

                  <div>
                    <IconContainer>
                      <img
                        src={PlantedTreesImg}
                        style={{ width: '1.8rem', height: '1.8rem' }}
                      />
                    </IconContainer>

                    <LabelContainer>
                      Equivalente a <b>{total_pt ? Math.ceil(+total_pt) : 0}</b>{' '}
                      <strong>árvores plantadas</strong>
                    </LabelContainer>
                  </div>
                </EconomyAndSustainabilityContent>
              </>
            );
          }
          default:
            return;
        }
      } catch (error) {
        console.log('file: index.js || line 22 || error', error);
        throw error;
      }
    },
    []
  );
  return (
    <>
      {!!(impactSelected && addressSelected && value) && (
        <>
          <CardWithEconomyAndSustainabilityContainer>
            {renderDashBoard(value, addressSelected, invoiceSelected)}
          </CardWithEconomyAndSustainabilityContainer>
        </>
      )}
    </>
  );
};

Impact.propTypes = {
  addressSelected: PropTypes.object.isRequired,
  impactSelected: PropTypes.object.isRequired,
  invoiceSelected: PropTypes.object.isRequired,
};

/* eslint-disable react/prop-types */
import { CircularProgress } from '@mui/material';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../contexts/themeContext';
import theme from '../../theme';

function GradientButton({
  text,
  children,
  handleClick = '',
  paddingg,
  background,
  border,
  color,
  marginLeft = '0',
  disabled,
  marginRight = '0',
  borderRadius = '50px',
  cursor,
  isSubmitting,
}) {
  const { themeValue } = useContext(ThemeContext);
  return (
    <ButtonContainer
      onClick={handleClick}
      value={themeValue}
      disabled={disabled}
    >
      <button
        type='button'
        className='read_more_btn'
        disabled={disabled}
        style={{
          padding: `${paddingg}`,
          background,
          border,
          color,
          marginLeft,
          marginRight,
          borderRadius,
          cursor,
        }}
      >
        {text?.toUpperCase()}
        {isSubmitting && (
          <CircularProgress
            disableShrink
            style={{ height: 20, width: 20, color: 'white', marginLeft: 10 }}
          />
        )}
        {children}
      </button>
    </ButtonContainer>
  );
}

export default GradientButton;

const ButtonContainer = styled.div`
  margin: 0;
  > button {
    border: 0;
    border-radius: 50px;
    background: ${(props) =>
      props.disabled
        ? theme.palette.gray.light
        : props.background ||
          'linear-gradient(140deg,' +
            theme.palette.secondary.main +
            '  0%, ' +
            theme.palette.secondary.main +
            ' 100%)'};
    color: ${(props) =>
      props.disabled ? theme.palette.gray.main : theme.palette.primary.main};
    font-weight: 600;
    font-size: 0.9rem;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    text-decoration: none;
    text-align: center;
  }
`;

/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { unMask as UNMASK } from 'remask';

import { onlyString } from '../../../../helpers/form/formatter';
import { maskCpfOrCnpj } from '../../../../helpers/form/masks';
import { unMask } from '../../../../helpers/form/unMask';
import {
  validationCpfOrCnpj,
  validationEmail,
  validationName,
  validationPhone,
} from '../../../../helpers/form/validations';
import { useStage } from '../../../../hooks/useStage';

import { Checkbox } from '../../../Checkbox';
import { InputCustom } from '../../../InputCustom';
import { PrimaryButton } from '../../../PrimaryButton';
import { Radiobox } from '../../../Radiobox';
import { LoadingCustomModal } from '../../../Modals/LoadingCustomModal';

import {
  Container,
  Content,
  Title,
  RadioboxContainer,
  InfoPeopleOrCompany,
  ButtonContainer,
} from './styles';
import {
  curlyHandleRadioBox,
  curlyHandleReceiveToken,
  signerOptionsRadioBox,
  typeClient,
} from '../../../../pages/Public/NewRegister/helpers';
import { validateCpfOrCnpj } from '../../../../utils/validateCpfOrCnpj';
import { SecondaryButton } from '../../../SecondaryButton';
import compareDocuments from '../../../../helpers/compareDocuments';
import {
  getLabelForDocument,
  getLabelForHolderOptions,
} from '../helpers/labels';
import {
  getSignerValue,
  unpreparedIsSignerFieldDisabled,
} from '../helpers/onChange';

export const StageSign = ({ isNewPropertyOrHolder }) => {
  const { stagePayload, changePayload, sendPayloadGlobal } = useStage();
  const { name, holder, options, document, document_ocr, signer } =
    stagePayload;
  const { isTheHolder, disableIsTheHolderChange } = options || {};
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleBackStage = () => {
    changePayload({ stage: 1 });
  };

  const unmaskedDocument = document?.unmaskedValue || unMask(document?.value);

  const unmaskedDocumentLenght = unmaskedDocument?.length;

  const documentLabel = getLabelForDocument(unmaskedDocumentLenght);

  const { label: holderLabel, prefix: holderPrefix } = getLabelForHolderOptions(
    unmaskedDocumentLenght
  );

  const isSignerFieldDisabled = (event) =>
    unpreparedIsSignerFieldDisabled(event, stagePayload);

  const handleHolderDocument = (event) => {
    let status = 'accepted';
    let touched = false;
    let isDocumentHolder = true;

    const unmaskedValue = unMask(event.target.value);

    const isTheHolder = unmaskedValue?.length !== 14;

    const isDocumentValid =
      validationCpfOrCnpj(unmaskedValue) && validateCpfOrCnpj(unmaskedValue);

    if (
      !!document_ocr &&
      isDocumentValid &&
      !compareDocuments(event.target.value, document_ocr)
    )
      isDocumentHolder = false;

    if (validationCpfOrCnpj(event.target.value)) {
      touched = true;
    }

    if (!isDocumentValid || !isDocumentHolder) {
      status = 'refused';
    }

    const textError = !isDocumentValid
      ? 'CPF/CNPJ inválido'
      : !isDocumentHolder
      ? 'O documento informado diverge do documento do titular da conta'
      : '';

    changePayload({
      document: {
        ...document,
        value: maskCpfOrCnpj(unmaskedValue),
        unmaskedValue,
        status,
        touched,
        textError,
      },
      options: { ...options, isTheHolder },
    });
  };

  const handleConfirmIsTheHolder = (event) => {
    const isTheHolder = event.target.checked;
    const { ocrSuccess } = options || {};

    const holderValue =
      (ocrSuccess ? holder?.initialValue : undefined) || name?.value || '';

    const restOfHolder = isTheHolder
      ? { value: holderValue, status: 'accepted' }
      : { value: '', status: 'awaiting' };

    const newHolder = { ...holder, ...restOfHolder };

    const finalOptions = { ...options, isTheHolder };

    changePayload({ holder: newHolder, options: finalOptions });
  };

  const handleNameHolder = (event, addTouched) => {
    let status = 'accepted';
    let textError = '';
    const { value } = event?.target || event;

    const isNameValid = validationName(onlyString(value));

    if (!isNameValid) {
      status = 'refused';

      const completeLabel = `${holderPrefix} ${holderLabel}`;
      const splitName = value.split(' ');
      if (splitName.length > 1 && splitName?.[1])
        textError = `${holderLabel} deve conter apenas letras e espaços`;
      else textError = `Digite ${completeLabel.toLocaleLowerCase()}`;
    }

    const finalHolder = {
      ...holder,
      value: onlyString(value),
      status,
      textError,
    };
    if (addTouched) finalHolder.touched = true;

    changePayload({ holder: finalHolder });
  };

  const blurHolderName = () => {
    if (!holder.value.length) {
      changePayload({
        holder: { ...holder, touched: true, status: 'refused' },
      });

      return;
    }

    changePayload({ holder: { ...holder, touched: true } });
  };

  const messageSign = () => {
    const { isTheHolder } = options;
    if (document.value.length === 14) {
      if (!isTheHolder)
        return 'Informe o melhor meio de contato do titular para assinatura.';

      return 'Como gostaria de receber seu token de assinatura?';
    }

    return 'Informe o melhor meio de contato do signatário para assinatura.';
  };

  const handleNextStage = async (event) => {
    event.preventDefault();

    const { signer } = stagePayload;
    const { type, email, phone_number } = signer;

    const payloadSubmitted = {
      document: unMask(document.value),
      holder: holder?.value,
      type,
      stage: 3,
    };

    switch (signer?.type) {
      case 'email':
        payloadSubmitted.email = email;
        break;
      case 'sms':
      case 'whatsapp':
        payloadSubmitted.phone_number = phone_number;
        break;
      default:
        throw { message: 'invalid option' };
    }

    await sendPayloadGlobal(payloadSubmitted, isNewPropertyOrHolder, {
      setLoading,
    });
  };

  useEffect(() => {
    if (
      (signer?.type === 'whatsapp' || signer?.type === 'sms') &&
      !validationPhone(signer?.value)
    ) {
      changePayload({
        holder_token: { ...signer, value: '' },
      });

      return;
    }

    if (signer?.type === 'email' && !validationEmail(signer.value)) {
      changePayload({
        signer: { ...signer, value: '' },
      });
    }
  }, []);

  useEffect(() => {
    const verify =
      document?.status === 'accepted' &&
      holder?.status === 'accepted' &&
      signer?.status === 'accepted' &&
      signer.type.length;

    if (verify) {
      setButtonDisabled(false);

      return;
    }

    setButtonDisabled(true);
  }, [document, holder, signer]);

  useEffect(() => {
    const { value, status, touched } = holder || {};
    if (!value) return;
    if (status !== 'awaiting') return;
    if (touched) return;
    handleNameHolder(holder, true);
  }, [holder]);

  const handleRadiobox = curlyHandleRadioBox(useStage());

  const handleReceiveToken = curlyHandleReceiveToken(useStage());

  return (
    <>
      <LoadingCustomModal isLoading={loading} />
      <Container>
        <Content isNewPropertyOrHolder={!!isNewPropertyOrHolder}>
          <Title>Quase lá!</Title>

          <p>
            Indique os dados do titular da conta de luz, ele deve ser o
            signatário do termo de adesão.
          </p>

          <form onSubmit={handleNextStage}>
            <InputCustom
              name='cpf_or_cnpj_holder'
              type='text'
              labelText={documentLabel}
              value={document?.value || ''}
              onChange={handleHolderDocument}
              hasError={document?.status === 'refused'}
              hasTouched={document?.touched}
              errorMessage={document?.textError}
              disabled={isSignerFieldDisabled('document')}
              maxLength={18}
            />

            {UNMASK(document?.value).length === 11 &&
              document?.status === 'accepted' &&
              !disableIsTheHolderChange && (
                <Checkbox
                  checked={isTheHolder}
                  onChange={handleConfirmIsTheHolder}
                >
                  <label>Confirmo ser o titular da conta de luz.</label>
                </Checkbox>
              )}

            {(UNMASK(document?.value).length === 11 ||
              UNMASK(document?.value).length === 14) &&
              document?.status === 'accepted' && (
                <InfoPeopleOrCompany>
                  <InputCustom
                    name='holder'
                    type='text'
                    labelText={holderLabel}
                    value={holder?.value || ''}
                    onChange={handleNameHolder}
                    onBlur={blurHolderName}
                    hasError={holder?.status === 'refused'}
                    errorMessage={holder?.textError}
                    hasTouched={holder?.touched}
                    disabled={isSignerFieldDisabled('holder')}
                  />

                  <p style={{ color: '#1C1B1F', textAlign: 'center' }}>
                    {messageSign()}
                  </p>

                  <RadioboxContainer>
                    <Radiobox
                      name='signerOptionsRadioBox'
                      checked={signer?.type}
                      options={signerOptionsRadioBox}
                      onChange={handleRadiobox}
                    />
                  </RadioboxContainer>
                </InfoPeopleOrCompany>
              )}

            {signer?.type &&
              (UNMASK(document?.value).length === 11 ||
                UNMASK(document?.value).length === 14) &&
              document?.status === 'accepted' && (
                <InputCustom
                  name='holder_token'
                  type='text'
                  value={getSignerValue(signer)}
                  labelText={typeClient(signer?.type, unMask(document?.value))}
                  onChange={handleReceiveToken}
                  hasError={signer?.status === 'refused'}
                  hasTouched={signer?.touched}
                  maxLength={
                    signer?.type === 'sms' || signer?.type === 'whatsapp'
                      ? 16
                      : ''
                  }
                />
              )}

            <ButtonContainer>
              <SecondaryButton onClick={handleBackStage}>
                Voltar
              </SecondaryButton>
              <PrimaryButton
                id={import.meta.env.REACT_APP_ENV === 'prod' ? 'contrato2' : ''}
                type='submit'
                disabled={buttonDisabled}
              >
                Avançar
              </PrimaryButton>
            </ButtonContainer>
          </form>
        </Content>
      </Container>
    </>
  );
};

import { Grid } from '@mui/material';
import PromocodesForm from '../../../components/Forms/PromocodesForm';
import { Modal } from '../../../components/NewModal/styles';

const ModalCreatePromocode = ({ onSubmit, onClose }) => {
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
      >
        <PromocodesForm
          onSubmit={onSubmit}
          onClose={onClose}
        />
      </Grid>
    </Modal>
  );
};

export default ModalCreatePromocode;

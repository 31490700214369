import React from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';

import PropTypes from 'prop-types';
import Button from '../../Button';
import { colors } from '../../../styles/colors';

const BaseModalConfirmation = ({
  className,
  children = undefined,
  title,
  open = false,
  onClose,
  onConfirm,
  closeText = 'Cancelar',
  confirmText = 'Confirmar',
  disabled = false,
  onConfirmLoading = false,
}) => {
  return (
    <div className={className.concat(open ? ' active' : '')}>
      <div className='modal-box'>
        <span className='modal-close-button' onClick={onClose} aria-hidden>
          <AiOutlineClose color={colors.primary} />
        </span>
        <h1 className='modal-title'>{title}</h1>
        <div className='modal-content'>{children}</div>
        <Grid className='modal-actions' container spacing={3} justify='center'>
          {onClose && (
            <Grid item>
              <Button
                text={closeText}
                handleClick={onClose}
                color='buttonGray'
                rectangle
                isLoading={onConfirmLoading}
              />
            </Grid>
          )}
          <Grid item>
            <Button
              text={confirmText}
              handleClick={onConfirm}
              rectangle
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

BaseModalConfirmation.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  closeText: PropTypes.string,
  confirmText: PropTypes.string,
  onConfirmLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export const ModalConfirmation = styled(BaseModalConfirmation)`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 9999999;
  padding: 30px;

  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    &-box {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 30px;
      width: auto;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      max-height: 90vh;
    }

    &-content {
      padding: 15px;
      overflow-y: auto;
    }

    &-title {
      color: ${colors.primary};
      margin-right: 15px;
    }

    &-close-button {
      position: absolute;
      top: 10px;
      right: 15px;
      cursor: pointer;
    }

    &-actions {
      margin-top: 50px;
    }
  }
`;

export const monthsArray = [
  'JAN',
  'FEV',
  'MAR',
  'ABR',
  'MAI',
  'JUN',
  'JUL',
  'AGO',
  'SET',
  'OUT',
  'NOV',
  'DEZ',
];

export const getFinalMonth = (monthInString) =>
  monthsArray.indexOf(monthInString) + 1;

export const getMonthInString = (monthInNumber) =>
  monthsArray[monthInNumber - 1];

export const completeMonthsArray = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const formatDate = (dateString) => {
  try {
    if (!dateString) return '';
    const date = new Date(dateString).toISOString();
    const stringDate = date.split('T')[0];
    const [year, month, day] = stringDate.split('-');
    return `${day}/${month}/${year}`;
  } catch (error) {
    return '';
  }
};

export const isDateValid = (dateString) => {
  //reconhece o padrão: "YYYY-MM-DDTHH:mm:ss.000Z" ou "YYYY-MM-DDTHH:mm:ss"
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z?$/;
  return regex.test(dateString);
};

export const PAYMENT_METHODS = {
  Card: 'credit_card',
  BankSlip: 'bank_slip',
  Pix: 'pix',
  All: 'all',
};

export const PAYMENT_METHODS_PTBR = {
  [PAYMENT_METHODS.Card]: 'Cartão de Crédito',
  [PAYMENT_METHODS.BankSlip]: 'Boleto',
  [PAYMENT_METHODS.Pix]: 'Pix',
  [PAYMENT_METHODS.All]: 'Todos',
};

import { useEffect, useState } from 'react';
import { InputCustom } from '../InputCustom';
import { MdCheckCircle, MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import { IoCloseCircleSharp, IoInformationCircle } from 'react-icons/io5';
import styled from 'styled-components';
import theme from '../../theme';

const isConsecutive = (sequence) => {
  let isSeqAsc = true;
  let isSeqDes = true;

  for (let i = 0; i < sequence.length - 1; i++) {
    if (parseInt(sequence[i]) + 1 !== parseInt(sequence[i + 1])) {
      isSeqAsc = false;
    }
    if (parseInt(sequence[i]) - 1 !== parseInt(sequence[i + 1])) {
      isSeqDes = false;
    }
  }
  return isSeqAsc || isSeqDes;
};

const findConsecutiveSequences = (text) => {
  const matches = text.match(/\d{4,}/g) || [];
  const results = [];

  matches.forEach((match) => {
    for (let i = 0; i <= match.length - 4; i++) {
      const subsequence = match.slice(i, i + 4);
      if (isConsecutive(subsequence)) {
        results.push(subsequence);
      }
    }
  });
  return results;
};

const InputPassword = ({
  setValuePassword,
  setInvalidPassword,
  invalidPassword,
  resetPasswordToken,
}) => {
  const minCharacters = 8;
  const [touchedPassword, setTouchedPassword] = useState(false);
  const [validation, setValidation] = useState({
    minCharacters: { status: false, text: 'Mínimo de 8 caracteres' },
    upperCase: { status: false, text: 'Letra maiúscula (A-Z)' },
    lowerCase: { status: false, text: 'Letra minúscula (a-z)' },
    number: { status: false, text: 'Número (0-9)' },
    sequenceNumber: {
      status: null,
      text: 'Até 3 números sequenciais',
      falseIcon: <IoCloseCircleSharp style={{ color: '#d3593c' }} />,
      nullIcon: (
        <MdOutlineRadioButtonUnchecked
          style={{ color: theme.palette.gray.main }}
        />
      ),
    },
    specialCharacters: {
      status: false,
      text: "Caracter especial (exceto #, ' ou --)",
    },
    noInvalidCharacters: {
      status: true,
      text: "Senha inválida devido o uso de um dos seguintes caracteres: #, ' ou --",
      textColor: theme.palette.error.main,
      showOnlyFalse: true,
      falseIcon: <IoCloseCircleSharp style={{ color: '#d3593c' }} />,
    },
  });
  const [password, setPassword] = useState('');

  const handlePassword = (event) => {
    setPassword(event.target.value);
    setValuePassword(event.target.value);
  };

  useEffect(() => validateInput(password), [password]);
  useEffect(() => console.log('touched', touchedPassword), [touchedPassword]);

  const validateInput = (value) => {
    const newValidation = {
      minCharacters: {
        ...validation.minCharacters,
        status: value.length >= minCharacters,
      },
      upperCase: {
        ...validation.upperCase,
        status: /[A-Z]/.test(value),
      },
      lowerCase: {
        ...validation.lowerCase,
        status: /[a-z]/.test(value),
      },
      number: {
        ...validation.number,
        status: /\d/.test(value),
      },
      sequenceNumber: {
        ...validation.sequenceNumber,
        status: !/\d/.test(value)
          ? null
          : !findConsecutiveSequences(value).length > 0,
      },
      specialCharacters: {
        ...validation.specialCharacters,
        status: /[!@$%^&*(),.?":{}|<>-]/.test(value),
      },
      noInvalidCharacters: {
        ...validation.noInvalidCharacters,
        status: !/(#|'|--)/.test(value),
      },
    };

    setValidation(newValidation);
  };

  useEffect(() => {
    const validate = Object.entries(validation)
      .map(([key, value]) => value.status)
      .some((status) => status === false);
    setInvalidPassword(validate);
  }, [validation]);

  return (
    <>
      <InputCustom
        type='password'
        name='password'
        labelText='Senha'
        value={password}
        onChange={handlePassword}
        hasError={invalidPassword}
        hasTouched={password.length > 0}
      />
      <ValidationResult>
        {Object.entries(validation).map(
          ([
            key,
            {
              text,
              status,
              trueIcon,
              falseIcon,
              nullIcon,
              showOnlyFalse,
              textColor,
            },
          ]) => (
            <>
              {showOnlyFalse && status ? null : (
                <div key={key}>
                  {status ? (
                    trueIcon ? (
                      trueIcon
                    ) : (
                      <MdCheckCircle style={{ color: ' #3fd33c' }} />
                    )
                  ) : status === false ? (
                    falseIcon ? (
                      falseIcon
                    ) : (
                      <MdOutlineRadioButtonUnchecked
                        style={{ color: theme.palette.gray.main }}
                      />
                    )
                  ) : nullIcon ? (
                    nullIcon
                  ) : (
                    <MdOutlineRadioButtonUnchecked
                      style={{ color: theme.palette.gray.main }}
                    />
                  )}
                  <span style={{ color: textColor }}>{text}</span>
                </div>
              )}
            </>
          )
        )}
        {resetPasswordToken ? (
          <div>
            <IoInformationCircle style={{ color: '#2091ed' }} /> A senha deve
            ser diferente das utilizadas anteriormente
          </div>
        ) : null}
      </ValidationResult>
    </>
  );
};

const ValidationResult = styled.div`
  padding: 8px;
  border-radius: 8px;
  background: #f9f9f9;
  margin-top: 0 !important;

  > div {
    display: flex;
    align-items: center;
    svg {
      margin-right: 10px;
      height: 22px !important;
      width: 22px !important;
    }
  }
`;

export default InputPassword;

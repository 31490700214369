import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import Button from '../../../../../../components/Button';
import Divider from '../../../../../../components/Divider';
import NewLocationForm from '../../../../../../components/Forms/NewLocationAdminForm';
import { StatusToggle } from '../../../../../../components/Interface';
import { TOAST_MESSAGES } from '../../../../../../helpers/toastMessages';
import { colors } from '../../../../../../styles/colors';
import { hasAttachment } from '../../../../../../utils';
import ContractsAdminService from '../../../../../../services/administrator/contractDetails.service';
import adminStores from '../../../../../../store/admin/contractDetails/index.store';
import { updateAdminStore } from '../../../../../../store/admin/contractDetails/helpers';
import theme from '../../../../../../theme';

const ContractBillAttachmentSection = () => {
  const {
    location: {
      location,
      location: { provider_id: providerId },
    },
    contract: { contract },
    providers: { providers },
  } = adminStores();

  const [isSubmitting, setIsSubmitting] = useState({});
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (providerId) {
      const provider = providers.find((p) => p.id === providerId);
      if (provider) {
        setProvider({
          hasOcr: provider.has_ocr,
          name: provider.name,
        });
      }
    }
  }, [providerId, providers]);

  const generateOcr = async () => {
    try {
      const savedProvider = provider?.name;
      toast.success(
        savedProvider
          ? `Realizando OCR para a distribuidora ${savedProvider}`
          : 'Realizando OCR '
      );

      const dataUpdated = await ContractsAdminService.update({
        locations: { ocr: 0, id: location.id },
      });

      updateAdminStore(dataUpdated);

      const { locations } = dataUpdated;
      const updatedProvider = providers.find(
        (p) => p.id === locations.provider
      );
      const providerName = updatedProvider
        ? updatedProvider.name
        : provider?.name || 'desconhecida';

      toast.success(
        `OCR e precificação realizada com sucesso para a distribuidora ${providerName}`
      );
    } catch (err) {
      console.log('file: index.js || line 368 || err', err);
      if (err instanceof Array)
        return toast.error('OCR realizado com falha: ' + err.join('\n'));
      toast.error('OCR realizado com falha: ' + err);
    }
  };

  const updateLocationAttchmentValid = async (isValid) => {
    try {
      setIsSubmitting((prevState) => ({
        ...prevState,
        attachment_valid: true,
      }));
      const payload = {
        locations: { id: location.id, attachment_valid: isValid },
      };
      const dataUpdated = await ContractsAdminService.update(payload);

      updateAdminStore(dataUpdated);

      toast.success(TOAST_MESSAGES.SUCCESS);
    } catch (err) {
      toast.error(TOAST_MESSAGES.ERROR);
    } finally {
      setIsSubmitting((prevState) => ({
        ...prevState,
        attachment_valid: false,
      }));
    }
  };

  return (
    <Grid
      container
      spacing={1}
      rowSpacing={1}
    >
      <Grid
        item
        xs
      >
        {hasAttachment(location) && (
          <>
            <Button
              text='Realizar OCR'
              color={theme.palette.primary.main}
              outlined
              rectangle
              handleClick={() =>
                provider?.hasOcr
                  ? generateOcr()
                  : toast.error(
                      `OCR não implementado para a distribuidora ${provider?.name}`
                    )
              }
            />
            <Divider space='medium' />
          </>
        )}
        <Button
          text='Visualizar conta'
          color='primary'
          disabled={!hasAttachment(location)}
          outlined
          rectangle
          handleClick={() => {
            if (hasAttachment(location)) {
              window.open(location.attachmentLink);
            }
          }}
        />
      </Grid>

      <Grid
        item
        xs
      >
        <StatusToggle
          active={location.attachment_valid}
          labelText={
            location.attachment_valid ? 'Conta Válida' : 'Conta Inválida'
          }
          statusColors={[theme.palette.secondary.main, theme.palette.gray.main]}
          handleChange={() =>
            location.attachment_valid
              ? updateLocationAttchmentValid(false)
              : updateLocationAttchmentValid(true)
          }
          isSubmitting={isSubmitting?.attachment_valid}
        />
      </Grid>

      {!location.attachment_valid && (
        <Grid item>
          <NewLocationForm
            onCancel={() =>
              setPageState((state) => ({
                ...state,
                isAddingLocation: false,
              }))
            }
            location={{
              ...location,
              contractId: contract?.id,
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ContractBillAttachmentSection;

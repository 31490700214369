export const handlingFileName = (name) => {
  if (!name) return '';

  const nameFiltered = name.replace(/[Ã¢Ì§|]/g, '*').split('*')[0];

  const extension = name.split('.').pop();
  const finalName =
    nameFiltered.length > 25
      ? `${nameFiltered
          .replace(`.${extension}`, '')
          .substring(0, 25)}***.${extension}`
      : nameFiltered;

  return finalName;
};

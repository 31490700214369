import { Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { FieldArray } from 'formik';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Heading3 } from '../../../../../../components/Interface/Text';
import FlexRowContainer from '../../../../../../componentsStyle/FlexRowContainer';

import {
  CustomErrorMessage,
  CustomField,
  FieldWrapper,
  Label,
} from '../../../../../../components/Interface/Form';
import { getMonthInString } from '../../../../../../helpers/date';

moment.locale('pt-br');

const removeFutureMeasurements = (measurements) => {
  if (!measurements?.length) return [];
  const today = moment();
  const filteredMeasurements = measurements.filter((measurement) => {
    const measurementDate = moment(measurement.date, 'yyyy-MM-DD');
    return measurementDate.isBefore(today);
  });

  return filteredMeasurements?.sort((a, b) => {
    return moment(b.date, 'yyyy-MM-DD').diff(moment(a.date, 'yyyy-MM-DD'));
  });
}

const Measurements = ({ measurements, values, formValues, readonly }) => {
  const [showMeasurements, setShowMeasurements] = useState(false);

  const filteredMeasurements = removeFutureMeasurements(formValues?.measurements);

  return (
    <MeasurementsContainer>
      <Grid
        container
        spacing={1}
        rowSpacing={1}
      >
        <Grid
          item
          xs={12}
        >
          <FlexRowContainer>
            <Heading3 style={{ flexGrow: 1 }}>Histórico de consumo</Heading3>
            <IconButton
              aria-label='show-measurements'
              style={{ height: 48, width: 48 }}
              onClick={() => setShowMeasurements(!showMeasurements)}
            >
              {showMeasurements ? (
                <ArrowUpIcon style={{ height: 32, width: 32 }} />
              ) : (
                <ArrowDownIcon style={{ height: 32, width: 32 }} />
              )}
            </IconButton>
          </FlexRowContainer>
        </Grid>
        {showMeasurements && (
          <FieldArray
            name='measurements'
            render={() =>
              filteredMeasurements?.length ? (
                filteredMeasurements.map((measurement, index) => {
                  const formKey = `measurements.${index}.kwh`;
                  return (
                    <Grid
                      item
                      xs={6}
                      md={3}
                      lg={2}
                      key={index}
                    >
                      <FieldWrapper>
                        <Label
                          htmlFor={formKey}
                          style={{
                            textTransform: 'uppercase',
                            textAlign: 'center',
                          }}
                        >
                          {`${getMonthInString(moment(measurement.date, 'yyyy-MM-DD').month() + 1)} ${moment(measurement.date, 'yyyy-MM-DD').year()}`}
                        </Label>
                        <CustomField
                          type='number'
                          name={formKey}
                          disabled={readonly}
                          fullwidth
                          style={{ textAlign: 'center' }}
                          background='#f7f7f7'
                        />
                        <CustomErrorMessage name={formKey} />
                      </FieldWrapper>
                    </Grid>
                  );
                })
              ) : (
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: 'center', marginTop: 16 }}
                >
                  <p>Não há medições disponíveis</p>
                </Grid>
              )
            }
          />
        )}
      </Grid>
    </MeasurementsContainer>
  );
};
export default Measurements;

const MeasurementsContainer = styled.div`
  padding: 8px;
  background: #f7f7f7;
  border-radius: 8px;
`;

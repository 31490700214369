import styled from 'styled-components';
import theme from '../../../theme';

export const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 1.5rem;

  svg {
    font-size: 2rem;
    color: ${theme.palette.primary.main};
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.438rem;
    color: ${theme.palette.primary.main};
    margin: 0;
  }
`;

/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStage } from '../../../hooks/useStage';

import { NewPageView } from '../../../containers/NewPageView';

import { Header } from '../../../components/Header';

import { StageOne } from '../../../components/StagesProgress/StageOne';
import { StageTwo } from '../../../components/StagesProgress/StageTwo';
import { StageSign } from '../../../components/StagesProgress/contractEmission/StageSign';
import { StageClicksignSignature } from '../../../components/StagesProgress/StageClicksignSignature';
import { StageBackUnsigned } from '../../../components/StagesProgress/contractEmission/StageBackUnsigned';

import { LoadingCustomModal } from '../../../components/Modals/LoadingCustomModal';

import { Container } from './styles';

import { useStateForProposal } from '../../../hooks/dataForProposal';
import { handleResume, handleSigned } from './helpers';
import { allCalc } from '../../../utils/calculateProposal';
import { DataForProposalProvider } from '../../../contexts/dataForProposalContext';
import { removeAuthData } from '../../../store';
import { asyncTimeout } from '../../../helpers/timers';
import { extractParams } from '../../../helpers/url';

const NewRegisterPages = ({ isNewPropertyOrHolder = false, selectedContract = {} }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(
    !!localStorage.getItem('floraenergia:auth')
  );
  const { stagePayload, changePayload, resetStagePayload } = useStage();
  const { setDataForProposal } = useStateForProposal();

  const handleSetPasswordAfterSigningViaClicksign = async (
    stagePayloadInsumes
  ) => {
    try {
      const {
        role,
        email: { value: email },
      } = stagePayloadInsumes;
      if (role !== 'set_password') return;
      await handleSigned(
        email,
        navigate,
        undefined,
        isNewPropertyOrHolder,
        resetStagePayload
      );
    } catch (error) {
      delete stagePayloadInsumes.role;
      console.log('file: index.js || line 47 || error', error);
    }
  };

  const resumeHandler = async () => {
    try {
      if (!localStorage.getItem('floraenergia:auth')) return;
      const { providerData, ...stagePayloadInsumes } = await handleResume(
        selectedContract,
        isNewPropertyOrHolder
      );

      const {
        estimated_electric_bill: {
          converted_value: estimated_electric_bill,
        } = { converted_value: 0 },
      } = stagePayloadInsumes;
      await handleSetPasswordAfterSigningViaClicksign(stagePayloadInsumes);

      let promocode = stagePayloadInsumes?.promocode?.value;

      if (!promocode) {
        ({ promocode } = extractParams(location)) || '';
        if (promocode)
          Object.assign(stagePayloadInsumes, {
            promocode: { value: promocode },
          });
      }

      const proposal = await allCalc(estimated_electric_bill, providerData);

      setDataForProposal({ providerData, proposal });

      changePayload(stagePayloadInsumes);
    } catch (err) {
      console.log('file: index.js || line 39 || error', err);
      const code = err?.status;
      if (code === 432) {
        setLoading(false);
        removeAuthData();
        toast.error(
          'Seu cadastro já está concluído, dentro de instantes você será redirecionado para a página de login'
        );
        await asyncTimeout(5000);
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading) {
      const {
        promocode,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_id,
        utm_term,
        utm_content,
        ...utm_extra_data
      } = extractParams(location);
      changePayload({
        promocode: { value: promocode },
        utm_source,
        utm_medium,
        utm_campaign,
        utm_id,
        utm_term,
        utm_content,
        utm_extra_data:
          Object.keys(utm_extra_data).length === 0
            ? utm_extra_data
            : JSON.stringify(utm_extra_data),
      });
      return;
    }
    resumeHandler();
  }, []);

  useEffect(() => {
    window.clarity('set', 'Fluxo De Cadastro', `step${stagePayload.stage}`);
  }, [stagePayload.stage]);

  return (
    <NewPageView isNewPropertyOrHolder={!!isNewPropertyOrHolder}>
      <LoadingCustomModal isLoading={loading} />
      <Container isNewPropertyOrHolder={!!isNewPropertyOrHolder}>
        <Header isNewPropertyOrHolder={!!isNewPropertyOrHolder} />
        {stagePayload.stage === 0 && (
          <StageOne
            isNewPropertyOrHolder={!!isNewPropertyOrHolder}
            resumeHandler={resumeHandler}
          />
        )}
        {stagePayload.stage === 1 && (
          <StageTwo isNewPropertyOrHolder={!!isNewPropertyOrHolder} />
        )}
        {stagePayload.stage === 2 && (
          <StageSign isNewPropertyOrHolder={!!isNewPropertyOrHolder} />
        )}
        {stagePayload.stage === 3 && (
          <StageClicksignSignature
            isNewPropertyOrHolder={!!isNewPropertyOrHolder}
          />
        )}
        {(stagePayload.stage === 4 || stagePayload.stage === 5) && (
          <StageBackUnsigned isNewPropertyOrHolder={!!isNewPropertyOrHolder} />
        )}
      </Container>
    </NewPageView>
  );
};

export const NewRegister = ({ isNewPropertyOrHolder = false, selectedContract = {} }) => (
  <DataForProposalProvider>
    <NewRegisterPages
      isNewPropertyOrHolder={!!isNewPropertyOrHolder}
      selectedContract={selectedContract}
    />
  </DataForProposalProvider>
);

NewRegisterPages.propTypes = {
  isNewPropertyOrHolder: PropTypes.bool,
  selectedContract: PropTypes.object,
};

NewRegister.propTypes = {
  isNewPropertyOrHolder: PropTypes.bool,
  selectedContract: PropTypes.object,
};

import styled, { css } from 'styled-components';
import theme from '../../../theme';

const isPendency = css`
  color: ${theme.palette.primary.main} !important;
`;

export const Container = styled.div`
  min-height: 100%;
  background: ${(props) => props.themeValue.page_user_background};
  padding-top: 60px;

  @media (max-width: 991px) {
    margin-top: 24px;
  }
`;

export const Content = styled.div`
  max-width: 428px;
  margin: 0 auto;
  padding: 0 1.5rem 1.5rem;

  @media (min-width: 992px) {
    max-width: 1128px;
  }
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  h1 {
    margin: 0;

    color: ${theme.palette.primary.main};
    font-size: 24px;
    font-weight: 700;
  }

  svg {
    color: ${theme.palette.primary.main};
  }
`;

export const Separator = styled.div`
  height: 1px;
  background: ${theme.palette.gray.main};
  margin: 24px 0;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }
`;

export const CardsPendingAndRegistrationData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Card = styled.div`
  position: relative;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  margin: 0 auto;
  width: 100%;
  max-width: 428px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);

  > p {
    margin: 24px 0 0 0;
    font-size: 1.125rem;

    span {
      color: ${theme.palette.primary.main};
      font-weight: 500;
    }
  }

  @media (min-width: 992px) {
    max-width: 552px;
  }
`;

export const CardContent = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 8px;

  button {
    max-width: 6rem;
    font-size: 1.125rem;

    &:disabled {
      font-size: 1.125rem;
    }
  }
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin: 0 0 0 4px;
    font-size: 20px;
    font-weight: 700;
    color: ${theme.palette.primary.main};
    ${(props) => props.isPendency && isPendency};
  }

  svg {
    color: ${theme.palette.primary.main};
  }
`;

export const RadioboxContainer = styled.div`
  p {
    margin-bottom: 0.5rem;
  }
`;

export const RadioboxContent = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const HiddenExpandContainer = styled.div`
  ${(props) =>
    props.isActive &&
    css`
      display: flex;
      flex-direction: column;
      gap: 16px;
    `}

  ${(props) =>
    !props.isActive &&
    css`
      display: none;
    `}
`;

export const ImportantMessage = styled.div`
  > p {
    font-size: 1.125rem;
    font-weight: 300;
    color: #1f1b1e;
    margin: 0;
  }

  strong {
    color: #b3261e;
    font-weight: 500;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* margin-bottom: 24px; */

  > button {
    width: 100%;
    height: 32px;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 16px;

    border-radius: 0px 0px 8px 8px;

    svg {
      margin-right: 8px;
    }
  }
`;

/* eslint-disable react/jsx-no-comment-textnodes */
import ClearIcon from '@mui/icons-material/Clear';
import { CircularProgress } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ProducersService from '../../../services/administrator/producers.service';
import { colors } from '../../../styles/colors';
import GradientButton from '../../GradientButton';
import { FormDefaultProps, FormPropTypes } from '../types';
import { Heading2 } from '../../Interface/Text';
import theme from '../../../theme';

export const BaseWaitlistForm = ({
  onSubmit,
  handleClose,
  providers,
  producers,
  values,
  loadingg,
  setLoadingg,
  setReload,
  reload,
}) => {
  const lightCodes = ['light', 'light_2'];
  const [producersOptions, setProducersOptions] = useState([]);
  const [producerIdSelected, setProducerIdSelected] = useState(
    values.producer_id
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    id: '',
    name: 0,
    producer_id: null,
    cip: 10,
    profile: null,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'profile') {
      setProducerIdSelected(null);
      setFormData((state) => ({
        ...state,
        profile: event?.target?.value,
        producer_id: null,
        producer: '',
      }));
    } else if (name === 'producer_id') {
      setProducerIdSelected(event?.target?.value);
      setFormData((state) => ({
        ...state,
        producer_id: event?.target?.value,
      }));
    } else {
      setFormData((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  const handleDelete = async () => {
    const { id } = values;
    setLoadingg(true);
    try {
      await ProducersService.deleteOne({ producerId: id });
      setLoadingg(false);
    } catch (error) {
      setLoadingg(false);
      console.log(error);
    } finally {
      handleClose();
      setReload(!reload);
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    onSubmit(formData, { isSubmitting });
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (values) {
      setFormData(values);
    } else {
      setFormData({
        id: '',
        name: '',
        producer_id: '',
        cip: 10,
      });
    }
  }, [values]);

  useEffect(() => {
    let options = [];
    if (formData.profile === 'on_hold') {
      options = producers.filter((producer) => producer.provider_id === null);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (lightCodes.includes(formData.code)) {
        const lightIds = providers
          .filter((provider) => lightCodes.includes(provider.code))
          .map(({ id }) => id);

        options = producers.filter((producer) =>
          lightIds.includes(producer.provider_id)
        );
      } else {
        options = producers.filter(
          (producer) => producer.provider_id === formData.id
        );
      }
    }
    setProducersOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <UpperDiv
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Heading2>
          {values ? 'Alterar Distribuidora' : 'Cadastrar Distribuidora'}
        </Heading2>
        <ClearIcon
          style={{ color: theme.palette.primary.main }}
          onClick={handleClose}
          id='clear'
        />
      </UpperDiv>
      <MainContainer>
        <ColumnContainer>
          <TextField
            variant='outlined'
            label='Nome'
            placeholder='Nome'
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            name='name'
            defaultValue={values ? values.name : formData.name}
            disabled={true}
            fullWidth
          />

          <TextField
            variant='outlined'
            label='CIP'
            placeholder='CIP'
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            name='cip'
            type='number'
            defaultValue={values ? values.cip : formData.cip}
            fullWidth
          />
        </ColumnContainer>

        <ColumnContainer>
          <FormControl
            variant='outlined'
            style={{
              marginTop: '1rem',
              width: '100%',
            }}
          >
            <InputLabel htmlFor='demo-simple-select-outlined'>
              Perfil
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              defaultValue={values.profile || formData.profile}
              style={{ width: '100%' }}
              onChange={handleChange}
              label='Perfil'
              name='profile'
              fullWidth
            >
              <MenuItem value='on_hold'>On hold</MenuItem>
              <MenuItem value='target'>Target</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant='outlined'
            style={{
              marginTop: '1rem',
              width: '100%',
              minWidth: 230,
            }}
          >
            <InputLabel htmlFor='demo-simple-select-outlined'>
              Gerador Principal
            </InputLabel>
            <Select
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={producerIdSelected}
              style={{ width: '100%' }}
              onChange={handleChange}
              label='Distribuidora'
              name='producer_id'
              fullWidth
              required={true}
            >
              {producersOptions &&
                producersOptions.map((producer) => {
                  return (
                    <MenuItem
                      key={producer.id}
                      value={producer.id}
                    >
                      {producer.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </ColumnContainer>
      </MainContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <GradientButton
          handleClick={handleSubmit}
          disabled={
            !formData.producer_id || (!formData.producer_id && isSubmitting)
          }
          text=''
          fontSize='50'
          borderRadius='6'
          style={{
            width: 'max-content',
          }}
          type='submit'
          loading={isSubmitting}
        >
          {loadingg ? (
            <CircularProgress style={{ color: 'white' }} />
          ) : (
            <>{values ? 'Alterar' : 'Cadastrar'}</>
          )}
        </GradientButton>
        {values && (
          <GradientButton
            handleClick={handleDelete}
            disabled={isSubmitting}
            text=''
            fontSize='50'
            borderRadius='6'
            style={{
              width: 'max-content',
            }}
            type='submit'
            loading={isSubmitting}
            background='white'
            color={theme.palette.primary.main}
            border={`1px solid ${theme.palette.primary.main}`}
          >
            {loadingg ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : (
              <>Deletar</>
            )}
          </GradientButton>
        )}
      </div>
    </form>
  );
};

BaseWaitlistForm.propTypes = FormPropTypes;
BaseWaitlistForm.defaultProps = FormDefaultProps;

const ProvidersForm = styled(BaseWaitlistForm)`
  position: relative;
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : 'Myriad-Pro-Condensed'};
  letter-spacing: 0.085em;
  background: ${colors.white};
  outline: none;
  border-radius: 2px;
  font-size: 16px;
  padding: 5px 10px;
  color: ${colors.primary};
  font-weight: 300;
  width: ${(props) => (props.fullwidth ? '100%' : 'auto')};

  &:disabled {
    border: none;
    padding: 0;
    color: ${colors.primary};
  }
`;
export default ProvidersForm;

const MainContainer = styled.section`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
`;

const UpperDiv = styled.div`
  #clear {
    :hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`;

import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const BaseStyledDialog = ({
  isOpen,
  handleClose,
  title = null,
  message = null,
  icon = null,
  imageSrc = null,
  imageAlt = null,
  className,
  children,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className={className}
    >
      <DialogContent>
        {(imageSrc || icon) && (
          <AlignCenter>
            <div className='styled-dialog-image'>
              {imageSrc && <img src={imageSrc} alt={imageAlt} />}
              {icon && <div className='styled-dialog-icon'>{icon}</div>}
            </div>
          </AlignCenter>
        )}
        {title && <p className='styled-dialog-title'>{title}</p>}
        {message && <p className='styled-dialog-message'>{message}</p>}
        {children}
      </DialogContent>
    </Dialog>
  );
};

BaseStyledDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  imageSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  imageAlt: PropTypes.string,
  className: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  icon: PropTypes.element,
};

export const StyledDialog = styled(BaseStyledDialog)`
  .MuiPaper-root {
    border-radius: 10px;
    padding: 30px 30px 15px 30px;
  }

  .MuiDialogContent-root {
    padding: 0 !important;
  }

  .styled-dialog-image {
    margin-bottom: 15px;
  }

  .styled-dialog-icon {
    text-align: center;
    color: #f9af40;
    & svg {
      font-size: 72px;
      @media screen and (max-width: 768px) {
        font-size: 36px;
      }
    }
  }

  p.styled-dialog {
    &-title {
      font-family: 'Myriad-Pro';
      font-weight: bold;
      font-size: 24px;
      line-height: 21px;
      text-align: center;
      color: #4d5e63;
      margin-bottom: 15px;
    }

    &-message {
      font-family: 'Myriad-Pro';
      font-size: 20px;
      line-height: 21px;
      text-align: center;
      margin-bottom: 15px;
      color: #4d5e63;
    }
  }

  @media screen and (max-width: 768px) {
    .MuiPaper-root {
      padding: 10px;
    }

    .MuiDialogContent-root {
      padding-top: 20px !important;
    }

    p.styled-dialog {
      &-title {
        font-size: 24px;
        line-height: 21px;
      }

      &-message {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
`;

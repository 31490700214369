import React, { useState } from 'react';

import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import sendEmailsService from '../../../services/sendBatchEmails/sendEmails.service';

import GradientButton from '../../../components/GradientButton';
import SearchableTable from '../../../components/Interface/SearchableTable';
import PageView from '../../../containers/PageView';

import { Container, FieldContainer } from './styles';
import ExcelRenderer from '../../../utils/excelRenderer';
import { DropzoneCustom } from '../../../components/DropzoneCustom';
import { Grid } from '@mui/material';
import { isValidExtension } from '../../../helpers/validationUploadfiles';

export const MassiveEmailShooting = () => {
  const [subject, setSubject] = useState('');
  const [html, setHtml] = useState('');
  const [file, setFile] = useState();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [standardTableColumns, setStandardTableColumns] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [noDownload, setNoDownload] = useState(true);
  const [loading, setLoading] = useState(false);
  const acceptedFileTypes = ['.xlsx'];

  const clearValues = () => {
    setSubject('');
    setHtml('');
    setFile('');
    setHasError(false);
    setRows([]);
  };

  const handleSendEmails = async () => {
    setIsDisabled(true);
    try {
      const { err } = await sendEmailsService.sendEmailsMassive({
        html,
        subject,
        columns,
        rows,
      });

      if (err) {
        const { data } = err;

        const arrayErros = data.map((element) => {
          const { email, variables } = element;

          return [email].concat(variables);
        });

        setHasError(true);
        setRows(arrayErros);
        setNoDownload(false);

        throw 'Erro no envio de emails.';
      }

      clearValues();
      toast.success('Emails enviados com sucesso.');
    } catch (error) {
      toast.error(error);
      console.error(error);
    } finally {
      setIsDisabled(false);
    }
  };

  const dataHandler = (data) => {
    const arrayOfArrays = data.rows;
    const [headers, ...rows] = arrayOfArrays;

    if (rows.length > 500) throw 'Documento tem mais de 500 linhas';

    if (headers[0] !== 'Email')
      throw 'A primeira coluna deve-se chamar "Email" é obrigatório';

    try {
      rows.map((array, index) => {
        if (array.length === 0) return;
        const hasAllItems =
          array.every((item) => item) && headers.length === array.length;

        if (!hasAllItems) throw `Há dados faltando na linha ${index + 1}`;
      });

      const assembleArrayOfColumns = headers.map((el) => {
        return { name: el };
      });
      const filteredRows = rows.filter((el) => el.length > 0);

      setRows(filteredRows);
      setColumns(headers);
      setStandardTableColumns(assembleArrayOfColumns);
    } catch (error) {
      setFile('');
      toast.error(error);
      console.error(error);
    }
  };

  const fileHandler = async (files) => {
    try {
      const fileObj = files[0];
      if (!isValidExtension(fileObj, acceptedFileTypes))
        throw "O formato do arquivo deve ser 'xlsx' ou 'xls'";

      const data = await ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          throw err;
        } else {
          return resp;
        }
      });

      dataHandler(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const getRows = (rows) => {
    try {
      return rows.map((row) => {
        const arr = row.map((cell) => {
          return { value: <p>{cell}</p> };
        });

        return { values: arr };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAll = async () => {
    setLoading(true);
    try {
      const headersAndRows = [];
      const rowsValues = [];

      const headers = columns.map((col) => {
        return col;
      });

      if (columns.length === 1) {
        rows.forEach((row) =>
          row.forEach((element) => {
            rowsValues.push(element);
          })
        );
      } else {
        rows.forEach((row) => {
          rowsValues.push(row);
        });
      }

      headersAndRows.push(rowsValues, headers);

      return headersAndRows;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageView title='Disparo Massivo de Emails'>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={4}
        >
          <Container>
            <FieldContainer>
              <TextField
                label='Assunto'
                placeholder='Ex: Promocode'
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
                type='text'
                color='primary'
                fullWidth={true}
              />
            </FieldContainer>

            <textarea
              placeholder='Inserir HTML'
              value={html}
              onChange={(event) => setHtml(event.target.value)}
            />

            <DropzoneCustom
              maxFiles={1}
              fileHandler={fileHandler}
              acceptedFileTypes={acceptedFileTypes}
              handlerFileAfterChange={true}
            />

            {hasError && (
              <div>
                <h2>Lista de emails não enviados</h2>
                <p>Quantidade: {rows.length}</p>
              </div>
            )}
          </Container>
        </Grid>
        <Grid
          item
          xs={12}
          md={8}
        >
          {rows.length > 0 && standardTableColumns.length > 0 && (
            <div
              style={{
                flexWrap: 'wrap',
                alignContent: 'center',
                width: '100%',
                overflow: 'auto',
              }}
            >
              <SearchableTable
                columns={standardTableColumns}
                rowsValues={getRows(rows)}
                noItemsText='Nenhum valor encontrado'
                noSearch
                noDownload={noDownload}
                loadingg={loading}
                fetchAll={fetchAll}
                maxHeight='80vh'
              />

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '1rem',
                }}
              >
                {isDisabled ? (
                  <GradientButton
                    paddingg='10px 30px'
                    cursor='not-allowed'
                    text=''
                  >
                    <CircularProgress />
                  </GradientButton>
                ) : (
                  <GradientButton
                    paddingg='10px 30px'
                    text='Enviar'
                    handleClick={() => handleSendEmails()}
                    disabled={
                      !!(
                        html.trim().length === 0 ||
                        subject.trim().length === 0 ||
                        isDisabled
                      )
                    }
                  />
                )}
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </PageView>
  );
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { mask as MASK } from 'remask';
import { MdClose } from 'react-icons/md';

import Payment from 'payment';
import { InputCustom } from '../../InputCustom';
import { NewModal } from '../../NewModal';
import { PrimaryButton } from '../../PrimaryButton';

import {
  Cards,
  CloseButton,
  Container,
  FormCreditCard,
  Header,
} from './styles';
import { onlyNumbers, onlyString } from '../../../helpers/form/formatter';
import {
  validationCard,
  validationName,
} from '../../../helpers/form/validations';
import theme from '../../../theme';

const masks = {
  expiry: '99/99',
  cvv: ['9999'],
};

export const CreditCardModal = ({
  payloadProperty = {},
  setPayloadProperty = () => {},
  isOpen,
  onRequestClose,
  handleSubmitPayment,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { numberCard, nameCard, expiryCard, cvv, focus, hasErrorCard } =
    payloadProperty;

  const handleNumberCard = (event) => {
    event.persist();

    const { value } = event.target;
    const isNumberCardValid = Payment.fns.validateCardNumber(value);
    const formatedNumber = Payment.fns.formatCardNumber(onlyNumbers(value));

    let status = 'accepted';

    if (!isNumberCardValid) {
      status = 'refused';
    }

    setPayloadProperty((prevState) => ({
      ...prevState,
      numberCard: {
        ...numberCard,
        value: formatedNumber,
        status,
      },
    }));
  };

  const blurNumberCard = () => {
    if (!numberCard.value.length) {
      setPayloadProperty((prevState) => ({
        ...prevState,
        numberCard: { ...numberCard, touched: true, status: 'refused' },
      }));

      return;
    }

    setPayloadProperty((prevState) => ({
      ...prevState,
      numberCard: {
        ...numberCard,
        touched: true,
      },
    }));
  };

  const handleNameCard = (event) => {
    event.persist();

    let status = 'accepted';

    const isNameValid = validationName(onlyString(event.target.value));

    if (!isNameValid) {
      status = 'refused';
    }

    setPayloadProperty((prevState) => ({
      ...prevState,
      nameCard: { ...nameCard, value: onlyString(event.target.value), status },
    }));
  };

  const blurNameCard = () => {
    if (!nameCard.value.length) {
      setPayloadProperty((prevState) => ({
        ...prevState,
        nameCard: { ...nameCard, touched: true, status: 'refused' },
      }));

      return;
    }

    setPayloadProperty((prevState) => ({
      ...prevState,
      nameCard: { ...nameCard, touched: true },
    }));
  };

  const handleExpiryCard = (event) => {
    event.persist();

    let status = 'accepted';

    const isDueDateValid = validationCard(event.target.value);

    if (!isDueDateValid) {
      status = 'refused';
    }

    setPayloadProperty((prevState) => ({
      ...prevState,
      expiryCard: {
        ...expiryCard,
        value: MASK(event.target.value, masks.expiry),
        status,
      },
      hasErrorCard: false,
    }));
  };

  const blurExpiryCard = () => {
    if (!expiryCard.value.length) {
      setPayloadProperty((prevState) => ({
        ...prevState,
        expiryCard: { ...expiryCard, touched: true, status: 'refused' },
      }));

      return;
    }

    setPayloadProperty((prevState) => ({
      ...prevState,
      expiryCard: { ...expiryCard, touched: true },
    }));
  };

  const handleCvv = (event) => {
    event.persist();

    let status = 'accepted';

    const isCvvValid = event.target.value.length >= 3;

    if (!isCvvValid) {
      status = 'refused';
    }

    setPayloadProperty((prevState) => ({
      ...prevState,
      cvv: {
        ...cvv,
        value: MASK(event.target.value, masks.cvv),
        status,
      },
      hasErrorCard: false,
    }));
  };

  const blurCvv = () => {
    if (!cvv.value.length) {
      setPayloadProperty((prevState) => ({
        ...prevState,
        cvv: { ...cvv, touched: true, status: 'refused' },
      }));

      return;
    }

    setPayloadProperty((prevState) => ({
      ...prevState,
      cvv: { ...cvv, touched: true },
    }));
  };

  const handleSaveCard = (event) => {
    handleSubmitPayment(event);
    //onRequestClose();
  };

  useEffect(() => {
    if (
      nameCard?.status === 'accepted' &&
      numberCard?.status === 'accepted' &&
      expiryCard?.status === 'accepted' &&
      cvv?.status === 'accepted'
    ) {
      setButtonDisabled(false);
      return;
    }

    setButtonDisabled(true);
  }, [nameCard?.status, numberCard?.status, expiryCard?.status, cvv?.status]);

  return (
    <NewModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Container>
        <Header>
          <h2>Cadastro de cartão de crédito</h2>
          <CloseButton onClick={onRequestClose}>
            <MdClose
              size='1.5rem'
              color={theme.palette.primary.main}
            />
          </CloseButton>
        </Header>

        <Cards
          number={numberCard?.value}
          expiry={expiryCard?.value}
          cvc={cvv?.value}
          focused={focus}
          name={nameCard?.value}
          placeholders={{ name: 'NOME' }}
          locale={{ valid: 'MM/AA' }}
        />

        <FormCreditCard>
          <InputCustom
            type='tel'
            name='number'
            labelText='Número do cartão'
            value={numberCard?.value || ''}
            onChange={handleNumberCard}
            onBlur={blurNumberCard}
            onFocus={(event) => {
              event.persist();
              setPayloadProperty((prevState) => ({
                ...prevState,
                focus: event.target.id,
              }));
            }}
            hasTouched={numberCard?.touched}
            hasError={numberCard?.status === 'refused'}
            errorMessage='Por favor digite um número de cartão válido'
            requiredMessage='Número do cartão é obrigatório'
            maxLength={19}
          />

          <InputCustom
            type='text'
            name='name'
            labelText='Nome no cartão'
            value={nameCard?.value || ''}
            onChange={handleNameCard}
            onBlur={blurNameCard}
            onFocus={(event) => {
              event.persist();
              setPayloadProperty((prevState) => ({
                ...prevState,
                focus: event.target.id,
              }));
            }}
            hasTouched={nameCard?.touched}
            hasError={nameCard?.status === 'refused'}
            errorMessage='Por favor digite um nome de cartão válido'
            requiredMessage='Nome do cartão é obrigatório'
            maxLength={40}
          />

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gridGap: '16px',
            }}
          >
            <InputCustom
              type='text'
              name='expiry'
              labelText='Vencimento'
              value={expiryCard?.value || ''}
              onChange={handleExpiryCard}
              onBlur={blurExpiryCard}
              onFocus={(event) => {
                event.persist();
                setPayloadProperty((prevState) => ({
                  ...prevState,
                  focus: event.target.id,
                }));
              }}
              hasTouched={expiryCard?.touched}
              hasError={expiryCard?.status === 'refused'}
              errorMessage='Por favor digite um vencimento válido'
              requiredMessage='Vencimento é obrigatório'
              maxLength={5}
            />

            <InputCustom
              type='tel'
              name='cvc'
              labelText='CVV'
              value={cvv?.value || ''}
              onChange={handleCvv}
              onBlur={blurCvv}
              onFocus={(event) => {
                event.persist();
                setPayloadProperty((prevState) => ({
                  ...prevState,
                  focus: event.target.id,
                }));
              }}
              hasTouched={cvv?.touched}
              hasError={cvv?.status === 'refused'}
              errorMessage='Por favor digite um CVV válido'
              requiredMessage='CVV do cartão é obrigatório'
              maxLength={4}
            />
          </div>

          {hasErrorCard && (
            <div>
              <p style={{ color: '#B3261E', margin: 0, fontSize: '1rem' }}>
                Verifique os dados acima, eles podem estar incorretos
              </p>
            </div>
          )}

          {/* <InputCustom
            type='tel'
            name='document'
            labelText='CPF do titular do cartão'
            value={document}
            onChange={(event) => setDocument(maskCpf(event.target.value))}
            // onFocus={}
            maxLength={14}
          /> */}

          <PrimaryButton
            onClick={handleSaveCard}
            disabled={buttonDisabled || hasErrorCard}
          >
            Cadastrar
          </PrimaryButton>
        </FormCreditCard>
      </Container>
    </NewModal>
  );
};

CreditCardModal.propTypes = {
  payloadProperty: PropTypes.object,
  setPayloadProperty: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

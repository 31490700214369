import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../styles/colors';

const BaseFormControl = ({
  children,
  className,
  error = null,
  touched = null,
  mustValidate = true,
}) => {
  const showError = !!error && !!touched;

  return (
    <div className={className}>
      {children}
      {mustValidate && (
        <span className={clsx({ error: true, on: showError })}>
          {error}
        </span>
      )}
    </div>
  );
};
BaseFormControl.propTypes = {
  className: PropTypes.string.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  mustValidate: PropTypes.bool,
};

export const FormControl = styled(BaseFormControl)`
  position: relative;
  margin-bottom: 15px;

  span.error {
    display: inline-block;
    font-family: Myriad-Pro;
    letter-spacing: 0.085em;
    color: #b12626;
    font-size: 13px;
    opacity: 0;

    &.on {
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    span.error {
      display: none;

      &.on {
        display: inline-block;
      }
    }
  }
`;
export const ProspectsFormControl = styled(BaseFormControl)`
  position: relative;
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : 'Myriad-Pro-Condensed'};
  letter-spacing: 0.085em;
  background: ${colors.white};
  outline: none;
  border-radius: 2px;
  font-size: 16px;
  padding: 5px 10px;
  color: ${colors.primary};
  font-weight: 300;
  width: ${(props) => (props.fullwidth ? '100%' : 'auto')};

  &:disabled {
    border: none;
    padding: 0;
    color: ${colors.primary};
  }
`;

export const ContactFormControl = styled(BaseFormControl)`
  position: relative;
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : 'Myriad-Pro-Condensed'};
  letter-spacing: 0.085em;
  background: ${colors.white};
  outline: none;
  border-radius: 2px;
  font-size: 16px;
  padding: 5px 10px;
  color: ${colors.primary};
  font-weight: 300;
  width: ${(props) => (props.fullwidth ? '100%' : 'auto')};

  &:disabled {
    border: none;
    padding: 0;
    color: ${colors.primary};
  }
`;

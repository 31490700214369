import cep from 'cep-promise';

const get = async (cepValue) => {
  return cep(cepValue);
};

const CEPService = {
  get,
};

export default CEPService;

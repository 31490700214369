import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../../theme';

export const StyledLink = styled(Link)`
  color: ${(props) => props.color || theme.palette.primary.main};
  text-decoration: underline;

  ${(props) =>
    props.tag
      ? `
    &:before {
      content: '#';
    }
  `
      : null}

  &:hover {
    text-decoration: underline;
  }
`;

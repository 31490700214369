const allowedTypes = ['string', 'number', 'boolean'];

export const getHandledPayload = (payload) => {
  const finalPayload = {};
  if (!payload instanceof Object) return finalPayload;

  for (const key in payload) {
    const value = payload[key];
    if (value == null) continue;
    if (!allowedTypes.includes(typeof value)) continue;
    if (typeof value === 'string')
      if (!value?.length) continue;
      else finalPayload[key] = value.trim();
    else finalPayload[key] = value;
  }
  return finalPayload;
};

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { useStage } from '../../../hooks/useStage';

import AttachmentsService from '../../../services/attachments/attachments.service';
import { NewModal } from '../../NewModal';

import { ButtonsContainer, Container, UploadButtonContainer } from './styles';
import FileIsLargeModal from '../FileIsLargeModal';
import { SecondaryButton } from '../../SecondaryButton';

export const FiledOCRModal = ({
  isOpen = false,
  setFile = () => {},
  setLoading = () => {},
  onRequestClose = () => {},
  removeAttachment = () => {},
  maxSize = parseInt(import.meta.env.REACT_APP_MAX_UPLOAD_SIZE, 10),
  accept = [],
  continueRegisterWithoutBill = () => {},
  continueFlow = () => {},
}) => {
  const { changePayload } = useStage();
  const [isOpenFileIsLargeModal, setIsOpenFileIsLargeModal] = useState(false);

  const clearStateAndCloseModal = () => {
    removeAttachment();
    setIsOpenFileIsLargeModal(false);
    onRequestClose();
    continueRegisterWithoutBill();
  };

  const saveAttachment = async (event) => {
    try {
      setLoading(true);
      const data = await AttachmentsService.uploadAttachment(
        event.target.files[0]
      );
      changePayload({ attachment: data });
      setFile(event.target.files);
    } catch (err) {
      setFile({});
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (event) => {
    const fileSelected = event.target?.files[0];

    if (!accept.includes(fileSelected?.type)) {
      toast.error('Arquivo não suportado.');
      return;
    }

    if (fileSelected.size >= maxSize) {
      setIsOpenFileIsLargeModal(true);
      return;
    }

    onRequestClose(false);
    setIsOpenFileIsLargeModal(false);
    saveAttachment(event);
  };

  return (
    <>
      <NewModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        isFiledOCR
      >
        <Container>
          <h2>Ops! A leitura do PDF falhou</h2>
          <p>
            Infelizmente nossos robôzinhos não conseguiram ler o PDF anexado.
          </p>

          <ButtonsContainer>
            <UploadButtonContainer>
              <input
                id='file_upload_other_pdf'
                type='file'
                onChange={handleFileUpload}
                accept='application/pdf'
              />
              <label htmlFor='file_upload_other_pdf'>
                Enviar outra conta de luz
              </label>
            </UploadButtonContainer>

            <SecondaryButton onClick={clearStateAndCloseModal}>
              Continuar sem conta de luz
            </SecondaryButton>
          </ButtonsContainer>
        </Container>
      </NewModal>
      <FileIsLargeModal
        isOpen={isOpenFileIsLargeModal}
        handleFileUpload={handleFileUpload}
        handleClose={clearStateAndCloseModal}
        removeAttachment={false}
      />
    </>
  );
};

FiledOCRModal.propTypes = {
  isOpen: PropTypes.bool,
  setFile: PropTypes.func,
  setLoading: PropTypes.func,
  onRequestClose: PropTypes.func,
  continueFlow: PropTypes.func,
  removeAttachment: PropTypes.func,
  maxSize: PropTypes.number,
  accept: PropTypes.array,
  continueRegisterWithoutBill: PropTypes.func,
};

/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Grid } from '@mui/material';
import {
  CustomField,
  FieldWrapper,
  Label,
} from '../../../../../../components/Interface/Form';
import { Heading3 } from '../../../../../../components/Interface/Text';
import adminStores from '../../../../../../store/admin/contractDetails/index.store';
import { translateValidCredentialLocation } from '../../../../../../helpers/translateValidCredentialLocation';
import FlexRowContainer from '../../../../../../componentsStyle/FlexRowContainer';
import { IoReload } from 'react-icons/io5';
import { LocationContext } from '../../../../../../contexts/LocationContext';

const LocationCredentialsData = () => {
  const {
    location: {
      location: { provider_id: providerId },
    },
    locationCredentials: {
      locationCredentials: {
        id: idLocationCredential,
        is_valid_login: isValidLogin,
        is_valid_install_code: isValidInstallCode,
        error_message: errorMessage,
        updated_at: updatedAt,
      },
    },
    providers: { providers },
  } = adminStores();

  const [provider, setProvider] = useState(null);

  useEffect(() => {
    if (providerId) {
      const provider = providers.find((p) => p.id === providerId);
      if (provider)
        setProvider({
          hasValidateLogin: provider.has_validate_login,
          name: provider.name,
        });
    }
  }, [providerId, providers]);

  const verifiedAtDate = useMemo(
    () =>
      new Date(updatedAt).toLocaleDateString('pt-br', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      }),
    [updatedAt]
  );

  const [isSubmiting, setIsSubmiting] = useState(false);

  const { onValidateCredential } = useContext(LocationContext);

  return (
    <Grid
      container
      spacing={1}
      rowSpacing={1}
    >
      <Grid
        item
        xs={12}
      >
        <FlexRowContainer
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            gap: 1,
          }}
        >
          <Heading3>Login e Senha Distribuidora</Heading3>

          {idLocationCredential ? (
            <FlexRowContainer style={{ gap: '1rem' }}>
              {provider?.hasValidateLogin ? (
                <Alert
                  severity='info'
                  style={{ margin: '0 auto' }}
                  className='alert'
                >
                  Útilma verificação: {verifiedAtDate}
                </Alert>
              ) : (
                <Alert
                  severity='warning'
                  style={{ margin: '0 auto' }}
                  className='alert'
                >
                  Não é possivel validar login para {provider?.name}
                </Alert>
              )}

              <Button
                size='small'
                variant='outlined'
                startIcon={<IoReload />}
                color='primary'
                style={{ marginLeft: 8 }}
                disabled={isSubmiting || !provider?.hasValidateLogin}
                onClick={() =>
                  onValidateCredential(
                    {
                      location_credentials: {
                        id: idLocationCredential,
                        is_valid_login: null,
                        is_valid_install_code: null,
                        error_message: null,
                        code: null,
                      },
                    },
                    setIsSubmiting
                  )
                }
              >
                Revalidar
              </Button>
            </FlexRowContainer>
          ) : null}
        </FlexRowContainer>
      </Grid>
      {idLocationCredential ? (
        <>
          <Grid
            item
            xs={12}
            lg={6}
            xl={4}
          >
            <FieldWrapper>
              <Label htmlFor='valid'>Validação acesso:</Label>
              <CustomField
                value={translateValidCredentialLocation(isValidLogin)}
                disabled={true}
                fullwidth
              />
            </FieldWrapper>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            xl={4}
          >
            <FieldWrapper>
              <Label htmlFor='pn'>Validação UC:</Label>
              <CustomField
                value={translateValidCredentialLocation(isValidInstallCode)}
                disabled={true}
                fullwidth
              />
            </FieldWrapper>
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
            xl={4}
          >
            <FieldWrapper>
              <Label htmlFor='pn'>Erro:</Label>
              <span style={{ color: '#446063ab' }}>{errorMessage}</span>
            </FieldWrapper>
          </Grid>
        </>
      ) : (
        <FlexRowContainer style={{ width: '100%' }}>
          <Alert
            severity='warning'
            style={{ width: '100%' }}
          >
            Não foram informados dados de login!
          </Alert>
        </FlexRowContainer>
      )}
    </Grid>
  );
};
export default LocationCredentialsData;

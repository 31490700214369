import { Grid } from '@mui/material';

import { Modal } from '../../../components/NewModal/styles';

import { DropzoneCustom } from '../../../components/DropzoneCustom';
import ExcelRenderer from '../../../utils/excelRenderer';
import theme from '../../../theme';
import Button from '../../../components/Button';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Heading2 } from '../../../components/Interface/Text';

const ModalCreateBulkPromocode = ({ onSubmit, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [standardTableColumns, setStandardTableColumns] = useState([]);

  const dataHandler = (data) => {
    const arrayOfArrays = data.rows;
    const [headers, ...rows] = arrayOfArrays;

    const regexHeader = /promocodes?/gi;
    if (!headers[0].match(regexHeader))
      throw 'A primeira coluna deve-se chamar "Promocodes"';

    try {
      const assembleArrayOfColumns = headers.slice(0, 1).map((el) => {
        return { name: el };
      });
      const filteredRows = rows
        .map((row) => row.slice(0, 1))
        .filter((el) => el.length > 0);

      setRows(filteredRows);
      setColumns(headers);
      setStandardTableColumns(assembleArrayOfColumns);
    } catch (error) {
      setFile('');
      toast.error(error);
      console.error(error);
    }
  };

  const fileHandler = async (files) => {
    try {
      const fileObj = files[0];
      const { type } = fileObj;
      if (
        type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
        throw "O formato do arquivo deve ser 'xlsx'";

      const data = await ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          throw err;
        } else {
          return resp;
        }
      });
      dataHandler(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const getRows = (rows) => {
    try {
      return rows.map((row) => {
        const arr = row.map((cell) => {
          return { value: <p>{cell}</p> };
        });

        return { values: arr };
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      width='800px'
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        spacing={1}
        style={{ padding: '10px 20px' }}
      >
        <Grid
          item
          xs={12}
        >
          <Heading2 align='center'>Criar promocodes em lote</Heading2>
        </Grid>
        <Grid
          item
          justifyContent='center'
          alignItems='center'
          xs={12}
          md={rows.length ? 8 : 12}
        >
          <DropzoneCustom
            maxFiles={1}
            fileHandler={fileHandler}
            acceptedFileTypes={['.xlsx']}
            handlerFileAfterChange={true}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          justifyContent='center'
          alignItems='center'
        >
          {rows.length > 0 && standardTableColumns.length > 0 && (
            <SearchableTable
              columns={standardTableColumns}
              rowsValues={getRows(rows)}
              noItemsText='Nenhum valor encontrado'
              noSearch
              noDownload={true}
              maxHeight='80vh'
            />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-around'
        style={{ margin: '10px 0' }}
      >
        <Button
          text='Cancelar'
          handleClick={onClose}
          color='white'
          borderColor={theme.palette.primary.main}
          outlined
        />
        <Button
          handleClick={() =>
            onSubmit(
              { promocodes: rows.map((row) => row[0]) },
              { setSubmitting: setIsSubmitting }
            )
          }
          disabled={isSubmitting || rows.length === 0}
          textColor={theme.palette.primary.main}
          color={theme.palette.secondary.main}
          text='Cadastrar'
          fontSize='50'
          loading={isSubmitting}
        />
      </Grid>
    </Modal>
  );
};

export default ModalCreateBulkPromocode;

/* eslint-disable no-plusplus */
/* eslint-disable no-var */
import * as XLSX from 'xlsx';

const make_cols_name = (ws, startRow) => {
  var o = [],
    C = XLSX.utils.decode_range(ws['!ref']).e.c + 1;

  for (let i = 0; i < C; ++i) {
    o[i] = { name: getCellValue(ws, i, startRow), key: i };
  }
  return o;
};

const getCellValue = (sheet, col, row) => {
  const cellAddress = XLSX.utils.encode_cell({ c: col, r: row });
  return sheet[cellAddress] ? sheet[cellAddress].v : undefined;
};

const ExcelRenderer = async (file, callback, startRow = 0) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    var rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      var bstr = e.target.result;
      var wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });

      /* Get first worksheet */
      var wsname = wb.SheetNames[0];
      var ws = wb.Sheets[wsname];

      const range = XLSX.utils.decode_range(ws['!ref']);
      const endRow = range.e.r; // Índice da última linha da planilha
      const firstCol = range.s.c; // Índice da primeira coluna da planilha
      const lastCol = range.e.c; // Índice da última coluna da planilha

      const options = {
        header: 1,
        range: `${XLSX.utils.encode_col(firstCol)}${
          startRow + 1
        }:${XLSX.utils.encode_col(lastCol)}${endRow + 1}`,
      };

      /* Convert array of arrays */
      var json = XLSX.utils.sheet_to_json(ws, options);

      var cols = make_cols_name(ws, startRow);

      var data = { rows: json, cols };

      resolve(data);
      return callback(null, data);
    };
    if (file && rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
};

export default ExcelRenderer;

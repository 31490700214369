import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export const Radiobox = ({ name, options, checked, ...rest }) => {
  return (
    <>
      {!!options.length &&
        options.map((option) => (
          <Container key={option.value}>
            <input
              type='radio'
              id={option.value}
              name={name}
              value={option.value}
              checked={checked === option.value}
              {...rest}
            />
            <label htmlFor={option.value}>{option.label}</label>
          </Container>
        ))}
    </>
  );
};

Radiobox.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.string.isRequired,
};

export const PROFILE_TYPES = {
  on_hold: 'on_hold',
  target: 'target',
  typo: 'typo',
  no_profile: 'no_profile',
};

export const PROFILE_TYPES_LABEL = {
  [PROFILE_TYPES.on_hold]: 'On Hold',
  [PROFILE_TYPES.target]: 'Target',
  [PROFILE_TYPES.typo]: 'Typo',
  [PROFILE_TYPES.no_profile]: 'Sem Perfil',
};

export const ADMIN_PROFILE_TYPES_LABEL = {
  on_hold: 'On Hold',
  target: 'Target',
  typo: 'Typo',
  [PROFILE_TYPES.no_profile]: 'Sem Perfil',
};

import { Grid } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import { StyledLink } from '../../components/Interface';
import SearchableTable from '../../components/Interface/SearchableTable';
import { Paragraph } from '../../components/Interface/Text/Paragraph';
import PageView from '../../containers/PageView';
import ContractsService from '../../services/general/contracts.service';

import ContentCard from '../../components/ContentCard';

import {
  ContractStatusLabel,
  getStatusText,
  setCCOrEmissionError,
} from '../../components/ContractStatusLabel';
import LoadMore from '../../components/LoadMore';
import { CONNECTION_TYPES_LABEL } from '../../helpers/connectionTypes';
import { maskCpfOrCnpj } from '../../helpers/form/masks';
import { PAYMENT_METHODS_PTBR } from '../../helpers/paymentMethods';
import {
  PROVIDERS_TYPES,
  PROVIDERS_TYPES_LABEL,
} from '../../helpers/providersTypes';
import { SIGNER_TYPES_LABEL } from '../../helpers/signerTypes';
import { TAG_TYPES_LABEL } from '../../helpers/tagTypes';
import { TOAST_MESSAGES } from '../../helpers/toastMessages';
import { translateValidCredentialLocation } from '../../helpers/translateValidCredentialLocation';

const COLUMNS = [
  { name: 'Contrato' },
  { name: 'Usuário' },
  { name: 'Data do Contrato' },
  { name: 'Data de Assinatura' },
  { name: 'Data de ativação' },
  { name: 'Nome' },
  { name: 'CPF-CNPJ do contratante' },
  { name: 'Email' },
  { name: 'Celular' },
  { name: 'Distribuidora' },
  { name: 'Gerador', align: 'center' },
  { name: 'Volume', align: 'center' },
  { name: 'Valor Mensal', align: 'right' },
  { name: 'Status', align: 'center' },
  { name: 'Obs', align: 'center' },
  { name: 'Perfil', align: 'center' },
  { name: 'Classificação de Perfil', align: 'center' },
  { name: 'Status 2', align: 'center' },
  { name: 'Promocode', align: 'center' },
  { name: 'Canal de atribuição', align: 'center' },
  { name: 'Cidade', align: 'center' },
  { name: 'Descrição', align: 'center' },
  { name: 'Data de cancelamento', align: 'center' }, // stages_cancelled_at
  { name: 'Motivos de Cancelamento', align: 'center' },
  { name: 'Código de Instalação', align: 'center' },
  { name: 'Tipo de conexão', align: 'center' },
  { name: 'Data de login e senha', align: 'center' },
  { name: 'Validação login e senha', align: 'center' },
  { name: 'Validação UC login e senha', align: 'center' },
  { name: 'Erro login e senha', align: 'center' },
  { name: 'Origem U | C', align: 'center' },
  { name: 'Canal de Assinatura', align: 'center' },
  { name: 'Data de upload', align: 'center' },
  { name: 'Tem conta', align: 'center' },
  { name: 'Tag 2', align: 'center' },
  { name: 'OCR Realizado', align: 'center' },
  { name: 'Refinado', align: 'center' },
  { name: 'Data de refino', align: 'center' },
  { name: 'Forma de Pagamento', align: 'center' },
  { name: 'Tipo cartão', align: 'center' },
  { name: 'Origem Mídia', align: 'center' },
  { name: 'Campanha Digital', align: 'center' },
  { name: 'Fonte', align: 'center' },
  { name: 'Campanha ID', align: 'center' },
  { name: 'Palavra chave', align: 'center' },
  { name: 'Formato companha', align: 'center' },
];
const getRows = (contracts) => {
  const setOrigin = (user_origin, location_origin) => {
    try {
      if (!user_origin && !location_origin) return '';
      return `${user_origin || ''} | ${location_origin || ''}`;
    } catch (error) {
      return '';
    }
  };

  const getUploadTimeFromAttachment = (attachment) => {
    try {
      if (!attachment) return '';
      const uploadUnixTime = attachment.split('/')[2].split('-')[0];
      return new Date(+uploadUnixTime);
    } catch (error) {
      return '';
    }
  };
  return contracts.map((contract) => {
    const {
      id,
      user_id,
      name,
      document,
      volume,
      value_cents,
      created_at,
      signed_at,
      promocode,
      attribution_channel,
      city,
      providerBackup,
      provider,
      phone_number,
      user_phone_number,
      email,
      tag,
      notes,
      reason,
      producer,
      install_code,
      connection_type,
      location_credentials_updated_at,
      location_credentials_is_valid_login,
      location_credentials_is_valid_install_code,
      location_credentials_error_message,
      user_origin,
      location_origin,
      type,
      attachment,
      ocr,
      proposal_ready,
      payment_method,
      tag_2,
      activated_at,
      classification_profiles_selected,
      issuer,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_id,
      utm_term,
      utm_content,
      stages_cancelled_at,
      location_stage,
      refined_at,
    } = contract;

    return {
      values: [
        {
          value: (
            <StyledLink
              tag='true'
              to={`/contratos/${id}`}
            >{`${id}`}</StyledLink>
          ),
        },
        {
          value: (
            <StyledLink
              tag='true'
              to={`/usuarios/${user_id}`}
            >{`${user_id}`}</StyledLink>
          ),
        },
        {
          value: (
            <Paragraph>
              {moment(created_at).format('DD/MM/YYYY, HH:mm')}
            </Paragraph>
          ),
        },
        {
          value: (
            <Paragraph>
              {signed_at
                ? moment(signed_at).format('DD/MM/YYYY, HH:mm')
                : 'Não Aplicável'}
            </Paragraph>
          ),
        },
        {
          value: (
            <Paragraph>
              {activated_at
                ? moment(activated_at).format('DD/MM/YYYY')
                : 'Nunca ativado'}
            </Paragraph>
          ),
        },
        {
          value: (
            <StyledLink to={`/usuarios/${user_id}`}>{`${name}`}</StyledLink>
          ),
        },
        {
          value: <Paragraph>{maskCpfOrCnpj(document) || 'Vazio'}</Paragraph>,
        },
        {
          value: <Paragraph>{email || ''}</Paragraph>,
        },
        {
          value: (
            <Paragraph>{phone_number || user_phone_number || ''}</Paragraph>
          ),
        },
        {
          value: (
            <Paragraph>
              {PROVIDERS_TYPES_LABEL[PROVIDERS_TYPES[provider]] ||
                provider?.toUpperCase() ||
                providerBackup?.toUpperCase() ||
                'On Hold'}
            </Paragraph>
          ),
        },
        {
          value: <Paragraph align='center'>{producer}</Paragraph>,
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>{`${parseFloat(volume).toLocaleString(
              'pt-BR'
            )} kWh`}</Paragraph>
          ),
        },
        {
          value: (
            <Paragraph align='center'>
              {`R$ ${parseFloat(value_cents / 100).toLocaleString('pt-BR')}`}
            </Paragraph>
          ),
        },
        {
          value: (
            <ContractStatusLabel contract={{ ...contract, location_stage }} />
          ),
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>
              {setCCOrEmissionError(contract)}
            </Paragraph>
          ),
        },
        {
          value: <>{getStatusText(contract, 'admin', false, true)}</>,
          align: 'center',
        },
        {
          value: <>{classification_profiles_selected}</>,
          align: 'center',
        },
        {
          value: <Paragraph align='center'>{TAG_TYPES_LABEL[tag]}</Paragraph>,
          align: 'center',
        },
        {
          value: <Paragraph align='center'>{promocode}</Paragraph>,
          align: 'center',
        },
        {
          value: <Paragraph align='center'>{attribution_channel}</Paragraph>,
          align: 'center',
        },
        {
          value: <Paragraph align='center'>{city}</Paragraph>,
          align: 'center',
        },
        {
          value: <Paragraph>{notes}</Paragraph>,
          align: 'center',
        },
        {
          value: (
            <Paragraph>
              {stages_cancelled_at
                ? moment(stages_cancelled_at).format('DD/MM/YYYY, HH:mm')
                : 'Não Aplicável'}
            </Paragraph>
          ),
        },
        {
          value: <Paragraph align='center'>{reason}</Paragraph>,
          align: 'center',
        },
        {
          value: <Paragraph align='center'>{install_code}</Paragraph>,
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>
              {CONNECTION_TYPES_LABEL?.[connection_type] || connection_type}
            </Paragraph>
          ),
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>
              {location_credentials_updated_at
                ? moment(location_credentials_updated_at).format(
                    'DD/MM/yyyy, kk:mm'
                  )
                : 'Não Aplicável'}
            </Paragraph>
          ),
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>
              {translateValidCredentialLocation(
                location_credentials_is_valid_login
              )}
            </Paragraph>
          ),
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>
              {translateValidCredentialLocation(
                location_credentials_is_valid_install_code
              )}
            </Paragraph>
          ),
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>
              {location_credentials_error_message}
            </Paragraph>
          ),
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>
              {setOrigin(user_origin, location_origin)}
            </Paragraph>
          ),
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>
              {SIGNER_TYPES_LABEL[type] || 'Email'}
            </Paragraph>
          ),
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>
              {attachment
                ? moment(getUploadTimeFromAttachment(attachment)).format(
                    'DD/MM/yyyy, kk:mm'
                  )
                : 'Não Aplicável'}
            </Paragraph>
          ),
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>{attachment ? 'Sim' : 'Não'}</Paragraph>
          ),
          align: 'center',
        },
        {
          value: <Paragraph align='center'>{tag_2 ? 'Sim' : 'Não'}</Paragraph>,
          align: 'center',
        },
        {
          value: <Paragraph align='center'>{ocr ? 'Sim' : 'Não'}</Paragraph>,
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>
              {proposal_ready ? 'Sim' : 'Não'}
            </Paragraph>
          ),
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>
              {refined_at ? moment(refined_at).format('DD/MM/yyyy') : ''}
            </Paragraph>
          ),
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>
              {payment_method && payment_method !== 'all'
                ? PAYMENT_METHODS_PTBR[payment_method]
                : 'Não cadastrado'}
            </Paragraph>
          ),
          align: 'center',
        },
        {
          value: (
            <Paragraph align='center'>
              {issuer ? _.capitalize(issuer) : 'Vazio'}
            </Paragraph>
          ),
          align: 'center',
        },
        {
          value: <Paragraph align='center'>{utm_source}</Paragraph>,
          align: 'center',
        },
        {
          value: <Paragraph align='center'>{utm_campaign}</Paragraph>,
          align: 'center',
        },
        {
          value: <Paragraph align='center'>{utm_medium}</Paragraph>,
          align: 'center',
        },
        {
          value: <Paragraph align='center'>{utm_id}</Paragraph>,
          align: 'center',
        },
        {
          value: <Paragraph align='center'>{utm_term}</Paragraph>,
          align: 'center',
        },
        {
          value: <Paragraph align='center'>{utm_content}</Paragraph>,
          align: 'center',
        },
      ],
    };
  });
};

const Contracts = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    contracts: [],
    prevY: 0,
  });
  const [loadingg, setLoadingg] = useState(false);
  const [maxContracts, setMaxContracts] = useState(null);
  const [page, setPage] = useState(0);
  const [noMore, setNoMore] = useState(false);

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        setLoadingg(true);
        const { data: resources, count } = await ContractsService.getAll(page);

        if (count) setMaxContracts(count);
        if ((!resources || resources.length === 0) && page === 0) {
          setPageState(() => ({
            loading: false,
            contracts: [],
          }));
          setLoadingg(false);
          setNoMore(true);
          return;
        }
        setPageState((state) => ({
          loading: false,
          contracts: [...state.contracts, ...resources],
        }));
        setLoadingg(false);
      } catch (err) {
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };

    fetchContracts();
  }, [page]);

  const fetchAll = async () => {
    const limit = 4450;

    const timesToRun = maxContracts / limit;

    const numberOfRequests = Math.ceil(timesToRun);

    const contractsPerRequest = Math.ceil(maxContracts / numberOfRequests) + 5;

    const batchSize = 4;

    try {
      const contractss = [];

      // logica para chamar API de contratos pelo numero BatchSize de vezes simultaneamente
      for (let i = 0; i < numberOfRequests; i += batchSize) {
        const batch = [];
        for (let j = 0; j < batchSize && i + j < numberOfRequests; j++) {
          batch.push(ContractsService.getAll(i + j, contractsPerRequest));
        }

        const datas = await Promise.all(batch);

        datas.forEach(({ data }) => contractss.push(...data));
      }

      return getRows(contractss);
    } catch (err) {
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const { loading, hasError, contracts } = pageState;

  return (
    <PageView
      title='Contratos'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
      id='pageview'
    >
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <ContentCard>
            <SearchableTable
              columns={COLUMNS}
              rowsValues={getRows(contracts)}
              dataJson={contracts}
              noItemsText='Nenhum contrato'
              fetchAll={fetchAll}
            />
          </ContentCard>
          {!noMore && (
            <LoadMore
              loadingg={loadingg}
              setPage={setPage}
            />
          )}
        </Grid>
      </Grid>
    </PageView>
  );
};

export default Contracts;

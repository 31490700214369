import connection from '../connections/main';

const mountFiltersQuery = (filters) => {
  const mappedFilters = {};

  if (filters) {
    const { userId } = filters;

    if (userId) {
      mappedFilters.users_id_eq = userId;
    }

    return `?q=${JSON.stringify(mappedFilters)}`;
  }

  return '';
};

const getAll = async () => {
  const { err, data } = await connection.get(`/producerOccupation`);

  if (err) {
    throw err;
  }

  return data;
};

const get = async (id) => {
  const { err, data } = await connection.get(`/producerOccupation/${id}`);

  if (err) {
    throw err;
  }

  return data;
};

const create = async (payload) => {
  console.log(payload);
  const { err, data } = await connection.post(`/producerOccupation`, {
    contract: payload,
  });

  if (err) {
    throw err;
  }

  return data;
};

const update = async (id, payload) => {
  const { err, data } = await connection.patch(`/producerOccupation/${id}`, {
    contract: payload,
  });

  if (err) {
    throw err;
  }

  return data;
};

const CustomerSuccessService = {
  getAll,
  get,
  create,
  update,
};

export default CustomerSuccessService;

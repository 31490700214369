import { getDoc } from '../helpers/vanilla';

let inicialClassName;
let iFrameClassName;

const getZendeskSelector = () => {
  const allIframes = document.querySelectorAll('iframe');
  for (const item of allIframes) {
    const { title } = item;
    if (
      title?.includes('Botão para abrir a janela de mensagens') ||
      title?.includes('Button to launch messaging window')
    )
      return item;
  }
};

export const setupZendesk = (shouldHide = true) =>
  new Promise((resolve) => {
    try {
      const zendeskIframe = getZendeskSelector();

      if (!zendeskIframe) throw { message: 'not loaded yet' };
      iFrameClassName = zendeskIframe.className;

      const zendeskDocument = getDoc(zendeskIframe);
      if (!zendeskDocument) throw { message: 'not loaded yet' };

      if (shouldHide) {
        zendeskIframe.className = null;
        zendeskIframe.hidden = true;
      } else {
        if (inicialClassName) {
          zendeskIframe.className = iFrameClassName;
          zendeskIframe.hidden = false;
        }
      }

      return resolve();
    } catch (error) {
      return setTimeout(() => setupZendesk(shouldHide), 0);
    }
  });

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import PropTypes from 'prop-types';
import { colors } from '../../../styles/colors';
import theme from '../../../theme';

const BaseStatusToggle = ({
  className,
  handleChange,
  labelText = '',
  active = false,
  isSubmitting = false,
  readOnly = false,
  statusColors = undefined,
}) => {
  const toggleSwitchChange = () => {
    if (!isSubmitting && !readOnly) handleChange();
  };

  return (
    <Toogle
      className={className.concat(
        active ? ' active' : '',
        readOnly ? ' readOnly' : ''
      )}
    >
      <span className='toggle-label'>{labelText}</span>
      <div
        className='toggle-control'
        role='button'
        onClick={toggleSwitchChange}
        tabIndex={0}
        aria-hidden
      >
        <span className='toggle-switch' />
      </div>
    </Toogle>
  );
};

BaseStatusToggle.propTypes = {
  className: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  labelText: PropTypes.string,
  active: PropTypes.bool,
  statusColors: PropTypes.arrayOf(PropTypes.string),
  isSubmitting: PropTypes.bool,
  readOnly: PropTypes.bool,
};

const Toogle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-right: 20px;
  width: fit-content;
`;

export const StatusToggle = styled(BaseStatusToggle)`
  display: inline-block;
  &.active {
    .toggle-control {
      background-color: ${(props) =>
        lighten(0.25)(
          (props.statusColors && props.statusColors[0]) || colors.secondary
        )};
      float: right;
    }

    .toggle-switch {
      left: calc(50% + 0.5px);
      background-color: ${(props) =>
        (props.statusColors && props.statusColors[0]) || colors.secondary};
    }
  }

  &.readOnly {
    opacity: 0.5;
  }

  .toggle {
    &-label {
      display: block;
      transition: all 0.2s ease;
      font-size: 12px;
      color: ${theme.palette.black.main};
      text-align: center;
    }

    &-control {
      cursor: pointer;
      transition: all 0.2s ease;
      position: relative;
      outline: none;
      width: 52px;
      height: 26px;
      background-color: ${(props) =>
        (props.statusColors && props.statusColors[1]) || colors.disabled};
      border-radius: 26px;
      float: right;
    }

    &-switch {
      transition: all 0.2s ease;
      position: absolute;
      background-color: ${(props) =>
        lighten(0.25)(
          (props.statusColors && props.statusColors[1]) || colors.disabled
        )};
      width: 50%;
      height: 100%;
      top: 0px;
      left: -0.5px;
      border-radius: 50%;
      border: 1.5px solid #fff;
    }
  }
`;

const transformBytes = (bytes) => {
  if (bytes < 1000000) {
    return `${(bytes / 1000).toFixed(2)} KB`;
  } else if (bytes < 1000000000) {
    return `${(bytes / 1000000).toFixed(2)} MB`;
  } else {
    return `${(bytes / 1000000000).toFixed(2)} GB`;
  }
};
export default transformBytes;

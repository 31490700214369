import { createContext } from 'react';
import theme from '../theme';

export const ThemeContext = createContext({
  navbar_bg_color: theme.palette.primary.main,
  navbar_text_color: theme.palette.white.main,
  navbar_text_color_hover: theme.palette.green.main,
  navbar_text_color_active: theme.palette.green.main,
  navbar_separator: theme.palette.white.main,
  page_user_background: theme.palette.gray.light,
  page_user_text_color: theme.palette.primary.main,
  text_color: theme.palette.black.main,
});

import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;

  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-gap: 20px;
`;

import * as Yup from 'yup';
import { formErrors } from '../../../helpers/form/errors';
import { formValidations } from '../../../helpers/form/validations';

const UserFormSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, formErrors.maxLength(50))
    .required(formErrors.required),
  email: Yup.string()
    .email(formErrors.invalidEmail)
    .required(formErrors.required),
  phone_number: formValidations.phoneNumber().required(formErrors.required),
  // newsletter: Yup.bool().required(formErrors.required),
});

export default UserFormSchema;

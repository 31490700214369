import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from './styles';

export const NewModalFull = ({ children, ...rest }) => {
  return <Modal {...rest}>{children}</Modal>;
};

NewModalFull.propTypes = {
  children: PropTypes.node.isRequired,
};

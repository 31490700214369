import styled from 'styled-components';
import theme from '../../../theme';
import LogoBg from '../../../assets/images/logo/logo-bg.svg';

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbfdff;

  @media (min-width: 768px) {
    background: ${theme.palette.gray.main};
    background-image: url(${LogoBg});
    background-repeat: no-repeat;
    background-size: 50vh;
  }
`;

export const Content = styled.div`
  img {
    display: block;
    width: 200px;
    height: 64.68px;
    margin: 0 auto;
  }

  h1 {
    margin: 24px 0;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    color: ${theme.palette.primary.main};
  }

  h2 {
    margin: 18px 0;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    color: ${theme.palette.gray.main};
  }

  > button {
    height: 48px;
  }
`;

export const AnchorContainer = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    color: ${theme.palette.primary.main};
    font-size: 1rem;
    font-weight: 700;
    text-decoration: none;
  }
`;

export const Card = styled.div`
  width: 648px;
  padding: 48px;
  background: #fbfdff;
  border-radius: 8px;

  @media (min-width: 992px) {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  }
`;

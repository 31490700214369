/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import ContentCard from '../../../components/ContentCard';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { Paragraph } from '../../../components/Interface/Text';
import PageView from '../../../containers/PageView';

import { TOAST_MESSAGES } from '../../../helpers/toastMessages';
import GradientButton from '../../../components/GradientButton';
import ProducersForm from '../../../components/Forms/ProducersForm';
import ProvidersForm from '../../../components/Forms/ProvidersForm';
// SERVICES
import CompaniesService from '../../../services/general/companies.service';
import ProducersService from '../../../services/administrator/producers.service';
import AdminProvidersService from '../../../services/administrator/providers.service';

import { useAdminProvidersStore } from '../../../store/admin/providers.store';
import FlexRowContainer from '../../../componentsStyle/FlexRowContainer';
import Alerts from '../../../components/Alerts';
import theme from '../../../theme';
import ModalMassiveDiscount from './modalMassiveDiscount';
import { useAuthStore } from '../../../store';

const PROVIDERSCOLUMNS = [
  { name: 'Id' },
  { name: 'Nome' },
  { name: 'Gerador Padrão', align: 'left' },
  { name: 'CIP', align: 'left' },
];

const COLUMNS = [
  { name: 'Id' },
  { name: 'Nome' },
  { name: 'Capacidade', align: 'left' },
  { name: 'Volume Rateio', align: 'left' },
  { name: 'Preco Piso', align: 'left' },
  { name: 'Desconto (%)', align: 'left' },
  { name: 'Desconto Promocode (%)', align: 'left' },
  { name: 'Distribuidora', align: 'left' },
];

function Generators() {
  const { isSuperAdmin } = useAuthStore.getState().userAuth;
  const navigate = useNavigate();
  const { setProviders } = useAdminProvidersStore();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    generators: [],
    providers: [],
    companies: [],
    alerts: [],
  });
  const [isOpenModalMassiveDiscount, setIsOpenModalMassiveDiscount] =
    useState(false);

  const [editing, setEditing] = useState({
    open: false,
    type: 'producer',
    method: null,
    values: null,
  });

  const [loadingg, setLoadingg] = useState(false);
  const [generalLoading, setGeneralLoading] = useState({
    generators: true,
    providers: true,
    companies: true,
  });
  const [reload, setReload] = useState(true);

  const handleOpen = (type) => {
    if (!type) throw 'Type is required';
    setEditing((state) => ({ ...state, open: true, type, method: 'post' }));
  };

  const handleClose = () => {
    setEditing((state) => ({
      ...state,
      open: false,
      type: null,
      method: null,
      values: null,
    }));
  };

  const handleOpenEditingUpdating = (type) => {
    if (!type) throw 'Type is required';
    setEditing((state) => ({ ...state, open: true, type, method: 'post' }));
  };

  const handleCloseEditingUpdating = () => {
    setEditing((state) => ({
      ...state,
      open: false,
      type: null,
      method: null,
      values: null,
    }));
  };

  const handleEdit = async (id, type) => {
    try {
      switch (type) {
        case 'provider':
          try {
            const providerToEdit = providers.find((el) => {
              return el.id === id;
            });
            setEditing((state) => ({
              ...state,
              values: providerToEdit,
            }));
          } catch (error) {
            console.log('file: index.js || line 114 || error', error);
          }
          break;
        case 'producer':
          try {
            const generatorToEdit = generators.find((el) => {
              return el.id === id;
            });
            const selectedCompany = pageState.companies.find((companies) => {
              return companies.id === generatorToEdit.company_id;
            });
            if (!generatorToEdit.final_date) {
              Object.assign(generatorToEdit, {
                final_date: new Date().toISOString(),
              });
            }
            if (selectedCompany?.name)
              Object.assign(generatorToEdit, {
                company: selectedCompany.name,
              });

            setEditing((state) => ({
              ...state,
              values: generatorToEdit,
            }));
          } catch (error) {
            console.log('file: index.js || line 140 || error', error);
          }
          break;

        default:
          throw 'Invalid type';
      }
      handleOpenEditingUpdating(type);
    } catch (error) {
      console.log('file: index.js || line 106 || error', error);
      toast.error(error);
    }
  };

  const getProvidersRows = (providers) => {
    return providers.map(({ id, name, producer, cip }, index) => ({
      values: [
        {
          value: <Paragraph>{id || index + 1}</Paragraph>,
        },
        {
          value: (
            <Inner style={{ display: 'flex', alignItems: 'center' }}>
              <Paragraph>{name}</Paragraph>
              <EditIcon
                id='editIcon'
                onClick={() => handleEdit(id, 'provider')}
                style={{ color: theme.palette.primary.main }}
              />
            </Inner>
          ),
        },
        {
          value: <Paragraph>{producer} </Paragraph>,
          align: 'center',
        },
        {
          value: (
            <Paragraph>
              {new Intl.NumberFormat('pt-br', {
                style: 'currency',
                currency: 'BRL',
              }).format(cip)}
            </Paragraph>
          ),
          align: 'center',
        },
      ],
    }));
  };

  const getRows = (generators) =>
    generators.map(
      (
        {
          id,
          name,
          volume,
          provider,
          volume_forecast,
          price_floor,
          discount,
          promo_discount,
        },
        index
      ) => ({
        values: [
          {
            value: <Paragraph>{id || index + 1}</Paragraph>,
          },
          {
            value: (
              <Inner style={{ display: 'flex', alignItems: 'center' }}>
                <Paragraph>{name}</Paragraph>
                <EditIcon
                  id='editIcon'
                  onClick={() => handleEdit(id, 'producer')}
                  style={{ color: theme.palette.primary.main }}
                />
              </Inner>
            ),
          },

          {
            value: (
              <Paragraph>
                {volume_forecast ? volume_forecast.toLocaleString('pt-BR') : 0}{' '}
                kWh
              </Paragraph>
            ),
            align: 'center',
          },
          {
            value: (
              <Paragraph>
                {volume ? volume.toLocaleString('pt-BR') : 0} kWh
              </Paragraph>
            ),
            align: 'center',
          },
          {
            value: (
              <Paragraph>
                {price_floor
                  ? typeof price_floor === 'string'
                    ? parseFloat(price_floor)
                        .toFixed(6)
                        .toString()
                        .replaceAll('.', ',')
                    : price_floor.toFixed(6).toString().replaceAll('.', ',')
                  : '-'}
              </Paragraph>
            ),
            align: 'center',
          },

          {
            value: (
              <Paragraph>
                {discount
                  ? `${parseFloat(discount).toFixed(2).replace('.', ',')}%`
                  : '-'}
              </Paragraph>
            ),
            align: 'center',
          },

          {
            value: (
              <Paragraph>
                {promo_discount
                  ? `${parseFloat(promo_discount)
                      .toFixed(2)
                      .replace('.', ',')}%`
                  : '-'}
              </Paragraph>
            ),
            align: 'center',
          },
          {
            value: <Paragraph>{provider || '-'} </Paragraph>,
            align: 'center',
          },
        ],
      })
    );

  useEffect(() => {
    if (reload) {
      const fetchGenerators = async () => {
        try {
          const resources = await ProducersService.getAll();
          setPageState((state) => ({
            ...state,
            generators: resources,
          }));
          setGeneralLoading((state) => ({ ...state, generators: false }));
        } catch (error) {
          setPageState((state) => ({
            ...state,
            loading: false,
            hasError: true,
          }));
          setGeneralLoading((state) => ({ ...state, generators: false }));
        }
      };
      const fetchProviders = async () => {
        try {
          const providers = await AdminProvidersService.getAll();
          setPageState((state) => ({
            ...state,
            hasError: false,
            ...providers,
          }));
          setGeneralLoading((state) => ({ ...state, providers: false }));
        } catch (error) {
          setPageState((state) => ({
            ...state,
            loading: false,
            hasError: true,
          }));
          setGeneralLoading((state) => ({ ...state, providers: false }));
        }
      };
      const fetchCompanies = async () => {
        try {
          const flora = { id: 0, name: 'Flora' };
          const companies = await CompaniesService.getAll();
          companies.push(flora);
          setPageState((state) => ({
            ...state,
            hasError: false,
            companies,
          }));
          setGeneralLoading((state) => ({ ...state, companies: false }));
        } catch (error) {
          setPageState((state) => ({
            ...state,
            loading: false,
            hasError: true,
          }));
          setGeneralLoading((state) => ({ ...state, companies: false }));
        }
      };

      Promise.all([fetchProviders(), fetchGenerators(), fetchCompanies()]).then(
        () => setReload(!reload)
      );
    }
  }, [reload]);

  useEffect(() => {
    const pageStateLoadingValues = Object.values(generalLoading);
    const hasLoaded = !pageStateLoadingValues.includes(true);

    if (hasLoaded)
      setPageState((state) => ({
        ...state,
        loading: false,
      }));
  }, [generalLoading]);

  useEffect(() => setProviders(pageState.providers), [pageState.providers]);

  const onSubmit = async (data, { setSubmitting }) => {
    try {
      setLoadingg(true);
      switch (type) {
        case 'provider':
          const { id: providerId, ...payload } = data;

          if (values) {
            const updatedProviders = await AdminProvidersService.update(
              providerId,
              payload
            );

            setPageState((state) => ({
              ...state,
              providers: updatedProviders,
            }));

            setReload(!reload);
            toast.success(TOAST_MESSAGES.SUCCESS);
          } else toast.error('Ocorreu um erro desconhecido');
          break;
        case 'producer':
          try {
            if (values) {
              const wasChangedProvider =
                values.provider_id !== data.provider_id;
              await ProducersService.updateOne({ ...data, wasChangedProvider });
              const selectedProducerIndex = generators.findIndex(
                (producer) => producer?.id === data?.id
              );
              generators[selectedProducerIndex] = { ...data };
              setReload(!reload);
            } else {
              const newProducer = await ProducersService.create({ ...data });
              generators.push(newProducer);
            }

            toast.success(TOAST_MESSAGES.SUCCESS);
          } catch (error) {
            console.log('file: index.js || line 363 || error', error);
            toast.error('Ocorreu um erro desconhecido');
          }
          break;
      }
    } catch (err) {
      console.log(err);
      toast.error(TOAST_MESSAGES.ERROR);
      const { error: msg, errors } = err.response.data;

      if (errors) {
        const text = Object.keys(errors)
          .map((field) => [field, errors[field].join('\n')])
          .map((partial) => partial.join(' '))
          .join('\n');
      }
    } finally {
      setLoadingg(false);
      handleClose();
      handleCloseEditingUpdating();
    }
  };
  const setEditingForm = () => {
    try {
      const { type, method, values } = editing;

      if (!type) return;

      switch (type) {
        case 'provider':
          return (
            <ProvidersForm
              onSubmit={onSubmit}
              handleClose={handleClose}
              providers={providers}
              producers={generators}
              loadingg={loadingg}
              values={values}
              setLoadingg={setLoadingg}
              setReload={setReload}
              reload={reload}
            />
          );
        case 'producer':
          return (
            <ProducersForm
              onSubmit={onSubmit}
              handleClose={handleClose}
              providers={providers}
              companies={companies}
              loadingg={loadingg}
              values={values}
              setLoadingg={setLoadingg}
              setReload={setReload}
              reload={reload}
            />
          );

        default:
          break;
      }
    } catch (error) {
      console.log('file: index.js || line 404 || error', error);
    }
  };

  const { loading, hasError, generators, providers, companies, alerts } =
    pageState;

  const { open, type, values } = editing;

  const onCloseMassiveDiscountModal = () => {
    setIsOpenModalMassiveDiscount(false);
  };

  try {
    return (
      <>
        <Alerts alerts={alerts} />
        <PageView
          title='Gestão de Oferta'
          loading={loading}
          hasError={hasError}
          navigate={navigate}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <FlexRowContainer style={{ gap: '1rem', marginBottom: 20 }}>
                <GradientButton
                  paddingg='10px 30px'
                  margin-left='10px'
                  text='Ativação massiva de Geradores'
                  handleClick={() => navigate('/GeneratormassActivation')}
                  rectangle
                />

                <GradientButton
                  paddingg='10px 30px'
                  margin-left='10px'
                  text='Gerenciar cidades'
                  handleClick={() => navigate('/cidades')}
                  rectangle
                />

                {isSuperAdmin && (
                  <GradientButton
                    paddingg='10px 30px'
                    margin-left='10px'
                    text='Alterar descontos em lote'
                    handleClick={() => setIsOpenModalMassiveDiscount(true)}
                    rectangle
                  />
                )}
              </FlexRowContainer>
              <ContentCard>
                <SearchableTable
                  columns={PROVIDERSCOLUMNS}
                  rowsValues={getProvidersRows(providers)}
                  noItemsText='Nenhuma Distribuidora'
                  rerender={reload}
                  dataIsComplete
                />
              </ContentCard>
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: '2rem',
              marginBottom: '2rem',
              border: '1px solid black',
            }}
          />
          <Section>
            <GradientButton
              text=''
              type='button'
              handleClick={() => handleOpen('producer')}
            >
              Novo Gerador
            </GradientButton>
          </Section>
          <div style={{ marginTop: '1rem' }} />
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <ContentCard>
                <SearchableTable
                  columns={COLUMNS}
                  rowsValues={getRows(generators)}
                  noItemsText='Nenhum gerador'
                  rerender={reload}
                  dataIsComplete
                />
              </ContentCard>
            </Grid>
          </Grid>
          <div>
            <Modal
              aria-labelledby='transition-modal-title'
              aria-describedby='transition-modal-description'
              open={open}
              onClose={handleClose}
              closeAfterTransition
            >
              <div
                style={{
                  width: '100vw',
                  height: '100vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Fade in={open}>
                  <ModalDiv>{open && type && setEditingForm()}</ModalDiv>
                </Fade>
              </div>
            </Modal>
          </div>
          {isOpenModalMassiveDiscount && (
            <ModalMassiveDiscount onClose={onCloseMassiveDiscountModal} />
          )}
        </PageView>
      </>
    );
  } catch (error) {
    console.log('file: index.js || line 525 || error', error);
  }
}

export default Generators;

const Inner = styled.div`
  #editIcon {
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
`;

const Section = styled.section`
  display: flex;
  justify-content: flex-end;
  button {
    :hover {
      opacity: 0.7;
    }
  }
`;

const ModalDiv = styled.div`
  background-color: white;
  padding: 3rem;
  border-radius: 2rem;
`;

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import PageView from '../../../containers/PageView';
import MassProducerService from '../../../services/administrator/ManagementBases/massProducer.service';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { StyledLink } from '../../../components/Interface';
import { Paragraph } from '../../../components/Interface/Text/Paragraph';

import ContentCard from '../../../components/ContentCard';
import GradientButton from '../../../components/GradientButton';
import { DefaultExcelRenderer } from '../../../components/ExcelRenderer';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';

const COLUMNS = [{ name: 'Contrato' }, { name: 'Gerador' }];

const COLUMNREGISTEREDPRODUCERS = [{ name: 'Geradores Cadastrados' }];

const InvoicesCheck = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    draft: [],
    all: [],
  });

  const [registeredProducers, setRegisteredProducers] = useState([]);
  const [roughExcelData, setRoughExcelData] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [message, setMessage] = useState('');

  const desiredColumns = ['Contrato', 'Gerador'];

  const getRowsRegisteredProducers = (regisProducers) => {
    return regisProducers.map(({ name }) => ({
      values: [
        {
          value: <Paragraph>{name}</Paragraph>,
        },
      ],
    }));
  };

  const getRows = (producers) => {
    return producers.map(({ Contrato, Gerador }) => ({
      values: [
        {
          value: (
            <StyledLink
              tag='true'
              to={Contrato ? `/contratos/${Contrato}` : '/checkFaturamento'}
            >{`${Contrato}`}</StyledLink>
          ),
        },
        {
          value: <Paragraph>{Gerador || '-'}</Paragraph>,
        },
      ],
    }));
  };

  useEffect(() => {
    const fetchAll = async () => {
      const data = await MassProducerService.getAllGenerators();
      setRegisteredProducers(data);
      setPageState((state) => ({
        ...state,
        loading: false,
        hasError: false,
      }));
    };
    fetchAll();
  }, []);

  const onSubmit = async (el) => {
    try {
      await MassProducerService.updateAllGenerators(processedData);
      toast.success(TOAST_MESSAGES.SUCCESS);
    } catch (error) {
      let errMessage = 'Houve um erro, por favor revise suas informações';
      if (error.data[1] === 'Contract Not Found') {
        errMessage = 'Alguns contratos não existem, por favor revisar';
        setProcessedData(error.data[0]);
        setMessage('Por favor revisar os seguintes contratos: ');
      }
      toast.error(errMessage);
    }
  };

  useEffect(() => {
    let finalData = roughExcelData.filter(
      ({ Contrato, Gerador }) => Contrato || Gerador
    );
    const errors = [];
    for (const item of finalData) {
      const { Gerador } = item;
      if (!registeredProducers.find((register) => register.name === Gerador))
        errors.push(item);
    }

    if (!errors.length) {
      finalData = finalData.map((item) => {
        const { Gerador } = item;
        item.GeradorId = registeredProducers.find((p) => p.name === Gerador).id;
        return item;
      });
    }

    const message = errors.length
      ? 'Os seguintes contratos possuem geradores não cadastrados:'
      : 'Pronto para envio';
    setMessage(message);

    const handledData = errors?.length ? errors : finalData;

    setProcessedData(handledData);
  }, [roughExcelData]);

  const { loading, hasError } = pageState;

  return (
    <PageView
      title='Ativação em Massa de Geradores'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Paragraph
        align='center'
        marginBottom='10px'
      >
        Upload de Contratos, com o respectivo gerador que deseja atualizar:
      </Paragraph>
      <DefaultExcelRenderer
        desiredColumns={desiredColumns}
        // handleData={rowsHandler(data)}
        isFinalDataObject
        setAllData={(newData) => setRoughExcelData(newData)}
      />

      <Grid
        item
        xs={12}
        style={{ marginBottom: '5rem' }}
      >
        <ContentCard>
          <SearchableTable
            columns={COLUMNREGISTEREDPRODUCERS}
            rowsValues={getRowsRegisteredProducers(registeredProducers)}
            noItemsText='Nenhum Gerador Cadastrado'
            dataIsComplete
          />
        </ContentCard>
      </Grid>
      {processedData.length > 0 && <h1>{message}</h1>}
      <Grid
        item
        xs={12}
      >
        <ContentCard>
          <SearchableTable
            columns={COLUMNS}
            rowsValues={getRows(processedData)}
            dataIsComplete
          />
        </ContentCard>
      </Grid>
      {message !==
        'Os seguintes contratos possuem geradores não cadastrados:' &&
        processedData.length > 0 && (
          <div style={{ marginTop: '3rem' }}>
            <GradientButton
              text='Atualizar base'
              handleClick={() => onSubmit()}
            />
          </div>
        )}
    </PageView>
  );
};

export default InvoicesCheck;

import connection from '../connections/main';

const getAll = async (page = 0, limit = 500) => {
  let { err, data } = await connection.get(
    `/losts?page=${page.toString()}&limit=${limit.toString()}`
  );

  if (err) {
    throw err;
  }

  return Array.isArray(data) ? data : [];
};

const lostsService = {
  getAll,
};

export default lostsService;

const isDocumentOcrComplete = (documentOcr) => {
  if (!documentOcr) return false;
  const allowedLenghts = [11, 14];
  const document = documentOcr.match(/\d/g);
  return allowedLenghts.includes(document?.length);
};

export const unpreparedIsSignerFieldDisabled = (field, stagePayload) => {
  const { options, document_ocr } = stagePayload;
  const { isTheHolder, ocrSuccess } = options || {};
  const fieldObject = stagePayload?.[field];
  const { status } = fieldObject || {};
  if (status !== 'accepted') return false;

  switch (field) {
    case 'document':
      return ocrSuccess && isDocumentOcrComplete(document_ocr);
  }
  return !!isTheHolder;
};

export const getSignerValue = (signer) => {
  const { type } = signer;

  const isEmail = type === 'email';
  const valueFromSigner = signer[isEmail ? 'email' : 'phone_number'];

  return valueFromSigner || '';
};

import { randomNumber } from '../helpers/numbers';
import loadScript from './importScript';

let IuguLib, utils;

const setupIugu = async (errorCount = 0) => {
  try {
    await loadScript('Iugu', 'https://js.iugu.com/v2');
    IuguLib = window.Iugu;

    IuguLib.setAccountID(import.meta.env.REACT_APP_IUGU_ACCOUNT_ID);

    IuguLib.setTestMode(import.meta.env.REACT_APP_IUGU_TEST_MODE);

    if (import.meta.env.REACT_APP_ENV === 'dev') IuguLib.setTestMode(true);

    IuguLib.setup();
    utils = IuguLib?.utils;
  } catch (error) {
    const milliseccondsToWait = randomNumber(
      1000 * 60 + (2 * errorCount + 1),
      1000 * 60 + (2 * errorCount + 1) * 2
    );

    return setTimeout(() => setupIugu((errorCount += 1)), milliseccondsToWait);
  }
};

export { utils, setupIugu, IuguLib };

import { Modal, CircularProgress, Grid, Input, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import Select from 'react-select';
import { MdSave } from 'react-icons/md';

import { StatusToggle } from '../../../../../components/Interface';
import { ModalConfirmation } from '../../../../../components/Interface/ModalConfirmation';
import { Heading2, Paragraph } from '../../../../../components/Interface/Text';
import {
  ADMIN_CONTRACT_STATUS_TYPES_PTBR,
  CONTRACT_STATUS_TYPES,
} from '../../../../../helpers/contractStatusTypes';
import { colors } from '../../../../../styles/colors';
import { TOAST_MESSAGES } from '../../../../../helpers/toastMessages';
import { proposalCanBeReady } from '../../../../../helpers/location';
import { ContractStatusLabel } from '../../../../../components/ContractStatusLabel';
import IdsForm from '../../../../../components/Forms/IdsForm';
import GradientButton from '../../../../../components/GradientButton';
import UsersService from '../../../../../services/general/users.service';
import { useAuthStore } from '../../../../../store';
import ContractsAdminService from '../../../../../services/administrator/contractDetails.service';

import FlexRowContainer from '../../../../../componentsStyle/FlexRowContainer';
import FlexColContainer from '../../../../../componentsStyle/FlexColContainer';

import { FieldWrapper, Label } from '../../../../../components/Interface/Form';
import ContractStatusToggles from './ContractStatusToggles';
import { setOptionsForDropdownlist } from '../../../../../helpers/form';
import { PROFILE_TYPES_LABEL } from '../../../../../helpers/profileTypes';
import { TAG_TYPES_LABEL } from '../../../../../helpers/tagTypes';
import MultiSelect from '../../../../../components/MultiSelect';
import transformClassificationsProfilesToOptions from '../../../../../helpers/transformClassificationsProfilesToOptions';
import adminStores from '../../../../../store/admin/contractDetails/index.store';
import { updateAdminStore } from '../../../../../store/admin/contractDetails/helpers';
import { InputWithLoadindSaving } from '../../../../../components/InputWithLoadingSaving';
import theme from '../../../../../theme';

const availableOptions = [
  'pending_activation',
  'pending_transfer',
  'pending_cancel',
  'cancelled',
];
const statusToShow = [...availableOptions, 'active'];
const statusOptions = availableOptions.reduce((option, value) => {
  option.push({ value, label: ADMIN_CONTRACT_STATUS_TYPES_PTBR[value] });
  return option;
}, []);

const ContractStatusSection = () => {
  const navigate = useNavigate();
  const userAuthId = useAuthStore((state) => state.userAuth?.id);

  const {
    contract: { contract },
    location: { location },
    credential: { credential },
    locationClassificationProfiles: { locationClassificationProfiles },
    classificationProfiles: { classificationProfiles },
    attributionChannel: { attributionChannel },
    vwAttributionChannel: { vwAttributionChannel },
  } = adminStores();

  const [id, setId] = useState(null);

  const [modalActivationIsOpen, setModalActivationIsOpen] = useState(false);
  const [modalActiveContractIsOpen, setModalActiveContractIsOpen] =
    useState(false);
  const [modalDeleteContractIsOpen, setModalDeleteContractIsOpen] =
    useState(false);
  const [isDeletingContract, setIsDeletingContract] = useState(false);

  const [hasCredential, sethasCredential] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(location);

  const tagOptions = setOptionsForDropdownlist({ TAG_TYPES_LABEL });
  const profileOptions = setOptionsForDropdownlist({ PROFILE_TYPES_LABEL });
  const [oldTag, setOldTag] = useState(TAG_TYPES_LABEL[location.tag]);
  const [oldProfile, setOldProfile] = useState(
    PROFILE_TYPES_LABEL[location.profile]
  );

  const [isSubmitting, setIsSubmiting] = useState({});
  const [isError, setIsError] = useState({});

  const [notes, setNotes] = useState(location.notes);
  const [attributionChannelValue, setAttributionChannelValue] = useState(
    attributionChannel.attribution_channel
  );

  const [classificationProfilesOptions, setClassificationProfilesOptions] =
    useState(classificationProfiles);

  const [
    classificationProfilesOptionsSelected,
    setClassificationProfilesOptionsSelected,
  ] = useState(null);

  const updateLocation = async (data, showToastSuccess = true) => {
    const [key] = Object.entries(data)[0];
    try {
      setIsSubmiting((prevState) => ({ ...prevState, [key]: true }));

      const locations = { id: location.id, ...data };
      const payload = { locations };

      const dataUpdated = await ContractsAdminService.update(payload);
      updateAdminStore(dataUpdated);

      if (showToastSuccess) toast.success(TOAST_MESSAGES.SUCCESS);
    } catch (error) {
      toast.error(TOAST_MESSAGES.UNKNOWN_ERROR);
    } finally {
      setIsSubmiting((prevState) => ({ ...prevState, [key]: false }));
    }
  };

  const updateContract = async (data, showToastSuccess = true) => {
    const [key] = Object.entries(data)[0];
    try {
      setIsSubmiting((prevState) => ({ ...prevState, [key]: true }));

      const contracts = { id: contract.id, ...data };
      const payload = { contracts };

      const dataUpdated = await ContractsAdminService.update(payload);
      updateAdminStore(dataUpdated);

      if (showToastSuccess) toast.success(TOAST_MESSAGES.SUCCESS);
    } catch (error) {
      toast.error(TOAST_MESSAGES.UNKNOWN_ERROR);
    } finally {
      setIsSubmiting((prevState) => ({ ...prevState, [key]: false }));
    }
  };

  const updateAttributionChannels = async (data, showToastSuccess = true) => {
    const [key] = Object.entries(data)[0];
    try {
      setIsSubmiting((prevState) => ({ ...prevState, [key]: true }));

      const attribution_channels = { id: attributionChannel.id, ...data };
      const payload = { attribution_channels };

      const dataUpdated = await ContractsAdminService.update(payload);
      updateAdminStore(dataUpdated);

      if (showToastSuccess) toast.success(TOAST_MESSAGES.SUCCESS);
    } catch (error) {
      toast.error(TOAST_MESSAGES.UNKNOWN_ERROR);
    } finally {
      setIsSubmiting((prevState) => ({ ...prevState, [key]: false }));
    }
  };

  const hasToActiveContract = () =>
    contract.status === CONTRACT_STATUS_TYPES.PENDING_ACTIVATION;

  useEffect(() => {
    sethasCredential(!!credential);
  }, [credential]);

  useEffect(() => {
    setSelectedLocation(location);
    setOldTag(TAG_TYPES_LABEL[location.tag]);
    setOldProfile(PROFILE_TYPES_LABEL[location.profile]);

    const contractId = contract?.id;
    const userId = location?.user_id;
    const locationId = location?.id;
    setId({ userId, contractId, locationId });
  }, [contract, location]);

  const locationFullfilled = () => {
    const { producer_id, holder, document, volume, value_cents } = location;
    return producer_id && holder && document && +volume && value_cents;
  };

  const hasToShowSendProposal = (includeNotProposalReady = true) =>
    includeNotProposalReady
      ? !contract.proposal_ready && locationFullfilled() && hasCredential
      : locationFullfilled() && hasCredential;

  const toggleContractStatus = () => {
    const newStatus =
      contract.status === CONTRACT_STATUS_TYPES.ACTIVE
        ? CONTRACT_STATUS_TYPES.BLOCKED
        : CONTRACT_STATUS_TYPES.ACTIVE;

    updateContract({ status: newStatus });
  };

  const activateContract = () => {
    updateContract({ status: CONTRACT_STATUS_TYPES.ACTIVE });
  };

  const handleOpenDeleteContract = () => {
    setModalDeleteContractIsOpen(true);
  };

  const handleCloseDeleteContract = () => {
    setModalDeleteContractIsOpen(false);
  };

  const handleDeleteContract = async () => {
    try {
      const contractId = contract.id;
      const userId = location.user_id;
      let req;
      setIsDeletingContract(true);
      if (contractId) {
        req = await UsersService.deleteContractById({
          contractId,
          userId,
          id: userAuthId,
        });
      }
      if (req?.done) {
        toast.success(TOAST_MESSAGES.SUCCESS);
        navigate(`/usuarios/${userId}`);
        return;
      } else {
        setIsDeletingContract(false);
        toast.error(TOAST_MESSAGES.ERROR);
        throw { error: true };
      }
    } catch (error) {
      setIsDeletingContract(false);
      if (error.status === 404) {
        toast.error(
          'Voce não está autorizado a realizar esta operação. "Great power comes with great responsibility".'
        );
        handleCloseDeleteContract();
      } else {
        toast.error('Erro de conexão, tente novamente mais tarde...');
      }
    }
  };

  const openSendContractModal = () => {
    const hasShare =
      location.share !== '' &&
      location.share &&
      parseFloat(location.share) !== 0;
    if (!hasShare) {
      toast.warning(TOAST_MESSAGES.NO_CONTRACT_SHARE);
    } else {
      setModalActiveContractIsOpen(true);
    }
  };

  const shouldShowToggle = () => {
    return (
      contract.status === CONTRACT_STATUS_TYPES.ACTIVE ||
      contract.status === CONTRACT_STATUS_TYPES.BLOCKED
    );
  };

  const isContractActive = () => {
    return contract.status === CONTRACT_STATUS_TYPES.ACTIVE;
  };

  const debouncedNotes = useDebouncedCallback((value) => {
    setNotes(value);
    updateLocation({ notes: value }, false);
  }, 1000);

  const debouncedAttributionChannel = useDebouncedCallback((value) => {
    setAttributionChannelValue(value);
    updateAttributionChannels({ attribution_channel: value }, false);
  }, 1000);

  const updateClassificationProfile = async (values) => {
    try {
      setIsSubmiting((prevState) => ({
        ...prevState,
        location_classification_profiles: true,
      }));
      const getDivergentValues = (initialValues, finalValues) => {
        const delta = [];
        const initialIds = initialValues
          .filter((value) => !value.hide)
          .map(({ classification_profile_id }) => classification_profile_id);
        for (const value of finalValues) {
          const { classification_profile_id, hide } = value;
          if (hide) continue;
          if (initialIds.includes(classification_profile_id)) continue;
          delta.push({ ...value, location_id: location.id });
        }
        return delta;
      };

      const adjustmentDataPayload = (data) => {
        const createdValues = getDivergentValues(
          locationClassificationProfiles,
          data
        );
        const deletedValues = getDivergentValues(
          data,
          locationClassificationProfiles
        );

        const finalValues = [...createdValues, ...deletedValues];

        return finalValues;
      };

      const payload = {
        location_classification_profiles: [...adjustmentDataPayload(values)],
      };

      const dataUpdated = await ContractsAdminService.update(payload);

      updateAdminStore(dataUpdated);

      toast.success(TOAST_MESSAGES.SUCCESS);
    } catch (error) {
      console.log('file: index.jsx:372 || error:', error);
      setIsError((prevState) => ({
        ...prevState,
        location_classification_profiles: true,
      }));
      toast.error(TOAST_MESSAGES.UNKNOWN_ERROR);
    } finally {
      setIsSubmiting((prevState) => ({
        ...prevState,
        location_classification_profiles: false,
      }));
    }
  };

  useEffect(() => {
    setClassificationProfilesOptions(
      transformClassificationsProfilesToOptions(classificationProfiles)
    );
  }, [classificationProfiles]);

  useEffect(() => {
    const optionsSelected = locationClassificationProfiles
      .map((item) => ({
        ...classificationProfiles.find(
          (c) => c.classification_profile_id === item.classification_profile_id
        ),
        ...item,
      }))
      .filter((option) => !option.hide);
    setClassificationProfilesOptionsSelected(
      transformClassificationsProfilesToOptions(optionsSelected)
    );
  }, [locationClassificationProfiles, classificationProfiles]);

  return (
    <MainCont loading={!id}>
      <TitleContainer>
        <h5>Situação Atual do Contrato</h5>
      </TitleContainer>
      <Grid
        container={true}
        spacing={1}
      >
        <Grid
          item
          xs={12}
          lg={4}
        >
          <IdsForm
            values={{
              ...id,
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          lg={4}
        >
          <FlexColContainer>
            <FieldWrapper>
              <Label>Status do contrato:</Label>
              <FlexRowContainer
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'end',
                }}
              >
                <ContractStatusLabel
                  contract={{
                    ...contract,
                    profile: location.profile,
                    location_stage: location.stage,
                  }}
                />
                {shouldShowToggle() && (
                  <StatusToggle
                    active={isContractActive()}
                    labelText={isContractActive() ? 'Ativo' : 'Bloqueado'}
                    statusColors={[
                      theme.palette.secondary.main,
                      theme.palette.gray.main,
                    ]}
                    handleChange={() => setModalActivationIsOpen(true)}
                    isDisabled={isSubmitting?.status}
                  />
                )}
              </FlexRowContainer>
            </FieldWrapper>

            {statusToShow.includes(contract.status) && (
              <FieldWrapper>
                <Label>Definição de Status:</Label>
                <Select
                  options={statusOptions}
                  defaultValue={statusOptions.find(
                    (el) => el.value === contract.status
                  )}
                  onChange={(el) => updateContract({ status: el.value })}
                  isDisabled={isSubmitting?.status}
                />
              </FieldWrapper>
            )}

            <Grid
              item
              xs={12}
            >
              <FieldWrapper>
                <Label style={{ margin: 0 }}>Status 2:</Label>

                <Select
                  options={tagOptions}
                  onChange={(el) => updateLocation({ tag: el.value })}
                  defaultValue={{
                    label:
                      tagOptions.find((item) => item.label === oldTag)?.label ||
                      'Selecione',
                  }}
                  isDisabled={isSubmitting?.tag}
                />
              </FieldWrapper>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <FieldWrapper>
                <Label style={{ margin: 0 }}>Perfil:</Label>

                <Select
                  options={profileOptions}
                  onChange={(el) => updateLocation({ profile: el.value })}
                  defaultValue={{
                    label:
                      profileOptions.find((item) => item.label === oldProfile)
                        ?.label || 'Selecione',
                  }}
                  isDisabled={isSubmitting?.profile}
                />
              </FieldWrapper>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <FieldWrapper>
                <Label style={{ margin: 0 }}>Classificação de perfil:</Label>
                {classificationProfilesOptionsSelected &&
                  classificationProfilesOptions && (
                    <MultiSelect
                      optionsSelected={classificationProfilesOptionsSelected}
                      options={classificationProfilesOptions}
                      updateData={updateClassificationProfile}
                      isDisabled={
                        isSubmitting?.location_classification_profiles || false
                      }
                      isError={isError}
                      setIsError={setIsError}
                    />
                  )}
              </FieldWrapper>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <InputWithLoadindSaving
                title='Canal de atribuição'
                isSubmitting={isSubmitting.attribution_channel}
                onChange={debouncedAttributionChannel}
                value={attributionChannelValue}
                disabled={vwAttributionChannel.disabled}
              />
            </Grid>
          </FlexColContainer>
        </Grid>

        <Grid
          item
          xs={12}
          lg={4}
        >
          <ContractStatusToggles
            selectedLocation={selectedLocation}
            hasToShowSendProposal={hasToShowSendProposal}
            updateLocation={updateLocation}
            updateContract={updateContract}
            isSubmitting={isSubmitting}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <InputWithLoadindSaving
            title='Anotações'
            isSubmitting={isSubmitting.notes}
            onChange={debouncedNotes}
            value={notes}
            type='textarea'
          />
        </Grid>
      </Grid>

      <FlexRowContainer style={{ marginTop: 30 }}>
        {(hasToActiveContract() || hasToShowSendProposal()) &&
          contract?.proposal_ready && (
            <GradientButton
              text={
                hasToActiveContract() ? 'Ativar Contrato' : 'Validei a Proposta'
              }
              backgroundd='red'
              rectangle
              disabled={
                !contract?.proposal_ready
                  ? !proposalCanBeReady(contract)
                  : false
              }
              handleClick={() => {
                if (hasToActiveContract() && contract?.proposal_ready)
                  openSendContractModal();
              }}
              paddingg='8px 35px'
              marginRight='20px'
            />
          )}

        <GradientButton
          text='Deletar Contrato'
          handleClick={handleOpenDeleteContract}
          disabled={false}
          outlined
          type='button'
          rectangle
          paddingg='8px 35px'
        />
        <Modal
          open={modalDeleteContractIsOpen}
          onClose={handleCloseDeleteContract}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                width: 'fit-content',
                height: 'auto',
                padding: '2rem 4rem',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Heading2>Tem certeza desta operação?</Heading2>
              {isDeletingContract && (
                <CircularProgress style={{ color: 'orange' }} />
              )}
              {(id?.locationId?.toString()?.split(',')?.length >= 2 ||
                typeof id?.locationId === 'object') && (
                <h5>
                  Esse contrato possui mais de um imóvel, essa operação irá
                  apagar tudo.
                </h5>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: '100%',
                  marginTop: '2rem',
                }}
              >
                <GradientButton
                  paddingg='10px 30px'
                  text='Sim'
                  handleClick={handleDeleteContract}
                  disabled={isDeletingContract}
                />
                <GradientButton
                  paddingg='10px 30px'
                  background='white'
                  border={`1px solid ${theme.palette.primary.main}`}
                  text='Não'
                  handleClick={handleCloseDeleteContract}
                />
              </div>
            </div>
          </div>
        </Modal>
      </FlexRowContainer>

      <ModalConfirmation
        title='Tem certeza?'
        open={modalActivationIsOpen}
        onClose={() => setModalActivationIsOpen(false)}
        onConfirm={() => toggleContractStatus()}
        confirmText={isContractActive() ? 'Bloquear' : 'Ativar'}
      >
        <Paragraph
          size={20}
          color={colors.textGray}
        >
          Você deseja mesmo{' '}
          <b>{`${isContractActive() ? 'bloquear' : 'ativar'}`}</b> esse
          contrato?
        </Paragraph>
      </ModalConfirmation>

      <ModalConfirmation
        title='Deseja ativar o contrato para o cliente?'
        open={modalActiveContractIsOpen}
        onClose={() => setModalActiveContractIsOpen(false)}
        onConfirm={activateContract}
        confirmText='Ativar o contrato'
      />
    </MainCont>
  );
};

export default ContractStatusSection;

const MainCont = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 0;
  padding: 1.5rem;
  border-radius: 10px;
  width: '100%';
  // width: 47.5vw;
`;

const TitleContainer = styled.div`
  margin: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  > h5 {
    font-size: 1.5rem;
    color: ${theme.palette.primary.main};
    font-weight: bold;
  }
`;

const AnimatedSave = styled(MdSave)`
  @keyframes ping {
    55%,
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  width: 12px;
  margin-left: 10px;
  animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
`;

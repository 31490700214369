import { create } from 'zustand';
import { FaBusinessTime, FaFlag } from 'react-icons/fa';
import NAVBAR_ITEMS from '../core/navbarItems';

const initialState = {
  pageName: '',
  mobileView: false,
  menuMobileIsOpen: false,
  navbarItems: null,
  updatePage: false,
};

export const useGlobalStore = create((set) => ({
  ...initialState,
  setMobileView: (status) => {
    set((state) => ({ ...state, mobileView: status }));
  },
  setPage: (name) => {
    set((state) => ({ ...state, pageName: name, menuMobileIsOpen: false }));
  },
  setUpdatePage: () => {
    set((state) => ({ ...state, updatePage: !state.updatePage }));
  },
  setMenuMobileIsOpen: (isOpen) => {
    set((state) => ({ ...state, menuMobileIsOpen: isOpen }));
  },
  closeMenu: () => {
    set((state) => ({ ...state, menuMobileIsOpen: false }));
  },
  setNavbarItems: (role, company_id) => {
    const items = NAVBAR_ITEMS.filter((item) => item.forRoles.includes(role));
    set((state) => ({ ...state, navbarItems: items }));
  },
}));

export const setupNavbar = (
  pendency = false,
  userRole = '',
  company_id = ''
) => {
  useGlobalStore.getState().setNavbarItems(userRole, company_id, pendency);
};

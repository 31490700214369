import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import GradientButton from '../../../components/GradientButton';
import CitiesService from '../../../services/administrator/cities.service';
import SearchableTable from '../../../components/Interface/SearchableTable';
import ContentCard from '../../../components/ContentCard';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';
import ModalCities from './ModalForm/ModalCities';
import PageView from '../../../containers/PageView';
import { useAdminProvidersStore } from '../../../store/admin/providers.store';
import AdminProvidersService from '../../../services/administrator/providers.service';

const COLUMNS = [
  { name: 'Cidade' },
  { name: 'Estado', align: 'center' },
  { name: 'Distribuidora', align: 'center' },
];

const Cities = () => {
  const { providers } = useAdminProvidersStore();

  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    cities: [],
    states: [],
    providers: [],
  });
  const [isOpenInsertNewRegister, setIsOpenInsertNewRegister] = useState(false);

  const getRows = (registers) => {
    return registers.map(({ city, state, provider }) => ({
      values: [
        { value: city },
        { value: state, align: 'center' },
        { value: provider, align: 'center' },
      ],
    }));
  };

  const fetchAllCities = async () => {
    const { cities } = await CitiesService.getAll(0, 50000);
    return getRows(cities);
  };

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const resources = await CitiesService.getAll(0, 500);

        setPageState((state) => ({
          ...state,
          hasError: false,
          ...resources,
        }));
      } catch (error) {
        setPageState((state) => ({
          ...state,
          hasError: true,
        }));
      }
    };
    fetchCities();
  }, []);

  useEffect(() => {
    const { cities, providers } = pageState;

    if (cities?.length > 0 && providers?.length > 0)
      setPageState((state) => ({
        ...state,
        loading: false,
      }));
  }, [pageState.cities, pageState.providers]);

  useEffect(() => {
    const fetchProviders = async () => {
      try {
        const { providers } = await AdminProvidersService.getAll();
        setPageState((state) => ({
          ...state,
          hasError: false,
          providers,
        }));
      } catch (error) {
        setPageState((state) => ({
          ...state,
          hasError: true,
        }));
      }
    };

    if (providers?.length)
      setPageState((state) => {
        return { ...state, providers };
      });
    else fetchProviders();
  }, [providers]);

  const onSubmit = async (data) => {
    try {
      const result = await CitiesService.create(data);

      setPageState((state) => ({
        ...state,
        cities: [result, ...state.cities],
      }));
      toast.success('Cidade criada com sucesso');
      setIsOpenInsertNewRegister(false);
    } catch (err) {
      toast.error(err || TOAST_MESSAGES.ERROR);
    }
  };

  return (
    <PageView
      title='Cidades'
      loading={pageState.loading}
      hasError={pageState.hasError}
    >
      <ButtonContainer>
        <GradientButton
          text='Nova cidade'
          type='button'
          handleClick={() => setIsOpenInsertNewRegister(true)}
        />
      </ButtonContainer>

      <ContentCard>
        <SearchableTable
          columns={COLUMNS}
          rowsValues={getRows(pageState.cities)}
          noItemsText='Nenhuma cidade cadastrada'
          fetchAll={fetchAllCities}
        />
      </ContentCard>
      {isOpenInsertNewRegister && (
        <ModalCities
          isOpen={isOpenInsertNewRegister}
          setIsOpen={setIsOpenInsertNewRegister}
          states={pageState.states}
          providers={pageState.providers}
          onSubmit={onSubmit}
        />
      )}
    </PageView>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export default Cities;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { NewModal } from '../../NewModal';

import { ButtonsContainer, Container, UploadButtonSecondary } from './styles';
import { PrimaryButton } from '../../PrimaryButton';
import { useStage } from '../../../hooks/useStage';
import AttachmentsService from '../../../services/attachments/attachments.service';
import FileIsLargeModal from '../FileIsLargeModal';

const UploadSuccessButNotPdfFileModal = ({
  isOpen = false,
  setFile = () => {},
  setLoading = () => {},
  onRequestClose = () => {},
  continueRegisterWithoutBill = () => {},
  maxSize = parseInt(import.meta.env.REACT_APP_MAX_UPLOAD_SIZE, 10),
}) => {
  const [isOpenFileIsLargeModal, setIsOpenFileIsLargeModal] = useState(false);
  const { changePayload } = useStage();

  const handleContinueRegister = () => {
    continueRegisterWithoutBill();
    onRequestClose(false);
  };

  const saveAttachment = async (event) => {
    try {
      setLoading(true);
      const data = await AttachmentsService.uploadAttachment(
        event.target.files[0]
      );
      changePayload({ attachment: data });
      setFile(event.target.files);
    } catch (err) {
      setFile({});
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (event) => {
    const fileSelected = event.target?.files[0];

    if (fileSelected.type !== 'application/pdf') {
      toast.error('Arquivo não suportado.');
      return;
    }

    if (fileSelected.size >= maxSize) {
      setIsOpenFileIsLargeModal(true);
      return;
    }

    onRequestClose(false);
    setIsOpenFileIsLargeModal(false);
    saveAttachment(event);
  };

  return (
    <>
      <NewModal
        isOpen={isOpen}
        onRequestClose={() => handleContinueRegister()}
        isFiledOCR
        width='600px'
      >
        <Container>
          <h2 type='success'>Arquivo anexado com sucesso!</h2>
          <p>
            Obrigada por nos enviar sua conta de luz. Se quiser ter uma melhor
            experiência em sua contratação utilize o arquivo no formato PDF.
          </p>

          <ButtonsContainer>
            <PrimaryButton onClick={handleContinueRegister}>
              Continuar cadastro
            </PrimaryButton>
            <UploadButtonSecondary>
              <input
                id='file_upload_other_pdf'
                type='file'
                onChange={handleFileUpload}
                accept='application/pdf'
              />
              <label htmlFor='file_upload_other_pdf'>
                Anexar outra conta de luz
              </label>
            </UploadButtonSecondary>
          </ButtonsContainer>
        </Container>
      </NewModal>
      <FileIsLargeModal
        isOpen={isOpenFileIsLargeModal}
        handleFileUpload={handleFileUpload}
        handleClose={setIsOpenFileIsLargeModal}
        removeAttachment={false}
        continueRegisterWithoutBill={continueRegisterWithoutBill}
      />
    </>
  );
};

UploadSuccessButNotPdfFileModal.propTypes = {
  isOpen: PropTypes.bool,
  setFile: PropTypes.func,
  setLoading: PropTypes.func,
  onRequestClose: PropTypes.func,
  continueRegisterWithoutBill: PropTypes.func,
  maxSize: PropTypes.number,
};

export default UploadSuccessButNotPdfFileModal;

import connection from './connection';

export const checkProgress = async (contractId, register) => {
  const { err, data } = contractId
    ? await connection.get(`/contracts/${contractId}/checkProgress`)
    : await connection.get('/users/checkProgress', {
        params: { register },
      });

  if (err) {
    throw err;
  }
  return data;
};

import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import FlexColContainer from '../../componentsStyle/FlexColContainer';

const Alerts = ({ alerts, removeAlert }) => {
  const [closeAlerts, setCloseAlerts] = useState([]);

  useEffect(() => setCloseAlerts([]), [alerts]);

  return (
    <AlertsContainer>
      <FlexColContainer gap='0.5rem'>
        {alerts.map((alert, index) => {
          const { message, type } = alert;

          return (
            !closeAlerts.includes(index) && (
              <Alert
                key={index}
                severity={type}
                onClose={(e) => {
                  setCloseAlerts((prevState) => [...prevState, index]);
                }}
              >
                {message}
              </Alert>
            )
          );
        })}
      </FlexColContainer>
    </AlertsContainer>
  );
};

export default Alerts;
const AlertsContainer = styled.div`
  background: #fff;
`;

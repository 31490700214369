export const getLabelForDocument = (unmaskedDocumentLenght) => {
  switch (unmaskedDocumentLenght) {
    case 11:
      return 'CPF do titular';
    case 14:
      return 'CNPJ do titular';
    default:
      return 'CPF / CNPJ do titular';
  }
};

export const getLabelForHolderOptions = (unmaskedDocumentLenght) => {
  const cpfString = 'Nome completo';
  const cnpjString = 'Razão social';
  switch (unmaskedDocumentLenght) {
    case 11:
      return { label: cpfString + ' do titular', prefix: 'o' };
    case 14:
      return { label: cnpjString + ' do titular', prefix: 'a' };
    default:
      return {
        label: `${cpfString} / ${cpfString} do titular`,
        prefix: 'o',
      };
  }
};

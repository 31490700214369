export const allCalc = async (estimated_electric_bill, providerData) => {
  try {
    if (!providerData) throw 'On Hold';

    const energyBruteCost = estimated_electric_bill - +providerData.cip; // 1

    const kwhConsume = energyBruteCost / +providerData.gross_fee;

    const contractedVolume = kwhConsume; // 3

    const { sale_price } = providerData;

    const creditValue = +providerData.credit_value * contractedVolume;

    const invoiceFlora = sale_price * contractedVolume;

    const invoiceProvider = estimated_electric_bill - creditValue;

    const totalInvoices = invoiceProvider + invoiceFlora;

    const monthlyEconomy = estimated_electric_bill - totalInvoices;

    const adjustedMonthlyEconomy = +monthlyEconomy.toFixed(2);

    const proposalData =
      monthlyEconomy <= 0
        ? {
            totalInvoices: +estimated_electric_bill.toFixed(2),
            invoiceProvider: +estimated_electric_bill.toFixed(2),
            invoiceFlora: +0,
            monthlyEconomy: 0,
            yearlyEconomy: 0,
          }
        : {
            totalInvoices: +totalInvoices.toFixed(2),
            invoiceProvider: +invoiceProvider.toFixed(2),
            invoiceFlora: +invoiceFlora.toFixed(2),
            monthlyEconomy: adjustedMonthlyEconomy,
            yearlyEconomy: +(adjustedMonthlyEconomy * 12).toFixed(2),
          };

    if (providerData.profile === 'target')
      proposalData.providerName = providerData.name;

    return proposalData;
  } catch (error) {
    console.log('file: calculateProposal.js || line 161 || error', error);
    throw error;
  }
};

import styled from 'styled-components';
import { colors } from '../../../../styles/colors';

export const Paragraph = styled.p`
  font-size: ${(props) => (props.size ? `${props.size}px` : '16px')};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  color: ${(props) => props.color || colors.primary};
  text-align: ${(props) => props.align || 'left'};
  margin-bottom: ${(props) => props.marginBottom || 0};
`;

/* Dependencies */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import moment from 'moment';

import { Grid } from '@mui/material';

/* Components */
import PageView from '../../../containers/PageView';
import ContentCard from '../../../components/ContentCard';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { StyledLink } from '../../../components/Interface';
import { Paragraph } from '../../../components/Interface/Text/Paragraph';

/* API Connection */
import UsersService from '../../../services/general/users.service';

/* Helpers */
import { USER_ROLES_TYPES_PTBR } from '../../../helpers/userRoles';
import { ADMIN_CONTRACT_STATUS_TYPES_PTBR } from '../../../helpers/contractStatusTypes';
import LoadMore from '../../../components/LoadMore';

const COLUMNS = [
  { name: 'Usuário' },
  { name: 'Email' },
  { name: 'Nome' },
  { name: 'Tipo' },
  { name: 'Status' },
  { name: 'Criado em', align: 'center' },
  { name: 'ZapSac FUP', align: 'center' },
];

const sendZapsac = async (user, e, opt) => {
  UsersService.zapsac({ user, model_id: e.value, option: opt }).then(
    (success) => {
      toast.success('Mensagem enviado ao cliente!');
    }
  );
};

const fupList = [
  { label: '#1', value: 1 },
  { label: '#2', value: 2 },
  { label: '#3', value: 3 },
  { label: '#4', value: 4 },
  { label: '#5', value: 5 },
  { label: '#6', value: 6 },
  { label: '#7', value: 7 },
  { label: '#8', value: 8 },
];

const getRows = (users) => {
  return users.map(
    ({
      id,
      name,
      email,
      phone_number,
      created_at: createdAt,
      status,
      role,
    }) => ({
      values: [
        {
          value: (
            <StyledLink
              tag='true'
              to={`/usuarios/${id}`}
            >
              {id}
            </StyledLink>
          ),
        },
        {
          value: <StyledLink to={`/usuarios/${id}`}>{email}</StyledLink>,
        },
        {
          value: <Paragraph>{name}</Paragraph>,
        },
        {
          value: <Paragraph>{USER_ROLES_TYPES_PTBR[role]}</Paragraph>,
        },
        {
          value: (
            <Paragraph>{ADMIN_CONTRACT_STATUS_TYPES_PTBR[status]}</Paragraph>
          ),
        },
        {
          value: (
            <Paragraph align='center'>
              {moment(createdAt).format('DD/MM/yyyy')}
            </Paragraph>
          ),
        },
        {
          value: (
            <Select
              placeholder='Fup #'
              options={fupList}
              onChange={(opt) =>
                sendZapsac({ id, name, phone_number, email }, opt, 'fup')
              }
            />
          ),
        },
      ],
    })
  );
};

let currentItem = {};
const usersList = [];

const AdminUsers = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    users: [],
  });
  const [loadingg, setLoadingg] = useState(false);
  const [page, setPage] = useState(0);
  const [noMore, setNoMore] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoadingg(true);
        const resources = await UsersService.getAll(page);

        if (!resources || resources?.length <= 0) {
          setNoMore(true);
          return;
        } else {
          setNoMore(false);
        }

        setPageState((state) => ({
          ...state,
          loading: false,
          users: [...state.users, ...resources],
        }));
        setLoadingg(false);
      } catch (err) {
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };

    fetchUsers();
  }, [page]);

  const fetchAll = async () => {
    setExportLoading(true);
    try {
      const contractss = await UsersService.getAll(page, 100000);
      let rowsValues = getRows(contractss);
      let rowValuesArray = [];
      let headers = [];
      COLUMNS.map((col) => {
        headers.push(col.name);
      });
      rowValuesArray.push(headers);
      await Promise.all(
        rowsValues.map(async (row) => {
          let rowArr = [];
          await Promise.all(
            row.values.map((value) => {
              if (typeof value?.value?.props?.children !== 'object') {
                rowArr.push(value?.value?.props?.children);
              }
            })
          );
          rowValuesArray.push(rowArr);
        })
      );
      let csvContent =
        'data:text/csv;charset=utf-8,' +
        rowValuesArray.map((e) => e.join(',')).join('\n');
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', 'my_data.csv');
      document.body.appendChild(link); // Required for FF
      link.click();
      setExportLoading(false);
    } catch (error) {
      console.log(error);
      setExportLoading(false);
    }
  };

  const { loading, hasError, users } = pageState;

  return (
    <PageView
      title='Usuários'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <ContentCard>
            <SearchableTable
              columns={COLUMNS}
              rowsValues={getRows(users)}
              noItemsText='Nenhum usuário'
              fetchAll={fetchAll}
              loadingg={exportLoading}
            />
          </ContentCard>
          {!noMore && (
            <LoadMore
              loadingg={loadingg}
              setPage={setPage}
            />
          )}
        </Grid>
      </Grid>
    </PageView>
  );
};

export default AdminUsers;

import React from 'react';
import PropTypes from 'prop-types';

import { NewModal } from '../../NewModal';

import { ButtonsContainer, Container, UploadButtonContainer } from './styles';
import { SecondaryButton } from '../../SecondaryButton';

const FileIsLargeModal = ({
  isOpen = false,
  handleFileUpload = () => {},
  handleClose = () => {},
  removeAttachment = false,
  accept = '',
  continueRegisterWithoutBill = () => {},
}) => {
  const clearStateAndCloseModal = () => {
    removeAttachment();
    handleClose(false);
    continueRegisterWithoutBill();
  };
  const handleUpload = (event) => {
    handleClose(false);
    handleFileUpload(event);
  };

  return (
    <NewModal
      isOpen={isOpen}
      onRequestClose={() => handleClose(false)}
      isFiledOCR
      width='600px'
    >
      <Container>
        <h2>Tamanho não suportado</h2>
        <p>O tamanho do arquivo anexado é superior ao suportado.</p>

        <ButtonsContainer>
          <UploadButtonContainer>
            <input
              id='file_upload_other_pdf'
              type='file'
              onChange={handleUpload}
              accept={accept}
            />
            <label htmlFor='file_upload_other_pdf'>
              Anexar outra conta de luz
            </label>
          </UploadButtonContainer>
          {removeAttachment ? (
            <SecondaryButton onClick={clearStateAndCloseModal}>
              Continuar sem conta de luz
            </SecondaryButton>
          ) : (
            <SecondaryButton onClick={() => handleClose(false)}>
              Cancelar
            </SecondaryButton>
          )}
        </ButtonsContainer>
      </Container>
    </NewModal>
  );
};

FileIsLargeModal.propTypes = {
  isOpen: PropTypes.bool,
  handleFileUpload: PropTypes.any,
  handleClose: PropTypes.func,
  removeAttachment: PropTypes.func || PropTypes.bool,
  accept: PropTypes.array || PropTypes.string,
  continueRegisterWithoutBill: PropTypes.func,
};

export default FileIsLargeModal;

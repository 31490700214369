import React, { useState } from 'react';
import { DateRange } from 'react-date-range';

import './styles.css'; // Importe o tema padrão

import ptBR from 'date-fns/locale/pt-BR';
import { Button } from '@mui/material';
import styled from 'styled-components';
import theme from '../../theme';

//COM MAIS TEMPO A IDEIA É USAR BOTÕES PARA SELEÇÕES POR PERIODO
//POR 'ESTE MES', 'ESTA SEMANA' ....
// const shortcutsItems = [
//   {
//     label: 'Esta semana',
//     getValue: () => {
//       const today = dayjs();
//       return [today.startOf('week'), today.endOf('week')];
//     },
//   },
//   {
//     label: 'Ultima semana',
//     getValue: () => {
//       const today = dayjs();
//       const prevWeek = today.subtract(7, 'day');
//       return [prevWeek.startOf('week'), prevWeek.endOf('week')];
//     },
//   },
//   {
//     label: 'Ultimos 7 dias',
//     getValue: () => {
//       const today = dayjs();
//       return [today.subtract(7, 'day'), today];
//     },
//   },
//   {
//     label: 'Este mês',
//     getValue: () => {
//       const today = dayjs();
//       return [today.startOf('month'), today.endOf('month')];
//     },
//   },
//   {
//     label: 'Próximo mês',
//     getValue: () => {
//       const today = dayjs();
//       const startOfNextMonth = today.endOf('month').add(1, 'day');
//       return [startOfNextMonth, startOfNextMonth.endOf('month')];
//     },
//   },
//   { label: 'Limpar', getValue: () => [null, null] },
// ];

const DatePickerPeriod = ({ setPeriod, startDate, endDate }) => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: startDate ? new Date(startDate) : new Date(),
      endDate: endDate ? new Date(endDate) : new Date(),
      key: 'selection',
      color: theme.palette.primary.main,
    },
  ]);

  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    setDateRange([ranges.selection]);

    setPeriod({ from: startDate, to: endDate.setHours(23, 59, 59) });
  };

  const clearPeriod = () => {
    setDateRange([{ startDate: new Date(), endDate: new Date() }]);

    setPeriod({ from: null, to: null });
  };

  return (
    <Container>
      <DateRange
        direction='horizontal'
        scroll={{ enabled: false }}
        ranges={dateRange}
        onChange={handleSelect}
        locale={ptBR}
        linkedCalendars={false}
        editableDateInputs={false}
        moveRangeOnFirstSelection={true}
        startDatePlaceholder='Início'
        endDatePlaceholder='Fim'
      />
      <ContainerButton>
        <Button
          variant='outlined'
          size='small'
          onClick={clearPeriod}
        >
          Limpar período
        </Button>
      </ContainerButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export default DatePickerPeriod;

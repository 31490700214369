import styled, { css } from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  min-height: 100%;
  background: ${(props) => props.themeValue.page_user_background};
  padding-top: 3.75rem;

  @media (max-width: 991px) {
    margin-top: 1.5rem;
  }
`;

export const Content = styled.div`
  max-width: 380px;
  margin: 0 auto;
  padding: 1.5rem;

  @media (min-width: 992px) {
    max-width: 1128px;
  }
`;

export const CardHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
  padding: 0.094rem 1.5rem;
  height: 40px;

  strong {
    font-size: 1.125rem;

    abbr {
      text-decoration: none;
      font-size: 1rem;
    }
  }

  span {
    font-size: 1rem;
    font-weight: 700;
  }

  span:first-child {
    max-width: 27ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 1rem;
  }

  span + span {
    /* font-size: 14px; */
    font-weight: 700;
    white-space: nowrap;
  }

  ${({ status }) =>
    (status === 'registered' ||
      status === 'proposal_accepted' ||
      status === 'pending_signature' ||
      status === 'pending_activation' ||
      status === 'pending_transfer') &&
    css`
      background: ${theme.palette.purple.light};
      color: ${theme.palette.primary.main};
      strong {
        color: ${theme.palette.primary.main};
      }
    `}

  ${({ status }) =>
    status === 'active' &&
    css`
      background: ${theme.palette.primary.main};
      color: ${theme.palette.secondary.main};
      strong {
        color: ${theme.palette.secondary.main};
      }
    `}

  ${({ status }) =>
    status === 'pending_cancel' &&
    css`
      background: ${theme.palette.red.light};
      color: ${theme.palette.red.main};
      strong {
        color: ${theme.palette.red.main};
      }
    `}
  ${({ status }) =>
    status === 'cancelled' &&
    css`
      background: ${theme.palette.red.main};
      color: ${theme.palette.white.main};
      strong {
        color: ${theme.palette.white.main};
      }
    `}
  ${({ status }) =>
    status === 'suspended' &&
    css`
      background: ${theme.palette.gray.light};
      color: ${theme.palette.primary.main};
      strong {
        color: ${theme.palette.primary.main};
      }
    `}
`;

export const CardBody = styled.div`
  padding: 1.5rem;

  div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const InvoiceLine = styled.div`
  display: flex;
  justify-content: space-between;

  /* display: grid; */
  /* grid-template-columns: 1fr 1fr; */
  align-items: center;
  margin-top: 1rem;

  a {
    width: 120px;
  }

  button {
    width: 100%;
    max-width: 120px;
    font-size: 1rem;
    padding: 0.25rem 0;

    &:disabled {
      font-size: 1rem;
      padding: 0.25rem 0;
    }
  }
`;

export const CardFooter = styled.button`
  width: 100%;
  background: ${theme.palette.secondary.main};
  color: ${theme.palette.primary.main};
  border-radius: 0 0 8px 8px;
  outline: none;
  border: none;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
`;

export const CardNewProperty = styled.button`
  width: 100%;
  height: 100%;
  border: 1px solid ${theme.palette.purple.main};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
  background: none;

  span {
    font-size: 1.125rem;
    font-weight: 500;
    color: ${theme.palette.purple.main};
  }

  @media (min-width: 992px) {
    padding: 6.75rem 0;
  }
`;

export const Separator = styled.div`
  height: 1px;
  background: ${theme.palette.gray.main};
  margin: 1rem 0 1.5rem 0;

  @media (min-width: 992px) {
    margin-top: 1.5rem;
  }
`;

export const TitleAndButtonNewPropertyContent = styled.div`
  button {
    width: 100%;
    padding: 0.563rem 0;
    margin-top: 1rem;
    font-size: 1.25rem;
  }

  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      max-width: 264px;
      margin: 0;
    }
  }
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  h1 {
    margin: 0;
    color: ${theme.palette.primary.main};
    font-size: 1.5rem;
    font-weight: 700;
  }

  h1 strong {
    color: ${theme.palette.primary.main};
  }

  svg {
    color: ${theme.palette.primary.main};
  }
`;

export const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

const isOverdue = css`
  color: #b3261e;
  font-weight: 500;
`;

export const Span = styled.span`
  font-size: 1.125rem;
  align-items: center;
  display: flex;
  gap: 5px;

  ${(props) => props.isOverdue && isOverdue}

  strong {
    font-weight: 500;
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-gap: 1.5rem;

  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import PageView from '../../../containers/PageView';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { StyledLink, StatusLabel } from '../../../components/Interface';
import { Paragraph } from '../../../components/Interface/Text/Paragraph';
import ContentCard from '../../../components/ContentCard';
import ProducersService from '../../../services/administrator/producers.service';
import { getStatusColor } from '../../../styles/colors';
import { USER_STATUS_TYPES_PTBR } from '../../../helpers/userStatusTypes';
import { USER_ROLES_TYPES_PTBR } from '../../../helpers/userRoles';
import Button from '../../../components/Button';
import ProducerForm from '../../../components/Forms/ProducersForm';
import { centsToDollars } from '../../../helpers/payment';
import {
  PROFILE_TYPES,
  PROFILE_TYPES_LABEL,
} from '../../../helpers/profileTypes';

import { TOAST_MESSAGES } from '../../../helpers/toastMessages';

const COLUMNS = [
  { name: 'Id' },
  { name: 'Nome' },
  { name: 'Te Líquida' },
  { name: 'Tusd Líquida' },
  { name: 'ICMS' },
  { name: 'Pis' },
  { name: 'Cofins' },
  { name: 'Desconto Máximo' },
  { name: 'Volume' },
  // { name: 'Criado em', align: 'center' },
];

const getRows = (users) => {
  return users.map(
    ({
      id,
      name,
      cofins,
      icms,
      pis,
      te_liq,
      tusd_liq,
      discount,
      volume, // staff_id: staffId,
      // created_at: createdAt,
      // estimated_electric_bill: estimatedElectricBill,
    }) => ({
      values: [
        {
          value: <Paragraph>{id}</Paragraph>,
        },
        {
          value: <Paragraph>{name}</Paragraph>,
        },
        {
          value: <Paragraph>{te_liq}</Paragraph>,
        },
        {
          value: <Paragraph>{tusd_liq}</Paragraph>,
        },
        {
          value: <Paragraph>{icms}%</Paragraph>,
        },
        {
          value: <Paragraph>{pis}%</Paragraph>,
        },
        {
          value: <Paragraph>{cofins}%</Paragraph>,
        },
        {
          value: <Paragraph>{discount}%</Paragraph>,
        },
        {
          value: <Paragraph>{volume.toLocaleString('pt-BR')}</Paragraph>,
        },
      ],
    })
  );
};

const AdminProducers = () => {
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    users: [],
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const resources = await ProducersService.getAll();
        resources
          .sort((a, b) => {
            return a.id - b.id;
          })
          .reverse();
        setPageState((state) => ({
          ...state,
          loading: false,
          users: resources,
        }));
      } catch (err) {
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };

    fetchUsers();
  }, []);
  const onSubmit = async (data, { setSubmitting }) => {
    setSubmitting(true);
    setRequestError(false);
    const prospectId = [];

    try {
      const { id } = await ProducersService.create({ ...data });
      prospectId.push(id);
    } catch (err) {
      toast.error(TOAST_MESSAGES.ERROR);
      setRequestError(true);
      const { error: msg, errors } = err.response.data;

      if (msg) {
        setErrorMessage(msg);
      }

      if (errors) {
        const text = Object.keys(errors)
          .map((field) => [field, errors[field].join('\n')])
          .map((partial) => partial.join(' '))
          .join('\n');

        setErrorMessage(text);
      }
    } finally {
      /// /////// AQUI///////
      // try {
      //   // const dataP = {
      //   //   id: payload.id,
      //   // };
      //   // const prospectId = await ProducersService.getProspectId();
      //   // console.log(prospectId);
      //   await TasksService.create(data, prospectId[0]);

      //   await ContactsService.create(data, prospectId[0]);
      //   toast.success(TOAST_MESSAGES.SUCCESS);
      // }
      // console.log(ida.total);
      // const idT = ida.total;
      // console.log(idT);
      // await TasksService.create({ ...data, staffId });
      // const idP = await ContactsService.getP();
      // const idPP = await idP.total;
      // console.log(idPP);
      // console.log(idP);

      // console.log(da.total);
      // Formik.handleReset();
      setSubmitting(false);
    }
  };

  const { loading, hasError, users } = pageState;

  return (
    <PageView
      title='Geradores'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          {!isEditing && (
            <Grid
              container
              justify='flex-end'
            >
              <Button
                rectangle
                text='Novo Gerador'
                handleClick={() => setIsEditing(true)}
              />
            </Grid>
          )}
          {isEditing && (
            <Grid
              container
              justify='flex-end'
            >
              <Button
                rectangle
                text='Omitir'
                handleClick={() => setIsEditing(false)}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
        >
          {isEditing && (
            <ContentCard>
              <ProducerForm onSubmit={onSubmit} />
            </ContentCard>
          )}
        </Grid>
        <Grid
          item
          xs={12}
        >
          <ContentCard>
            <SearchableTable
              columns={COLUMNS}
              rowsValues={getRows(users)}
              noItemsText='Nenhum prospect'
            />
          </ContentCard>
        </Grid>
      </Grid>
    </PageView>
  );
};

export default AdminProducers;

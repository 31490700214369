import { Form } from 'formik';
import { Grid } from '@mui/material';
import React, { useContext } from 'react';

import styled from 'styled-components';
import GenericForm from '../../Forms/GenericForm';
import {
  FieldWrapper,
  Label,
  CustomField,
  CustomErrorMessage,
} from '../../Interface/Form';
import CompanyFormSchema from './schema';
import { FormPropTypes, FormDefaultProps } from '../../Forms/types';
import { formMasks } from '../../../helpers/form/masks';
import { useAuthStore } from '../../../store';
import { USER_ROLES_TYPES } from '../../../helpers/userRoles';
import { CONTRACT_STATUS_TYPES_PTBR } from '../../../helpers/contractStatusTypes';
import GradientButton from '../../GradientButton';
import { ThemeContext } from '../../../contexts/themeContext';
import { useAdminContractDetailsStore } from '../../../store/admin/contractDetails.store';

const initialValues = {};

const CompanyForm = ({
  values,
  onSubmit,
  onCancel,
  readonly,
  isEditing,
  rowInfo,
}) => {
  const { contract } = useAdminContractDetailsStore();
  const role = useAuthStore((state) => state.userAuth?.role);

  const { themeValue } = useContext(ThemeContext);

  const isCustomerEdit = () => {
    return isEditing && role === USER_ROLES_TYPES.Customer;
  };

  const isCustomer = () => {
    return role === USER_ROLES_TYPES.Customer;
  };
  const isAdmin = () => {
    return role === USER_ROLES_TYPES.Admin;
  };

  const handleCancel = (resetForm) => {
    resetForm();
    onCancel();
  };

  const getContractStatus = (contract) => {
    return CONTRACT_STATUS_TYPES_PTBR[contract?.status]?.toUpperCase();
  };

  return (
    <GenericForm
      enableReinitialize
      initialValues={initialValues}
      values={values}
      schema={CompanyFormSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, resetForm }) => {
        return (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit();
              }
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            {!isAdmin() && (
              <FieldWrapper>
                <Label
                  htmlFor='holder'
                  style={{ color: themeValue?.text_color || '#61787b' }}
                >
                  Status:{' '}
                  <Spann color={themeValue?.secondary_color || '#61787b'}>
                    {getContractStatus(contract)}
                  </Spann>
                </Label>
              </FieldWrapper>
            )}

            <Grid
              container={true}
              spacing={2}
            >
              <Grid
                item
                xs={12}
                lg={6}
              >
                <FieldWrapper>
                  <Label
                    htmlFor='holder'
                    style={{ color: themeValue?.text_color }}
                  >
                    Nome/Razão Social:
                  </Label>
                  <CustomField
                    name='holder'
                    disabled={readonly || isCustomerEdit()}
                    // placeholder='Estamos extraindo esse dado da conta de luz :)'
                    style={{
                      color: 'black',
                      margin: '0',
                    }}
                    fullwidth={1}
                  />
                  <CustomErrorMessage name='holder' />
                </FieldWrapper>
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
              >
                <FieldWrapper>
                  <Label
                    htmlFor='document'
                    style={{ color: themeValue?.text_color }}
                  >
                    CPF/CNPJ:
                  </Label>
                  <CustomField
                    name='document'
                    mask={formMasks.cpfOrCnpj}
                    disabled={readonly || isCustomerEdit()}
                    style={{
                      color: 'black',
                      margin: '0',
                    }}
                    // placeholder='Estamos extraindo esse dado da conta de luz :)'
                    fullwidth={1}
                  />
                  <CustomErrorMessage name='document' />
                </FieldWrapper>
              </Grid>
              {!isAdmin() && (
                <>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                  >
                    <FieldWrapper
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Label
                        htmlFor='document'
                        style={{
                          color: themeValue?.text_color || '#61787b',
                        }}
                      >
                        Rua
                      </Label>
                      <CustomField
                        name='street'
                        value={rowInfo?.street || '-'}
                        disabled={true}
                        readonly={true}
                        style={{
                          color: 'black',
                          margin: '0',
                        }}
                      />
                      <CustomErrorMessage name='street' />
                    </FieldWrapper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                  >
                    <FieldWrapper
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Label
                        htmlFor='document'
                        style={{
                          color: themeValue?.text_color || '#61787b',
                        }}
                      >
                        Número
                      </Label>
                      <CustomField
                        name='nujmber'
                        value={rowInfo?.number || '-'}
                        disabled={true}
                        readonly={true}
                        style={{
                          color: 'black',
                          margin: '0',
                        }}
                      />
                      <CustomErrorMessage name='number' />
                    </FieldWrapper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                  >
                    <FieldWrapper
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Label
                        htmlFor='document'
                        style={{
                          color: themeValue?.text_color || '#61787b',
                        }}
                      >
                        Complemento
                      </Label>
                      <CustomField
                        name='complement'
                        value={rowInfo?.complement || '-'}
                        disabled={true}
                        readonly={true}
                        style={{
                          color: 'black',
                          maxWidth: '5rem',
                          margin: '0',
                        }}
                      />
                      <CustomErrorMessage name='complement' />
                    </FieldWrapper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                  >
                    <FieldWrapper
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Label
                        htmlFor='document'
                        style={{
                          color: themeValue?.text_color || '#61787b',
                        }}
                      >
                        CEP
                      </Label>
                      <CustomField
                        name='postal_code'
                        value={rowInfo?.postal_code || '-'}
                        disabled={true}
                        readonly={true}
                        style={{
                          color: 'black',
                          margin: '0',
                        }}
                      />
                      <CustomErrorMessage name='postal code' />
                    </FieldWrapper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                  >
                    <FieldWrapper
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Label
                        htmlFor='document'
                        style={{
                          color: themeValue?.text_color || '#61787b',
                        }}
                      >
                        Bairro
                      </Label>
                      <CustomField
                        name='neighborhood'
                        value={rowInfo?.neighborhood || '-'}
                        disabled={true}
                        readonly={true}
                        style={{
                          color: 'black',
                          margin: '0',
                        }}
                      />
                      <CustomErrorMessage name='neighborhood' />
                    </FieldWrapper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                  >
                    <FieldWrapper
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Label
                        htmlFor='document'
                        style={{
                          color: themeValue?.text_color || '#61787b',
                        }}
                      >
                        Estado
                      </Label>
                      <CustomField
                        name='state'
                        value={rowInfo?.state || '-'}
                        disabled={true}
                        readonly={true}
                        style={{
                          color: 'black',
                          maxWidth: '5rem',
                          margin: '0',
                        }}
                      />
                      <CustomErrorMessage name='state' />
                    </FieldWrapper>
                  </Grid>
                </>
              )}
            </Grid>

            {!isCustomer() && (
              <BtnsCont>
                {!readonly && onCancel && (
                  <GradientButton
                    background='white'
                    color={theme.palette.primary.main}
                    border={`1px solid ${theme.palette.primary.main}`}
                    text='Cancelar'
                    paddingg='10px 30px'
                    handleClick={() => handleCancel(resetForm)}
                    disabled={isSubmitting}
                    outlined
                    rectangle
                  />
                )}
                {!readonly && (
                  <GradientButton
                    paddingg='10px 30px'
                    text='Salvar'
                    handleClick={handleSubmit}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    rectangle
                  />
                )}
              </BtnsCont>
            )}
          </Form>
        );
      }}
    </GenericForm>
  );
};

CompanyForm.propTypes = FormPropTypes;
CompanyForm.defaultProps = FormDefaultProps;

export default CompanyForm;

const Spann = styled.span`
  color: ${(props) => props.color};
`;

const BtnsCont = styled.div`
  display: flex;
`;

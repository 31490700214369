import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  width: 100%;
  height: ${(props) => (props.isNewPropertyOrHolder ? '100%' : '100vh')};
  margin: 0 auto;

  strong {
    color: ${theme.palette.primary.main};
  }
`;

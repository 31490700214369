/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
import React, { useEffect, useRef, useState } from 'react';
import { unMask as UNMASK } from 'remask';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { InputCustom } from '../../../InputCustom';
import { PrimaryButton } from '../../../PrimaryButton';
import { SecondaryButton } from '../../../SecondaryButton';
import { Radiobox } from '../../../Radiobox';

import {
  ButtonContainer,
  Container,
  InfoPeopleOrCompany,
  EditTokenContainer,
  RadioboxContainer,
  ContHolder,
  Title,
} from './styles';
import { useStage } from '../../../../hooks/useStage';
import {
  signerOptionsRadioBox,
  curlyBlurHolderToken,
  curlyHandleRadioBox,
  curlyHandleReceiveToken,
  typeClient,
} from '../../../../pages/Public/NewRegister/helpers';
import UsersService from '../../../../services/general/users.service';
import { LoadingCustomModal } from '../../../Modals/LoadingCustomModal';
import { unMask } from '../../../../helpers/form/unMask';
import { maskCpfOrCnpj } from '../../../../helpers/form/masks';
import ContractsService from '../../../../services/general/contracts.service';
import { onlyString } from '../../../../helpers/form/formatter';
import {
  validationEmail,
  validationPhone,
  validationCpfOrCnpj,
  validationName,
} from '../../../../helpers/form/validations';
import { validateCpfOrCnpj } from '../../../../utils/validateCpfOrCnpj';
import AuthService from '../../../../services/authentication/auth.service';
import { Checkbox } from '../../../Checkbox';
import {
  getLabelForDocument,
  getLabelForHolderOptions,
} from '../helpers/labels';
import {
  getSignerValue,
  unpreparedIsSignerFieldDisabled,
} from '../helpers/onChange';
import { deepCopy } from '../../../../helpers/object';
import compareDocuments from '../../../../helpers/compareDocuments';

export const StageBackUnsigned = ({ isNewPropertyOrHolder }) => {
  const navigate = useNavigate();
  const redirectRef = useRef(null);
  const { stagePayload, changePayload, sendPayloadGlobal } = useStage();
  const { name, holder, options, document, document_ocr, signer } =
    stagePayload;
  const { isTheHolder, disableIsTheHolderChange } = options || {};

  const [isFormValid, setIsFormValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const [initialValues] = useState(() => {
    const keysToCopy = ['holder', 'document', 'signer'];
    return deepCopy(stagePayload, keysToCopy);
  });

  const unmaskedDocument = document?.unmaskedValue || unMask(document?.value);

  const unmaskedDocumentLenght = unmaskedDocument?.length;

  const documentLabel = getLabelForDocument(unmaskedDocumentLenght);

  const { label: holderLabel, prefix: holderPrefix } = getLabelForHolderOptions(
    unmaskedDocumentLenght
  );

  const isSignerFieldDisabled = (event) =>
    unpreparedIsSignerFieldDisabled(event, stagePayload);

  useEffect(() => {
    if (signer.status === 'refused') {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [signer]);

  const getSignerChanged = () => {
    try {
      const dataToCheck = {
        document: ['value'],
        signer: ['email', 'phone_number', 'type'],
        holder: ['value'],
      };

      for (const objectToCompare in dataToCheck) {
        const keysToCompare = dataToCheck[objectToCompare];

        const newValue = stagePayload[objectToCompare];
        const initialValue = initialValues[objectToCompare];

        for (const key of keysToCompare)
          if (initialValue[key] !== newValue[key]) return true;
      }
      return false;
    } catch (error) {
      console.log('file: index.jsx:122 || error:', error);
      return true;
    }
  };

  const handleSignerChange = async () => {
    const {
      document: { value: holderDocument },
      signer: { email: signerEmail, phone_number, type },
      holder: { value: holderValue },
      widget,
    } = stagePayload;

    const didSignerChanged = getSignerChanged();

    if (!didSignerChanged) return;

    const payloadSubmitted = {
      stage: 5,
      type,
      document: unMask(holderDocument),
      holder: holderValue,
    };

    switch (type) {
      case 'email':
        const isEmailValid = validationEmail(signerEmail);
        if (!isEmailValid) {
          toast.error('Insira um email válido');
          throw { message: 'invalid option' };
        }
        payloadSubmitted.email = signerEmail;
        break;
      case 'sms':
      case 'whatsapp':
        const isPhoneValid = validationPhone(phone_number);
        if (!isPhoneValid) {
          toast.error('Insira um telefone válido');
          throw { message: 'invalid option' };
        }

        payloadSubmitted.phone_number = unMask(phone_number);
        break;
      default:
        throw { message: 'invalid option' };
    }

    await sendPayloadGlobal(payloadSubmitted, isNewPropertyOrHolder, {
      setLoading,
    });
    if (widget) widget.unmount();
  };

  const handleOpenContract = async () => {
    try {
      setLoading(true);
      await handleSignerChange();
      changePayload({ stage: 3 });
      const payload = { stage: 8 };
      await sendPayloadGlobal(payload, isNewPropertyOrHolder, {
        setLoading,
      });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const sendUserHome = () => navigate('/home');

  const handleRadiobox = curlyHandleRadioBox(useStage());

  const handleReceiveToken = curlyHandleReceiveToken(useStage());

  const blurHolderToken = curlyBlurHolderToken(useStage());

  const handleSignLater = () => AuthService.clearToken();

  const handleFinishFlux = async () => {
    try {
      setLoading(true);
      await handleSignerChange();
      const { contract_id } = stagePayload;
      const payload = { stage: 7 };
      if (isNewPropertyOrHolder) {
        sendUserHome();
        await ContractsService.newLocationNew(payload, contract_id);
      } else {
        await UsersService.signupUpdateCheckNew(payload);
        handleSignLater();
        redirectRef?.current?.click();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleHolderDocument = (event) => {
    let status = 'accepted';
    let touched = false;
    let isDocumentHolder = true;

    const unmaskedValue = unMask(event.target.value);

    const isTheHolder = unmaskedValue?.length !== 14;

    const isDocumentValid =
      validationCpfOrCnpj(unmaskedValue) && validateCpfOrCnpj(unmaskedValue);

    if (
      !!document_ocr &&
      isDocumentValid &&
      !compareDocuments(event.target.value, document_ocr)
    )
      isDocumentHolder = false;

    if (validationCpfOrCnpj(event.target.value)) {
      touched = true;
    }

    if (!isDocumentValid || !isDocumentHolder) {
      status = 'refused';
    }

    const textError = !isDocumentValid
      ? 'CPF/CNPJ inválido'
      : !isDocumentHolder
      ? 'O documento informado diverge do documento do titular da conta'
      : '';

    changePayload({
      document: {
        ...document,
        value: maskCpfOrCnpj(unmaskedValue),
        unmaskedValue,
        status,
        touched,
        textError,
      },
      options: { ...options, isTheHolder },
    });
  };

  const handleNameHolder = (event, addTouched) => {
    let status = 'accepted';
    let textError = '';
    const { value } = event?.target || event;

    const isNameValid = validationName(onlyString(value));

    if (!isNameValid) {
      status = 'refused';

      const completeLabel = `${holderPrefix} ${holderLabel}`;
      const splitName = value.split(' ');
      if (splitName.length > 1 && splitName?.[1])
        textError = `${holderLabel} deve conter apenas letras e espaços`;
      else textError = `Digite ${completeLabel.toLocaleLowerCase()}`;
    }

    const finalHolder = {
      ...holder,
      value: onlyString(value),
      status,
      textError,
    };
    if (addTouched) finalHolder.touched = true;

    changePayload({ holder: finalHolder });
  };

  const blurHolderName = () => {
    if (!holder.value.length) {
      changePayload({
        holder: { ...holder, touched: true, status: 'refused' },
      });

      return;
    }

    changePayload({ holder: { ...holder, touched: true } });
  };

  const messageByDocumentType = (type) => {
    switch (type) {
      case 'email':
        return 'E-mail';
      case 'whatsapp':
        return 'WhatsApp';
      case 'sms':
        return 'SMS';
      default:
        return 'E-mail';
    }
  };

  const handleConfirmIsTheHolder = (event) => {
    const isTheHolder = event.target.checked;
    const { ocrSuccess } = options || {};

    const holderValue =
      (ocrSuccess ? holder?.initialValue : undefined) || name?.value || '';

    const restOfHolder = isTheHolder
      ? { value: holderValue, status: 'accepted' }
      : { value: '', status: 'awaiting' };

    const newHolder = { ...holder, ...restOfHolder };

    const finalOptions = { ...options, isTheHolder };

    changePayload({ holder: newHolder, options: finalOptions });
  };
  useEffect(() => {
    const { value, status, touched } = holder || {};
    if (!value) return;
    if (status !== 'awaiting') return;
    if (touched) return;
    handleNameHolder(holder, true);
  }, [holder]);

  return (
    <>
      <LoadingCustomModal isLoading={loading} />
      <Container isNewPropertyOrHolder={!!isNewPropertyOrHolder}>
        <Title>Você voltou</Title>

        <p>
          Fique tranquilo &#40;a&#41;! Enviaremos seu termo de adesão via{' '}
          <strong>{messageByDocumentType(initialValues?.signer?.type)}</strong>{' '}
          nos próximos 2 minutos, mesmo local onde receberá o token.
        </p>
        <ContHolder>
          <InputCustom
            name='cpf_or_cnpj_holder'
            type='text'
            labelText={documentLabel}
            value={document?.value || ''}
            onChange={handleHolderDocument}
            hasError={document?.status === 'refused'}
            hasTouched={document?.touched}
            errorMessage={document?.textError}
            disabled={isSignerFieldDisabled('document')}
            maxLength={18}
          />

          {UNMASK(document?.value).length === 11 &&
            document?.status === 'accepted' &&
            !disableIsTheHolderChange && (
              <Checkbox
                checked={isTheHolder}
                onChange={handleConfirmIsTheHolder}
              >
                <label>Confirmo ser o titular da conta de luz.</label>
              </Checkbox>
            )}

          <InfoPeopleOrCompany isNewPropertyOrHolder={isNewPropertyOrHolder}>
            <InputCustom
              name='holder'
              type='text'
              labelText={holderLabel}
              value={holder?.value || ''}
              onChange={handleNameHolder}
              onBlur={blurHolderName}
              hasError={holder?.status === 'refused'}
              errorMessage={holder?.textError}
              hasTouched={holder?.touched}
              disabled={isSignerFieldDisabled('holder')}
            />
          </InfoPeopleOrCompany>
        </ContHolder>
        <p style={{ marginTop: '1rem' }}>
          Também é possível alterar o meio em que receberá para assinar depois
          ou agora.
        </p>

        <RadioboxContainer>
          <Radiobox
            name='signerBackOptionsRadioBox'
            checked={signer?.type}
            options={signerOptionsRadioBox}
            onChange={handleRadiobox}
          />
        </RadioboxContainer>

        <EditTokenContainer>
          <InputCustom
            name='holder_token'
            type='text'
            value={getSignerValue(signer)}
            labelText={typeClient(signer?.type, unMask(document?.value))}
            onChange={handleReceiveToken}
            hasError={signer?.status === 'refused'}
            hasTouched={signer?.touched}
            maxLength={
              signer?.type === 'sms' || signer?.type === 'whatsapp' ? 16 : ''
            }
          />
        </EditTokenContainer>

        <ButtonContainer>
          <PrimaryButton
            onClick={handleOpenContract}
            disabled={loading || !isFormValid}
            style={{ height: 'auto' }}
          >
            Assinar agora
          </PrimaryButton>
          {isNewPropertyOrHolder ? (
            <SecondaryButton
              onClick={handleFinishFlux}
              disabled={loading || !isFormValid}
            >
              Assinar depois
            </SecondaryButton>
          ) : (
            <>
              <a
                href='https://floraenergia.com.br/'
                ref={redirectRef}
                hidden
              />
              <SecondaryButton
                onClick={handleFinishFlux}
                disabled={loading || !isFormValid}
              >
                Assinar depois
              </SecondaryButton>
            </>
          )}
        </ButtonContainer>
      </Container>
    </>
  );
};

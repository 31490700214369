import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  align-items: center;
  /* margin-bottom: 0.25rem; */

  input {
    appearance: none;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.125rem;
    height: 1.125rem;

    border: 2px solid ${theme.palette.green.main};
    border-radius: 50%;

    cursor: pointer;
    position: relative;

    &:disabled {
      border-color: #949092;
      cursor: not-allowed;
    }

    &:disabled + label {
      color: #949092;
    }

    &:checked {
      border: 2px solid ${theme.palette.green.main};
    }

    &:checked + label {
      color: #1c1b1f;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0.5rem;
      width: 0.5rem;
      background: ${theme.palette.green.main};
      border-radius: 50%;
      opacity: 0;
    }

    &:checked:after {
      opacity: 1;
    }
  }

  label {
    /* width: 4.625rem; */
    margin-left: 0.625rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 300;
    color: #1c1b1f;
    cursor: pointer;
  }
`;

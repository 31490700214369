import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  gap: 1.5rem;

  h2 {
    color: ${(props) =>
      props.type === 'error'
        ? theme.palette.error.main
        : theme.palette.success.main};
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
  }

  p {
    color: #000;
    font-weight: 300;
    font-size: 1.125rem;
    text-align: center;
    margin: 0;
  }
`;

export const UploadButtonSecondary = styled.div`
  width: 100%;

  input[type='file'] {
    display: none;
  }

  label {
    width: 100%;
    padding: 0.563rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid ${theme.palette.primary.main};
    background: none;
    color: ${theme.palette.primary.main};
    font-size: 18px;
    font-weight: 500;
    transition: all 0.3s;

    &:hover {
      color: ${theme.palette.purple.dark};
      border-color: ${theme.palette.purple.dark};
    }

    &:active {
      color: ${theme.palette.purple.light};
      border-color: ${theme.palette.purple.light};
    }

    &:disabled {
      width: 100%;
      color: #989799;
      background: none;
      border: 1px solid #989799;
      border-radius: 8px;
      font-size: 18px;
      font-weight: 500;
      cursor: not-allowed;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  button {
    padding: 0.563rem 0;
    font-size: 1.25rem;
  }

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const UploadButtonContainer = styled.div`
  width: 100%;

  input[type='file'] {
    display: none;
  }

  label {
    width: 100%;
    padding: 0.563rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    cursor: pointer;
    border-radius: 8px;
    background: #f9af40;
    color: #fbfdff;
    font-size: 1.25rem;
    font-weight: 500;
    transition: all 0.3s;

    &:hover {
      background: #d89025;
      /* box-shadow: 0px 1px 3px 1px #00000026; */
      box-shadow: 0px 1px 2px 0px #0000004d;
    }

    &:active {
      background: #fdd496;
      color: #b57008;
      border-color: #b57008;
    }

    /* &:disabled {
      color: #e4e4e4;
      background: none;
      border: 1px solid #e4e4e4;
      border-radius: 8px;
      font-size: 1.25rem;
      font-weight: 500;
      cursor: not-allowed;
    } */
  }
`;

import connection from '../connections/main';

const getAll = async () => {
  const { err, data } = await connection.get(`/massContractsActivation`);

  if (err) {
    throw err;
  }

  return data;
};

const updateAll = async (payload) => {
  const { err, data } = await connection.put(
    `/massContractsActivation/updateAll`,
    {
      massContractsActivation: payload,
    }
  );

  if (err) {
    throw err;
  }

  return data;
};

const MassActivationService = {
  getAll,
  updateAll,
};

export default MassActivationService;

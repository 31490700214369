import administratorConnection from '../administrator/connections/main';

const createSheetRegisters = async (urls, macroProvider) => {
  const payload = { urls, macroProvider };
  const { err, data } = await administratorConnection.post(
    '/createManualFirstContact/sheet',
    payload
  );
};

const createUnpreparedAttachmentsRegister = async (urls, macroProvider) => {
  const payload = { urls, macroProvider };
  await administratorConnection.post(
    '/createManualFirstContact/unpreparedAttachment',
    payload
  );
};

const firstContactRegisterService = {
  createSheetRegisters,
  createUnpreparedAttachmentsRegister,
};

export default firstContactRegisterService;

import styled, { css } from 'styled-components';
import theme from '../../theme';

const isCurrent = css`
  font-weight: 700;
`;

const isPassed = css`
  font-weight: 400;
  background: ${theme.palette.purple.dark};
`;

const isActive = css`
  font-weight: 400;
  background: ${theme.palette.secondary.main};
  color: ${theme.palette.primary.main};
`;

export const Container = styled.div`
  /* height: 5rem; */
  background: ${theme.palette.primary.main};
  position: sticky;
  top: 0;
  z-index: 1;
  overflow-y: auto;

  @media (min-width: 768px) {
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1.625rem;

  ${(props) =>
    props.isNewPropertyOrHolder &&
    css`
      justify-content: end;
    `}

  img {
    padding: 0.5rem 0;
  }

  button {
    border: 0;
    background: transparent;
  }

  @media (max-width: 991px) {
    button {
      display: block !important;
    }
  }
`;

export const ImageContainer = styled.img`
  width: 100px;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const MenuToggle = styled.div`
  width: 1.25rem;

  div {
    background: #1c1b1f;
    height: 2px;
    width: 100%;
    margin: 3px auto;
  }
`;

export const ContProgressBar = styled.div`
  display: flex;
  gap: 0.25rem;
`;

export const NavigateButton = styled.button`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  background: ${theme.palette.gray.light};
  border: 0;
  color: ${theme.palette.gray.main};
  font-size: 1rem;
  font-weight: 300;

  &:disabled {
    cursor: not-allowed;
  }

  ${(props) => props.isActive && isActive}
  ${(props) => props.isCurrent && isCurrent}
  ${(props) => props.isPassed && isPassed} /* @media (max-width: 768px) {
    pointer-events: none;
  } */
`;

export const BackButton = styled.button`
  color: #fff;
  font-weight: 700;
  font-size: 1.125rem;
  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
  background: ${theme.palette.primary.main};
`;

export const HelpButton = styled.button`
  padding: 0.75rem 0;

  @media (min-width: 992px) {
    display: none;
  }
`;

export const ArrowButton = styled.button``;

export const Link = styled.a`
  ${(props) =>
    props.isLogo &&
    css`
      display: none;

      @media (min-width: 992px) {
        display: block;
      }
    `}

  ${(props) =>
    props.isArrow &&
    css`
      display: block;
      @media (min-width: 992px) {
        display: none;
      }
    `}
`;

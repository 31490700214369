import React, { useContext, useEffect, useState } from 'react';

import { LuHome } from 'react-icons/lu';
import { useLocation } from 'react-router-dom';
import { NewPageView } from '../../../containers/NewPageView';
import { NewRegister } from '../../Public/NewRegister';
import { Container, Content, StagesContainer, TitleContent } from './styles';
import { ThemeContext } from '../../../contexts/themeContext';

import PropertiesImg from '../../../assets/images/icons/monochromatic-png/imoveis.png';

export const NewPropertyOrHolder = () => {
  const { themeValue } = useContext(ThemeContext);
  const location = useLocation();
  const [loading, setLoading] = useState(!!location?.state);
  const [selectedContract, setSelectedContract] = useState(false);

  useEffect(() => {
    const definedSelectedContract = location?.state;
    if (!definedSelectedContract) return;
    setSelectedContract(definedSelectedContract);
  }, [location]);

  useEffect(() => {
    if (!selectedContract) return;
    setLoading(false);
  }, [selectedContract]);

  return (
    <NewPageView>
      <Container themeValue={themeValue}>
        <Content>
          <TitleContent>
            <img
              src={PropertiesImg}
              style={{ width: '3.5rem', height: '3.5rem', marginLeft: '1rem' }}
            />
            <h1>Imóveis | Novo Imóvel</h1>
          </TitleContent>

          {!loading && (
            <StagesContainer>
              <NewRegister
                isNewPropertyOrHolder
                selectedContract={selectedContract}
              />
            </StagesContainer>
          )}
        </Content>
      </Container>
    </NewPageView>
  );
};

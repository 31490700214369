import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  min-height: 100%;
  background: ${(props) => theme.palette.white.dark};
  padding-top: 3.75rem;

  @media (max-width: 991px) {
    margin-top: 1.5rem;
  }
`;

export const Content = styled.div`
  max-width: 428px;
  margin: 0 auto;
  /* padding: 1.5rem; */

  @media (min-width: 992px) {
    max-width: 1128px;
  }
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1.5rem 1.5rem 0 1.5rem;

  @media (min-width: 480px) {
    padding: 1.5rem 0 0 0;
  }

  h1 {
    margin: 0;
    color: ${theme.palette.primary.main};
    font-size: 1.5rem !important;
    font-weight: 700;
  }

  h1 strong {
    color: ${theme.palette.primary.main} !important;
    font-size: 1.5rem !important;
  }

  svg {
    color: ${theme.palette.primary.main};
  }
`;

export const Separator = styled.div`
  height: 1px;
  background: ${theme.palette.gray.main};
  margin-top: 1.5rem;
`;

export const FormContainer = styled.form`
  max-width: 648px;
  margin: 0 auto;

  button {
    margin-top: 1rem;
  }
`;

export const Span = styled.span`
  font-weight: 400;
  font-size: 1rem;
  color: #000000;
`;

export const NoHasBillContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;

export const ContAddress = styled.div`
  margin-top: 1rem;

  a {
    margin-top: 1rem;
  }
`;
/* ${(props) => props.isEqual && isEqual} */

export const LinkContainer = styled.div`
  margin-top: 0.25rem;

  a {
    color: #d89025;
    font-weight: 500;
  }
`;

export const StagesContainer = styled.div`
  padding-bottom: 1.5rem;
  margin-top: 1rem;
`;

import styled, { css } from 'styled-components';
import theme from '../../../../theme';

export const Container = styled.div`
  max-width: 648px;
  margin: 0 auto;
`;

export const Content = styled.div`
  margin: 0 3rem;

  > p {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.688rem;
    text-align: center;
    color: #1c1b1f;
    margin-bottom: 1rem;

    &:nth-child(6) {
      margin-top: 1rem;
    }
  }

  div:nth-child(5) {
    margin-top: 1rem;
  }

  ${(props) =>
    props.isNewPropertyOrHolder &&
    css`
      margin: 0;
    `}
`;

export const Title = styled.h2`
  margin: 3rem 0 2.25rem 0;
  color: ${theme.palette.primary.main};
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
`;

export const RadioboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 0.625rem;
  margin: 1rem 0;
  padding-bottom: 3rem;

  button {
    width: 100%;
    padding: 0.563rem 0;
    font-size: 1.25rem;

    &:disabled {
      font-size: 1.25rem;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const InfoPeopleOrCompany = styled.div`
  margin-top: 1rem;

  p {
    margin-top: 1rem;
  }
`;

import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { MdOutlineChat } from 'react-icons/md';
import { IoIosArrowDown } from 'react-icons/io';
import { useZendeskStore } from '../../store';
import { useLocation } from 'react-router-dom';
import chatImg from '../../assets/images/icons/negative-png/mensagem-suporte 2.png';

export const ButtonZendesk = () => {
  const { isOpenZendesk, shouldHideBtn, close, open, verifyShoudHideBtn } =
    useZendeskStore();
  const location = useLocation();

  useEffect(() => {
    const pathname = location?.pathname;
    if (pathname) {
      verifyShoudHideBtn(pathname);
    }
  }, [location?.pathname]);

  return (
    <>
      {!shouldHideBtn ? (
        <Container onClick={() => (isOpenZendesk ? close() : open())}>
          {isOpenZendesk ? (
            <IoIosArrowDown
              size='1.5rem'
              color='#FBFDFF'
            />
          ) : (
            <img
              src={chatImg}
              style={{ width: '2rem', height: '2rem' }}
            />
          )}
        </Container>
      ) : null}
    </>
  );
};

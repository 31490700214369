import connection from './connections/main';

const getAll = async () => {
  const { err, data } = await connection.get(`/invoicesStages`);

  if (err) {
    throw err;
  }

  return data;
};

const getAllFromContractId = async (contractId) => {
  const { err, data } = await connection.get(
    `/invoicesStages/contracts/${contractId}`
  );

  if (err) {
    throw err;
  }

  return data;
};

const updateDone = async (stageId) => {
  const { err, data } = await connection.patch(`/invoicesStages/${stageId}`, {
    invoiceStage: { done: true },
  });

  if (err) {
    throw err;
  }

  return data;
};

const InvoicesStagesService = {
  getAll,
  getAllFromContractId,
  updateDone,
};

export default InvoicesStagesService;

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import PageView from '../../../containers/PageView';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { StyledLink, StatusLabel } from '../../../components/Interface';
import { Paragraph } from '../../../components/Interface/Text/Paragraph';
import ContentCard from '../../../components/ContentCard';

import UsersService from '../../../services/general/users.service';
import ContractsService from '../../../services/general/contracts.service';
import LocationsService from '../../../services/general/locations.service';

const COLUMNS = [
  { name: 'Contrato', align: 'left' },
  { name: 'Imóvel' },
  { name: 'UC' },
  { name: 'Distrib.' },
  { name: 'Gerador' },
  { name: 'Ag. Rateio' },
  { name: '*Rateio(%)' },
  { name: '*Data envio' },
];

const getRows = (proposals) => {
  return proposals.map((proposal) => ({
    values: [
      {
        value: proposal.contractId ? (
          <StyledLink to={`/contratos/${proposal.contractId}`}>
            #{proposal.contractId}
          </StyledLink>
        ) : (
          '-'
        ),
      },
      {
        value: proposal.nickname ? (
          <Paragraph>{proposal.nickname}</Paragraph>
        ) : (
          '-'
        ),
      },
      {
        value: <Paragraph>{proposal.uc}</Paragraph>,
      },
      {
        value: proposal.provider ? (
          <Paragraph style={{ textTransform: 'uppercase' }}>
            {proposal.provider}
          </Paragraph>
        ) : (
          '-'
        ),
      },
      {
        value: proposal.producer ? (
          <Paragraph style={{ textTransform: 'uppercase' }}>
            {proposal.producer}
          </Paragraph>
        ) : (
          '-'
        ),
      },
      // format('DD/MM/yyyy hh:mm')
      {
        value: <Paragraph>{moment(proposal.volume_since).days}</Paragraph>,
      },
      {
        value: <Paragraph>{proposal.volume}</Paragraph>,
      },
      {
        value: <Paragraph>{proposal.update}</Paragraph>,
      },
    ],
  }));
};

const AdminSetupDistributor = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    distributors: [],
  });

  const credentials = [];
  let distributorsList = [];
  const contractsIdsList = [];
  let contractsListMap = [];
  const distributorsMap = [];
  let currentItem = {};

  useEffect(() => {
    const taskDate = (date) => {
      const currentDate = new Date(date);
      return currentDate.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    };

    const fetchCredential = async () => {
      try {
        const awaitList = [];
        const associativeCredential = [];

        contractsIdsList.forEach((contractId, index) => {
          awaitList.push(LocationsService.getCredential(contractId));
          associativeCredential.push({
            contract: contractId,
            responseKey: awaitList.length - 1,
          });
        });

        contractsListMap = await Promise.all(awaitList);

        contractsListMap.forEach((contractList, i) => {
          associativeCredential.forEach((credential) => {
            if (credential.responseKey === i) {
              credentials.push({
                contractId: credential.contract,
                uc: contractList.install_code,
                updated: contractList.updated_at,
              });
            }
          });
        });
      } catch (err) {
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };

    const mountList = async () => {
      // Prepare valid object to map
      distributorsList.forEach((proposal) => {
        if (proposal.status !== 'pending_activation') {
          return;
        }
        let ucVal = '';
        credentials.forEach((credential) => {
          if (credential.contractId === proposal.id) {
            ucVal = credential.uc;
          }
        });

        currentItem = {
          contractId: proposal.id,
          uc: ucVal,
          provider:
            typeof proposal.locations[0].provider !== 'undefined'
              ? proposal.locations[0].provider
              : '',
          producer:
            typeof proposal.locations[0].producer !== 'undefined'
              ? proposal.locations[0].producer
              : '',
          volume_since: proposal.signed_at,
          volume: proposal.volume,
          update:
            proposal.locations.length > 0
              ? taskDate(proposal.locations[0].batch_date)
              : '',
        };
        distributorsMap.push(currentItem);
      });
    };
    const fetchDistributors = async () => {
      try {
        const { resources } = await ContractsService.getAll();

        distributorsList = resources;
        distributorsList.forEach(({ id, status }) => {
          if (status !== 'pending_activation') {
            return;
          }
          contractsIdsList.push(id);
        });
      } catch (err) {
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };

    fetchDistributors().then((resources) => {
      fetchCredential()
        .then(() => {
          mountList().then(() => {
            setPageState((state) => ({
              ...state,
              loading: false,
              distributors: distributorsMap,
            }));
          });
        })
        .catch((error) => {
          console.log('test');
        });
    });
  }, []);

  const { loading, hasError, distributors } = pageState;

  return (
    <PageView
      title='Set Up Distribuidora'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <ContentCard>
            <SearchableTable
              columns={COLUMNS}
              rowsValues={getRows(distributors)}
              noItemsText='Nenhum set up distribuidor'
            />
          </ContentCard>
        </Grid>
      </Grid>
    </PageView>
  );
};

export default AdminSetupDistributor;

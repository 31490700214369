import React, { useEffect } from 'react';
import { Form, FieldArray } from 'formik';
import { Grid } from '@mui/material';
import moment from 'moment';

import { NumericFormat } from 'react-number-format';

import {
  FieldWrapper,
  Label,
  CustomErrorMessage,
  CustomField,
} from '../../Interface/Form';
import GenericForm from '../GenericForm';
import { FormPropTypes, FormDefaultProps } from '../types';
import Button from '../../Button';


const InitialProposalForm = ({ onSubmit, onCancel, values, readonly }) => {
  const handleCancel = (resetForm) => {
    resetForm();
    onCancel();
  };

  return (
    <GenericForm enableReinitialize onSubmit={onSubmit} values={values}>
      {({ isSubmitting, handleSubmit, resetForm, values: formValues }) => {
        return (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <div className='input-lines'>
              <div className='input-line'>
                <div className='input-title'>
                  Quanto você paga para a {values.providerName}?
                  <p>
                    <small>(média histórica)</small>
                  </p>
                </div>
                <div
                  className='input-value'
                  style={{
                    backgroundColor: 'rgb(207 207 207)',
                  }}
                >
                  R${' '}
                  <NumericFormat
                    style={{
                      backgroundColor: 'rgb(207 207 207)',
                      color: '#fff',
                    }}
                    defaultValue={values.estimated_electric_bill}
                    decimalSeparator=','
                    name='currentpay'
                  />
                </div>
              </div>
              <div className='input-line'>
                <div className='input-title'>
                  Com a solução da Flora você passará a pagar
                </div>
                <div className='input-value'>
                  R${' '}
                  <input
                    type='text'
                    name='willpay'
                    placeholder='0,00'
                    readOnly={true}
                    value={values.totalInvoices}
                  />
                </div>
                <div className='input-invoices'>
                  Conta de luz da {values.providerName} (Fatura 1){' '}
                  <span>R$ {values.invoiceProvider}</span>
                </div>
                <div className='input-invoices'>
                  Fatura Flora (Fatura 2) <span>R$ {values.invoiceFlora}</span>
                </div>
              </div>
              <div className='input-line'>
                <div className='input-title'>Economia no mês</div>
                <div className='input-value'>
                  R${' '}
                  <input
                    type='text'
                    placeholder='0,00'
                    name='ecomonth'
                    readOnly={true}
                    value={values.monthlyEconomy}
                  />
                </div>
              </div>
              <div
                className={`input-line ${values.dealEffect ? 'input-year-effect' : null
                  }`}
              >
                <div className='input-title'>Economia no ano</div>
                <div className='input-value'>
                  R${' '}
                  <input
                    type='text'
                    name='ecoyear'
                    placeholder='0,00'
                    readOnly={true}
                    value={values.yearlyEconomy}
                  />
                </div>
              </div>
              {/* <div className='input-line text'>
                <div className='input-title'>Garantia de economia</div>
                <div className='input-text'>
                  Sua conta de luz com a distribuidora somada a fatura Flora,
                  será <strong>sempre menor</strong> do que a sua conta de luz
                  atual.
                </div>
              </div>
              <div className='input-line text'>
                <div className='input-title'>Sem prazo ou multa contratual</div>
                <div className='input-text'>
                  Basta solicitar seu desligamento com um aviso prévio de 90
                  dias.
                </div>
              </div> */}
            </div>
          </Form>
        );
      }}
    </GenericForm>
  );
};

InitialProposalForm.propTypes = FormPropTypes;
InitialProposalForm.defaultProps = FormDefaultProps;

export default InitialProposalForm;

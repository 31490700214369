import React from 'react';
import { NewPageView } from '../../../containers/NewPageView';
import { Card, Container, Content } from './styles';
import ErrorImg from '../../../assets/images/icons/positive-png/construcao da usina 1.png';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../../components/PrimaryButton';

const NotFound = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    if (window.history.length > 4) {
      navigate(-3);
    } else {
      navigate('/login');
    }
  };
  return (
    <NewPageView>
      <Container>
        <Card>
          <Content>
            <img
              src={ErrorImg}
              style={{ width: '10rem', height: '10rem' }}
            />
            <h1>A página solicitada não existe no ecossistema da Flora. </h1>

            <PrimaryButton onClick={handleGoBack}>Voltar</PrimaryButton>
          </Content>
        </Card>
      </Container>
    </NewPageView>
  );
};

export default NotFound;

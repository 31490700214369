import React, { useContext, useEffect, useState } from 'react';
import { LuAlertCircle } from 'react-icons/lu';
import { BsHouses } from 'react-icons/bs';

import { useNavigate } from 'react-router-dom';
import { NewPageView } from '../../../containers/NewPageView';

import { PrimaryButton } from '../../../components/PrimaryButton';
import { SecondaryButton } from '../../../components/SecondaryButton';

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardNewProperty,
  CardsContainer,
  Container,
  Content,
  InvoiceLine,
  Separator,
  Span,
  TitleAndButtonNewPropertyContent,
  TitleContent,
} from './styles';
import UsersService from '../../../services/general/users.service';
import { LoadingCustomModal } from '../../../components/Modals/LoadingCustomModal';
import { CONTRACT_STATUS_TYPES_PTBR } from '../../../helpers/contractStatusTypes';
import { formatDate } from '../../../helpers/date';
import { getInvoiceStatusButton } from '../../../helpers/invoicesButtons';
import { setIsOverdue } from '../../../helpers/invoices';
import { ThemeContext } from '../../../contexts/themeContext';
import FlexRowContainer from '../../../componentsStyle/FlexRowContainer';
import theme from '../../../theme';

import PropertiesImg from '../../../assets/images/icons/monochromatic-png/imoveis.png';
import PendenciesImg from '../../../assets/images/icons/monochromatic-png/pendencias 1.png';

export const NewMyLocations = () => {
  const { themeValue } = useContext(ThemeContext);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Ordem de organização dos imóveis:

  // 1. Ativo com pendência de pagamento.
  // 2. Proposta aceita
  // 3. Aguardando assinatura
  // 4. Cadastrado
  // 5. Aguardando ativação com pendência de conta de luz
  // 6. Aguardando ativação sem pendência
  // 7. Ativo
  // 8. Cancelado

  const newStatusArray = [
    'registered',
    'proposal_accepted',
    'pending_signature',
    'pending_activation',
    'pending_transfer',
    'active',
    'pending_cancel',
    'cancelled',
    'suspended',
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await UsersService.getProperties();
        setProperties(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleNewProperty = () => {
    navigate('/imoveis/novo');
  };

  const handlePropertyView = (id) => {
    navigate(`/imovel/${id}`);
  };

  const resumeAddress = (addressFull) => {
    if (!addressFull?.length) return;

    const addressMaxLength = 20;
    const addressCurrentLength = addressFull.length;

    if (addressCurrentLength <= addressMaxLength) {
      return addressFull;
    }

    const [currentAddress, number] = addressFull.split(',');
    const currentAddressWithoutNumber = currentAddress.slice(
      0,
      addressMaxLength - addressCurrentLength
    );
    const currentAddressResumed = currentAddressWithoutNumber.concat(
      ',',
      number
    );

    return number ? currentAddressResumed : currentAddressWithoutNumber;
  };

  const resumeStatus = (status) => {
    return status.replace('Aguardando', 'Ag.');
  };

  return (
    <NewPageView>
      <LoadingCustomModal isLoading={loading} />
      <Container themeValue={themeValue}>
        <Content>
          <TitleAndButtonNewPropertyContent>
            <TitleContent>
              <img
                src={PropertiesImg}
                style={{ width: '3.5rem', height: '3.5rem' }}
              />
              <h1>Imóveis</h1>
            </TitleContent>

            {properties.length > 3 && (
              <PrimaryButton onClick={handleNewProperty}>
                Novo imóvel
              </PrimaryButton>
            )}
          </TitleAndButtonNewPropertyContent>

          <Separator />

          <CardsContainer>
            {properties.map((property) => {
              const { invoice } = property;
              const isOverdue = setIsOverdue(invoice);
              if (invoice) Object.assign(invoice, { isOverdue });

              return (
                <Card key={property.id}>
                  <CardHeader status={property?.status}>
                    <span>
                      <strong>
                        <abbr title={property?.nickname}>
                          {resumeAddress(property?.nickname)}
                        </abbr>
                      </strong>
                    </span>

                    <span>
                      {resumeStatus(
                        CONTRACT_STATUS_TYPES_PTBR[property?.status]
                      )}
                    </span>
                  </CardHeader>
                  <CardBody>
                    {invoice?.due_date && (
                      <FlexRowContainer
                        style={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Span isOverdue={isOverdue}>
                          <strong>Vencimento:</strong>{' '}
                        </Span>

                        <Span>
                          {formatDate(invoice?.due_date)}
                          {isOverdue && (
                            <img
                              src={PendenciesImg}
                              style={{ width: '1.2rem', height: '1.2rem' }}
                            />
                          )}
                        </Span>
                      </FlexRowContainer>
                    )}

                    <InvoiceLine>
                      <Span>
                        Fatura <strong>Flora</strong>
                      </Span>
                      <div
                        style={{
                          display: 'flex',
                          flex: 1,
                          justifyContent: 'flex-end',
                        }}
                      >
                        {getInvoiceStatusButton(invoice, 'isNewMyLocations')}
                      </div>
                    </InvoiceLine>

                    <InvoiceLine>
                      <Span>
                        Fatura <strong>Distribuidora</strong>
                      </Span>
                      <a
                        href={invoice?.attachment_link}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <SecondaryButton
                          disabled={!Object.keys(invoice ?? {})?.length}
                        >
                          Visualizar
                        </SecondaryButton>
                      </a>
                    </InvoiceLine>
                  </CardBody>

                  <CardFooter onClick={() => handlePropertyView(property?.id)}>
                    Ver detalhes
                  </CardFooter>
                </Card>
              );
            })}

            {properties.length <= 3 && (
              <CardNewProperty onClick={handleNewProperty}>
                <span>+ Novo imóvel</span>
              </CardNewProperty>
            )}
          </CardsContainer>
        </Content>
      </Container>
    </NewPageView>
  );
};

/* eslint-disable one-var */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable no-throw-literal */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { toast } from 'react-toastify';
import PageView from '../../../../containers/PageView';
import SearchableTable from '../../../../components/Interface/SearchableTable';
import { StyledLink } from '../../../../components/Interface';
import { Paragraph } from '../../../../components/Interface/Text/Paragraph';

import ContentCard from '../../../../components/ContentCard';
import GradientButton from '../../../../components/GradientButton';
import InvoicesCheckService from '../../../../services/administrator/ManagementBases/invoicesCheck.service';
import ExcelRenderer from '../../../../utils/excelRenderer';
import { DropzoneCustom } from '../../../../components/DropzoneCustom';

const COLUMN = [{ name: 'Contrato' }];
const COLUMNRESPONSE = [
  { name: 'Contrato' },
  { name: 'Sucesso em todas as contas' },
];

const getRows = (contracts, responseReaders) =>
  responseReaders
    ? contracts.map(({ id, success }) => ({
        values: [
          {
            value: (
              <StyledLink
                tag='true'
                to={id ? `/contratos/${id}` : '/checkFaturamento'}
              >{`${id}`}</StyledLink>
            ),
          },
          {
            value: <Paragraph>{success ? 'Sim' : 'Não'}</Paragraph>,
          },
        ],
      }))
    : contracts.map((id) => ({
        values: [
          {
            value: (
              <StyledLink
                tag='true'
                to={id ? `/contratos/${id}` : '/checkFaturamento'}
              >{`${id}`}</StyledLink>
            ),
          },
        ],
      }));

const TryAgain = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
  });

  const initialData = location?.state?.length ? location?.state : [];

  const [allData, setAllData] = useState(initialData);
  const [includedFile, setHasIncludedFile] = useState(false);
  const [responseHeaders, setResponseHeaders] = useState(false);

  const [rerender, setRerender] = useState(true);

  const fetchInvoicesCheck = async () => {
    try {
      setPageState({ ...pageState, loading: true });
      const requestedData = await InvoicesCheckService.getAll();
      const { fixableContracts } = requestedData;
      setAllData(fixableContracts);
    } catch (error) {
      console.log('file: index.js || line 73 || error', error);
      setPageState({ ...pageState, hasError: true });
    } finally {
      setPageState({ ...pageState, loading: false });
    }
  };

  const onSubmit = async () => {
    try {
      setPageState((state) => ({
        ...state,
        loading: true,
      }));
      const responseData = await InvoicesCheckService.tryAgainForContracts(
        allData
      );
      setAllData(responseData);
      setResponseHeaders(true);
    } catch (error) {
      setPageState((state) => ({
        ...state,
        loading: true,
        hasError: true,
      }));
    }
  };

  useEffect(() => {
    if (!allData) return;
    setPageState((state) => ({
      ...state,
      loading: false,
    }));
  }, [allData]);

  useEffect(() => {
    fetchInvoicesCheck();
  }, []);

  const desiredColumns = ['Contrato'];

  const checkModel = (titles) => {
    desiredColumns.map((desiredTitle, index) => {
      if (titles[index] !== desiredTitle) throw 'Por favor utilize o modelo';
    });
  };

  const rowsHandler = (datas) => {
    try {
      const finalData = [];

      datas.map((data) => {
        const [contractId] = data;
        if (!contractId) return;

        finalData.push(+contractId);
      });
      setAllData(finalData);
      setRerender(!rerender);
    } catch (error) {
      console.log('file: index.js || line 263 || error', error);
      throw error;
    }
  };
  const dataHandler = (data) => {
    const { rows } = data;
    const [titles, ...datas] = rows;
    checkModel(titles);
    rowsHandler(datas);
  };

  const fileHandler = async (files) => {
    try {
      const fileObj = files[0];
      const { type } = fileObj;
      if (
        type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
        throw "O formato do arquivo deve ser 'xlsx'";

      const data = await ExcelRenderer(fileObj, (err, resp) => {
        if (err) throw err;
        else return resp;
      });
      dataHandler(data);
      setHasIncludedFile(true);
      setResponseHeaders(false);
    } catch (error) {
      toast.error(error);
      setHasIncludedFile(false);
    }
  };

  const { loading, hasError } = pageState;

  return (
    <PageView
      title='Tentar novamente'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Paragraph align='center'>
        Upload de Contratos, que tentarão realizar o faturamento novamente:
      </Paragraph>

      <DropzoneCustom
        maxFiles={1}
        fileHandler={fileHandler}
        acceptedFileTypes={['.xlsx']}
        handlerFileAfterChange={true}
      />

      <div
        style={{
          marginTop: '2rem',
          marginBottom: '2.5rem',
          border: '1px solid gray',
        }}
      />
      <>
        {allData && (
          <Grid container>
            <Grid
              item
              xs={12}
            >
              <ContentCard
                title={
                  responseHeaders
                    ? 'Validar contratos'
                    : 'Contratos passiveis de tentar novamente'
                }
              >
                <SearchableTable
                  columns={responseHeaders ? COLUMNRESPONSE : COLUMN}
                  rowsValues={getRows(allData, responseHeaders)}
                  noItemsText='Nenhuma contrato passível de tentar novamente'
                  rerender={rerender}
                />
              </ContentCard>
            </Grid>
          </Grid>
        )}
        {allData && includedFile && (
          <div style={{ marginTop: '3rem' }}>
            {responseHeaders ? (
              <GradientButton
                text='Recarregar'
                handleClick={() => window.location.reload()}
              />
            ) : (
              <GradientButton
                text='Salvar'
                handleClick={() => onSubmit()}
              />
            )}
          </div>
        )}
      </>
    </PageView>
  );
};

export default TryAgain;

import React from 'react';
import PropTypes from 'prop-types';

import { BaseCard } from '../BaseCard';

import { Header } from './styles';

export const Card = ({ icon, title, children }) => {
  return (
    <BaseCard>
      <Header>
        {icon}
        <h3>{title}</h3>
      </Header>
      <main>{children}</main>
    </BaseCard>
  );
};

Card.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

import { Grid, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import PaymentDetailsForm from '../Forms/PaymentDetailsForm';
import GradientButton from '../GradientButton';
import { ThemeContext } from '../../contexts/themeContext';
import { Heading2 } from '../Interface/Text/';

import { generatePayloadFieldsModifieds } from '../../helpers/form/generatePayloadFieldsModifieds';
import ContractsAdminService from '../../services/administrator/contractDetails.service';
import adminStores from '../../store/admin/contractDetails/index.store';
import { updateAdminStore } from '../../store/admin/contractDetails/helpers';
import theme from '../../theme';

const PaymentSection = ({ contract = null, isMobile, isAdminPage }) => {
  const {
    paymentMethod: { paymentMethod },
    contract: {
      contract: { id: contract_id },
    },
    location: {
      location: { user_id },
    },
  } = adminStores();

  const [isEditing, setIsEditing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { themeValue } = useContext(ThemeContext);

  const updatePayment = async (formData, { setSubmitting }) => {
    setSubmitting(true);
    try {
      const paymentPayload = generatePayloadFieldsModifieds(
        formData,
        paymentMethod
      );
      if (!paymentMethod.id) {
        Object.assign(paymentPayload, { contract_id, user_id });
      }
      const payload = {
        payment_methods: { id: paymentMethod.id, ...paymentPayload },
      };

      if (Object.keys(payload).length > 0) {
        const dataUpdated = await ContractsAdminService.update(payload);
        updateAdminStore(dataUpdated);
      }

      setIsEditing(false);
      setSubmitting(false);
      setExpanded(false);
    } catch (err) {
      const errorCause = err?.data?.errors?.record_invalid;
      if (errorCause.split(':').includes(' Number is invalid')) {
        toast.error('Número do cartao inválido');
      }
    }
  };

  const handleIsEditing = () => {
    setIsEditing(true);
    setExpanded(true);
  };

  return (
    <MainContainer
      color={
        themeValue?.primary_btn_bg ||
        'linear-gradient(140deg, #f3d23c 0%, orange 100%)'
      }
    >
      {!isAdminPage ? (
        <TitleContainerNotAdmin>
          <h4 style={{ color: themeValue?.text_color || '#61787b' }}>
            Dados de Pagamento
          </h4>
        </TitleContainerNotAdmin>
      ) : (
        <TitleContainer>
          <Heading2>Dados de Pagamento</Heading2>
        </TitleContainer>
      )}
      {isAdminPage ? (
        <AdminInnerContainer>
          {isEditing && (
            <PaymentDetailsForm
              onSubmit={updatePayment}
              onCancel={() => setIsEditing(false)}
              readonly={!isEditing}
              values={{ ...paymentMethod, expanded, isAdminPage }}
              expanded={expanded}
              isAdminPage={isAdminPage}
            />
          )}
          {!isEditing && (
            <PaymentDetailsForm
              values={paymentMethod}
              expanded={expanded}
              readonly
              isAdminPage={isAdminPage}
            />
          )}
        </AdminInnerContainer>
      ) : (
        <Grid
          container={true}
          spacing={1}
        >
          <Grid
            item
            xs={12}
            lg={8}
          >
            {isEditing && (
              <PaymentDetailsForm
                onSubmit={updatePayment}
                onCancel={() => setIsEditing(false)}
                readonly={!isEditing}
                values={{ ...paymentMethod, expanded }}
                expanded={expanded}
              />
            )}
            {!isEditing && (
              <PaymentDetailsForm
                values={paymentMethod}
                expanded={expanded}
                readonly
              />
            )}
          </Grid>
        </Grid>
      )}
      {!isEditing && (
        <Grid
          container={true}
          spacing={1}
        >
          <Grid
            item
            xs={6}
            lg={4}
          >
            <Grid
              container
              justify='flex-start'
            >
              <GradientButton
                paddingg='10px 30px'
                text={paymentMethod ? 'Alterar' : 'Adicionar'}
                rectangle
                handleClick={() => handleIsEditing()}
              />
            </Grid>
          </Grid>
          {isMobile ? (
            <Grid
              item
              xs={6}
              lg={4}
            >
              <div className='d-flex justify-content-end'>
                <IconButton className='expandBtn'>
                  {expanded ? (
                    <ExpandLessIcon
                      style={{ color: 'white' }}
                      onClick={() => setExpanded(!expanded)}
                    />
                  ) : (
                    <ExpandMoreIcon
                      style={{ color: 'white' }}
                      onClick={() => setExpanded(!expanded)}
                    />
                  )}
                </IconButton>
              </div>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              lg={12}
              style={{ marginTop: '1.25em' }}
            >
              <div className='d-flex justify-content-end'>
                <IconButton
                  className='expandBtn'
                  onClick={() => setExpanded(!expanded)}
                >
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
            </Grid>
          )}
        </Grid>
      )}
    </MainContainer>
  );
};

PaymentSection.propTypes = {
  contract: PropTypes.object,
};

export default PaymentSection;

const MainContainer = styled.div`
  .expandBtn {
    background: ${theme.palette.secondary.main};
    color: ${theme.palette.primary.main};
  }
  padding: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
`;

const TitleContainer = styled.div`
  margin: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  > h5 {
    font-size: 1.5rem;
    color: ${theme.palette.primary.main};
    font-weight: bold;
  }
`;

const TitleContainerNotAdmin = styled.div`
  margin: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  > h4 {
    marginBottom: '25px',
    fontWeight: 'bold'
  }
`;

const AdminInnerContainer = styled.div``;

import styled, { css } from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  min-height: 100%;
  background: ${(props) => props.themeValue.page_user_background};
  padding-top: 3.75rem;

  @media (max-width: 479px) {
    margin-top: 1.5rem;
  }
`;

export const Content = styled.div`
  max-width: 428px;
  margin: 0 auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: 992px) {
    max-width: 1128px;
  }
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin: 0 0 0 0.25rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: ${theme.palette.primary.main};
  }

  svg {
    color: ${theme.palette.primary.main};
  }
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  h1 {
    margin: 0;

    color: ${theme.palette.primary.main};
    font-size: 1.5rem;
    font-weight: 700;
  }

  svg {
    color: ${theme.palette.primary.main};
  }
`;

export const Separator = styled.div`
  height: 1px;
  background: ${theme.palette.gray.main};
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-gap: 1.5rem;

  @media (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Card = styled.div`
  position: relative;
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);

  > p {
    margin-top: 1.5rem;
    margin-bottom: 0;
  }
`;

export const PropertyStatus = styled.div`
  /* margin-top: 24px; */

  font-weight: 700;
  font-size: 1.125rem;
  padding: 5px 10px;
  border-radius: 8px;

  ${({ status }) =>
    (status === 'registered' ||
      status === 'proposal_accepted' ||
      status === 'pending_signature' ||
      status === 'pending_activation' ||
      status === 'pending_transfer') &&
    css`
      background: ${theme.palette.purple.light};
      color: ${theme.palette.primary.main};
      strong {
        color: ${theme.palette.primary.main};
      }
    `}

  ${({ status }) =>
    status === 'active' &&
    css`
      background: ${theme.palette.primary.main};
      color: ${theme.palette.secondary.main};
      strong {
        color: ${theme.palette.secondary.main};
      }
    `}

  ${({ status }) =>
    status === 'pending_cancel' &&
    css`
      background: ${theme.palette.red.light};
      color: ${theme.palette.red.main};
      strong {
        color: ${theme.palette.red.main};
      }
    `}
  ${({ status }) =>
    status === 'cancelled' &&
    css`
      background: ${theme.palette.red.main};
      color: ${theme.palette.white.main};
      strong {
        color: ${theme.palette.white.main};
      }
    `}
  ${({ status }) =>
    status === 'suspended' &&
    css`
      background: ${theme.palette.gray.light};
      color: ${theme.palette.primary.main};
      strong {
        color: ${theme.palette.primary.main};
      }
    `}
`;

export const ContractButtons = styled.div`
  display: flex;
  gap: 1rem;

  button {
    width: 100%;
  }
`;

export const CardContent = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;

  button {
    max-width: 6rem;
    font-size: 1rem;
    padding: 0.25rem 0;

    &:disabled {
      font-size: 1rem;
    }
  }
`;

export const CardContent2 = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-top: 1.5rem;

  > p {
    margin: 0;
    font-weight: 400;
    font-size: 1.125rem;
    color: #000;
  }
`;

export const RowContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  margin-bottom: 0.5rem;

  button {
    width: 100%;
    height: 2rem;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 1rem;
    border-radius: 0px 0px 8px 8px;

    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const RadioboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const TableContainer = styled.table`
  margin-top: 1.5rem;

  width: 100%;

  th {
    font-size: 1.125rem;
    font-weight: 500;
    color: #4d5f63;
    padding: 0 0.25rem;
  }

  td {
    font-size: 1.125rem;
    font-weight: 300;
    color: #1f1b1e;
    text-align: center;

    strong {
      font-weight: 500;
    }
  }

  tfoot td {
    font-weight: 500;
  }

  thead {
    border-bottom: 0.5px solid #88aaab;
  }

  th,
  td {
    padding-bottom: 0.5rem;
  }

  tbody {
    tr:nth-child(1) td {
      padding-top: 0.5rem;
    }

    td {
      button {
        font-size: 1rem;
        width: 100%;
        padding: 0.375rem 1.75rem;

        @media (min-width: 480px) {
          padding: 0.375rem 3.563rem;
        }
      }
    }
  }

  tfoot tr td {
    padding-top: 0.5rem;
  }

  td:nth-child(n + 2):nth-child(-n + 3),
  th {
    text-align: center;
  }

  td:last-child {
    text-align: right;
    padding-left: 0.5rem;
  }
`;

export const PaidStatus = styled.div`
  padding: 0.375rem 1.75rem;
  border-radius: 8px;
  background: ${theme.palette.green.light};
  border: none;
  color: ${theme.palette.primary.main};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  svg {
    height: 1rem;
  }

  ${(props) =>
    props.isGift &&
    css`
      padding: 0.25rem 0.875rem;
      width: 100%;
    `}

  ${(props) =>
    props.isNewMyLocation &&
    css`
      padding: 0.25rem 0.875rem;
      width: 100%;
      max-width: 120px;
    `}
`;

export const Form = styled.form`
  /* display: flex;
  flex-direction: column;
  gap: 16px;
  /* margin-bottom: 24px; */

  /* > button {
    width: 100%;
    height: 32px;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 16px;

    border-radius: 0px 0px 8px 8px;

    svg {
      margin-right: 8px;
    }
  }  */
`;

export const SupportMessage = styled.span`
  font-weight: 300;
  font-size: 1rem;
  color: #000000;
`;

export const DuedateCustom = styled.td`
  ${(props) =>
    props.isOverdue &&
    css`
      color: #b3261e !important;
      font-weight: 500 !important;
    `}
`;

export const ValueInReais = styled.td`
  ${(props) =>
    props.isOverdue &&
    css`
      color: #b3261e !important;
      font-weight: 500 !important;
    `}
`;

export const RegisterCreditCardContainer = styled.div`
  button {
    padding: 0.563rem 0;
    font-size: 1.25rem;
  }
`;

export const PendenciesContent = styled.div`
  margin-top: 1.5rem;

  p {
    margin: 0;
    font-weight: 300;
    font-size: 1rem;
    color: ${theme.palette.primary.main};
  }
`;

export const DistributorContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;

  button:nth-child(4) {
    font-size: 1.125rem;
    padding: 0.563rem 0;
  }
`;

export const DistributorInfo = styled.div`
  display: grid;
  grid-gap: 1.5rem;
`;

export const AnchorButton = styled.a`
  font-weight: 500;
  font-size: 1.125rem;
  border: 1px solid ${theme.palette.primary.main};
  border-radius: 0.5rem;
  color: ${theme.palette.primary.main};
  padding: 0.5rem;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${theme.palette.purple.dark};
    border-color: ${theme.palette.purple.dark};
  }

  &:active {
    color: ${theme.palette.purple.light};
    border-color: ${theme.palette.purple.light};
  }
`;

export const DistributorSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.palette.gray.light};
`;

export const SignInData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const HelpButton = styled.button`
  background: #fffbfe;
  border: 0;
  padding: 0.25rem;
  border-radius: 50%;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const TooltipContainer = styled.div`
  padding: 1rem;
  background: #f4eff2;

  position: absolute;
  width: 248px;
  z-index: 5;
  top: -174px;
  right: 10px;
  border-radius: 8px 8px 0 8px;
  box-shadow: 0px 4px 8px 3px rgb(0 0 0 / 15%);
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));

  @media (min-width: 480px) {
    top: -228px;
    width: 328px;
    right: 14px;
  }

  @media (min-width: 992px) {
    top: -184px;
    right: 14px;
    width: 405px;
    border-radius: 8px 8px 0px 8px;
  }
`;

export const CloseButton = styled.button`
  border: 0;
  background: #f4eff2;
  padding: 0.25rem;
  transition: 0.2s;
  border-radius: 50%;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const Tooltip = styled.div`
  h6 {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: #1f1b1e;
  }

  p {
    margin: 0;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    font-weight: 300;
    color: #1f1b1e;
    left: 50%;
    bottom: calc(100% + 20px);

    &::before {
      content: '';
      border-style: solid;
      border-color: #f4eff2 transparent;
      border-width: 16px 0 0 16px;
      bottom: 20px;
      position: absolute;
      top: 100%;
      right: 0;

      @media (min-width: 992px) {
        /* left: 0; */
        border-width: 16px 0 0 16px;
        /* right: auto; */
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HelpMessage = styled.span`
  color: #1f1b1e;
  font-size: 0.875rem;
  font-weight: 400;
  margin-top: 0.5rem;
  display: block;
`;

export const AnchorButtonModal = styled.button`
  background: transparent;
  border: 0;
  text-decoration: underline;
  color: ${theme.palette.primary.main};
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const Label = styled.span`
  font-weight: 500;
  font-size: 1.125rem;
  color: ${theme.palette.primary.main};
`;

export const ContentSignInData = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardRegistered = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f4eff2;
  padding: 1rem 0.5rem;
  border-radius: 0.5rem;

  div {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  button {
    background: none;
    border: none;
  }

  span {
    font-size: 1rem;
    font-weight: 300;
    color: #000000;
  }

  ${(props) =>
    props.status === 'active' &&
    css`
      background: ${theme.palette.green.light};
    `}
`;

import styled from 'styled-components';
import InputWithMask from 'react-text-mask';
import { colors } from '../../styles/colors';

/// ////////////////////////////////////
// From Landing Page
export * from './atm.inputs';
export * from './mol.dialog';
export * from './mol.form-control';
/// ////////////////////////////////////

/// /////////////////////////////////////////////////////////////
// From Landing Page

export const Body = styled.p`
  font-family: 'Myriad-Pro';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.085em;
  color: #4d5f63;
  margin-bottom: 10px;
  padding-left: 20px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const MaskedInput = styled(InputWithMask)`
  letter-spacing: 0.085em;
  font-family: 'Myriad-Pro-Condensed';
  background: #e5e5e5;
  border: 1px solid #70a766;
  outline: none;
  border-radius: 2px;
  height: 33px;
  width: 389px;
  font-size: 20px;
  padding: 5px 5px 5px 8px;
  color: rgba(53, 106, 53, 0.5);
  font-weight: 300;
`;

export const FormWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.5);
`;

export const Checkbox = styled.span`
  background: ${(props) => (props.checked ? '#70a766' : 'unset')};
  width: 27.96px;
  height: 26.36px;
  border: 1px solid #70a766;
  box-sizing: border-box;
  border-radius: 2px;

  &:hover {
    cursor: pointer;
  }
`;

export const InputError = styled.span`
  font-size: 14px;
  line-height: 177.4%;
  letter-spacing: 0.085em;
  color: #ff4e4e;
`;

export const SubTitle = styled.p`
  font-size: 22px;
  color: #7a9196;
  margin: 20px;
  text-align: ${(props) => (props.center ? 'center' : 'initial')};
`;

export const H1 = styled.h1`
  color: ${colors.primary};
  font-size: 36px;
  text-align: ${(props) => (props.center ? 'center' : 'initial')};
`;

export const H2 = styled.h2`
  font-size: ${(props) => (props.size ? `${props.size}px` : '16px')};
  letter-spacing: 0.085em;
  color: ${colors.primary};
  margin-bottom: 10px;
  text-align: ${(props) => (props.center ? 'center' : 'initial')};
`;

export const H3 = styled.h3`
  font-size: ${(props) => (props.size ? `${props.size}px` : '14px')};
  letter-spacing: 0.085em;
  color: ${colors.primary};
  margin-bottom: 10px;
  text-align: ${(props) => (props.center ? 'center' : 'initial')};
`;

export const Links = styled.h3`
  font-size: 16px;
  letter-spacing: 0.085em;
  margin-bottom: 10px;
  text-decoration: underline;
  text-align: ${(props) => (props.center ? 'center' : 'initial')};
`;

export const Highlight = styled.span`
  color: #f9af40;
  font-weight: bold;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.direction === 'column' ? 'column' : 'row'};

  & input {
    background: unset;

    @media screen and (max-width: 1089px) {
      width: 100%;
    }
  }
`;

export * from './Input';
export * from './StatusLabel';
export * from './StatusToggle';
export * from './StyledLink';
export * from './TextColorized';
export * from './Anchor';
export * from './BubbleSpeech';
export * from './ProspectType';

import { create } from 'zustand';
import { inferedStores, storesConfigs } from './configurations';

const storesObject = {};
let setup = false;

const handleArray = (store, newData) => {
  const finalData = [];
  const usedIds = [];
  for (const newItem of newData) {
    let updated = false;
    for (const oldItem of store) {
      if (oldItem.id !== newItem.id) continue;
      updated = true;
      usedIds.push(oldItem.id);
      finalData.push({ ...oldItem, ...newItem });
      break;
    }
    if (!updated) finalData.push(newItem);
  }
  const unhandledRegisters = store.filter(({ id }) => !usedIds?.includes(id));

  return [...finalData, ...unhandledRegisters];
};

const getfinalPayloadFunction = (table, payload, isArray) => {
  if (typeof payload !== 'object') throw new Error('Payload must be an object');
  if (inferedStores.includes(table)) return (oldStore, payload) => payload;
  return isArray
    ? (oldStore, payload) => handleArray(oldStore, payload)
    : (oldStore, payload) => Object.assign(oldStore, payload);
};

const defaultCreateStore = (table, isArray) => {
  const INITIAL_STATE = { [table]: isArray ? [] : {} };

  const capitalizedTable = table[0].toUpperCase() + table.slice(1);

  return create((set) => ({
    ...INITIAL_STATE,
    [`set${capitalizedTable}`]: (payload) =>
      set((state) => {
        const updateFunction = getfinalPayloadFunction(table, payload, isArray);
        const newData = updateFunction(state[table], payload);

        return { ...state, [table]: newData };
      }),
    [`reset${capitalizedTable}`]: () => set(() => INITIAL_STATE),
  }));
};

const arrayStore = (table) => defaultCreateStore(table, true);

const objectStore = (table) => defaultCreateStore(table, false);

const functionPerStore = { array: arrayStore, object: objectStore };

if (!setup) {
  for (const functionName in functionPerStore) {
    const prepareStoreFunction = functionPerStore[functionName];
    const tables = storesConfigs[functionName];

    for (const table of tables) {
      const store = prepareStoreFunction(table);
      storesObject[table] = store;
    }
  }
  setup = true;
}

const adminStores = () => {
  const reset = [];
  const preparedStores = {};

  for (const table in storesObject) {
    const capitalizedTable = table[0].toUpperCase() + table.slice(1);
    const handledStore = storesObject[table].getState();
    preparedStores[table] = handledStore;
    const { [`reset${capitalizedTable}`]: resetFunction } = handledStore;
    reset.push(resetFunction);
  }
  preparedStores.reset = () =>
    reset.forEach((resetFunction) => resetFunction());
  return preparedStores;
};

export default adminStores;

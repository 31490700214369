import React from 'react';
import _ from 'lodash';

import { FaTrashAlt } from 'react-icons/fa';
import { StyledLink } from '../../../components/Interface';
import { Paragraph } from '../../../components/Interface/Text/Paragraph';
import { centsToDollars } from '../../../helpers/payment';
import { ContractStatusLabel } from '../../../components/ContractStatusLabel';
import { ADMIN_CONTRACT_STATUS_TYPES_PTBR } from '../../../helpers/contractStatusTypes';
// NEW:
import Loader from '../../../components/Loader';
// FINAL

export const COLUMNS = [
  { name: 'Contrato' },
  { name: 'Volume', align: 'center' },
  { name: 'Valor mensal', align: 'center' },
  { name: 'Status', align: 'center' },
  { name: 'Conta de Luz', align: 'right' },
];

export const getRows = (contracts) => {
  return contracts.map(
    ({ contractId, volume, value_cents, status, attachment }) => ({
      values: [
        {
          value: (
            <StyledLink to={`/contratos/${contractId}`}>
              Contrato {contractId}
            </StyledLink>
          ),
        },
        {
          value: (
            <Paragraph align='right'>
              {parseFloat(volume).toLocaleString('pt-BR') || 'NaN'} kWh
            </Paragraph>
          ),
        },
        {
          value: (
            <Paragraph align='right'>
              R$ {parseFloat(value_cents / 100).toLocaleString('pt-BR')}
            </Paragraph>
          ),
        },
        {
          value: (
            <Paragraph align='right'>
              {ADMIN_CONTRACT_STATUS_TYPES_PTBR[status]}
            </Paragraph>
          ),
        },
        {
          value: (
            <Paragraph align='right'>{attachment ? 'Sim' : 'Não'}</Paragraph>
          ),
        },
        // {
        //   value: (
        //     <ContractStatusLabel contract={{ status, proposal_ready }} bold />
        //   ),
        //   align: 'center',
        // },
      ],
    })
  );
};

export const getActionIcon = (id, remove, isDeleting) => {
  return isDeleting ? (
    <Loader fontSize={20} />
  ) : (
    <FaTrashAlt onClick={() => remove(id)} />
  );
};

export const getLocationsForTableCols = () => [
  { name: 'Apelido' },
  // { name: 'Tipo', align: 'center' },
  { name: '', align: 'center' },
];
export const getLocationsForTableRows = (
  locations,
  removeLocation,
  deletingRows
) => {
  return locations.map(({ id, nickname, category }) => ({
    values: [
      { value: nickname },
      {
        value: getActionIcon(id, removeLocation, deletingRows.includes(id)),
        align: 'center',
      },
    ],
  }));
};

export const mergeHolderInLocation = (contractPayload, location) => {
  const mergedObj = { ...location, ...contractPayload };
  delete mergedObj.location_ids;
  return mergedObj;
};

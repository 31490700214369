import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  max-width: 480px;
  padding: 1.5rem;

  h2 {
    font-size: 1.5rem;
    font-weight: 700;

    text-align: center;
    margin: 0;
  }

  /* @media (min-width: 0px) {
  } */

  /* @media (min-width: 576px) {
  } */
`;

export const Header = styled.div`
  position: relative;
  width: 100%;

  h2 {
    color: ${theme.palette.primary.main};
  }
`;

export const Content = styled.div`
  > p {
    margin: 0;
    font-weight: 300;
    font-size: 1rem;
    color: #1f1b1e;
    line-height: 1.188rem;
  }

  > p + p {
    margin-top: 0.5rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;

  button {
    height: 2.75rem;
    font-size: 1.125rem;
  }
`;

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import PageView from '../../../containers/PageView';
import ProviderCpflService from '../../../services/administrator/ManagementBases/providerCpfl.service';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { StyledLink, StatusLabel } from '../../../components/Interface';
import { Paragraph } from '../../../components/Interface/Text/Paragraph';

import { getStatusColor } from '../../../styles/colors';
import ContentCard from '../../../components/ContentCard';
import styled from 'styled-components';
import LoadMore from '../../../components/LoadMore';
import { ADMIN_CONTRACT_STATUS_TYPES_PTBR } from '../../../helpers/contractStatusTypes';
import GradientButton from '../../../components/GradientButton';
import { formatCpfCpnj } from '../Proposal';

const COLUMNS = [
  // { name: 'User' },
  { name: 'Nome' },
  { name: 'Contrato' },
  { name: 'Documento' },
  { name: 'PN' },
  { name: 'Unidade Consumidora' },
  { name: 'Status', align: 'center' },
  { name: 'Rua' },
  { name: 'Distribuidora' },
  { name: 'Gerador' },
  { name: 'Email' },
  { name: 'Email do Signatário' },
  { name: 'Email de Pagamento' },
  { name: 'Tempo Aguardando Ativação' },
  { name: 'Rateio(%)' },
  { name: 'Data de envio para Distribuidora' },
  { name: 'Data de leitura' },
];

const papaparseOptions = {
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true,
};
const validadeData = (data, index) => {
  try {
    const { Contrato, 'Data de Ativação': activationDate } = data;
    if (!Contrato) return;
    if (Contrato && !activationDate)
      throw `O contrato ${Contrato}, está sem data de ativação, na linha ${
        index + 2
      }`;
    const activatedAt = Date.parse(activationDate);
    if (Number.isNaN(activatedAt)) {
      throw `O contrato ${Contrato}, está com a data de ativação errada, na linha ${
        index + 2
      }, remova o contrato ou corrija a data`;
    }
    return {
      id: Contrato,
      activated_at: Date.parse(activationDate),
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getRows = (contracts) => {
  return contracts.map((contract) => {
    const {
      user_id,
      id,
      name,
      document,
      install_code,
      pn,
      street,
      neighborhood,
      complement,
      state,
      number,
      postal_code,
      provider,
      producer,
      signed_at,
      share,
      email,
      signer_email,
      payment_method_email,
      batch_date,
      holder,
      status,
      read_date,
    } = contract;
    return {
      values: [
        {
          value: (
            <StyledLink to={`/usuarios/${user_id}`}>{`${holder}`}</StyledLink>
          ),
        },
        {
          value: (
            <StyledLink
              tag='true'
              to={`/contratos/${id}`}
            >{`${id}`}</StyledLink>
          ),
        },
        {
          value: (
            <Paragraph>{document ? formatCpfCpnj(document) : ''}</Paragraph>
          ),
        },
        {
          value: <Paragraph>{pn || ''}</Paragraph>,
        },
        {
          value: <Paragraph>{install_code || ''}</Paragraph>,
        },
        {
          value: (
            <StatusLabel status={getStatusColor(status)}>
              {ADMIN_CONTRACT_STATUS_TYPES_PTBR[status]}
            </StatusLabel>
          ),
          align: 'center',
        },
        {
          value: (
            <Paragraph>
              {` ${street || ''}`}
              {number ? `, ${number}` : ''}
              {complement ? `, ${complement}` : ''}
              {neighborhood ? `, ${neighborhood}` : ''}
              {state ? `, ${state}` : ''}
              {postal_code ? `, ${postal_code}` : ''}
            </Paragraph>
          ),
        },
        {
          value: <Paragraph>{provider}</Paragraph>,
        },
        {
          value: <Paragraph>{producer}</Paragraph>,
        },
        {
          value: <Paragraph>{email}</Paragraph>,
        },
        {
          value: <Paragraph>{signer_email}</Paragraph>,
        },
        {
          value: <Paragraph>{payment_method_email}</Paragraph>,
        },
        {
          value: <Paragraph>{moment(signed_at).fromNow()}</Paragraph>,
        },
        {
          value: (
            <Paragraph>
              {parseFloat(parseFloat(share).toFixed(2)).toLocaleString('pt-BR')}
            </Paragraph>
          ),
        },
        {
          value: (
            <Paragraph>
              {batch_date?.split('T')[0].split('-').reverse().join('/') || ''}
            </Paragraph>
          ),
        },
        {
          value: <Paragraph>{read_date}</Paragraph>,
        },
      ],
    };
  });
};

const Contracts = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    contracts: [],
  });
  const [page, setPage] = useState(0);
  const [loadingg, setLoadingg] = useState(false);
  const [noMore, setNoMore] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        setLoadingg(true);
        const resources = await ProviderCpflService.getAll(page);

        if ((!resources || resources.length === 0) && page === 0) {
          setPageState((state) => ({
            ...state,
            loading: false,
            contracts: [],
          }));
        }
        resources.sort((a, b) => {
          return a.status_updated_at - b.status_updated_at;
        });
        if (!resources || resources?.length <= 0) {
          setNoMore(true);
          return;
        } else {
          setNoMore(false);
        }
        setPageState((state) => ({
          ...state,
          loading: false,
          contracts: [...state.contracts, ...resources],
        }));
        setLoadingg(false);
      } catch (err) {
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };

    fetchContracts();
  }, [page]);

  const fetchAll = async () => {
    const rows = [],
      limit = 5000;

    let page = 0,
      data = [];
    do {
      data = await ProviderCpflService.getAll(page, limit);
      if (data.length > 0) rows.push(...data);
      page += 1;
    } while (data.length === limit); //Se retornou menos que o limit, é que não terá dados em uma proxima requisição
    return getRows(rows);
  };

  const { loading, hasError, contracts } = pageState;

  return (
    <PageView
      title='Set Up Distribuidora'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'start',
          marginBottom: '1rem',
          marginRight: '2rem',
        }}
      >
        <Paragraph style={{ marginRight: '2rem' }}>
          Upload de Contratos que deseja ativar
        </Paragraph>

        <GradientButton
          paddingg='10px 30px'
          text='Baixar Modelo'
          handleClick={() => navigate('/massActivation')}
          rectangle
        />
      </div>
      {/* {final && (
        <StyledLink
          to={{
            pathname: '/massActivation',
            state: {
              final,
            },
          }}
          ref={contractsCheck}
        />
      )} */}
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <ContentCard>
            <SearchableTable
              columns={COLUMNS}
              rowsValues={getRows(contracts)}
              noItemsText='Nenhum contrato'
              fetchAll={fetchAll}
              loadingg={exportLoading}
            />
          </ContentCard>
          {!noMore && (
            <LoadMore
              loadingg={loadingg}
              setPage={setPage}
            />
          )}
        </Grid>
      </Grid>
    </PageView>
  );
};

export default Contracts;

const CpfDiv = styled.div`
  input {
    color: #44606c;
  }
`;

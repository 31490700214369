export const errorMessageToField = {
  holder: 'O campo deve conter apenas letras e espaços',
  document: 'CPF/CNPJ inválido',
};

const signerErrorhandlerOptions = {
  isTheHolder: false,
  disableIsTheHolderChange: false,
};

export const notDefaultKeys = ['options'];

export const extraPayloadPerField = {
  holder: { options: signerErrorhandlerOptions },
  document: { options: signerErrorhandlerOptions },
};

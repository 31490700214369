/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Select from 'react-select';
import {
  CustomErrorMessage,
  CustomField,
  FieldWrapper,
  Label,
} from '../../../../../../components/Interface/Form';
import { Heading3 } from '../../../../../../components/Interface/Text';
import adminStores from '../../../../../../store/admin/contractDetails/index.store';

const RegistrationData = ({
  selectHandler,
  values,
  readonly,
  selectValues,
}) => {
  const [providersTypesOptions, setProvidersTypeOptions] = useState([]);
  const stores = adminStores();
  const {
    providers: { providers },
  } = stores;

  useEffect(() => {
    const options = providers.map((p) => ({ value: p.id, label: p.name }));
    setProvidersTypeOptions(options);
  }, [providers]);

  return (
    <Grid
      container
      spacing={1}
      rowSpacing={1}
    >
      <Grid
        item
        xs={12}
      >
        <Heading3>Dados Cadastrais</Heading3>
      </Grid>

      <Grid
        item
        xs={12}
        lg={6}
        xl={4}
      >
        <FieldWrapper>
          <Label htmlFor='provider'>Distribuidora:</Label>
          <Select
            onChange={(el) => selectHandler({ provider_id: el.value })}
            value={providersTypesOptions.find(
              (el) => el.value === selectValues.provider_id
            )}
            options={providersTypesOptions}
            fullWidth
            isDisabled={readonly}
          />
          <CustomErrorMessage name='provider' />
        </FieldWrapper>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        xl={4}
      >
        <FieldWrapper>
          <Label htmlFor='state'>Estado:</Label>
          <CustomField
            name='state'
            type='string'
            disabled={readonly}
            fullwidth
          />
          <CustomErrorMessage name='state' />
        </FieldWrapper>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        xl={4}
      >
        <FieldWrapper>
          <Label htmlFor='city'>Cidade:</Label>
          <CustomField
            name='city'
            type='string'
            disabled={readonly}
            fullwidth
          />
          <CustomErrorMessage name='city' />
        </FieldWrapper>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        xl={4}
      >
        <FieldWrapper>
          <Label htmlFor='billing_day'>Dia de Faturamento:</Label>
          <CustomField
            name='billing_day'
            type='number'
            disabled={readonly}
            fullwidth
          />
          <CustomErrorMessage name='billing_day' />
        </FieldWrapper>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        xl={4}
      >
        <FieldWrapper>
          <Label htmlFor='install_code'>Código da UC / Instalação:</Label>
          <CustomField
            name='install_code'
            type='number'
            disabled={readonly}
            fullwidth
          />
          <CustomErrorMessage name='install_code' />
        </FieldWrapper>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        xl={4}
      >
        <FieldWrapper>
          <Label htmlFor='pn'>Código de cliente / PN:</Label>
          <CustomField
            name='pn'
            type='number'
            disabled={readonly}
            fullwidth
          />
          <CustomErrorMessage name='pn' />
        </FieldWrapper>
      </Grid>
    </Grid>
  );
};
export default RegistrationData;

export const URLS_PROVIDERS = {
  ame: 'https://www.sgeeasy.com.br/#',
  ceau: 'https://al.equatorialenergia.com.br/',
  cebdis: 'https://agenciavirtual.neoenergiabrasilia.com.br/',
  ceee_d: 'https://rs.equatorialenergia.com.br/login',
  celcesc_dis: 'https://agenciaweb.celesc.com.br/AgenciaWeb/',
  celg_d: 'https://go.equatorialenergia.com.br/',
  celpe:
    'https://servicos.neoenergiapernambuco.com.br/area-logada/Paginas/login.aspx',
  cemar: 'https://ma.equatorialenergia.com.br/',
  cemig: 'https://atende.cemig.com.br/Login',
  ceron: 'https://servicos.energisa.com.br/login',
  coelba:
    'https://servicos.neoenergiacoelba.com.br/area-logada/Paginas/login.aspx',
  copel_dis: 'https://avanl.copel.com/avaweb/paginaLogin/login.jsf',
  cosern:
    'https://servicos.neoenergiacosern.com.br/area-logada/Paginas/login.aspx',
  cpfl_paulista: 'https://servicosonline.cpfl.com.br/agencia-webapp/#/login',
  cpfl_piratininga: 'https://servicosonline.cpfl.com.br/agencia-webapp/#/login',
  cpfl_santa_cruz: 'https://servicosonline.cpfl.com.br/agencia-webapp/#/login',
  edp_es:
    'https://www.edponline.com.br/para-sua-casa/login?returnUrl=%2Fservicos%2Fextrato-de-contas',
  edp_sp:
    'https://www.edponline.com.br/para-sua-casa/login?returnUrl=%2Fservicos%2Fextrato-de-contas',
  elektro: 'https://agencia.neoenergiaelektro.com.br/login.aspx',
  emg: 'https://servicos.energisa.com.br/login',
  ems: 'https://servicos.energisa.com.br/login',
  emt: 'https://servicos.energisa.com.br/login',
  enel_ce:
    'https://www.enel.com.br/pt-ceara/login.html?commonAuthCallerPath=%2Fsamlsso&forceAuth=false&passiveAuth=false&spEntityID=ENEL_CEA_WEB_BRA&tenantDomain=carbon.super&sessionDataKey=b9ecd5e9-76f0-47cd-a7c8-58f57629ea36&relyingParty=ENEL_CEA_WEB_BRA&type=samlsso&sp=ENEL_CEA_WEB_BRA&isSaaSApp=false&authenticators=FacebookAuthenticator%3Afacebook%3BGoogleOIDCAuthenticator%3Agoogle%3BOpenIDConnectAuthenticator%3Aapple_eebrcea%3BEnelCustomBasicAuthenticator%3ALOCAL',
  enel_rj:
    'https://www.enel.com.br/pt/login.html?commonAuthCallerPath=%2Fsamlsso&forceAuth=false&passiveAuth=false&spEntityID=ENEL_RJO_WEB_BRA&tenantDomain=carbon.super&sessionDataKey=a77e1dec-db33-4577-8c00-e9191dbef54a&relyingParty=ENEL_RJO_WEB_BRA&type=samlsso&sp=ENEL_RJO_WEB_BRA&isSaaSApp=false&authenticators=OpenIDConnectAuthenticator%3Aapple_eebrrjo%3BFacebookAuthenticator%3Afacebook%3BGoogleOIDCAuthenticator%3Agoogle%3BEnelCustomBasicAuthenticator%3ALOCAL',
  enel_sp:
    'https://portalhome.eneldistribuicaosp.com.br/#/autenticacao/tipo-de-acesso',
  epb: 'https://servicos.energisa.com.br/login',
  equatorial_al: 'https://al.equatorialenergia.com.br/',
  equatorial_pa: 'https://pa.equatorialenergia.com.br/',
  equatorial_pi: 'https://pi.equatorialenergia.com.br/',
  ese: 'https://servicos.energisa.com.br/login',
  ess: 'https://servicos.energisa.com.br/login',
  eto: 'https://servicos.energisa.com.br/login',
  light: 'https://agenciavirtual.light.com.br/Portal/Acesso_Servicos.aspx',
  rge: 'https://servicosonline.cpfl.com.br/agencia-webapp/#/login',
};

export const URLS_CREATE_ACCOUNT = {
  cpfl_paulista:
    'https://staging-flora-sa-east-1.s3.sa-east-1.amazonaws.com/public/CPFL_Criar+minha+conta.pdf',
  light:
    'https://staging-flora-sa-east-1.s3.sa-east-1.amazonaws.com/public/LIGHT_Criar+minha+conta.pdf',
  cemig:
    'https://staging-flora-sa-east-1.s3.sa-east-1.amazonaws.com/public/CEMIG_Criar+minha+conta.pdf',
  enel_sp:
    'https://staging-flora-sa-east-1.s3.sa-east-1.amazonaws.com/public/Criar+minha+conta+Enel+SP.pdf',
};

export const URLS_RECOVER_PASSWORD = {
  cpfl_paulista:
    'https://staging-flora-sa-east-1.s3.sa-east-1.amazonaws.com/public/CPFL_Recuperar+minha+senha.pdf',
  light:
    'https://staging-flora-sa-east-1.s3.sa-east-1.amazonaws.com/public/LIGHT_Recuperar+minha+senha.pdf',
  cemig:
    'https://staging-flora-sa-east-1.s3.sa-east-1.amazonaws.com/public/CEMIG_Recuperar+minha+senha.pdf',
  enel_sp:
    'https://staging-flora-sa-east-1.s3.sa-east-1.amazonaws.com/public/Como+recuperar+minha+senha+Enel+SP.pdf',
};

export const providersCodeWithUrl = [
  ...new Set(
    Object.keys(URLS_CREATE_ACCOUNT),
    Object.keys(URLS_RECOVER_PASSWORD)
  ),
];

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  max-width: 480px;
  padding: 1.5rem;

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
  }
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -0.438rem;
  right: -0.875rem;
  border: 0;
  background: #fbfdff;
  transition: filter 0.2s;
  padding: 0.5rem;
  border-radius: 50%;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const Content = styled.div`
  > p {
    margin: 0;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.188rem;
    color: #1f1b1e;
  }

  strong {
    font-weight: 500;
  }

  > p + p {
    margin-top: 0.5rem;
  }
`;

export const AnchorButton = styled.a`
  height: 2rem;
  width: 100%;
  border: 1px solid #f9af40;
  border-radius: 8px;
  color: #f9af40;
  font-weight: 500;
  font-size: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #ffefd8;
    color: #d89025;
    border-color: #d89025;
  }
`;

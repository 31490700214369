import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CircularProgress, TextField } from '@mui/material';
import { IoIosCloseCircle } from 'react-icons/io';
import { Paragraph } from '../Interface/Text';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DatePickerPeriod from '../DatePickerPeriod/DatePickerPeriod';
import Textarea from '../Interface/TextareaAutosize';

const initialFilterTerm = { text: '', value: '' };

const Filter = ({
  hasFilter,
  hasPeriod,
  columns = [],
  setFilter = () => {},
  setPeriod,
  setReload = () => {},
  isLoading,
  period,
  loadAllData = null,
}) => {
  const [optionsColumns, setOptionsColumns] = useState(columns);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [anchorElPeriod, setAnchorElPeriod] = useState(null);
  const [typeFilterSelected, setTypeFilterSelected] = useState(null);
  const [editingAccessor, setEditingAccessor] = useState(null);
  const [filterSelected, setFilterSelected] = useState([]);
  const [term, setTerm] = useState(initialFilterTerm);
  const open = Boolean(anchorEl);
  const openFilter = Boolean(anchorElFilter);
  const openPeriod = Boolean(anchorElPeriod);
  const [openAfterAdded, setOpenAfterAdded] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickPeriod = (event) => {
    setAnchorElPeriod(event.currentTarget);
  };

  const handleClickFilter = (event, accessor, term) => {
    setEditingAccessor(accessor);
    setTerm(term);
    setAnchorElFilter(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElFilter(null);
    setAnchorElPeriod(null);
  };

  const addFilter = (data) => {
    const newFilter = { ...data };
    setTypeFilterSelected(data?.type);
    setOpenAfterAdded(true);
    setFilterSelected((prevState) => [...prevState, newFilter]);
    handleClose();
    setReload(true);
  };

  const defineTermFilter = () => {
    const newFilter = filterSelected.map((filter) => {
      if (filter.accessor === editingAccessor) filter.term = term;
      return filter;
    });
    setFilterSelected(newFilter);
    setTerm(initialFilterTerm);
    handleClose();
    setReload(true);
  };

  const onKeyDownFilter = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter' && e.ctrlKey) {
      defineTermFilter(term);
    }
  };

  const removeFilter = (e, accessor) => {
    e.preventDefault();
    e.stopPropagation();
    handleClose();
    setFilterSelected((prevState) =>
      prevState.filter((f) => f.accessor !== accessor)
    );
    setReload(true);
  };

  const handleTerm = (term) => {
    setTerm(term);
  };

  const definePeriod = (period) => {
    setPeriod(period);
    setReload(true);
  };

  useEffect(() => {
    setOptionsColumns(
      columns.filter(
        (c) =>
          !filterSelected.map((f) => f.accessor).includes(c.accessor) &&
          c?.searchable !== false
      )
    );

    const divElement = document.getElementById(
      `filter-${filterSelected[[filterSelected.length - 1]]?.accessor}-button`
    );
    if (divElement && openAfterAdded) {
      divElement.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      setOpenAfterAdded(false);
    }
    if (!filterSelected.some((f) => typeof f?.term === 'undefined'))
      setFilter(filterSelected);
  }, [filterSelected]);

  const renderMenuDefineFilter = useMemo(
    () => (
      <Menu
        id='filter-menu'
        anchorEl={anchorElFilter}
        open={openFilter}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'filter-menu',
        }}
      >
        <MenuContainer>
          {typeFilterSelected === 'date' ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  label='Selecione uma data'
                  defaultValue={term?.value}
                  onChange={(date) =>
                    setTerm({
                      text: new Date(date).toLocaleDateString('pt-br'),
                      value: new Date(date).toISOString(),
                    })
                  }
                  format='DD/MM/YYYY'
                />
              </DemoContainer>
            </LocalizationProvider>
          ) : (
            <Textarea
              placeholder='Adicione itens separando por ";" ou "enter"'
              handleTerm={handleTerm}
              defaultValue={
                Array.isArray(term?.value) ? term?.value.join(';') : term?.value
              }
              onKeyDownFilter={onKeyDownFilter}
              size='small'
              maxRows={5}
            />
          )}

          <Button
            onClick={defineTermFilter}
            size='small'
          >
            Adicionar
          </Button>
        </MenuContainer>
      </Menu>
    ),
    [anchorElFilter, openFilter, typeFilterSelected, setTerm, defineTermFilter]
  );
  return (
    <>
      <FlexRow padding='1rem 0'>
        <FilterContainer>
          {hasFilter ? (
            <>
              {filterSelected.map((filter) => (
                <FilterTag
                  id={`filter-${filter.accessor}-button`}
                  aria-controls={openFilter ? `filter-button` : undefined}
                  aria-haspopup='true'
                  aria-expanded={openFilter ? 'true' : undefined}
                  onClick={(e) =>
                    handleClickFilter(e, filter.accessor, filter.term)
                  }
                >
                  <Paragraph>
                    {filter.Header}
                    {filter?.term?.text ? (
                      <span>
                        :{' '}
                        <SpanFilter>{`${filter.term.text.substring(0, 10)}${
                          filter.term.text.length > 10 ? '...' : ''
                        }`}</SpanFilter>
                      </span>
                    ) : (
                      ''
                    )}
                  </Paragraph>
                  <IoIosCloseCircle
                    style={{ fontSize: 20 }}
                    onClick={(e) => removeFilter(e, filter.accessor)}
                  />
                </FilterTag>
              ))}
              {setFilter && optionsColumns?.length ? (
                <Button
                  id='filter-add-button'
                  aria-controls={open ? 'filter-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  size='small'
                  variant='outlined'
                >
                  Adicionar Filtro
                </Button>
              ) : null}
            </>
          ) : null}

          {hasPeriod ? (
            <Button
              id='filter-period-button'
              aria-controls={openPeriod ? 'add-period-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openPeriod ? 'true' : undefined}
              onClick={handleClickPeriod}
              size='small'
              variant='outlined'
            >
              <>
                {period?.from || period?.to ? (
                  <>
                    Período:{' '}
                    {new Date(period?.from).toLocaleDateString('pt-br')} -{' '}
                    {new Date(period?.to).toLocaleDateString('pt-br')}
                  </>
                ) : (
                  <>Definir período</>
                )}
              </>
            </Button>
          ) : null}

          {/* {loadAllData ? (
            <Button
              id='filter-period-button'
              aria-controls={openPeriod ? 'add-period-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={openPeriod ? 'true' : undefined}
              onClick={loadAllData}
              size='small'
              variant='outlined'
            >
              Carregar todos os dados
            </Button>
          ) : null} */}

          {isLoading ? (
            <CircularProgress
              style={{ width: 20, height: 20, margin: '0 10px' }}
            />
          ) : null}
        </FilterContainer>
      </FlexRow>
      <Menu
        id='add-filter-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'filter-add-button',
        }}
      >
        {optionsColumns.map((collumn) => (
          <MenuItem
            id={collumn.accessor}
            key={collumn.accessor}
            onClick={() => addFilter(collumn)}
          >
            {collumn.Header}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        id='add-period-menu'
        anchorEl={anchorElPeriod}
        open={openPeriod}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'filter-period-button',
        }}
      >
        <DatePickerPeriod
          setPeriod={definePeriod}
          startDate={period?.from}
          endDate={period?.to}
        />
      </Menu>
      {renderMenuDefineFilter}
    </>
  );
};

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  gap: 1rem;
  ${(props) =>
    props.padding &&
    css`
      padding: ${props?.padding || '16px'};
    `}
`;
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FilterTag = styled.div`
  background: #ecf7f4;
  padding: 8px 16px;
  border-radius: 8px;
  margin-right: 8px;
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const MenuContainer = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;
`;
const SpanFilter = styled.span`
  background: #c3dddf;
  padding: 4px 8px;
  border-radius: 5px;
`;

export default Filter;

import styled from 'styled-components';

const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;

export default FlexRowContainer;

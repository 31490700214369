import styled, { css } from 'styled-components';
import theme from '../../theme';

const isActive = css`
  > div:first-child {
    background: ${theme.palette.primary.main};
  }

  > div > div {
    background: ${theme.palette.purple.dark};
  }

  h6 {
    color: ${theme.palette.primary.main};
    font-weight: 700;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  div:first-child > div:first-child {
    display: none;
  }
`;

export const Step = styled.div`
  display: flex;
  align-items: center;

  div {
    height: 3rem;
    width: 3rem;
    color: #fffbfe;
    background: #949092;
    font-weight: 700;
    font-size: 1.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  h6 {
    color: #949092;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.313rem;
  }
`;

export const CircleConnection = styled.div`
  width: 3rem;
  height: 0.25rem;
  background: #949092;
  transform: rotate(90deg);
`;

export const Content = styled.div`
  ${(props) => props.isActive && isActive}
`;

import connection from './connection';

const sendEmailsMassive = async (payload) => {
  try {
    const { html, subject, columns, rows } = payload;

    const data = await connection.post('/emails/send-emails-massive', {
      html,
      subject,
      columns,
      dataArray: rows,
    });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const sendEmailsService = { sendEmailsMassive };

export default sendEmailsService;

import React from 'react';
import PropTypes from 'prop-types';

import { Container, Step, CircleConnection, Content } from './styles';

export const StepProgressBar = ({ steps, stageActive }) => {
  return (
    <Container>
      {steps.map((step) => (
        <Content
          key={step.value}
          isActive={step?.value <= stageActive}
        >
          <CircleConnection />
          <Step>
            <div>{step?.circleValue}</div>
            <h6>{step?.labelText}</h6>
          </Step>
        </Content>
      ))}
    </Container>
  );
};

StepProgressBar.propTypes = {
  steps: PropTypes.array.isRequired,
  stageActive: PropTypes.number.isRequired,
};

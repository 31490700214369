import * as Yup from 'yup';
import { formErrors } from '../../../helpers/form/errors';

export const PromocodeFormSchema = Yup.object().shape({
  code: Yup.string()
    .max(40, formErrors.maxLength(40))
    .required(formErrors.required),
  contributors: Yup.number().required(formErrors.required),
  active_at: Yup.date()
    .max(new Date('2030-12-31'), 'Ano inválido')
    .min(new Date('2020-01-01'), 'Ano inválido')
    .required(formErrors.required)
});

import * as Yup from 'yup';

// UPDATE WHEN UPDATING DATA ON CLICKSIGN

const CompanyFormSchema = Yup.object().shape({
  holder: Yup.string(),
  // document: Yup.string().test(
  //   'cpfCnpjTest',
  //   formErrors.invalidCpfOrCnpj,
  //   (v) => {
  //     const chars = v && v.match(/\d/g);
  //     if (!chars) {
  //       return false;
  //     }

  //     return chars.length === 11 || chars.length === 14;
  //   }
  // ),
  // .required(formErrors.required)
  phone_number: Yup.string().test(
    'is-valid-phone-number',
    'O número de telefone é obrigatório quando o tipo é SMS ou WhatsApp',
    function (value) {
      const { type } = this.parent;
      if (type === 'sms' || type === 'whatsapp') {
        return value && value.length === 15;
      }
      return true;
    }
  ),
  email: Yup.string()
    .required('O email é obrigatório')
    .email('Digite um endereço de e-mail válido'),
});

export default CompanyFormSchema;

// phone_number: formValidations.phoneNumber().required(formErrors.required),

import React, { forwardRef, useEffect, useState, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useTable, useBlockLayout, useRowSelect } from 'react-table';
import CustomModal from '../ModalBaseManagement';
import _ from 'lodash';
import { FixedSizeList } from 'react-window';
import PropTypes from 'prop-types';
import Loading from './loading.svg';
import { StyledLink } from '../Interface';
import ModalRequestBaseManagement from '../ModalResquestBaseManagement';
import { isDateValid } from '../../helpers/date';
import ModalFormBaseManagement from '../ModalFormBaseManagement';

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <input
      type='checkbox'
      ref={resolvedRef}
      {...rest}
    />
  );
});

const Table = ({
  data: dataTable,
  hasUseRowSelect,
  setSelectedIds = () => {},
  maxHeighComponent,
  updateDataOnScroll,
  widthComponent,
  setHasUpdateData,
  hasUpdateData,
}) => {
  const {
    count,
    next,
    params: { columns, data },
    modals,
  } = dataTable;
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [isLoadingNewRows, setIsLoadingNewRows] = useState(false);
  const [finalWidthComponent, setFinalWidthComponent] = useState(
    window.innerWidth
  );
  const listRef = useRef(null);
  const windowHeight = window.innerHeight;
  const itemHeight = 100;

  const defineCustomComponent = (customComponent) => {
    switch (customComponent) {
      case 'modals':
        return ModalComponent;
      case 'requestModal':
        return ModalRequestComponent;
      case 'editFormRequest':
        return ModalFormComponent;
      case 'links':
        return LinkComponent;
      default:
        return <></>;
    }
  };

  const ModalComponent = ({ row, column }) => {
    const {
      customCell: { path, dataSetId },
      Header,
    } = column;
    const { [dataSetId]: id } = row.original;
    const {
      [path]: {
        params: { columns: columnsModal, dataSet },
        type,
        modals: subModal,
      },
    } = modals;
    const finalData = {
      type,
      params: { columns: columnsModal, data: dataSet[id] },
      modals: subModal,
      value: Header,
      title: Header,
    };
    return <CustomModal data={finalData} />;
  };

  const ModalRequestComponent = ({ row, column }) => {
    const {
      customCell: { tablePath, filter },
      Header,
    } = column;

    let filterAccessor;

    for (let key in filter) {
      filterAccessor = filter[key];
    }

    const { accessor } = filterAccessor;

    const { [accessor]: accessorValue } = row.original;
    const data = {
      btnText: Header,
      title: Header,
      path: tablePath,
      processAccessor: accessor,
      processvalue: accessorValue,
    };
    return <ModalRequestBaseManagement data={data} />;
  };

  const ModalFormComponent = ({ row, column }) => {
    const {
      customCell: {
        path,
        accessor,
        title,
        isConditionField,
        conditionFields,
        conditionValues,
        icon,
      },
      onlySuperAdmin,
    } = column;

    const { [accessor]: accessorValue, ...rest } = row.original;

    const fieldsConditionValue = Object.keys(rest).reduce((acc, key) => {
      if (conditionFields?.includes(key)) {
        return { ...acc, [key]: rest[key] };
      }
      return acc;
    }, {});

    return !isConditionField ||
      (isConditionField &&
        conditionValues &&
        Object.entries(conditionValues).every(([key, value]) => {
          if (Array.isArray(value))
            return value.includes(fieldsConditionValue[key]);
          else return value === fieldsConditionValue[key];
        })) ? (
      <ModalFormBaseManagement
        data={{ title, path: `${path}${accessorValue}`, icon, onlySuperAdmin }}
        setHasUpdateData={setHasUpdateData}
        hasUpdateData={hasUpdateData}
        key={accessorValue}
      />
    ) : null;
  };

  const LinkComponent = ({ row, column }) => {
    const {
      customCell: { component, path, dataSetId },
    } = column;
    const { [dataSetId]: id } = row.original;
    const {
      [path]: { dataSet },
    } = dataTable[component];
    const data = dataSet[id];
    return (
      <StyledLink
        tag='true'
        to={data.url}
      >
        {data.value}
      </StyledLink>
    );
  };

  useEffect(() => {
    const cols = columns?.length
      ? columns.map((column) => {
          if (column?.customCell) {
            const {
              customCell: { component: customComponent },
            } = column;
            if (customComponent)
              column.Cell = defineCustomComponent(customComponent);
          }
          return column;
        })
      : [];
    setUpdatedColumns(cols);
  }, [columns, hasUpdateData]);

  const defaultColumn = useMemo(
    () => ({
      width: `calc((100% - 50px) / ${updatedColumns.length})`,
    }),
    [updatedColumns]
  );

  const handleItemsRendered = async ({ visibleStopIndex }) => {
    // O scroll está no final
    if (
      visibleStopIndex === rows.length - 1 &&
      rows.length < count &&
      !isLoadingNewRows
    ) {
      setIsLoadingNewRows(true);
      try {
        await updateDataOnScroll(next);
      } finally {
        setIsLoadingNewRows(false);
      }
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns: updatedColumns,
      data,
      defaultColumn,
    },
    useBlockLayout,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (hasUseRowSelect) {
          return [
            {
              id: 'selection',
              Header: ({ getToggleAllRowsSelectedProps }) => {
                return (
                  <div
                    style={{
                      width: 30,
                      minWidth: 30,
                    }}
                  >
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps()}
                    />
                  </div>
                );
              },
              Cell: ({ row }) => {
                return (
                  <div
                    style={{
                      width: 30,
                      minWidth: 30,
                    }}
                  >
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                    />
                  </div>
                );
              },
            },
            ...columns,
          ];
        } else {
          return [...columns];
        }
      });
    }
  );

  const renderCell = (cell) => {
    if (isDateValid(cell.value)) {
      return (
        <span>
          {new Date(cell.value).toLocaleDateString('pt-br', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
          })}
        </span>
      );
    } else if (typeof cell.value === 'boolean') {
      return cell.value ? <span>Sim</span> : <span>Não</span>;
    }
    return <span>{cell.render('Cell')}</span>;
  };

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className='tr'
        >
          {row.cells.map((cell) => {
            return (
              <div
                {...cell.getCellProps()}
                className='td'
              >
                {renderCell(cell)}
              </div>
            );
          })}
        </div>
      );
    },
    [prepareRow, rows, selectedFlatRows]
  );

  const calcTableHeight = useMemo(() => {
    return (maxHeighComponent || windowHeight) < itemHeight * rows.length
      ? maxHeighComponent
        ? maxHeighComponent
        : windowHeight
      : itemHeight * rows.length;
  }, [windowHeight, rows.length, maxHeighComponent]);

  useEffect(() => {
    const widthColums = 200 * updatedColumns.length + 40;
    setFinalWidthComponent(_.max([widthColums, widthComponent]));
  }, [widthComponent, updatedColumns.length]);

  useEffect(() => {
    const rowsSelected = selectedFlatRows.map((d) => d.original);
    setSelectedIds(rowsSelected.map((r) => r.id));
  }, [selectedRowIds]);

  return (
    <>
      <TableContainer
        numCols={updatedColumns.length}
        hasUseRowSelect={hasUseRowSelect}
        widthList={finalWidthComponent}
      >
        <div
          {...getTableProps()}
          className='table'
        >
          <div>
            {headerGroups.map((headerGroup) => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className='tr header'
              >
                {headerGroup.headers.map((column) => (
                  <div
                    {...column.getHeaderProps()}
                    className='th'
                  >
                    {column.render('Header')}
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()}>
            <FixedSizeList
              height={calcTableHeight}
              itemCount={rows.length}
              itemSize={itemHeight}
              width={finalWidthComponent}
              ref={listRef}
              onItemsRendered={handleItemsRendered}
              style={{ overflowX: 'clip' }}
            >
              {RenderRow}
            </FixedSizeList>
          </div>
          {isLoadingNewRows ? (
            <LoadingContainer>
              <img
                src={Loading}
                style={{ height: 50 }}
              />
            </LoadingContainer>
          ) : null}
        </div>
      </TableContainer>
    </>
  );
};

const TableContainer = styled.div`
  padding: '1rem 1rem 0 1rem';

  .table {
    border-spacing: 0;
    width: ${(props) => props.widthList}px;
    .tr {
      align-items: center;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      border-bottom: 1px solid #e3e3e3;
      width: 100%;
    }

    .header {
      background: #f1f1f1;
      border-radius: 8px 8px 0 0;
      font-weight: 500;
      width: ${(props) => props.widthList}px;
    }
    .th,
    .td {
      margin: 0;
      padding: 0.5rem;
      width: ${(props) => `calc((100% - 50px) / ${props.numCols})`};
      min-width: 200px;
      word-break: break-word;
      flex: 0 0 auto;
      :last-child {
        border-right: 0;
      }
      text-align: center;
      overflow-x: auto;
      max-height: 100%;
    }

    .tr .td:last-child,
      width: ${(props) => `calc((100%  / ${props.numCols}) - 30px)`};
      min-width: 180px;
    }

    .tr .th:last-child {
      width: ${(props) => `calc((100%  / ${props.numCols}) - 60px)`};
      min-width: 180px;
    }

    ${(props) =>
      props.hasUseRowSelect &&
      css`
        .tr > .th:first-child,
        .tr > .td:first-child {
          width: 50px;
          min-width: 50px;
        }
      `}
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

Table.propTypes = {
  data: PropTypes.object.isRequired,
  maxHeighComponent: PropTypes.number,
};

export default Table;

/* eslint-disable react/jsx-no-comment-textnodes */
import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ClearIcon from '@mui/icons-material/Clear';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { NumericFormat } from 'react-number-format';
import { colors } from '../../../styles/colors';
import { FormPropTypes, FormDefaultProps } from '../types';
import GradientButton from '../../GradientButton';
import ProducerReportService from '../../../services/administrator/ManagementBases/producerReport.service';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value.replace(',', '.'),
          },
        });
      }}
      thousandSeparator
      isNumericString
      fixedDecimalScale={true}
      decimalScale={2}
      prefix='R$'
    />
  );
});
NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const BaseWaitlistForm = ({
  onSubmit,
  initialValues,
  handleClose,
  providers,
  companies,
  producers,
  generatorToEdit,
  producerReport,
  loadingg,
  setLoadingg,
  setReload,
  reload,
}) => {
  const [providerId, setProviderId] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    month: new Date().getMonth() + 1,
    value_cents: 0,
    volume_forecast: 0,
    volume: 0,
  });

  

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'producer') {
      const producerInfo = producers.find((producer) => producer.id === value);
      const { price_floor, volume_forecast, volume } = producerInfo;
      const value_cents = price_floor * volume_forecast;
      setFormData((state) => ({
        ...state,
        producer_id: event?.target?.value,
        value_cents: value_cents || 0,
        volume_forecast: volume_forecast || 0,
        volume: parseFloat(volume).toFixed(0) || 0,
      }));
      return;
    }
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleDelete = async () => {
    const { id } = generatorToEdit;
    setLoadingg(true);
    try {
      await ProducerReportService.deleteProducerReport(id);
      const indexOf = producerReport.indexOf(generatorToEdit);
      producerReport.splice(indexOf, 1);
      setLoadingg(false);
    } catch (error) {
      setLoadingg(false);
      console.log(error);
    } finally {
      handleClose();
      setReload(!reload);
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    onSubmit(formData, { isSubmitting });
    setIsSubmitting(false);
  };
  initialValues = {};

  useEffect(() => {
    if (generatorToEdit) {
      setFormData({
        ...generatorToEdit,
        value_cents: (generatorToEdit.value_cents / 100).toFixed(2),
      });
    }
  }, [generatorToEdit]);

  return (
    <form
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <UpperDiv
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <p style={{ fontSize: '40px' }}>
          {generatorToEdit ? 'Alterar Report' : 'Cadastrar Report'}
        </p>
        <ClearIcon style={{ color: 'red' }} onClick={handleClose} id='clear' />
      </UpperDiv>
      <MainContainer>
        <ColumnContainer>
          <div style={{ width: '100%' }}>
            <FormControl
              variant='outlined'
              style={{
                marginTop: '1rem',
                width: '100%',
              }}
            >
              <InputLabel htmlFor='demo-simple-select-outlined-companies'>
                Gerador
              </InputLabel>
              <Select
                labelId='demo-simple-select-outlined-label-companies'
                id='demo-simple-select-outlined-companies'
                defaultValue={
                  generatorToEdit
                    ? `${generatorToEdit.producer_id}`
                    : `${formData.producer}--${formData.producer_id}`
                }
                onChange={handleChange}
                label='Gerador'
                name='producer'
              >
                {producers &&
                  producers.map((producer) => {
                    return (
                      <MenuItem key={producer.id} value={producer.id}>
                        {producer.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <TextField
            variant='outlined'
            label='Capacidade'
            placeholder='Capacidade'
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            name='volume_forecast'
            type='number'
            value={formData.volume_forecast}
          />
        </ColumnContainer>
        <ColumnContainer>
          <TextField
            variant='outlined'
            label={formData?.value_cents ? 'Mensalidade' : ''}
            placeholder={formData?.value_cents ? 'Mensalidade' : ''}
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            name='value_cents'
            type='string'
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            value={formData?.value_cents || 0}
          />

          <TextField
            variant='outlined'
            label='Mês'
            placeholder='Mês'
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            name='month'
            type='number'
            value={formData.month}
          />
        </ColumnContainer>
        <ColumnContainer>
          <TextField
            variant='outlined'
            label='Geração'
            placeholder='Geração'
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            name='volume'
            type='number'
            value={formData.volume}
          />
        </ColumnContainer>
      </MainContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <GradientButton
          handleClick={handleSubmit}
          disabled={isSubmitting}
          text=''
          fontSize='50'
          borderRadius='6'
          style={{
            width: 'max-content',
          }}
          type='submit'
          loading={isSubmitting}
        >
          {loadingg ? (
            <CircularProgress style={{ color: 'white' }} />
          ) : (
            <>{generatorToEdit ? 'Alterar' : 'Cadastrar'}</>
          )}
        </GradientButton>
        {generatorToEdit && (
          <GradientButton
            handleClick={handleDelete}
            disabled={isSubmitting}
            text=''
            fontSize='50'
            borderRadius='6'
            style={{
              width: 'max-content',
            }}
            type='submit'
            loading={isSubmitting}
            background='red'
          >
            {loadingg ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : (
              <>Deletar</>
            )}
          </GradientButton>
        )}
      </div>
    </form>
  );
};

BaseWaitlistForm.propTypes = FormPropTypes;
BaseWaitlistForm.defaultProps = FormDefaultProps;

const ProducersOccupationForm = styled(BaseWaitlistForm)`
  position: relative;
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : 'Myriad-Pro-Condensed'};
  letter-spacing: 0.085em;
  background: ${colors.white};
  outline: none;
  border-radius: 2px;
  font-size: 16px;
  padding: 5px 10px;
  color: ${colors.primary};
  font-weight: 300;
  width: ${(props) => (props.fullwidth ? '100%' : 'auto')};

  &:disabled {
    border: none;
    padding: 0;
    color: ${colors.primary};
  }
`;
export default ProducersOccupationForm;

const MainContainer = styled.section`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
`;

const UpperDiv = styled.div`
  #clear {
    :hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`;

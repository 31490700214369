import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineFactCheck } from 'react-icons/md';

import { LuZap, LuUser } from 'react-icons/lu';

import { NewPageView } from '../../../containers/NewPageView';

import { UserContext } from '../../../contexts/userContext';
import { Card } from '../../../components/Cards/Card';
import { PrimaryButton } from '../../../components/PrimaryButton';
import { LoadingCustomModal } from '../../../components/Modals/LoadingCustomModal';

import {
  CardContent,
  CardsContainer,
  Container,
  Content,
  Properties,
  Separator,
  Title,
} from './styles';
import { ThemeContext } from '../../../contexts/themeContext';

import RecomendationsImg from '../../../assets/images/icons/monochromatic-png/recomendacoes 3.png';
import ProviderDataImg from '../../../assets/images/icons/monochromatic-png/cobranca simples e digital.png';
import ProfileImg from '../../../assets/images/icons/monochromatic-png/quem sou.png';

export const Recommendations = () => {
  const { themeValue } = useContext(ThemeContext);

  const { loading, recommendations } = useContext(UserContext);
  const navigate = useNavigate();

  const handleProfileEditing = () => {
    navigate('/perfil');
  };

  const handleFillInDistributorData = (id) => {
    if (!id) return;

    const state = 'isUserExperience';
    navigate(`/imovel/${id}`, { state });
  };

  return (
    <>
      <LoadingCustomModal isLoading={loading} />
      <NewPageView>
        <Container themeValue={themeValue}>
          <Content>
            <Title>
              <img
                src={RecomendationsImg}
                style={{ width: '3.5rem', height: '3.5rem' }}
              />

              <h1>Recomendações</h1>
            </Title>

            <Separator />

            <CardsContainer>
              {!!recommendations?.length && (
                <div>
                  <Card
                    icon={
                      <img
                        src={ProviderDataImg}
                        style={{ width: '2rem', height: '2rem' }}
                      />
                    }
                    title='Dados da distribuidora'
                  >
                    <CardContent>
                      <p>
                        Informe os seus dados de login da distribuidora. Não se
                        preocupe, você pode removê-los depois livremente.
                      </p>

                      {recommendations?.map((property) => (
                        <Properties key={property.id}>
                          <Separator />

                          <div>
                            <span>
                              <strong>Imóvel:</strong> {property.nickname}
                            </span>
                            <PrimaryButton
                              onClick={() =>
                                handleFillInDistributorData(property.id)
                              }
                            >
                              Informar dados
                            </PrimaryButton>
                          </div>
                        </Properties>
                      ))}
                    </CardContent>
                  </Card>
                </div>
              )}

              <div>
                <Card
                  icon={
                    <img
                      src={ProfileImg}
                      style={{ width: '2rem', height: '2rem' }}
                    />
                  }
                  title='Complete seu perfil'
                >
                  <CardContent>
                    <p>
                      Atualize seu perfil, preencha os dados em quem sou em seu
                      perfil.
                    </p>
                    <PrimaryButton onClick={handleProfileEditing}>
                      Editar meu perfil
                    </PrimaryButton>
                  </CardContent>
                </Card>
              </div>
            </CardsContainer>
          </Content>
        </Container>
      </NewPageView>
    </>
  );
};

import responseConfigOptions from './handlers/response';
const {
  REACT_APP_AUTH_URL,
  REACT_APP_BASE_URL,
  REACT_APP_ADM_URL,
  REACT_APP_SEND_EMAILS_URL,
  REACT_APP_S3_SIGNED_URLS_URL,
  REACT_APP_UPDATE_BASES_URL,
  REACT_APP_AUTHENTICATED_UPLOAD_URL,
  REACT_APP_UPLOAD_URL,
} = import.meta.env;

const {
  user,
  admin,
  bases,
  s3GetData,
  s3RefreshData,
  default: connectionDefault,
} = responseConfigOptions;

const connectionsOptions = {
  general: { url: REACT_APP_BASE_URL, responseOptions: user },
  administration: { url: REACT_APP_ADM_URL, responseOptions: admin },
  authentication: { url: REACT_APP_AUTH_URL, responseOptions: user },
  sendBatchEmails: { url: REACT_APP_SEND_EMAILS_URL, responseOptions: admin },
  bases: { url: REACT_APP_UPDATE_BASES_URL, responseOptions: bases },
  s3GetData: {
    url: REACT_APP_S3_SIGNED_URLS_URL + '/bases',
    responseOptions: s3GetData,
  },
  s3RefreshData: {
    url: REACT_APP_ADM_URL,
    responseOptions: s3RefreshData,
  },
  s3UploadData: {
    url: REACT_APP_S3_SIGNED_URLS_URL + '/upload',
    responseOptions: admin,
  },
  authenticatedAttachmentsUpload: {
    url: REACT_APP_AUTHENTICATED_UPLOAD_URL,
    responseOptions: admin,
    contentType: 'multipart/form-data',
  },
  unauthenticatedAttachmentsUpload: {
    url: REACT_APP_UPLOAD_URL,
    responseOptions: connectionDefault,
    contentType: 'multipart/form-data',
  },
};

export default connectionsOptions;

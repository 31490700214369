import connection from './connections/main';

const getAll = async (filters = {}) => {
  const { err, data } = await connection.get(`/providers`);

  if (err) {
    throw err;
  }

  return data;
};

const create = async (payload) => {
  const { err, data } = await connection.post(`/providers`, payload);

  if (err) {
    throw err;
  }

  return data;
};

const update = async (id, payload) => {
  const { err, data } = await connection.patch(`/providers/${id}`, payload);

  if (err) {
    throw err;
  }

  return data;
};

const ProvidersService = {
  getAll,
  create,
  update,
};

export default ProvidersService;

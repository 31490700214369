import axios from 'axios';
import connectionsOptions from './config';

const getFunctionNameFromStackTrace = () => {
  try {
    const err = new Error();
    const stack = err.stack.split('\n');

    return stack?.[3]?.trim();
  } catch (error) {
    return null;
  }
};

const handleAuth = (config) => {
  const AUTH_STORAGE_KEY = 'floraenergia:auth';
  const authValid = !!localStorage.getItem(AUTH_STORAGE_KEY);
  if (!authValid) return delete config.headers.Authorization;

  const authTokenString = JSON.parse(
    localStorage.getItem(AUTH_STORAGE_KEY)
  ).token;
  config.headers.Authorization = authTokenString;
};

const createConnection = (connectionName) => {
  const connectionObject = connectionsOptions?.[connectionName];
  if (!connectionObject) throw new Error(' no connectionObject');

  const { url, responseOptions, contentType } = connectionObject;
  const connection = axios.create({
    baseURL: url,
    headers: {
      'Content-Type': contentType ? contentType : 'application/json',
      Accept: 'application/json',
    },
  });
  if (connectionName !== 'unauthenticatedAttachmentsUpload')
    connection.interceptors.request.use(
      (config) => {
        config.metadata = {
          connectionCallerName: getFunctionNameFromStackTrace(),
        };
        handleAuth(config);
        return config;
      },
      ({ response }) => ({ err: response })
    );

  connection.interceptors.response.use((config) => config, responseOptions);
  return connection;
};

export default createConnection;

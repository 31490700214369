import { createTheme } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';

const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#6200F2',
      },
      secondary: {
        main: '#6BFF69',
      },
      disable: {
        main: '#E4E4E4',
      },
      error: {
        main: '#B3261E',
      },
      success: {
        main: '#6BFF69',
      },
      purple: {
        dark: '#8940F4',
        main: '#B080F8',
        light: '#D8C0FB',
      },
      gray: {
        main: '#BEBEBE',
        light: '#E3E3E3',
      },
      green: {
        dark: '#78F976',
        main: '#6BFF69',
        light: '#9DFD9C',
      },
      red: {
        main: '#B3261E',
        light: '#E9C8C7',
      },
      black: {
        dark: '#000000',
        main: '#1c1b1f',
      },
      white: {
        dark: '#fffbfe',
        main: '#FFFFFF',
      },
      customPopupBgColor: {
        main: '#6200f2cc',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
        strong {
          color: '#6200F2'; 
        }
      `,
      },
    },
  },
  ptBR
);

export default theme;

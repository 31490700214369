import { toast } from 'react-toastify';
import defaultConnectionResponse from './default';
import { TOAST_MESSAGES } from '../../../../helpers/toastMessages';

const adminAxiosConnectionResponse = (error) => {
  defaultConnectionResponse(error);
  const { response } = error;
  if (response) {
    const { status } = response;

    switch (status) {
      case 403:
        toast.error(TOAST_MESSAGES.UNAUTHORIZED, { autoClose: 3000 });
        break;
      default:
    }
  }

  return { err: response || error };
};

export default adminAxiosConnectionResponse;

import connection from '../connections/main';
import getS3DataConnection from '../connections/S3/getData';
import refreshDataConnection from '../connections/S3/refreshData';

const getAll = async () => {
  const { err, data } = await getS3DataConnection.get(`/invoicesCheck`);

  if (err) {
    throw err;
  }

  return data;
};

const getNewData = async () => {
  const { err, data } = await refreshDataConnection.get(`/invoicesCheck`);

  if (err) {
    throw err;
  }

  return data;
};

const fixInvoice = async (id, payload) => {
  const { err, data } = await connection.put(
    `/invoicesCheck/${id}/fixInvoice`,
    {
      invoicesCheck: payload,
    }
  );

  if (err) {
    throw err;
  }

  return data;
};

const tryAgainForContracts = async (contractsToTryAgain) => {
  const { err, data } = await connection.put(
    `/invoicesCheck/tryAgainForContracts`,
    contractsToTryAgain
  );

  if (err) {
    throw err;
  }

  return data;
};

const InvoicesCheckService = {
  getAll,
  getNewData,
  fixInvoice,
  tryAgainForContracts,
};

export default InvoicesCheckService;

export const genresSelectItems = [
  { label: 'Masculino', value: 'man' },
  { label: 'Feminino', value: 'woman' },
  { label: 'Outro', value: 'other' },
];

export const maritalStatusOptions = [
  {
    label: 'Solteiro(a)',
    value: 'single',
  },
  {
    label: 'Casado(a)',
    value: 'married',
  },
  {
    label: 'Viúvo(a)',
    value: 'widowed',
  },
  {
    label: 'Separado(a)',
    value: 'separated',
  },
  {
    label: 'Divorciado(a)',
    value: 'divorced',
  },
];

export const hasChildrenSelectItems = [
  { label: 'Sim', value: 'yes' },
  { label: 'Não', value: 'no' },
];

export const schoolingSelectItems = [
  {
    label: 'Ensino Fundamental Incompleto',
    value: { school: 'elementary_school', complete: false },
  },
  {
    label: 'Ensino Fundamental Completo',
    value: { school: 'elementary_school', complete: true },
  },
  {
    label: 'Ensino Médio Incompleto',
    value: { school: 'high_school', complete: false },
  },
  {
    label: 'Ensino Médio Completo',
    value: { school: 'high_school', complete: true },
  },
  {
    label: 'Ensino Superior Incompleto',
    value: { school: 'bachelor_degree', complete: false },
  },
  {
    label: 'Ensino Superior Completo',
    value: { school: 'bachelor_degree', complete: true },
  },
  {
    label: 'Pós Graduação Incompleto',
    value: { school: 'master_degree', complete: false },
  },
  {
    label: 'Pós Graduação Completo',
    value: { school: 'master_degree', complete: true },
  },
];

export const householdIncomeSelectItems = [
  { label: 'Até 2 salários mínimos', value: 'up_until_2' },
  { label: 'De 3 a 5 salários mínimos', value: 'from_3_to_5' },
  { label: 'De 6 a 10 salários mínimos', value: 'from_6_to_10' },
  { label: 'Acima de 10 salários mínimos', value: 'above_10' },
];

export const metTheCompanySelectItems = [
  { label: 'Empresa onde trabalho', value: 'ET' },
  { label: 'Anúncio em TV', value: 'ATV' },
  { label: 'Rádio', value: 'R' },
  { label: 'Redes sociais', value: 'RS' },
  { label: 'Google', value: 'G' },
  { label: 'Indicação', value: 'I' },
  { label: 'Outro', value: 'Outro' },
];

export const reasonToHireCompanySelectItems = [
  { label: 'Economia', value: 'Economia' },
  {
    label: 'Sustentabilidade / Meio ambiente',
    value: 'Sustentabilidade / Meio ambiente',
  },
  { label: 'Liberdade de escolha', value: 'Liberdade de escolha' },
  {
    label: 'Problemas com minha distribuidora',
    value: 'Problemas com minha distribuidora',
  },
  { label: 'Outro', value: 'Outro' },
];

import React from 'react';
import styled from 'styled-components';

const Message = styled.p``;

export const TOAST_MESSAGES = {
  SUCCESS: <Message>Operação realizada com sucesso!</Message>,
  CONFIRM_EMAIL: (
    <Message>Cheque na sua caixa de entrada pelo email de confirmação!</Message>
  ),
  ERROR: <Message>Ocorreu um erro! Por favor, tente novamente.</Message>,
  UNKNOWN_ERROR: (
    <Message>
      Ops! Houve um erro desconhecido, tente novamente mais tarde.
    </Message>
  ),
  POSTAL_CODE_NOT_FOUND: (
    <Message>
      Não foi possível encontrar automaticamente as informações desse CEP
    </Message>
  ),
  NO_CONTRACT_ACTIVATION_DATE: (
    <Message>
      Antes de ativar o contrato é necessário preencher a data de ativação de
      pelo menos uma localidade.
    </Message>
  ),
  NO_CONTRACT_SHARE: (
    <Message>
      Antes de refinar a proposta do contrato é necessário haver o rateio de
      creditos preenchido
    </Message>
  ),
  NO_CONTRACT_FULL_FIELD: (
    <Message>
      Antes de refinar a proposta do contrato verifique se todos os dados estão
      devidamente preenchidos
    </Message>
  ),
  NO_CONTRACT_VOLUME: (
    <Message>
      Antes de ativar o contrato é necessário preencher ao menos o volume,
      rateio de creditos e data de ativação
    </Message>
  ),
  EXPIRED_SESSION: <Message>Sessão expirada</Message>,
  UNAUTHORIZED: (
    <Message>Você não tem permissão para acessar esse recurso</Message>
  ),
  UNAUTHORIZED_CLIENT: (
    <Message>Ocorreu um pequeno problema ao preencher as informações.</Message>
  ),
  CONTACT_TEAM: (
    <Message>Precisa de uma luz? Fale conosco através do chat.</Message>
  ),
  LOCATION_ADDED: <Message>Imóvel adicionado com sucesso!</Message>,
  LOCATION_ADDED_FAIL: (
    <Message>
      Houve um erro ao tentar adicionar o imóvel, tente novamente.
    </Message>
  ),
  LOCATION_REMOVED: <Message>Imóvel removido com sucesso!</Message>,
  LOCATION_REMOVED_FAIL: (
    <Message>
      Houve um erro ao tentar remover o imóvel, tente novamente.
    </Message>
  ),
  FORM_INVALID: (
    <Message>O formulário não foi preenchido corretamente.</Message>
  ),
  NO_LOCATIONS_WARN: (
    <Message>Você precisa adicionar ao menos 1 imóvel.</Message>
  ),
  FILE_ADDED_FAIL: (
    <Message>
      Erro ao adicionar arquivo, o formato deve ser .jpg, .jpeg, .png ou .pdf.
    </Message>
  ),
  SIGNER_REMOVED: <Message>Signatário removido do contrato atual</Message>,
  CREATE_CARD_PAYMENT_METHOD_FAIL: (
    <Message>Houve um erro no cadastro desse método de pagamento</Message>
  ),
  NOT_COMPLETE_CONTRACT: (
    <Message>
      Você ainda não finalizou o preenchimento de todos os dados
    </Message>
  ),
  CONTRACT_UPDATED: <Message>Imovél atualizado com sucesso!</Message>,
  LOW_VOLUME: (
    <Message>
      Não podemos atender... <br />
      Volume e mensalidade ajustados para sua segurança.
    </Message>
  ),
  USER_ALREADY_EXISTS: (
    <Message>
      Você já possui cadastro em nossa plataforma com esse email... <br />
    </Message>
  ),
  CONTRACT_UPDATE: (
    <Message>
      Aguarde, estamos reemitindo o contrato... <br />
    </Message>
  ),
  NO_DATA_UPDATED: <Message>Nenhum dado foi alterado.</Message>,
  ERROR_DEFAULT_CLIENT: (
    <Message>
      Ops! Tivemos um problema ao atualizar seus dados, tente novamente. Caso o
      problema persista, contate nosso time de suporte.
    </Message>
  ),
  INVALID_CHARACTER: (
    <Message>Verifique os caracteres inseridos e tente novamente.</Message>
  ),
  PROCESS_IN_PROGRESS: (
    <Message>
      A requisição está sendo processada. Aguarde alguns instantes.
    </Message>
  ),
  PAYLOAD_TOO_LARGE: (
    <Message>
      O dado enviado na requisição é muito grande. Por favor, tente dividir os
      dados em partes menores e envie novamente.
    </Message>
  ),
  INVALID_POSTAL_CODE: (
    <Message>
      O CEP do imóvel está desatualizado ou inválido. Entre em contato com nosso
      time para atualizar.
    </Message>
  ),
};

import connection from './connection';
import AuthService from '../authentication/auth.service';

const getAll = async (page, limit = 500) => {
  const { err, data } = await connection.get(
    `/users?page=${page}&limit=${limit}`
  );

  if (err) {
    throw err;
  }

  return data;
};

const signupUpdateCheck = async (payload) => {
  const { err, data } = await connection.post(`/users/sign_up_check`, payload);

  if (err) {
    throw err;
  }

  if (typeof data.error === 'undefined') {
    if (data && data.token) {
      return AuthService.refreshToken(data, false);
    }
    return data;
  } else {
    return err;
  }
};

const signupUpdateCheckNew = async (payload) => {
  const { err, data } = await connection.post(
    `/users/sign_up_checkNew`,
    payload
  );

  if (err) {
    throw err;
  }

  if (typeof data.error === 'undefined') {
    if (data && data.token) {
      return AuthService.refreshToken(data, false);
    }
    return data;
  } else {
    return err;
  }
};

const createOrDeleteDistributorUserData = async (
  payload,
  actionDelete = false
) => {
  const { err, data } = actionDelete
    ? await connection.delete(`/user_credentials/${payload?.id}`)
    : await connection.post('/users/credentials', payload);

  if (err) {
    throw err;
  }

  return data;
};

const requestEmailChange = async (payload) => {
  const {
    user: { id },
  } = payload;
  const { err, data } = await connection.post(`/users/${id}/email`, payload);

  if (err) {
    throw err;
  }
  return data;
};

const get = async (id) => {
  const { err, data } = id
    ? await connection.get(`/users/${id}`)
    : await connection.get('/users/');

  if (err) {
    throw err;
  }

  return data;
};

const getUser = async () => {
  const { err, data } = await connection.get('/users/home');

  if (err) {
    throw err;
  }

  return data;
};

const getProperties = async () => {
  const { err, data } = await connection.get('/users/imoveis');

  if (err) {
    throw err;
  }

  return data;
};

const update = async (id, payload) => {
  if (payload.profile && payload.profile !== 'target') {
    payload.provider = null;
  }

  const { err, data } = await connection.put(`/users/${id}`, {
    user: payload,
  });

  if (err) {
    throw err;
  }
  return data;
};

const updateProfile = async (id, payload) => {
  const { err, data } = await connection.patch(`/users/${id}`, payload);

  if (err) {
    throw err;
  }
  return data;
};

const profile = async (id = 0, payload = null) => {
  if (payload) {
    return updateProfile(id, payload);
  }

  return get(id);
};

const getContracts = async (id) => {
  const { err, data } = await connection.get(`/users/${id}/contracts`);

  if (err) {
    throw err;
  }

  return data;
};

const getPaymentInfo = async (id) => {
  const { err, data } = await connection.get(`/contracts/${id}/payment-info`);

  if (err) {
    throw err;
  }

  return data;
};

const updatePaymentInfo = async (id, payload) => {
  const { err, data } = await connection.put(
    `/contracts/${id}/payment-info`,
    payload
  );

  if (err) {
    throw err;
  }

  return data;
};

const confirmEmail = async (token) => {
  const { err } = await connection.patch(
    `/users/confirmation?confirmation_token=${token}`
  );

  if (err) {
    throw err;
  }
};

const create = async (payload, signupEndpoint = 'sign_up') => {
  if (payload.user.profile && payload.user.profile !== 'target') {
    payload.user.provider = null;
  }
  const { err, data } = await connection.post(
    `/users/${signupEndpoint}`,
    payload
  );

  if (err) {
    throw err;
  }

  return data;
};

const userPassword = async (payload) => {
  const { err, data } = await connection.post(`/users/password`, payload);

  if (err) {
    throw err;
  }

  return data;
};

const zapsac = async (payload) => {
  const { err, data } = await connection.post(`/webhooks/zapsac`, payload);

  if (err) {
    throw err;
  }

  return data;
};

const email = async (payload) => {
  const { err, data } = await connection.post(`/webhooks/email`, payload);

  if (err) {
    throw err;
  }

  return data;
};

const deleteById = async (payload) => {
  const { err, data } = await connection.post(`/users/delete`, payload);
  if (err) {
    throw err;
  }
  return data;
};

const deleteContractById = async (payload) => {
  const { err, data } = await connection.post(`/users/deleteContract`, payload);
  if (err) {
    throw err;
  }
  return data;
};


const getUserEmailByToken = async (token) => {
  const { err, data } = await connection.get(`/users/email-by-token/${token}`);

  if (err) {
    throw err;
  }

  return data;
};

const resetPasswordByToken = async (token, email, password) => {
  const { err } = await connection.put(`/users/reset-password/${token}`, {
    email,
    password,
  });

  if (err) {
    throw err;
  }
};

const UsersService = {
  zapsac,
  email,
  signupUpdateCheck,
  signupUpdateCheckNew,
  createOrDeleteDistributorUserData,
  create,
  getAll,
  get,
  getUser,
  getProperties,
  getPaymentInfo,
  updatePaymentInfo,
  profile,
  update,
  confirmEmail,
  getContracts,
  userPassword,
  deleteById,
  deleteContractById,
  requestEmailChange,
  updateProfile,
  getUserEmailByToken,
  resetPasswordByToken,
};

export default UsersService;

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import PageView from '../../../containers/PageView';
import PaymentMethodsBaseService from '../../../services/administrator/ManagementBases/paymentMethodsBase.service';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { StyledLink } from '../../../components/Interface';
import { Paragraph } from '../../../components/Interface/Text/Paragraph';

import ContentCard from '../../../components/ContentCard';

import { PAYMENT_METHODS_PTBR } from '../../../helpers/paymentMethods';
import { INVOICE_STATUS_TYPES_PTBR } from '../../../helpers/invoiceStatusTypes';
import LoadMore from '../../../components/LoadMore';
import { formatDate } from '../../../helpers/date';
// DESCONTINUADO FP-2998
const COLUMNS = [
  { name: 'Usuário', align: 'left' },
  { name: 'Contrato' },
  { name: 'Fatura' },
  { name: 'Código Iugu' },
  { name: 'Vencimento' },
  { name: 'Celular' },
  { name: 'Gerador' },
  { name: '*Em aberto' },
  { name: 'Financeiro' },
  { name: 'Meio de Pagamento' },
  { name: 'Última mensagem' },
];

const getRows = (contracts) => {
  return contracts.map((contract) => {
    const {
      user_id,
      name,
      id,
      invoice_id,
      due_date,
      phone_number,
      user_phone_number,
      producer,
      invoice_value_cents,
      method,
      invoice_status,
      custumer_id,
      total_cents,
      iuguError,
      iuguManagementCode,
      lastSentMessage,
    } = contract;
    return {
      values: [
        {
          value: (
            <StyledLink
              tag='false'
              to={`/usuarios/${user_id}`}
            >{`${name}`}</StyledLink>
          ),
        },
        {
          value: (
            <StyledLink
              tag='true'
              to={`/contratos/${id}`}
            >{`${id}`}</StyledLink>
          ),
        },
        {
          value: (
            <Linkk
              target='_blank'
              href={custumer_id || ''}
            >
              {custumer_id ? `#${invoice_id}` : invoice_id}
            </Linkk>
          ),
        },
        {
          value: <Paragraph>{iuguManagementCode}</Paragraph>,
        },
        {
          value: <Paragraph>{formatDate(due_date)}</Paragraph>,
        },
        {
          value: <Paragraph>{phone_number || user_phone_number}</Paragraph>,
        },
        {
          value: <Paragraph>{producer}</Paragraph>,
        },
        {
          value: (
            <Paragraph>{`R$${
              (total_cents / 100).toFixed(2).replaceAll('.', ',') ||
              (invoice_value_cents / 100).toFixed(2).replaceAll('.', ',')
            }`}</Paragraph>
          ),
        },
        {
          value: (
            <Paragraph>{INVOICE_STATUS_TYPES_PTBR[invoice_status]}</Paragraph>
          ),
        },
        {
          value: (
            <Paragraph>
              {iuguError
                ? 'Clique em fatura para verficar os dados daquela fatura'
                : PAYMENT_METHODS_PTBR[method]}
            </Paragraph>
          ),
        },
        {
          value: <Paragraph>{lastSentMessage}</Paragraph>,
        },
      ],
    };
  });
};

const Contracts = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    invoices: [],
  });
  const [page, setPage] = useState(0);
  const [loadingg, setLoadingg] = useState(false);
  const [noMore, setNoMore] = useState(false);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        setLoadingg(true);
        const resources = await PaymentMethodsBaseService.getAll(page);

        if ((resources?.length <= 0 || !resources) && page > 0) {
          setNoMore(true);
          return;
        } else {
          setNoMore(false);
        }

        setPageState((state) => ({
          ...state,
          loading: false,
          invoices: [...state.invoices, ...resources],
        }));
        setLoadingg(false);
      } catch (err) {
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };

    fetchInvoices();
  }, [page]);

  const fetchAll = async () => {
    const rows = [],
      limit = 300;

    let page = 0,
      data = [];
    do {
      data = await PaymentMethodsBaseService.getAll(page, limit);
      if (data.length > 0) rows.push(...data);
      page += 1;
    } while (data.length === limit); //Se retornou menos que o limit, é que não terá dados em uma proxima requisição
    return getRows(rows);
  };

  const { loading, hasError, invoices } = pageState;

  return (
    <PageView
      title='Gestão de Pagamentos'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <ContentCard>
            <SearchableTable
              columns={COLUMNS}
              rowsValues={getRows(invoices)}
              noItemsText='Nenhuma Fatura'
              fetchAll={fetchAll}
            />
          </ContentCard>
          {!noMore && (
            <LoadMore
              loadingg={loadingg}
              setPage={setPage}
            />
          )}
        </Grid>
      </Grid>
    </PageView>
  );
};

export default Contracts;

const Linkk = styled.a`
  color: #44606c;
  > span {
    user-select: none;
  }
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

import { toast } from 'react-toastify';
import setSentryScope from '../../../../helpers/sentry/sentryScope';
import { removeAuthData } from '../../../../store';
import { navigate } from '../../../../utils';
import { TOAST_MESSAGES } from '../../../../helpers/toastMessages';

const isLoginRequest = (request) =>
  !!request.responseURL.match(import.meta.env.REACT_APP_AUTH_URL);

const defaultConnectionResponse = (error, showToast403 = true) => {
  const { response } = error;
  if (response) {
    const { status, request } = response;

    switch (status) {
      case 401:
        // Clear auth data if request was not for login
        removeAuthData();
        if (!isLoginRequest(request)) navigate('/login');
        break;
      case 403 && showToast403:
        toast.error(TOAST_MESSAGES.UNAUTHORIZED);
        break;
      case 413:
        toast.error(TOAST_MESSAGES.PAYLOAD_TOO_LARGE);
      default:
    }
  }

  setSentryScope(error);

  return { err: response || error };
};

export default defaultConnectionResponse;

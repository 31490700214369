export const proposalCanBeReady = (contract) => {
  if (!contract || !contract.id) return false;

  return contract.locations
    .map(({ value_cents, volume, producer, share }) => [
      value_cents,
      volume,
      producer,
      share,
      document,
    ])
    .every((propValue) => propValue !== null && propValue !== undefined);
};

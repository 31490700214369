/* eslint-disable no-var */
/* eslint-disable vars-on-top */
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUrl } from '../../../helpers/getUrl';
import { useStage } from '../../../hooks/useStage';
import { handleSigned } from '../../../pages/Public/NewRegister/helpers';
import { Clicksign } from '../../../utils/clicksign';

export function StageClicksignSignature({ isNewPropertyOrHolder }) {
  const { stagePayload, changePayload, resetStagePayload } = useStage();

  const navigate = useNavigate();
  const {
    signer: { request_signature_key } = {},
    email: { value: email },
  } = stagePayload;
  var widget;

  const run = () => {
    if (widget) widget.unmount();
    widget = new Clicksign(request_signature_key);

    widget.on('signed', async () =>
      handleSigned(
        email,
        navigate,
        widget,
        isNewPropertyOrHolder,
        resetStagePayload
      )
    );

    if (import.meta.env.REACT_APP_ENV !== 'prod')
      widget.endpoint = 'https://sandbox.clicksign.com';

    const { url } = getUrl();

    widget.origin = url || import.meta.env.REACT_APP_MAIN_URL;

    widget.mount('container');
    changePayload({ widget });
  };

  useEffect(() => run(), []);

  return (
    <div
      id='container'
      style={{ height: isNewPropertyOrHolder ? '80vh' : '90vh' }}
    />
  );
}

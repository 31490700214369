const getBase64 = (file, res, rej) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    res(reader.result);
  };
  reader.onerror = (error) => {
    rej(error);
  };
};

export const fileToBase64 = async (file) => {
  return new Promise((res, rej) => {
    getBase64(file, res, rej);
  });
};

export const extractBlobData = (file, type = 'application/pdf') =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = ({ target: { result } }) => {
        const base64Data = result.split(',')[1];
        const binaryData = window.atob(base64Data);
        const len = binaryData.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i += 1) bytes[i] = binaryData.charCodeAt(i);

        const blobData = new Blob([bytes], { type });

        resolve(blobData);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
  });

import moment from 'moment';

export const onlyNumbers = (value) => {
  return value && value.replace(/\D/g, '');
};

export const onlyString = (value) => {
  try {
    return (
      value &&
      value
        .replace(/\d/g, '')
        .replace(/[`',~!@#$%^&*()_|+?;:."¹²³£¢¬§°ªº₢₢´¨<>\{\}\[\]\\\/]/gi, '')
    );
  } catch (error) {
    return value;
  }
};

export const getAddressText = (address) => {
  const { street, number, neighborhood, city, state, postal_code, complement } =
    address || {};

  if (!street) {
    return '-';
  }
  return `${street}, ${number}${
    complement ? ' '.concat(complement) : ''
  } - ${neighborhood}, ${city} - ${state}, ${postal_code}`;
};

export const getDateString = (timestamp) =>
  moment(timestamp).format('DD/MM/yyyy');

const moneyPrefix = {
  BRL: 'R$ ',
};

export const checkSplitCity = async (st) => {
  let returnVal = st;
  try {
    if (st.includes(' - ')) {
      const temp = st.split(' - ');
      if (temp.length > 2) {
        for (let i = 0; i < temp.length - 1; i++) {
          temp.splice(temp.length - 1, 1);
          returnVal = temp.join(' ');
        }
      } else {
        returnVal = temp[0];
      }
    }
    return returnVal;
  } catch (err) {
    console.log(err);
  }
};

export const formatCity = (event) => {
  let value = event;
  value = value.replace(
    /[0-9~=',!@#$%^&*()_|+?;:."¹²³£¢¬§°ªº₢₢¨<>\{\}\[\]\\\/]/gi,
    ''
  );
  event = value;
  return event;
};

export const removeSpacesBetween = (string) => {
  try {
    const unfiltered = string.trim().split(' ');
    return unfiltered.filter((el) => el).join(' ');
  } catch (error) {
    console.log('file: formatter.js || line 88 || error', error);
  }
};

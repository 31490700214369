import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.button`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${theme.palette.primary.main};
  background: none;
  color: ${theme.palette.primary.main};
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s;

  &:hover {
    border-color: ${theme.palette.purple.dark};
    color: ${theme.palette.purple.dark};
  }

  &:active {
    border-color: ${theme.palette.purple.light};
    color: ${theme.palette.purple.light};
  }

  &:disabled {
    width: 100%;
    color: #989799;
    background: none;
    border: 1px solid #989799;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    cursor: not-allowed;
  }
`;

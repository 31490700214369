import connection from './connection';

const get = async (promocode) => {
  const { err, data } = await connection.post(`/promocodes/check`, {
    promocode: {
      code: promocode,
    },
  });

  if (err) {
    throw err;
  }

  return data;
};

const getAndReturn = async () => {
  const { err, data } = await connection.get(`/promocodes/getAndReturn`);
  if (err) {
    throw err;
  }
  return data;
};

const getByCode = async (code) => {
  const { err, data } = await connection.get(`/promocodes/valid/${code}`);
  if (err) {
    throw err;
  }
  return data;
}

const PromocodesService = {
  get,
  getAndReturn,
  getByCode,
};

export default PromocodesService;

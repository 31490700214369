import { InputCustom } from '../../../components/InputCustom';
import {
  CardContent,
  CardTitle,
  RadioboxContainer,
  RegisterCreditCardContainer,
  SupportMessage,
} from './styles';
import { Radiobox } from '../../../components/Radiobox';
import { PAYMENT_METHODS } from '../../../helpers/paymentMethods';
import { useEffect, useState } from 'react';
import {
  validationEmail,
  validationName,
} from '../../../helpers/form/validations';
import { onlyString } from '../../../helpers/form/formatter';
import { PrimaryButton } from '../../../components/PrimaryButton';
import { SecondaryButton } from '../../../components/SecondaryButton';
import CreditCardInfo from '../../../components/Interface/CreditCardInfo';
import { CreditCardModal } from '../../../components/Modals/CreditCardModal';
import { toast } from 'react-toastify';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';

export const MethodPayment = ({
  payload,
  setPayload,
  handleSubmitPayment,
  data,
}) => {
  const [isEditingPayment, setIsEditingPayment] = useState(false);
  const [isValidPayment, setIsValidPayment] = useState(false);
  const [openModalCreditCard, setOpenModalCreditCard] = useState(false);

  const { email, method, fullname } = payload;

  const paymentMethodsSelectItems = [
    { label: 'Boleto', value: 'bank_slip' },
    { label: 'Cartão de crédito', value: 'credit_card' },
    { label: 'Pix', value: 'pix' },
  ];

  const handleEmail = (event) => {
    event.persist();
    let status = 'accepted';

    const isEmailValid = validationEmail(event.target.value);

    if (!isEmailValid) {
      status = 'refused';
    }
    setPayload((prevState) => ({
      ...prevState,
      email: { ...email, value: event.target.value, status },
    }));
  };

  const handlePaymentMethods = (event) => {
    event.persist();
    const creditCardData = {};

    if (event.target.value !== PAYMENT_METHODS.Card)
      Object.assign(creditCardData, {
        numberCard: {
          value: '',
        },
        nameCard: { value: '' },
        expiryCard: { value: '' },
        cvv: { value: '' },
      });
    setPayload((prevState) => ({
      ...prevState,
      method: event.target.value,
      ...creditCardData,
    }));
  };

  const handleName = (event) => {
    event.persist();
    let status = 'accepted';
    const isNameValid = validationName(onlyString(event.target.value));
    if (!isNameValid) {
      status = 'refused';
    }

    setPayload((prevState) => ({
      ...prevState,
      fullname: { ...fullname, value: onlyString(event.target.value), status },
    }));
  };

  const handleCloseModalCreditCard = () => {
    setOpenModalCreditCard(false);
  };

  const handleIsEditingPayment = () => {
    setIsEditingPayment(!isEditingPayment);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await handleSubmitPayment(event);
      setIsEditingPayment(false);
      toast.success(TOAST_MESSAGES.SUCCESS);
      if (openModalCreditCard) handleCloseModalCreditCard();
    } catch {}
  };

  useEffect(() => {
    const fieldsToValidate = ['email', 'fullname'];
    const statusFields = [];
    for (const field of fieldsToValidate) {
      const { status } = payload[field] || {};
      statusFields.push(status);
    }

    const invalid =
      statusFields.some((status) => status !== 'accepted') && method !== '';
    setIsValidPayment(!invalid);
  }, [email, method, fullname]);

  return (
    <>
      <CreditCardModal
        payloadProperty={payload}
        setPayloadProperty={setPayload}
        isOpen={openModalCreditCard}
        onRequestClose={handleCloseModalCreditCard}
        handleSubmitPayment={handleSubmit}
      />
      <CardContent>
        <CardTitle>
          <span
            style={{
              fontSize: '1.125rem',
            }}
          >
            Forma de pagamento:
          </span>
        </CardTitle>

        {isEditingPayment ? (
          <PrimaryButton
            onClick={handleSubmit}
            disabled={!isValidPayment}
          >
            Salvar
          </PrimaryButton>
        ) : (
          <SecondaryButton onClick={handleIsEditingPayment}>
            Editar
          </SecondaryButton>
        )}
      </CardContent>

      <InputCustom
        type='text'
        name='fullname'
        labelText='Nome completo'
        value={fullname?.value || ''}
        onChange={handleName}
        hasTouched
        hasError={fullname?.status === 'refused' && isEditingPayment}
        errorMessage='Por favor digite um nome completo'
        requiredMessage='Nome é obrigatório'
        disabled={!isEditingPayment}
      />

      <InputCustom
        type='email'
        name='email'
        labelText='E-mail'
        value={email?.value || ''}
        onChange={handleEmail}
        hasError={email?.status === 'refused' && isEditingPayment}
        hasTouched
        errorMessage='Por favor digite um email válido'
        requiredMessage='Email é obrigatório'
        disabled={!isEditingPayment}
      />

      <RadioboxContainer>
        <Radiobox
          name='paymentMethods'
          options={paymentMethodsSelectItems}
          checked={method || ''}
          onChange={handlePaymentMethods}
          disabled={!isEditingPayment}
        />
      </RadioboxContainer>

      {method === PAYMENT_METHODS.Card &&
        data?.paymentMethod?.bin &&
        data?.paymentMethod?.display_number && (
          <CreditCardInfo
            bin={data.paymentMethod.bin}
            lastNumbers={data.paymentMethod.display_number}
          />
        )}

      {method === PAYMENT_METHODS.Card && isEditingPayment ? (
        <RegisterCreditCardContainer>
          <PrimaryButton onClick={() => setOpenModalCreditCard(true)}>
            {data?.paymentMethod?.display_number
              ? 'Trocar cartão'
              : 'Adicionar cartão'}
          </PrimaryButton>
        </RegisterCreditCardContainer>
      ) : null}

      {method === PAYMENT_METHODS.BankSlip && (
        <SupportMessage>
          Será enviado via e-mail o boleto para o pagamento da sua fatura.
        </SupportMessage>
      )}

      {method === PAYMENT_METHODS.Pix && (
        <SupportMessage>
          Será enviado via e-mail o QR Code para o pagamento da sua fatura.
        </SupportMessage>
      )}
    </>
  );
};

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PageView from '../../../containers/PageView';
import CustomerSuccessService from '../../../services/administrator/ManagementBases/customerSuccess.service';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { StyledLink, StatusLabel } from '../../../components/Interface';
import { Paragraph } from '../../../components/Interface/Text/Paragraph';

import { getStatusColor } from '../../../styles/colors';
import { CONTRACT_STATUS_TYPES_PTBR } from '../../../helpers/contractStatusTypes';
import ContentCard from '../../../components/ContentCard';

import LoadMore from '../../../components/LoadMore';
import { TAG_TYPES_LABEL } from '../../../helpers/tagTypes';
import { formatCpfCpnj } from '../Proposal';

const COLUMNS = [
  { name: 'User ID' },
  { name: 'Contrato ID' },
  { name: 'Status', align: 'center' },
  { name: 'Status 2' },
  { name: 'Gerador' },
  { name: 'Mês de referência' },
  { name: 'Unidade Consumidora' },
  { name: 'CPF' },
  { name: 'Dia de faturamento' },
  { name: 'Volume contratado' },
  { name: 'Volume injetado' },
  { name: 'Volume compensado' },
  { name: 'Saldo de créditos ' },
];

const getRows = (contracts) => {
  return contracts.map((contract) => {
    const {
      user_id,
      id,
      status,
      tag,
      name,
      month,
      install_code,
      document,
      billing_day,
      volume,
      injected,
      compensated,
      balance,
    } = contract;

    return {
      values: [
        {
          value: (
            <StyledLink
              tag='true'
              to={`/usuarios/${user_id}`}
            >{`${user_id}`}</StyledLink>
          ),
        },
        {
          value: (
            <StyledLink
              tag='true'
              to={`/contratos/${id}`}
            >{`${id}`}</StyledLink>
          ),
        },
        {
          value: (
            <StatusLabel status={getStatusColor(status)}>
              {CONTRACT_STATUS_TYPES_PTBR[status]}
            </StatusLabel>
          ),
          align: 'center',
        },
        {
          value: <Paragraph>{TAG_TYPES_LABEL[tag]}</Paragraph>,
        },
        {
          value: <Paragraph>{name}</Paragraph>,
        },
        {
          value: <Paragraph>{month}</Paragraph>,
        },
        {
          value: <Paragraph>{install_code}</Paragraph>,
        },
        {
          value: (
            <Paragraph>{document ? formatCpfCpnj(document) : ''}</Paragraph>
          ),
        },
        {
          value: <Paragraph>{billing_day}</Paragraph>,
        },
        {
          value: (
            <Paragraph>{`${parseFloat(volume).toLocaleString(
              'pt-BR'
            )} kWh`}</Paragraph>
          ),
        },
        {
          value: (
            <Paragraph>
              {injected
                ? `${parseFloat(injected)
                    .toFixed(2)
                    .toString()
                    .replaceAll('.', ',')} kWh`
                : '-'}
            </Paragraph>
          ),
        },
        {
          value: (
            <Paragraph>
              {compensated
                ? `${parseFloat(compensated)
                    .toFixed(2)
                    .toString()
                    .replaceAll('.', ',')} kWh`
                : '-'}
            </Paragraph>
          ),
        },
        {
          value: (
            <Paragraph>
              {balance
                ? `${parseFloat(balance)
                    .toFixed(2)
                    .toString()
                    .replaceAll('.', ',')} kWh`
                : '-'}
            </Paragraph>
          ),
        },
      ],
    };
  });
};
const Contracts = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    contracts: [],
  });
  const [loadingg, setLoadingg] = useState(false);
  const [page, setPage] = useState(0);
  const [noMore, setNoMore] = useState(false);

  useEffect(() => {
    const fetchContracts = async () => {
      try {
        const resources = await CustomerSuccessService.getAll(page);
        if (!resources || resources?.length <= 0) {
          setNoMore(true);
          setPageState((state) => ({
            ...state,
            loading: false,
            hasError: false,
          }));
          return;
        } else {
          setNoMore(false);
        }
        setPageState((state) => ({
          ...state,
          loading: false,
          contracts: resources,
        }));
      } catch (err) {
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };

    fetchContracts();
  }, [page]);

  const fetchAll = async () => {
    const contractss = await CustomerSuccessService.getAll(0, 100000);
    return getRows(contractss);
  };

  const { loading, hasError, contracts } = pageState;

  return (
    <PageView
      title='Customer Success'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <ContentCard>
            <SearchableTable
              columns={COLUMNS}
              rowsValues={getRows(contracts)}
              noItemsText='Nenhum contrato'
              fetchAll={fetchAll}
            />
          </ContentCard>
          {!noMore && (
            <LoadMore
              loadin={loadingg}
              setPage={setPage}
            />
          )}
        </Grid>
      </Grid>
    </PageView>
  );
};

export default Contracts;

import adminAxiosConnectionResponse from './admin';
import basesAxiosConnectionResponse from './bases';
import defaultConnectionResponse from './default';
import s3GetDataAxiosConnectionResponse from './s3GetData';
import s3RefreshDataAxiosConnectionResponse from './s3RefreshData';
import userAxiosConnectionResponse from './user';

const responseConfigOptions = {
  admin: adminAxiosConnectionResponse,
  bases: basesAxiosConnectionResponse,
  user: userAxiosConnectionResponse,
  default: defaultConnectionResponse,
  s3GetData: s3GetDataAxiosConnectionResponse,
  s3RefreshData: s3RefreshDataAxiosConnectionResponse,
};

export default responseConfigOptions;

import React, { memo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { NewModal } from '../../NewModal';

import Loading from '../../../assets/images/logo-loading.gif';

function LoadingCustomModalComponent({ isLoading }) {
  return (
    <NewModal
      isLoading
      isOpen={isLoading}
    >
      {!!isLoading && (
        <img
          className='selected'
          src={Loading}
          alt='Logo flora carregamento um'
          height={80}
          width={80}
        />
      )}
    </NewModal>
  );
}

export const LoadingCustomModal = memo(LoadingCustomModalComponent);

LoadingCustomModalComponent.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  gap: 1.5rem;

  h2 {
    color: ${theme.palette.error.main};
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
  }

  p {
    color: #000;
    font-weight: 300;
    font-size: 1.125rem;
    text-align: center;
    margin: 0;
  }
`;

export const UploadButtonContainer = styled.div`
  width: 100%;

  input[type='file'] {
    display: none;
  }

  label {
    width: 100%;
    padding: 0.563rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    cursor: pointer;
    border-radius: 8px;
    background: ${theme.palette.secondary.main};
    color: ${theme.palette.primary.main};
    font-size: 1.25rem;
    font-weight: 500;
    transition: all 0.3s;

    &:hover {
      background: ${theme.palette.green.dark};
    }

    &:active {
      background: ${theme.palette.green.light};
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;

  button {
    padding: 0.563rem 0;
    font-size: 1.25rem;
  }

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

import React, { useState } from 'react';
import BasesManagement from '../../../../components/BasesManagement';
import BasesManagementService from '../../../../services/administrator/ManagementBases/BasesManagementService';
import { Button, Dialog, Modal } from '@mui/material';
import BillableItems from './BillableItems';
import verifyPermissionAction from '../../../../utils/verifyPermissionAction';

const AdminBillingManagement = () => {
  const [refreshedAt, setRefreshedAt] = useState(undefined);
  const [isOpenBillableItems, setIsOpenBillableItems] = useState(false);

  const extraComponents = {
    topComponents: [
      <div style={{ marginRight: '1rem' }}>
        <Button
          size='small'
          variant='outlined'
          onClick={() => setIsOpenBillableItems(true)}
        >
          Itens faturáveis
        </Button>
      </div>,
    ],
    buttonComponents: [],
    flexDirection: 'row',
  };
  return (
    <>
      <BasesManagement
        title='Gestão de faturamento'
        indentifier={'/billingManagement/invoices'}
        reciveMode=''
        hasFilter={true}
        hasPeriod={true}
        hasUpdateBase={true}
        hasPermissionToUpdate={verifyPermissionAction()}
        hasSelectItems={true}
        maxHeighComponent={window.innerHeight - 400}
        type='download'
        sharedRefreshedAt={{ refreshedAt, setRefreshedAt }}
        extraComponents={extraComponents}
      />

      <BasesManagement
        title='Gestão de erros'
        indentifier={'/billingManagement/errors'}
        reciveMode=''
        hasFilter={true}
        hasPeriod={true}
        hasUpdateBase={true}
        hasSelectItems={true}
        buttonSelectItems={{
          text: 'Reprocessar Itens',
          requestFunction: BasesManagementService.retry,
        }}
        maxHeighComponent={window.innerHeight - 500}
        type='table'
        sharedRefreshedAt={{ refreshedAt, setRefreshedAt }}
      />

      {isOpenBillableItems && (
        <BillableItems
          open={isOpenBillableItems}
          handleClose={() => setIsOpenBillableItems(false)}
        />
      )}
    </>
  );
};

export default AdminBillingManagement;

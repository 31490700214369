import { toast } from 'react-toastify';
import defaultConnectionResponse from './default';
import { TOAST_MESSAGES } from '../../../../helpers/toastMessages';
import axios from 'axios';

const s3GetDataAxiosConnectionResponse = (onRejected) => {
  defaultConnectionResponse(onRejected);
  const {
    data: { linkToGet },
    response,
  } = onRejected;

  try {
    return axios.get(linkToGet, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  } catch (error) {
    console.log('file: getData.js || line 64 || error', error);
  }

  const { status } = response;

  switch (status) {
    case 403:
      toast.error(TOAST_MESSAGES.UNAUTHORIZED, { autoClose: 3000 });
      break;
    default:
  }

  return { err: response };
};

export default s3GetDataAxiosConnectionResponse;

import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const PublicRouter = ({ component: Component, path, ...rest }) => (
  <Route
    {...rest}
    element={<Component {...rest} />}
    key={path}
    path={path}
  />
);

export default PublicRouter;

PublicRouter.propTypes = {
  component: PropTypes.elementType.isRequired,
};

import { useEffect, useState } from 'react';
import FlexColContainer from '../../componentsStyle/FlexColContainer';
import { Checkbox } from '../Checkbox';
import { InputCustom } from '../InputCustom';
import { validationData } from '../../helpers/form/validations';

export const AddressNumber = ({
  changePayload,
  number,
  FieldComponent = InputCustom,
  styleComponent = {},
  disabled,
}) => {
  const [type, setType] = useState('number');
  const [isWithoutNumber, setIsWithoutNumber] = useState(false);

  useEffect(() => {
    if (number.value === 'S/N') {
      setType('text');
      setIsWithoutNumber(true);
    } else setType('number');
  }, []);

  const handleNumber = (event) => {
    const value = event?.target?.value || event?.value;

    let status = 'accepted';
    const isNumberValid = validationData(value);

    if (!isNumberValid) {
      status = 'refused';
    }

    changePayload({
      number: {
        ...number,
        value,
        status,
      },
    });
  };

  const blurNumber = () => {
    const newNumber = { ...number, touched: true, status: 'accepted' };
    if (!number?.value?.length) newNumber.status = 'refused';

    changePayload({ number: newNumber });
  };

  const handleWithoutNumber = (event) => {
    event.persist();
    const { checked } = event.target;
    if (checked) {
      setType('text');
      setIsWithoutNumber(true);
      handleNumber({
        value: 'S/N',
      });
    } else {
      setType('number');
      setIsWithoutNumber(false);
      handleNumber({
        ...number,
      });
    }
  };

  return (
    <FlexColContainer>
      <FieldComponent
        type={type}
        name='number-address'
        labelText='* Número'
        value={number?.value}
        onChange={handleNumber}
        onBlur={blurNumber}
        hasError={!(number?.status === 'accepted')}
        hasTouched={number?.touched}
        requiredMessage='Número é obrigatório'
        maxLength={6}
        disabled={disabled || type === 'text'}
        fullwidth={1}
        style={styleComponent}
      />
      <Checkbox
        checked={isWithoutNumber}
        id='without-number'
        onChange={(event) => handleWithoutNumber(event)}
        disabled={disabled}
      >
        <label>Não tem número</label>
      </Checkbox>
    </FlexColContainer>
  );
};

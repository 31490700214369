import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.button`
  display: none;
  position: fixed;
  right: 16px;
  bottom: 24px;
  background: ${theme.palette.primary.main};
  border: 0;
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));
  z-index: 999999;
  @media (min-width: 992px) {
    display: block;
  }
`;

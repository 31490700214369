import styled from 'styled-components';

export const ArrowButton = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  z-index: 100;
  top: 80px;

  @media (min-width: 480px) {
    top: 68px;
  }

  @media (min-width: 992px) {
    top: 98px;
  }
`;

export const LegendsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    flex-direction: row;
    gap: 1rem;
  }
`;

export const LegendGraphic = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: start;

  @media (min-width: 992px) {
    justify-content: center;
  }
`;

import connection from './connections/main';

const getAll = async (page = 0, limit = 500) => {
  const { err, data } = await connection.get(
    `/offerManagement/cities?page=${page.toString()}&limit=${limit.toString()}`
  );

  if (err) {
    throw err;
  }

  return data;
};

const create = async (payload) => {
  const { err, data } = await connection.post(
    `offerManagement/cities`,
    payload
  );

  if (err) throw err?.data || err;

  return data;
};

const CitiesService = {
  getAll,
  create,
};

export default CitiesService;

import React from 'react';
import { Grid } from '@mui/material';
import { Heading2 } from '../../../../../components/Interface/Text';
import TitleContainer from '../../../../../componentsStyle/TitleContainer';
import ContractBillAttachmentSection from './ContractBillAttachmentSection';
import ContractBillMeasurements from './ContractBillMeasurements';
import adminStores from '../../../../../store/admin/contractDetails/index.store';

const ContractBillSection = () => {
  const {
    location: { location },
  } = adminStores();

  return (
    <Grid
      spacing={2}
      rowSpacing={2}
      className='container-section'
    >
      <Grid
        item
        xs={12}
      >
        <TitleContainer>
          <Heading2>Dados da conta de luz: {location?.nickname}</Heading2>
        </TitleContainer>
      </Grid>
      <ContractBillAttachmentSection />
      <ContractBillMeasurements />
    </Grid>
  );
};

export default ContractBillSection;

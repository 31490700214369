import connection from './connection';

const getAll = async (filters = {}) => {
  // const query = mountFiltersQuery(filters);
  // const { err, data } = await connection.get(`/producers${query}`);
  const { err, data } = await connection.get(`/companies`);

  if (err) {
    throw err;
  }

  return data;
};

const updateOne = async (id, payload) => {
  const { err, data } = await connection.patch(
    `/companies/edit/${id}`,
    payload
  );

  if (err) {
    throw err;
  }

  return data;
};

const deleteOne = async (payload) => {
  const { err, data } = await connection.post(`/companies/delete`, payload);

  if (err) {
    throw err;
  }

  return data;
};

const create = async (payload) => {
  const { err, data } = await connection.post(`/companies/create`, {
    company: payload,
  });

  if (err) {
    throw err;
  }

  return data;
};

const get = async (id) => {
  const { err, data } = await connection.get(`/companies/${id}`);

  if (err) {
    throw err;
  }

  return data;
};

const CompaniesService = {
  getAll,
  updateOne,
  deleteOne,
  create,
  get,
};

export default CompaniesService;

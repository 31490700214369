import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import theme from '../../../theme';

export const Input = styled.input`
  letter-spacing: 0.085em;
  font-family: 'Myriad-Pro-Condensed';
  background: ${colors.white};
  border: 1px solid ${theme.palette.primary.main};
  outline: none;
  border-radius: 2px;
  width: 389px;
  font-size: 20px;
  padding: 5px 15px;
  font-weight: 300;
  font-size: 16px;
`;

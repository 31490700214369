/* eslint-disable no-restricted-syntax */
/* eslint-disable default-case */
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getUrl } from '../helpers/getUrl';
import UsersService from '../services/general/users.service';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const initialState = [];
  const navigate = useNavigate();
  const [pendencies, setPendencies] = useState(initialState);
  const [globalPendencies, setGlobalPendencies] = useState(initialState);
  const [preparedPendencies, setPreparedPendencies] = useState(false);
  const [propertiesData, setPropertiesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkedForPendencies, setCheckedForPendencies] = useState(false);
  const [reload, setReload] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const [hasCustomerResearchPendency, setHasCustomerResearchPendency] =
    useState(false);

  const location = useLocation();
  const pathname = location?.pathname;

  const userPaths = ['/home', '/pendencias', '/recomendacoes'];

  const isInUserContext = userPaths.includes(pathname);

  const reloadData = useCallback(() => {
    setReload(!reload);
  }, []);

  const resetStagePayload = () => {
    setGlobalPendencies(initialState);
    setPendencies(initialState);
  };

  const filterRecommendations = (data) => {
    const filteredRecommendations = data.filter(
      (item) => item.requestProviderData === true
    );

    if (!filteredRecommendations.length) return;

    setRecommendations(filteredRecommendations);
  };

  useEffect(() => {
    (async () => {
      try {
        if (!isInUserContext) return;
        setLoading(true);
        resetStagePayload();
        const res = await UsersService.getUser();

        const { customerResearchDone: crd } = res?.[0] || {};
        setHasCustomerResearchPendency(!crd);
        filterRecommendations(res);
        setPropertiesData(res);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [reload, isInUserContext]);

  const handlePendenciesSetup = useCallback(
    (pendency, isGlobal, addressSelected, pastDueDateInvoices) => {
      try {
        const pushPendency = (pendency, isGlobal, finalData) => {
          if (isGlobal) {
            setGlobalPendencies((pendencies) =>
              pendencies.concat({ ...finalData, pendency })
            );
          } else {
            setPendencies((pendencies) =>
              finalData
                ? pendencies.concat({ ...finalData, pendency })
                : pendencies.concat(pendency)
            );
          }
        };

        const { stage, id, nickname, location_id, status } = addressSelected;

        if (isGlobal) {
          const finalData = { id, nickname };
          switch (pendency) {
            case 'register':
              Object.assign(finalData, { stage, status });
              pushPendency(pendency, isGlobal, finalData);
              break;
            case 'attachment':
              Object.assign(finalData, { location_id });
              pushPendency(pendency, isGlobal, finalData);
              break;
            case 'invoice':
              Object.assign(finalData, { invoices: pastDueDateInvoices });
              pushPendency(pendency, isGlobal, finalData);
              break;
          }
        } else if (pendency === 'register')
          pushPendency(pendency, isGlobal, { stage, status, id });
        else pushPendency(pendency);
      } catch (error) {
        console.log('file: index.js || line 300 || error', error);
      }
    },
    []
  );

  const hasInvoiceWithPendency = useCallback((isGlobal, addressSelected) => {
    try {
      const { invoices } = addressSelected;
      const isPastDueDateInvoices = [];
      const notPaidInvoices = invoices.filter(
        ({ status }) => status === 'pending'
      );
      let isPastDueDate = false;

      for (const invoice of notPaidInvoices) {
        const { due_date } = invoice;
        if (!due_date) continue;
        const invoiceDate = new Date(due_date);
        const invoiceTime = invoiceDate.getTime();
        const now = Date.now();
        const invoicePastDueDate = invoiceTime < now;
        if (!invoicePastDueDate) continue;

        if (!isGlobal) {
          isPastDueDate = true;
          break;
        }

        isPastDueDateInvoices.push(invoice);
      }

      if (isGlobal ? !isPastDueDateInvoices.length : !isPastDueDate) return;

      handlePendenciesSetup(
        'invoice',
        isGlobal,
        addressSelected,
        isPastDueDateInvoices
      );
    } catch (error) {
      console.log('file: index.js || line 300 || error', error);
    }
  }, []);

  const handlePendencies = useCallback((addressSelected, isGlobal = false) => {
    try {
      const { status, attachment, profile, invoices } = addressSelected;
      switch (status) {
        case 'draft':
        case 'pending_signature':
          handlePendenciesSetup('register', isGlobal, addressSelected);
          break;
        case 'pending_activation':
          if (profile === 'on_hold')
            return handlePendenciesSetup('on_hold', isGlobal, addressSelected);
          if (!attachment)
            handlePendenciesSetup('attachment', isGlobal, addressSelected);
          else
            handlePendenciesSetup(
              'newProfileAndNewLocation',
              isGlobal,
              addressSelected
            );
          break;
        case 'active':
          handlePendenciesSetup('waitInvoice', isGlobal, addressSelected);
        case 'default':
          if (!invoices?.length) break;
          hasInvoiceWithPendency(isGlobal, addressSelected);
          break;
      }
    } catch (error) {
      console.log('file: index.js || line 300 || error', error);
    }
  }, []);

  const checkGlobalPendencies = useCallback((propertiesData) => {
    try {
      if (!propertiesData?.length) return;

      for (const location of propertiesData) handlePendencies(location, true);
      setCheckedForPendencies(true);
    } catch (error) {
      console.log('file: index.js || line 300 || error', error);
    }
  }, []);

  const handlePendenciesAlocation = useCallback((globalPendencies) => {
    try {
      const finalPendencies = {};

      for (const pendencyObject of globalPendencies) {
        const { pendency } = pendencyObject;
        if (!finalPendencies[pendency]) finalPendencies[pendency] = [];
        finalPendencies[pendency].push(pendencyObject);
      }
      if (!Object.values(finalPendencies).length) return;

      setPreparedPendencies(finalPendencies);
    } catch (error) {
      console.log('file: index.js || line 41 || error', error);
    }
  }, []);

  const handleNoMorePendensies = useCallback(() => {
    const { path } = getUrl();

    setTimeout(() => {
      if (path === 'pendencias' && !globalPendencies.length) navigate('/home');
    }, 5000);
  }, [globalPendencies]);

  useEffect(() => {
    if (!checkedForPendencies) return;

    handleNoMorePendensies();
  }, [checkedForPendencies]);

  useEffect(() => {
    if (!propertiesData) return;

    checkGlobalPendencies(propertiesData);
  }, [propertiesData]);

  useEffect(() => {
    if (!globalPendencies) return;

    handlePendenciesAlocation(globalPendencies);
  }, [globalPendencies]);

  return (
    <UserContext.Provider
      value={{
        pendencies,
        setPendencies,
        globalPendencies,
        setGlobalPendencies,
        recommendations,
        handlePendencies,
        checkGlobalPendencies,
        preparedPendencies,
        setPreparedPendencies,
        resetStagePayload,
        propertiesData,
        loading,
        reloadData,
        hasCustomerResearchPendency,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

import styled, { css } from 'styled-components';
import theme from '../../theme';

const isPrimary = css`
  background: ${theme.palette.secondary.main};
  color: ${theme.palette.primary.main};
  border: 0;
  font-size: 1.25rem;
  padding: 0.75rem 1.375rem;
  font-weight: 600;

  &:hover {
    background: ${theme.palette.green.dark};
    color: ${theme.palette.purple.dark};
  }

  &:active {
    background: ${theme.palette.green.light};
    color: ${theme.palette.purple.light};
  }
`;

export const Container = styled.div`
  /* margin: 0 48px; */
  /* display: flex;
  flex-direction: column; */

  a {
    font-size: 0.875rem;
    font-weight: 500;
    color: ${theme.palette.primary.main};
  }

  p {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    font-weight: 300;
    color: #605d62;
    text-align: center;
    margin-bottom: 0;
  }

  span {
    margin-top: 0.25rem;
    color: #313033;
    font-size: 0.875rem;
    font-weight: 300;
  }
`;

export const UploadButtonContainer = styled.div`
  input[type='file'] {
    display: none;
  }

  label {
    margin: 0;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    /* margin-top: 1rem; */

    cursor: pointer;
    border-radius: 8px;
    border: 1px solid ${theme.palette.primary.main};
    background: none;
    color: ${theme.palette.primary.main};
    font-size: 1.125rem;
    font-weight: 500;
    transition: all 0.3s;

    &:hover {
      background: #ffefd8;
      color: ${theme.palette.purple.dark};
      border-color: ${theme.palette.purple.dark};
    }

    &:active {
      background: ${theme.palette.purple.light};
      color: ${theme.palette.purple.dark};
      border-color: ${theme.palette.purple.light};
    }

    &:disabled {
      width: 25.75rem;
      height: 3rem;
      color: ${theme.palette.disable.main};
      background: none;
      border: 1px solid ${theme.palette.disable.main};
      border-radius: 8px;
      font-size: 1.125rem;
      font-weight: 500;
      cursor: not-allowed;
    }

    ${(props) => props.className === 'primary' && isPrimary}
    ${(props) =>
      props.className === 'stageOne' &&
      css`
        padding: 0.625rem 0;
      `}
  }
`;

export const Strong = styled.strong`
  color: ${theme.palette.primary.main}
  font-size: ${(props) => (props.isNameFile ? '1rem' : '0.875rem')};
  font-weight: 700;
`;

export const LabelAttachment = styled.div`
  margin: 1rem 0;
  font-size: 0.875rem;
  color: #605d62;
  font-weight: 400;
`;

export const UploadContainer = styled.div`
  background: ${theme.palette.green.light};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.533rem;
  padding: 0 0.625rem;
  margin-top: 1rem;
`;

export const UploadContent = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    margin-left: 1.625rem;
  }
`;

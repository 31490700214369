import { randomNumber } from '../helpers/numbers';
import { useAuthStore } from '../store';

const setupHotjar = async (errorCount = 0) => {
  try {
    const user = { id: null };
    const userStore = useAuthStore.getState().userAuth;
    if (userStore) {
      Object.assign(user, userStore);
    }

    const {
      role,
      promocode,
      provider,
      c_signed_at,
      c_actived_at,
      first_invoicing,
      c_status,
    } = user;

    window.hj('identify', user.id, {
      role,
      contract_signed_at: c_signed_at || 'Não há dados',
      contract_actived_at: c_actived_at || 'Não há dados',
      contract_status: c_status || 'Não há dados',
      provider: provider || 'Não há dados',
      first_invoicing: first_invoicing || 'Não há dados',
      promocode: promocode || 'Não há dados',
      enviroment: import.meta.env.REACT_APP_ENV,
    });
  } catch (error) {
    const milliseccondsToWait = randomNumber(
      1000 * 60 + (2 * errorCount + 1),
      1000 * 60 + (2 * errorCount + 1) * 2
    );

    return setTimeout(
      () => setupHotjar((errorCount += 1)),
      milliseccondsToWait
    );
  }
};

export { setupHotjar };

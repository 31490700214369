import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { MdOutlineChat } from 'react-icons/md';

import { useAuthStore, useGlobalStore, useZendeskStore } from '../../store';

import LogoFullWhite from '../../assets/images/logo/logo-flora-white.svg';

import Navbar from '../../components/Navbar';

import { ThemeContext } from '../../contexts/themeContext';
import chatImg from '../../assets/images/icons/negative-png/mensagem-suporte 2.png';

const BaseDashboardSidebar = ({ className, children = undefined }) => {
  const { isOpenZendesk, open, close, shouldHideBtn } = useZendeskStore();
  const [classMenu, setClassMenu] = useState('open-main-nav');
  const { mobileView, pageName, menuMobileIsOpen, setMenuMobileIsOpen } =
    useGlobalStore();
  const { themeValue } = useContext(ThemeContext);

  const user = useAuthStore((state) => state.userAuth);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(null);

  useEffect(() => {
    if (!menuMobileIsOpen) {
      setClassMenu('open-main-nav');
    } else {
      setClassMenu('open-main-nav is-open');
    }
  }, [menuMobileIsOpen]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenuMobileIsOpen(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const handleOpenNavbarClick = (e) => {
    if (menuMobileIsOpen) {
      setClassMenu('open-main-nav');
    } else {
      setClassMenu('open-main-nav is-open');
    }
    setMenuMobileIsOpen(!menuMobileIsOpen);
  };

  const handleNavItemClick = (e, link) => {
    if (
      link === '/pendencias' &&
      window.localStorage.getItem('closed_pendency_modal')
    ) {
      window.localStorage.removeItem('closed_pendency_modal');
      navigate('/home');
      // window.location.reload();
    }
    setMenuMobileIsOpen(false);
    setClassMenu('open-main-nav');
  };

  useEffect(() => {
    if (
      user?.role === 'admin' &&
      (window.localStorage.getItem('closed_pendency_modal') ||
        window.localStorage.getItem('has_pendency'))
    ) {
      window.localStorage.removeItem('closed_pendency_modal');
      window.localStorage.removeItem('has_pendency');
      // window.location.reload();
    }
  }, [user]);

  useEffect(() => {
    if (user?.role !== 'admin' && user?.company_id != '0' && !themeValue) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [themeValue, user]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <>
      {!loading && (
        <div
          ref={wrapperRef}
          className={className.concat(menuMobileIsOpen ? ' open' : '')}
        >
          <div className='mobile-topbar'>
            <button
              className={classMenu}
              type='button'
              onClick={(e) => handleOpenNavbarClick(e)}
            >
              <span className='burger' />
            </button>
            <img
              src={LogoFullWhite}
              alt='Logo Flora Completa'
              className='logo-full'
            />
            {!shouldHideBtn ? (
              <div>
                <img
                  //className='help-button'
                  onClick={() => (isOpenZendesk ? close() : open())}
                  src={chatImg}
                  style={{
                    width: '30px',
                    height: '30px',
                    display: 'block',
                  }}
                />
              </div>
            ) : null}
            {/* <img
              src={
                themeValue?.logo_link && themeValue?.logo_link !== ''
                  ? `${import.meta.env.REACT_APP_AWS_BUCKET_URL}/${themeValue.logo_link}`
                  : LogoPNG
              }
              className='logo'
              alt='Logo da Flora'
            /> */}
          </div>
          {children}
          <Separator themeValue={themeValue} />
          <Navbar
            handleNavItemClick={handleNavItemClick}
            classy={classMenu}
            themeValue={themeValue}
            user={user}
          />
        </div>
      )}
    </>
  );
};

BaseDashboardSidebar.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const SIDEBAR_WIDTH = 250;

export const DashboardSidebar = styled(BaseDashboardSidebar)`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${(props) => props.value.themeValue.navbar_bg_color};
  width: 250px;
  height: 100%;
  transition: width 0.3s ease;
  z-index: 600;

  img:last-child {
    display: none;
  }

  @media (min-width: 992px) {
    width: 70px;

    &:hover {
      width: ${SIDEBAR_WIDTH}px;

      .logo {
        display: none;
      }

      img:last-child {
        display: block;
        width: 125px;
      }
    }
  }

  &:hover .margin-icon {
    width: 180px;
    display: inline-block;
  }

  .open-main-nav {
    z-index: 1000;
    background: none;
    border: 0;
    cursor: pointer;
    padding: 5px 0px;
  }

  .open-main-nav:focus {
    outline: none;
  }

  .burger {
    display: block;
    width: 20px;
    border-top: 2px solid;
    color: ${(props) => props.value.themeValue.navbar_text_color};
  }

  .burger:after,
  .burger:before {
    content: '';
    width: 20px;
    height: 2px;
    display: block;
    background: currentColor;
    margin-top: 3px;
    transition: 0.3s;
    position: relative;
  }

  .is-open .burger {
    border-top-color: transparent;
  }

  .is-open .burger:before {
    transform: rotate(45deg);
    top: -2.5px;
  }

  .is-open .burger:after {
    transform: rotate(-45deg);
    position: absolute;
    top: 26px;
  }

  .logo-full {
    width: 99.24px;
    height: 32px;
  }

  .help-button {
    cursor: pointer;
    border: none;
    background: none;
  }

  /* ESTILIZAÇÃO DO MENU AO TOPO */
  .mobile-topbar {
    top: 0;
    left: 0;
    position: fixed;
    background-color: ${(props) => props.value.themeValue.navbar_bg_color};
    width: 100vw;
    height: 60px;
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;

    img {
      height: 30px;
    }

    @media (max-width: 991px) {
      /* display: block; */
      display: flex;
    }
  }

  /* MEDIA QUERY PARA TIRAR O SIDEBAR */

  /* breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1366,
  }, */

  @media screen and (max-width: 991px) {
    left: -${SIDEBAR_WIDTH}px;
    padding-top: 60px;

    &.open {
      left: 0;
    }
  }
`;

const BaseDashboardPageView = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

BaseDashboardPageView.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const DashboardPageView = styled(BaseDashboardPageView)`
  width: calc(100% - 70px);
  margin-left: 70px;
  transition: margin-left 0.3s ease;

  @media screen and (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
`;

const BaseDashboard = ({ className, children }) => {
  return <div className={className}>{children}</div>;
};

BaseDashboard.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const Dashboard = styled(BaseDashboard)`
  height: 100%;
  min-height: 100vh;
`;

const Separator = styled.div`
  height: 1px;
  background: ${(props) => props.themeValue.navbar_separator};
  margin: 0 12px;
  display: none;

  @media (min-width: 992px) {
    display: block;
  }
`;

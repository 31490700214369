export const modalContainerStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const modalContentStyle = {
  backgroundColor: 'white',
  width: 'fit-content',
  height: 'auto',
  padding: '2rem 4rem',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const titleStyle = {
  color: '#61787b',
};

export const progressStyle = {
  color: 'orange',
};

export const buttonContainerStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  marginTop: '2rem',
};

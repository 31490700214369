import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { NewModal } from '../../NewModal';
import { PrimaryButton } from '../../PrimaryButton';
import { SecondaryButton } from '../../SecondaryButton';

import { Container, Header, Content, ButtonsContainer } from './styles';

export const ImproveExperienceModal = ({ isOpen, onRequestClose, id = '' }) => {
  const navigate = useNavigate();

  const handleFillInDistributorData = () => {
    if (!id) return;

    const state = 'isUserExperience';
    navigate(`/imovel/${id}`, state);
  };

  const handleClose = () => {
    localStorage.setItem('@FloraEnergia:closedImproveYourExperience', true);
    onRequestClose();
  };

  return (
    <NewModal
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      <Container>
        <Header>
          <h2>Melhore sua experiência!</h2>
        </Header>

        <Content>
          <p>
            Nos fornecendo seus dados de acesso à agência virtual de sua
            distribuidora conseguimos maximizar sua economia e experiência
            Flora.
          </p>
          <ButtonsContainer>
            <PrimaryButton onClick={handleFillInDistributorData}>
              Preencher dados
            </PrimaryButton>
            <SecondaryButton onClick={handleClose}>Fechar</SecondaryButton>
          </ButtonsContainer>
        </Content>
      </Container>
    </NewModal>
  );
};

ImproveExperienceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  id: PropTypes.string,
};

import React from 'react';
import { Container } from './styles';

export const SecondaryButton = ({ children, ...rest }) => {
  return (
    <Container type='button' {...rest}>
      {children}
    </Container>
  );
};

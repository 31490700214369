import styled from 'styled-components';
import { colors } from '../../../styles/colors';

export const Anchor = styled.a`
  color: ${(props) => props.color || colors.primary};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

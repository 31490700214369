import InitialProposalForm from '../../../../../components/Forms/InitialProposalForm';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { allCalc } from '../../../../../utils/calculateProposal';
import Divider from '../../../../../components/Divider';
import adminStores from '../../../../../store/admin/contractDetails/index.store';

const ContractLocationsProposal = () => {
  const [initialProposal, setInitialProposal] = useState(null);
  const {
    location: { location: selectedLocation },
  } = adminStores();

  useEffect(() => {
    let mounted = true;
    if (Object.values(!selectedLocation).length > 0) return;
    const runAllCalc = async () => {
      if (mounted) {
        const { ocr, estimated_electric_bill, provider, user_promocode } =
          selectedLocation;

        if (!(estimated_electric_bill && provider)) return;

        const insumesForCalc = {
          estimated_electric_bill,
          provider,
          promocode: user_promocode,
        };

        const proposal = await allCalc(insumesForCalc);
        setInitialProposal({ ...proposal, estimated_electric_bill });
      }
    };

    runAllCalc();

    return () => (mounted = false);
  }, [selectedLocation]);

  return (
    <Grid
      item
      xs={12}
    >
      <Divider space='medium' />
      {initialProposal && (
        <InitialProposalForm
          values={initialProposal}
          initialValues={selectedLocation}
          style={{ marginTop: 20 }}
        />
      )}
    </Grid>
  );
};
export default ContractLocationsProposal;

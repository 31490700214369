import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AuthService from '../../services/authentication/auth.service';
import { isUserAuthenticated } from '../../store';
import StyleguidesService from '../../services/administrator/styleguides.service';
import { ThemeContext } from '../../contexts/themeContext';
import CompaniesService from '../../services/general/companies.service';

import { NewPageView } from '../../containers/NewPageView';
import { InputCustom } from '../../components/InputCustom';
import { useStage } from '../../hooks/useStage';
import { PrimaryButton } from '../../components/PrimaryButton';
import { LoadingCustomModal } from '../../components/Modals/LoadingCustomModal';

import { isTokenValid } from '../../store/auth.store';

import LogoImg from '../../assets/images/logo/logo-flora.svg';
import {
  Container,
  Content,
  ContInput,
  ImageContainer,
  Form,
  Title,
  ButtonContainer,
} from './styles';
import { setUser, useAuthStore } from '../../store/auth.store';
import { redirectToInitialPage } from '../../helpers/loginHelpers';
import { toast } from 'react-toastify';
import UsersService from '../../services/general/users.service';
import { setText } from './helpers';
import InputPassword from '../../components/InputPassword';

export const DefinePassword = ({ className = '' }) => {
  const user = useAuthStore((state) => state.userAuth);
  const { stagePayload } = useStage();

  const emailFromStage = stagePayload?.email?.value;
  const emailFromToken = user?.email;
  const [email, setEmail] = useState(emailFromToken || emailFromStage);
  const [password, setPassword] = useState('');
  const [weakPassword, setWeakPassword] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(true);
  const [touchedConfirmPassword, setTouchedConfirmPassword] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const navigate = useNavigate();

  const [hasLoginError, setHasLoginError] = useState(false);
  const [showPoweredBy, setShowPoweredBy] = useState(false);
  const firstPassRef = useRef();
  const landingPageRef = useRef();

  const [loading, setLoading] = useState(false);
  const { themeValue, setthemeValue } = useContext(ThemeContext);
  const location = useLocation();
  const [resetPasswordToken, setToken] = useState(null);
  const texts = setText(
    resetPasswordToken ? 'resetPassword' : 'definePassword'
  );

  const checkStyleChange = () => {
    if (location?.search !== '' && location?.search) {
      const temp = location.search.slice(1, location.search.length).split('&');
      const companyDirty = temp.filter((query) => {
        if (query.split('=')[0] === 'company_id') {
          window.localStorage.setItem('company_id', query.split('=')[1]);
          setUserTheme(query.split('=')[1]);
        }
      });
    } else if (window.localStorage.getItem('company_id')) {
      const company_id = window.localStorage.getItem('company_id');
      if (company_id) {
        setUserTheme(company_id);
      }
    }
  };

  const setUserTheme = async (id) => {
    if (!id || !parseInt(id)) return;
    try {
      const [styleguide] = await StyleguidesService.getByCompanyId(id);
      if (!styleguide) return;
      const company = await CompaniesService.get(id);
      if (!company) return;
      setShowPoweredBy(company?.[0]?.show_powered_by);
      setthemeValue({ ...styleguide[0] });
    } catch (error) {
      console.log(error);
    }
  };

  const handleResetPasswordSubmit = async (event) => {
    event.preventDefault();

    if (weakPassword === false && password.trim() === passwordConfirm.trim()) {
      try {
        setLoading(true);
        if (!isTokenValid() && !resetPasswordToken)
          await AuthService.login(email);

        if (resetPasswordToken) {
          await UsersService.resetPasswordByToken(
            resetPasswordToken,
            email,
            password.trim()
          );
        } else {
          await AuthService.setPassword({
            password: password.trim(),
          });
        }
        await AuthService.login(email, password);
        await setUser();
        const userRole = useAuthStore.getState().userAuth?.role;
        await redirectToInitialPage(navigate, userRole);
      } catch (err) {
        const { status } = err;

        setHasLoginError(true);
        if (status === 425) {
          toast.error('Senha já utilizada anteriormente.');
        } else if (status === 422) {
          toast.error(
            'Senha fraca. Deve conter pelo menos 8 caracteres, incluindo letra maiúscula, letra minúscula, caractere especial e números.'
          );
        } else if (status === 400) {
          toast.error('Ops! Seu contrato ainda consta como não foi assinado.');
        } else if (status !== 403) {
          toast.error('Ops! Ocorreu um erro, tente novamente mais tarde');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const redirectToLandingPage = () => {
    import.meta.env.ENV === 'dev' || import.meta.env.REACT_APP_ENV === 'dev'
      ? navigate('/')
      : landingPageRef?.current && landingPageRef.current.click();
  };

  const handleConfirmPassword = (event) => {
    setPasswordConfirm(event.target.value);
  };

  useEffect(() => {
    if (passwordConfirm.length >= 8) {
      setTouchedConfirmPassword(true);
    }
  }, [passwordConfirm]);

  useEffect(() => {
    let mounted = true;
    const checkAuth = async () => {
      const searchParams = new URLSearchParams(location.search);
      const tokenParam = searchParams.get('token');

      if (tokenParam) {
        setToken(tokenParam);
      }

      if (!tokenParam && !isUserAuthenticated() && mounted) {
        navigate('/login');
      } else if (isUserAuthenticated() && mounted) {
        const role = useAuthStore.getState().userAuth?.role;
        await redirectToInitialPage(navigate, role);
      }
    };

    checkAuth();
    checkStyleChange();

    if (firstPassRef.current) {
      firstPassRef.current.firstChild.focus();
    }

    return () => {
      mounted = false;
    };
  }, [location.search]);

  useEffect(() => {
    if (resetPasswordToken) {
      const fetchEmail = async () => {
        try {
          const userEmail = await UsersService.getUserEmailByToken(
            resetPasswordToken
          );
          setEmail(userEmail.email);
        } catch (error) {
          toast.error(
            'Seu token de recuperação de senha expirou. Por favor, solicite um novo link de recuperação.'
          );
          navigate('/login');
        }
      };
      fetchEmail();
    }
  }, [resetPasswordToken]);

  useEffect(() => {
    if (!invalidPassword && password.trim() === passwordConfirm.trim()) {
      setButtonDisabled(false);
      return;
    }

    setButtonDisabled(true);
  }, [password, passwordConfirm]);

  return (
    <NewPageView>
      <LoadingCustomModal isLoading={loading} />
      <Container>
        <Content>
          <a href='https://floraenergia.com.br'>
            <ImageContainer>
              <img
                src={LogoImg}
                alt='Flora Energia'
              />
            </ImageContainer>
          </a>

          <Title>{texts.title}</Title>

          <p>{texts.firstLine}</p>

          <p>{texts.secondLine}</p>

          <Form onSubmit={handleResetPasswordSubmit}>
            {!!email && (
              <InputCustom
                type='email'
                name='email'
                labelText='Email'
                value={email}
                disabled
              />
            )}

            <ContInput>
              <InputPassword
                setValuePassword={setPassword}
                invalidPassword={invalidPassword}
                setInvalidPassword={setInvalidPassword}
                resetPasswordToken={resetPasswordToken}
              />
            </ContInput>
            <ContInput>
              <InputCustom
                type='password'
                name='confirmPassword'
                labelText='Confirmar senha'
                value={passwordConfirm}
                onChange={handleConfirmPassword}
                hasError={password.trim() !== passwordConfirm.trim()}
                hasTouched={touchedConfirmPassword}
                errorMessage='As senhas não são iguais. Tente novamente'
              />
            </ContInput>
            <ButtonContainer>
              <PrimaryButton
                type='submit'
                disabled={buttonDisabled}
              >
                {texts.button}
              </PrimaryButton>
            </ButtonContainer>
          </Form>
        </Content>
      </Container>
    </NewPageView>
  );
};

DefinePassword.propTypes = {
  className: PropTypes.string,
};

import adminStores from '../../../../store/admin/contractDetails/index.store';

const AddManagementsInPayload = (payload) => {
  const {
    contract: { contract },
    alerts: { alerts },
  } = adminStores();

  const management = {
    contracts: { id: contract.id },
    olderAlerts: alerts,
  };

  Object.assign(payload, { management });
  return payload;
};
export default AddManagementsInPayload;

import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  min-height: 100%;
  background: ${(props) => props.themeValue.page_user_background};
  padding-top: 3.75rem;

  @media (max-width: 991px) {
    margin-top: 1.5rem;
  }
`;

export const Content = styled.div`
  max-width: 428px;
  margin: 0 auto;
  padding: 1.5rem;

  @media (min-width: 992px) {
    max-width: 1128px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  h1 {
    color: ${theme.palette.primary.main};
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
  }
  h1 strong {
    color: ${theme.palette.primary.main};
  }
  svg {
    color: ${theme.palette.primary.main};
  }
`;

export const Separator = styled.div`
  background: ${theme.palette.gray.main};
  margin: 1.5rem 0;
  height: 1px;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
`;

export const CardContent = styled.div`
  p,
  span {
    color: #1f1b1e;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    margin: 0;

    strong {
      font-weight: 500;
    }
  }

  p {
    margin-bottom: 1rem;
  }

  button {
    height: 2.75rem;
  }
`;

export const Properties = styled.div`
  div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  hr {
    display: none;
  }

  & + div hr {
    display: block;
  }
`;

import React from 'react';
import { PrimaryButton } from '../components/PrimaryButton';

/* eslint-disable default-case */
const handleNewLocationResume = (id, status, desiredStage, navigate) => {
  try {
    const pathname = '/imoveis/novo';
    const state = { id: +id, status };
    if (status === 'pending_signature' && desiredStage)
      Object.assign(state, { stage: desiredStage });
    return navigate({ pathname, state });
  } catch (error) {
    console.log('file: index.js || line 87 || error', error);
  }
};

export const getRegisterButton = ({ id, status }, navigate) => {
  try {
    if (!status) return <></>;
    const defaultHandler = (stage) =>
      handleNewLocationResume(id, status, stage, navigate);
    switch (status) {
      case 'draft':
        return (
          <PrimaryButton onClick={() => defaultHandler()}>
            Continuar contratação
          </PrimaryButton>
        );

      case 'pending_signature':
        return (
          <>
            <PrimaryButton onClick={() => defaultHandler(3)}>
              Assinar Agora
            </PrimaryButton>
            <PrimaryButton onClick={() => defaultHandler(5)}>
              Trocar signatário
            </PrimaryButton>
          </>
        );
    }
  } catch (error) {
    return <></>;
  }
};

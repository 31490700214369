import connection from '../connections/main';

const getAll = async (page, limit = 100) => {
  const { err, data } = await connection.get(
    `/allInvoicesBase?page=${page}&limit=${limit}`
  );

  if (err) {
    throw err;
  }

  return data;
};

const AllInvoicesService = {
  getAll,
};

export default AllInvoicesService;

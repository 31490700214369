import * as Yup from 'yup';
import { formErrors } from '../../../helpers/form/errors';
import { onlyNumbers } from '../../../helpers/form/formatter';
import { PAYMENT_METHODS } from '../../../helpers/paymentMethods';
import { utils } from '../../../utils/iugu';

const commonNumber = Yup.string()
  .required(formErrors.required)
  .transform(onlyNumbers);

let creditCardValue;

const PaymentDetailsFormSchema = Yup.object().shape({
  email: Yup.string()
    .email(formErrors.invalidEmail)
    .required(formErrors.required),
  first_name: Yup.string().required(formErrors.required),
  last_name: Yup.string().required(formErrors.required),
  method: Yup.string()
    .oneOf([...Object.values(PAYMENT_METHODS)])
    .required(formErrors.required),
  street: Yup.string()
    .max(40, formErrors.maxLength(40))
    .required(formErrors.required),
  number: Yup.string().ensure().required(formErrors.required),
  complement: Yup.string().ensure(),
  postal_code: Yup.string().required(formErrors.required),
  neighborhood: Yup.string()
    .max(40, formErrors.maxLength(40))
    .required(formErrors.required),
  city: Yup.string()
    .max(40, formErrors.maxLength(40))
    .required(formErrors.required),
  state: Yup.string()
    .max(40, formErrors.maxLength(40))
    .required(formErrors.required),
  card: Yup.object().when(['method', 'isPageAdmin'], {
    is: (...fields) => 'method' === PAYMENT_METHODS.Card && !'isAdminPage',
    then: () =>
      Yup.object().shape({
        number: utils
          ? commonNumber.test(
            'cardNumberTest',
            formErrors.cardNumbersInvalid,
            (value) => {
              creditCardValue = value;
              return value && utils.validateCreditCardNumber(value);
            }
          )
          : commonNumber,
        verification_value: utils
          ? Yup.string()
            .required(formErrors.required)
            .test(
              'cardCvvTest',
              formErrors.cardCvvInvalid,
              (value) =>
                value &&
                creditCardValue &&
                utils.validateCVV(
                  value,
                  utils.getBrandByCreditCardNumber(creditCardValue)
                )
            )
          : Yup.string().required(formErrors.required),
        expiration: utils
          ? Yup.string()
            .required(formErrors.required)
            .test(
              'cardExpDateTest',
              formErrors.cardExpDateInvalid,
              (value) =>
                value &&
                utils.validateExpiration(
                  ...value?.split('/').map((datePart) => +datePart)
                )
            )
          : Yup.string().required(formErrors.required),
      }),
  }),
});

export default PaymentDetailsFormSchema;

import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import AuthService from '../../services/authentication/auth.service';
import PageView from '../../containers/PageView';
import { removeAuthData } from '../../store';
import { LoadingCustomModal } from '../../components/Modals/LoadingCustomModal';
import { NewPageView } from '../../containers/NewPageView';
import { ThemeContext } from '../../contexts/themeContext';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

export const Container = styled.div`
  min-height: 100%;
  background: ${(props) => props.themeValue.page_user_background};
  padding-top: 60px;

  @media (max-width: 991px) {
    margin-top: 24px;
  }
`;

const Logout = () => {
  const { themeValue } = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {
    const request = async () => {
      await AuthService.logout();
      navigate('/login');
      removeAuthData();
      if (window.localStorage.getItem('company_id')) {
        window.localStorage.removeItem('company_id');
      }
      if (window.localStorage.getItem('closed_pendency_modal')) {
        window.localStorage.removeItem('closed_pendency_modal');
      }
    };

    request();
  });

  return (
    <NewPageView>
      <Container themeValue={themeValue}>
        <LoadingCustomModal isLoading={true} />;
      </Container>
    </NewPageView>
  );
};

Logout.propTypes = {};

export default Logout;

import { toast } from 'react-toastify';
import { stage0ExceptionErrors } from './exceptionPerStage/0';
import { errorMessageToField, extraPayloadPerField } from './configurations';

const exceptionsPerStage = { 0: stage0ExceptionErrors };

const getHandledValue = (oldValue, { textError, extraPayload }) => {
  const finalPayload = { ...oldValue };
  if (textError) finalPayload.textError = textError;
  if (extraPayload && extraPayload instanceof Object)
    Object.assign(finalPayload, extraPayload);
  else finalPayload.status = 'refused';
  return finalPayload;
};

export const errorHandler = (err, stagePayload, functions, changePayload) => {
  const { data: errorResponse } = err || {};

  const { stage } = stagePayload;

  const stageExceptionFunction = exceptionsPerStage[stage];

  if (stageExceptionFunction) {
    const workingPremisses = { err, stagePayload, functions, changePayload };
    const hasHandledError = stageExceptionFunction(workingPremisses);
    if (hasHandledError) return;
  }

  const { invalidFields } = errorResponse;

  if (!invalidFields.length)
    return toast.error('Houve um erro desconhecido, tente novamente.');

  const finalPayload = {};

  for (const errorKey of invalidFields) {
    const textError = errorMessageToField[errorKey];
    if (!textError) continue;
    const oldValue = stagePayload[errorKey];
    const allExtraPayloads = extraPayloadPerField?.[errorKey];
    const newValue = getHandledValue(oldValue, { textError });

    finalPayload[errorKey] = newValue;

    for (const key in allExtraPayloads) {
      const extraPayload = allExtraPayloads[key];

      const oldValue = stagePayload[key];

      const hasKey = !!oldValue;
      if (!hasKey) finalPayload[key] = extraPayload;
      else {
        const newValue = getHandledValue(oldValue, { extraPayload });
        finalPayload[key] = newValue;
      }
    }
  }

  changePayload(finalPayload);
};

import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import Table from '../Table/table';
import styled from 'styled-components';

const CustomModal = ({ data }) => {
  const { type, params, modals, value, title } = data;

  const [open, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        variant='outlined'
        onClick={openModal}
      >
        {value}
      </Button>
      {open && (
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          aria-describedby='alert-dialog-slide-description'
          fullWidth
          maxWidth='lg'
        >
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            <Button onClick={handleClose}>x</Button>
          </DialogHeader>
          <DialogContent>
            {type === 'table' ? <Table data={{ params, modals }} /> : null}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  h2 {
    flex-grow: 1;
    text-align: center;
  }
`;

export default CustomModal;

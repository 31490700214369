import React from 'react';
import PropTypes from 'prop-types';

import { NewModal } from '../../NewModal';

import {
  Container,
  Header,
  DistributorOptions,
  Body,
  ButtonContainer,
} from './styles';

import { PrimaryButton } from '../../PrimaryButton';
import { SecondaryButton } from '../../SecondaryButton';

export const WhereToFindYourAccountModal = ({ isOpen, onRequestClose }) => {
  return (
    <NewModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      isFiledOCR
    >
      <Container>
        <Header>Não sabe onde encontrar sua conta de luz em PDF?</Header>
        <Body>
          <div>
            <p>
              Para baixar o PDF de sua conta de luz você deve acessar o site da
              sua distribuidora e baixá-lo.
            </p>
            <p>Clique no nome da sua distribuidora para acessar:</p>
          </div>

          <DistributorOptions>
            <a
              href='https://servicosonline.cpfl.com.br/agencia-webapp/#/login'
              target='_blank'
              rel='noreferrer'
            >
              <SecondaryButton>CPFL</SecondaryButton>
            </a>

            <a
              href='https://agenciavirtual.light.com.br/portal/'
              target='_blank'
              rel='noreferrer'
            >
              <SecondaryButton>Light</SecondaryButton>
            </a>

            <a
              href='https://atende.cemig.com.br/Login'
              target='_blank'
              rel='noreferrer'
            >
              <SecondaryButton>CEMIG</SecondaryButton>
            </a>
          </DistributorOptions>

          <ButtonContainer>
            <PrimaryButton onClick={() => onRequestClose()}>
              Fechar
            </PrimaryButton>
          </ButtonContainer>
        </Body>
      </Container>
    </NewModal>
  );
};

WhereToFindYourAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

import React from 'react';
import { MdOutlineCardGiftcard, MdOutlineCheckCircle } from 'react-icons/md';
import { PrimaryButton } from '../components/PrimaryButton';
import { PrimaryButtonRed } from '../components/PrimaryButtonRed';
import { PaidStatus } from '../pages/Customer/Property/styles';
import { setIsOverdue } from './invoices';
import { Tooltip } from '@mui/material';

export const getInvoiceStatusButton = (invoice = {}, label) => {
  try {
    if (!invoice || !Object.keys(invoice ?? {})?.length)
      return <PrimaryButton disabled={true}>Pagar</PrimaryButton>;
    const { status, gift, isOverdue: definedIsOverdue } = invoice;

    const isOverdue = definedIsOverdue || setIsOverdue(invoice);

    if (gift)
      return (
        <PaidStatus
          isGift
          isNewMyLocation={label === 'isNewMyLocations'}
        >
          <MdOutlineCardGiftcard />
          Cortesia
        </PaidStatus>
      );
    else {
      switch (status) {
        case 'paid':
          return (
            <PaidStatus>
              <MdOutlineCheckCircle />
              Pago
            </PaidStatus>
          );
        default:
          return (
            <Tooltip
              title={invoice?.secure_url ? '' : 'Fatura Flora não disponível'}
              arrow
            >
              <a
                href={invoice?.secure_url}
                target='_blank'
                rel='noreferrer'
              >
                {isOverdue ? (
                  <PrimaryButtonRed>Pagar</PrimaryButtonRed>
                ) : (
                  <PrimaryButton>Pagar</PrimaryButton>
                )}
              </a>
            </Tooltip>
          );
      }
    }
  } catch (error) {
    console.log('file: index.js || line 413 || error', error);
  }
};

import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { FiLoader } from 'react-icons/fi';
import { colors } from '../../styles/colors';
import { ThemeContext } from '../../contexts/themeContext';
// import { CircularProgress } from '@mui/material';

const BaseLoader = ({ className, fontSize = 70 }) => {
  const { themeValue } = useContext(ThemeContext);
  return (
    <div className={className}>
      {/* <CircularProgress
        style={{ color: 'inherit' || themeValue?.primary_color || '#61787b' }}
      /> */}
      <FiLoader
        className='loader-icon'
        fontSize={fontSize}
        color={themeValue?.primary_color || colors.orange}
      />
    </div>
  );
};

BaseLoader.propTypes = {
  className: PropTypes.string.isRequired,
  fontSize: PropTypes.number,
};

const Loader = styled(BaseLoader)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-icon {
    animation-name: loadAnimation;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }

  @keyframes loadAnimation {
    from {
      opacity: 1;
      transform: rotateZ(0deg);
    }
    to {
      opacity: 0.2;
      transform: rotateZ(360deg);
    }
  }
`;

export default Loader;

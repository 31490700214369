/* eslint-disable react/jsx-no-comment-textnodes */
import { CircularProgress } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
import { colors } from '../../../styles/colors';
import { FormPropTypes, FormDefaultProps } from '../types';
import GradientButton from '../../GradientButton';
import PromocodesService from '../../../services/administrator/promocodes.service';
import { onlyNumbers } from '../../../helpers/form/formatter';

export const EditForm = ({
  onSubmit,
  initialValues,
  handleClose,
  promocode,
  loadingg,
  setLoadingg,
  setReload,
  reload,
}) => {
  const [providerId, setProviderId] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contributors, setContributors] = useState(
    promocode?.contributors || ''
  );
  const [formData, setFormData] = useState({
    contributors: 0,
    active_at: '2021-01-01',
  });
  const [isDateInvalid, setIsDateInvalid] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'contributors') {
      setContributors(onlyNumbers(value));
    }

    if (name === 'active_at') {
      const minimumValidDate = new Date('2020-01-01');
      const maximumValidDate = new Date('2030-12-31');

      if (
        new Date(value) < minimumValidDate ||
        new Date(value) > maximumValidDate ||
        value.length === 0
      ) {
        setIsDateInvalid(true);
      } else {
        setIsDateInvalid(false);
      }
    }

    setFormData((oldState) => ({
      ...oldState,
      [name]: value,
    }));
  };

  const handleDelete = async () => {
    const { id } = promocode;
    setLoadingg(true);
    try {
      await PromocodesService.deleteOne({ id });
      setLoadingg(false);
      toast.success('Promocode deletado com sucesso');
    } catch (error) {
      setLoadingg(false);
      toast.success('Houve um erro desconhecido');
      console.log(error);
    } finally {
      handleClose();
      setReload(!reload);
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    onSubmit(formData, { isSubmitting });
    setIsSubmitting(false);
  };
  initialValues = {};

  useEffect(() => {
    if (promocode) {
      setFormData(promocode);
    } else {
      setFormData({});
    }
  }, [promocode]);

  return (
    <form
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <UpperDiv
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <p style={{ fontSize: '40px' }}>
          {promocode ? 'Alterar Promocode' : 'Editar Promocode'}
        </p>
        <ClearIcon style={{ color: 'red' }} onClick={handleClose} id='clear' />
      </UpperDiv>
      <MainContainer>
        <ColumnContainer>
          <TextField
            variant='outlined'
            label='Contribuidores: '
            value={contributors}
            placeholder='Contribuidores: '
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            name='contributors'
            defaultValue={contributors || formData.contributors}
          />
        </ColumnContainer>

        <ColumnContainer>
          <TextField
            id='active_at'
            name='active_at'
            type='date'
            defaultValue={
              promocode
                ? promocode.active_at.split('T')?.[0]
                : formData.active_at
            }
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: '2020-01-01',
              max: '2030-12-31',
            }}
            style={{ marginTop: '1rem' }}
            variant='outlined'
            label='Data Ativo:'
          />
          {isDateInvalid && (
            <p style={{ color: '#446063', marginLeft: '0.1rem' }}>
              Ano inválido
            </p>
          )}
        </ColumnContainer>
      </MainContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <GradientButton
          handleClick={handleSubmit}
          disabled={isSubmitting || isDateInvalid}
          text=''
          fontSize='50'
          borderRadius='6'
          style={{
            width: 'max-content',
          }}
          type='submit'
          loading={isSubmitting}
        >
          {loadingg ? (
            <CircularProgress style={{ color: 'white' }} />
          ) : (
            <>{promocode ? 'Alterar' : 'Cadastrar'}</>
          )}
        </GradientButton>
        {promocode && (
          <GradientButton
            handleClick={handleDelete}
            disabled={isSubmitting}
            text=''
            fontSize='50'
            borderRadius='6'
            style={{
              width: 'max-content',
            }}
            type='submit'
            loading={isSubmitting}
            background='red'
          >
            {loadingg ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : (
              <>Deletar</>
            )}
          </GradientButton>
        )}
      </div>
    </form>
  );
};

EditForm.propTypes = FormPropTypes;
EditForm.defaultProps = FormDefaultProps;

const PromocodeEditForm = styled(EditForm)`
  position: relative;
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : 'Myriad-Pro-Condensed'};
  letter-spacing: 0.085em;
  background: ${colors.white};
  outline: none;
  border-radius: 2px;
  font-size: 16px;
  padding: 5px 10px;
  color: ${colors.primary};
  font-weight: 300;
  width: ${(props) => (props.fullwidth ? '100%' : 'auto')};
  &:disabled {
    border: none;
    padding: 0;
    color: ${colors.primary};
  }
`;
export default PromocodeEditForm;

const MainContainer = styled.section`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
`;

const UpperDiv = styled.div`
  #clear {
    :hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`;

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import PageView from '../../../../containers/PageView';

import SearchableTable from '../../../../components/Interface/SearchableTable';
import { StyledLink } from '../../../../components/Interface';
import { Paragraph } from '../../../../components/Interface/Text/Paragraph';

import ContentCard from '../../../../components/ContentCard';

import LoadMore from '../../../../components/LoadMore';
import AllInvoicesService from '../../../../services/administrator/ManagementBases/allInvoicesBase.service';
import theme from '../../../../theme';

const COLUMNS = [
  { name: 'Contrato' },
  { name: 'Fatura' },
  { name: 'Código do cliente' },
  { name: 'Código da Fatura' },
  { name: 'Ano' },
  { name: 'Mês' },
  { name: 'Gerador' },
  { name: 'Link' },
  { name: 'Link do boleto em PDF' },
  { name: 'PIX da Fatura Flora' },
];

const getRows = (contracts) => {
  return contracts.map((contract) => {
    const {
      id,
      invoice_id,
      invoiceLink,
      link,
      iuguManagementCode,
      customerId,
      customerLink,
      year,
      month,
      producer,
      bank_slip_url,
      pix_qr_code
    } = contract;
    return {
      values: [
        {
          value: (
            <StyledLink
              tag='true'
              to={`/contratos/${id}`}
            >{`${id}`}</StyledLink>
          ),
        },
        {
          value: <Paragraph>{invoice_id}</Paragraph>,
        },
        {
          value: customerLink ? (
            <Linkk
              target='_blank'
              href={customerLink || ''}
            >
              <span hidden={!customerLink}>#</span>
              {customerId}
            </Linkk>
          ) : (
            <Paragraph>{customerId}</Paragraph>
          ),
        },
        {
          value: invoiceLink ? (
            <Linkk
              target='_blank'
              href={invoiceLink || ''}
            >
              <span hidden={!invoiceLink}>#</span>
              {iuguManagementCode}
            </Linkk>
          ) : (
            <Paragraph>{customerId}</Paragraph>
          ),
        },
        {
          value: <Paragraph>{year}</Paragraph>,
        },
        {
          value: <Paragraph>{month}</Paragraph>,
        },
        {
          value: <Paragraph>{producer}</Paragraph>,
        },
        {
          value: link ? (
            <Linkk
              target='_blank'
              href={link || ''}
            >
              <span hidden={!link}></span>
              {link}
            </Linkk>
          ) : (
            <Paragraph>{'Indisponível'}</Paragraph>
          ),
        },
        {
          value: bank_slip_url ? (
            <Linkk
              target='_blank'
              href={bank_slip_url || ''}
            >
              <span hidden={!bank_slip_url}></span>
              {bank_slip_url}
            </Linkk>
          ) : (
            <Paragraph>{'Indisponível'}</Paragraph>
          ),
        },
        {
          value: pix_qr_code ? (
            <Linkk
              target='_blank'
              href={pix_qr_code || ''}
            >
              <span hidden={!pix_qr_code}></span>
              {pix_qr_code}
            </Linkk>
          ) : (
            <Paragraph>{'Indisponível'}</Paragraph>
          ),
        },
      ],
    };
  });
};

const AllInvoices = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    allInvoices: [],
  });
  const [page, setPage] = useState(0);
  const [loadingg, setLoadingg] = useState(false);
  const [noMore, setNoMore] = useState(false);

  useEffect(() => {
    const fetchAllInvoices = async () => {
      try {
        setLoadingg(true);
        const resources = await AllInvoicesService.getAll(page);

        if ((resources?.length <= 0 || !resources) && page > 0) {
          setNoMore(true);
          return;
        } else if (page === 0 && resources?.length < 100) {
          setNoMore(true);
        } else {
          setNoMore(false);
        }

        setPageState((state) => ({
          ...state,
          loading: false,
          allInvoices: [...state.allInvoices, ...resources],
        }));
        setLoadingg(false);
      } catch (err) {
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };

    fetchAllInvoices();
  }, [page]);

  const fetchAll = async () => {
    const rows = [],
      limit = 5000;

    let page = 0,
      data = [];
    do {
      data = await AllInvoicesService.getAll(page, limit);
      if (data.length > 0) rows.push(...data);
      page += 1;
    } while (data.length === limit); //Se retornou menos que o limit, é que não terá dados em uma proxima requisição
    return getRows(rows);
  };

  const { loading, hasError, allInvoices } = pageState;

  return (
    <PageView
      title='Todas Faturas'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <ContentCard>
            <SearchableTable
              columns={COLUMNS}
              rowsValues={getRows(allInvoices)}
              noItemsText='Nenhuma Fatura'
              fetchAll={fetchAll}
            />
          </ContentCard>
          {!noMore && (
            <LoadMore
              loadingg={loadingg}
              setPage={setPage}
            />
          )}
        </Grid>
      </Grid>
    </PageView>
  );
};

export default AllInvoices;

const Linkk = styled.a`
  color: ${theme.palette.primary.main};
  > span {
    user-select: none;
  }
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const INVOICE_STATUS_TYPES_PTBR = {
  pending: 'Pendente',
  paid: 'Paga',
  canceled: 'Cancelada',
  draft: 'Rascunho',
  partially_paid: 'Parcialmente Paga',
  refunded: 'Reembolsada',
  expired: 'Vencida',
  in_protest: 'Em Protesto',
  chargeback: 'Contestada',
  in_analysis: 'Em Análise',
  unknown: 'Não foi possível obter o status da fatura',
  gift: 'Cortesia',
};

import { maskPhone } from '../../../helpers/form/masks';
import { unMask } from '../../../helpers/form/unMask';
import {
  validationEmail,
  validationPhone,
} from '../../../helpers/form/validations';
import { randomNumber } from '../../../helpers/numbers';
import { asyncTimeout } from '../../../helpers/timers';
import AuthService from '../../../services/authentication/auth.service';
import ProvidersService from '../../../services/general/providers.service';
import { checkProgress } from '../../../services/general/registerResume';
import { decode, setUser } from '../../../store';

export const signerOptionsRadioBox = [
  { label: 'E-mail', value: 'email' },
  { label: 'SMS', value: 'sms' },
  { label: 'Whatsapp', value: 'whatsapp' },
];

export const handleResume = async (selectedContract, isNewPropertyOrHolder) => {
  try {
    if (!localStorage.getItem('floraenergia:auth')) return;
    const { id, stage } = selectedContract;
    const data = await checkProgress(id, !isNewPropertyOrHolder);
    if (stage) Object.assign(data, { stage });

    return data;
  } catch (err) {
    console.log('file: index.js || line 32 || error', err);
    throw err;
  }
};

export const loadCoreData = async (errorCount = 0) => {
  try {
    const coreData = await ProvidersService.get();
    return coreData;
  } catch (error) {
    if (errorCount === 100) return;
    const milliseccondsToWait = randomNumber(
      100 + (errorCount + 1),
      100 + (errorCount + 1) * 2
    );
    setTimeout(() => loadCoreData(errorCount + 1), milliseccondsToWait);
  }
};

const sendLogin = async (email, errorCount = 0) => {
  try {
    if (!email) throw { message: 'no email' };
    const { token2 } = await AuthService.login(email);
    await setUser();
    const { role } = await decode(token2);

    if (role === 'set_password') return { success: true };
    if (errorCount >= 50)
      return setTimeout(
        async () => sendLogin(email, (errorCount += 1)),
        errorCount * 500
      );
    throw { role };
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const handlePostSignature = async (isNewPropertyOrHolder, navigate, widget) => {
  try {
    if (!isNewPropertyOrHolder) {
      if (widget) widget.unmount();
      navigate('/definir-senha');
      return;
    }

    await asyncTimeout(5000);
    navigate('/home');
    if (widget) widget.unmount();
  } catch (error) {
    console.log('file: index.js || line 87 || error', error);
  }
};

export const handleSigned = async (
  email,
  navigate,
  widget,
  isNewPropertyOrHolder,
  resetStagePayload,
  loginErrorCount = 0
) => {
  try {
    if (!isNewPropertyOrHolder) await sendLogin(email, navigate);

    await handlePostSignature(isNewPropertyOrHolder, navigate, widget);
  } catch (error) {
    if (loginErrorCount >= 5) throw error;
    setTimeout(
      async () =>
        handleSigned(
          email,
          navigate,
          widget,
          isNewPropertyOrHolder,
          resetStagePayload,
          (loginErrorCount += 1)
        ),
      loginErrorCount * 1.5 * 10000
    );
  } finally {
    resetStagePayload();
  }
};

export const typeClient = (type, documentValue) => {
  if (type === 'email') {
    if (documentValue?.length === 11) return 'E-mail do titular';

    if (documentValue?.length === 14) return 'E-mail do representante';

    return 'Email';
  }

  if (type === 'phone_number' || type === 'sms' || type === 'whatsapp') {
    if (documentValue?.length === 11) return 'Celular do titular';

    if (documentValue?.length === 14) return 'Celular do representante';

    return 'Celular';
  }
};

export const autoCompleteSigner = (email, phoneNumber, type) => {
  if (type === 'email') return email;

  return phoneNumber;
};

const getSignerAndValidateHolderToken = (value, signerType) => {
  try {
    const type = signerType;
    const signerPayload = { type };
    let status = 'accepted';
    let valueToShow = value || '';

    switch (type) {
      case 'email':
        signerPayload.email = value;
        if (!validationEmail(value)) status = 'refused';
        break;
      case 'sms':
      case 'whatsapp':
        valueToShow = maskPhone(value);
        signerPayload.phone_number = valueToShow;
        if (!validationPhone(value)) status = 'refused';
        break;
      default:
        throw { message: 'invalid option' };
    }

    // const holder_token = { value: valueToShow, status, touched: true };

    signerPayload.status = status;
    signerPayload.touched = true;

    return { signer: signerPayload };
  } catch (err) {
    console.log('file: index.js || line 190 || error', err);
  }
};

const preHandleRadioBox = (useStageObject, event) => {
  try {
    const {
      target: { value },
    } = event;

    const { stagePayload, changePayload } = useStageObject;

    const phoneOptions = ['sms', 'whatsapp'];

    let valueToCheck;

    if (value === 'email') valueToCheck = 'email';
    else if (phoneOptions.includes(value)) valueToCheck = 'phone_number';

    const existingValue = stagePayload[valueToCheck]?.value;

    const restOfData = getSignerAndValidateHolderToken(existingValue, value);

    changePayload({ ...restOfData });
  } catch (err) {
    console.log('file: helpers.js || line 119 || error', err);
  }
};

export const curlyHandleRadioBox = (useStageObject) => (event) => {
  preHandleRadioBox(useStageObject, event);
};

const preHandleReceiveToken = (useStageObject, event) => {
  try {
    const {
      target: { value },
    } = event;

    const { stagePayload, changePayload } = useStageObject;

    const { signer } = stagePayload;
    const validatedData = getSignerAndValidateHolderToken(value, signer?.type);
    changePayload({
      ...validatedData,
    });
  } catch (err) {
    console.log('file: index.js || line 207 || error', err);
  }
};

export const curlyHandleReceiveToken = (useStageObject) => (event) =>
  preHandleReceiveToken(useStageObject, event);

const preBlurHolderToken = (useStageObject) => {
  const { stagePayload, changePayload } = useStageObject;

  const { holder_token } = stagePayload;
  if (!holder_token.value.length) {
    changePayload({
      holder_token: { ...holder_token, touched: true, status: 'refused' },
    });

    return;
  }

  changePayload({
    holder_token: { ...holder_token, touched: true },
  });
};

export const curlyBlurHolderToken = (useStageObject) => () =>
  preBlurHolderToken(useStageObject);

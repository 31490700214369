import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const MultiSelect = ({
  options,
  optionsSelected,
  updateData,
  isDisabled = false,
  isError,
  setIsError,
}) => {
  const [multiValues, setMultiValues] = useState(optionsSelected);

  const handleMultiChange = (values) => {
    setMultiValues(values);
    updateData(values);
  };

  useEffect(() => {
    if (isError?.location_classification_profiles === true) {
      setMultiValues(optionsSelected);
      setIsError((prevState) => ({
        ...prevState,
        location_classification_profiles: false,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError?.location_classification_profiles]);

  return (
    <Select
      value={multiValues}
      options={options}
      onChange={handleMultiChange}
      isMulti
      name='classificationProfile'
      isDisabled={isDisabled}
      className='basic-multi-select'
      classNamePrefix='select'
      isClearable={false}
    />
  );
};

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  optionsSelected: PropTypes.array.isRequired,
  updateData: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isError: PropTypes.object.isRequired,
  setIsError: PropTypes.func.isRequired,
};

export default MultiSelect;

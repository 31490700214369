import React from 'react';

import { onlyString } from '../../helpers/form/formatter';
import { validationData } from '../../helpers/form/validations';
import { STATE_LIST } from '../../helpers/states';
import { useStage } from '../../hooks/useStage';
import { InputCustom } from '../InputCustom';
import { Fields } from './styles';
import { AddressNumber } from '../PostalCodeWithoutAddressAndNeighborhood/addressNumber';

export const PostalCodeIsCorrectlyChecked = () => {
  const { stagePayload, changePayload } = useStage();
  const { city, state, street, neighborhood, number, complement } =
    stagePayload;

  const handleCity = (event) => {
    event.persist();
    let status = 'accepted';
    const isCityValid = validationData(onlyString(event.target.value));

    if (!isCityValid) {
      status = 'refused';
    }

    changePayload({
      city: { ...city, value: onlyString(event.target.value), status },
    });
  };

  const blurCity = () => {
    const newCity = { ...city, touched: true };
    if (!stagePayload.city.value.length) newCity.status = 'refused';

    changePayload({ city: newCity });
  };

  const handleState = (event) => {
    event.persist();
    const value = event.target?.value?.toUpperCase();
    const arrUF = STATE_LIST.map((UF) => UF.sigla);
    const isStateValid = arrUF.includes(value);
    let status = 'accepted';

    if (!isStateValid) {
      status = 'refused';
    }

    changePayload({
      state: {
        ...state,
        value: onlyString(value),
        status,
      },
    });
  };

  const blurState = () => {
    const newState = { ...state, touched: true };

    if (!stagePayload.state.value.length) newState.status = 'refused';

    changePayload({ state: newState });
  };

  const handleStreet = (event) => {
    event.persist();
    let status = 'accepted';
    const isStreetValid = validationData(onlyString(event.target.value));

    if (!isStreetValid) {
      status = 'refused';
    }

    changePayload({
      street: { ...street, value: onlyString(event.target.value), status },
    });
  };

  const blurStreet = () => {
    const newStreet = { ...street, touched: true };
    if (!stagePayload.street.value.length) newStreet.status = 'refused';

    changePayload({ street: newStreet });
  };

  const handleNeighborhood = (event) => {
    event.persist();
    let status = 'accepted';
    const isNeighborhoodValid = validationData(onlyString(event.target.value));

    if (!isNeighborhoodValid) status = 'refused';

    changePayload({
      neighborhood: {
        ...neighborhood,
        value: onlyString(event.target.value),
        status,
      },
    });
  };

  const blurNeighborhood = () => {
    const newNeighborhood = { ...neighborhood, touched: true };

    if (!stagePayload.neighborhood.value.length)
      newNeighborhood.status = 'refused';

    changePayload({ neighborhood: newNeighborhood });
  };

  const handleComplement = (event) => {
    event.persist();
    let status = 'accepted';
    const isComplementValid = validationData(event.target.value);

    if (!isComplementValid) status = 'refused';

    changePayload({
      complement: { ...complement, value: event.target.value, status },
    });
  };

  const blurComplement = () =>
    changePayload({ complement: { ...complement, touched: true } });

  return (
    <Fields>
      <InputCustom
        type='text'
        name='city-isCorrectly'
        labelText='* Cidade'
        value={city?.value}
        onChange={handleCity}
        onBlur={blurCity}
        hasError={city?.status === 'refused'}
        hasTouched={city?.touched}
        requiredMessage='Cidade é obrigatório'
      />

      <InputCustom
        type='text'
        name='state-isCorrectly'
        labelText='* Estado'
        value={state?.value}
        onChange={handleState}
        onBlur={blurState}
        hasError={state?.status === 'refused'}
        hasTouched={state?.touched}
        requiredMessage='Estado é obrigatório'
        errorMessage='Estado inválido'
        maxLength={2}
      />

      <InputCustom
        type='text'
        name='address-isCorrectly'
        labelText='* Endereço'
        value={street?.value}
        onChange={handleStreet}
        onBlur={blurStreet}
        hasError={street?.status === 'refused'}
        hasTouched={street?.touched}
        requiredMessage='Endereço é obrigatório'
      />

      <InputCustom
        type='text'
        name='neighborhood-isCorrectly'
        labelText='* Bairro'
        value={neighborhood?.value}
        onChange={handleNeighborhood}
        onBlur={blurNeighborhood}
        hasError={neighborhood?.status === 'refused'}
        hasTouched={neighborhood?.touched}
        requiredMessage='Bairro é obrigatório'
      />

      <AddressNumber
        changePayload={changePayload}
        number={number}
      />

      <InputCustom
        type='text'
        name='complement-isCorrectly'
        labelText='Complemento'
        value={complement?.value}
        onChange={handleComplement}
        onBlur={blurComplement}
        hasTouched={complement?.touched}
      />
    </Fields>
  );
};

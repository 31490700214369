import connection from './connections/main';

const updateBulk = async (payload) => {
  const { err, data } = await connection.put(`/contracts/bulk`, payload);

  if (err) {
    throw err;
  }

  return data;
};

const MassiveDiscountService = {
  updateBulk,
};

export default MassiveDiscountService;

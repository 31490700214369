import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';
import { Form } from 'formik';
import styled from 'styled-components';
import GenericForm from '../GenericForm';
import { colors } from '../../../styles/colors';
import Button from '../../Button';
import { InputText, ProspectsFormControl } from '../../Interface';
import { PromocodeFormSchema } from './schema';
import { FormPropTypes, FormDefaultProps } from '../types';
import { Heading2 } from '../../Interface/Text';
import { Label } from '../../Interface/Form';
import theme from '../../../theme';

export const BaseWaitlistForm = ({
  className,
  onSubmit,
  onClose,
  values,
  initialValues,
}) => {
  initialValues = {};
  return (
    <div className={className}>
      <GenericForm
        enableReinitialize
        initialValues={initialValues}
        values={values}
        schema={PromocodeFormSchema}
        onSubmit={onSubmit}
        validateOnChange
        validateOnBlur={false}
      >
        {({
          handleSubmit,
          resetForm,
          errors,
          touched,
          isSubmitting,
          handleChange,
        }) => {
          return (
            <Form
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            >
              <Grid
                container
                spacing={2}
                direction='column'
                justifyContent='center'
                alignItems='center'
              >
                <Grid
                  item
                  xs={12}
                >
                  <Heading2 align='center'>
                    Adicionar Código Promocional
                  </Heading2>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Grid
                    container
                    spacing={2}
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Grid item>
                      <ProspectsFormControl
                        error={errors.code}
                        touched={touched.code}
                      >
                        <InputText
                          label='Código Promocional:*'
                          name='code'
                          placeholder=''
                          // fullwidth
                          onChange={handleChange}
                        />
                      </ProspectsFormControl>
                      <ProspectsFormControl
                        error={errors.contributors}
                        touched={touched.contributors}
                      >
                        <InputText
                          label='Número de colaboradores:*'
                          name='contributors'
                          placeholder=''
                          // fullwidth
                          onChange={handleChange}
                        />
                      </ProspectsFormControl>
                    </Grid>
                    <Grid item>
                      <ProspectsFormControl
                        error={errors.active_at}
                        touched={touched.active_at}
                      >
                        <Label htmlFor='active_at'>Ativo a partir de:</Label>
                        <TextField
                          id='active_at'
                          type='date'
                          size='big'
                          onChange={handleChange}
                          inputProps={{
                            min: '2020-01-01',
                            max: '2030-12-31',
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </ProspectsFormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                direction='row'
                justifyContent='space-around'
                style={{ margin: '10px 0' }}
              >
                <Button
                  text='Cancelar'
                  handleClick={onClose}
                  color='white'
                  borderColor={theme.palette.primary.main}
                  outlined
                />
                <Button
                  className='onlyDesktop'
                  onSubmit={() => resetForm}
                  handleClick={handleSubmit}
                  disabled={isSubmitting}
                  textColor={theme.palette.primary.main}
                  color={theme.palette.secondary.main}
                  text='Cadastrar'
                  fontSize='50'
                  borderRadius='12'
                  style={{
                    width: 'max-content',
                  }}
                  type='submit'
                  loading={isSubmitting}
                />
              </Grid>
            </Form>
          );
        }}
      </GenericForm>
    </div>
  );
};

BaseWaitlistForm.propTypes = FormPropTypes;
BaseWaitlistForm.defaultProps = FormDefaultProps;

const ProspectForm = styled(BaseWaitlistForm)`
  position: relative;
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : 'Myriad-Pro-Condensed'};
  letter-spacing: 0.085em;
  background: ${colors.white};
  outline: none;
  border-radius: 2px;
  font-size: 16px;
  padding: 5px 10px;
  color: ${colors.primary};
  font-weight: 300;
  width: ${(props) => (props.fullwidth ? '100%' : 'auto')};

  &:disabled {
    border: none;
    padding: 0;
    color: ${colors.primary};
  }
`;
export default ProspectForm;

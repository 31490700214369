import connection from './connections/main';

const mountFiltersQuery = (filters) => {
  const mappedFilters = {};

  if (filters) {
    const { userId } = filters;

    if (userId) {
      mappedFilters.users_id_eq = userId;
    }

    return `?q=${JSON.stringify(mappedFilters)}`;
  }

  return '';
};

const getAll = async (filters = {}) => {
  // const query = mountFiltersQuery(filters);
  // const { err, data } = await connection.get(`/producers${query}`);
  const { err, data } = await connection.get(`/producers`);

  if (err) {
    throw err;
  }

  return data;
};

const get = async (id) => {
  const query = mountFiltersQuery({});
  const { err, data } = await connection.get(`/producers/${id}${query}`);

  if (err) {
    throw err;
  }

  return data;
};

const create = async (payload) => {
  const { err, data } = await connection.post(`/producers`, {
    producer: payload,
  });

  if (err) {
    throw err;
  }

  return data;
};

const update = async (id, payload) => {
  if (payload.profile !== 'target') {
    payload.provider = null;
  }
  const { err, data } = await connection.patch(`/producers/${id}`, payload);

  if (err) {
    throw err;
  }

  return data;
};

const requestSignature = async (id) => {
  const { err, data } = await connection.post(`/producers/${id}/sign`);

  if (err) {
    throw err;
  }

  return data;
};

const activate = async (id) => {
  const { err, data } = await connection.post(`/producers/${id}/activate`);

  if (err) {
    throw err;
  }

  return data;
};

const suspend = async (id) => {
  const { err, data } = await connection.post(`/producers/${id}/suspend`);

  if (err) {
    throw err;
  }

  return data;
};

const updateOne = async (payload) => {
  const { err, data } = await connection.patch(`/producers/updateOne`, payload);

  if (err) {
    throw err;
  }

  return data;
};

const deleteOne = async (payload) => {
  const { err, data } = await connection.post(`/producers/delete`, payload);

  if (err) {
    throw err;
  }

  return data;
};

const ProducersService = {
  getAll,
  get,
  create,
  update,
  requestSignature,
  activate,
  suspend,
  updateOne,
  deleteOne,
};

export default ProducersService;

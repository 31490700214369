/* eslint-disable react/jsx-no-comment-textnodes */
import { CircularProgress, Grid } from '@mui/material';
// import { makeStyles } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { Form, Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import ClearIcon from '@mui/icons-material/Clear';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import GenericForm from '../GenericForm';
import { colors } from '../../../styles/colors';
import { formMasks } from '../../../helpers/form/masks';
import Button from '../../Button';
import {
  FormControl as FC,
  InputCheckbox,
  InputText,
  ProspectsFormControl,
} from '../../Interface';
// import PrivacyPolicyModal from '../../PrivacyPolicyModal';
import { FormPropTypes, FormDefaultProps } from '../types';
import ContentCard from '../../ContentCard';
import { Paragraph } from '../../Interface/Text';
import {
  CustomErrorMessage,
  CustomField,
  FieldWrapper,
  Label,
} from '../../Interface/Form';
import {
  PROFILE_TYPES,
  PROFILE_TYPES_LABEL,
} from '../../../helpers/profileTypes';
import { TAG_TYPES, TAG_TYPES_LABEL } from '../../../helpers/tagTypes';
import GradientButton from '../../GradientButton';
import { isEmpty } from '../../../utils';
import CompaniesService from '../../../services/general/companies.service';
import { useAuthStore } from '../../../store';

export const BaseWaitlistForm = ({
  onSubmit,
  initialValues,
  handleClose,
  companyToEdit,
  loadingg,
  setLoadingg,
  setReload,
  reload,
}) => {
  const userId = useAuthStore((state) => state.userAuth?.id); 

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    mock_name: '',
    document: '',
    address: '',
    phone_number: '',
    email: '',
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleDelete = async () => {
    const { id } = companyToEdit;
    setLoadingg(true);
    try {
      await CompaniesService.deleteOne({ companyId: id, userId });
      setLoadingg(false);
    } catch (error) {
      setLoadingg(false);
      console.log(error);
    } finally {
      handleClose();
      setReload(!reload);
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    onSubmit(formData, { isSubmitting });
    setIsSubmitting(false);
  };
  initialValues = {};

  useEffect(() => {
    if (companyToEdit) {
      setFormData(companyToEdit);
    } else {
      setFormData({
        name: '',
        mock_name: '',
        document: '',
        address: '',
        phone_number: '',
        email: '',
      });
    }
  }, [companyToEdit]);

  return (
    <form
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <UpperDiv
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <p style={{ fontSize: '40px' }}>
          {companyToEdit ? 'Alterar Empresa' : 'Cadastrar Empresa'}
        </p>
        <ClearIcon style={{ color: 'red' }} onClick={handleClose} id='clear' />
      </UpperDiv>
      <MainContainer>
        <ColumnContainer>
          <TextField
            variant='outlined'
            label='Nome'
            placeholder='Nome'
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            name='name'
            defaultValue={companyToEdit ? companyToEdit.name : formData.name}
          />

          <TextField
            variant='outlined'
            label='Razão Social'
            placeholder='Razão Social'
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            name='mock_name'
            type='text'
            defaultValue={
              companyToEdit ? companyToEdit.mock_name : formData.mock_name
            }
          />

          <TextField
            variant='outlined'
            label='CNPJ'
            placeholder='CNPJ'
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            name='document'
            type='text'
            defaultValue={
              companyToEdit ? companyToEdit.document : formData.document
            }
          />
        </ColumnContainer>
        <ColumnContainer>
          <TextField
            variant='outlined'
            label='Endereço'
            placeholder='Endereço'
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            name='address'
            type='text'
            defaultValue={
              companyToEdit ? companyToEdit.address : formData.address
            }
          />

          <TextField
            variant='outlined'
            label='Telefone'
            placeholder='Telefone'
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            name='phone_number'
            type='text'
            defaultValue={
              companyToEdit ? companyToEdit.phone_number : formData.phone_number
            }
          />

          <TextField
            variant='outlined'
            label='E-mail"'
            placeholder='E-mail'
            onChange={handleChange}
            style={{ marginTop: '1rem' }}
            name='email'
            type='text'
            defaultValue={companyToEdit ? companyToEdit.email : formData.email}
          />
        </ColumnContainer>
      </MainContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <GradientButton
          handleClick={handleSubmit}
          disabled={isSubmitting}
          text=''
          fontSize='50'
          borderRadius='6'
          style={{
            width: 'max-content',
          }}
          type='submit'
          loading={isSubmitting}
        >
          {loadingg ? (
            <CircularProgress style={{ color: 'white' }} />
          ) : (
            <>{companyToEdit ? 'Alterar' : 'Cadastrar'}</>
          )}
        </GradientButton>
      </div>
    </form>
  );
};

BaseWaitlistForm.propTypes = FormPropTypes;
BaseWaitlistForm.defaultProps = FormDefaultProps;

const CompaniesForm = styled(BaseWaitlistForm)`
  position: relative;
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : 'Myriad-Pro-Condensed'};
  letter-spacing: 0.085em;
  background: ${colors.white};
  outline: none;
  border-radius: 2px;
  font-size: 16px;
  padding: 5px 10px;
  color: ${colors.primary};
  font-weight: 300;
  width: ${(props) => (props.fullwidth ? '100%' : 'auto')};

  &:disabled {
    border: none;
    padding: 0;
    color: ${colors.primary};
  }
`;
export default CompaniesForm;

const MainContainer = styled.section`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
`;

const UpperDiv = styled.div`
  #clear {
    :hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`;

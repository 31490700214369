import styled from 'styled-components';
import theme from '../../theme';
import LogoBg from '../../assets/images/logo/logo-bg.svg';

export const ContInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  div + div {
    margin-top: 1rem;
  }
`;

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbfdff;

  @media (min-width: 768px) {
    background: ${theme.palette.gray.main};
    background-image: url(${LogoBg});
    background-repeat: no-repeat;
    background-size: 50vh;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 648px;
  padding: 0 3rem 3rem;
  background: #fff;

  @media (min-width: 768px) {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
      0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }

  > p {
    font-size: 1.125rem;
    font-weight: 300;
    text-align: center;

    &:nth-child(3) {
      margin: 1.5rem 0 0.25rem 0;
    }

    &:nth-child(4) {
      margin-bottom: 1.5rem;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 3rem;

  img {
    width: 11.625rem;
    height: 3.75rem;
  }
`;

export const Title = styled.h2`
  margin: 1.5rem 0 0 0;
  color: ${theme.palette.primary.main};
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ButtonContainer = styled.div`
  button {
    padding: 0.563rem 0;
  }
`;

/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-syntax */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { mask as MASK, unMask as UNMASK } from 'remask';

import { CircularProgress, Link } from '@mui/material';
import { InputCustom } from '../../InputCustom';
import { NewModal } from '../../NewModal';
import { PrimaryButton } from '../../PrimaryButton';

import LocationsService from '../../../services/general/locations.service';
import { Checkbox } from '../../Checkbox';
import { SecondaryButton } from '../../SecondaryButton';
import {
  ButtonsContainer,
  CloseButton,
  Container,
  Error,
  Fields,
  Form,
  Header,
  Info,
  TitleCheckbox,
} from './styles';

import {
  validationEmail,
  validationNumber,
} from '../../../helpers/form/validations';
import { validateCpfOrCnpj } from '../../../utils/validateCpfOrCnpj';
import { URLS_PROVIDERS } from '../../../helpers/providersURL';
import theme from '../../../theme';

const masks = {
  cpf: '999.999.999-99',
  cnpj: '99.999.999/9999-99',
};

export const DistributorUserDataModal = ({
  isOpen,
  onRequestClose,
  data = {},
  hasCredentials,
  removeCredentials,
  additionCredentials,
  location_id = null,
  provider = '',
}) => {
  const initialState = {
    login: { value: '', status: 'awaiting', touched: false },
    password: { value: '', status: 'awaiting', touched: false },
    acceptTerms: false,
    id: null,
  };
  const linkProvider = URLS_PROVIDERS[provider];
  const [payload, setPayload] = useState(initialState);

  useEffect(() => {
    if (!data || Object.values(data)?.length === 0) return;
    const finalPayload = { acceptTerms: !!data?.id };

    for (const key in data) {
      finalPayload[key] = { ...initialState[key] };
      if (key === 'id') finalPayload[key] = data[key];
      else finalPayload[key].value = data[key];
    }
    setPayload(finalPayload);
  }, [data]);

  const [hasError, setHasError] = useState(false);
  const [showSpinner, setShowSpinner] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { login, password, acceptTerms, id } = payload;

  const resetState = () => {
    setPayload(initialState);
  };

  const handleRemoveData = () => {
    resetState();
    removeCredentials();
  };

  function validationTypeLogin() {
    if (
      validationNumber(UNMASK(login.value)) &&
      (UNMASK(login?.value).length === 11 || UNMASK(login?.value).length === 14)
    ) {
      return UNMASK(login?.value);
    }

    return login.value;
  }

  const handleDistributorUserData = async ({ target: { value } }) => {
    try {
      setButtonDisabled(true);

      const isSaveData = value !== 'removeData';
      isSaveData ? setShowSpinner('save') : setShowSpinner('remove');

      const payloadSubmitted = {
        login: isSaveData ? validationTypeLogin() : '',
        password: isSaveData ? password.value : '',
        id: !isSaveData ? id : '',
      };

      for (const key in payloadSubmitted)
        if (payloadSubmitted[key]?.length === 0) delete payloadSubmitted[key];

      const res = await LocationsService.createOrDeleteProviderLoginData(
        payloadSubmitted,
        value === 'removeData',
        location_id
      );

      setPayload((prevState) => ({
        ...prevState,
        id: res.id,
        login: { ...login, status: 'accepted', touched: true },
        password: { ...password, status: 'accepted', touched: true },
        oldLogin: login.value,
        oldPassword: password.value,
      }));

      if (isSaveData) additionCredentials(res);
      else handleRemoveData();

      localStorage.setItem('@FloraEnergia:closedImproveYourExperience', true);
      onRequestClose();
    } catch (err) {
      setHasError(true);
      setPayload((prevState) => ({
        ...prevState,
        login: { ...login, status: 'refused', touched: true },
        password: { ...password, status: 'refused', touched: true },
      }));
    } finally {
      setShowSpinner(null);
    }
  };

  const handleLogin = useCallback(
    (event) => {
      event.persist();

      const { value } = event.target;

      function updateState(isNumber = false) {
        setPayload((prevState) => ({
          ...prevState,
          login: {
            ...login,
            value: isNumber ? value.replace(/[.|\-\/]/g, '') : value,
            status: validationEmail(value) ? 'accepted' : 'refused',
          },
        }));
      }

      function addingMask(isCpf = false) {
        setPayload((prevState) => ({
          ...prevState,
          login: {
            ...login,
            value: isCpf ? MASK(value, masks.cpf) : MASK(value, masks.cnpj),
            status: validateCpfOrCnpj(UNMASK(value)) ? 'accepted' : 'refused',
          },
        }));
      }

      function maskingValidation() {
        switch (UNMASK(value)?.length) {
          case 11:
            addingMask(true);
            break;
          case 14:
            addingMask();
            break;
          default:
            updateState(true);
            break;
        }
      }

      if (validationNumber(UNMASK(value))) {
        return maskingValidation();
      }

      updateState();
    },
    [login]
  );

  const blurLogin = useCallback(() => {
    setPayload((prevState) => ({
      ...prevState,
      login: {
        ...login,
        touched: true,
      },
    }));
  }, [login]);

  const handlePassword = useCallback(
    (event) => {
      event.persist();

      const { value } = event.target;

      setPayload((prevState) => ({
        ...prevState,
        password: {
          ...password,
          value,
          status: value.length >= 4 ? 'accepted' : 'refused',
        },
      }));
    },
    [password]
  );

  const blurPassword = useCallback(() => {
    setPayload((prevState) => ({
      ...prevState,
      password: { ...password, touched: true },
    }));
  }, [password]);

  const handleAcceptTerms = useCallback((event) => {
    setPayload((prevState) => ({
      ...prevState,
      acceptTerms: event.target.checked,
    }));
  }, []);

  useEffect(() => {
    const credentialUnchanged =
      (data.login === login.value && data.password === password.value) ||
      (payload.oldLogin === login.value &&
        payload.oldPassword === password.value);

    const hasErrorInInput =
      login.status === 'refused' || password.status === 'refused';

    if (credentialUnchanged || hasErrorInInput || !acceptTerms) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [login, password, acceptTerms, data, payload]);

  return (
    <NewModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Container>
        <Header>
          <h2>Dados de login</h2>
          <CloseButton onClick={onRequestClose}>
            <MdClose
              size='1.5rem'
              color={theme.palette.primary.main}
            />
          </CloseButton>
        </Header>

        <Form>
          <Info>
            Informe abaixo seus dados de acesso da sua distribuidora.
            {linkProvider ? (
              <>
                {' '}
                Dúvidas?{' '}
                <Link
                  href={linkProvider}
                  target='_blank'
                  underline='always'
                  style={{ fontWeight: 600 }}
                >
                  Acesse sua distribuidora.
                </Link>
              </>
            ) : null}
          </Info>

          <Fields>
            <InputCustom
              type='text'
              name='login'
              labelText='Login'
              value={login?.value}
              onChange={handleLogin}
              onBlur={blurLogin}
              hasTouched={login?.touched}
              hasError={login?.status === 'refused'}
              errorMessage={!hasError && 'Login inválido'}
            />

            <div>
              <InputCustom
                type='password'
                name='password'
                labelText='Senha'
                value={password?.value}
                onChange={handlePassword}
                onBlur={blurPassword}
                hasTouched={password?.touched}
                hasError={password?.status === 'refused'}
                errorMessage={!hasError && 'Mínimo de 4 dígitos'}
              />

              <Checkbox
                checked={acceptTerms}
                onChange={handleAcceptTerms}
              >
                <TitleCheckbox>
                  Autorizo a Flora a usar os dados informados para acessar minha
                  fatura.
                </TitleCheckbox>
              </Checkbox>

              {hasError && (
                <Error>Falha ao tentar salvar, tente novamente.</Error>
              )}

              <ButtonsContainer>
                <PrimaryButton
                  onClick={handleDistributorUserData}
                  disabled={buttonDisabled}
                >
                  Salvar
                  {showSpinner === 'save' && (
                    <CircularProgress
                      style={{ color: 'white', height: 25, width: 25 }}
                    />
                  )}
                </PrimaryButton>

                {hasCredentials && (
                  <SecondaryButton
                    onClick={handleDistributorUserData}
                    value='removeData'
                    disabled={showSpinner === 'remove'}
                  >
                    Remover dados
                    {showSpinner === 'remove' && (
                      <CircularProgress
                        style={{ color: 'white', height: 25, width: 25 }}
                      />
                    )}
                  </SecondaryButton>
                )}
              </ButtonsContainer>
            </div>
          </Fields>
        </Form>
      </Container>
    </NewModal>
  );
};

DistributorUserDataModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  hasCredentials: PropTypes.bool.isRequired,
  removeCredentials: PropTypes.func.isRequired,
  additionCredentials: PropTypes.func.isRequired,
  location_id: PropTypes.number,
};

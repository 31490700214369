import Sentry from './init';

const setSentryScope = (responseObject) => {
  const { response, ...rest } = responseObject;
  const { connectionCallerName } = rest?.config?.metadata || {};
  Object.defineProperty(setSentryScope, 'name', {
    value: connectionCallerName,
  });

  Sentry.withScope((scope) => {
    // scope.setTransactionName(connectionCallerName);
    scope.setExtra('request', {
      url: response?.config.url || rest?.config?.url,
      method: response?.config.method || rest?.config?.method,
      data: response?.config.data || rest?.config?.data,
      headers:
        response?.config.headers.toJSON() || rest?.config?.headers.toJSON(),
    });
    scope.setExtra('response', {
      data: response?.data,
      status: response?.status,
      headers: response?.headers.toJSON(),
    });
    Sentry.captureException(response);
  });
};

export default setSentryScope;

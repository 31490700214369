/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { MdArrowBackIosNew, MdClose } from 'react-icons/md';
import { NewModal } from '../../NewModal';

import explicatedInvoiceCPFLImg from '../../../assets/images/explicatedInvoiceCPFL.png';
import explicatedInvoiceLightImg from '../../../assets/images/explicatedInvoiceLight.png';
import explicatedInvoiceCemigImg from '../../../assets/images/explicatedInvoiceCemig.png';
import explicatedInvoiceEnelRjImg from '../../../assets/images/explicatedInvoiceEnelRj.png';

import {
  Button,
  Container,
  DownloadButtonContainer,
  Header,
  ImageBigContainer,
  ImageButtonBig,
  InvoiceAndButtonContainer,
  Separator,
  TextAndImageContainer,
  TextContainer,
} from './styles';
import { PrimaryButton } from '../../PrimaryButton';
import {
  formatCurrency,
  Months,
  TotalValueInvoiceSelected,
} from '../../../pages/Customer/NewHome/helpers';
import theme from '../../../theme';

export const UnderstandYourBillModal = ({
  data,
  name,
  providerCode,
  isOpen,
  onRequestClose,
}) => {
  const [isShowBigImage, setIsShowBigImage] = useState(false);
  const [urlImage, setUrlImage] = useState();

  useEffect(() => {
    getUrlExplicatedInvoiceImg();
  }, []);

  const month =
    Months[data?.label?.split('-')[0].trim()] ||
    data?.label?.split('-')[0].trim();
  const totalInvoice = TotalValueInvoiceSelected(
    data?.value_cents / 100,
    data?.producer_total_cents / 100
  );
  const totalInvoiceCurrency = formatCurrency(totalInvoice, false);

  const {
    compensated,
    brute_tax,
    uncompensated_tax_cents: uncompensatedTaxCents,
    calc_reduced_cents,
  } = data;

  const bruteTaxCurrency = formatCurrency(brute_tax, false, false, 6);

  const additionalCost = compensated * brute_tax;
  const additionalCostCurrency = formatCurrency(additionalCost, false);

  const reducedWithFlora = calc_reduced_cents / 100;
  const reducedWithFloraCurrency = formatCurrency(reducedWithFlora);

  const uncompensatedTax = uncompensatedTaxCents / 100;
  const uncompensatedTaxCurrency = formatCurrency(
    uncompensatedTax,
    false,
    false,
    3
  );

  const reducedValueWithuncompensatedTax = additionalCost - uncompensatedTax;
  const reducedValueWithuncompensatedTaxCurrency = formatCurrency(
    reducedValueWithuncompensatedTax
  );
  const totalConsume = formatCurrency(data?.captive_consumption, true, true);

  const floraConsume = formatCurrency(Math.ceil(compensated), true, true);

  const valueCents = data?.gift ? 0 : data?.value_cents / 100;

  const valueCentsCurrency = formatCurrency(valueCents);

  let saving = 0;
  switch (providerCode) {
    case 'cemig':
    case 'light':
      saving = reducedValueWithuncompensatedTax - valueCents;
      break;
    default:
      saving = reducedWithFlora - valueCents;
      break;
  }

  const monthlyBenefitCurrency = formatCurrency(saving, false);
  const yearlyBenefitCurrency = formatCurrency(saving * 12, false);

  const handleShowBigImage = useCallback(() => {
    setIsShowBigImage(true);
  }, []);

  const handleHiddenBigImage = useCallback(() => {
    setIsShowBigImage(false);
  }, []);

  const getTextContainer = (isFullText) => {
    switch (providerCode) {
      case 'cpfl_paulista':
      case 'cpfl_santa_cruz':
      case 'cpfl_piratininga':
        return (
          <TextContainer>
            <p>
              Olá <strong>{name}</strong> {!!urlImage ? 'sem' : 'com'},
            </p>
            <p>
              Esta é a sua economia do mês de
              <strong> {month}</strong>:
            </p>
            <ol className='custom-list'>
              <li>
                Você gastou um total de <strong>{totalInvoiceCurrency}</strong>;
              </li>
              <li>
                Seu consumo total foi de <strong>{totalConsume} kWh</strong>;
              </li>
              <li>
                A Flora alocou <strong>{floraConsume} kWh</strong>;
              </li>
              <li>
                A Flora compensou <strong>{reducedWithFloraCurrency}</strong>{' '}
                (repare nos valores negativos abatendo a sua conta de luz).
              </li>
            </ol>
            <p>Em paralelo, é cobrado nossa fatura.</p>
            <p>
              Nesse mês, cobramos um valor de{' '}
              <strong>{valueCentsCurrency}</strong>.
            </p>
            <p>
              Totalizando um{' '}
              <strong>benefício mensal de {monthlyBenefitCurrency}</strong>{' '}
              &#40;
              {reducedWithFloraCurrency} - {valueCentsCurrency}&#41;.
            </p>
          </TextContainer>
        );
      case 'cemig':
        return (
          <TextContainer>
            <h6>
              Olá <strong>{name}</strong>,
            </h6>
            <p>
              Este mês de <strong>{month}</strong> você gastou um total de{' '}
              <strong>{totalInvoiceCurrency}</strong>.
            </p>
            <ol className='custom-list'>
              <li>
                Seu consumo total de energia foi de{' '}
                <strong>{totalConsume} kWh</strong>;
              </li>
              <li>
                A Flora creditou <strong>{floraConsume} kWh</strong>;
              </li>
              <li>
                Com uma tarifa da distribuidora de{' '}
                <strong>{bruteTaxCurrency}</strong>, sem a Flora, você teria um
                custo adicional de <strong>{additionalCostCurrency}</strong> (
                {floraConsume} kWh * {bruteTaxCurrency})
              </li>
              <li>
                Simultaneamente, há um valor adicional de{' '}
                <strong>{uncompensatedTaxCurrency}</strong>;
              </li>
            </ol>
            <p>
              Portando, a Flora reduziu{' '}
              <strong>{reducedValueWithuncompensatedTaxCurrency}</strong> (
              {additionalCostCurrency} – {uncompensatedTaxCurrency}) de sua
              conta de luz.
            </p>
            <p>
              Por esta redução, cobraríamos uma fatura de{' '}
              <strong>{valueCentsCurrency}</strong> gerando uma economia de{' '}
              <strong> {monthlyBenefitCurrency}</strong> (
              {reducedValueWithuncompensatedTaxCurrency} – {valueCentsCurrency}){' '}
            </p>
            <p>
              Sendo assim, no período de <strong>um ano</strong> com a Flora,
              você acumularia um desconto total de{' '}
              <strong>{yearlyBenefitCurrency}</strong> ({monthlyBenefitCurrency}{' '}
              * 12 meses)!
            </p>
          </TextContainer>
        );
      case 'light':
        return (
          <TextContainer>
            <h6>
              Olá <strong>{name}</strong>,
            </h6>
            <p>
              Este mês de <strong>{month}</strong> você gastou um total de{' '}
              <strong>{totalInvoiceCurrency}</strong>.
            </p>
            <ol className='custom-list'>
              <li>
                Seu consumo total de energia foi de{' '}
                <strong>{totalConsume} kWh</strong>;
              </li>
              <li>
                A Flora creditou <strong>{floraConsume} kWh</strong>;
              </li>
              <li>
                Com uma tarifa da distribuidora de{' '}
                <strong>{bruteTaxCurrency}</strong>, sem a Flora, você teria um
                custo adicional de <strong>{additionalCostCurrency}</strong> (
                {floraConsume} kWh * {bruteTaxCurrency})
              </li>
              <li>
                Simultaneamente, há um valor adicional de{' '}
                <strong>{uncompensatedTaxCurrency}</strong>;
              </li>
            </ol>
            <p>
              Portando, a Flora reduziu{' '}
              <strong>{reducedValueWithuncompensatedTaxCurrency}</strong> (
              {additionalCostCurrency} – {uncompensatedTaxCurrency}) de sua
              conta de luz.
            </p>
            <p>
              Por esta redução, cobraríamos uma fatura de{' '}
              <strong>{valueCentsCurrency}</strong> gerando uma economia de{' '}
              <strong> {monthlyBenefitCurrency}</strong> (
              {reducedValueWithuncompensatedTaxCurrency} – {valueCentsCurrency}){' '}
            </p>
            <p>
              Sendo assim, no período de <strong>um ano</strong> com a Flora,
              você acumularia um desconto total de{' '}
              <strong>{yearlyBenefitCurrency}</strong> ({monthlyBenefitCurrency}{' '}
              * 12 meses)!
            </p>
          </TextContainer>
        );
      case 'enel_rj':
        return (
          <TextContainer>
            <h6>
              Olá <strong>{name}</strong>,
            </h6>
            <p>
              Este mês de <strong>{month}</strong> você gastou um total de{' '}
              <strong>{totalInvoiceCurrency}</strong>.
            </p>
            <ol className='custom-list'>
              <li>
                Seu consumo total de energia foi de{' '}
                <strong>{totalConsume} kWh</strong>;
              </li>
              <li>
                A Flora creditou <strong>{floraConsume}</strong> kWh, que
                significa uma redução de{' '}
                <strong>{reducedWithFloraCurrency}</strong>;
              </li>
            </ol>
            <p>
              Por esta redução, cobraríamos uma fatura de{' '}
              <strong>{valueCentsCurrency}</strong> gerando uma economia de{' '}
              <strong> {monthlyBenefitCurrency}</strong> (
              {reducedWithFloraCurrency} – {valueCentsCurrency}){' '}
            </p>
            <p>
              Sendo assim, no período de <strong>um ano</strong> com a Flora,
              você acumularia um desconto total de{' '}
              <strong>{yearlyBenefitCurrency}</strong> ({monthlyBenefitCurrency}{' '}
              * 12 meses)!
            </p>
          </TextContainer>
        );
      default:
        return (
          <TextContainer isFullText={isFullText}>
            <h6>
              Olá <strong>{name}</strong>,
            </h6>
            <p>
              Este mês de <strong>{month}</strong> você gastou um total de{' '}
              <strong>{totalInvoiceCurrency}</strong>.
            </p>
            <ol className='custom-list'>
              <li>Seu consumo total de energia foi de {totalConsume} kWh;</li>
              <li>Foi alocado {floraConsume} kWh pela Flora;</li>
              <li>
                A Flora compensou {reducedWithFloraCurrency} repare nos valores
                negativos abatendo a sua conta de luz.
              </li>
            </ol>
            <p>
              Desssa forma, a Flora reduziu {reducedWithFloraCurrency} da sua
              conta de luz.
            </p>
            <p>Em paralelo, é cobrado nossa fatura.</p>
            <p>
              Nesse mês, cobramos um valor de{' '}
              <strong>{valueCentsCurrency}</strong>.
            </p>
            <p>
              Totalizando um{' '}
              <strong>benefício mensal de {monthlyBenefitCurrency}</strong>,
              considerando o valor compensando de menos o valor da fatura Flora
              &#40;{reducedWithFloraCurrency} - {valueCentsCurrency}&#41;.
            </p>
          </TextContainer>
        );
    }
  };

  const getUrlExplicatedInvoiceImg = () => {
    let urlFile = null;
    switch (providerCode) {
      case 'cpfl_paulista':
      case 'cpfl_santa_cruz':
      case 'cpfl_piratininga':
        urlFile = explicatedInvoiceCPFLImg;
        break;
      case 'light':
        urlFile = explicatedInvoiceLightImg;
        break;
      case 'cemig':
        urlFile = explicatedInvoiceCemigImg;
        break;
      case 'enel_rj':
        urlFile = explicatedInvoiceEnelRjImg;
        break;
    }
    setUrlImage(urlFile);
  };

  return (
    <NewModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      isUnderstandYourBill
    >
      <Container>
        <Header>
          <Button
            onClick={handleHiddenBigImage}
            isShowBigImage={!isShowBigImage}
          >
            <MdArrowBackIosNew
              size='1.5rem'
              color={theme.palette.primary.main}
            />
          </Button>
          <h2>Como ler minha fatura</h2>
          <Button onClick={onRequestClose}>
            <MdClose
              size='1.5rem'
              color={theme.palette.primary.main}
            />
          </Button>
        </Header>

        {isShowBigImage && urlImage ? (
          <ImageBigContainer>
            <img
              src={urlImage}
              alt='Imagem grande explicando como ler uma fatura de conta de luz '
            />
          </ImageBigContainer>
        ) : (
          <TextAndImageContainer>
            {getTextContainer(!urlImage)}

            {urlImage ? (
              <>
                <DownloadButtonContainer>
                  <Separator />
                  <a
                    href={urlImage}
                    alt='Imagem explicando como ler uma fatura de conta de luz'
                    download='Explicação-Fatura'
                  >
                    <PrimaryButton>Baixar imagem exemplo</PrimaryButton>
                  </a>
                </DownloadButtonContainer>

                <InvoiceAndButtonContainer>
                  <img
                    src={urlImage}
                    alt='Imagem explicando como ler uma fatura de conta de luz'
                    style={{ width: '100%', maxWidth: 700 }}
                  />
                  <ImageButtonBig onClick={handleShowBigImage}>
                    Clique aqui para abrir a imagem
                  </ImageButtonBig>
                </InvoiceAndButtonContainer>
              </>
            ) : null}
          </TextAndImageContainer>
        )}
      </Container>
    </NewModal>
  );
};

UnderstandYourBillModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  provider: PropTypes.string.isRequired,
  providerCode: PropTypes.string.isRequired,
};

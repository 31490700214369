import React from 'react';
import { Grid } from '@mui/material';
import { Heading1 } from '../../../../../components/Interface/Text';
import LocationContextProvider from '../../../../../contexts/LocationContext';
import ContractBillSection from '../ContractBillSection';
import ContractBillLocationCondition from '../ContractBillSection/ContractBiilLocationCondition';
import ContractLocationsProposal from '../ContractLocationsProposal';

const ContractLocationsSection = () => {
  return (
    <LocationContextProvider>
      <Grid
        item
        xs={12}
      >
        <Heading1>Imóveis</Heading1>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
      >
        <ContractBillSection />
      </Grid>

      <Grid
        item
        sx={12}
        lg={6}
      >
        <ContractBillLocationCondition />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <ContractLocationsProposal />
      </Grid>
    </LocationContextProvider>
  );
};

export default ContractLocationsSection;

/**
 * Returns true if browser environment detected
 */
export function isBrowser() {
  return typeof window !== 'undefined';
}

/**
 * Navigates to the given path
 */
export function navigate(path = 'login') {
  if (isBrowser()) {
    window.location.pathname = path;
  }
}

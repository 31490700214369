import { Grid } from '@mui/material';

import { Modal } from '../../../components/NewModal/styles';

import { DropzoneCustom } from '../../../components/DropzoneCustom';
import ExcelRenderer from '../../../utils/excelRenderer';
import theme from '../../../theme';
import Button from '../../../components/Button';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Heading2 } from '../../../components/Interface/Text';
import MassiveDiscountService from '../../../services/administrator/massiveDiscount.service';

const ModalMassiveDiscount = ({ onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rows, setRows] = useState([]);
  const [standardTableColumns, setStandardTableColumns] = useState([]);
  const [hasResetFiles, setHasResetFiles] = useState(false);

  const onSubmit = async (data, { setSubmitting }) => {
    setSubmitting(true);
    const items = data.map((item) => ({
      contractId: item[0],
      discount: item[1],
    }));

    try {
      const { updatedContractIds, notExistsContractIds } =
        await MassiveDiscountService.updateBulk(items);

      toast.success(
        `A alteração de desconto de  ${updatedContractIds.length} ${
          updatedContractIds.length !== 1 ? 'contratos' : 'contrato'
        } foi bem sucedida.
        ${
          notExistsContractIds.length > 0
            ? `${notExistsContractIds.length} 
                  ${
                    notExistsContractIds.length !== 1
                      ? 'contratos não encontrados'
                      : 'contrato não encontrado'
                  } no sistema.`
            : ''
        }`
      );
      onClose(false);
    } catch (err) {
      toast.error(
        'Processamento não realizado, revise a planilha e tente novamente.'
      );
    } finally {
      setSubmitting(false);
    }
  };

  const dataHandler = (data) => {
    const arrayOfArrays = data.rows;
    const [headers, ...rows] = arrayOfArrays;

    const regexHeader = [/^(contratos?)$/i, /^descontos?$/i];
    if (!headers[0].match(regexHeader[0]))
      throw 'A primeira coluna deve-se chamar "Contrato"';
    if (!headers[1].match(regexHeader[1]))
      throw 'A segunda coluna deve-se chamar "Desconto"';

    try {
      const assembleArrayOfColumns = headers.slice(0, 2).map((el) => {
        return { name: el };
      });
      const filteredRows = rows
        .map((row) => row.slice(0, 2))
        .filter((el) => el.length > 0);

      for (const row of filteredRows) {
        const discount = row[1];
        if (parseFloat(discount) < 0 || discount > 100) {
          throw 'O desconto aplicado deve ser entre 0 e 100. Analise a planilha e tente novamente.';
        }
      }

      setRows(filteredRows);
      setStandardTableColumns(assembleArrayOfColumns);
    } catch (error) {
      throw error;
    }
  };

  const fileHandler = async (files) => {
    setHasResetFiles(false);
    try {
      const fileObj = files[0];
      const { type } = fileObj;
      if (
        type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
        throw "O formato do arquivo deve ser 'xlsx'";

      const data = await ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          throw err;
        } else {
          return resp;
        }
      });
      dataHandler(data);
    } catch (error) {
      setHasResetFiles(true);
      toast.error(error);
    }
  };

  const getRows = (rows) => {
    try {
      return rows.map((row) => {
        const arr = row.map((cell) => {
          return { value: <p>{cell}</p> };
        });

        return { values: arr };
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      width='800px'
    >
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        spacing={1}
        style={{ padding: '10px 20px' }}
      >
        <Grid
          item
          xs={12}
        >
          <Heading2 align='center'>Alterar descontos em lote</Heading2>
        </Grid>
        <Grid
          item
          justifyContent='center'
          alignItems='center'
          xs={12}
          md={rows.length ? 8 : 12}
        >
          <DropzoneCustom
            maxFiles={1}
            fileHandler={fileHandler}
            acceptedFileTypes={['.xlsx']}
            handlerFileAfterChange={true}
            hasResetFiles={hasResetFiles}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          justifyContent='center'
          alignItems='center'
        >
          {rows.length > 0 && standardTableColumns.length > 0 && (
            <SearchableTable
              columns={standardTableColumns}
              rowsValues={getRows(rows)}
              noItemsText='Nenhum valor encontrado'
              noSearch
              noDownload={true}
              maxHeight='80vh'
            />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='space-around'
        style={{ margin: '10px 0' }}
      >
        <Button
          text='Cancelar'
          handleClick={onClose}
          color='white'
          borderColor={theme.palette.primary.main}
          outlined
        />
        <Button
          handleClick={() => onSubmit(rows, { setSubmitting: setIsSubmitting })}
          disabled={isSubmitting || rows.length === 0}
          textColor={theme.palette.primary.main}
          color={theme.palette.secondary.main}
          text='Alterar'
          fontSize='50'
          loading={isSubmitting}
        />
      </Grid>
    </Modal>
  );
};

export default ModalMassiveDiscount;

/* eslint-disable one-var */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable no-throw-literal */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import moment from 'moment';

import { toast } from 'react-toastify';
import PageView from '../../../containers/PageView';
import MassActivationService from '../../../services/administrator/ManagementBases/massActivation.service';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { StatusToggle, StyledLink } from '../../../components/Interface';
import { Paragraph } from '../../../components/Interface/Text/Paragraph';

import ContentCard from '../../../components/ContentCard';
import { ADMIN_CONTRACT_STATUS_TYPES_PTBR } from '../../../helpers/contractStatusTypes';
import GradientButton from '../../../components/GradientButton';
import ExcelRenderer from '../../../utils/excelRenderer';
import { DropzoneCustom } from '../../../components/DropzoneCustom';
import styled from 'styled-components';
import { parseISO, format } from 'date-fns';
import Alerts from '../../../components/Alerts';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';

const COLUMNSEXTRA = [
  { name: 'Contrato' },
  { name: 'Data de Ativação / Mensagem' },
];

const COLUMN = [{ name: 'Contrato' }, { name: 'Data de Ativação' }];

const getRows = (contracts, full = true) => {
  try {
    return contracts.map((contract) => {
      const { id, status, activated_at, error, errorMessage } = contract;
      const rows = full
        ? {
            values: [
              {
                value: (
                  <StyledLink
                    tag='true'
                    to={id ? `/contratos/${id}` : '/checkFaturamento'}
                  >{`${id}`}</StyledLink>
                ),
              },
              {
                value: (
                  <Paragraph>
                    {activated_at
                      ? moment(activated_at).format('DD/MM/yyyy')
                      : ''}
                  </Paragraph>
                ),
              },
              {
                value: (
                  <Paragraph>
                    {ADMIN_CONTRACT_STATUS_TYPES_PTBR[status]}
                  </Paragraph>
                ),
              },
            ],
          }
        : {
            values: [
              {
                value: (
                  <StyledLink
                    tag='true'
                    to={id ? `/contratos/${id}` : '/checkFaturamento'}
                  >{`${id}`}</StyledLink>
                ),
              },
              {
                value: (
                  <Paragraph>
                    {error
                      ? errorMessage
                      : activated_at
                      ? moment(activated_at).format('DD/MM/yyyy')
                      : ''}
                  </Paragraph>
                ),
              },
            ],
          };

      return rows;
    });
  } catch (error) {
    console.log(error);
  }
};

const InvoicesCheck = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    active: [],
    pending_activation: [],
    draft: [],
    all: [],
  });

  const [allData, setAllData] = useState(location?.state?.final || null);
  const [contractsErrorInvalidDate, setContractsErrorInvalidDate] = useState(
    []
  );
  const [exportLoading, setExportLoading] = useState(false);
  const [responseHeaders, setResponseHeaders] = useState(false);
  const [rerender, setRerender] = useState(true);
  const notFullMode = true;

  useEffect(() => {
    const fetchInvoicesCheck = async () => {
      try {
        const activeContractsByMonth = await MassActivationService.getAll();
        setAllData(activeContractsByMonth.sort((a, b) => b.id - a.id));
        setPageState((state) => ({
          ...state,
          loading: false,
          hasError: false,
        }));
      } catch (err) {
        console.log(err);
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };
    if (!allData) {
      fetchInvoicesCheck();
    } else {
      setPageState((state) => ({ ...state, loading: false, hasError: false }));
    }
  }, []);

  const fetchAll = async () => {
    const data = await MassActivationService.getAll();

    return getRows(data, false);
  };

  const onSubmit = async (el) => {
    setPageState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const responseData = await MassActivationService.updateAll(allData);
      setAllData(responseData);
      setResponseHeaders(true);
      toast.success(TOAST_MESSAGES.SUCCESS);
    } catch (error) {
      setPageState((state) => ({
        ...state,
        loading: false,
        hasError: false,
      }));
      toast.error(TOAST_MESSAGES.ERROR);
      setResponseHeaders(false);
    }
  };

  useEffect(() => {
    if (!allData) return;
    setPageState((state) => ({
      ...state,
      loading: false,
    }));
  }, [allData]);

  const desiredColumns = ['Contrato', 'Data de Ativação'];

  const handleDate = (data) => {
    try {
      let month, day, year;
      const date = parseISO('1900-01-01'); // Data base do Excel
      date.setDate(date.getDate() + (data - 2));
      const formattedDate = format(date, 'MM/dd/yyyy');
      [month, day, year] = formattedDate?.split('/');
      if (month && day && year) return new Date(year, month - 1, day).getTime();
    } catch (error) {
      console.log('file: index.js || line 230 || error', error);
      return 'Data com padrão inválido';
    }
  };
  const checkModel = (titles) => {
    desiredColumns.map((desiredTitle, index) => {
      if (titles[index] !== desiredTitle) throw 'Por favor utilize o modelo';
    });
  };

  const rowsHandler = (datas) => {
    try {
      const finalData = [];
      const contractsInvalidDate = [];

      datas.map((data) => {
        const [contractId, activated_at] = data;
        if (!contractId) return;
        if (!activated_at)
          return finalData.push({ id: +contractId, activated_at: Date.now() });
        else if (typeof activated_at !== 'number')
          contractsInvalidDate.push({
            id: +contractId,
          });
        else {
          const handledDate = handleDate(activated_at);
          finalData.push({ id: +contractId, activated_at: handledDate });
        }
      });
      setAllData(finalData);
      setRerender(!rerender);
      setContractsErrorInvalidDate(contractsInvalidDate);
    } catch (error) {
      console.log('file: index.js || line 263 || error', error);
      throw error;
    }
  };
  const dataHandler = (data) => {
    const { rows } = data;
    const [titles, ...datas] = rows;
    checkModel(titles);
    rowsHandler(datas);
  };

  const fileHandler = async (files) => {
    try {
      const fileObj = files[0];
      const { type } = fileObj;
      if (
        type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
        throw "O formato do arquivo deve ser 'xlsx'";

      const data = await ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          throw err;
        } else {
          return resp;
        }
      });
      dataHandler(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const { loading, hasError } = pageState;

  return (
    <PageView
      title='Ativação em Massa'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Paragraph align='center'>
        Upload de Contratos, com a respectiva data de ativação que deseja
        ativar. Caso esteja em branco será preenchida a data atual.
      </Paragraph>
      <DropzoneCustom
        maxFiles={1}
        fileHandler={fileHandler}
        acceptedFileTypes={['.xlsx']}
        handlerFileAfterChange={true}
      />

      <div
        style={{
          marginTop: '2rem',
          marginBottom: '2.5rem',
          border: '1px solid gray',
        }}
      />
      <>
        {allData && (
          <Grid container>
            <Grid
              item
              xs={12}
            >
              <ContentCard
                title={
                  notFullMode
                    ? 'Validar contratos'
                    : 'Contratos passiveis de serem ativos '
                }
              >
                {contractsErrorInvalidDate.length > 0 ? (
                  <AlertsContainer>
                    <Alerts
                      alerts={[
                        {
                          type: 'error',
                          message: `O${
                            contractsErrorInvalidDate.length > 1 ? 's' : ''
                          } contrato${
                            contractsErrorInvalidDate.length > 1 ? 's' : ''
                          } ${contractsErrorInvalidDate
                            .map((c) => c.id)
                            .join(', ')} não ${
                            contractsErrorInvalidDate.length > 1
                              ? 'possuem'
                              : 'possui'
                          } o padrão de data esperado. Ajuste o arquivo para adiciona-lo a lista de ativação.`,
                        },
                      ]}
                    />
                  </AlertsContainer>
                ) : null}
                <SearchableTable
                  columns={!responseHeaders ? COLUMN : COLUMNSEXTRA}
                  // rowsValues={getRows(allData, !notFullMode)}
                  rowsValues={getRows(allData, false)}
                  noItemsText='Nenhuma contrato passível de ativação'
                  fetchAll={fetchAll}
                  rerender={rerender}
                />
              </ContentCard>
            </Grid>
          </Grid>
        )}
        {allData && (
          <div style={{ marginTop: '3rem' }}>
            <GradientButton
              text={'Salvar'}
              handleClick={() => onSubmit()}
            />
          </div>
        )}
      </>
    </PageView>
  );
};

const FormatDateContainer = styled.div`
  background: #fff3cde7;
  padding: 10px;
  border-radius: 10px;
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const AlertsContainer = styled.div`
  margin-bottom: 20px;
`;

export default InvoicesCheck;

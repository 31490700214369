import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import LogoImg from '../../../assets/images/logo/logo-flora.svg';

import AuthService from '../../../services/authentication/auth.service';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';
import { NewPageView } from '../../../containers/NewPageView';
import { Container, Card, Content } from './styles';
import { PrimaryButton } from '../../../components/PrimaryButton';
import theme from '../../../theme';

const BaseConfirmEmail = ({ className }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [checker, setChecker] = useState(false);

  useEffect(() => {
    setChecker(location?.search?.split('=')[0]?.replace('?', '') === 'token');
  }, [location]);

  const confirm = async () => {
    setLoading(true);
    try {
      const param = location?.search.split('=')[0];
      if (param === '?company_id') return;
      const token =
        location.search.split('=')[1] ||
        window.localStorage.getItem('token_email_reset');
      if (!token) {
        toast.error(TOAST_MESSAGES.ERROR);
      }
      let check;
      if (checker) {
        check = await AuthService.confirmEmail({ token });
      } else {
        check = await AuthService.confirmResendToken({ token });
      }
      if (check.success) {
        toast.success(TOAST_MESSAGES.SUCCESS);
        setSuccess(true);
      } else {
        toast.error(
          checker
            ? 'Falha ao confirmar seu email. Entre em contato com o atendimento ou tente novamente mais tarde .'
            : 'Falha ao reenviar seu contrato. Entre em contato com o atendimento ou tente novamente mais tarde.'
        );
        setSuccess(false);
      }
    } catch {
      setHasError(true);
      toast.error(
        checker
          ? 'Falha ao confirmar seu email. Entre em contato com o atendimento ou tente novamente mais tarde .'
          : 'Falha ao reenviar seu contrato. Entre em contato com o atendimento ou tente novamente mais tarde.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <NewPageView>
      <Container>
        <Card>
          <Content>
            <a href='https://floraenergia.com.br/'>
              <img
                src={LogoImg}
                alt='Logo flora'
              />
            </a>
            {success ? (
              <>
                <h2>
                  {checker
                    ? 'Email confirmado com sucesso!'
                    : 'Contrato reenviado com sucesso!'}
                </h2>
                <PrimaryButton onClick={() => navigate('/home')}>
                  Acessar sua conta
                </PrimaryButton>
              </>
            ) : (
              <>
                <h2>
                  {checker
                    ? 'Para confirmar seu email clique no botao abaixo'
                    : 'Para reenviar o contrato clique no botao abaixo'}
                </h2>

                <PrimaryButton
                  disabled={loading}
                  onClick={() => confirm()}
                >
                  {loading ? (
                    <CircularProgress
                      style={{
                        color: theme.palette.primary.main,
                      }}
                    />
                  ) : (
                    <>{checker ? 'CONFIRMAR EMAIL' : 'REENVIAR'}</>
                  )}
                </PrimaryButton>
              </>
            )}
          </Content>
        </Card>
      </Container>
    </NewPageView>
  );
};

BaseConfirmEmail.propTypes = {
  className: PropTypes.string.isRequired,
};

const ConfirmEmail = styled(BaseConfirmEmail)`
  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  position: fixed;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-color: #61787b;

  @media screen and (max-width: 1089px) {
    background: unset;
    height: auto;
    overflow: auto;
    position: unset;
  }

  .login {
    &-wrapper {
      height: 100%;
    }
    &-error {
      margin-top: 30px;
      color: red;
    }
  }

  .form {
    &-wrapper {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 1089px) {
        justify-content: center;
        align-items: center;
      }

      .box {
        border: none;
        max-height: 95%;
        overflow: hidden;
        max-width: 600px;
        align-items: center;
        flex-direction: column;
        padding: 50px 50px 50px 50px;
        width: 100%;
        background: #ffffff;
        border-radius: 15px;
        @media screen and (max-width: 1200px) {
          padding: 75px 30px 30px 30px;
          box-sizing: border-box;
          border-radius: 0px;
          box-shadow: none;
          height: 100vh;
          justify-content: center;
          .img-wrapper-mobile {
            display: flex;
            justify-content: center;
            width: 100% !important;
            height: fit-content !important;
            > img {
              height: auto !important;
            }
          }
        }

        .img-wrapper {
          display: flex;
          width: 100%;
          justify-content: center;
          :hover {
            cursor: pointer;
            opacity: 0.9;
          }
        }
      }

      h1 {
        margin: 30px 0px 30px 0px;

        @media screen and (max-width: 1089px) {
          margin: 60px 0px 30px 0px;
        }
      }
    }
  }
`;

export default ConfirmEmail;

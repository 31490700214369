export const formErrors = Object.freeze({
  required: 'Campo obrigatório',
  invalidEmail: 'E-mail inválido',
  invalidCpf: 'CPF inválido',
  passwordMatch: 'As senhas não são iguais',
  invalidFormat: 'Insira arquivos nos formatos jpeg, jpg, png ou PDF',
  invalidCnpj: 'CNPJ inválido',
  invalidCpfOrCnpj: 'CPF/CNPJ inválido',
  correctLength: (limit, message) =>
    message
      ? `Deve ocupar ${limit} caracteres ${message}`
      : `Deve ocupar ${limit} caracteres`,
  minLength: (limit) => `No mínimo ${limit} caracteres`,
  maxLength: (limit) => `No máximo ${limit} caracteres`,
  textField: 'Apenas letras são permitidas',
  cardNumbersInvalid: 'Esse número de cartão não é válido',
  cardCvvInvalid: 'Código de verificação inválido',
  cardExpDateInvalid: 'Data de vencimento inválida',
  maxNumericValue: (digitsLimit) =>
    `O valor máximo é ${Array(digitsLimit).fill(9).join('')}`,
  percentage: 'Valor percentual inválido (0 a 100%)',
  invalidDate: 'Essa não é uma data válida',
  invalidPhone: 'Telefone Inválido',
  invalidFormatHolder: 'Formato do Nome/Razão Social inválido',
});

import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import { MdOutlineCheckCircleOutline } from 'react-icons/md';
import { RiErrorWarningLine } from 'react-icons/ri';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import {
  Message,
  ContContent,
  Content,
  ContIcon,
  InputContainer,
  Placeholder,
} from './styles';
import theme from '../../theme';

export const InputCustom = forwardRef(
  (
    {
      type,
      name,
      labelText,
      placeholder = '',
      value,
      hasError = false,
      hasTouched = false,
      errorMessage = '',
      message = '',
      requiredMessage = '',
      noIcon = false,
      disabled = false,
      onBlur = () => {},
      onChange = () => {},
      onFocus = () => {},
      successMessage = '',
      ...rest
    },
    ref
  ) => {
    try {
      if (value === undefined) {
        throw new Error('value undefined');
      }
    } catch (error) {
      console.log(error);
    }

    const labelRef = useRef();
    const placeholderRef = useRef();
    const [hasPassword, setHasPassword] = useState(false);

    let openPassword = false;

    if (type === 'password' && value?.trim().length > 0) {
      openPassword = true;
    }

    const seePassword = () => {
      setHasPassword(!hasPassword);
    };

    const hasLabelText = useCallback(() => {
      labelRef.current.style.top = '0px';
      labelRef.current.style.left = '16px';
      labelRef.current.style.fontSize = '12px';
      labelRef.current.style.color = theme.palette.primary.main;

      if (value.length) {
        placeholderRef.current.style.opacity = '0';
        return;
      }

      placeholderRef.current.style.opacity = '1';
    }, [value]);

    const noHasLabelText = () => {
      labelRef.current.style.top = '20px';
      labelRef.current.style.fontSize = '16px';
      labelRef.current.style.color = '#323232';
      labelRef.current.style.left = '16px';
      placeholderRef.current.style.opacity = '0';
    };

    const handleBlur = (event) => {
      onBlur(event);

      if (value.length) return;

      noHasLabelText();
    };

    const handleChange = (event) => {
      onChange(event);

      if (event.target.value.length) {
        placeholderRef.current.style.opacity = '0';
        return;
      }

      placeholderRef.current.style.opacity = '1';
    };

    const handleFocus = (event) => {
      hasLabelText();
      onFocus(event);
    };

    useEffect(() => {
      const str = String(value);

      if (str.length) {
        hasLabelText();
      } else {
        noHasLabelText();
      }
    }, [value, hasLabelText]);

    return (
      <div>
        <ContContent>
          <Content
            hasError={hasError && hasTouched}
            isAccept={!hasError && hasTouched && !!value?.length}
            isDisabled={disabled}
          >
            <InputContainer isDisabled={disabled}>
              <input
                id={name}
                type={hasPassword ? 'text' : type}
                onBlur={handleBlur}
                value={value}
                disabled={disabled}
                ref={ref}
                onFocus={handleFocus}
                onChange={handleChange}
                {...rest}
                spellcheck='false'
              />
              {openPassword && (
                <button
                  type='button'
                  onClick={seePassword}
                  disabled={disabled}
                >
                  {hasPassword ? (
                    <AiOutlineEye size={20} />
                  ) : (
                    <AiOutlineEyeInvisible size={20} />
                  )}
                </button>
              )}
            </InputContainer>

            <label
              htmlFor={name}
              ref={labelRef}
              disabled={disabled}
            >
              {labelText}
            </label>

            <Placeholder
              ref={placeholderRef}
              htmlFor={labelText}
            >
              {placeholder}
            </Placeholder>

            {!noIcon && (
              <ContIcon isDisabled={disabled}>
                {!!hasError && hasTouched && (
                  <RiErrorWarningLine
                    size='20px'
                    color={theme.palette.error.main}
                  />
                )}

                {!hasError && hasTouched && !!value?.length && (
                  <MdOutlineCheckCircleOutline
                    size='20px'
                    color={theme.palette.success.main}
                  />
                )}
              </ContIcon>
            )}
          </Content>
        </ContContent>
        {hasTouched && hasError && !!value?.length && (
          <Message isError>{errorMessage}</Message>
        )}
        {hasTouched && hasError && !value?.length && (
          <Message isError>{requiredMessage}</Message>
        )}
        {message && !hasError && !(hasTouched && !hasError && value?.length) && <Message>{message}</Message>}
        {hasTouched && value && !hasError && ( 
          <Message isSuccess>{successMessage}</Message>
        )}
      </div>
    );
  }
);

InputCustom.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  hasTouched: PropTypes.bool,
  errorMessage: PropTypes.string,
  message: PropTypes.string,
  successMessage: PropTypes.string,
  requiredMessage: PropTypes.string,
  noIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

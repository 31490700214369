import * as yup from 'yup';

import { formErrors } from './errors';
import { removeSpacesBetween } from './formatter';
import { unMask } from './unMask';

export const formValidations = {
  phoneNumber: () =>
    yup
      .string()
      .ensure()
      .transform((value) => value && value.replace(/\D/g, ''))
      .test(
        'phoneNumber',
        formErrors.correctLength('10 ou 11'),
        (val) =>
          !val || (val && val.length === 10) || (val && val.length === 11)
      ),
  maxDigits: (value, digits) =>
    value >= 0 && value <= parseInt(Array(digits).fill(9).join(''), 10),
  cpf: () =>
    yup
      .string()
      .ensure()
      .transform((value) => value && value.replace(/\D/g, ''))
      .test(
        'cpfTest',
        formErrors.invalidCpf,
        (val) => val && val.length === 11
      ),
};

// phoneNumber: () =>
//     yup.string()
//       .ensure()
//       .transform((value) => value && value.replace(/\D/g, ''))
//       .test(
//         'phoneNumber',
//         formErrors.correctLength('10 ou 11'),
//         (val) =>
//           !val || (val && val.length === 10) || (val && val.length === 11)
//       )
//       .required(formErrors.required),

export const validationName = (name) => {
  const nameFiltered = removeSpacesBetween(name);
  const splitName = nameFiltered.split(' ');

  if (splitName.length <= 1 || splitName?.[1] === '' || !splitName?.[1]) {
    return false;
  }

  const regexName =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,´]+$/u;
  return regexName.test(name);
};

export const validationPhone = (phone) => {
  const phoneFiltered = unMask(phone);

  if (phoneFiltered.length === 11 || phoneFiltered.length === 10) return true;

  return false;
};

export const validationEmail = (email) => {
  const regexEmail = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
  return regexEmail.test(email);
};

export const validationEstimatedElectricBill = (estimatedElectricBill) => {
  if (parseFloat(estimatedElectricBill) >= 40) return true;

  return false;
};

export const validationNumber = (number) => {
  if (!number.length || Number(number) < 1) return false;

  if (number.match(/^s\/n$/i)) return true;

  const regexNumber = /\D/g;

  return !regexNumber.test(number.trim());
};

export const validationPostalCode = (postalCode) => {
  if (postalCode.length === 8) return true;

  return false;
};

export const validationData = (value) => {
  if (value?.trim()?.length > 0) return true;

  return false;
};

export const validationCpfOrCnpj = (document) => {
  if (unMask(document).length === 11 || unMask(document).length === 14)
    return true;

  return false;
};

export const validationCard = (dueDate) => {
  const getNumbers = dueDate.split('/').join('');
  if (Number.isNaN(+getNumbers)) return false;

  if (getNumbers.length < 4) return false;

  let minMonth = new Date().getMonth() + 1;
  const minYear = new Date().getFullYear();
  let [month, year] = dueDate.split('/');

  minMonth = Number(String(minMonth).padStart(2, '0'));
  month = Number(month);
  year = Number(String(year).padStart(4, '20'));

  if (year < minYear) return false;
  if (month > 12 || (year === minYear && month < minMonth)) return false;

  return true;
};

export const birthDateValidation = (birthDate) => {
  if (birthDate.length < 10) return false;

  const todayDate = new Date();
  const currentYear = todayDate.getFullYear();
  const [birthday, birthMonth, yearOfBirth] = birthDate.split('/');

  if (birthday > 31) return false;
  if (birthMonth > 12) return false;

  let age = currentYear - yearOfBirth;
  const currentMonth = todayDate.getMonth() + 1;

  if (currentMonth < birthMonth) age -= 1;
  else if (currentMonth === birthMonth) {
    if (new Date().getDate() < birthday) age -= 1;
  }

  if (age < 18 || age > 80) return false;

  return true;
};

import connection from '../general/connection';

const create = async (payload) => {
  const { err, data } = await connection.post(`/styleguides/create`, {
    styleguide: payload,
  });

  if (err) {
    throw err;
  }

  return data;
};

const updateOne = async (id, payload) => {
  const { err, data } = await connection.patch(
    `/styleguides/edit/${id}`,
    payload
  );

  if (err) {
    throw err;
  }

  return data;
};

const get = async (id) => {
  const { err, data } = await connection.get(`/styleguides/${id}`);

  if (err) {
    throw err;
  }

  return data;
};

const getByCompanyId = async (companyId) => {
  const { err, data } = await connection.get(
    `/companies/${companyId}/styleguide`
  );

  if (err) {
    throw err;
  }

  return data;
};

const StyleguidesService = {
  updateOne,
  create,
  get,
  getByCompanyId,
};

export default StyleguidesService;

import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import FlexColContainer from '../../componentsStyle/FlexColContainer';
import FlexRowContainer from '../../componentsStyle/FlexRowContainer';
import MultiSelect from '../NewMultiSelect';
import styled from 'styled-components';
import { generatePayloadFieldsModifieds } from '../../helpers/form/generatePayloadFieldsModifieds';
import ExportingDatabasesService from '../../services/administrator/ManagementBases/ExportingDatabasesService';
import { TOAST_MESSAGES } from '../../helpers/toastMessages';
import { toast } from 'react-toastify';
import Alerts from '../Alerts';
import theme from '../../theme';

const TYPE_FIELDS = {
  SELECT: 'select',
  MULTISELECT: 'multiSelect',
  RADIO: 'radio',
  CHECK: 'check',
  SWITCH: 'switch',
};
const FormBasesManagement = ({
  data,
  setHasUpdateDataTable,
  additionalFormData,
}) => {
  const [formData, setFormData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [fieldsRequired, setFieldsRequired] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alerts, setAlerts] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleChangeSwitch = (event) => {
    const { name, checked } = event.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  const handleChangeCheckBox = (event) => {
    const { name, checked, value } = event.target;

    const newValue = checked
      ? [...formData[name], value]
      : formData[name].filter((v) => v.toString() !== value.toString());

    setFormData((state) => ({
      ...state,
      [name]: newValue,
    }));
  };

  const handleChangeMultiselect = (name, value) => {
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const defineField = (field) => {
    const { type, name, label, options, required, disabled, value } = field;
    if (type === TYPE_FIELDS.SELECT) {
      return (
        <FormControl className='w-100'>
          <InputLabel id={`label-${name}`}>{label}</InputLabel>
          <Select
            labelId={`label-${name}`}
            id={name}
            name={name}
            value={formData[name]}
            label={label}
            onChange={handleChange}
            disabled={disabled}
            required={required}
          >
            <MenuItem value={null}>Selecione</MenuItem>
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (type == TYPE_FIELDS.RADIO) {
      return (
        <FormControl className='w-100'>
          <FormLabel id={`label-${name}`}>{label}</FormLabel>
          <RadioGroup
            row
            aria-labelledby={`label-${name}`}
            name={name}
            defaultValue={value}
            onChange={handleChange}
            required={required}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
        </FormControl>
      );
    } else if (type === TYPE_FIELDS.CHECK) {
      return (
        <FormControl
          variant='standard'
          required={required}
          className='w-100'
        >
          <FormLabel>{label}</FormLabel>
          <FormGroup row>
            {options.map((option) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData[name]?.some(
                      (value) => option.value.toString() === value.toString()
                    )}
                    onChange={handleChangeCheckBox}
                    name={name}
                    value={option.value}
                    disabled={disabled}
                  />
                }
                label={option.label}
              />
            ))}
          </FormGroup>
        </FormControl>
      );
    } else if (type === TYPE_FIELDS.SWITCH) {
      return (
        <FormControl className='w-100'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <FormLabel style={{ flexGrow: 1, marginBottom: 0 }}>
              {label}
            </FormLabel>
            <Switch
              checked={formData[name]}
              onChange={handleChangeSwitch}
              disabled={disabled}
              name={name}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        </FormControl>
      );
    } else if (type === TYPE_FIELDS.MULTISELECT) {
      return (
        <>
          <FormLabel style={{ flexGrow: 1, marginBottom: 0 }}>
            {label}
          </FormLabel>
          <MultiSelect
            options={options}
            optionsSelected={formData[name]}
            updateData={handleChangeMultiselect}
            name={name}
            isDisabled={disabled}
          />
        </>
      );
    } else {
      return (
        <TextField
          id={name}
          name={name}
          label={label}
          type={type}
          variant='outlined'
          onChange={handleChange}
          disabled={disabled}
          required={required}
          className='w-100'
          defaultValue={value}
        />
      );
    }
  };

  const onSubmit = async () => {
    setAlerts([]);
    const {
      updateReq: { url, method },
    } = data;
    const updatedFields = generatePayloadFieldsModifieds(
      formData,
      initialData,
      additionalFormData
    );
    if (Object.keys(updatedFields).length > 0) {
      setIsSubmitting(true);
      setIsSubmitDisabled(true);
      try {
        await ExportingDatabasesService.updateForm(url, method, updatedFields);
        setHasUpdateDataTable(true);
        toast.success(TOAST_MESSAGES.SUCCESS);
      } catch (error) {
        const {
          data: { messageError },
        } = error;
        setAlerts([{ message: messageError, type: 'error' }]);
      } finally {
        setIsSubmitting(false);
        setIsSubmitDisabled(false);
      }
    } else {
      toast.warn(TOAST_MESSAGES.NO_DATA_UPDATED);
    }
  };

  const verifyFieldsRequired = () => {
    const hasValuesRequired = fieldsRequired.map(
      (field) =>
        formData[field] &&
        ((Array.isArray(formData[field]) && formData[field].length > 0) ||
          !Array.isArray(formData[field]))
    );

    return hasValuesRequired.some((value) => value !== true);
  };

  useEffect(() => {
    const { fields } = data;

    const initialData = fields.reduce((accumulator, currentValue) => {
      accumulator[currentValue.name] = currentValue.value;
      return accumulator;
    }, {});

    setFormData(initialData);
    setInitialData(initialData);

    const fieldsRequired = data.fields
      .filter((x) => x.required)
      .map((x) => x.name);
    setFieldsRequired(fieldsRequired);
  }, [data]);

  useEffect(() => {
    setIsSubmitDisabled(verifyFieldsRequired());
  }, [formData]);

  return (
    <Container>
      <AlertsContainer hasAlerts={alerts.length}>
        <Alerts alerts={alerts} />
      </AlertsContainer>
      {Object.keys(formData).length > 0 ? (
        <FlexColContainer>
          {data.fields.map((field) => (
            <div className='w-100 mb-4'>{defineField(field)}</div>
          ))}
          <ButtonContainer>
            <Button
              variant='contained'
              onClick={onSubmit}
              disabled={isSubmitDisabled}
              style={{
                background: theme.palette.secondary.main,
                color: theme.palette.primary.main,
              }}
            >
              <FlexRowContainer style={{ gap: '1rem' }}>
                Salvar
                {isSubmitting ? (
                  <CircularProgress
                    style={{ width: 20, height: 20, color: 'gray' }}
                  />
                ) : null}
              </FlexRowContainer>
            </Button>
          </ButtonContainer>
        </FlexColContainer>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  padding: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const AlertsContainer = styled.div`
  margin: 0 auto;
  top: 0;
  z-index: 99;
  padding-bottom: ${(props) => (props.hasAlerts ? '10px !important' : 0)};
  margin-bottom: 10px;
  background: #fff;
`;

export default FormBasesManagement;

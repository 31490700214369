import connection from './connection';

const getAll = async () => {
  const { err, data } = await connection.get(`/locations`);

  if (err) {
    throw err;
  }

  return data;
};

const get = async (locationId) => {
  const { err, data } = await connection.get(`/locations/${locationId}`);

  if (err) {
    throw err;
  }

  return data;
};

const create = async (payload) => {
  const { err, data } = await connection.post(`/locations`, payload);

  if (err) {
    throw err;
  }

  return data;
};

const update = async (locationId, payload) => {
  // const formData = new FormData();
  // const entries = Object.entries(payload);

  // entries.forEach(([key, value]) => {
  //   formData.append(`location[${key}]`, value);
  // });
  // if (payload.value) {
  //   payload = { ...payload, value_cents: payload.value * 100 };
  // }
  const formData = { location: { ...payload } };

  const { err, data } = await connection.patch(
    `/locations/${locationId}`,
    formData
  );

  if (err) {
    throw err;
  }

  return data;
};

const remove = async (locationId) => {
  const { err, data } = await connection.delete(`/locations/${locationId}`);

  if (err) {
    throw err;
  }

  return data;
};

const getCredential = async (locationId) => {
  const { err, data } = await connection.get(
    `/locations/${locationId}/credential`
  );

  if (err) {
    throw err;
  } else {
    return data;
  }
};

const saveCredential = async (locationId, credential) => {
  const { err, data } = await (credential.id
    ? connection.patch(`/locations/${locationId}/credential`, {
        credential,
      })
    : connection.post(`/locations/${locationId}/credential`, {
        credential,
      }));

  if (err) {
    throw err;
  }

  return data;
};

const getMeasurements = async (locationId) => {
  const { err, data } = await connection.get(
    `/locations/${locationId}/measurements`
  );

  if (err) {
    throw err;
  }

  return data;
};

const saveMeasurements = async (locationId, measurements) => {
  const responses = await Promise.all(
    measurements.map((measurement) =>
      measurement.id
        ? connection.patch(
            `/locations/${locationId}/measurements/${measurement.id}`,
            {
              measurement,
            }
          )
        : connection.post(`/locations/${locationId}/measurements`, {
            measurement,
          })
    )
  );

  const someErr = responses.find(({ err }) => !!err);
  if (someErr) {
    throw someErr;
  }

  return responses.map(({ data }) => data);
};

const getDashboard = async (locationId) => {
  const { err, data } = await connection.get(
    `/locations/${locationId}/dashboard`
  );

  if (err) {
    throw err;
  }

  return data;
};

const generateInvoices = async () => {
  const { err, data } = await connection.post(`/locations/generateInvoices`);

  if (err) {
    throw err;
  }

  return data;
};

const getOcr = async (locationId) => {
  const { err, data } = await connection.get(`/locations/${locationId}/ocr`);

  if (err) {
    throw err?.data || err;
  } else {
    return data;
  }
};

const getPricing = async (locationId) => {
  const { err, data } = await connection.get(
    `/locations/${locationId}/pricings`
  );

  if (err) {
    throw err;
  } else {
    return data;
  }
};

const savePricing = async (locationId, pricing) => {
  const payload = {
    te_liq: pricing.te_liq,
    tusd_liq: pricing.tusd_liq,
    charges_te: pricing.charges_te,
    icms: pricing.icms,
    pis: pricing.pis,
    cofins: pricing.cofins,
    discount: pricing.discount,
    discount_target: pricing.discount_target,
    volume: pricing.volume,
    volume_target: pricing.volume_target,
    location_id: locationId,
  };
  const { id } = pricing;
  const { err, data } = await (id
    ? connection.patch(`/locations/${locationId}/pricings/${id}`, {
        pricing: payload,
      })
    : connection.post(`/locations/${locationId}/pricings`, {
        pricing: payload,
      }));

  if (err) {
    throw err;
  }

  return data;
};

const createOrDeleteProviderLoginData = async (
  payload,
  actionDelete = false,
  location_id
) => {
  const { err, data } = actionDelete
    ? await connection.delete(`/location_credentials/${payload.id}`)
    : await connection.post(`/locations/${location_id}/credentials`, payload);

  if (err) {
    throw err;
  }

  return data;
};

const LocationsService = {
  getAll,
  get,
  create,
  update,
  remove,
  getCredential,
  getMeasurements,
  saveCredential,
  saveMeasurements,
  getDashboard,
  generateInvoices,
  getOcr,
  getPricing,
  savePricing,
  createOrDeleteProviderLoginData,
};

export default LocationsService;

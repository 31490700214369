/* eslint-disable no-unused-expressions */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { mask as MASK } from 'remask';

import {
  MdClose,
  MdHelpOutline,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
} from 'react-icons/md';

import { BiCheckCircle, BiErrorCircle } from 'react-icons/bi';

import { InputCustom } from '../../../components/InputCustom';
import { LoadingCustomModal } from '../../../components/Modals/LoadingCustomModal';
import { PrimaryButton } from '../../../components/PrimaryButton';
import { SecondaryButton } from '../../../components/SecondaryButton';
import { NewPageView } from '../../../containers/NewPageView';

import { Paragraph } from '../../../components/Interface/Text';
import { DistributorUserDataModal } from '../../../components/Modals/DistributorUserDataModal';
import { HelpCenterModal } from '../../../components/Modals/HelpCenterModal';
import { ThemeContext } from '../../../contexts/themeContext';
import { CONTRACT_STATUS_TYPES_PTBR } from '../../../helpers/contractStatusTypes';
import { completeMonthsArray, formatDate } from '../../../helpers/date';
import { setIsOverdue } from '../../../helpers/invoices';
import { getInvoiceStatusButton } from '../../../helpers/invoicesButtons';
import {
  URLS_PROVIDERS,
  providersCodeWithUrl,
} from '../../../helpers/providersURL';
import ContractsService from '../../../services/general/contracts.service';
import LocationsService from '../../../services/general/locations.service';
import PaymentMethodsService from '../../../services/general/paymentMethods.service';
import theme from '../../../theme';
import { formatCurrency } from '../NewHome/helpers';
import {
  AnchorButton,
  AnchorButtonModal,
  ButtonContainer,
  Card,
  CardContent,
  CardContent2,
  CardTitle,
  CardsContainer,
  CloseButton,
  Container,
  Content,
  ContentSignInData,
  DistributorContent,
  DistributorInfo,
  DistributorSeparator,
  DuedateCustom,
  Form,
  Header,
  HelpButton,
  HelpMessage,
  Label,
  PendenciesContent,
  PropertyStatus,
  RowContainer,
  Separator,
  SignInData,
  TableContainer,
  TitleContent,
  Tooltip,
  TooltipContainer,
  ValueInReais,
} from './styles';

const masks = {
  postalCode: '99999-999',
  cpfOrCnpj: ['999.999.999-99', '99.999.999/9999-99'],
};

import PropertiesImg from '../../../assets/images/icons/monochromatic-png/imoveis.png';

import { toast } from 'react-toastify';
import ProviderDataImg from '../../../assets/images/icons/monochromatic-png/cobranca simples e digital.png';
import ContractImg from '../../../assets/images/icons/monochromatic-png/dados cadastrais 2.png';
import navigatePaymentImg from '../../../assets/images/icons/monochromatic-png/faturas 2.png';
import PaymentDataImg from '../../../assets/images/icons/monochromatic-png/impacto financeiro.png';
import PropertyImg from '../../../assets/images/icons/monochromatic-png/inicio 1.png';
import PendenciesImg from '../../../assets/images/icons/monochromatic-png/pendencias 1.png';
import { PAYMENT_METHODS } from '../../../helpers/paymentMethods';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';
import { MethodPayment } from './methodPayment';
import { PaymentAddress } from './paymentAddress';

export const Property = () => {
  const location = useLocation();

  const { themeValue } = useContext(ThemeContext);
  const { propertyId } = useParams();
  const [data, setData] = useState({});
  const [expandIsActive, setExpandIsActive] = useState(false);
  const [openModalDistributorUserData, setOpenModalDistributorUserData] =
    useState(false);
  const [openModalHelpCenter, setOpenModalHelpCenter] = useState(false);
  const [isEditingImmobile, setIsEditingImmobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstThreeInvoices, setFirstThreeInvoices] = useState([]);
  const [hiddenInvoices, setHiddenInvoices] = useState([]);
  const [payload, setPayload] = useState({});
  const [unpaidOptions, setUnpaidOptions] = useState({});
  const [showDistributorTooltip, setShowDistributorTooltip] = useState(false);
  const [hasInfolocationCredential, setHasInfolocationCredential] =
    useState(false);
  const [hasInfolocationCredentialValid, setHasInfolocationCredentialValid] =
    useState(null);
  const {
    payment_method_id,
    nickname,
    email,
    method,
    fullname,
    postal_code,
    city,
    state,
    street,
    neighborhood,
    number,
    complement,
    contract_id,
    location_id,
    numberCard,
    nameCard,
    expiryCard,
    cvv,
    hasUpdatedAddress,
  } = payload;

  const handleNickname = (event) => {
    event.persist();

    setPayload((prevState) => ({
      ...prevState,
      nickname: { ...nickname, value: event.target.value },
    }));
  };

  const handleOpenModalDistributorUserData = useCallback(() => {
    setOpenModalDistributorUserData(true);
  }, []);

  const handleCloseModalDistributorUserData = useCallback(() => {
    setOpenModalDistributorUserData(false);
  }, []);

  const handleOpenModalHelpCenter = useCallback(() => {
    setOpenModalHelpCenter(true);
  }, []);

  const handleCloseModalHelpCenter = useCallback(() => {
    setOpenModalHelpCenter(false);
  }, []);

  const handleImmobileEditing = () => {
    setIsEditingImmobile(!isEditingImmobile);
  };

  const handleSubmitImmobile = async (event) => {
    event.preventDefault();

    const payloadSubmitted = {
      nickname: nickname.value,
    };

    try {
      setLoading(true);
      await LocationsService.update(data.location_id, payloadSubmitted);
      setData((prevState) => ({ ...prevState, nickname: nickname.value }));
      setIsEditingImmobile(false);
    } finally {
      setLoading(false);
    }
  };

  const handlingErrorPayment = (err) => {
    const { data: message } = err;

    switch (message) {
      case 'invalid card_number':
        setPayload((prevState) => ({
          ...prevState,
          numberCard: { ...numberCard, status: 'refused' },
        }));
        break;
      case 'invalid last_name':
        setPayload((prevState) => ({
          ...prevState,
          nameCard: { ...nameCard, status: 'refused' },
        }));
        break;
      case 'invalid expiration':
        setPayload((prevState) => ({
          ...prevState,
          expiryCard: { ...expiryCard, status: 'refused' },
        }));
        break;
      case 'invalid verification_value':
        setPayload((prevState) => ({
          ...prevState,
          cvv: { ...cvv, status: 'refused' },
        }));
        break;
      default:
    }
  };

  const removelocationCredential = () => {
    setHasInfolocationCredential(false);
    setHasInfolocationCredential(null);
  };

  const additionlocationCredential = (credential) => {
    setData((prevState) => ({ ...prevState, locationCredential: credential }));
    setHasInfolocationCredential(true);
    setHasInfolocationCredentialValid(
      credential?.is_valid_login && credential?.is_valid_install_code
    );
  };

  const handleSubmitPayment = async (event) => {
    event?.preventDefault();

    const targetName =
      method === PAYMENT_METHODS.Card && nameCard?.value
        ? nameCard?.value
        : fullname?.value;

    const [first_name] = targetName.split(' ');

    const last_name = targetName.split(first_name)[1].trim();

    const payloadSubmitted = {
      payment_method_id,
      email: email.value,
      method,
      first_name,
      last_name,
      postal_code: postal_code.value,
      city: city.value,
      state: state.value,
      street: street.value,
      neighborhood: neighborhood.value,
      number: number.value,
      complement: complement.value || '',
      card_number: numberCard?.value || '',
      expiration: expiryCard?.value || '',
      verification_value: cvv?.value || '',
      contract_id,
      location_id,
    };

    for (const key in payloadSubmitted)
      if (payloadSubmitted[key]?.length === 0) delete payloadSubmitted[key];

    try {
      setLoading(true);
      const newPaymentMethod = await PaymentMethodsService.create(
        payloadSubmitted
      );
      setData((prevState) => ({
        ...prevState,
        paymentMethod: newPaymentMethod,
      }));
      setPayload((prevState) => ({
        ...prevState,
        fullname: {
          ...prevState.fullname,
          value: first_name + ' ' + last_name,
        },
      }));
    } catch (err) {
      const { data, status } = err;
      if (status === 422) {
        //Erros iugu
        toast.error(data.message, { autoClose: false });
        toast.info(TOAST_MESSAGES.CONTACT_TEAM, {
          delay: 3000,
          autoClose: 8000,
        });
        throw err;
      } else {
        handlingErrorPayment(err);
        throw err;
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hasUpdatedAddress) {
      handleSubmitPayment();
      setPayload((prevState) => ({
        ...prevState,
        hasUpdatedAddress: false,
      }));
    }
  }, [hasUpdatedAddress]);

  const handleSubmitAddress = async (event, updatedData) => {
    event.preventDefault();

    const payloadSubmitted = {
      postal_code: updatedData.postal_code.value,
      city: updatedData.city.value,
      state: updatedData.state.value,
      street: updatedData.street.value,
      neighborhood: updatedData.neighborhood.value,
      number: updatedData.number.value,
      complement: updatedData.complement.value || 'NULL',
    };

    for (const key in payloadSubmitted)
      if (payloadSubmitted[key]?.length === 0) delete payloadSubmitted[key];

    try {
      setLoading(true);
      const newPaymentMethod = await PaymentMethodsService.updateAddress(
        contract_id,
        payloadSubmitted
      );
      setPayload((prevState) => ({
        ...prevState,
        postal_code: {
          ...updatedData.postalCode,
          value: newPaymentMethod.postal_code,
          status: 'accepted',
          disabled: true,
        },
        city: {
          ...updatedData.city,
          value: newPaymentMethod.city,
          disabled: true,
        },
        street: {
          ...updatedData.street,
          value: newPaymentMethod.street,
          disabled: true,
        },
        state: {
          ...updatedData.state,
          value: newPaymentMethod.state,
          disabled: true,
        },
        number: {
          ...updatedData.number,
          value: newPaymentMethod.number,
          disabled: true,
        },
        neighborhood: {
          ...updatedData.neighborhood,
          value: newPaymentMethod.neighborhood,
          disabled: true,
        },
        complement: {
          ...updatedData.complement,
          value: newPaymentMethod.complement,
          disabled: true,
        },
      }));
    } catch (err) {
      handlingErrorPayment(err);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleUnpaidInvoicePendency = useCallback((invoices) => {
    try {
      if (!invoices || !invoices.length) return;
      const unpaidInvoices = invoices.filter(
        ({ status, gift }) => status !== 'paid' && !gift
      );
      if (!unpaidInvoices.length) return;

      const { due_date, month } = unpaidInvoices[0];

      const monthString = completeMonthsArray[month - 1];
      setUnpaidOptions({ monthString, blockdate: formatDate(due_date) });
    } catch (error) {}
  }, []);

  const handleOpenDistributorTooltip = useCallback(() => {
    if (showDistributorTooltip) return;

    setShowDistributorTooltip(true);
  }, [showDistributorTooltip]);

  const handleCloseDistributorTooltip = useCallback(() => {
    setShowDistributorTooltip(false);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await ContractsService.getProperty(propertyId);
        setData(res);

        const {
          paymentMethod = {},
          invoices = [],
          nickname,
          id,
          location_id,
          locationCredential = {},
        } = res;

        Object.values(locationCredential).length
          ? setHasInfolocationCredential(true)
          : setHasInfolocationCredential(false);

        const { is_valid_install_code, is_valid_login } = locationCredential;
        if (is_valid_install_code === false || is_valid_login === false)
          setHasInfolocationCredentialValid(false);

        const { first_name, last_name } = paymentMethod;

        const fullnameValue =
          first_name && last_name ? first_name.concat(' ', last_name) : '';

        const obj = {
          payment_method_id: +paymentMethod.id,
          contract_id: +id,
          location_id,
          nickname: {
            value: nickname,
            status: nickname ? 'accepted' : 'refused',
          },
          email: {
            value: paymentMethod.email,
            status: paymentMethod.email ? 'accepted' : 'refused',
          },
          method: paymentMethod.method,
          fullname: {
            value: fullnameValue,
            status: fullnameValue ? 'accepted' : 'refused',
          },
          postal_code: {
            value: paymentMethod.postal_code,
            status: paymentMethod.postal_code ? 'accepted' : 'refused',
            disabled: true,
          },
          city: {
            value: paymentMethod.city || '',
            status: paymentMethod.city ? 'accepted' : 'refused',
            disabled: true,
          },
          state: {
            value: paymentMethod.state,
            status: paymentMethod.state ? 'accepted' : 'refused',
            disabled: true,
          },
          street: {
            value: paymentMethod.street,
            status: paymentMethod.street ? 'accepted' : 'refused',
            disabled: true,
          },
          neighborhood: {
            value: paymentMethod.neighborhood,
            status: paymentMethod.neighborhood ? 'accepted' : 'refused',
            disabled: true,
          },
          number: {
            value: paymentMethod.number,
            status: paymentMethod.number ? 'accepted' : 'refused',
            disabled: true,
          },
          complement: {
            value: paymentMethod.complement,
            status: paymentMethod.complement ? 'accepted' : 'refused',
            disabled: true,
          },
          numberCard: { value: '', status: 'awaiting', touched: false },
          nameCard: { value: '', status: 'awaiting', touched: false },
          expiryCard: { value: '', status: 'awaiting', touched: false },
          cvv: { value: '', status: 'awaiting', touched: false },
          focus: '',
          hasErrorCard: false,
        };
        const invoicesCopy = [...invoices];
        handleUnpaidInvoicePendency(invoicesCopy);

        setPayload(obj);
        setFirstThreeInvoices(invoices.splice(-3).reverse());

        if (location.state === 'isUserExperience') {
          setOpenModalDistributorUserData(true);
        }

        if (invoices.length < 3) return;
        setHiddenInvoices(invoices.reverse());
      } finally {
        setLoading(false);
      }
    })();
  }, [propertyId]);

  const { blockdate, monthString } = unpaidOptions;

  return (
    <NewPageView>
      <DistributorUserDataModal
        isOpen={openModalDistributorUserData}
        onRequestClose={handleCloseModalDistributorUserData}
        provider={data?.provider}
        data={data?.locationCredential}
        hasCredentials={hasInfolocationCredential}
        removeCredentials={removelocationCredential}
        additionCredentials={additionlocationCredential}
        location_id={Number(location_id)}
      />
      <HelpCenterModal
        isOpen={openModalHelpCenter}
        onRequestClose={handleCloseModalHelpCenter}
        data={data}
      />

      <Container themeValue={themeValue}>
        <Content>
          <TitleContent>
            <img
              src={PropertiesImg}
              style={{ width: '3.5rem', height: '3.5rem' }}
            />
            <h1>{data?.nickname}</h1>
          </TitleContent>

          <Separator />

          <CardsContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.5rem',
              }}
            >
              {!!firstThreeInvoices.length && blockdate && (
                <Card style={{ background: theme.palette.green.main }}>
                  <CardTitle>
                    <img
                      src={PendenciesImg}
                      style={{ width: '2rem', height: '2rem' }}
                    />
                    <h2 style={{ color: theme.palette.primary.main }}>
                      Pendências
                    </h2>
                  </CardTitle>

                  <PendenciesContent>
                    <p>
                      A fatura referente ao mês de {monthString} está em aberto.
                    </p>
                    <p>
                      Caso não pague até a data {blockdate} sua conta será
                      bloqueada.
                    </p>
                  </PendenciesContent>
                </Card>
              )}

              <Card>
                <CardTitle>
                  <img
                    src={ContractImg}
                    style={{ width: '2rem', height: '2rem' }}
                  />
                  <h2>Contrato</h2>
                </CardTitle>

                <CardContent2>
                  <RowContainer>
                    <span>Status do imóvel:</span>
                    <PropertyStatus status={data?.status}>
                      {CONTRACT_STATUS_TYPES_PTBR[data?.status]}
                    </PropertyStatus>
                  </RowContainer>
                  <InputCustom
                    type='text'
                    name='nameOrSocialReason'
                    labelText='Nome / Razão Social'
                    value={data?.holder || ''}
                    disabled
                  />
                  <InputCustom
                    type='tel'
                    name='cpfOrCnpj'
                    labelText='CPF / CNPJ'
                    value={MASK(data?.document || '', masks.cpfOrCnpj)}
                    disabled
                  />
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: '1.125rem',
                      color: '#1F1B1E',
                    }}
                  >
                    Informações do imóvel.
                  </span>
                  <InputCustom
                    type='tel'
                    name='postalCodeImmutable'
                    labelText='CEP'
                    value={MASK(data?.postal_code || '', masks.postalCode)}
                    disabled
                  />

                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      gridGap: '1rem',
                    }}
                  >
                    <InputCustom
                      type='text'
                      name='cityImmutable'
                      labelText='Cidade'
                      value={data?.city || ''}
                      disabled
                    />
                    <InputCustom
                      type='text'
                      name='stateImmutable'
                      labelText='Estado'
                      value={data?.state || ''}
                      disabled
                    />
                    <InputCustom
                      type='text'
                      name='streetImmutable'
                      labelText='Endereço'
                      value={data?.street || ''}
                      disabled
                    />
                    <InputCustom
                      type='text'
                      name='neighborhoodImmutable'
                      labelText='Bairro'
                      value={data?.neighborhood || ''}
                      disabled
                    />
                    <InputCustom
                      type='tel'
                      name='numberImmutable'
                      labelText='Número'
                      value={data?.number || ''}
                      disabled
                    />
                    <InputCustom
                      type='text'
                      name='complementImmutable'
                      labelText='Complemento'
                      value={data?.complement || ''}
                      disabled
                    />
                  </div>
                  {/* <span
                    style={{
                      fontWeight: 400,
                      fontSize: '1.125rem',
                      color: '#1F1B1E',
                    }}
                  >
                    Termo de adesão:
                  </span> */}
                  {/* <ContractButtons>
                    <PrimaryButton>Visualizar</PrimaryButton>
                    <a
                      href={testeIMG}
                      download
                    >
                      <SecondaryButton>Baixar</SecondaryButton>
                    </a>
                  </ContractButtons> */}
                  {/* aparecer somente quando botão editar for clicado */}
                </CardContent2>
              </Card>

              <Card>
                <CardTitle>
                  <img
                    src={navigatePaymentImg}
                    style={{ width: '2rem', height: '2rem' }}
                  />
                  <h2>Histórico de pagamento</h2>
                </CardTitle>

                <TableContainer>
                  <thead>
                    <tr>
                      <th>Vencimento</th>
                      <th>Valor (R$)</th>
                      <th>Flora</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!firstThreeInvoices.length && (
                      <>
                        {firstThreeInvoices.map((invoice) => {
                          const isOverdue = setIsOverdue(invoice);
                          Object.assign(invoice, { isOverdue });

                          return (
                            <tr key={invoice.key}>
                              {invoice?.gift === true ? (
                                <td
                                  style={{
                                    color: '#21B31E',
                                    fontWeight: 500,
                                    fontSize: '1.125',
                                  }}
                                >
                                  -
                                </td>
                              ) : (
                                <DuedateCustom isOverdue={!!isOverdue}>
                                  {formatDate(invoice?.due_date)}
                                </DuedateCustom>
                              )}

                              <ValueInReais isOverdue={!!isOverdue}>
                                {formatCurrency(
                                  invoice?.value_cents / 100,
                                  false,
                                  true
                                )}
                              </ValueInReais>

                              <td>{getInvoiceStatusButton(invoice)}</td>
                            </tr>
                          );
                        })}
                      </>
                    )}

                    {!!hiddenInvoices.length && expandIsActive && (
                      <>
                        {hiddenInvoices.map((invoice) => {
                          const isOverdue = setIsOverdue(invoice);
                          Object.assign(invoice, { isOverdue });

                          return (
                            <tr key={invoice.key}>
                              {invoice?.gift === true ? (
                                <td
                                  style={{
                                    color: '#21B31E',
                                    fontWeight: 500,
                                    fontSize: '1.125',
                                  }}
                                >
                                  -
                                </td>
                              ) : (
                                <DuedateCustom isOverdue={!!isOverdue}>
                                  {formatDate(invoice?.due_date)}
                                </DuedateCustom>
                              )}

                              <ValueInReais isOverdue={!!isOverdue}>
                                {formatCurrency(
                                  invoice?.value_cents / 100,
                                  false,
                                  true
                                )}
                              </ValueInReais>

                              <td>{getInvoiceStatusButton(invoice)}</td>
                            </tr>
                          );
                        })}
                      </>
                    )}
                  </tbody>
                </TableContainer>

                {!firstThreeInvoices.length && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '1rem 0 0 0',
                      fontWeight: 300,
                      fontSize: '1rem',
                      color: '#000000',
                    }}
                  >
                    Imóvel ainda não possui nenhuma fatura
                  </div>
                )}

                {!!hiddenInvoices?.length && (
                  <ButtonContainer>
                    <PrimaryButton
                      onClick={() => setExpandIsActive(!expandIsActive)}
                    >
                      {expandIsActive ? (
                        <>
                          <MdKeyboardArrowUp size='1.5rem' />
                          retrair
                        </>
                      ) : (
                        <>
                          <MdKeyboardArrowDown size='1.5rem' />
                          expandir
                        </>
                      )}
                    </PrimaryButton>
                  </ButtonContainer>
                )}
              </Card>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.5rem',
              }}
            >
              <Card>
                <Form onSubmit={handleSubmitImmobile}>
                  <CardContent>
                    <CardTitle>
                      <img
                        src={PropertyImg}
                        style={{ width: '2rem', height: '2rem' }}
                      />
                      <h2>Imóvel</h2>
                    </CardTitle>

                    {isEditingImmobile ? (
                      <PrimaryButton
                        type='submit'
                        disabled={!nickname?.value?.length}
                      >
                        Salvar
                      </PrimaryButton>
                    ) : (
                      <SecondaryButton onClick={handleImmobileEditing}>
                        Editar
                      </SecondaryButton>
                    )}
                  </CardContent>

                  <CardContent2>
                    <p>
                      É possível atribuir um nome ao imóvel, facilitando a sua
                      identificação.
                    </p>

                    <InputCustom
                      type='text'
                      name='propertyName'
                      labelText='Nome do imóvel'
                      value={nickname?.value || ''}
                      onChange={handleNickname}
                      hasTouched
                      disabled={!isEditingImmobile}
                    />
                  </CardContent2>
                </Form>
              </Card>

              <Card>
                <Form onSubmit={handleSubmitPayment}>
                  <CardContent>
                    <CardTitle>
                      <img
                        src={PaymentDataImg}
                        style={{ width: '2rem', height: '2rem' }}
                      />
                      <h2>Dados do pagamento</h2>
                    </CardTitle>
                  </CardContent>

                  <CardContent2>
                    <MethodPayment
                      payload={payload}
                      setPayload={setPayload}
                      handleSubmitPayment={handleSubmitPayment}
                      data={data}
                    />
                    <PaymentAddress
                      payload={payload}
                      setPayload={setPayload}
                      handleSubmitAddress={handleSubmitAddress}
                    />
                  </CardContent2>
                </Form>
              </Card>

              <Card>
                <CardTitle>
                  <img
                    src={ProviderDataImg}
                    style={{ width: '2rem', height: '2rem' }}
                  />
                  <h2>Minha distribuidora</h2>
                </CardTitle>

                <DistributorContent>
                  <DistributorInfo>
                    <AnchorButton
                      href={URLS_PROVIDERS[data?.provider]}
                      target='_blank'
                      disabled={!URLS_PROVIDERS[data?.provider]}
                    >
                      Site da {data?.provider_name || 'distribuidora'}
                    </AnchorButton>
                  </DistributorInfo>

                  <DistributorSeparator />

                  <SignInData>
                    <ContentSignInData>
                      <Label>Dados de login</Label>

                      {showDistributorTooltip && (
                        <TooltipContainer>
                          <Tooltip>
                            <Header>
                              <h6>Por que a Flora precisa desses dados?</h6>
                              <CloseButton
                                onClick={handleCloseDistributorTooltip}
                              >
                                <MdClose
                                  size='1.5rem'
                                  color={theme.palette.primary.main}
                                />
                              </CloseButton>
                            </Header>
                            <p>
                              Para emitir sua fatura Flora, precisamos da sua
                              conta de luz. Como o mercado em que estamos
                              inseridos é novo, as distribuidoras estão se
                              adaptando às novas dinâmicas e podem atrasar
                              alguns envios. Ao informar seus dados, teremos
                              acesso garantido à sua conta e você à fatura
                              Flora.
                            </p>
                            {providersCodeWithUrl.includes(data?.provider) && (
                              <HelpMessage>
                                Precisa de ajuda para conseguir esses dados?{' '}
                                <AnchorButtonModal
                                  onClick={handleOpenModalHelpCenter}
                                >
                                  Clique aqui
                                </AnchorButtonModal>
                              </HelpMessage>
                            )}
                          </Tooltip>
                        </TooltipContainer>
                      )}

                      <HelpButton onClick={handleOpenDistributorTooltip}>
                        <MdHelpOutline
                          size='1.5rem'
                          color={theme.palette.primary.main}
                        />
                      </HelpButton>
                    </ContentSignInData>

                    <Paragraph>
                      Com sua ajuda podemos maximizar sua econonomia e
                      experiência Flora!
                    </Paragraph>

                    {!!hasInfolocationCredential &&
                      !(hasInfolocationCredentialValid === false) && (
                        <p
                          style={{
                            margin: 0,
                            fontWeight: 300,
                            fontSize: '1rem',
                            background: '#BCE8BB',
                            color: '#5E6A5D',
                            padding: 10,
                            borderRadius: 10,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <BiCheckCircle
                            style={{ marginRight: 10, fontSize: 20 }}
                          />
                          Dados informados!
                        </p>
                      )}
                    {hasInfolocationCredentialValid === false && (
                      <p
                        style={{
                          margin: 0,
                          fontWeight: 300,
                          fontSize: '1rem',
                          background: '#E8BDBB',
                          color: '#B3261E',
                          padding: 10,
                          borderRadius: 10,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <BiErrorCircle
                          style={{ marginRight: 10, fontSize: 20 }}
                        />
                        Dados informados são inválidos!
                      </p>
                    )}
                  </SignInData>

                  <PrimaryButton onClick={handleOpenModalDistributorUserData}>
                    Meus dados
                  </PrimaryButton>
                </DistributorContent>
              </Card>
            </div>
          </CardsContainer>
        </Content>
      </Container>
      <LoadingCustomModal isLoading={loading} />
    </NewPageView>
  );
};

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { unMask as UNMASK, mask as MASK } from 'remask';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import { InputCustom } from '../../../components/InputCustom';
import { Radiobox } from '../../../components/Radiobox';
import { PrimaryButton } from '../../../components/PrimaryButton';
import { SecondaryButton } from '../../../components/SecondaryButton';
import { LoadingCustomModal } from '../../../components/Modals/LoadingCustomModal';

import { NewPageView } from '../../../containers/NewPageView';

import {
  Card,
  CardContent,
  CardsContainer,
  CardsPendingAndRegistrationData,
  CardTitle,
  Container,
  Content,
  Form,
  HiddenExpandContainer,
  ImportantMessage,
  RadioboxContainer,
  RadioboxContent,
  Separator,
  TitleContent,
} from './styles';
import { useAuthStore } from '../../../store';
import UsersService from '../../../services/general/users.service';

import {
  genresSelectItems,
  hasChildrenSelectItems,
  householdIncomeSelectItems,
  maritalStatusOptions,
  metTheCompanySelectItems,
  reasonToHireCompanySelectItems,
  schoolingSelectItems,
} from './helpers';
import {
  birthDateValidation,
  validationData,
  validationEmail,
  validationName,
} from '../../../helpers/form/validations';
import { onlyString } from '../../../helpers/form/formatter';
import { validateCpfOrCnpj } from '../../../utils/validateCpfOrCnpj';
import { NewDropdown } from '../../../components/NewDropdown';
import { formatDate } from '../../../helpers/date';
import { ThemeContext } from '../../../contexts/themeContext';

const masks = {
  cpf: '999.999.999-99',
  phone: '(99) 9 9999-9999',
  date: '99/99/9999',
  children: '99',
};

import ProfileImg from '../../../assets/images/icons/monochromatic-png/perfil.png';
import HowIAmImg from '../../../assets/images/icons/monochromatic-png/quem sou.png';
import RegistrationDataImg from '../../../assets/images/icons/monochromatic-png/dados cadastrais 2.png';

import PendenciesImg from '../../../assets/images/icons/monochromatic-png/pendencias 1.png';
import theme from '../../../theme';

export const Profile = () => {
  const { themeValue } = useContext(ThemeContext);
  const userId = useAuthStore((state) => state.userAuth?.id);
  const [expandIsActive, setExpandIsActive] = useState(false);
  const [isEditingRegistration, setIsEditingRegistration] = useState(false);
  const [isEditingAboutYou, setIsEditingAboutYou] = useState(false);
  const [hasCustomerResearchPendency, setHasCustomerResearchPendency] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});

  const {
    id,
    created_at,
    promocode,
    email,
    emailToChange,
    name,
    cpf,
    phone_number,
    birthdate,
    genre,
    maritalStatus,
    children,
    quantityChildren,
    schooling,
    profession,
    householdIncome,
    metTheCompany,
    metTheCompanyOther,
    reasonToHireCompany,
    reasonToHireCompanyOther,
  } = payload;

  const mountPayload = useCallback((data) => {
    const {
      id,
      promocode,
      created_at: registrationDate,
      email: emailValue,
      name: nameValue,
      cpf: cpfValue,
      phone_number: phoneValue,
      birthdate: birthValue,
      sex,
      marital_status,
      sons,
      education_level,
      education_level_complete,
      occupation,
      family_income,
      found_about_us,
      hiring_reason,
    } = data;
    let { email_to_change: emailToChange } = data;

    if (emailToChange === emailValue) emailToChange = null;

    setPayload({
      id,
      created_at: registrationDate,
      promocode,
      email: { value: emailValue, status: 'accepted' },
      name: { value: nameValue, status: 'accepted' },
      cpf: {
        value: cpfValue ? MASK(cpfValue, masks.cpf) : '',
        status: cpfValue?.length ? 'accepted' : 'awaiting',
        touched: !!cpfValue?.length,
        hasInitialValue: !!cpfValue?.length,
      },
      phone_number: { value: MASK(phoneValue, masks.phone) },
      birthdate: {
        value: birthValue ? formatDate(birthValue) : '',
        status: birthValue ? 'accepted' : 'awaiting',
        touched: !!birthValue,
      },
      genre: { type: sex },
      children: { type: +sons >= 1 ? 'yes' : 'no' },
      quantityChildren: { value: sons },
      metTheCompany: { type: found_about_us },
      metTheCompanyOther: { value: '' },
      reasonToHireCompany: { type: hiring_reason },
      reasonToHireCompanyOther: { value: '' },
      maritalStatus: marital_status,
      householdIncome: family_income,
      profession: {
        value: occupation,
        status: occupation ? 'accepted' : 'awaiting',
      },
      schooling: {
        school: education_level,
        complete: education_level_complete,
      },
      emailToChange,
    });
  }, []);

  const handleRegistrationEditing = () => {
    setIsEditingRegistration(!isEditingRegistration);
  };

  const handleAboutYouEditing = () => {
    setIsEditingAboutYou(!isEditingAboutYou);
    setExpandIsActive(true);
  };

  const handleEmail = (event) => {
    event.persist();
    const isEmailValid = validationEmail(event.target.value);

    const status = isEmailValid ? 'accepted' : 'refused';

    setPayload((prevState) => ({
      ...prevState,
      email: { value: event.target.value, status },
    }));
  };

  const handleName = (event) => {
    event.persist();

    let status = 'accepted';

    const isNameValid = validationName(onlyString(event.target.value));

    if (!isNameValid) {
      status = 'refused';
    }

    setPayload((prevState) => ({
      ...prevState,
      name: { value: onlyString(event.target.value), status },
    }));
  };

  const handleCpf = (event) => {
    event.persist();
    let status = 'accepted';

    const isCpfValid =
      UNMASK(event.target.value).length >= 11 &&
      validateCpfOrCnpj(UNMASK(event.target.value));

    if (!isCpfValid) {
      status = 'refused';
    }

    setPayload((prevState) => ({
      ...prevState,
      cpf: {
        ...cpf,
        value: MASK(event.target.value, masks.cpf),
        status,
      },
    }));
  };

  const blurCpf = () => {
    if (!cpf?.value?.length) {
      setPayload((prevState) => ({
        ...prevState,
        cpf: {
          ...cpf,
          touched: true,
          status: 'refused',
        },
      }));

      return;
    }

    setPayload((prevState) => ({
      ...prevState,
      cpf: { ...cpf, touched: true },
    }));
  };

  const handlePhone = (event) => {
    event.persist();
    let status = 'accepted';

    const isPhoneNumberValid = UNMASK(event.target.value).length >= 11;

    if (!isPhoneNumberValid) {
      status = 'refused';
    }

    setPayload((prevState) => ({
      ...prevState,
      phone_number: {
        ...phone_number,
        value: MASK(event.target.value, masks.phone),
        status,
      },
    }));
  };

  const handleBirthDate = (event) => {
    event.persist();
    let status = 'accepted';

    const isBirthDateValid = birthDateValidation(event.target.value);

    if (!isBirthDateValid) {
      status = 'refused';
    }

    setPayload((prevState) => ({
      ...prevState,
      birthdate: {
        ...birthdate,
        value: MASK(event.target.value, masks.date),
        status,
      },
    }));
  };

  const blurBirthdate = () => {
    setPayload((prevState) => ({
      ...prevState,
      birthdate: { ...birthdate, touched: true },
    }));
  };

  const handleGenre = (event) => {
    event.persist();

    setPayload((prevState) => ({
      ...prevState,
      genre: { type: event.target.value },
    }));
  };

  const handleMaritalStatus = (option) => {
    setPayload((prevState) => ({ ...prevState, maritalStatus: option.value }));
  };

  const handleChildren = (event) => {
    event.persist();

    if (children.type === 'yes' && event.target.value === 'no') {
      setPayload((prevState) => ({
        ...prevState,
        children: { type: event.target.value },
        quantityChildren: { value: '' },
      }));
      return;
    }

    setPayload((prevState) => ({
      ...prevState,
      children: { type: event.target.value },
    }));
  };

  const handleQuantityChildren = (event) => {
    event.persist();

    setPayload((prevState) => ({
      ...prevState,
      quantityChildren: {
        ...quantityChildren,
        value: MASK(event.target.value, masks.children),
      },
    }));
  };

  const handleSchooling = (option) => {
    setPayload((prevState) => ({ ...prevState, schooling: option.value }));
  };

  const handleProfession = (event) => {
    event.persist();
    let status = 'accepted';

    const isComplementValid = validationData(event.target.value);

    if (!isComplementValid) {
      status = 'refused';
    }

    setPayload((prevState) => ({
      ...prevState,
      profession: {
        ...profession,
        value: onlyString(event.target.value),
        status,
      },
    }));
  };

  const blurProfession = () => {
    setPayload((prevState) => ({
      ...prevState,
      profession: { ...profession, touched: true },
    }));
  };

  const handleHouseholdIncome = (option) => {
    setPayload((prevState) => ({
      ...prevState,
      householdIncome: option.value,
    }));
  };

  const handleMetTheCompany = (event) => {
    event.persist();
    setPayload((prevState) => ({
      ...prevState,
      metTheCompany: { type: event.target.value },
    }));
  };

  const handleMetTheCompanyOther = (event) => {
    event.persist();
    setPayload((prevState) => ({
      ...prevState,
      metTheCompanyOther: { value: onlyString(event.target.value) },
    }));
  };

  const handleReasonToHireCompany = (event) => {
    event.persist();
    setPayload((prevState) => ({
      ...prevState,
      reasonToHireCompany: { type: event.target.value },
    }));
  };

  const handleToHireCompanyOther = (event) => {
    event.persist();
    setPayload((prevState) => ({
      ...prevState,
      reasonToHireCompanyOther: { value: onlyString(event.target.value) },
    }));
  };

  const handleSubmitRegistration = async (event) => {
    event.preventDefault();

    const payloadSubmitted = { email: email.value };

    try {
      setLoading(true);
      const newUser = await UsersService.profile(id, payloadSubmitted);
      const { email, email_to_change } = newUser;
      if (email_to_change)
        setPayload((prevState) => ({
          ...prevState,
          email: { value: email, status: 'accepted' },
          emailToChange: email_to_change,
        }));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }

    setIsEditingRegistration(false);
  };

  const handleSubmitAboutYou = async (event) => {
    event.preventDefault();

    const payloadSubmitted = {
      name: name.value,
      cpf: UNMASK(cpf.value),
      phone_number: UNMASK(phone_number.value),
      birthdate: birthdate.value,
      sex: genre.type,
      marital_status: maritalStatus,
      sons: children.type === 'yes' ? +quantityChildren?.value : 0,
      education_level: schooling?.school,
      education_level_complete: schooling?.complete,
      occupation: profession?.value,
      family_income: householdIncome,
      found_about_us:
        metTheCompany?.type === 'other'
          ? metTheCompanyOther?.value
          : metTheCompany?.type,
      hiring_reason:
        reasonToHireCompany?.type === 'other'
          ? reasonToHireCompanyOther?.value
          : reasonToHireCompany?.type,
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const key in payloadSubmitted)
      if (payloadSubmitted[key]?.length === 0) delete payloadSubmitted[key];

    try {
      setLoading(true);
      const { customerResearchDone } = await UsersService.profile(
        id,
        payloadSubmitted
      );

      setHasCustomerResearchPendency(!customerResearchDone);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }

    setIsEditingAboutYou(false);
    setExpandIsActive(false);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { customerResearchDone, ...res } = await UsersService.profile();
        setHasCustomerResearchPendency(!customerResearchDone);

        mountPayload(res);
      } catch (err) {
        // setHasError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [userId, mountPayload]);

  return (
    <NewPageView>
      <LoadingCustomModal isLoading={loading} />
      <Container themeValue={themeValue}>
        <Content>
          <TitleContent>
            <img
              src={ProfileImg}
              style={{ width: '3.5rem', height: '3.5rem' }}
            />
            <h1>Perfil</h1>
          </TitleContent>

          <Separator />

          <CardsContainer>
            <CardsPendingAndRegistrationData>
              {hasCustomerResearchPendency && (
                <Card
                  style={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.primary.main,
                  }}
                >
                  <CardContent>
                    <CardTitle isPendency>
                      <img
                        src={PendenciesImg}
                        style={{ width: '2rem', height: '2rem' }}
                      />
                      <h2>Pendências</h2>
                    </CardTitle>
                  </CardContent>

                  <p>
                    Atualize seu perfil, preencha os dados em{' '}
                    <span>quem sou</span> clicando em &apos;editar&apos;.
                  </p>
                </Card>
              )}

              <Card>
                <Form onSubmit={handleSubmitRegistration}>
                  <CardContent>
                    <CardTitle>
                      <img
                        src={RegistrationDataImg}
                        style={{ width: '2rem', height: '2rem' }}
                      />
                      <h2>Dados cadastrais</h2>
                    </CardTitle>
                    {isEditingRegistration ? (
                      <PrimaryButton
                        type='submit'
                        disabled={email.status === 'refused'}
                      >
                        Salvar
                      </PrimaryButton>
                    ) : (
                      <SecondaryButton onClick={handleRegistrationEditing}>
                        Editar
                      </SecondaryButton>
                    )}
                  </CardContent>

                  <InputCustom
                    type='text'
                    name='registrationData'
                    labelText='Data de cadastro'
                    value={formatDate(created_at)}
                    hasTouched
                    noIcon
                    disabled
                  />

                  <InputCustom
                    type='email'
                    name='email'
                    labelText='E-mail de login'
                    value={email?.value || ''}
                    onChange={handleEmail}
                    hasTouched
                    hasError={email?.status === 'refused'}
                    errorMessage='Por favor digite um email válido'
                    requiredMessage='Email é obrigatório'
                    noIcon={!isEditingRegistration}
                    disabled={!isEditingRegistration}
                  />

                  {emailToChange && (
                    <InputCustom
                      type='email_to_change'
                      name='email_to_change'
                      labelText='E-mail a confirmar'
                      value={emailToChange}
                      noIcon={!isEditingRegistration}
                      disabled
                    />
                  )}

                  {!!promocode?.length && (
                    <InputCustom
                      type='text'
                      name='promocode'
                      labelText='Código promocional'
                      value={promocode || ''}
                      hasTouched
                      noIcon
                      disabled
                    />
                  )}

                  {isEditingRegistration && (
                    <ImportantMessage>
                      <p>
                        <strong>IMPORTANTE!</strong> Ao alterar seu e-mail de
                        login, será necessária uma confirmação por meio do novo
                        endereço de e-mail. A alteração só será concluída após
                        essa confirmação, garantindo seu acesso à plataforma
                        Flora.
                      </p>
                      <p>
                        Lembre-se de que o e-mail de alteração pode levar até 5
                        minutos para ser enviado. Por favor, verifique também
                        sua pasta de spam.
                      </p>
                    </ImportantMessage>
                  )}
                </Form>
              </Card>
            </CardsPendingAndRegistrationData>

            <div style={{ display: 'block' }}>
              <Card>
                <Form
                  onSubmit={handleSubmitAboutYou}
                  style={{ marginBottom: '24px' }}
                >
                  <CardContent>
                    <CardTitle>
                      <img
                        src={HowIAmImg}
                        style={{ width: '2rem', height: '2rem' }}
                      />
                      <h2>Quem sou</h2>
                    </CardTitle>

                    {isEditingAboutYou ? (
                      <PrimaryButton
                        type='submit'
                        disabled={
                          name?.status === 'refused' ||
                          cpf?.status === 'refused' ||
                          phone_number?.status === 'refused'
                        }
                      >
                        Salvar
                      </PrimaryButton>
                    ) : (
                      <SecondaryButton onClick={handleAboutYouEditing}>
                        Editar
                      </SecondaryButton>
                    )}
                  </CardContent>

                  <InputCustom
                    type='text'
                    name='fullname'
                    labelText='Nome completo *'
                    value={name?.value || ''}
                    onChange={handleName}
                    hasError={name?.status === 'refused'}
                    hasTouched
                    errorMessage='Por favor digite um nome completo'
                    requiredMessage='Nome é obrigatório'
                    disabled={!isEditingAboutYou}
                  />

                  <InputCustom
                    type='tel'
                    name='cpf'
                    labelText='CPF'
                    value={cpf?.value || ''}
                    onChange={handleCpf}
                    onBlur={blurCpf}
                    hasError={cpf?.status === 'refused'}
                    hasTouched={cpf?.touched}
                    errorMessage='Por favor digite um CPF válido'
                    requiredMessage='CPF é obrigatório'
                    disabled={!isEditingAboutYou || cpf?.hasInitialValue}
                  />

                  <InputCustom
                    type='tel'
                    name='phone_number'
                    labelText='Celular *'
                    value={phone_number?.value || ''}
                    onChange={handlePhone}
                    hasError={phone_number?.status === 'refused'}
                    hasTouched
                    errorMessage='Por favor digite um celular válido'
                    requiredMessage='Celular é obrigatório'
                    disabled={!isEditingAboutYou}
                  />

                  <InputCustom
                    type='text'
                    name='birthDate'
                    labelText='Data de nascimento'
                    value={birthdate?.value || ''}
                    onChange={handleBirthDate}
                    onBlur={blurBirthdate}
                    hasError={birthdate?.status === 'refused'}
                    hasTouched={birthdate?.touched}
                    errorMessage='Por favor digite uma data válida'
                    maxLength={10}
                    disabled={!isEditingAboutYou}
                  />

                  <RadioboxContainer>
                    <p>O gênero que me identifico é:</p>
                    <RadioboxContent>
                      <Radiobox
                        name='genres'
                        options={genresSelectItems}
                        checked={genre?.type || ''}
                        onChange={handleGenre}
                        disabled={!isEditingAboutYou}
                      />
                    </RadioboxContent>
                  </RadioboxContainer>

                  {!loading && (
                    <HiddenExpandContainer isActive={expandIsActive}>
                      <NewDropdown
                        title='Estado civil'
                        selected={{ value: payload?.maritalStatus }}
                        options={maritalStatusOptions}
                        onChange={handleMaritalStatus}
                        disabled={!isEditingAboutYou}
                      />

                      <RadioboxContainer>
                        <p>Possuo filhos:</p>
                        <RadioboxContent>
                          <Radiobox
                            name='children'
                            options={hasChildrenSelectItems}
                            checked={children?.type || ''}
                            onChange={handleChildren}
                            disabled={!isEditingAboutYou}
                          />
                        </RadioboxContent>

                        {children?.type === 'yes' && (
                          <div style={{ marginTop: '1rem' }}>
                            <InputCustom
                              type='tel'
                              name='quantityChildren'
                              labelText='Quantos?'
                              value={quantityChildren?.value || ''}
                              onChange={handleQuantityChildren}
                              maxLength={2}
                              disabled={!isEditingAboutYou}
                            />
                          </div>
                        )}
                      </RadioboxContainer>

                      <NewDropdown
                        title='Escolaridade'
                        selected={{ value: payload?.schooling }}
                        options={schoolingSelectItems}
                        onChange={handleSchooling}
                        disabled={!isEditingAboutYou}
                      />

                      <InputCustom
                        type='text'
                        name='profession'
                        labelText='Profissão'
                        value={profession?.value || ''}
                        onChange={handleProfession}
                        onBlur={blurProfession}
                        hasTouched={profession?.touched}
                        disabled={!isEditingAboutYou}
                      />

                      <NewDropdown
                        title='Renda familiar'
                        options={householdIncomeSelectItems}
                        selected={{ value: payload?.householdIncome }}
                        onChange={handleHouseholdIncome}
                        disabled={!isEditingAboutYou}
                      />

                      <RadioboxContainer>
                        <p>Como conheci a Flora Energia:</p>
                        <span>
                          <Radiobox
                            name='metTheCompany'
                            options={metTheCompanySelectItems}
                            checked={metTheCompany?.type || ''}
                            onChange={handleMetTheCompany}
                            disabled={!isEditingAboutYou}
                          />
                        </span>

                        {metTheCompany?.type === 'other' && (
                          <InputCustom
                            type='text'
                            name='metTheCompanyOther'
                            value={metTheCompanyOther?.value || ''}
                            onChange={handleMetTheCompanyOther}
                          />
                        )}
                      </RadioboxContainer>

                      <RadioboxContainer>
                        <p>Motivo de contratar a Flora Energia:</p>
                        <span>
                          <Radiobox
                            name='reasonToHireCompany'
                            options={reasonToHireCompanySelectItems}
                            checked={reasonToHireCompany?.type || ''}
                            onChange={handleReasonToHireCompany}
                            disabled={!isEditingAboutYou}
                          />
                        </span>

                        {reasonToHireCompany?.type === 'other' && (
                          <InputCustom
                            type='text'
                            name='reasonToHireCompanyOther'
                            value={reasonToHireCompanyOther?.value || ''}
                            onChange={handleToHireCompanyOther}
                          />
                        )}
                      </RadioboxContainer>
                    </HiddenExpandContainer>
                  )}

                  <PrimaryButton
                    onClick={() => setExpandIsActive(!expandIsActive)}
                  >
                    {expandIsActive ? (
                      <>
                        <MdKeyboardArrowUp size='24px' />
                        retrair
                      </>
                    ) : (
                      <>
                        <MdKeyboardArrowDown size='24px' />
                        expandir
                      </>
                    )}
                  </PrimaryButton>
                </Form>
              </Card>
            </div>
          </CardsContainer>
        </Content>
      </Container>
    </NewPageView>
  );
};

import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

const GenericForm = ({
  onSubmit = undefined,
  initialValues = undefined,
  values = undefined,
  schema,
  children,
  ...props
}) => {
  return (
    <Formik
      {...props}
      initialValues={{ ...initialValues, ...values }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {children}
    </Formik>
  );
};

GenericForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.oneOfType([PropTypes.object]),
  values: PropTypes.oneOfType([PropTypes.object]),
  schema: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
};

export default GenericForm;

import React from 'react';
import { Modal, CircularProgress } from '@mui/material';
import GradientButton from '../../GradientButton';
import {
  modalContainerStyle,
  modalContentStyle,
  titleStyle,
  progressStyle,
  buttonContainerStyle,
} from './style';
import { Heading2 } from '../../Interface/Text';
import theme from '../../../theme';

const ConfirmModal = ({
  open,
  onClose,
  onConfirm,
  isLoading,
  title,
  description,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
    >
      <div style={modalContainerStyle}>
        <div style={modalContentStyle}>
          <Heading2>{title}</Heading2>
          {isLoading && <CircularProgress style={progressStyle} />}
          {description && <h5>{description}</h5>}
          <div style={buttonContainerStyle}>
            <GradientButton
              paddingg='10px 30px'
              text='Reemitir Contrato'
              handleClick={onConfirm}
              disabled={isLoading}
            />
            <GradientButton
              background='white'
              color={theme.palette.primary.main}
              border={`1px solid ${theme.palette.primary.main}`}
              paddingg='10px 30px'
              text='Cancelar'
              handleClick={onClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;

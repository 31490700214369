import React, { useState, useEffect, useRef } from 'react';

import { useNavigate, useLocation, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import AuthService from '../../../services/authentication/auth.service';

import {
  isUserAuthenticated,
  removeAuthData,
  useAuthStore,
} from '../../../store';

import { NewPageView } from '../../../containers/NewPageView';
import { InputCustom } from '../../../components/InputCustom';
import { PrimaryButton } from '../../../components/PrimaryButton';

import LogoImg from '../../../assets/images/logo/logo-flora.svg';
import {
  AnchorContainer,
  Card,
  Container,
  Content,
  FormContainer,
} from './styles';

import { validationEmail } from '../../../helpers/form/validations';
import { LoadingCustomModal } from '../../../components/Modals/LoadingCustomModal';
import { redirectToInitialPage } from '../../../helpers/loginHelpers';

export const SignIn = () => {
  const role = useAuthStore((state) => state.userAuth?.role);
  const navigate = useNavigate();
  const location = useLocation();
  const [showInputPassword, setShowInputPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [disabledSignInButton, setDisabledSignInButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const emailInputRef = useRef(null);
  const passwordRef = useRef(null);
  const [urlToken, setUrlToken] = useState(undefined);
  const [rediretToRegister, setRediretToRegister] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setUrlToken(params.get('token'));
  }, [location.search]);

  const [isAuthenticated, setIsAuthenticaded] = useState(false);
  useEffect(() => {
    const isAuth = isUserAuthenticated();
    setIsAuthenticaded(isAuth);
  }, []);

  const [hasRedirect, setHasRedirect] = useState(false);

  useEffect(() => {
    let mounted = true;
    const checkAuth = async () => {
      if (mounted) {
        if (urlToken) {
          try {
            await AuthService.validatePasswordReset(urlToken);
            removeAuthData();
            toast.success('Digite seu email para definir sua senha');
          } catch (e) {
            console.log(e);
            toast.error(
              'Houve um erro desconhecido, tente novamente, ou entre em contato conosco via WhatsApp'
            );
            navigate('/login');
          }
        } else if (isUserAuthenticated()) {
          // eslint-disable-next-line no-use-before-define
          setHasRedirect(true);
        } else if (window.localStorage.getItem('floraenergia:auth')) {
          try {
            const { token } = JSON.parse(
              localStorage.getItem('floraenergia:auth')
            );
          } catch {
            localStorage.removeItem('floraenergia:auth');
          }
        }
      }
    };

    //token deve ser null (caso não tenha parametro token na url)
    //ou com o valor especificado na url
    if (urlToken !== undefined) checkAuth();

    return () => (mounted = false);
  }, [urlToken]);

  useEffect(() => {
    try {
      emailInputRef?.current?.focus();
    } catch (err) {
      console.log(err);
    }
  }, [emailInputRef]);

  useEffect(() => {
    if (isAuthenticated) setHasRedirect(true);
  }, [isAuthenticated]);

  useEffect(() => {
    if (validationEmail(email) && !showInputPassword) {
      setDisabledSignInButton(false);
      return;
    }

    if (password.length >= 8) {
      setDisabledSignInButton(false);
      return;
    }

    setDisabledSignInButton(true);
  }, [email, password, showInputPassword]);

  useEffect(() => {
    const redirect = async () => {
      const { pathname } = location;
      await redirectToInitialPage(navigate, role, rediretToRegister, pathname);
    };
    if (hasRedirect) {
      redirect();
    }
  }, [hasRedirect, role]);

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setHasRedirect(false);

    try {
      setLoading(true);
      setRediretToRegister(true);
      await AuthService.login(email, password, true);
      setHasRedirect(true);
    } catch (err) {
      try {
        const { status } = err;
        if (status === 401) {
          setShowInputPassword(false);
          setPassword('');

          return toast.error('Email não cadastrado');
        } else if (status === 406) {
          setShowInputPassword(true);
          passwordRef?.current?.focus();

          if (showInputPassword) return toast.error('Senha incorreta');
        } else if (status === 408)
          return toast.error(
            'Estamos passando por melhorias :) tente novamente, ou entre em contato conosco via WhatsApp'
          );
        else if (status === 430)
          return toast.error(
            'Para sua segurança antes de definir sua senha será necessário seguir as instruções que enviamos em seu email. Você deve clicar no botão para a redefinição. Após clicar no botão insira seu email novamente e poderá definir sua senha'
          );
        else if (status === 431) {
          removeAuthData();
          toast.success(
            'Para sua segurança devido ao tempo de inatividade estamos enviando um email para que possa definir sua senha'
          );
          setTimeout(() => {
            toast.success(
              'Após seguir suas instruções, digite o email cadastrado na tela do login e poderá definir sua senha'
            );
          }, 2500);
        } else if (status === 425) {
          navigate(`/senha-expirada?email=${email}`);
        } else return;
      } catch (error) {
        toast.error(
          'Houve um erro desconhecido, tente novamente, ou entre em contato conosco via WhatsApp'
        );
        throw err;
      }
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <NewPageView>
      <LoadingCustomModal isLoading={loading} />
      <Container>
        <Card>
          <Content>
            <a href='https://floraenergia.com.br/'>
              <img
                src={LogoImg}
                alt='Logo flora'
              />
            </a>
            <h2>Bem-vindo(a)!</h2>

            <FormContainer onSubmit={handleLogin}>
              <InputCustom
                type='email'
                name='email'
                labelText='E-mail'
                value={email}
                onChange={handleEmail}
                ref={emailInputRef}
              />

              {showInputPassword && (
                <InputCustom
                  type='password'
                  name='password'
                  labelText='Senha'
                  value={password}
                  onChange={handlePassword}
                  ref={passwordRef}
                />
              )}

              <PrimaryButton
                type='submit'
                disabled={disabledSignInButton}
              >
                {showInputPassword ? 'Entrar' : 'Avançar'}
              </PrimaryButton>

              <AnchorContainer>
                <Link to='/cadastro'>Criar minha conta</Link>
                <Link to='/esqueci-minha-senha'>Esqueci minha senha</Link>
              </AnchorContainer>
            </FormContainer>
          </Content>
        </Card>
      </Container>
    </NewPageView>
  );
};

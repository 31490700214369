import { useGlobalStore } from '../../global.store';
import { objectStores } from './configurations';
import adminStores from './index.store';

const snakeToCamelCase = (str) =>
  str.replace(/(_\w)/g, (match) => match[1].toUpperCase());

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);

const objectTables = objectStores.map((el) => el + 's');

export const updateAdminStore = (data) => {
  const stores = adminStores();
  const { setUpdatePage } = useGlobalStore.getState();
  for (const table in data) {
    const camelCaseTable = snakeToCamelCase(table);

    const isObjectTable = objectTables.includes(camelCaseTable);
    const storeName = isObjectTable
      ? camelCaseTable.slice(0, -1)
      : camelCaseTable;

    if (!stores[storeName]) continue;

    const store = stores[storeName];
    const capitalizedTable = storeName[0].toUpperCase() + storeName.slice(1);

    const { [`set${capitalizedTable}`]: setFunction } = store;
    const payloadData = data[table];

    setFunction(payloadData);
  }
  setUpdatePage();
};

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContentCard from '../../../components/ContentCard';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { Paragraph } from '../../../components/Interface/Text';
import PageView from '../../../containers/PageView';
import ProducersService from '../../../services/administrator/producers.service';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';
import { toast } from 'react-toastify';
import GradientButton from '../../../components/GradientButton';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import CompaniesService from '../../../services/general/companies.service';
import CompaniesForm from '../../../components/Forms/CompaniesForm';
import { useAuthStore } from '../../../store';
import { StyledLink } from '../../../components/Interface';
import StyleguidesService from '../../../services/administrator/styleguides.service';

const COLUMNS = [
  { name: 'Id' },
  { name: 'Razão Social' },
  { name: 'Nome Fantasia', align: 'left' },
  { name: 'CNPJ', align: 'left' },
  { name: 'Endereço', align: 'left' },
  { name: 'Telefone', align: 'left' },
  { name: 'Email', align: 'left' },
];

function AdminCompanies() {
  const navigate = useNavigate();
  const userId = useAuthStore((state) => state.userAuth?.id);
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    companies: [],
  });
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [companyToEdit, setCompanyToEdit] = useState(null);
  const [loadingg, setLoadingg] = useState(false);
  const [reload, setReload] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setCompanyToEdit(null);
    setOpen2(false);
  };

  const handleEditCompany = async (id) => {
    try {
      const companyToEdit = await companies.find((el) => {
        return el.id === id;
      });

      setCompanyToEdit(companyToEdit);
      handleOpen2();
    } catch (error) {
      console.log(error);
    }
  };

  const getRows = (companies) => {
    return companies.map(
      (
        { id, name, mock_name, document, address, phone_number, email },
        index
      ) => ({
        values: [
          {
            value: (
              <StyledLink
                to={`/companies/${id}`}
                tag={true}
              >
                {id || index + 1}
              </StyledLink>
            ),
          },
          {
            value: (
              <Inner style={{ display: 'flex', alignItems: 'center' }}>
                <Paragraph>{name}</Paragraph>
                <EditIcon
                  id='editIcon'
                  onClick={() => handleEditCompany(id)}
                />
              </Inner>
            ),
          },
          {
            value: <Paragraph>{mock_name}</Paragraph>,
            align: 'center',
          },
          {
            value: <Paragraph>{document || 'NaN'}</Paragraph>,
            align: 'center',
          },
          {
            value: <Paragraph>{address}</Paragraph>,
            align: 'center',
          },
          {
            value: <Paragraph>{phone_number || 'NaN'} </Paragraph>,
            align: 'center',
          },
          {
            value: <Paragraph>{email}</Paragraph>,
            align: 'center',
          },
        ],
      })
    );
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const resources = await CompaniesService.getAll();
        setPageState((state) => ({
          ...state,
          loading: false,
          companies: resources,
        }));
      } catch (error) {
        console.log(error);
        setPageState((state) => ({
          ...state,
          loading: false,
          hasError: true,
        }));
      }
    };

    fetchCompanies();
  }, [reload]);

  const onSubmit = async (data, { setSubmitting }) => {
    try {
      setLoadingg(true);
      let req;

      if (!companyToEdit) {
        const temp = await StyleguidesService.create();
        req = await CompaniesService.create({
          ...data,
          styleguide_id: temp.id,
          userId,
        });
      } else {
        req = await CompaniesService.updateOne(companyToEdit.id, {
          ...data,
          userId,
        });
      }
      if (req?.error) {
        throw new Error('Erro');
      }
      setLoadingg(false);
      setReload(!reload);
    } catch (err) {
      setLoadingg(false);
      console.log(err);
      toast.error(TOAST_MESSAGES.ERROR);
      setReload(!reload);
    } finally {
      handleClose();
      handleClose2();
    }
  };

  const { loading, hasError, companies } = pageState;

  return (
    <PageView
      title='Empresas'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Section>
        <GradientButton
          text=''
          type='button'
          handleClick={handleOpen}
        >
          Nova Empresa
        </GradientButton>
      </Section>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <ContentCard>
            <SearchableTable
              columns={COLUMNS}
              rowsValues={getRows(companies)}
              noItemsText='Nenhuma Empresa'
              dataIsComplete
            />
          </ContentCard>
        </Grid>
      </Grid>
      <div>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Fade in={open}>
              <ModalDiv>
                <CompaniesForm
                  onSubmit={onSubmit}
                  handleClose={handleClose}
                  companies={companies}
                  loadingg={loadingg}
                  setLoadingg={setLoadingg}
                  setReload={setReload}
                  reload={reload}
                />
              </ModalDiv>
            </Fade>
          </div>
        </Modal>

        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={open2}
          onClose={handleClose2}
          closeAfterTransition
        >
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Fade in={open2}>
              <ModalDiv>
                <CompaniesForm
                  onSubmit={onSubmit}
                  handleClose={handleClose2}
                  companyToEdit={companyToEdit}
                  loadingg={loadingg}
                  setLoadingg={setLoadingg}
                  setReload={setReload}
                  reload={reload}
                />
              </ModalDiv>
            </Fade>
          </div>
        </Modal>
      </div>
    </PageView>
  );
}

export default AdminCompanies;

const Inner = styled.div`
  #editIcon {
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
`;

const Section = styled.section`
  display: flex;
  justify-content: flex-end;
  button {
    :hover {
      opacity: 0.7;
    }
  }
`;

const ModalDiv = styled.div`
  background-color: white;
  padding: 3rem;
  border-radius: 2rem;
`;

import styled, { css } from 'styled-components';

const isEqual = css`
  display: grid;
  grid-template-columns: 1fr 1fr;

  grid-gap: 1.25rem;
`;

export const Container = styled.div`
  min-height: 100%;
  background: #fffbfe;

  ${(props) =>
    props.isNewPropertyOrHolder &&
    css`
      border-radius: 0 0 8px 8px;
      padding: 0 1.5rem;

      @media (min-width: 992px) {
        padding: 0;
      }
    `}
`;

export const Content = styled.div`
  margin: 0 3rem;

  span {
    font-weight: 400;
    font-size: 1rem;
    color: #000000;
  }

  a {
    color: #d89025;
    font-weight: 500;
  }
`;

export const Title = styled.p`
  margin: 3rem 0 2.25rem 0;
  color: #1c1b1f;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
`;

export const ContInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  margin-bottom: 1rem;
`;

export const ContAddress = styled.div`
  ${(props) => props.isEqual && isEqual}
`;

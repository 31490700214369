import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import moment from 'moment';

import Button from '../../components/Button';
import { H1, H2, StyledLink } from '../../components/Interface';
import Loader from '../../components/Loader';
import { colors } from '../../styles/colors';
import { Paragraph } from '../../components/Interface/Text/Paragraph';
import { ButtonSubmit } from '../../pages/Admin/BillingManual/styles';
import theme from '../../theme';
import { LoadingCustomModal } from '../../components/Modals/LoadingCustomModal';

const BackLink = styled(StyledLink)`
  display: block;
  font-size: 20px;
  font-family: 'Myriad-Pro-Condensed';
  letter-spacing: 0.085em;
  margin-bottom: 15px;
`;

const BasePageView = ({
  className,
  title = undefined,
  subtitle = undefined,
  children = undefined,
  loading = false,
  hasError = false,
  goBackText = undefined,
  goBackLink = undefined,
  refreshData = undefined,
  lastUpdate = undefined,
}) => {
  const [requestActive, setRequestActive] = useState(false);

  const handleRefreshData = async () => {
    try {
      if (requestActive) return;
      if (typeof refreshData !== 'function') return;
      setRequestActive(true);
      toast.info('Atualizando dados...');
      await refreshData();
    } catch (error) {
      console.log('file: index.js || line 39 || error', error);
    } finally {
      setRequestActive(false);
    }
  };

  const navigate = useNavigate();

  return (
    <div className={className}>
      {!hasError && loading && <LoadingCustomModal isLoading={loading} />}
      {!loading && (
        <>
          {!hasError ? (
            <div className='page-view-content'>
              {goBackLink && (
                <BackLink to={goBackLink}>{goBackText || 'Voltar'}</BackLink>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                {title && (
                  <div className='page-title'>
                    <H1>{title}</H1>
                    <Paragraph
                      size={20}
                      color={colors.textGray}
                    >
                      {subtitle}
                    </Paragraph>
                  </div>
                )}
                {refreshData && (
                  <div
                    style={{
                      height: '50%',
                      marginTop: '1rem',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {lastUpdate && typeof lastUpdate === 'number' && (
                      <Paragraph
                        size={20}
                        color={colors.textGray}
                        style={{ marginRight: '1rem' }}
                      >
                        Ultima atualização:{' '}
                        {moment(lastUpdate).format('DD/MM/YYYY, HH:mm')}
                      </Paragraph>
                    )}
                    <ButtonSubmit
                      onClick={() => handleRefreshData()}
                      disabled={requestActive}
                    >
                      Atualizar Dados
                    </ButtonSubmit>
                  </div>
                )}
              </div>
              {children}
            </div>
          ) : (
            <Grid
              className='page-view-error'
              container
              direction='column'
              alignItems='center'
            >
              <Grid item>
                <H2>
                  Desculpe, houve um erro durante o carregamento da página.
                  Tente novamente mais tarde.
                </H2>
              </Grid>
              <Grid item>
                <Button
                  text='Voltar para o início'
                  handleClick={() => navigate('/home')}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

BasePageView.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  hasError: PropTypes.bool,
  goBackLink: PropTypes.string,
  goBackText: PropTypes.string,
  refreshData: PropTypes.func,
  lastUpdate: PropTypes.number,
};

const PageView = styled(BasePageView)`
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  padding-bottom: 60px;

  .page-title {
    margin-bottom: 20px;
    ${H1} {
      @media screen and (max-width: 1024px) {
        text-align: center;
      }
      color: ${theme.palette.primary.main};
    }

    ${Paragraph} {
      letter-spacing: 0.085em;

      @media screen and (max-width: 1024px) {
        text-align: center;
      }
    }
  }

  .page-view {
    &-content {
      padding: ${(props) => (props.isMobile ? '0.2rem' : '1.5rem')};
      padding-top: 0px;
    }
    &-error {
      ${H2} {
        margin-top: 50px;
      }

      ${Button} {
        margin-top: 10px;
      }
    }
  }
`;

export default PageView;

const optionsToReturn = ['year', 'month', 'day', 'hour', 'minute', 'second'];

export const ExcelDateToJSDate = (serial, valuesToReturn) => {
  if (valuesToReturn && !Array.isArray(valuesToReturn))
    throw new Error(
      'valuesToReturn should be in: ' + optionsToReturn.join(', ')
    );

  const utc_days = Math.floor(serial - 25568);
  const utc_value = utc_days * 86400;
  const date_info = new Date(utc_value * 1000);

  const fractional_day = serial - Math.floor(serial) + 0.0000001;

  let total_seconds = Math.floor(86400 * fractional_day);

  const second = total_seconds % 60;

  total_seconds -= second;

  const hour = Math.floor(total_seconds / (60 * 60));
  const minute = Math.floor(total_seconds / 60) % 60;
  const year = date_info.getFullYear();
  const month = date_info.getMonth() + 1;
  const day = date_info.getDate();

  if (!valuesToReturn)
    return new Date(year, month - 1, day, hour, minute, second);

  const finalObject = {};
  const dateObject = { year, month, day, hour, minute, second };

  for (const key of valuesToReturn) finalObject[key] = dateObject[key];

  return finalObject;
};

import styled from 'styled-components';

export const Container = styled.div`
  max-width: 480px;
  padding: 1.5rem;
`;

export const Header = styled.h6`
  font-weight: 700;
  font-size: 1.5rem;
  color: #1f1b1e;
  text-align: center;
  margin: 0;
`;

export const DistributorOptions = styled.div`
  display: flex;
  gap: 0.625rem;

  a {
    width: 100%;
  }

  button {
    padding: 0.25rem 0;
    font-size: 1rem;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;

  p {
    margin: 0;
    font-weight: 300;
    font-size: 18px;
    color: #1f1b1e;
  }

  p + p {
    margin-top: 0.5rem;
  }
`;

export const ButtonContainer = styled.div`
  button {
    padding: 0.75rem 0;
    font-size: 1.25rem;
  }
`;

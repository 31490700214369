import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TableChartIcon from '@mui/icons-material/TableChart';
import PageView from '../../../containers/PageView';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { StyledLink } from '../../../components/Interface';
import { Paragraph } from '../../../components/Interface/Text/Paragraph';
import ContentCard from '../../../components/ContentCard';
import {
  PROVIDERS_TYPES,
  PROVIDERS_TYPES_LABEL,
} from '../../../helpers/providersTypes';
import {
  PROFILE_TYPES,
  PROFILE_TYPES_LABEL,
} from '../../../helpers/profileTypes';

import ProposalsService from '../../../services/administrator/proposals.service';
import LoadMore from '../../../components/LoadMore';

const COLUMNS = [
  { name: 'Usuário', align: 'left' },
  { name: 'Nome', align: 'left' },
  { name: 'Contrato', align: 'center' },
  { name: 'Celular' },
  { name: 'Volume' },
  { name: '* Rateio(%)' },
  { name: 'Distribuidora' },
  { name: 'Gerador' },
  { name: 'Conta de Luz Válida?' },
  { name: 'Validação Documento' },
];

export const formatCpfCpnj = (val) => {
  val = val.toString();
  if (val.length === 11) {
    return val
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  } else {
    return val.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  }
};

const clickSignValidation = (signerDocument = '', document = '') => {
  if (!signerDocument) return 'CPF de signatário não cadastrado';
  if (!document) return 'CPF do contratante não cadastrado';
  if (document.length === 14) return 'CNPJ';
  if (signerDocument === document) return 'Sim';
  if (signerDocument !== document) {
    return `CPF S ${formatCpfCpnj(signerDocument)} CPF C ${formatCpfCpnj(
      document
    )}`;
  }
};

const getRows = (proposals) => {
  return proposals.map((proposal) => {
    const {
      user_id,
      name,
      id,
      phone_number,
      user_phone_number,
      volume,
      share,
      provider,
      producer,
      profile,
      attachment_valid,
      signer_document,
      document,
    } = proposal;
    return {
      values: [
        {
          value: (
            <StyledLink
              tag='true'
              to={`/usuarios/${user_id}`}
            >
              {user_id}
            </StyledLink>
          ),
        },
        {
          value: <StyledLink to={`/usuarios/${user_id}`}>{name}</StyledLink>,
        },
        {
          value: (
            <StyledLink
              tag='true'
              to={`/contratos/${id}`}
            >
              {id}
            </StyledLink>
          ),
        },
        {
          value: <Paragraph>{phone_number || user_phone_number}</Paragraph>,
        },
        {
          value: (
            <Paragraph>{`${parseFloat(volume).toLocaleString(
              'pt-BR'
            )} kWh`}</Paragraph>
          ),
        },
        {
          value: (
            <Paragraph>
              {parseFloat(parseFloat(share).toFixed(2)).toLocaleString('pt-BR')}
            </Paragraph>
          ),
        },
        {
          value: (
            <Paragraph>
              {PROVIDERS_TYPES_LABEL[PROVIDERS_TYPES[provider]] ||
                PROFILE_TYPES_LABEL[PROFILE_TYPES[profile]]}
            </Paragraph>
          ),
        },
        {
          value: <Paragraph>{producer}</Paragraph>,
        },
        {
          value: <Paragraph>{attachment_valid ? 'Sim' : 'Não'}</Paragraph>,
        },
        {
          value: (
            <Paragraph>
              {clickSignValidation(signer_document, document)}
            </Paragraph>
          ),
        },
      ],
    };
  });
};

const AdminProposals = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    proposals: [],
  });
  const [page, setPage] = useState(0);
  const [loadingg, setLoadingg] = useState(false);
  const [noMore, setNoMore] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  useEffect(() => {
    const fetchProposals = async () => {
      try {
        setLoadingg(true);
        const resources = await ProposalsService.getAll(page);

        return resources;
      } catch (err) {
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };

    fetchProposals().then((resources) => {
      if (!resources || (resources.length === 0 && page === 0)) {
        setPageState((state) => ({
          ...state,
          loading: false,
          proposals: [],
        }));
      }
      if (resources?.length === 0 || !resources) {
        setNoMore(true);
        return;
      } else {
        setNoMore(false);
      }
      setPageState((state) => ({
        ...state,
        loading: false,
        proposals: [...state.proposals, ...resources],
      }));
      setLoadingg(false);
    });
  }, [page]);

  const fetchAll = async () => {
    const contractss = await ProposalsService.getAll(0, 100000);
    return getRows(contractss);
  };

  const { loading, hasError, proposals } = pageState;

  return (
    <PageView
      title='Refino Propostas'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <ContentCard>
            <SearchableTable
              columns={COLUMNS}
              rowsValues={getRows(proposals)}
              noItemsText='Nenhuma proposta'
              fetchAll={fetchAll}
              loadingg={exportLoading}
            >
              <span>Export</span>
              <TableChartIcon />
            </SearchableTable>
          </ContentCard>
          {!noMore && (
            <LoadMore
              loadingg={loadingg}
              setPage={setPage}
            />
          )}
        </Grid>
      </Grid>
    </PageView>
  );
};

export default AdminProposals;

import { toast } from 'react-toastify';
import defaultConnectionResponse from './default';
import { TOAST_MESSAGES } from '../../../../helpers/toastMessages';

const userAxiosConnectionResponse = (onRejected) => {
  defaultConnectionResponse(onRejected, false);
  const { response } = onRejected;
  const { status, request } = response;

  switch (status) {
    case 403:
      toast.error(TOAST_MESSAGES.UNAUTHORIZED_CLIENT, { autoClose: 3000 });
      toast.info(TOAST_MESSAGES.CONTACT_TEAM, {
        delay: 4000,
        autoClose: 8000,
      });
      break;
    case 400:
      toast.error(TOAST_MESSAGES.ERROR_DEFAULT_CLIENT, { autoClose: 3000 });
      toast.info(TOAST_MESSAGES.CONTACT_TEAM, {
        delay: 4000,
        autoClose: 8000,
      });
      break;
    default:
  }

  return { err: response };
};

export default userAxiosConnectionResponse;

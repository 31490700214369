import { toast } from 'react-toastify';
import defaultConnectionResponse from './default';
import { TOAST_MESSAGES } from '../../../../helpers/toastMessages';

const s3RefreshDataAxiosConnectionResponse = (onRejected) => {
  defaultConnectionResponse(onRejected);
  const { response } = onRejected;

  const { status } = response;

  toast.success('Dados renovados com sucesso');
  console.log('Dados renovados com sucesso');
  window.location.reload();

  switch (status) {
    case 403:
      toast.error(TOAST_MESSAGES.UNAUTHORIZED, { autoClose: 3000 });
      break;
    default:
  }

  return { err: response };
};

export default s3RefreshDataAxiosConnectionResponse;

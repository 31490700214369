import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

import {
  Container,
  IconWrapper,
  ItemLink,
  MenuContainer,
  MenuItemContainer,
  Name,
  ToggleButton,
} from './styles';

export const DropdownCard = ({
  title = '',
  options,
  onChange,
  selected = false,
  disabled = false,
}) => {
  const [dropdownState, setDropdownState] = useState('closed');
  const [optionSelected, setOptionSelected] = useState('');
  const dropdownRef = useRef(null);
  const titleRef = useRef(null);

  const handleOpenDropdown = () => {
    setDropdownState('open');
  };

  const handleCloseDropdown = () => {
    setDropdownState('closed');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
        return;
      }
      setDropdownState('closed');
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  useEffect(() => {
    if (!options?.length) return;

    const option = options.find(({ label }) => label === optionSelected);

    if (!option) return;

    setOptionSelected(option.label);
  }, [options, optionSelected]);

  useEffect(() => {
    if (selected && options.length && !optionSelected) {
      onChange(options[0]);
      setOptionSelected(options[0].label);
    }
  }, [selected, options, onChange]);

  useEffect(() => {
    if (optionSelected.length && title.length) {
      titleRef.current.style.position = 'absolute';
      titleRef.current.style.top = '0';
      titleRef.current.style.fontSize = '14px';
      titleRef.current.style.fontWeight = '400';
      titleRef.current.style.color = '#605D62';
      titleRef.current.style.transition = '0.2s ease';
      titleRef.current.style.paddingLeft = '16px';
    }
  }, [optionSelected, title.length]);

  const toggleMenu = () => {
    if (dropdownState === 'closed') return handleOpenDropdown();

    handleCloseDropdown();
  };

  const handleSelect = (option) => {
    setOptionSelected(option.label);
    onChange(option);
    setDropdownState('closed');
  };

  return (
    <Container
      ref={dropdownRef}
      disabled={disabled}
    >
      {!!title.length && (
        <Name
          disabled={disabled}
          ref={titleRef}
        >
          {title}
        </Name>
      )}

      <ToggleButton
        onClick={toggleMenu}
        noTitle={!title}
      >
        <Name>{optionSelected}</Name>

        <IconWrapper>
          {dropdownState === 'closed' ? (
            <MdArrowDropDown size='24px' />
          ) : (
            <MdArrowDropUp size='24px' />
          )}
        </IconWrapper>
      </ToggleButton>

      {dropdownState === 'open' && (
        <MenuContainer>
          {options.map((option) => (
            <MenuItemContainer
              onClick={() => handleSelect(option)}
              key={option.key}
            >
              <ItemLink>{option.label}</ItemLink>
            </MenuItemContainer>
          ))}
        </MenuContainer>
      )}
    </Container>
  );
};

DropdownCard.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.any,
  disabled: PropTypes.bool,
};

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Form } from 'formik';
import { Grid } from '@mui/material';

import styled from 'styled-components';
import { unMask } from 'remask';
import GenericForm from '../Forms/GenericForm';
import {
  FieldWrapper,
  Label,
  CustomField,
  CustomErrorMessage,
} from '../Interface/Form';
import CompanyFormSchema from './schema';
import { FormPropTypes, FormDefaultProps } from '../Forms/types';
import { formMasks } from '../../helpers/form/masks';
import { SIGNER_TYPES_LABEL } from '../../helpers/signerTypes';
import GradientButton from '../GradientButton';
import { Radiobox } from '../Radiobox';
import { signerOptionsRadioBox } from '../../pages/Public/NewRegister/helpers';
import theme from '../../theme';

const initialValues = {};

const CompanyForm = ({ values, onSubmit, isEditing, onCancel }) => {
  const [typeSelected, setTypeSelected] = useState(values.type);

  useEffect(() => {
    values.phone_number = values?.phone_number || '';
  }, [values.phone_number]);

  useEffect(() => {
    values.holder = values?.holder || '';
  }, [values.holder]);

  useEffect(() => {
    values.document = values?.document || '';
  }, [values.document]);

  const handleType = (event, setValues, updatedValues) => {
    const {
      target: { value },
    } = event;

    setValues({ ...updatedValues, type: value });
    setTypeSelected(value);
  };

  const submit = (data) => {
    Object.assign(data, {
      document: unMask(data.document),
    });
    onSubmit(data);
  };

  return (
    <GenericForm
      enableReinitialize
      initialValues={initialValues}
      values={values}
      schema={CompanyFormSchema}
      onSubmit={submit}
    >
      {({ handleSubmit, resetForm, values: updatedValues, setValues }) => {
        return (
          <Form
            onKeyDown={(e) => {
              console.log('teste');
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <Grid
              container={true}
              spacing={1}
              rowSpacing={1}
            >
              {!isEditing && (
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <FieldWrapper>
                    <Label
                      htmlFor='holder'
                      style={{}}
                    >
                      Nome/Razão Social:
                    </Label>
                    <CustomField
                      name='holder'
                      disabled={!isEditing}
                      style={{ color: 'gray' }}
                      value={values?.holder || ''}
                      fullwidth={1}
                    />
                    <CustomErrorMessage name='holder' />
                  </FieldWrapper>
                </Grid>
              )}

              {!isEditing && (
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <FieldWrapper>
                    <Label
                      htmlFor='document'
                      style={{}}
                    >
                      CPF/CNPJ:
                    </Label>
                    <CustomField
                      name='document'
                      mask={formMasks.cpfOrCnpj}
                      disabled={!isEditing}
                      style={{
                        color: 'gray',
                        maxWidth: 'fit-content',
                      }}
                      fullwidth={1}
                    />
                    <CustomErrorMessage name='document' />
                  </FieldWrapper>
                </Grid>
              )}
              {!isEditing && (
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <FieldWrapper>
                    <Label
                      htmlFor='email'
                      style={{}}
                    >
                      Email:
                    </Label>
                    <CustomField
                      name='email'
                      disabled={true}
                      style={{
                        color: 'gray',
                      }}
                      fullwidth={1}
                    />
                    <CustomErrorMessage name='email' />
                  </FieldWrapper>
                </Grid>
              )}
              {!isEditing && (
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <FieldWrapper>
                    <Label
                      htmlFor='phone_number'
                      style={{}}
                    >
                      Telefone:
                    </Label>
                    <CustomField
                      name='phone_number'
                      disabled={true}
                      style={{
                        color: 'gray',
                      }}
                      value={values?.phone_number || '-'}
                      fullwidth={1}
                    />
                    <CustomErrorMessage name='phone_number' />
                  </FieldWrapper>
                </Grid>
              )}
              {isEditing && (
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <FieldWrapper>
                    <Label
                      htmlFor='email'
                      style={{}}
                    >
                      Email:
                    </Label>
                    <CustomField
                      name='email'
                      disabled={!isEditing}
                      style={{
                        color: 'gray',
                      }}
                      fullwidth={1}
                    />
                    <CustomErrorMessage name='email' />
                  </FieldWrapper>
                </Grid>
              )}

              {!isEditing ? (
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <FieldWrapper>
                    <Label
                      htmlFor='type'
                      style={{}}
                    >
                      Canal de Assinatura:
                    </Label>
                    <CustomField
                      name='type'
                      disabled={true}
                      style={{
                        color: 'gray',
                      }}
                      fullwidth={1}
                      value={SIGNER_TYPES_LABEL[values?.type] || 'Email'}
                    />
                    <CustomErrorMessage name='type' />
                  </FieldWrapper>
                </Grid>
              ) : (
                <Grid item>
                  <Label style={{}}>
                    Informe o melhor meio de contato do titular para assinatura.
                  </Label>
                  <RadioboxContainer>
                    <Radiobox
                      name='type'
                      checked={typeSelected}
                      options={signerOptionsRadioBox}
                      onChange={(e) => handleType(e, setValues, updatedValues)}
                    />
                  </RadioboxContainer>
                </Grid>
              )}
              {isEditing &&
              (typeSelected === 'sms' || typeSelected === 'whatsapp') ? (
                <Grid
                  item
                  xs={12}
                  lg={6}
                >
                  <FieldWrapper>
                    <Label
                      htmlFor='phone_number'
                      style={{}}
                    >
                      Telefone:
                    </Label>
                    <CustomField
                      name='phone_number'
                      disabled={!isEditing}
                      style={{
                        color: 'gray',
                      }}
                      fullwidth={1}
                      onChange={(e) =>
                        setValues({
                          ...updatedValues,
                          phone_number: e.target.value,
                        })
                      }
                      mask={formMasks.phone}
                    />
                    <CustomErrorMessage name='phone_number' />
                  </FieldWrapper>
                </Grid>
              ) : null}
              <Grid
                item
                xs={12}
              >
                <BtnsCont>
                  {isEditing && (
                    <GradientButton
                      background='white'
                      color={theme.palette.primary.main}
                      border={`1px solid ${theme.palette.primary.main}`}
                      text='Cancelar'
                      paddingg={'10px 30px'}
                      handleClick={() => onCancel(resetForm)}
                      outlined
                      rectangle
                    />
                  )}
                  {isEditing && (
                    <GradientButton
                      paddingg={'10px 30px'}
                      text='Definir'
                      handleClick={handleSubmit}
                      rectangle
                      marginLeft={24}
                    />
                  )}
                </BtnsCont>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </GenericForm>
  );
};

CompanyForm.propTypes = FormPropTypes;
CompanyForm.defaultProps = FormDefaultProps;

export const RadioboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

const BtnsCont = styled.div`
  display: flex;
`;

export default CompanyForm;

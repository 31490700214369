import styled, { css } from 'styled-components';
import theme from '../../theme';

const isAccept = css`
  border-color: ${theme.palette.success.main};
`;

const hasError = css`
  border-color: ${theme.palette.error.main};
`;

const isDisabled = css`
  color: ${theme.palette.primary.main} !important;
  border-color: ${theme.palette.purple.main} !important;
  background: ${theme.palette.purple.light};
  svg {
    color: ${theme.palette.primary.main} !important;
  }
  label {
    color: ${theme.palette.primary.main} !important;
  }
`;

export const ContContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  & + div {
    margin-top: 8px;
  }
`;

export const Content = styled.div`
  height: 43px;
  width: 100%;
  display: flex;
  /* align-items: center; */
  border-bottom: 1px solid ${theme.palette.primary.main};

  label {
    position: absolute;
    top: 20px;
    left: 16px;
    color: #605d62 !important;
    cursor: text;
    font-size: 16px;
    font-weight: 400;
    transition: 0.2s ease;
  }

  &,
  input,
  label,
  button {
    cursor: ${(props) => props.isDisabled && 'not-allowed'};
  }

  ${(props) => props.isDisabled && isDisabled}
  ${(props) => props.hasError && hasError}
  ${(props) => props.isAccept && isAccept}
`;

export const ContIcon = styled.div`
  padding: 16px 16px 0 0;
  background: #fbfdff;
  ${(props) => props.isDisabled && isDisabled}
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 0 0 16px;
  width: 100%;
  background: #fbfdff;
  ${(props) => props.isDisabled && isDisabled}

  input {
    background: #fbfdff;
    margin-bottom: 1px;
    width: 100%;
    border: none;
    /* margin-top: 1rem; */
    /* padding: 0 5px; */
    outline: none;
    font-size: 16px;
    font-weight: 300;
    ${(props) => props.isDisabled && isDisabled}

    &:focus + label {
      font-size: 12px !important;
      top: 0px !important;
      color: #605d62 !important;
      transition: 0.1s;
    }

    &:focus + label + p {
      display: block;
    }

    /* &:focus + div {
      border-bottom: 1px solid red;
    } */

    /* &:valid + label {
      font-size: 12px;
      top: -16px;
      color: #fd7e14;
      transition: 0.3s;
    } */
  }

  button {
    display: flex;
    background: none;
    border: none;
    margin-right: 16px;
    cursor: pointer;
  }
`;

export const Placeholder = styled.span`
  color: #605d62;
  position: absolute;
  top: 18px;
  left: 16px;
  pointer-events: none;
  font-weight: 300;
  font-size: 16px;
  margin: 0;
  opacity: 0;
  transition: opacity 0.2s ease-out;
`;

export const Message = styled.p`
  color: ${({ isError, isSuccess }) => {
    if (isError) return theme.palette.error.main;
    if (isSuccess) return theme.palette.success.main;
    return '#4e4e4';
  }};
  font-size: 14px;
  font-weight: 300;
  margin: 4px 0 0 16px;
  text-align: left;
`;

/* eslint-disable no-nested-ternary */
import { ClickAwayListener } from '@mui/material';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { MdOutlineCheckCircleOutline } from 'react-icons/md';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Container, ContContent, Content, ContIcon, Input } from './styles';
import theme from '../../theme';

export const CurrencyInput = forwardRef(
  (
    {
      labelText,
      value,
      onChange,
      hasError,
      hasTouched,
      errorMessage,
      max = Number.MAX_SAFE_INTEGER,
      noIcon,
      isProposal,
      isDisabled,
      ...rest
    },
    ref
  ) => {
    const labelRef = useRef();
    const [isFocused, setIsFucused] = useState(false);

    const valueAbsTrunc = Math.trunc(Math.abs(value));
    if (
      value !== valueAbsTrunc ||
      !Number.isFinite(value) ||
      Number.isNaN(value)
    ) {
      throw new Error(`invalid value property`);
    }

    const handleValueChange = (event) => {
      // Remove qualquer caractere que não seja número
      const newValue = event.target.value.replace(/\D/g, '');

      onChange(Number.parseInt(newValue, 10) || 0);
    };

    useEffect(() => {
      const handleHasValue = () => {
        if (value || isFocused) {
          labelRef.current.style.top = '-2px';
          labelRef.current.style.fontSize = '12px';
          labelRef.current.style.color = '#605D62';
        } else {
          labelRef.current.style.top = '20px';
          labelRef.current.style.fontSize = '1rem';
          labelRef.current.style.color = '#323232';
          labelRef.current.style.left = '16px';
        }
      };
      handleHasValue();
    }, [value, isFocused]);

    const formatValue = (val) => {
      const valueformated =
        isFocused && val !== 0
          ? (val / 100).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
          : isFocused && val === 0
          ? (0).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
          : val !== 0
          ? (val / 100).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
          : '';

      return valueformated;
    };

    return (
      <Container>
        <ContContent>
          <ClickAwayListener onClickAway={() => setIsFucused(false)}>
            <Content
              hasError={hasError && hasTouched}
              isAccept={hasError === false && hasTouched}
              onClick={() => setIsFucused(true)}
              isDisabled={isDisabled}
              isProposal={isProposal}
            >
              <label
                htmlFor={labelText}
                ref={labelRef}
              >
                {labelText}
              </label>
              <Input
                inputMode='numeric'
                onChange={handleValueChange}
                value={formatValue(value)}
                ref={ref}
                isProposal={!!isProposal}
                disabled={isDisabled}
                {...rest}
              />

              {!noIcon && (
                <ContIcon>
                  {!!hasError && !!hasTouched && (
                    <RiErrorWarningLine
                      size='20px'
                      color={theme.palette.error.main}
                    />
                  )}

                  {hasError === false && !!hasTouched && (
                    <MdOutlineCheckCircleOutline
                      size='20px'
                      color={theme.palette.success.main}
                    />
                  )}
                </ContIcon>
              )}
            </Content>
          </ClickAwayListener>
        </ContContent>
        {!!hasTouched && !!hasError && <p>{errorMessage}</p>}
      </Container>
    );
  }
);

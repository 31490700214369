import styled from 'styled-components';
import PropTypes from 'prop-types';
import { statusColors } from '../../../styles/colors';

const getColor = (key) => statusColors[key];

export const StatusLabel = styled.span.attrs({
  bold: false,
})`
  font-size: ${(props) => (props.size ? `${props.size}px` : '20px')};
  color: ${(props) => getColor(props.status)};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  letter-spacing: 0.085em;
`;

StatusLabel.propTypes = {
  status: PropTypes.oneOf([...Object.keys(statusColors)]).isRequired,
  bold: PropTypes.bool,
};

import styled, { css } from 'styled-components';
import FlexRowContainer from '../../componentsStyle/FlexRowContainer';
import { FieldWrapper, Label } from '../Interface/Form';
import PropTypes from 'prop-types';
import { colors } from '../../styles/colors';

import { MdSave } from 'react-icons/md';
import { TextField } from '@mui/material';

export const InputWithLoadindSaving = ({
  title,
  isSubmitting,
  type,
  value,
  onChange,
  disabled = false,
}) => {
  return (
    <FieldWrapper style={{ marginTop: '15px', width: '100%' }}>
      <Label style={{ margin: 0 }}>
        <FlexRowContainer style={{ alignContent: 'center' }}>
          {title}:{isSubmitting ? <AnimatedSave /> : null}
        </FlexRowContainer>
      </Label>
      {type === 'textarea' ? (
        <textarea
          rows='3'
          type='text'
          name='notes'
          onChange={(e) => onChange(e.target.value)}
          style={{ width: '100%', border: '1px solid #cccccc', padding: 3 }}
          disabled={disabled}
        >
          {value}
        </textarea>
      ) : (
        <InputText
          variant='outlined'
          defaultValue={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
        />
      )}
    </FieldWrapper>
  );
};

const AnimatedSave = styled(MdSave)`
  @keyframes ping {
    55%,
    100% {
      transform: scale(2);
      opacity: 0;
    }
  }
  width: 12px;
  margin-left: 10px;
  animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
`;

const InputText = styled(TextField)`
  ${(props) =>
    props.disabled &&
    css`
      background: ${colors.disabled};
      pointer-events: none;
    `}
`;

InputWithLoadindSaving.propTypes = {
  title: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

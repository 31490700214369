import connection from '../connections/main';
import connectionUpdateBase from '../connections/bases';
import { setApiRoute } from './helpers';

const defaultLimit = import.meta.env.REACT_APP_DEFAULT_PAGINATION_LIMIT || 200;

const getAll = async () => {
  const { err, data } = await connection.get(`/basesGeneration`);

  if (err) {
    throw err;
  }

  return data;
};

const table = async (idOrPath, payload, offset = 0, limit = defaultLimit) => {
  const apiRoute = setApiRoute(idOrPath);

  const finalPath = apiRoute + '/table' + `?offset=${offset}&limit=${limit}`;

  const { err, data } = await connection.post(finalPath, payload);

  if (err) {
    throw err;
  }

  return data;
};

const form = async (path) => {
  const { err, data } = await connection.get(path);

  if (err) {
    throw err;
  }

  return data;
};

const download = async (idOrPath, payload) => {
  const apiRoute = setApiRoute(idOrPath);

  const { err, data } = await connection.post(apiRoute + '/download', payload);

  if (err) {
    throw err;
  }

  return data;
};

const updateBase = async (idOrPath, payload) => {
  const apiRoute = setApiRoute(idOrPath);
  const { err, data } = await connectionUpdateBase.post(
    apiRoute + '/update',
    payload
  );

  if (err) {
    throw err;
  }

  return data;
};

const updateForm = async (url, method, payload) => {
  const { err, data } = await connection({ method, url, data: payload });

  if (err) {
    throw err;
  }

  return data;
};

const getTableS3 = async (identifier, payload) => {
  throw 'err';
};

const ExportingDatabasesService = {
  getAll,
  table,
  form,
  download,
  updateBase,
  getTableS3,
  updateForm,
};

export default ExportingDatabasesService;

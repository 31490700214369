export const fixDecimalPoints = (value = 0, numberOfDecimals = 2) => {
  try {
    return value.toFixed(numberOfDecimals);
  } catch (error) {
    console.log('file: data.js || line 4 || error', error);
    return value;
  }
};

export const insertValueToArray = (array, targetIndexToAdd, item) =>
  array.splice(targetIndexToAdd, 0, item);


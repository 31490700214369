import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;

  padding: 120px 0;
  border: 3px dashed #c0c0c0;

  ${(props) =>
    props.isAccept &&
    css`
      border-color: rgba(33, 179, 30, 0.5);

      p {
        color: #21b31e;
      }
    `}

  ${(props) =>
    props.isReject &&
    css`
      border-color: rgba(179, 38, 30, 0.5);

      p {
        color: #b3261e;
      }
    `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const List = styled.ul`
  max-height: 358px;
  overflow-y: auto;
`;

export const ListItem = styled.li`
  list-style-type: none;
  padding: 1.5rem 1rem;
  background: rgba(33, 179, 30, 0.24);
  border-radius: 8px;
  color: #21b31e;
  font-weight: 500;

  ${(props) =>
    props.isReject &&
    css`
      background: rgba(179, 38, 30, 0.24);
      color: #b3261e;
    `}
`;

export const Aside = styled.aside`
  margin-top: 1.5rem;

  li + li {
    margin-top: 0.5rem;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  p {
    margin: 0;
  }
`;

export const ButtonSubmit = styled.button`
  padding: 0.5rem 1.5rem;
  border: 0;
  border-radius: 0.5rem;
  background: #1f6e43;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const ParagraphAndProviders = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Radiobox = styled.div`
  display: flex;
  gap: 1.5rem;

  input,
  label {
    cursor: pointer;
  }

  input {
    margin-right: 0.5rem;
  }
`;

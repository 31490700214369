import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.button`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: none;
  background: ${theme.palette.secondary.main};
  color: ${theme.palette.primary.main};
  font-size: 18px;
  font-weight: 600;
  transition: all 0.2s;

  &:hover {
    background: ${theme.palette.green.dark};
  }

  &:hover:disabled {
    box-shadow: none;
  }

  &:active {
    background: ${theme.palette.green.light};
  }

  &:disabled {
    width: 100%;
    /* height: 48px; */
    color: ${theme.palette.gray.main};
    background: ${theme.palette.gray.light};
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    cursor: not-allowed;
  }
`;

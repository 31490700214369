import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaPalette } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import CompanyIntegrationForm from '../../../components/Forms/CompanyIntegrationForm';
import DocumentsForm from '../../../components/Forms/DocumentsForm';
import GradientButton from '../../../components/GradientButton';
import { Paragraph } from '../../../components/Interface/Text';
import PageView from '../../../containers/PageView';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';
import CompaniesService from '../../../services/general/companies.service';
import StyleguidesService from '../../../services/administrator/styleguides.service';
import { isCompanyFlora, useAuthStore } from '../../../store';
import CancelIcon from '@mui/icons-material/Cancel';
import theme from '../../../theme';

function AdminCompanyDetails() {
  const navigate = useNavigate();
  let { companyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [company, setCompany] = useState(null);
  const [faqQuestions, setFaqQuestions] = useState(null);
  const [addQA, setAddQA] = useState(false);
  const [formData, setFormData] = useState({});
  const [reload, setReload] = useState(false);
  const [registerText, setRegisterText] = useState({
    extra_register_text_one_title: '',
    extra_register_text_two_title: '',
    extra_register_text_one_text: '',
    extra_register_text_two_text: '',
  });
  const userCompany = useAuthStore((state) => state.userAuth?.company_id);

  const fetchData = async () => {
    try {
      const isFlora = isCompanyFlora();
      const data = await CompaniesService.get(
        isFlora ? companyId : userCompany
      );
      setCompany(data[0]);
      const [styleguide] = await StyleguidesService.getByCompanyId(
        data?.[0]?.id
      );
      setRegisterText({
        extra_register_text_one_title:
          styleguide?.[0]?.extra_register_text_one.split(';')[0],
        extra_register_text_two_title:
          styleguide?.[0]?.extra_register_text_two.split(';')[0],
        extra_register_text_one_text:
          styleguide?.[0]?.extra_register_text_one.split(';')[1],
        extra_register_text_two_text:
          styleguide?.[0]?.extra_register_text_two.split(';')[1],
      });
      if (data?.[0]?.faq_text && data?.[0]?.faq_text?.length > 0) {
        let temp = data[0].faq_text.split('---');
        if (!temp) return;
        setFaqQuestions(temp);
      }
    } catch (err) {
      console.log(err);
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (dawta, { isSubmitting }) => {
    try {
      const req = await CompaniesService.updateOne(dawta.id, {
        ...dawta,
      });
      if (req.success) {
        toast.success(TOAST_MESSAGES.SUCCESS);
      } else {
        throw Error();
      }
    } catch (error) {
      console.log(error);
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const handleSaveText = async () => {
    if (!isCompanyFlora()) return;
    try {
      let register_text_one = `${extra_register_text_one_title} ; ${extra_register_text_one_text} `;
      let register_text_two = `${extra_register_text_two_title} ; ${extra_register_text_two_text}`;
      const req = await StyleguidesService.updateOne(company.styleguide_id, {
        extra_register_text_one: register_text_one,
        extra_register_text_two: register_text_two,
        id: company.styleguide_id,
      });
      if (req.success) {
        toast.success(TOAST_MESSAGES.SUCCESS);
      } else {
        throw Error();
      }
    } catch (error) {
      console.log(error);
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const handleEditFAQ = async () => {
    try {
      if (
        companyId &&
        typeof formData.question === 'string' &&
        typeof formData.answer === 'string'
      ) {
        let temp = `${
          company?.faq_text?.length > 0 ? `${company.faq_text} --- ` : ''
        }${
          formData.question.endsWith('?')
            ? formData.question
            : `${formData.question}?`
        } ${formData.answer}`;
        const req = await CompaniesService.updateOne(companyId, {
          faq_text: temp,
          id: companyId,
        });
        if (req?.success) {
          toast.success(TOAST_MESSAGES.SUCCESS);
        } else {
          throw Error();
        }
        setReload(!reload);
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  useEffect(() => {
    fetchData();

    return () => {};
  }, [companyId, reload]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handlePersonalizationClick = () => {
    navigate({
      pathname: `/personalization/${companyId}`,
      state: { styleguide_id: company?.styleguide_id },
    });
  };

  const handleChangeText = (event) => {
    const { name, value } = event.target;
    setRegisterText((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleDeleteQuestion = async (el, idx) => {
    try {
      if (!company) throw Error();
      const companyId = company?.id;
      if (!companyId) throw Error();
      faqQuestions.splice(idx, 1);
      let req;
      if (faqQuestions.length > 0) {
        let filtered = faqQuestions.join('---');
        req = await CompaniesService.updateOne(companyId, {
          faq_text: filtered,
          id: companyId,
        });
      } else {
        req = await CompaniesService.updateOne(companyId, {
          faq_text: '',
          id: companyId,
        });
      }
      if (req.success) {
        toast.success(TOAST_MESSAGES.SUCCESS);
        setReload(!reload);
      } else {
        throw Error();
      }
    } catch (error) {
      console.log(error);
      toast.warning(TOAST_MESSAGES.ERROR);
    }
  };

  const {
    extra_register_text_one_text,
    extra_register_text_two_text,
    extra_register_text_two_title,
    extra_register_text_one_title,
  } = registerText;

  return (
    <PageView
      title='Detalhes da Empresa'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      {isCompanyFlora() && (
        <PersonalizationSection>
          <GradientButton
            text='Personalizar Estilo'
            handleClick={handlePersonalizationClick}
          >
            <FaPalette style={{ marginLeft: '1rem' }} />
          </GradientButton>
        </PersonalizationSection>
      )}
      <MainContainer>
        {company && (
          <MainInfoSection>
            <DetailsSection
              style={{ width: isCompanyFlora() ? '50%' : '100%' }}
            >
              <TitleContainer>
                <h5>Informações Gerais</h5>
              </TitleContainer>
              <DetailsRow>
                <ColumnContainer>
                  <Paragraph
                    style={{
                      fontWeight: '500',
                      fontSize: '1.2rem',
                      color: '#61787b',
                    }}
                  >
                    Nome:
                  </Paragraph>
                  <h4>{company.name}</h4>
                </ColumnContainer>
                <ColumnContainer>
                  <Paragraph
                    style={{
                      fontWeight: '500',
                      fontSize: '1.2rem',
                      color: '#61787b',
                    }}
                  >
                    Nome Fantasia:
                  </Paragraph>
                  <h4>{company.mock_name}</h4>
                </ColumnContainer>
                <ColumnContainer>
                  <Paragraph
                    style={{
                      fontWeight: '500',
                      fontSize: '1.2rem',
                      color: '#61787b',
                    }}
                  >
                    CNPJ:
                  </Paragraph>
                  <h4>{company.document}</h4>
                </ColumnContainer>
              </DetailsRow>
              <DetailsRow>
                <ColumnContainer>
                  <Paragraph
                    style={{
                      fontWeight: '500',
                      fontSize: '1.2rem',
                      color: '#61787b',
                    }}
                  >
                    Endereço:
                  </Paragraph>
                  <h4>{company.address}</h4>
                </ColumnContainer>
                <ColumnContainer>
                  <Paragraph
                    style={{
                      fontWeight: '500',
                      fontSize: '1.2rem',
                      color: '#61787b',
                    }}
                  >
                    Telefone:
                  </Paragraph>
                  <h4>{company.phone_number}</h4>
                </ColumnContainer>
                <ColumnContainer>
                  <Paragraph
                    style={{
                      fontWeight: '500',
                      fontSize: '1.2rem',
                      color: '#61787b',
                    }}
                  >
                    E-mail:
                  </Paragraph>
                  <h4>{company.email}</h4>
                </ColumnContainer>
              </DetailsRow>
            </DetailsSection>
            {isCompanyFlora() && (
              <DetailsSection>
                <TitleContainer>
                  <h5>Integrações</h5>
                </TitleContainer>
                <CompanyIntegrationForm
                  onSubmit={handleSubmit}
                  data={company}
                />
              </DetailsSection>
            )}
          </MainInfoSection>
        )}
        <MainInfoSection>
          <FaqSection>
            <TitleContainer>
              <h5>Personalização do FAQ</h5>
            </TitleContainer>
            <section style={{ width: '100%' }}>
              <form style={{ width: '100%' }}>
                {faqQuestions &&
                  faqQuestions.length > 0 &&
                  faqQuestions.map((el, idx) => {
                    return (
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            align: 'flex-start',
                          }}
                        >
                          <Paragraph
                            style={{
                              fontWeight: '500',
                              fontSize: '1.2rem',
                              color: '#61787b',
                              wordBreak: 'break-all',
                            }}
                          >
                            {el.split('?')[0]} ?
                          </Paragraph>
                          <h4
                            style={{
                              fontSize: '1rem',
                              color: 'gray',
                              wordBreak: 'break-all',
                            }}
                          >
                            {el.split('?')[1]}
                          </h4>
                        </div>
                        <div>
                          <CancelIcon
                            onClick={() => handleDeleteQuestion(el, idx)}
                            className='deleteIcon'
                            style={{ color: 'red' }}
                          />
                        </div>
                      </div>
                    );
                  })}
                {addQA && (
                  <div style={{ width: '100%' }}>
                    <TextField
                      variant='outlined'
                      label='Pergunta'
                      placeholder='Pergunta'
                      onChange={handleChange}
                      style={{ marginTop: '1rem' }}
                      name='question'
                    />
                    <TextField
                      style={{ width: '50%', marginTop: '1rem' }}
                      variant='outlined'
                      label='Resposta'
                      placeholder='Resposta'
                      onChange={handleChange}
                      name='answer'
                    />
                  </div>
                )}
                {addQA && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginBottom: '1rem',
                    }}
                  >
                    <GradientButton
                      text='Salvar'
                      handleClick={() => handleEditFAQ()}
                    />
                  </div>
                )}
              </form>
            </section>
            {isCompanyFlora() && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <GradientButton
                  text={!addQA ? 'Adicionar QA' : 'Remover QA'}
                  handleClick={() => setAddQA(!addQA)}
                />
              </div>
            )}
          </FaqSection>
          <DocumentsSection>
            <TitleContainer>
              <h5>Documentos</h5>
            </TitleContainer>
            <DocumentsForm data={company} />
          </DocumentsSection>
        </MainInfoSection>
        <div>
          <Box2 style={{ marginRight: '6rem' }}>
            <TitleContainer>
              <h5>Texto do Cadastro</h5>
            </TitleContainer>
            <form>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Paragraph
                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                  >
                    Texto 1:
                  </Paragraph>
                  <TextField
                    disabled={!isCompanyFlora()}
                    style={{ width: '50%', marginTop: '1rem' }}
                    variant='outlined'
                    label='Título'
                    placeholder='Título'
                    onChange={handleChangeText}
                    name='extra_register_text_one_title'
                    value={extra_register_text_one_title}
                  />
                  <TextField
                    disabled={!isCompanyFlora()}
                    style={{ width: '50%', marginTop: '1rem' }}
                    variant='outlined'
                    label='Texto'
                    placeholder='Texto'
                    onChange={handleChangeText}
                    name='extra_register_text_one_text'
                    value={extra_register_text_one_text}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Paragraph
                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                  >
                    Texto 2:
                  </Paragraph>
                  <TextField
                    disabled={!isCompanyFlora()}
                    style={{ width: '50%', marginTop: '1rem' }}
                    variant='outlined'
                    label='Título'
                    placeholder='Título'
                    onChange={handleChangeText}
                    name='extra_register_text_two_title'
                    value={extra_register_text_two_title}
                  />
                  <TextField
                    disabled={!isCompanyFlora()}
                    style={{ width: '50%', marginTop: '1rem' }}
                    variant='outlined'
                    label='Texto'
                    placeholder='Texto'
                    onChange={handleChangeText}
                    name='extra_register_text_two_text'
                    value={extra_register_text_two_text}
                  />
                </div>
              </div>
            </form>
            {isCompanyFlora() && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <GradientButton
                  text='Salvar'
                  handleClick={() => handleSaveText()}
                />
              </div>
            )}
          </Box2>
        </div>
      </MainContainer>
    </PageView>
  );
}

export default AdminCompanyDetails;

const PersonalizationSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
`;

const DocumentsSection = styled.section`
  margin-top: 2rem;
  display: flex;
  width: 49%;
  height: fit-content;
  padding: 2rem 3rem;
  border: 1px solid lightgray;
  border-radius: 1rem;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  margin: 0;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  > h5 {
    font-size: 1.5rem;
    color: ${theme.palette.primary.main};
    font-weight: bold;
  }
`;
const FaqSection = styled.div`
  margin-top: 2rem;
  display: flex;
  width: 49%;
  height: fit-content;
  padding: 2rem 3rem;
  border: 1px solid lightgray;
  border-radius: 1rem;
  flex-direction: column;
  .deleteIcon {
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
`;

const MainContainer = styled.section``;

const DetailsSection = styled.section`
  display: flex;
  width: 49%;
  height: fit-content;
  padding: 2rem 3rem;
  border: 1px solid lightgray;
  border-radius: 1rem;
  flex-direction: column;
`;

const MainInfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DetailsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 1.5rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  align-items: center;
  > h4 {
    font-size: 1rem;
    color: gray;
  }
`;

const Box2 = styled.div`
  margin-top: 3rem;
  width: 48%;
  border: 1px solid lightgray;
  padding: 1.5rem;
  border-radius: 10px;
`;

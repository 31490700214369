/* eslint-disable one-var */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable no-throw-literal */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { toast } from 'react-toastify';
import moment from 'moment';

import PageView from '../../../../containers/PageView';

import SearchableTable from '../../../../components/Interface/SearchableTable';
import { StyledLink } from '../../../../components/Interface';
import { Paragraph } from '../../../../components/Interface/Text/Paragraph';

import ContentCard from '../../../../components/ContentCard';
import GradientButton from '../../../../components/GradientButton';
import GiftManagementService from '../../../../services/administrator/ManagementBases/giftManagement.service';
import ExcelRenderer from '../../../../utils/excelRenderer';
import { DropzoneCustom } from '../../../../components/DropzoneCustom';
import { isDate } from '../../../../helpers/validations';
import { format, parseISO } from 'date-fns';
import Alerts from '../../../../components/Alerts';
import styled from 'styled-components';

const COLUMNAFTERUPLOAD = [{ name: 'Contrato' }, { name: 'Gift' }];

const COLUMN = [{ name: 'Contrato' }, { name: 'Gift' }, { name: 'User' }];

const getRows = (contracts) => {
  try {
    return contracts.map((contract) => {
      const { user_id, id, gift } = contract;
      const rows = {
        values: [
          {
            value: (
              <StyledLink
                tag='true'
                to={id ? `/contratos/${id}` : '/checkFaturamento'}
              >{`${id}`}</StyledLink>
            ),
          },
          {
            value: (
              <Paragraph>
                {isDate(gift) ? moment(gift).format('DD/MM/yyyy') : gift}
              </Paragraph>
            ),
          },
        ],
      };
      if (user_id)
        rows.values.push({
          value: (
            <StyledLink
              tag='true'
              to={user_id ? `/usuarios/${user_id}` : '/checkFaturamento'}
            >{`${user_id}`}</StyledLink>
          ),
        });
      return rows;
    });
  } catch (error) {
    console.log(error);
  }
};

const InvoicesCheck = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    active: [],
    pending_activation: [],
    draft: [],
    all: [],
  });

  const [allData, setAllData] = useState(location?.state?.final || null);
  const [responseHeaders, setResponseHeaders] = useState(false);
  const [rerender, setRerender] = useState(true);
  const [fileAttached, setFileAttached] = useState(false);
  const notFullMode = true;
  const [contractsErrorInvalidDate, setContractsErrorInvalidDate] = useState(
    []
  );

  useEffect(() => {
    const fetchInvoicesCheck = async () => {
      try {
        const activeContractsByMonth = await GiftManagementService.getAll();
        setAllData(activeContractsByMonth);
        setPageState((state) => ({
          ...state,
          loading: false,
          hasError: false,
        }));
      } catch (err) {
        console.log(err);
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };
    if (!allData) {
      fetchInvoicesCheck();
    } else {
      setPageState((state) => ({ ...state, loading: false, hasError: false }));
    }
  }, []);

  const fetchAll = async () => {
    const contractss = await GiftManagementService.getAll();
    return getRows(contractss);
  };

  const onSubmit = async (el) => {
    setPageState((state) => ({
      ...state,
      loading: true,
    }));
    try {
      const responseData = await GiftManagementService.updateAll(allData);
      setAllData(responseData);
      setResponseHeaders(true);
      toast.success('Gift atualizado com sucesso!');
    } catch (error) {
      setPageState((state) => ({
        ...state,
        loading: true,
        hasError: true,
      }));
      toast.error('Houve um erro desconhecido, tente novamente mais tarde!');
      setResponseHeaders(false);
    }
  };

  useEffect(() => {
    if (!allData) return;
    setPageState((state) => ({
      ...state,
      loading: false,
    }));
  }, [allData]);

  const desiredColumns = ['Contrato'];

  const checkModel = (titles) => {
    desiredColumns.map((desiredTitle, index) => {
      if (titles[index] !== desiredTitle) throw 'Por favor utilize o modelo';
    });
  };

  const defineGift = (gift) => {
    // Verificar formato MM/DD/YYYY
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
    if (dateFormat.test(gift)) {
      return gift;
    } else {
      const date = parseISO('1900-01-01'); // Data base do Excel
      date.setDate(date.getDate() + (gift - 2));
      const formattedDate = format(date, 'MM/dd/yyyy');
      return formattedDate;
    }
  };

  const rowsHandler = (datas) => {
    try {
      const finalData = [];
      const contractsInvalidDate = [];

      datas.map((data) => {
        const [contractId, gift] = data;
        if (!contractId) return;
        else if (!gift)
          return finalData.push({ id: +contractId, gift: Date.now() });
        else if (typeof gift !== 'number')
          contractsInvalidDate.push({
            id: +contractId,
          });
        else return finalData.push({ id: +contractId, gift: defineGift(gift) });
      });

      setAllData(finalData);
      setRerender(!rerender);
      setContractsErrorInvalidDate(contractsInvalidDate);
    } catch (error) {
      console.log('file: index.js || line 263 || error', error);
      throw error;
    }
  };
  const dataHandler = (data) => {
    const { rows } = data;
    const [titles, ...datas] = rows;
    checkModel(titles);
    rowsHandler(datas);
    setFileAttached(true);
  };

  const fileHandler = async (files) => {
    try {
      const fileObj = files[0];
      const { type } = fileObj;
      if (
        type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
        throw "O formato do arquivo deve ser 'xlsx'";

      const data = await ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          throw err;
        } else {
          return resp;
        }
      });
      dataHandler(data);
    } catch (error) {
      toast.error(error);
    }
  };

  const { loading, hasError } = pageState;

  return (
    <PageView
      title='Gestão de Gifts'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Paragraph align='center'>
        Por favor, realize o upload de todos os contratos que deseja manter o
        gift ativo, inclusíve os que já estiverem ativos.
      </Paragraph>
      <DropzoneCustom
        maxFiles={1}
        fileHandler={fileHandler}
        acceptedFileTypes={['.xlsx']}
        handlerFileAfterChange={true}
      />

      <div
        style={{
          marginTop: '2rem',
          marginBottom: '2.5rem',
          border: '1px solid gray',
        }}
      />
      <>
        {allData && (
          <Grid container>
            <Grid
              item
              xs={12}
            >
              <ContentCard>
                {contractsErrorInvalidDate.length > 0 ? (
                  <AlertsContainer>
                    <Alerts
                      alerts={[
                        {
                          type: 'error',
                          message: `O${
                            contractsErrorInvalidDate.length > 1 ? 's' : ''
                          } contrato${
                            contractsErrorInvalidDate.length > 1 ? 's' : ''
                          } ${contractsErrorInvalidDate
                            .map((c) => c.id)
                            .join(', ')} não ${
                            contractsErrorInvalidDate.length > 1
                              ? 'possuem'
                              : 'possui'
                          } o padrão de data esperado. Ajuste o arquivo para adiciona-lo a lista de ativação.`,
                        },
                      ]}
                    />
                  </AlertsContainer>
                ) : null}
                <SearchableTable
                  columns={!fileAttached ? COLUMN : COLUMNAFTERUPLOAD}
                  rowsValues={getRows(allData)}
                  noItemsText='Nenhum contrato está com o gift ativo'
                  fetchAll={fetchAll}
                  rerender={rerender}
                />
              </ContentCard>
            </Grid>
          </Grid>
        )}
        {allData && fileAttached && (
          <div style={{ marginTop: '3rem' }}>
            <GradientButton
              text={'Salvar'}
              handleClick={() => onSubmit()}
            />
          </div>
        )}
      </>
    </PageView>
  );
};

const AlertsContainer = styled.div`
  margin-bottom: 20px;
`;

export default InvoicesCheck;

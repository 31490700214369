import React, { useState } from 'react';

import { MdArrowBackIos, MdOutlineChat } from 'react-icons/md';
import { useStage } from '../../hooks/useStage';

import LogoImgWhite from '../../assets/images/logo/logo-flora-white.svg';
import {
  Container,
  Content,
  ContProgressBar,
  ImageContainer,
  NavigateButton,
  BackButton,
  HelpButton,
  ArrowButton,
  Link,
} from './styles';
import ContractsService from '../../services/general/contracts.service';
import UsersService from '../../services/general/users.service';
import { useZendeskStore } from '../../store';
import theme from '../../theme';

export const Header = ({ isNewPropertyOrHolder }) => {
  const { isOpenZendesk, open, close, shouldHideBtn } = useZendeskStore();
  const { stagePayload, changePayload } = useStage();
  const [disabled, setDisabled] = useState(false);

  const { stage } = stagePayload;

  const disabledCasesStages = stage === 3 || stage === 4 || stage === 5;

  const handlePreviousStage = () => {
    if (stagePayload.stage >= 1) {
      const updatedStage = {
        ...stagePayload,
        stage: stagePayload.stage - 1,
      };
      changePayload(updatedStage);
      // localStorage.setItem('@FloraEnergia:stage', JSON.stringify(updatedStage));
    }
  };

  const handleNavigateStage = (stage) => {
    const updatedStage = {
      ...stagePayload,
      stage,
    };

    changePayload(updatedStage);
  };

  const handleBackUnsigned = async () => {
    try {
      const { contract_id } = stagePayload;
      setDisabled(true);
      changePayload({ stage: 4 });
      const payload = { stage: 6 };
      isNewPropertyOrHolder
        ? await ContractsService.newLocationNew(payload, contract_id)
        : await UsersService.signupUpdateCheckNew(payload);
    } catch (error) {
      console.log('file: index.js || line 58 || error', error);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <Container>
      <Content isNewPropertyOrHolder={!!isNewPropertyOrHolder}>
        {!isNewPropertyOrHolder && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 1,
              alignItems: 'center',
            }}
          >
            <Link
              href='https://floraenergia.com.br/'
              isLogo
            >
              <ImageContainer src={LogoImgWhite} />
            </Link>

            <Link
              href='https://floraenergia.com.br/'
              isArrow
            >
              <ArrowButton>
                <MdArrowBackIos
                  size='1.5rem'
                  color={theme.palette.secondary.main}
                />
              </ArrowButton>
            </Link>

            {!shouldHideBtn ? (
              <HelpButton onClick={() => (isOpenZendesk ? close() : open())}>
                <MdOutlineChat
                  size='1.5rem'
                  color={theme.palette.secondary.main}
                />
              </HelpButton>
            ) : null}
          </div>
        )}
        {stagePayload.stage === 3 && (
          <>
            <BackButton
              type='button'
              onClick={handleBackUnsigned}
              disabled={disabled}
              style={{ marginLeft: '1rem' }}
            >
              voltar
            </BackButton>
          </>
        )}
      </Content>

      <ContProgressBar>
        <NavigateButton
          isActive
          isCurrent={stagePayload.stage === 0}
          onClick={() => handleNavigateStage(0)}
          disabled={disabledCasesStages}
        >
          1 - {isNewPropertyOrHolder ? 'Seu imóvel' : 'Sobre você'}
        </NavigateButton>

        <NavigateButton
          isActive={stagePayload.stage > 0}
          isPassed={stagePayload.stage < 1 && stagePayload.max_stage >= 1}
          isCurrent={stagePayload.stage === 1}
          onClick={() => handleNavigateStage(1)}
          disabled={stagePayload.max_stage < 1 || disabledCasesStages}
        >
          2 - Proposta
        </NavigateButton>

        <NavigateButton
          isActive={
            stagePayload.stage === 2 ||
            stagePayload.stage === 3 ||
            stagePayload.stage === 4
          }
          isPassed={stagePayload.stage < 2 && stagePayload.max_stage === 2}
          isCurrent={
            stagePayload.stage === 2 ||
            stagePayload.stage === 3 ||
            stagePayload.stage === 4
          }
          onClick={() => handleNavigateStage(2)}
          disabled={stagePayload.max_stage < 2 || disabledCasesStages}
        >
          3 - Assinatura
        </NavigateButton>
      </ContProgressBar>
    </Container>
  );
};

import { filesize } from 'filesize';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import transformBytes from '../../helpers/transformBytes';
import { handlingFileName } from '../../utils/FileNameAdjustment';
import GradientButton from '../GradientButton';
import { Paragraph } from '../Interface/Text';
import FolderImg from './folder-drop-zone.png';

const messageError = {
  'file-too-large': `O arquivo é maior que ${transformBytes(
    import.meta.env.REACT_APP_MAX_UPLOAD_SIZE
  )}`,
  'file-invalid-type': 'Formato do arquivo incorreto',
};

export const DropzoneCustom = ({
  maxFiles = 1,
  acceptedFileTypes = [],
  fileHandler,
  handlerFileAfterChange = false,
  hasResetFiles = false,
  isButtonInTop = false,
  disabled = false,
  noMaxSize = false,
}) => {
  const [filesAccept, setFilesAccept] = useState([]);
  const [filesReject, setFilesReject] = useState([]);
  const [exceededLimit, setExceededLimit] = useState(false);

  const dropZoneOptions = {
    maxFiles,
    multiple: maxFiles > 1,
    accept: { 'application/*': acceptedFileTypes },
    disabled,
  };
  if (!noMaxSize)
    dropZoneOptions.maxSize = import.meta.env.REACT_APP_MAX_UPLOAD_SIZE;

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
  } = useDropzone(dropZoneOptions);

  const resetFiles = () => {
    setFilesAccept([]);
    setFilesReject([]);
    setExceededLimit(false);
  };

  const filesHandler = () => {
    fileHandler(filesAccept);
    resetFiles();
  };

  useEffect(() => {
    if (filesReject.length > 0) setFilesReject([]);
    if (
      (filesAccept.length > maxFiles ||
        filesAccept.length + acceptedFiles.length > maxFiles) &&
      !(maxFiles === 1 && acceptedFiles.length === 1)
    ) {
      setExceededLimit(true);
      return;
    }

    if (exceededLimit) setExceededLimit(false);

    if (maxFiles === 1 && acceptedFiles.length === 1)
      setFilesAccept(acceptedFiles);
    else setFilesAccept((prevState) => [...prevState, ...acceptedFiles]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  useEffect(() => {
    if (handlerFileAfterChange && filesAccept.length > 0) {
      fileHandler(filesAccept);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesAccept, handlerFileAfterChange]);

  useEffect(() => {
    setFilesReject((prevState) => [...prevState, ...fileRejections]);
  }, [fileRejections]);

  useEffect(() => {
    if (hasResetFiles) resetFiles();
  }, [hasResetFiles]);

  const createListItem = (file, type, error = null) => (
    <>
      <ListItem key={file.path}>
        <FileItem isReject={type === 'rejected'}>
          {handlingFileName(file.path)}
          <span>({filesize(file.size)})</span>
        </FileItem>
        <Paragraph className='error'>{messageError[error?.code]}</Paragraph>
      </ListItem>
    </>
  );

  return (
    <Container isButtonInTop={isButtonInTop}>
      <Section>
        <DropContainer
          isAccept={isDragAccept}
          isReject={isDragReject}
          {...getRootProps()}
          disabled={disabled}
        >
          <input {...getInputProps()} />
          <Content>
            <img
              src={FolderImg}
              alt='eko_logo'
              width={80}
            />
            <Paragraph align='center'>
              Arraste e solte {maxFiles > 1 ? 'o arquivo' : 'os arquivos'} (
              {acceptedFileTypes.join(', ')}) ou clique para selecionar.
            </Paragraph>
            {exceededLimit && (
              <p style={{ color: '#6e242b' }}>Excedeu o limite de arquivos.</p>
            )}
          </Content>

          {!exceededLimit &&
            !filesAccept.length &&
            !filesReject.length &&
            maxFiles > 1 && (
              <Paragraph align='center'>
                ({maxFiles} é o número máximo de arquivos que você pode soltar
                aqui.)
              </Paragraph>
            )}
        </DropContainer>
        <Aside>
          {!!filesAccept.length && (
            <>
              <ListTitle>Arquivos anexados ({filesAccept.length}):</ListTitle>
              <List>
                {filesAccept.map((file) => createListItem(file, 'acceted'))}
              </List>
            </>
          )}

          {!!filesReject.length && (
            <>
              <ListTitle color='#6e242b'>
                Arquivos rejeitados ({filesReject.length}):
              </ListTitle>
              <List>
                {filesReject.map(({ file, errors }) =>
                  createListItem(file, 'rejected', errors[0])
                )}
              </List>
            </>
          )}
        </Aside>
      </Section>
      <ButtonContainer>
        {filesAccept.length > 0 && !handlerFileAfterChange ? (
          <GradientButton
            handleClick={() => filesHandler(filesAccept)}
            variant='outlined'
            text='Enviar'
            disabled={disabled}
          />
        ) : null}
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0;
  ${(props) =>
    props.isButtonInTop &&
    css`
      flex-direction: column-reverse;
    `}
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const DropContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 70px 0;
  border: 2px dashed #e0e9e9;
  background: rgb(246, 250, 249);
  border-radius: 10px;
  width: 60%;
  min-width: 300px;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 0 20px;
`;
const ListTitle = styled.div`
  color: ${(props) => props?.color || 'rgb(78, 106, 117)'};
  font-size: 14px;
  text-align: center;
`;
const List = styled.ul`
  max-height: 358px;
  overflow-y: auto;
  padding: 0;
`;

const ListItem = styled.li`
  list-style-type: none;
  margin-right: 0;
  & .error {
    font-size: 12px;
    color: #6e242b;
    text-align: center;
    margin-bottom: 10px;
  }
`;

const FileItem = styled.div`
  padding: 10px 15px;
  background: #d1e7dd;
  border-radius: 8px;
  color: #144e48;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) =>
    props.isReject &&
    css`
      background: #f8d7da;
      color: #6e242b;
    `};
  & span {
    margin-left: 20px;
  }
`;

const Aside = styled.aside`
  margin-top: 1.5rem;

  li + li {
    margin-top: 0.5rem;
  }
`;

const ButtonContainer = styled.div`
  diplay: flex;
  flex: row;
  justify-content: center;
  margin-bottom: 20px;
`;

DropzoneCustom.propTypes = {
  maxFiles: PropTypes.number,
  acceptedFileTypes: PropTypes.array,
  fileHandler: PropTypes.func.isRequired,
  hasResetFiles: PropTypes.bool,
  handlerFileAfterChange: PropTypes.bool,
  isButtonInTop: PropTypes.bool,
  disabled: PropTypes.bool,
  noMaxSize: PropTypes.bool,
};

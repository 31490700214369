import { Grid } from '@mui/material';
import React from 'react';
import AnalitycsForm from '../../../../../../components/Forms/AnalyticsForm';
import { Heading2 } from '../../../../../../components/Interface/Text';
import TitleContainer from '../../../../../../componentsStyle/TitleContainer';

const ContractBillLocationCondition = () => {
  return (
    <Grid
      rowSpacing={2}
      className='container-section'
    >
      <Grid
        item
        xs={12}
      >
        <TitleContainer>
          <Heading2>Condições para o imóvel</Heading2>
        </TitleContainer>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Grid
          spacing={2}
          rowSpacing={2}
        >
          <AnalitycsForm />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ContractBillLocationCondition;

import { create } from 'zustand';

const INITIAL_STATE = {
  providers: null,
};

export const useAdminProvidersStore = create((set) => ({
  ...INITIAL_STATE,
  setProviders: (payload) => set((state) => ({ ...state, providers: payload })),
}));

export const USER_ROLES_TYPES = {
  Admin: 'admin',
  Customer: 'customer',
  NewCustomer: 'new_customer',
  NewCustomerAmbar: 'new_customer_ambar',
  NewCustomerXP: 'new_customer_xp',
  SetPassword: 'set_password',
};

export const USER_ROLES_TYPES_PTBR = {
  [USER_ROLES_TYPES.Admin]: 'Administrador',
  [USER_ROLES_TYPES.Customer]: 'Cliente',
  [USER_ROLES_TYPES.NewCustomer]: 'Cliente',
  [USER_ROLES_TYPES.NewCustomerAmbar]: 'Cliente',
  [USER_ROLES_TYPES.NewCustomerXP]: 'Cliente',
  [USER_ROLES_TYPES.SetPassword]: 'Cliente Sem Senha',
};

export function isAdmin(role) {
  return role === USER_ROLES_TYPES.Admin;
}

export function isCustomer(role) {
  return (
    role === USER_ROLES_TYPES.Customer ||
    role === USER_ROLES_TYPES.NewCustomer ||
    role === USER_ROLES_TYPES.NewCustomerAmbar ||
    role === USER_ROLES_TYPES.NewCustomerXP ||
    role === USER_ROLES_TYPES.SetPassword
  );
}

export function isNewCustomer(role) {
  return role === USER_ROLES_TYPES.NewCustomer;
}

export const hasPassword = (role) => {
  if (
    role === USER_ROLES_TYPES.NewCustomer ||
    role === USER_ROLES_TYPES.NewCustomerAmbar ||
    role === USER_ROLES_TYPES.NewCustomerXP ||
    role === USER_ROLES_TYPES.SetPassword
  ) {
    return 'Não';
  }
  return 'Sim';
};

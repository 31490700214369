import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Divider = ({ space = 'small' }) => <CustomSeparator space={space} />;

export default Divider;

Divider.propTypes = {
  space: PropTypes.string,
};

export const CustomSeparator = styled.div`
  width: 100%;
  height: ${(props) => props.space === 'small' && `15px`};
  height: ${(props) => props.space === 'medium' && `30px`};
  height: ${(props) => props.space === 'large' && `50px`};
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import styled from 'styled-components';

import FinishRegisterForm from '../../../components/FinishRegisterForm';

const BaseFinishRegister = ({ navigate, location, className }) => {
  const token = new URLSearchParams(location.search).get('token');

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      className={className}
    >
      <Grid item>
        <FinishRegisterForm
          navigate={navigate}
          token={token}
        />
      </Grid>
    </Grid>
  );
};

BaseFinishRegister.propTypes = {
  className: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  navigate: PropTypes.object.isRequired,
};

const FinishRegister = styled(BaseFinishRegister)`
  height: 100vh;
`;

export default FinishRegister;

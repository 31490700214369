import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

import AttachmentsService from '../../services/attachments/attachments.service';

import { LoadingCustomModal } from '../Modals/LoadingCustomModal';

import { Container, UploadButtonContainer } from './styles';
import LocationsService from '../../services/general/locations.service';
import { FiledOCRModal } from '../Modals/FiledOCRModal';
import { UserContext } from '../../contexts/userContext';

export const UploadCustomPendencies = ({
  location_id,
  removeItem = () => {},
  accept,
  className = '',
  resetAfterUpload = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { resetStagePayload, reloadData } = useContext(UserContext);

  const saveAttachment = async (event) => {
    try {
      setLoading(true);
      const attachmentKey = await AttachmentsService.uploadAttachment(
        event.target.files[0]
      );

      await LocationsService.update(location_id, {
        attachment: attachmentKey,
      });
      if (resetAfterUpload) {
        resetStagePayload();
        reloadData();
      } else {
        removeItem(location_id);
      }

      // eslint-disable-next-line no-unused-expressions
      // resetAfterUpload ? resetStagePayload() : removeItem(location_id);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }

    //   toast.success('Conta de luz adicionada com sucesso');
    // } catch (err) {
    //   console.log('file: index.js || line 348 || err', err);
    //   toast.error('Ocorreu um erro, tente novamente');
    // } finally {
    //   const indexOfUpload = uploadingFile.findIndex(
    //     (lId) => lId === locationData.id
    //   );
    //   uploadingFile.splice(indexOfUpload, 1);
    // }
  };

  const handleFileUpload = (event) => {
    if (event.target?.files[0].type !== accept) {
      toast.error('Arquivo não suportado.');
      return;
    }

    saveAttachment(event);
  };

  return (
    <>
      <LoadingCustomModal isLoading={loading} />
      <Container>
        <UploadButtonContainer className={className}>
          <input
            id={location_id}
            type='file'
            onChange={handleFileUpload}
            accept={accept}
          />
          <label htmlFor={location_id}>Enviar conta de luz</label>
        </UploadButtonContainer>
      </Container>
    </>
  );
};

UploadCustomPendencies.propTypes = {
  location_id: PropTypes.string.isRequired,
  removeItem: PropTypes.func,
  accept: PropTypes.string.isRequired,
  className: PropTypes.string,
  resetAfterUpload: PropTypes.bool,
};

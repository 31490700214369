import React from 'react';
import { Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Form } from 'formik';
import { LocationContext } from '../../../../../../contexts/LocationContext';
import RegistrationData from './RegistrationData';
import GenericForm from '../../../../../../components/Forms/GenericForm';
import QuantitativeData from './QuantitativeData';
import Measurements from './Measurements';
import GradientButton from '../../../../../../components/GradientButton';
import adminStores from '../../../../../../store/admin/contractDetails/index.store';
import LocationCredentialsData from './LocationCredentialsData';
import Hr from '../../../../../../components/Hr/hr';
import theme from '../../../../../../theme';

const ContractBillMeasurements = () => {
  const { isEditing, setIsEditing, onSubmit } = useContext(LocationContext);

  const {
    location: { location: selectedLocation },
    credential: { credential },
    measurements: { measurements },
    output: { output },
    locationCredentials: { locationCredentials },
  } = adminStores();

  const [readonly, setReadonly] = useState(!isEditing.measurements);
  useEffect(
    () => setReadonly(!isEditing.measurements),
    [isEditing.measurements]
  );

  const handleSelectChange = async (data) => {
    setSelectValues((prevState) => ({ ...prevState, ...data }));
  };

  const [values, setValues] = useState({
    measurements,
    ...selectedLocation,
    ...credential,
    averageKwh: output.averageKwh,
    suggestedVolume: output.suggestedVolume,
    ...locationCredentials,
  });

  const [selectValues, setSelectValues] = useState([]);

  useEffect(() => {
    setValues({
      measurements,
      ...selectedLocation,
      ...credential,
      averageKwh: output.averageKwh,
      ...locationCredentials,
    });
  }, [
    credential,
    measurements,
    selectedLocation,
    selectValues,
    output,
    locationCredentials,
  ]);

  const defineSelectedValues = () => {
    setSelectValues({
      provider_id: selectedLocation.provider_id,
      connection_type: selectedLocation.connection_type,
    });
  };

  useEffect(() => {
    defineSelectedValues();
  }, [selectedLocation.provider, selectedLocation.connection_type]);

  const handleCancel = (resetForm) => {
    resetForm();
    defineSelectedValues();
    setIsEditing((state) => ({
      ...state,
      measurements: false,
    }));
  };

  return (
    <GenericForm
      enableReinitialize
      onSubmit={(values, { setSubmitting }) =>
        onSubmit({ ...values, ...selectValues }, { setSubmitting })
      }
      values={values}
    >
      {({ isSubmitting, handleSubmit, resetForm, values: formValues }) => {
        return (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <Grid
              container
              spacing={1}
              rowSpacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <RegistrationData
                  selectHandler={handleSelectChange}
                  values={values}
                  readonly={readonly}
                  selectValues={selectValues}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Hr color='#bfbfbf4' />
                <LocationCredentialsData />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Hr color='#bfbfbf4' />
                <QuantitativeData
                  selectHandler={handleSelectChange}
                  values={values}
                  readonly={readonly}
                  selectValues={selectValues}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Measurements
                  selectHandler={handleSelectChange}
                  values={values}
                  formValues={formValues}
                  readonly={readonly}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Grid
                  container
                  justify='flex-start'
                  spacing={3}
                >
                  {!readonly && (
                    <Grid item>
                      <GradientButton
                        paddingg={'10px 30px'}
                        background='white'
                        color={theme.palette.primary.main}
                        border={`1px solid ${theme.palette.primary.main}`}
                        text='Cancelar'
                        handleClick={() => handleCancel(resetForm)}
                        disabled={isSubmitting}
                        outlined
                        rectangle
                      />
                    </Grid>
                  )}
                  {!readonly && (
                    <Grid item>
                      <GradientButton
                        paddingg={'10px 30px'}
                        text='Salvar'
                        handleClick={handleSubmit}
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                        rectangle
                        isSubmitting={isSubmitting}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
              >
                {!isEditing.measurements && (
                  <GradientButton
                    paddingg='10px 30px'
                    text='Alterar'
                    rectangle
                    handleClick={() =>
                      setIsEditing((state) => ({
                        ...state,
                        measurements: true,
                      }))
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </GenericForm>
  );
};
export default ContractBillMeasurements;

import React from 'react';

import { useStage } from '../../hooks/useStage';

import { InputCustom } from '../InputCustom';
import { validationData } from '../../helpers/form/validations';
import { onlyNumbers, onlyString } from '../../helpers/form/formatter';

import { Container } from './styles';
import { AddressNumber } from './addressNumber';

export const PostalCodeWithoutAddressAndNeighborhood = () => {
  const { stagePayload, changePayload } = useStage();
  const { city, state, street, neighborhood, number, complement } =
    stagePayload;

  const handleStreet = (event) => {
    event.persist();
    let status = 'accepted';
    const isStreetValid = validationData(onlyString(event.target.value));

    if (!isStreetValid) {
      status = 'refused';
    }

    changePayload({
      street: { ...street, value: onlyString(event.target.value), status },
    });
  };

  const blurStreet = () => {
    const newStreet = { ...street, touched: true };

    if (!stagePayload.street.value.length) newStreet.status = 'refused';

    changePayload({ street: newStreet });
  };

  const handleNeighborhood = (event) => {
    event.persist();
    let status = 'accepted';
    const isNeighborhoodValid = validationData(onlyString(event.target.value));

    if (!isNeighborhoodValid) {
      status = 'refused';
    }

    changePayload({
      neighborhood: {
        ...neighborhood,
        value: onlyString(event.target.value),
        status,
      },
    });
  };

  const blurNeighborhood = () => {
    const newNeighborhood = { ...neighborhood, touched: true };

    if (!stagePayload.neighborhood.value.length)
      newNeighborhood.status = 'refused';

    changePayload({ neighborhood: newNeighborhood });
  };

  const handleComplement = (event) => {
    event.persist();
    let status = 'accepted';
    const isComplementValid = validationData(event.target.value);

    if (!isComplementValid) status = 'refused';

    changePayload({
      complement: { ...complement, value: event.target.value, status },
    });
  };

  const blurComplement = () =>
    changePayload({ complement: { ...complement, touched: true } });

  return (
    <Container>
      <InputCustom
        type='text'
        name='city-address'
        labelText='Cidade'
        value={city?.value}
        hasError={city?.status === 'refused'}
        hasTouched={city?.touched}
        disabled
      />

      <InputCustom
        type='text'
        name='state-address'
        labelText='Estado'
        value={state?.value}
        hasError={state?.status === 'refused'}
        hasTouched={state?.touched}
        disabled
      />

      <InputCustom
        type='text'
        name='street-address'
        labelText='* Endereço'
        value={street?.value}
        onChange={handleStreet}
        onBlur={blurStreet}
        hasError={street?.status === 'refused'}
        hasTouched={street?.touched}
        requiredMessage='Endereço é obrigatório'
      />

      <InputCustom
        type='text'
        name='neighborhood-address'
        labelText='* Bairro'
        value={neighborhood?.value}
        onChange={handleNeighborhood}
        onBlur={blurNeighborhood}
        hasError={neighborhood?.status === 'refused'}
        hasTouched={neighborhood?.touched}
        requiredMessage='Bairro é obrigatório'
      />

      <AddressNumber
        changePayload={changePayload}
        number={number}
      />

      <InputCustom
        type='text'
        name='complement-address'
        labelText='Complemento'
        value={complement?.value}
        onChange={handleComplement}
        onBlur={blurComplement}
        hasTouched={complement?.touched}
      />
    </Container>
  );
};

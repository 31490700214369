export const TotalValueInvoiceSelected = (
  valueInvoiceCompany,
  valueInvoiceProvider
) => {
  if (!valueInvoiceCompany && !valueInvoiceProvider) return '-';

  const total = Number(valueInvoiceCompany) + Number(valueInvoiceProvider);

  return total;
};

export const TotalConsumeKwh = (consumeCompany, consumeProvider) => {
  if (!consumeCompany && !consumeProvider) return '-';

  const companyKwh = Number(consumeCompany);
  const companyProvider = Number(consumeProvider);

  const total = Number(companyKwh) + Number(companyProvider);

  return Number(total.toFixed()).toLocaleString('pt-BR');
};

export const formatCurrency = (
  value,
  withoutCents = false,
  withoutPrefix = false,
  maximumFractionDigits = 2
) => {
  if (!value) {
    if (!withoutPrefix) return 'R$ 0';
    if (withoutPrefix) return '0';
  }

  let format = new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits,
  }).format(value);

  if (withoutCents) format = format.split(',')?.[0];
  if (withoutPrefix) format = format.replace(/R\$\s/g, '');

  return format;
};

export const resumeOptionsMonths = (monthAndYear) => {
  const monthResume = monthAndYear.substring(0, 3);
  const yearResume = monthAndYear.substring(
    monthAndYear.length - 2,
    monthAndYear.length
  );

  return monthResume.concat(' - ', yearResume);
};

export const Months = {
  Jan: 'Janeiro',
  Fev: 'Fevereiro',
  Mar: 'Março',
  Abr: 'Abril',
  Mai: 'Maio',
  Jun: 'Junho',
  Jul: 'Julho',
  Ago: 'Agosto',
  Set: 'Setembro',
  Out: 'Outubro',
  Nov: 'Novembro',
  Dez: 'Dezembro',
};

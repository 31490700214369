import React, { useState } from 'react';
import { Button, Dialog, DialogContent } from '@mui/material';
import styled from 'styled-components';
import BasesManagement from '../BasesManagement';
import { NewModalFull } from '../NewModalFull';

const ModalRequestBaseManagement = ({ data }) => {
  const { btnText, title, path, processAccessor, processvalue } = data;
  const filter = { [processAccessor]: processvalue };
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant='outlined'
        onClick={openModal}
      >
        {btnText}
      </Button>

      {open && (
        <NewModalFull
          isOpen={open}
          onRequestClose={handleClose}
        >
          <DialogHeader>
            <Button onClick={handleClose}>x</Button>
          </DialogHeader>
          <DialogContent>
            <BasesManagement
              title={title}
              indentifier={path}
              filterBasesManagement={filter}
              maxHeighComponent={window.innerHeight - 400}
            />
          </DialogContent>
        </NewModalFull>
      )}
    </>
  );
};

const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: end;
  h2 {
    flex-grow: 1;
    text-align: center;
  }
`;

export default ModalRequestBaseManagement;

import * as Yup from 'yup';

import { formErrors } from '../../helpers/form/errors';

const FinishRegisterSchema = Yup.object().shape({
  password: Yup.string()
    .required(formErrors.required)
    .min(8, formErrors.minLength(8)),
  password_confirmation: Yup.string()
    .required(formErrors.required)
    .oneOf([Yup.ref('password'), null], formErrors.passwordMatch),
});

export default FinishRegisterSchema;

import { toast } from 'react-toastify';
import connection from '../connections/main';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';

const retry = async (payload) => {
  console.log(payload);
  const { err, data } = await connection.post(
    `/billingManagement/retry`,
    payload
  );

  if (err) {
    throw err;
  }
  toast.success(TOAST_MESSAGES.SUCCESS);

  return data;
};

const BasesManagementService = {
  retry,
};

export default BasesManagementService;

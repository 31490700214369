import * as Sentry from '@sentry/react';

Sentry.init({
  environment: import.meta.env.REACT_APP_ENV,
  dsn: 'https://adf00193994b562750d47c14ad7f7c8b@o4507069427482624.ingest.us.sentry.io/4507069460316160',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: ['*'],
  debug: false,
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});

export default Sentry;

import React from 'react';
import { Dialog } from '@mui/material';
import styled from 'styled-components';
import SignerForm from '../../../SignerForm';
import theme from '../../../../theme';

const SignerFormModal = ({ isOpen, setIsOpen, values, handleSubmit }) => {
  const handleClose = () => {
    setIsOpen(false);
  };
  const onCancel = (resetForm) => {
    resetForm();
    setIsOpen(false);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='dialog-signer-form'
      aria-describedby='dialog-update-signer'
      fullWidth
      maxWidth='sm'
    >
      <SignerFormContent>
        <TitleContainer>
          <h2>Dados do Signatário</h2>
        </TitleContainer>
        <SignerForm
          values={values}
          isEditing={true}
          onSubmit={handleSubmit}
          onCancel={onCancel}
        />
      </SignerFormContent>
    </Dialog>
  );
};

const SignerFormContent = styled.div`
  padding: 20px;
`;

const TitleContainer = styled.div`
  margin: 0;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  > h2 {
    font-size: 1.5rem;
    color: ${theme.palette.primary.main};
    font-weight: bold;
  }
`;

export default SignerFormModal;

import styled from 'styled-components';
import theme from '../../../../theme';

export const Heading3 = styled.h3`
  margin-bottom: 10px;
  margin-top: 20px;
  color: ${(props) => props.color || theme.palette.primary.main};
  font-size: 1.25rem;
  font-weight: bold;
`;

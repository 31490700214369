import CITIES from '../assets/Json/CITIES.json';

const providersCode = {
  'CPFL Santa Cruz': 'cpfl_santa_cruz',
  'CPFL Paulista': 'cpfl',
  CEMIG: 'cemig',
  // CELPE: 'celpe',
  LIGHT: 'light',
  'ENEL SP': 'enel_sp',
  ELEKTRO: 'elektro',
  ESS: 'ess',
};

const producersCode = {
  cpfl_santa_cruz: 'CGH Tito',
  cpfl: 'CGH JPH',
  // cemig: 'Âmbar',
  cemig: 'Copernico',
  // CELPE: 'celpe',
  light: 'TS0010',
};

export function normalizeString(string) {
  return string
    .split('')
    .map(
      function (letter) {
        const i = this.accents.indexOf(letter);
        return i !== -1 ? this.out[i] : letter;
      }.bind({
        accents:
          'ÀÁÂÃÄÅĄàáâãäåąßÒÓÔÕÕÖØÓòóôõöøóÈÉÊËĘèéêëęðÇĆçćÐÌÍÎÏìíîïÙÚÛÜùúûüÑŃñńŠŚšśŸÿýŽŻŹžżź',
        out: 'AAAAAAAaaaaaaaBOOOOOOOOoooooooEEEEEeeeeeeCCccDIIIIiiiiUUUUuuuuNNnnSSssYyyZZZzzz',
      })
    )
    .join('');
}

export const findProviderProfile = async (name = '', state = '') => {
  name = name || '';
  const normalizedCity = normalizeString(name);
  const lowerNormalizedCity = normalizedCity.toLowerCase();

  let t = [];
  // eslint-disable-next-line array-callback-return
  CITIES.map((el) => {
    const formattedCity = normalizeString(el[Object.keys(el)[0]]).toLowerCase();
    t.push({
      city: formattedCity,
      state: el[Object.keys(el)[1]],
      provider: el[Object.keys(el)[2]],
    });
  });

  let provider;
  let bool;
  let city;
  let prov = await Promise.all(
    t.map(async (el) => {
      if (
        el.city === lowerNormalizedCity &&
        state.toLowerCase() === el.state.toLowerCase()
      ) {
        let temp = providersCode[el['provider']];
        if (temp) {
          provider = temp;
          bool = true;
        } else {
          provider = el.provider;
          bool = false;
          city = el.city;
        }
      }
    })
  )
    .then(() => {
      if (bool === true) {
        const profile = 'target';
        return { provider, profile };
      } else if (bool === false) {
        const profile = 'on_hold';
        let providerAdjusted;
        providerAdjusted = provider;
        if (provider.includes(' ')) {
          providerAdjusted = provider.split(' ').join('_').trim();
        }
        if (provider.includes('-')) {
          providerAdjusted = provider.split('-').join('_').trim();
        }

        return { provider: providerAdjusted.toLowerCase(), profile };
      } else {
        return { provider: 'Corrija o erro na Cidade', profile: 'typo' };
      }
    })
    .catch((err) => {});

  return prov;
};
export const findProducer = (provider) => {
  if (provider === null || provider === undefined) {
    provider = '';
  }
  const producer = producersCode[provider];

  return producer;
};

export default { findProviderProfile, findProducer, CITIES, normalizeString };

import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from './styles';

export const NewModal = ({ children, ...rest }) => {
  return <Modal {...rest}>{children}</Modal>;
};

NewModal.propTypes = {
  children: PropTypes.node.isRequired,
  customBgColor: PropTypes.string,
};

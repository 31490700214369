/* eslint-disable no-redeclare */
/* eslint-disable no-throw-literal */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
import { toast } from 'react-toastify';

const fieldsPerTable = {
  locations: [
    'provider_id',
    'producer_id',
    'state',
    'discount',
    'city',
    'billing_day',
    'connection_type',
    'share',
    'volume',
    'icms',
    'connection_type',
    'te_liq',
    'tusd_liq',
    'use_producer_fees',
  ],
  credentials: ['install_code', 'pn'],
  measurements: ['measurements'],
  // pricings: [
  //   'icms',
  //   'pis',
  //   'cofins',
  //   'volume',
  //   'discount',
  //   'te_liq',
  //   'tusd_liq',
  // ],
};

const multiServices = {
  locations: {
    credentials: {
      get: 'getCredential',
      update: 'saveCredential',
    },
    measurements: {
      get: 'getMeasurements',
      update: 'saveMeasurements',
    },
    // pricings: {
    //   get: 'getPricing',
    //   update: 'savePricing',
    // },
  },
};

const getTablesForValue = (object, value) => {
  try {
    const tables = Object.keys(object).filter((key) =>
      object[key].includes(value)
    );
    if (tables?.length === 'undefined') throw { object, value };
    if (tables?.length === 0) return false;
    return tables;
  } catch (error) {
    console.log('file: setFieldsPerTable.js || line 38 || error', error);
    throw error;
  }
};

const checkForMultiService = (object, value) => {
  try {
    return Object.keys(object).find((key) =>
      Object.keys(object[key]).includes(value)
    );
  } catch (error) {
    return false;
  }
};

export const getServiceForTable = async (table) => {
  try {
    const exception = checkForMultiService(multiServices, table);

    const service = await import(
      `../../services/general/${exception || table}.service.js`
    )
      .then((importedModule) => {
        const { default: serviceFromTable } = importedModule;
        if (!exception) {
          return serviceFromTable;
        } else {
          const functionsToExtract = Object.entries(
            multiServices[exception][table]
          );
          const functions = {};
          functionsToExtract.map(([desiredName, actualName]) => {
            const extractedFunction = serviceFromTable[actualName];
            if (extractedFunction) functions[desiredName] = extractedFunction;
          });
          return functions;
        }
      })
      .catch((err) => {
        throw err;
      });
    return service;
  } catch (error) {
    console.log('file: setFieldsPerTable.js || line 37 || error', error);
    toast.error(error);
  }
};

export const prepareServicesAndPayload = async (formValues) => {
  try {
    const services = {};
    await Promise.all(
      Object.entries(formValues).map(async ([key, value]) => {
        const tables = getTablesForValue(fieldsPerTable, key);

        if (!tables) return;
        await Promise.all(
          tables.map(async (table) => {
            if (!services[table]) {
              services[table] = { payload: {} };
              const service = await getServiceForTable(table);
              Object.assign(services[table], { service });
            }
            if (value instanceof Array)
              return Object.assign(services[table], { payload: value });

            Object.assign(services[table].payload, { [key]: value });
          })
        );
      })
    );

    return services;
  } catch (error) {
    console.log('file: setFieldsPerTable.js || line 66 || error', error);
    throw error;
  }
};
const handleRequests = async (preparedServicesAndPayload, idsToPatchObject) => {
  try {
    await Promise.all(
      Object.entries(preparedServicesAndPayload).map(
        async ([table, { service, payload }]) => {
          const exception = checkForMultiService(multiServices, table);
          const { id, setState } = idsToPatchObject[table];

          if (exception) {
            if (id) Object.assign(payload, { id });
            var { id: idToRequest } = idsToPatchObject[exception];
            if (!idToRequest) throw 'no id to request';
          } else {
            var { id: idToRequest } = idsToPatchObject[table];
            if (!idToRequest) throw 'no id to request';
          }
          const data = await service.update(idToRequest, payload);
          if (data instanceof Array) return setState(data);
          setState((state) => ({ ...state, ...data }));
        }
      )
    );
  } catch (error) {
    console.log('file: setFieldsPerTable.js || line 120 || error', error);
    throw error;
  }
};

export const executePreparedServicesAndPayload = async (
  formValues,
  idsToPatchObject,
  setTriggerHandler
) => {
  try {
    if (typeof idsToPatchObject !== 'object')
      throw 'typeof idsToPatchObject is not object';

    const preparedServicesAndPayload = await prepareServicesAndPayload(
      formValues
    );
    if (!setTriggerHandler)
      return await handleRequests(preparedServicesAndPayload, idsToPatchObject);
    const statesToAwait = Object.keys(preparedServicesAndPayload);
    const newObject = {};
    statesToAwait.map((state) => {
      Object.assign(newObject, { [state]: false });
    });
    setTriggerHandler(newObject);

    return await handleRequests(preparedServicesAndPayload, idsToPatchObject);
  } catch (error) {
    console.log('🚀 ~ file: setFieldsPerTable.jsx:194 ~ error', error);
  }
};

/** */

export const preparePayloads = async (formValues) => {
  try {
    const services = {};
    await Promise.all(
      Object.entries(formValues).map(async ([key, value]) => {
        const tables = getTablesForValue(fieldsPerTable, key);

        if (!tables) return;
        await Promise.all(
          tables.map(async (table) => {
            if (!services[table]) {
              services[table] = {};
            }
            if (value instanceof Array)
              return Object.assign(services[table], { value });

            Object.assign(services[table], { [key]: value });
          })
        );
      })
    );

    return services;
  } catch (error) {
    console.log(
      '🚀 ~ file: setFieldsPerTable.jsx:220 ~ preparePayloads ~ error:',
      error
    );
    throw error;
  }
};

export const executePreparedPayload = async (formValues) => {
  try {
    const preparedPayload = await preparePayloads(formValues);

    return preparedPayload;
  } catch (error) {
    console.log('🚀 ~ file: setFieldsPerTable.jsx:194 ~ error', error);
  }
};

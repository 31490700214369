const FAQModalTexts = (providerName) => {
  switch (providerName) {
    case 'cpfl santa cruz':
    case 'cpfl piratininga':
    case 'cpfl paulista':
      return {
        style: 'cpfl',
        '1.1-text': 'Redução dos gastos de conta de luz por meio de créditos de energia gerados de fontes renováveis',
        '1.3.2-text': '(i) Todos os meses uma redução entre 10% e 25% da conta de luz...',
        '3.1.2-text': 'Todos os meses verificamos o quanto nossos créditos de energia reduziram da sua conta de luz e garantimos que o valor pago para a Flora, é menor do que o valor reduzido. Você passa a receber duas faturas:',
        '3.1.5-text':`Você mesmo poderá validar diretamente
na conta de luz a redução entregue pela
Flora Energia.`
      };
    default:
      return {
        style: 'default',
        '1.1-text': 'Redução no custo de energia através de fontes renováveis.',
        '1.3.2-text': '(i) Todos os meses uma redução entre 10% e 20% no seu custo de energia (varia de acordo com a sua região).',
        '3.1.2-text': 'Todos os meses verificamos o quanto nossos créditos de energia reduziram de sua conta de luz e cobramos um valor menor do que essa redução, garantindo sua economia. Você passará a receber duas faturas:',
        '3.1.5-text': `Não se preocupe! Todo esse processo é muito transparente. Você
mesmo poderá validar diretamente na conta de luz o desconto
entregue pela Flora Energia.`
      };
  }
}

export default FAQModalTexts;



import styled, { css } from 'styled-components';
import theme from '../../../theme';

export const FormContainer = styled.form`
  max-width: 648px;
  margin: 0 auto;
`;

export const Content = styled.div`
  margin: 0 3rem;

  ${(props) =>
    props.isNewPropertyOrHolder &&
    css`
      margin: 0;
    `}
`;

export const Title = styled.h2`
  margin: 3rem 0 1rem 0;
  color: ${theme.palette.primary.main};
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 1rem;
  color: #000000;
  margin-bottom: 1rem;
  font-weight: 700;
  color: ${theme.palette.primary.main};

  margin-top: ${(props) => (props.isNewPropertyOrHolder ? '1.5rem' : '1rem')};
`;

export const ContInput = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 36px; */
  padding: 1rem 0;

  div + div {
    margin-top: 1rem;
  }
`;

export const ContAddress = styled.div`
  margin-top: 1rem;

  a {
    margin-top: 1rem;
  }

  ${(props) =>
    props.isEqual &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.25rem;
    `}
`;

export const LinkContainer = styled.div`
  margin-top: 0.25rem;

  a {
    color: ${theme.palette.primary.main};
    font-weight: 700;
  }
`;

export const NoHasBillContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 0.625rem;
  margin-top: 1rem;
  padding-bottom: 3rem;
  /* box-shadow: 0px -3px 2px -3px rgba(0, 0, 0, 0.1),
  0px -5px 2px -3px rgba(0, 0, 0, 0.15); */

  button {
    width: 100%;
    padding: 0.563rem 0;
    font-size: 1.25rem;

    &:disabled {
      font-size: 1.25rem;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const Anchor = styled.a`
  &:hover {
    text-decoration: underline;
  }
  font-weight: 700 !important;
`;

import { create } from 'zustand';

const INITIAL_STATE = {
  contract: null,
  location: null, // one of the contract
  refetch: false,
};

export const useAdminContractDetailsStore = create((set) => ({
  ...INITIAL_STATE,
  setContract: (payload) =>
    set((state) => ({ ...state, contract: { ...payload } })),
  updateContract: (payload) =>
    set((state) => ({
      ...state,
      contract: { ...state.contract, ...payload },
    })),
  updateContractLocation: (locationId, payload) =>
    set((state) => {
      const { locations } = state.contract;
      const changedLocations = locations.map((l) =>
        l.id === parseInt(locationId) ? { ...l, ...payload, id: l.id } : l
      );
      return {
        ...state,
        contract: { ...state.contract, locations: changedLocations },
      };
    }),
  setRefetch: (payload) => {
    set((state) => ({ ...state, refetch: payload }));
  },
}));

import styled, { css } from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${theme.palette.purple.main};

  ${(props) =>
    props.disabled &&
    css`
      background: #e6e1e5;
      pointer-events: none;
    `}
`;

export const ToggleButton = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 16px 16px 3px 16px;
  cursor: pointer;

  ${(props) =>
    props.noTitle &&
    css`
      padding: 9px 8px 4px 8px;
    `}
`;

export const Name = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: #1c1b1f;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */

  ${(props) =>
    props.disabled &&
    css`
      color: #625d60;
    `}
`;

export const IconWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  pointer-events: none;
  margin: 0;

  @media (min-width: 992px) {
    margin-left: 20px;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 38px;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  max-height: 160px;
  overflow: auto;
  z-index: 2;

  ::-webkit-scrollbar {
    width: 13px;
  }

  ::-webkit-scrollbar-thumb {
    background: #949092;
    border-radius: 20px;
    border: 4px solid #fff;
  }
`;

export const MenuItemContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const ItemLink = styled.a`
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: flex; */
  display: block;
  position: relative;
  /* align-items: center; */
  /* justify-content: space-between; */
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  color: #1c1b1f;
  padding: 8px 4px;
  transition: all 0s;

  &:hover {
    background: #dfdfdf;
    color: #1c1b1f;
    transition: all 0.2s ease-in-out;
  }
`;

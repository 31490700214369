/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import ExcelRenderer from '../../utils/excelRenderer';
import { DropzoneCustom } from '../DropzoneCustom';

export const DefaultExcelRenderer = ({
  handleData = null,
  desiredColumns = null,
  setAllData = () => {},
  rerenderState = { rerender: true, setRerender: () => {} },
  handlerFileAfterChange = false,
  startRow = 0, //indica de qual linha deve começar a ler os dados, default: 0
  noMaxSize = false,
  setFile = () => {},
}) => {
  const acceptedFileTypes = ['.xlsx'];

  const { rerender, setRerender } = rerenderState;
  const checkModelAndGetDictionary = (titles) => {
    const dictionary = {};
    if (!desiredColumns) throw titles;

    for (let i = 0; i < titles.length; i++) {
      titles[i] = titles[i].replace(/^\s+/, '');
    }

    for (const desiredTitle of desiredColumns) {
      if (!titles.includes(desiredTitle)) throw 'Por favor utilize o modelo';
      const indexOf = titles.indexOf(desiredTitle);
      dictionary[indexOf] = desiredTitle;
    }
    return dictionary;
  };

  const getTreatedItem = (item, dictionary) => {
    const treatedItem = {};
    for (const key in dictionary) treatedItem[dictionary[key]] = item[key];
    return treatedItem;
  };

  const rowsHandler = (data, dictionary) => {
    try {
      const finalData = [];

      for (const item of data) {
        const treatedItem = getTreatedItem(item, dictionary);
        const finaItem = handleData ? handleData(treatedItem) : treatedItem;
        finalData.push(finaItem);
      }

      setAllData(finalData);
      setRerender(!rerender);
    } catch (error) {
      console.log('file: index.js || line 263 || error', error);
      throw error;
    }
  };
  const dataHandler = (data) => {
    const { rows } = data;

    const [titles, ...datas] = rows;

    const dictionary = checkModelAndGetDictionary(titles);
    rowsHandler(datas, dictionary);
  };

  const fileHandler = async (files) => {
    try {
      const fileObj = files[0];
      setFile(fileObj);
      const { type } = fileObj;
      if (
        type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
        throw "O formato do arquivo deve ser 'xlsx'";

      const data = await ExcelRenderer(
        fileObj,
        (err, resp) => {
          if (err) {
            console.log('🚀 ~ file: index.jsx:78 ~ fileHandler ~ err:', err);
            throw err;
          }
          return resp;
        },
        startRow
      );
      dataHandler(data);
    } catch (error) {
      const messageError =
        typeof error === 'string'
          ? error
          : 'Ocorreu um erro no processamento do arquivo';
      toast.error(messageError);
    }
  };

  return (
    <div>
      <DropzoneCustom
        maxFiles={1}
        fileHandler={fileHandler}
        acceptedFileTypes={acceptedFileTypes}
        handlerFileAfterChange={handlerFileAfterChange}
        noMaxSize
      />
    </div>
  );
};

DefaultExcelRenderer.propTypes = {
  handleData: PropTypes.func,
  desiredColumns: PropTypes.array,
  setAllData: PropTypes.func,
  rerenderState: PropTypes.func,
  handlerFileAfterChange: PropTypes.bool,
  headerLine: PropTypes.number,
  noMaxSize: PropTypes.bool,
};

import connection from '../connections/main';

const getAll = async () => {
  const { err, data } = await connection.get(`/promocodesBase`);

  if (err) {
    throw err;
  }

  return data;
};

const PromocodesBaseService = {
  getAll,
};

export default PromocodesBaseService;

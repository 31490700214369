import connection from './connections/main';

const create = async (payload) => {
  const { err, data } = await connection.put(`/promocodes`, {
    promocode: { ...payload },
  });

  if (err) {
    throw err;
  }

  return data;
};

const createBulk = async (promocodes) => {
  const { err, data } = await connection.post(`/promocodes/bulk`, {
    promocodes,
  });

  if (err) {
    throw err;
  }

  return data;
};

const update = async (payload) => {
  const { err, data } = await connection.put(`/promocodes/update`, {
    promocode: { ...payload },
  });
  if (err) {
    throw err;
  }
  return data;
};

const deleteOne = async (payload) => {
  const { err, data } = await connection.post('/promocodes/delete', {
    promocode: { ...payload },
  });
  if (err) throw err;

  return data;
};

const PromocodesService = {
  create,
  update,
  deleteOne,
  createBulk,
};

export default PromocodesService;

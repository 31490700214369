import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { colors, Grid } from '@mui/material';
import { StatusToggle } from '../../../components/Interface';

import PageView from '../../../containers/PageView';
import ContractsAdminService from '../../../services/administrator/contractDetails.service';

import ContractStagesSection from './components/ContractStagesSection';
import ContractCompanySection from './components/ContractCompanySection';
import ContractStatusSection from './components/ContractStatusSection';
import ContractSignerSection from './components/ContractSignerSection';
import PaymentSection from '../../../components/PaymentSection';
import ContractLocationsSection from './components/ContractLocationsSection';
import FlexRowContainer from '../../../componentsStyle/FlexRowContainer';
import Alerts from '../../../components/Alerts';
import adminStores from '../../../store/admin/contractDetails/index.store';
import { updateAdminStore } from '../../../store/admin/contractDetails/helpers';
import theme from '../../../theme';

const ContractDetails = () => {
  const { contractId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [showStages, setShowStages] = useState(false);
  const [showInvoicesStages, setShowInvoicesStages] = useState(false);

  const stores = adminStores();

  const {
    alerts: { alerts },
    signer: { signer },
    contract: { contract },
    location: { location },
  } = stores;

  const hasDoneReqs = { fetchDataNew: false };

  useEffect(() => {
    const fetchDataNew = async () => {
      try {
        const data = await ContractsAdminService.get(contractId);
        updateAdminStore(data);
      } catch (err) {
        console.log('file: index.jsx:58 || err:', err);
        setHasError(true);
      } finally {
        setLoading(false);
      }
    };

    if (!hasDoneReqs.fetchDataNew) fetchDataNew();
    return () => {
      hasDoneReqs.fetchDataNew = true;
    };
  }, [contractId]);

  const { reset: resetContactDetailsStore } = stores;

  useEffect(() => () => resetContactDetailsStore(), []);

  return (
    <ContracDetailsContainer>
      <AlertsContainer>
        <Alerts alerts={alerts} />
      </AlertsContainer>
      <PageView
        title='Detalhes do contrato'
        loading={loading}
        hasError={hasError}
        navigate={navigate}
      >
        <StagesContainer>
          <FlexRowContainer>
            <StatusToggle
              active={showStages}
              labelText={
                showStages ? 'Omitir troca de Status' : 'Troca de Status'
              }
              statusColors={[
                theme.palette.secondary.main,
                theme.palette.gray.main,
              ]}
              handleChange={() => setShowStages(!showStages)}
            />
            <StatusToggle
              active={showInvoicesStages}
              labelText={
                showInvoicesStages
                  ? 'Omitir mensagens de faturamento'
                  : 'Mensagens de faturamento'
              }
              statusColors={[
                theme.palette.secondary.main,
                theme.palette.gray.main,
              ]}
              handleChange={() => setShowInvoicesStages(!showInvoicesStages)}
            />
          </FlexRowContainer>

          {showStages && (
            <ContractStagesSection className='contract-invoices-stages-section' />
          )}

          {showInvoicesStages && (
            <ContractStagesSection
              type='invoicesStages'
              className='contract-stages-section'
            />
          )}
        </StagesContainer>

        <Grid
          container
          spacing={1}
          rowSpacing={1}
        >
          <Grid
            item
            xs={12}
            lg={6}
          >
            <ContractCompanySection className='contract-company-section' />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
          >
            <ContractStatusSection className='contract-status-section' />
          </Grid>
          {signer?.email ? (
            <Grid
              item
              xs={12}
              lg={6}
            >
              <ContractSignerSection className='contract-signer-section' />
            </Grid>
          ) : null}
          <Grid
            item
            xs={12}
            lg={6}
          >
            <PaymentSection
              isAdminPage={true}
              contract={{ ...contract, locations: [location] }}
              className='payment-section'
            />
          </Grid>

          <Grid
            container
            item
            xs={12}
            lg={12}
            spacing={1}
            rowSpacing={1}
          >
            <ContractLocationsSection />
          </Grid>
        </Grid>
      </PageView>
    </ContracDetailsContainer>
  );
};

export default ContractDetails;

const ContracDetailsContainer = styled.div``;

const StagesContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

const AlertsContainer = styled.div`
  margin: 0 auto;
  padding: 0 10px;
  position: sticky;
  top: 0;
  padding-top: 10px;
  z-index: 99;
  @media (max-width: 991px) {
    padding-top: 70px;
  }
  padding-bottom: ${(props) => (props.hasAlerts ? '16px !important' : 0)};
  background: #fff;
`;

export const getUrl = () => {
  try {
    if (!window?.location?.href) throw new Error('no url');
    const urls = window?.location?.href;
    const spliited = urls.split('/');
    const path = spliited.pop();
    return { url: spliited.join('/'), path };
  } catch (error) {
    console.log('file: getUrl.js || line 9 || error', error);
    return {};
  }
};

import * as Yup from 'yup';
import moment from 'moment';

import { formErrors } from '../../../helpers/form/errors';
import { formValidations } from '../../../helpers/form/validations';

const MeasurementsFormSchema = Yup.object().shape({
  measurements: Yup.array().of(
    Yup.object().shape({
      kwh: Yup.number().test(
        'month1MaxTest',
        formErrors.maxNumericValue(10),
        (value) => !value || formValidations.maxDigits(value, 10)
      ),
      date: Yup.string()
        .ensure()
        .test(
          'dateTest',
          formErrors.invalidDate,
          (value) =>
            value &&
            value.replace(/\D/g, '').length === 8 &&
            moment(value, 'yyyy-MM-DD').isValid()
        )
        .required(formErrors.required),
    })
  ),
});

const InputFormSchema = Yup.object().shape({
  discount: Yup.number()
    .min(0, 'O valor deve ser entre 0 e 100')
    .max(100, 'O valor deve ser entre 0 e 100')
    .required('Este campo é obrigatório'),
});

export { MeasurementsFormSchema, InputFormSchema };

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import styled from 'styled-components';
import GradientButton from '../../../../components/GradientButton';
import FlexRowContainer from '../../../../componentsStyle/FlexRowContainer';
import theme from '../../../../theme';

const initialValue = {
  city: '',
  state: null,
  provider_id: null,
};

const CitiesForm = ({ onSubmit, onCancel, states, providers }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState(initialValue);
  const [isValid, setIsValid] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((state) => ({
      ...state,
      [name]: value,
    }));
  };

  useEffect(
    () =>
      setIsValid(
        Object.values(values).every((valor) => valor !== null && valor !== '')
      ),
    [values]
  );

  const handleSubmit = () => {
    try {
      setIsSubmitting(true);
      onSubmit(values);
    } catch {
      setIsSubmitting(false);
    }
  };

  return (
    <ContainerForm>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs
          md={12}
        >
          <TextField
            id='city'
            label='Cidade'
            name='city'
            variant='outlined'
            fullWidth
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          xs
          md={6}
        >
          <FormControl fullWidth>
            <InputLabel id='state-select-label'>Estado</InputLabel>
            <Select
              labelId='state-select-label'
              id='state-select'
              value={values.state}
              label='Estado'
              name='state'
              onChange={handleChange}
            >
              {states.map((state) => (
                <MenuItem
                  key={state}
                  value={state}
                >
                  {state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs
          md={6}
        >
          <FormControl fullWidth>
            <InputLabel id='state-select-label'>Distribuidora</InputLabel>
            <Select
              labelId='provider-select-label'
              id='provider-select'
              name='provider_id'
              value={values.provider}
              label='Distribuidora'
              onChange={handleChange}
            >
              {providers.map((provider) => (
                <MenuItem
                  key={provider.id}
                  value={provider.id}
                >
                  {provider.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <ButtonsContainer>
        <GradientButton
          background='white'
          color={theme.palette.primary.main}
          border={`1px solid ${theme.palette.primary.main}`}
          text='Cancelar'
          paddingg='10px 30px'
          handleClick={() => onCancel()}
          outlined
          rectangle
        />
        <GradientButton
          paddingg='10px 30px'
          text='Cadastrar'
          handleClick={handleSubmit}
          isSubmitting={isSubmitting}
          rectangle
          marginLeft={24}
          disabled={!isValid}
        />
      </ButtonsContainer>
    </ContainerForm>
  );
};

const ContainerForm = styled.div`
  padding: 10px;
`;

const ButtonsContainer = styled(FlexRowContainer)`
  margin-top: 20px;
`;

CitiesForm.propTypes = {
  states: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CitiesForm;

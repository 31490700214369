const objectStores = [
  'contract',
  'location',
  'paymentMethod',
  'pricing',
  'credential',
  'signer',
  'output',
  'locationCredentials',
  'attributionChannel',
  'vwAttributionChannel',
];

const arrayStores = [
  'alerts',
  'cancellationReasons',
  'measurements',
  'producers',
  'providers',
  'locationClassificationProfiles',
  'classificationProfiles',
];

const storesConfigs = { object: objectStores, array: arrayStores };

const inferedStores = ['output', 'alerts'];

export { objectStores, arrayStores, storesConfigs, inferedStores };

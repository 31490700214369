import { useAuthStore } from '../store';

const verifyPermissionAction = (userId) => {
  const id = userId || useAuthStore.getState().userAuth.id;

  if (import.meta.env.REACT_APP_ENV !== 'prod') return true;

  const discount =
    import.meta.env.REACT_APP_ENV === 'prod'
      ? import.meta.env?.REACT_APP_DISCOUNT?.split(',').map((el) =>
          parseInt(el, 10)
        )
      : [];

  if (!discount.includes(id)) return false;
  return true;
};

export default verifyPermissionAction;

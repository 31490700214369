import connection from './connections/main';

const getAll = async () => {
  const { err, data } = await connection.get(`/stages`);

  if (err) {
    throw err;
  }

  return data;
};

const getAllFromContractId = async (contractId) => {
  const { err, data } = await connection.get(`/stages/contracts/${contractId}`);

  if (err) {
    throw err;
  }

  return data;
};

const create = async (payload) => {
  const { err, data } = await connection.put(`/stages`, {
    promocode: { ...payload },
  });

  if (err) {
    throw err;
  }

  return data;
};
const updateDone = async (stageId) => {
  const { err, data } = await connection.patch(`/stages/${stageId}`, {
    stage: { done: true },
  });

  if (err) {
    throw err;
  }

  return data;
};

const getAllActiveContracts = async () => {
  const { err, data } = await connection.get(`/stages/invoices`);

  if (err) {
    throw err;
  }

  return data;
};

const StagesService = {
  getAll,
  create,
  getAllFromContractId,
  updateDone,
  getAllActiveContracts,
};

export default StagesService;

import { isUserAuthenticated, removeAuthData, setUser } from '../store';
import { USER_ROLES_TYPES } from './userRoles';

export const redirectToInitialPage = async (
  navigate,
  userRole,
  redirectToRegister = false,
  path = ''
) => {
  if (!isUserAuthenticated()) {
    navigate('/login');
    return;
  }
  if (isUserAuthenticated && userRole === undefined) {
    await setUser();
    return;
  }
  if (userRole === USER_ROLES_TYPES.Admin) {
    window.localStorage.removeItem('company_id');
    navigate('/contratos');
  } else if (userRole === USER_ROLES_TYPES.Customer) {
    navigate('/home');
  } else if (userRole === USER_ROLES_TYPES.SetPassword) {
    navigate('/definir-senha');
  } else if (
    userRole === USER_ROLES_TYPES.NewCustomerAmbar ||
    userRole === USER_ROLES_TYPES.NewCustomerXP ||
    userRole === USER_ROLES_TYPES.NewCustomer
  ) {
    if (redirectToRegister) navigate('/cadastro');
    else {
      removeAuthData();
      navigateToLogin(path);
    }
  } else {
    navigateToLogin(path);
  }
};

const navigateToLogin = (path) => {
  const LoginPath = ['/', '/login'];
  if (!LoginPath.includes(path)) {
    navigate('/login');
  }
};

/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
const states = {
  AC: 'Acre',
  AL: 'Alagoas',
  AP: 'Amapá',
  AM: 'Amazonas',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MT: 'Mato Grosso',
  MS: 'Mato Grosso do Sul',
  MG: 'Minas Gerais',
  PA: 'Pará',
  PB: 'Paraíba',
  PR: 'Paraná',
  PE: 'Pernambuco',
  PI: 'Piauí',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RS: 'Rio Grande do Sul',
  RO: 'Rondônia',
  RR: 'Roraima',
  SC: 'Santa Catarina',
  SP: 'São Paulo',
  SE: 'Sergipe',
  TO: 'Tocantins',
};

export const stateInformed = (
  stateAcronym,
  wantTheAcronym = false,
  isPostalCodeEnteredCorrect = true
) => {
  if (wantTheAcronym || !isPostalCodeEnteredCorrect) {
    const [stateOfTheFederation] = Object.keys(states).filter((UF) => {
      if (!isPostalCodeEnteredCorrect) return states[UF] === stateAcronym;

      return UF === stateAcronym;
    });

    return stateOfTheFederation;
  }

  return states[stateAcronym];
};

const functionPerKey = {
  number: (number) => number,
  postal_code: (postal_code) => postal_code,
  state: (state) => state,
  city: (city) => (city ? city.split('(')[0].trim() : ''),
  street: (street) => (street ? street.split('-')[0].trim() : ''),
  neighborhood: (neighborhood) =>
    neighborhood ? neighborhood.split('-')[0].trim() : '',
};

export const getAddressPayload = (data) => {
  const { postal_code } = data;
  const finalPayload = { oldPostalCode: postal_code };

  for (const key in functionPerKey) {
    const functionToExecute = functionPerKey[key];
    const value = functionToExecute?.(data[key]) || '';
    const status = functionToExecute && value ? 'accepted' : 'awaiting';
    const values = { value, valueFromPostalCode: value };

    finalPayload[key] = { touched: !!value, status, ...values };
  }

  return finalPayload;
};

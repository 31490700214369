/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable default-case */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import PageView from '../../../containers/PageView';

import { LoadingCustomModal } from '../../../components/Modals/LoadingCustomModal';

import { DropzoneCustom } from '../../../components/DropzoneCustom';
import { DefaultExcelRenderer } from '../../../components/ExcelRenderer';
import GradientButton from '../../../components/GradientButton';
import { ExcelDateToJSDate } from '../../../helpers/converter';
import { getFinalMonth } from '../../../helpers/date';
import getUrlsFromS3Responses from '../../../helpers/getUrlsFromS3Responses';
import {
  checkPayloadMaxSize,
  getPayloadSize,
} from '../../../helpers/requestMananagement';
import firstContactRegisterService from '../../../services/attachments/firstContactRegister.service';
import generateInvoicesService from '../../../services/attachments/generateInvoices.service';
import {
  adjustmentExcelDate,
  desiredColumnsBalance,
  desiredColumnsBalanceLight,
  desiredColumnsBalanceLightMap,
  desiredColumnsVA,
  validationDate,
} from './helpers';
import { Header, ParagraphAndProviders, Radiobox } from './styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const MAX_PAYLOAD_SIZE = import.meta.env.REACT_APP_MAX_PAYLOAD_SIZE_IN_MB;

export const BillingManual = () => {
  const [open, setOpen] = useState(false);
  const [diff, setDiff] = useState(new Set());
  const [dialogActions, setDialogActions] = useState({
    onAgree: () => {},
    onDisagree: () => {},
  });

  const handleOpenSheetWarning = () => {
    return new Promise((resolve) => {
      const handleClose = (agree) => {
        setOpen(false);
        resolve(agree);
      };

      setDialogActions({
        onAgree: () => handleClose(true),
        onDisagree: () => handleClose(false),
      });
      setOpen(true);
    });
  };

  const handleCloseSheetWarning = () => {
    dialogActions.onDisagree();
    setOpen(false);
  };

  const [selected, setSelected] = useState({
    macroProvider: null,
    option: null,
    text: null,
    batch: null,
  });

  const [pageState] = useState({
    loading: false,
    hasError: false,
  });
  const [isSending, setIsSending] = useState(false);
  const navigate = useNavigate();
  const [avaliableOptionsPerMacroProvider] = useState({
    cpfl: [
      { option: 'preparedAttachment', batch: true },
      { option: 'unpreparedAttachment', batch: false },
    ],
    light: [
      { option: 'preparedAttachment', batch: true },
      { option: 'sheet', batch: true },
    ],
    cemig: [
      { option: 'preparedAttachment', batch: true },
      { option: 'sheet', batch: false },
    ],
    enel_sp: [{ option: 'preparedAttachment', batch: true }],
    enel_rj: [{ option: 'preparedAttachment', batch: true }],
    ess: [{ option: 'preparedAttachment', batch: true }],
    elektro: [{ option: 'preparedAttachment', batch: true }],
  });

  const [sheetData, setSheetData] = useState({
    clients: [],
    unhandledBalance: [],
  });
  const [sheetDataLight, setSheetDataLight] = useState({
    unhandledBalance: [],
  });

  const [sheetFiles, setSheetFiles] = useState([]);

  const [hasResetFiles, setHasResetFiles] = useState(false);

  const { loading, hasError } = pageState;

  const setHelperText = () => {
    const { option: selectedOption } = selected;
    let text;

    switch (selectedOption) {
      case 'unpreparedAttachment':
        text = 'Inclua todos os arquivos com as contas unificadas em PDF.';
        break;
      case 'sheet':
        text = `Inclua o excel disponiblizado no site da ${selected.macroProvider}.`;
    }

    return text ? <HelperText>{text}</HelperText> : null;
  };
  const getOption = (optionsPerMacroProvider) => {
    const { option, batch } = optionsPerMacroProvider;

    const { option: selectedOption } = selected;

    switch (option) {
      case 'preparedAttachment':
        return (
          <label>
            <input
              type='radio'
              name='option'
              onChange={() =>
                setSelected({
                  ...selected,
                  option: 'preparedAttachment',
                  batch,
                })
              }
              checked={selectedOption === 'preparedAttachment'}
            />
            PDF Único
          </label>
        );

      case 'unpreparedAttachment':
        return (
          <label>
            <input
              type='radio'
              name='option'
              onChange={() =>
                setSelected({
                  ...selected,
                  option: 'unpreparedAttachment',
                  batch,
                })
              }
              checked={selectedOption === 'unpreparedAttachment'}
            />
            PDF Consolidado
          </label>
        );
      case 'sheet':
        return (
          <label>
            <input
              type='radio'
              name='option'
              onChange={() =>
                setSelected({ ...selected, option: 'sheet', batch })
              }
              checked={selectedOption === 'sheet'}
            />
            Planilha
          </label>
        );
    }
  };

  const setOptionsPerMacroProvider = () => {
    const { macroProvider } = selected;
    const options = avaliableOptionsPerMacroProvider[macroProvider];
    if (!macroProvider) return;
    return (
      <>
        <Radiobox>
          <p>Selecione o modo:</p>
          {options.map(getOption)}
        </Radiobox>
        {setHelperText()}
      </>
    );
  };
  const handleRequest = async (payload) => {
    const { option: selectedOption, macroProvider } = selected;
    switch (selectedOption) {
      case 'preparedAttachment': {
        const formData = new FormData();
        formData.append('macroProviderCode', macroProvider);
        payload.forEach((attachment) => formData.append('file', attachment));
        payload.forEach(async (attachment) => {
          const unitFormData = new FormData();
          unitFormData.append('macroProviderCode', macroProvider);
          unitFormData.append('file', attachment);
          await generateInvoicesService.uploadPdf(unitFormData);
        });
        await generateInvoicesService.trigggerPreProcess(formData);
        break;
      }
      case 'unpreparedAttachment': {
        const time = Date.now();
        const responses = await Promise.all(
          payload.map((attachment) =>
            generateInvoicesService.uploadUnpreparedAttachment(attachment, time)
          )
        );
        const urls = getUrlsFromS3Responses(responses);
        await firstContactRegisterService.createUnpreparedAttachmentsRegister(
          urls,
          macroProvider
        );
        await generateInvoicesService.startBatchJob(time, payload);
        break;
      }
      case 'sheet': {
        if (!payload?.balance?.length) {
          toast.error(
            'Não foi feito nenhum cruzamento válido de informações entre as duas planilhas',
            {
              autoClose: false,
            }
          );
          return;
        }
        if (macroProvider === 'cemig') {
          const balanceInstallCodes = new Set(
            payload.balance.map((item) => item.Instalacao)
          );

          const agencyInstallCodes = new Set(
            payload.clients.map((item) => item.DGV_INSTALACAO)
          );

          const diff = new Set(
            [...agencyInstallCodes].filter((x) => !balanceInstallCodes.has(x))
          );

          if (diff.size) {
            setDiff(diff);
            const continueProcessing = await handleOpenSheetWarning();
            if (!continueProcessing) {
              return;
            }
          }
        }

        const time = Date.now();
        const responses = await Promise.all(
          sheetFiles.map((attachment) =>
            generateInvoicesService.uploadSheetAttachment(attachment, time)
          )
        );
        const urls = getUrlsFromS3Responses(responses);
        await firstContactRegisterService.createSheetRegisters(
          urls,
          macroProvider
        );
        await generateInvoicesService.createOcrsFromSheet(
          payload,
          macroProvider
        );
        break;
      }
    }
  };

  const createAttachmentsPayloads = (files) => {
    const maxSizePayload = MAX_PAYLOAD_SIZE * 1024 * 1024;
    const payloadFiles = [];
    let currentPayload = [];
    let currentPayloadSize = 0;

    for (const file of files) {
      if (currentPayloadSize + file.size > maxSizePayload) {
        payloadFiles.push(currentPayload);
        currentPayload = [];
        currentPayloadSize = 0;
      }

      if (file.size <= maxSizePayload) {
        currentPayload.push(file);
        currentPayloadSize += file.size;
      }
    }

    if (currentPayload.length > 0) {
      payloadFiles.push(currentPayload);
    }

    return payloadFiles;
  };

  const createSheetPayloads = (sheet) => {
    const maxSizePayload = MAX_PAYLOAD_SIZE * 1024 * 1024 * 0.5;
    const payload = [];
    let currentPayload = [];
    let currentPayloadSize = 0;

    for (const raw of sheet) {
      const rawSize = getPayloadSize(raw);

      if (currentPayloadSize + rawSize > maxSizePayload) {
        payload.push(currentPayload);
        currentPayload = [];
        currentPayloadSize = 0;
      }

      if (rawSize <= maxSizePayload) {
        currentPayload.push(raw);
        currentPayloadSize += rawSize;
      }
    }

    if (currentPayload.length > 0) {
      payload.push({ balance: currentPayload });
    }

    return payload;
  };

  const getPreparedPayloads = (payload) => {
    const { option: selectedOption, macroProvider, batch } = selected;
    if (!batch) return payload;
    switch (selectedOption) {
      case 'preparedAttachment':
        return createAttachmentsPayloads(payload);
      case 'sheet':
        if (macroProvider === 'light') {
          const { balance } = payload;
          return createSheetPayloads(balance);
        }
        throw new Error(
          `create getPreparedPayload for macroProvider ${macroProvider} `
        );
    }
  };

  const typeCheck = (preparedPayloads) => {
    const { option: selectedOption, macroProvider, batch } = selected;

    const errorMessage = `create getPreparedPayload for macroProvider ${macroProvider} and option ${selectedOption}, should be an array`;
    if (typeof preparedPayloads !== 'object') throw new Error(errorMessage);
    if (selectedOption === 'sheet' && macroProvider === 'cemig') {
      if (Array.isArray(preparedPayloads)) throw new Error(errorMessage);
    } else {
      if (!Array.isArray(preparedPayloads)) throw new Error(errorMessage);
    }
  };

  const handleRequests = async (payload) => {
    const { batch } = selected;
    const preparedPayloads = getPreparedPayloads(payload);

    typeCheck(preparedPayloads);

    if (!batch) return handleRequest(preparedPayloads);

    const promises = preparedPayloads.map(handleRequest);

    return Promise.allSettled(promises).then((results) => {
      const totalSuccess = results.filter(
        (r) => r.status === 'fulfilled'
      ).length;
      if (totalSuccess)
        toast.success(
          `${totalSuccess}/${results.length} sucessos no batch de upload.`
        );
      const totalErros = results.filter((r) => r.status === 'rejected').length;
      if (totalErros)
        toast.error(
          `${totalErros}/${results.length} erros no batch de upload.`
        );
    });
  };

  const handleSubmit = async (payload) => {
    const { macroProvider, option } = selected;
    if (!payload.length && option !== 'sheet') return;
    if (sheetFiles?.length < 1 && option === 'sheet') return;
    if (!macroProvider.length) return toast.error('Selecione a distribuidora');

    try {
      setIsSending(true);

      await handleRequests(payload);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSending(false);
    }
  };

  const handleSelectMacroProvider = useCallback(({ target: { value } }) => {
    setSelected({ ...selected, macroProvider: value });
  }, []);

  const getFinalInstallCodes = useCallback((clients) => {
    const finalInstallCodesPerPeriod = {};
    const finalData = [];
    for (const clientData of clients) {
      const { DGV_MES_CONTA, DGV_INSTALACAO } = clientData;

      const defaultErrorMessage =
        'não encontrado na planilha da agência virtual';

      if (!DGV_MES_CONTA)
        throw new Error(`DGV_MES_CONTA ${defaultErrorMessage}`);

      if (!DGV_INSTALACAO)
        throw new Error(`DGV_MES_CONTA ${defaultErrorMessage}`);

      const [unhandledMonth, year] = DGV_MES_CONTA.split('/');
      const month = getFinalMonth(unhandledMonth);

      if (!finalInstallCodesPerPeriod[year])
        finalInstallCodesPerPeriod[year] = {};
      if (!finalInstallCodesPerPeriod[year][month])
        finalInstallCodesPerPeriod[year][month] = [];
      if (finalInstallCodesPerPeriod[year][month].includes(DGV_INSTALACAO))
        continue;
      const payload = { install_code: DGV_INSTALACAO, month, year: +year };
      finalInstallCodesPerPeriod[year][month].push(DGV_INSTALACAO);
      finalData.push(payload);
    }
    return finalData;
  }, []);

  const getHandledBalance = useCallback((handledInstallCodes, balance) => {
    const finalData = [];

    for (const item of balance) {
      const { Periodo, ...finalItem } = item;

      const { Instalacao } = finalItem;
      const { month, year } = ExcelDateToJSDate(Periodo, ['year', 'month']);
      for (const installCodeItem of handledInstallCodes) {
        if (Instalacao !== installCodeItem.install_code) continue;
        if (month !== installCodeItem.month) continue;
        if (year !== installCodeItem.year) continue;
        finalData.push({ ...finalItem, month, year });
      }
    }

    return finalData;
  }, []);

  useEffect(() => {
    setHasResetFiles(true);
    setSelected({ ...selected, option: null, text: null });
    setSheetFiles([]);
  }, [selected.macroProvider]);
  useEffect(() => {
    setHasResetFiles(true);
  }, [selected.option]);

  useEffect(() => {
    try {
      const { clients, unhandledBalance } = sheetData;
      if (!(clients.length && unhandledBalance.length)) return;

      checkPayloadMaxSize(clients, unhandledBalance);

      const finalInstallCodesPayload = getFinalInstallCodes(clients);

      const balance = getHandledBalance(
        finalInstallCodesPayload,
        unhandledBalance
      );

      setSheetData({ ...sheetData, balance });
    } catch (error) {
      console.log('file: index.jsx:390 || error:', error);
      toast.error(error?.message);
      setSheetData({ clients: [], unhandledBalance: [] });
    }
  }, [sheetData.clients, sheetData.unhandledBalance]);

  useEffect(() => {
    try {
      const messageErrors = {
        no_data:
          'Não foi possível realizar o faturamento. Data de vencimento vazia',
        incorrect_format:
          'Não foi possível realizar o faturamento. Problema na formatação da data de vencimento',
        lower_than_today:
          'Não foi possível realizar o faturamento. Data de vcto passada',
      };
      const { unhandledBalance } = sheetDataLight;
      if (!unhandledBalance?.length) return;

      const unhandledBalanceFiltered = unhandledBalance.filter(
        (item) => item['Posto Horário (desc.)'] === 'Fora Ponta/Geral'
      );

      const balance = [];

      for (const itemFiltered of unhandledBalanceFiltered) {
        const item = {};
        for (const key in itemFiltered) {
          if (desiredColumnsBalanceLightMap.hasOwnProperty(key)) {
            const newKey = desiredColumnsBalanceLightMap[key];
            item[newKey] = itemFiltered[key];
          }
        }

        if (!item['data_vencimento']) throw new Error(messageErrors.no_data);
        else
          item['data_vencimento'] = adjustmentExcelDate(
            item['data_vencimento'],
            messageErrors
          );

        validationDate(item['data_vencimento'], messageErrors);

        balance.push(item);
      }
      setSheetDataLight({ ...sheetDataLight, balance });
    } catch (error) {
      console.log('🚀 ~ file: index.jsx:356 ~ useEffect ~ error:', error);
      toast.error(error?.message);
      setSheetDataLight({ unhandledBalance: [], balance: [] });
    }
  }, [sheetDataLight.unhandledBalance]);

  const { macroProvider, option } = selected;

  return (
    <PageView
      title='Faturamento Manual'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <LoadingCustomModal isLoading={isSending} />
      <Header>
        <ParagraphAndProviders>
          <p>Upload de contas de luz, para a execução de faturamento.</p>

          <Radiobox>
            <p>Selecione a distribuidora:</p>

            {Object.keys(avaliableOptionsPerMacroProvider).map(
              (availableMacroProvider) => (
                <label>
                  <input
                    type='radio'
                    name='radio'
                    value={availableMacroProvider}
                    onChange={handleSelectMacroProvider}
                    checked={macroProvider === availableMacroProvider}
                  />
                  {availableMacroProvider.toUpperCase().replace('_', ' ')}
                </label>
              )
            )}
          </Radiobox>
          {setOptionsPerMacroProvider()}
        </ParagraphAndProviders>
      </Header>

      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseSheetWarning}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>Tem certeza que deseja continuar?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description'>
            Não foi possível cruzar dados de {diff.size} instalações.
            <br />
            Os dados de <code>"período"</code> na planilha de Saldo, devem ser
            idênticos aos dados de <code>"DGV_MES_CONT"</code> da planilha
            Agência Virtual.
            <br />
            <div
              style={{
                marginTop: '1rem',
              }}
            >
              Instalações com divergências: <i>{Array.from(diff).join(', ')}</i>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogActions.onDisagree}>Cancelar</Button>
          <Button onClick={dialogActions.onAgree}>Continuar</Button>
        </DialogActions>
      </Dialog>

      {option === 'sheet' && selected.macroProvider === 'cemig' && (
        <>
          <ButtonContainer>
            <GradientButton
              handleClick={() => handleSubmit(sheetData)}
              variant='outlined'
              text='Enviar'
              disabled={isSending}
            />
          </ButtonContainer>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p>
              Planilha Agência Virtual:
              <DefaultExcelRenderer
                desiredColumns={desiredColumnsVA}
                isFinalDataObject
                setFile={(file) => {
                  setSheetFiles([...sheetFiles, file]);
                }}
                setAllData={(newData) =>
                  setSheetData({ ...sheetData, clients: newData })
                }
                handlerFileAfterChange={true}
              />
            </p>

            <p>
              Planilha Saldo de créditos:
              <DefaultExcelRenderer
                desiredColumns={desiredColumnsBalance}
                isFinalDataObject
                setFile={(file) => {
                  setSheetFiles([...sheetFiles, file]);
                }}
                setAllData={(newData) =>
                  setSheetData({ ...sheetData, unhandledBalance: newData })
                }
                handlerFileAfterChange={true}
              />
            </p>
          </div>
        </>
      )}

      {option === 'sheet' && selected.macroProvider === 'light' && (
        <>
          <ButtonContainer>
            <GradientButton
              handleClick={() => handleSubmit(sheetDataLight)}
              variant='outlined'
              text='Enviar'
              disabled={isSending}
            />
          </ButtonContainer>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p>
              Planilha Saldo de créditos:
              <DefaultExcelRenderer
                desiredColumns={desiredColumnsBalanceLight}
                isFinalDataObject
                setFile={(file) => {
                  setSheetFiles([...sheetFiles, file]);
                }}
                setAllData={(newData) =>
                  setSheetDataLight({
                    unhandledBalance: newData,
                  })
                }
                handlerFileAfterChange={true}
                startRow={1}
                noMaxSize={true}
              />
            </p>
          </div>
        </>
      )}

      {option && option !== 'sheet' && (
        <DropzoneCustom
          maxFiles={50}
          acceptedFileTypes={['.pdf']}
          fileHandler={handleSubmit}
          disabled={isSending}
          hasResetFiles={hasResetFiles}
          isButtonInTop={true}
          noMaxSize={selected?.option === 'unpreparedAttachment'}
        />
      )}
    </PageView>
  );
};

const ButtonContainer = styled.div`
  width: 988px;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 30px;
`;
const HelperText = styled.div`
  background: #fff1c5;
  color: #5e4646;
  padding: 5px 10px;
  border-radius: 5px;
  width: max-content;
`;

import React from 'react';
import { Container, Content } from './styles';

export const Checkbox = ({ children, ...rest }) => {
  if (!children) {
    throw new Error();
  }

  return (
    <Container>
      <Content>
        <input type='checkbox' {...rest} />
        <span />
      </Content>
      {children}
    </Container>
  );
};

import { FormControlLabel, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import CompaniesService from '../../../services/general/companies.service';
import GradientButton from '../../GradientButton';

function CompanyIntegrationForm({ onSubmit, data }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(null);
  const { companyId } = useParams();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    onSubmit({ ...formData, id: companyId }, { isSubmitting });
    setIsSubmitting(false);
    setIsEditing(false);
  };

  const handleChangePoweredBy = (e) => {
    const { checked } = e.target;
    setFormData((state) => ({
      ...state,
      show_powered_by: checked,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFormData({
      video_link: data?.video_link,
      signer_key: data?.signer_key,
      iugu_key: data?.iugu_key,
      comunicator_key: data?.comunicator_key,
      show_powered_by: data?.show_powered_by,
    });
  }, []);

  return (
    <form
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <MainCont>
        <ColumnContainer>
          <>
            <TextField
              variant='outlined'
              label='Video'
              placeholder='Video Institucional'
              onChange={handleChange}
              style={{ marginTop: '1rem' }}
              name='video_link'
              disabled={!isEditing}
              defaultValue={data?.video_link || ''}
            />
            <div style={{ marginTop: '2rem' }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!isEditing}
                    name='show_powered_by'
                    onChange={handleChangePoweredBy}
                    defaultChecked={data?.show_powered_by}
                  />
                }
                label='Mostrar PoweredBy'
              />
            </div>
          </>
        </ColumnContainer>
        <ColumnContainer>
          <>
            <TextField
              variant='outlined'
              label='Chave ElfSight'
              placeholder='Chave ElfSight'
              onChange={handleChange}
              style={{ marginTop: '1rem' }}
              name='comunicator_key'
              disabled={!isEditing}
              defaultValue={data?.comunicator_key || ''}
            />

            <TextField
              variant='outlined'
              label='Chave ClickSign'
              placeholder='Chave ClickSign'
              onChange={handleChange}
              style={{ marginTop: '1rem' }}
              name='signer_key'
              disabled={!isEditing}
              defaultValue={data?.signer_key || ''}
            />
          </>
        </ColumnContainer>
      </MainCont>
      {!isEditing && (
        <div
          style={{
            marginTop: '1rem',
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <GradientButton
            text='Alterar'
            handleClick={() => setIsEditing(true)}
          />
        </div>
      )}
      {isEditing && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '1.5rem',
          }}
        >
          <GradientButton
            text='Enviar'
            type='submit'
            handleClick={handleSubmit}
          />
          <GradientButton
            text='Cancelar'
            handleClick={() => setIsEditing(false)}
          />
        </div>
      )}
    </form>
  );
}

export default CompanyIntegrationForm;

const MainCont = styled.section`
  display: flex;
  justify-content: space-around;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
`;

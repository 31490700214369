import ReactModal from 'react-modal';
import styled, { css } from 'styled-components';
import theme from '../../../theme';

ReactModal.setAppElement('#root');

const modalCustomStyle = {
  content: {
    padding: 0,
    position: 'relative',
    inset: 0,
    ':focusVisible': { outline: '0' },
    margin: '0.75rem',
    width: '100%',
    maxWidth: '687px',
    height: '100%',
    maxHeight: '461px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    inset: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export const Modal = styled(ReactModal).attrs({
  style: modalCustomStyle,
})`
  background: #fbfdff;
  border-radius: 0.25rem;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem;
  height: 100%;

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
    color: ${theme.palette.primary.main};
  }
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -0.438rem;
  right: -0.875rem;
  border: 0;
  background: #fbfdff;
  transition: filter 0.2s;
  padding: 0.5rem;
  border-radius: 50%;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 360px;
`;

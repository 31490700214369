import { Grid } from "@mui/material";
import moment from "moment";
import { CustomErrorMessage, CustomField, FieldWrapper, Label } from "../../../../../../components/Interface/Form";
import { Heading3, Paragraph } from "../../../../../../components/Interface/Text";

const Output = ({ values }) => {
  return (
    <Grid container spacing={2} rowSpacing={2}>
      <Grid item xs={12}>
        <Heading3>Output</Heading3>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FieldWrapper>
          <Label htmlFor='revenueFee'>Preço de venda (R$/KWh):</Label>
          <CustomField
            name='sale_price'
            type='number'
            disabled={true}
            fullwidth={1}
          />
          <CustomErrorMessage name='revenueFee' />
        </FieldWrapper>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FieldWrapper>
          <Label htmlFor='price_floor'>Preço Piso Gerador:</Label>
          <CustomField
            name='price_floor'
            type='number'
            disabled={true}
          />
          <CustomErrorMessage name='price_floor' />
        </FieldWrapper>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FieldWrapper>
          <Label htmlFor='new_value'>Mensalidade (R$): </Label>
          <CustomField
            type='number'
            name='value_cents'
            disabled={true}
            fullwidth={1}
          />
          <CustomErrorMessage name='new_value' />
        </FieldWrapper>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FieldWrapper>
          <Label htmlFor='share'>Rateio (%):</Label>
          <CustomField
            type='number'
            name='share'
            disabled={true}
            fullwidth={1}
          />
          <CustomErrorMessage name='share' />
        </FieldWrapper>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <FieldWrapper>
          <Label
            htmlFor='activated_at'
            style={{
              marginBottom: '0',
            }}
          >
            Data de ativação com a distribuidora:
          </Label>

          <Paragraph
            style={{
              width: '100%',
              textAlign: 'left',
              color: '#446063',
            }}
          >
            {values?.activated_at
              ? moment(values?.activated_at).format('DD/MM/yyyy')
              : ''}
          </Paragraph>
        </FieldWrapper>
      </Grid>

    </Grid>
  )
}
export default Output;
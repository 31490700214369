import { fixDecimalPoints } from './data';

export const getWithFloraValue = (invoice) => {
  try {
    const { producer_total_cents, total_cents, value_cents, gift } = invoice;

    if ((total_cents || value_cents) > 0 || gift !== true)
      return fixDecimalPoints(
        (producer_total_cents + (total_cents || value_cents)) / 100
      );
    else if (gift === true) return fixDecimalPoints(producer_total_cents / 100);
  } catch (error) {
    console.log('file: invoices.js || line 18 || error', error);
    return 0;
  }
};

export const setIsOverdue = (invoice) => {
  try {
    const { status, gift, due_date } = invoice;

    if (gift) return false;
    if (status === 'paid') return false;
    return Date.now() > Date.parse(due_date);
  } catch (error) {
    return false;
  }
};

import styled from 'styled-components';

export const BubbleSpeech = styled.span`
  position: relative;
  display: inline-block;

  &.p-1 {
    background: #9abdce;
  }
  &.p-2 {
    background: #ff9900;
  }

  &:hover:after {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(100% + 9px);
    z-index: 99;
    content: attr(data-comment);
    white-space: nowrap;
    background: #777;
    color: #fff;
    font-size: 10px;
    text-align: center;
    height: 16px;
    line-height: 16px;
    padding: 2px 8px;
    border-radius: 5px;
  }

  &:hover:before {
    z-index: 99;
    position: absolute;
    left: calc(100% - 8px);
    top: 4px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #777 transparent transparent;
    width: 0;
    height: 0;
    content: '';
    line-height: 1;
    margin: 0;
    box-sizing: border-box;
    padding: 0;
  }

  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`;

export default BubbleSpeech;

import React, { useEffect } from 'react';
import GradientButton from '../GradientButton';
import CircularProgress from '@mui/material/CircularProgress';

function LoadMore({ loadingg, setPage, noMore = false }) {
  return (
    <>
      {!noMore && (
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          {loadingg ? (
            <GradientButton paddingg={'10px 30px'} text=''>
              <CircularProgress />
            </GradientButton>
          ) : (
            <GradientButton
              paddingg={'10px 30px'}
              text='Carregar Mais'
              handleClick={() => setPage((page) => page + 1)}
            />
          )}
        </div>
      )}
    </>
  );
}

export default LoadMore;

import connection from '../connections/main';

const getAll = async () => {
  const { err, data } = await connection.get(`/giftManagement`);

  if (err) {
    throw err;
  }

  return data;
};

const updateAll = async (payload) => {
  const { err, data } = await connection.put(`/giftManagement/updateAll`, {
    giftManagement: payload,
  });

  if (err) {
    throw err;
  }

  return data;
};

const GiftManagementService = {
  getAll,
  updateAll,
};

export default GiftManagementService;

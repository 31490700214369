export const extractParams = (location) => {
  const finalParams = {};
  const { search } = location || {};
  if (!search) return {};
  const unhandledParams = search.split('?')?.[1];

  if (!unhandledParams) return {};
  const spliitedParams = unhandledParams.split('&');
  for (const param of spliitedParams) {
    const [key, value] = param.split('=');
    finalParams[key] = value;
  }
  return finalParams;
};

import React from 'react';
import { MdClose } from 'react-icons/md';
import { NewModal } from '../../NewModal';
import {
  CloseButton,
  Container,
  Content,
  Header,
  SubItems,
  Text,
  Title,
} from './styles';
import theme from '../../../theme';
import FAQModalTexts from './FAQModalTexts';

export const FAQModal = ({ isOpen, onRequestClose, provider }) => {
  const text = FAQModalTexts(provider.toLowerCase());
  return (
    <NewModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Container>
        <Header>
          <h2>Perguntas frequentes</h2>
          <CloseButton onClick={onRequestClose}>
            <MdClose
              size='1.5rem'
              color={theme.palette.primary.main}
            />
          </CloseButton>
        </Header>
        <Content>
          <ul>
            <li>
              <Title>1. Produto</Title>

              <SubItems>
                <h6>1.1 O que é o produto?</h6>
                <Text>
                  {text['1.1-text']}
                </Text>
              </SubItems>

              <SubItems>
                <h6>1.2 Como funciona?</h6>
                <Text>O processo é muito simples:</Text>
                <Text>
                  &#40;i&#41; A rede de parceiros da Flora Energia gera energia
                  renovável e fornece para a rede elétrica tradicional.
                </Text>
                <Text>
                  &#40;ii&#41; Esse fornecimento é compensado com créditos de
                  energia renovável dados pela distribuidora.
                </Text>
                <Text>
                  &#40;iii&#41; Alocamos esses créditos em sua conta de luz,
                  reduzindo o valor cobrado pela distribuidora.
                </Text>
              </SubItems>

              <SubItems>
                <h6>1.3 Quanto vou economizar?</h6>
                <Text>São duas formas de economizar com a Flora Energia:</Text>
                <Text>
                  {text['1.3.2-text']}
                </Text>
                <Text>
                  &#40;ii&#41; Em meses de bandeiras tarifárias, economia
                  adicional entre 5% e 15% &#40;varia de acordo com o tipo de
                  bandeira&#41;.
                </Text>
              </SubItems>

              <SubItems>
                <h6>1.4 Existe risco ao contratar a Flora Energia?</h6>
                <Text>
                  Nenhum! Você continua sendo abastecido pela sua distribuidora
                  da forma como é hoje.
                </Text>

                <Text>
                  A única diferença é que a Flora Energia vai reduzir o valor da
                  sua conta de luz com créditos de energia renovável gerados em
                  suas fazendas sustentáveis parceiras.
                </Text>

                <Text>
                  Caso a Flora Energia não consiga reduzir este valor por
                  completo, você pagará a diferença de forma automática e nas
                  mesmas condições atuais para a sua distribuidora.
                </Text>

                <Text>
                  Isso pode acontecer por conta da sazonalidade de geração de
                  energia de nossas fazendas parceiras, assim como do seu
                  consumo.
                </Text>
              </SubItems>

              <SubItems>
                <h6>1.5 Quais fontes de energia a Flora utiliza?</h6>
                <Text>Utilizamos apenas fontes renováveis de energia.</Text>
                <Text>
                  Atualmente, nossos geradores parceiros utilizam fontes
                  distintas como solar, hídrica e biogás.
                </Text>
              </SubItems>

              <SubItems>
                <h6>1.6 Como a Flora Energia ganha dinheiro?</h6>
                <Text>
                  Cobramos um valor mensal dos geradores de energia parceiros
                  pelo serviço de gestão comercial e administrativa das fazendas
                  sustentáveis.
                </Text>
              </SubItems>
            </li>

            <li>
              <Title>2. Adesão</Title>

              <SubItems>
                <h6>2.1 Quem pode aderir?</h6>

                <Text>
                  Qualquer consumidor <strong>residencial</strong> ou{' '}
                  <strong>comercial</strong>, cuja tarifa seja convencional,
                  conectado em <strong>baixa tensão</strong>.
                </Text>
                <Text>» Vale para casa ou apartamento.</Text>
                <Text>
                  » Vale para <strong>pessoa física</strong> ou{' '}
                  <strong>jurídica</strong>.
                </Text>
                <Text>
                  <strong>Importante:</strong> Não atendemos clientes que
                  possuam geração própria de energia (como painéis solares) ou
                  beneficiários de outra empresa de Geração Distribuída.
                </Text>
              </SubItems>

              <SubItems>
                <h6>
                  2.2 É necessário algum tipo de obra para aderir ao serviço?
                </h6>

                <Text>
                  Não! Utilizamos a infraestrutura da sua distribuidora para
                  levar nossa energia até você. A adesão é 100% digital.
                </Text>
              </SubItems>

              <SubItems>
                <h6>
                  2.3 Posso fazer o cadastro se eu não for o titular da conta de
                  luz?
                </h6>

                <Text>
                  Sim, mas apenas o titular da conta de luz pode assinar o termo
                  de adesão à nossa solução. Se a conta estiver em nome de um
                  terceiro que não queira assinar a adesão, é possível realizar
                  a troca de titularidade junto à sua distribuidora.
                </Text>
              </SubItems>

              <SubItems>
                <h6>
                  2.4 Posso cadastrar mais de um imóvel na plataforma da Flora
                  Energia?
                </h6>

                <Text>
                  Sim! Depois de se cadastrar, você terá acesso à plataforma
                  Flora Energia, onde poderá cadastrar outros imóveis na área do
                  cliente clicando em Imóveis - Novo Imóvel no canto esquerdo da
                  tela.
                </Text>
              </SubItems>

              <SubItems>
                <h6>
                  2.5 Quais são as diferenças entre aderir a Flora Energia e
                  instalar placas solares?
                </h6>

                <Text>
                  Instalar placas fotovoltáicas é uma excelente alternativa, mas
                  requer investimento, tempo e experiência para geri-las
                  corretamente.
                </Text>
                <Text>
                  A solução da Flora Energia utiliza usinas renováveis próprias
                  para enviar energia &#40;em forma de créditos&#41; até sua
                  casa.
                </Text>
                <Text>
                  Com a nossa alternativa você não precisa investir, não há
                  alterações no seu imóvel e não precisa se preocupar com a
                  performance nem manutenção das placas solares.
                </Text>
                <Text>
                  Por outro lado, seu desconto não será tão relevante em relação
                  ao uso de placas.
                </Text>
              </SubItems>
            </li>

            <li>
              <Title>3. Serviço</Title>

              <SubItems>
                <h6>3.1 Quanto vou pagar por isso?</h6>

                <Text>Não há cobrança para aderir nem cancelar.</Text>
                <Text>
                  {text['3.1.2-text']}
                </Text>

                <Text>
                  &#40;i&#41; A conta de luz da distribuidora com o desconto
                  proporcionado por nossos créditos.
                </Text>
                <Text>
                  &#40;ii&#41; A fatura da Flora Energia referente aos créditos
                  entregues.
                </Text>
                <Text>
                  {text['3.1.5-text']}
                </Text>
              </SubItems>

              <SubItems>
                <h6>3.2 Como posso pagar?</h6>

                <Text>
                  Você receberá a fatura da Flora Energia com a mesma data de
                  vencimento da conta de luz da distribuidora.
                </Text>
                <Text>
                  Para o pagamento, poderá escolher cartão de crédito, PIX ou
                  boleto bancário.
                </Text>
              </SubItems>

              <SubItems>
                <h6>3.3 Quando vou receber o desconto?</h6>

                <Text>
                  Após a assinatura de seu termo de adesão e o envio de sua
                  conta de luz, avisaremos por e-mail quando uma de nossas novas
                  fazendas sustentáveis estiver em operação para atendê-lo.
                </Text>
                <Text>
                  A partir do momento do comunicado por e-mail, os créditos de
                  energia e os descontos serão entregues em sua conta dentro do
                  prazo de até 60 dias.
                </Text>
                <Text>
                  Não se preocupe! No intervalo de espera, você continua pagando
                  normalmente a conta de luz da distribuidora.
                </Text>
              </SubItems>

              <SubItems>
                <h6>3.4 E se eu quiser cancelar?</h6>

                <Text>
                  Basta entrar em contato pelos nossos canais de atendimento.
                </Text>
                <Text>
                  Solicitamos um aviso prévio de 90 dias para descadastrar seus
                  benefícios junto à distribuidora.
                </Text>
                <Text>
                  Durante esse período, você continuará recebendo o desconto em
                  sua conta de luz e, portanto, seguirá recebendo a fatura da
                  Flora Energia.
                </Text>
              </SubItems>

              <SubItems>
                <h6>3.5 O que acontece se eu mudar de endereço?</h6>

                <Text>
                  Não tem problema! Você poderá continuar recebendo o desconto
                  em seu novo endereço, desde que dentro de uma região atendida
                  pela Flora.
                </Text>
                <Text>
                  Basta nos avisar com antecedência de 90 dias para
                  transferirmos os benefícios para o novo imóvel.
                </Text>
                <Text>
                  O mesmo vale caso troque a titularidade de sua conta de luz.
                </Text>
              </SubItems>

              <SubItems>
                <h6>3.6 Vou continuar sendo cliente da distribuidora?</h6>

                <Text>
                  Sim, pois ela ainda será encarregada de levar energia até sua
                  casa, assim como pela manutenção da infraestrutura da rede
                  elétrica.
                </Text>
                <Text>
                  Basta nos avisar com antecedência de 90 dias para
                  transferirmos os benefícios para o novo imóvel.
                </Text>
                <Text>
                  O mesmo vale caso troque a titularidade de sua conta de luz.
                </Text>
              </SubItems>

              <SubItems>
                <h6>
                  3.7 Caso ocorra uma queda de luz no meu imóvel, quem devo
                  avisar?
                </h6>

                <Text>
                  Caso ocorra uma queda de luz, contate a distribuidora local,
                  ela é a responsável técnica pela transmissão da energia até
                  sua casa.
                </Text>
              </SubItems>
            </li>

            <li>
              <Title>4. Setor</Title>

              <SubItems>
                <h6>4.1 O que é geração distribuída?</h6>

                <Text>
                  Geração distribuída é o termo utilizado para referenciar a
                  geração de energia que é diretamente conectada à rede de
                  distribuição, sendo válida para diversas fontes de energia
                  renováveis como solar, eólica, hídrica e de biogás.
                </Text>
                <Text>
                  Este mercado nasceu para empoderar o consumidor e incentivar
                  fontes de energia limpas, sendo a única alternativa ao
                  monopólio das distribuidoras de energia.
                </Text>
              </SubItems>

              <SubItems>
                <h6>4.2 O que é um crédito de energia?</h6>

                <Text>
                  A Resolução Normativa 482 criou o Sistema de Compensação de
                  Energia Elétrica, no qual toda energia gerada e injetada na
                  rede da distribuidora é concedida na forma de empréstimo.
                </Text>
                <Text>
                  A distribuidora então concede ao consumidor os chamados
                  créditos de energia, que são utilizados para compensar a
                  energia que foi consumida da distribuidora.
                </Text>
                <Text>
                  Esses créditos possuem um prazo de 60 meses &#40;5 anos&#41;
                  para serem utilizados.
                </Text>
              </SubItems>

              <SubItems>
                <h6>4.3 O que é custo de disponibilidade?</h6>

                <Text>
                  Custo de disponibilidade é o valor cobrado pela sua
                  concessionária para disponibilizar o serviço de eletricidade
                  no seu imóvel.
                </Text>
                <Text>
                  Logo, essa taxa contempla o custo da infraestrutura que visa
                  assegurar que a energia elétrica seja ofertada aos moradores
                  de determinado local. Mesmo que você não consuma eletricidade,
                  precisa pagar somente pelo fato de a energia estar disponível.
                </Text>
              </SubItems>

              <SubItems>
                <h6>4.4 O que é reajuste tarifário?</h6>

                <Text>
                  É um dos mecanismos de atualização do valor da energia paga
                  pelo consumidor, aplicado anualmente, de acordo com fórmula
                  prevista no contrato de concessão. Seu objetivo é restabelecer
                  o poder de compra da concessionária. Os reajustes acontecem em
                  datas determinadas pelo contrato de concessão e são divulgados
                  pela Aneel.
                </Text>
              </SubItems>

              <SubItems>
                <h6>4.5 O que é bandeira tarifária?</h6>

                <Text>
                  É o sistema que sinaliza aos consumidores os custos reais da
                  geração de energia elétrica. O funcionamento é simples: as
                  cores das bandeiras &#40;verde, amarela ou vermelha&#41;
                  indicam se a energia custará mais ou menos em função das
                  condições de geração de eletricidade.
                </Text>
                <Text>
                  Com as bandeiras tarifárias, a conta de luz fica mais
                  transparente e o consumidor tem a melhor informação para usar
                  a energia elétrica de forma mais consciente.
                </Text>
              </SubItems>
            </li>
          </ul>
        </Content>
      </Container>
    </NewModal>
  );
};

import { createGlobalStyle } from 'styled-components';

const breakpoints = {
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  xxl: '96em',
};

const { sm } = breakpoints;

export const GlobalStyle = createGlobalStyle`

  * {
    font-family: 'Raleway';
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 12px;

     /* @media (max-width: 1366px) {
    }

    @media (max-width: 992px) {
    }

    @media (max-width: 768px) {
    }

     @media (max-width: 576px) {
      font-size: 87.5%
    } */

    @media (min-width: ${sm}) {
      font-size: 16px;
    }
  }
  
  body {
    /* background: #fff; */
    -webkit-font-smoothing: antialiased;
  }

  button {
    cursor: pointer;
  }

  /* .react-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-modal-content {
    width: 90%;
    max-width: 576px;
    background: #f0f2f5;
    padding: 3rem;
    position: relative;
    border-radius: 0.25rem;

    &:focus-visible {
      outline: 0;
    }
  } */
  
  /* .react-modal-loading {
    &:focus-visible {
      outline: 0;
    }
  } */

  /* .react-modal-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    border: 0;
    background: transparent;
    transition: filter 0.2s;
    
    &:hover {
      filter: brightness(0.8);
    }
  } */
`;

// TODOS OS LINKS UNDERLINE APENAS NO HOVER

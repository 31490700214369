import styled, { css } from 'styled-components';
import theme from '../../../theme';

const isFirstLine = css`
  border-bottom: 1px solid ${theme.palette.primary.main};
`;

export const Container = styled.div`
  max-width: 648px;
  margin: 0 auto;
`;

export const Content = styled.div`
  margin: 0 3rem;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.isNewPropertyOrHolder &&
    css`
      margin: 0;
    `}
`;

export const Title = styled.h2`
  margin: 3rem 0 0 0;
  color: ${theme.palette.primary.main};
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 0.625rem;
  margin-bottom: 3rem;

  button {
    width: 100%;
    padding: 0.563rem 0;
    font-size: 1.25rem;

    &:disabled {
      font-size: 1.25rem;
    }

    @media (min-width: 992px) {
      flex-direction: row;
    }
  }
`;

export const ImageContainer = styled.div`
  justify-content: center;
  margin-top: 5.625rem;
  display: none;

  @media (max-width: 768px) {
    display: flex;

    img {
      width: 11.625rem;
      height: 3.75rem;
    }
  }
`;

export const LineOneContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.palette.primary.main};
  padding: 1.5rem 0;

  label {
    width: 11.75rem;
    font-size: 1.125rem;
    font-weight: 700;
    flex: 1;
    color: #1c1b1f;
    margin: 0;
  }

  input {
    font-size: 1.25rem;
  }
`;

export const MiddleThreeLinesContainer = styled.div`
  padding: 1.5rem 0;
  border-bottom: 1px solid ${theme.palette.primary.main};

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    color: #605d62;

    span {
      font-size: 1rem;
      font-weight: 300;

      &:last-child {
        font-size: 1.125rem;
        font-weight: 500;
        white-space: nowrap;
      }
    }

    &:first-child {
      margin: 0;

      color: ${theme.palette.primary.main};

      span {
        font-size: 1.25rem;
        font-weight: 700;

        &:first-child {
          font-size: 1.125rem;
          margin-right: 0.5rem;
        }

        &:last-child {
          white-space: nowrap;
        }
      }
    }
  }
`;

export const LastTwoLinesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  color: ${theme.palette.primary.main};

  span {
    font-size: 1.125rem;
    font-weight: 700;

    &:last-child {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }

  ${(props) => props.isFirstLine && isFirstLine}
`;

export const ParagraphContainer = styled.div`
  margin-top: 1.5rem;

  p {
    font-size: 1.125rem;
    font-weight: 400;
    text-align: center;
    color: #1c1b1f;
    margin-bottom: 0;
  }
`;

export const FAQWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;

  > button {
    font-size: 1rem;
    height: 2rem;
  }

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

const RedirectTo = ({ to, ...rest }) => {
  const location = useLocation();
  const search = location?.search || '';
  return (
    <Navigate
      to={`/${to}${search}`}
      {...rest}
    />
  );
};

export default RedirectTo;

RedirectTo.propTypes = {
  to: PropTypes.string.isRequired,
};

import connection from '../connections/main';

const getAllGenerators = async () => {
  const { err, data } = await connection.get(`/massProducersActivation`);

  if (err) {
    throw err;
  }

  return data;
};

const updateAllGenerators = async (payload) => {
  const { err, data } = await connection.put(
    `/massProducersActivation/updateAll`,
    {
      massProducerActivation: payload,
    }
  );

  if (err) {
    throw err;
  }

  return data;
};

const MassProducerService = {
  getAllGenerators,
  updateAllGenerators,
};

export default MassProducerService;

/**
 * @module {utils/attachment}
 */

/**
 * Checks if an attachable model contains the given attachment 

 * @param {*} attachable 
 * @param {*} key 
 */
export const hasAttachment = (attachable) => !!attachable?.attachment;

export const PROVIDERS_TYPES = {
  cpfl: 'cpfl',
  cpfl_santa_cruz: 'cpfl_santa_cruz',
  light: 'light',
  cemig: 'cemig',
  enel_sp: 'enel_sp',
  enel_rj: 'enel_rj',
  elektro: 'elektro',
  ess: 'ess',
};

export const PROVIDERS_TYPES_LABEL = {
  [PROVIDERS_TYPES.cpfl]: 'CPFL Paulista',
  [PROVIDERS_TYPES.cpfl_santa_cruz]: 'CPFL Santa Cruz',
  [PROVIDERS_TYPES.light]: 'Light',
  [PROVIDERS_TYPES.cemig]: 'CEMIG',
  [PROVIDERS_TYPES.elektro]: 'Elektro',
  [PROVIDERS_TYPES.enel_sp]: 'ENEL SP',
  [PROVIDERS_TYPES.enel_rj]: 'ENEL RJ',
  [PROVIDERS_TYPES.ess]: 'ENERGISA',
};

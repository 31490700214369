import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'formik';
import { Grid, CircularProgress } from '@mui/material';

import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Modal from '@mui/material/Modal';
import {
  FieldWrapper,
  Label,
  CustomErrorMessage,
  CustomField,
} from '../../Interface/Form';
import UserFormSchema from './schema';
import Button from '../../Button';
import { FormPropTypes, FormDefaultProps } from '../types';
import { formMasks } from '../../../helpers/form/masks';
import GenericForm from '../GenericForm';
import { useAuthStore } from '../../../store';
import { USER_ROLES_TYPES } from '../../../helpers/userRoles';
import {
  PROFILE_TYPES,
  PROFILE_TYPES_LABEL,
} from '../../../helpers/profileTypes';
import {
  PROVIDERS_TYPES,
  PROVIDERS_TYPES_LABEL,
} from '../../../helpers/providersTypes';

import GradientButton from '../../GradientButton';
import { ThemeContext } from '../../../contexts/themeContext';
import theme from '../../../theme';
import { Heading2 } from '../../Interface/Text';

const INITIAL_VALUES = {
  name: '',
  email: '',
  phone_number: '',
  newsletter: false,
  register_date: '',
};

const UserForm = ({
  onSubmit,
  onCancel,
  values,
  readonly,
  isEditing = false,
  onEdit,
  handleDelete,
  loader = false,
  setLoader,
  openEmailModal,
  handleOpenEmailModal,
}) => {
  const role = useAuthStore((state) => state.userAuth?.role);
  const [open, setOpen] = useState(false);
  const { themeValue } = useContext(ThemeContext);

  const isCustomer = () => {
    return role === USER_ROLES_TYPES.Customer;
  };

  const target = () => {
    return (
      PROVIDERS_TYPES_LABEL[PROVIDERS_TYPES[values.provider]] !== undefined
    );
  };

  const isMobile = useMediaQuery({ maxWidth: 1400 });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <GenericForm
      enableReinitialize
      initialValues={INITIAL_VALUES}
      values={values}
      schema={UserFormSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, isValid, resetForm }) => {
        return (
          <Grid
            container
            direction='row'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {isMobile ? (
              <MobileGrid>
                <FieldWrapper>
                  <Label htmlFor='name'>Nome:</Label>
                  <CustomField
                    name='name'
                    disabled={isCustomer() ? true : readonly}
                    style={{
                      color: 'gray',
                      maxWidth: '200px',
                    }}
                  />
                  <CustomErrorMessage name='name' />
                </FieldWrapper>
                <FieldWrapper>
                  <Label htmlFor='email'>E-mail:</Label>
                  <CustomField
                    type='email'
                    name='email'
                    disabled={isCustomer() ? true : readonly}
                    style={{ color: 'gray' }}
                  />
                  <CustomErrorMessage name='email' />
                </FieldWrapper>
                <FieldWrapper>
                  <Label htmlFor='email'>E-mail a confirmar:</Label>
                  <CustomField
                    type='email'
                    name='email_to_change'
                    disabled={true}
                    style={{ color: 'gray' }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Label htmlFor='phone_number'>Celular:</Label>
                  <CustomField
                    name='phone_number'
                    disabled={readonly}
                    mask={formMasks.phone}
                    style={{
                      color: 'gray',
                      maxWidth: '200px',
                    }}
                  />
                  <CustomErrorMessage name='phone_number' />
                </FieldWrapper>

                {isCustomer() && values?.promocode && (
                  <FieldWrapper>
                    <Label htmlFor='promocode'>Código Promocional:</Label>
                    <CustomField
                      name='promocode'
                      disabled={true}
                      style={{
                        color: 'gray',
                        maxWidth: '200px',
                      }}
                    />
                    <CustomErrorMessage name='promocode' />
                  </FieldWrapper>
                )}
              </MobileGrid>
            ) : (
              <Grid
                item
                xs={12}
                sm={6}
                spacing={2}
              >
                <FieldWrapper>
                  <Label htmlFor='name'>Nome:</Label>
                  <CustomField
                    name='name'
                    disabled={isCustomer() ? true : readonly}
                    style={{ color: 'gray' }}
                  />
                  <CustomErrorMessage name='name' />
                </FieldWrapper>

                <FieldWrapper>
                  <Label htmlFor='email'>E-mail:</Label>
                  <CustomField
                    type='email'
                    name='email'
                    disabled={isCustomer() ? true : readonly}
                    style={{ color: 'gray' }}
                  />
                  <CustomErrorMessage name='email' />
                </FieldWrapper>
                {values?.email_to_change ? (
                  <FieldWrapper>
                    <Label htmlFor='email'>E-mail a confirmar:</Label>
                    <CustomField
                      type='email'
                      name='email_to_change'
                      disabled={true}
                      style={{ color: 'gray' }}
                    />
                  </FieldWrapper>
                ) : null}

                <FieldWrapper>
                  <Label htmlFor='phone_number'>Celular:</Label>
                  <CustomField
                    name='phone_number'
                    disabled={readonly}
                    mask={formMasks.phone}
                    style={{ color: 'gray' }}
                  />
                  <CustomErrorMessage name='phone_number' />
                </FieldWrapper>

                {isCustomer() && values?.promocode && (
                  <FieldWrapper>
                    <Label htmlFor='promocode'>Código Promocional:</Label>
                    <CustomField
                      name='promocode'
                      disabled={true}
                      style={{ color: 'gray' }}
                    />
                    <CustomErrorMessage name='promocode' />
                  </FieldWrapper>
                )}
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sm={6}
            >
              {isCustomer() && (
                <div>
                  <FieldWrapper style={{ marginLeft: isMobile ? '0' : '5rem' }}>
                    <Label
                      htmlFor='phone_number'
                      style={{
                        textWrap: 'nowrap',
                      }}
                    >
                      Data de cadastro:
                    </Label>
                    <CustomField
                      name='register_date'
                      disabled={true}
                      style={{
                        color: 'gray',
                        maxWidth: '200px',
                      }}
                      value={values?.created_at
                        ?.split('T')[0]
                        .split('-')
                        .reverse()
                        .join('/')}
                    />
                    <CustomErrorMessage name='phone_number' />
                  </FieldWrapper>
                  {isEditing && (
                    <div style={{ marginLeft: isMobile ? '0' : '5rem' }}>
                      <GradientButton
                        text='ALTERAR EMAIL'
                        handleClick={handleOpenEmailModal}
                        background={themeValue?.secondary_btn_bg || 'lightblue'}
                        color={themeValue?.secondary_btn_color || 'white'}
                      />
                    </div>
                  )}
                </div>
              )}
              {!isCustomer() && (
                <div>
                  <FieldWrapper>
                    <Label htmlFor='promocode'>Código promocional:</Label>
                    <CustomField
                      name='promocode'
                      disabled={readonly}
                      style={{ color: 'gray' }}
                    />
                    <CustomErrorMessage name='promocode' />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Label htmlFor='origin'>Origem:</Label>
                    <CustomField
                      name='origin'
                      disabled={readonly}
                      style={{ color: 'gray' }}
                    />
                    <CustomErrorMessage name='origin' />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Label
                      htmlFor='phone_number'
                      style={{ textWrap: 'nowrap' }}
                    >
                      Data de cadastro:
                    </Label>
                    <CustomField
                      name='register_date'
                      disabled={true}
                      style={{ color: 'gray' }}
                      value={values?.created_at
                        ?.split('T')[0]
                        .split('-')
                        .reverse()
                        .join('/')}
                    />
                    <CustomErrorMessage name='phone_number' />
                  </FieldWrapper>
                </div>
              )}
            </Grid>

            {!isCustomer() && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    maxWidth: '30%',
                    justifyContent: 'space-between',
                  }}
                >
                  {!readonly && onCancel && (
                    <GradientButton
                      background='white'
                      color={theme.palette.primary.main}
                      border={`1px solid ${theme.palette.primary.main}`}
                      paddingg='10px 30px'
                      text='Cancelar'
                      handleClick={(...args) => {
                        resetForm();
                        onCancel(...args);
                      }}
                      disabled={isSubmitting}
                      outlined
                      type='button'
                      rectangle
                    />
                  )}

                  {!readonly && (
                    <GradientButton
                      paddingg='10px 30px'
                      text='Salvar'
                      type='submit'
                      handleClick={handleSubmit}
                      disabled={!isValid}
                      rectangle
                      isLoading={isSubmitting}
                    />
                  )}
                </div>
                <div
                  container
                  justify='flex-end'
                  align='center'
                  spacing={2}
                >
                  {!readonly && (
                    <>
                      <GradientButton
                        background={theme.palette.primary.main}
                        color={theme.palette.white.main}
                        border={`1px solid ${theme.palette.primary.main}`}
                        paddingg='10px 30px'
                        text='Deletar'
                        handleClick={handleOpen}
                        disabled={isSubmitting}
                        outlined
                        type='button'
                        rectangle
                      />
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby='simple-modal-title'
                        aria-describedby='simple-modal-description'
                      >
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: 'white',
                              width: 'fit-content',
                              height: 'auto',
                              padding: '2rem 4rem',
                              borderRadius: '10px',
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Heading2>Tem certeza desta operação?</Heading2>
                            <p>
                              Isso irá apagar todos os dados do usuario em nosso
                              banco de dados
                            </p>
                            {loader && (
                              <CircularProgress style={{ color: 'orange' }} />
                            )}
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-around',
                                width: '100%',
                                marginTop: '2rem',
                              }}
                            >
                              <GradientButton
                                paddingg='10px 30px'
                                text='Sim'
                                handleClick={handleDelete}
                              />
                              <GradientButton
                                paddingg='10px 30px'
                                background='white'
                                color={theme.palette.primary.main}
                                border={`1px solid ${theme.palette.primary.main}`}
                                text='Não'
                                handleClick={handleClose}
                              />
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </>
                  )}
                </div>
              </div>
            )}
            {isCustomer() && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: '100%',
                  marginBottom: '20px',
                  marginTop: '5rem',
                }}
              >
                {!readonly && (
                  <GradientButton
                    paddingg='10px 30px'
                    text='Salvar'
                    type='submit'
                    background={
                      themeValue?.primary_btn_bg ||
                      'linear-gradient(140deg, #f3d23c 0%, orange 100%)'
                    }
                    color={themeValue?.primary_btn_color || 'white'}
                    handleClick={handleSubmit}
                    disabled={!isValid}
                    rectangle
                    isLoading={isSubmitting}
                  />
                )}
                {isEditing && (
                  <GradientButton
                    paddingg='10px 30px'
                    text='Cancelar'
                    type='button'
                    background='white'
                    color={theme.palette.primary.main}
                    border={`1px solid ${theme.palette.primary.main}`}
                    handleClick={onEdit}
                  />
                )}
              </div>
            )}
          </Grid>
        );
      }}
    </GenericForm>
  );
};
UserForm.propTypes = FormPropTypes;
UserForm.defaultProps = FormDefaultProps;

export default UserForm;

const MobileGrid = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 80vw; */
`;

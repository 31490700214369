import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { NewModal } from '../../../../components/NewModal';

import CitiesForm from './Form';
import FlexColContainer from '../../../../componentsStyle/FlexColContainer';
import theme from '../../../../theme';

const ModalCities = ({ isOpen, setIsOpen, states, providers, onSubmit }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <NewModal
      isOpen={isOpen}
      onRequestClose={handleClose}
      width='600px'
    >
      <FlexColContainer style={{ padding: 10 }}>
        <TitleContainer>
          <h2>Cadastar cidade</h2>
        </TitleContainer>

        <CitiesForm
          states={states}
          providers={providers}
          onCancel={handleClose}
          onSubmit={onSubmit}
        />
      </FlexColContainer>
    </NewModal>
  );
};

const TitleContainer = styled.div`
  margin: 0;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  > h2 {
    font-size: 1.5rem;
    color: ${theme.palette.primary.main};
    font-weight: bold;
  }
`;

ModalCities.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.bool.isRequired,
  states: PropTypes.array.isRequired,
  providers: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ModalCities;

import styled, { css } from 'styled-components';
import theme from '../../../../theme';

export const Container = styled.div`
  max-width: 648px;
  margin: 0 auto;
  padding: 0 3rem;

  ${(props) =>
    props.isNewPropertyOrHolder &&
    css`
      padding: 0;
    `}

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: #1c1b1f;

    strong {
      font-weight: 500;
    }

    &:nth-child(3) {
      margin: 1rem 0;
    }
  }
`;

export const Title = styled.h2`
  margin: 3rem 0 2.25rem 0;
  color: ${theme.palette.primary.main};
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
`;

export const RadioboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const EditTokenContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  div {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  padding-bottom: 3rem;
  display: flex;
  gap: 1rem;

  button {
    padding: 0.563rem 0;
    font-size: 1.25rem;

    &:disabled {
      font-size: 1.25rem;
    }
  }

  a {
    width: 100%;

    button {
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
  /* margin-top: 97.16px; */
  /* padding: 16px 24px; */
  /* box-shadow: 0px -3px 2px -3px rgba(0, 0, 0, 0.1),
    0px -5px 2px -3px rgba(0, 0, 0, 0.15); */

  /* button + button {
    margin-top: 10px;
  } */
`;

export const EditButton = styled.button`
  display: flex;
  flex-direction: column;
  background: transparent;
  border: 0;
  align-items: center;

  label {
    font-size: 0.625rem;
    font-weight: 400;
    text-align: center;
    color: #605d62;
    cursor: pointer;
  }
`;

export const ContHolder = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 36px; */
  /* padding: 1rem 0;*/

  // div + div {
  //   margin-top: 1rem;
  // }
`;

export const InfoPeopleOrCompany = styled.div`
  ${(props) =>
    props.isNewPropertyOrHolder &&
    css`
      margin-top: 1rem;
    `}

  p {
    margin-top: 1rem;
  }
`;

import { create } from 'zustand';

const initialState = {
  isOpenZendesk: false,
  shouldHideBtn: true,
};

const hidePaths = [
  '/contratos',
  '/set-up',
  '/gestao-oferta',
  '/todas-faturas',
  '/giftManagement',
  '/customerSuccess',
  '/ocupacao',
  '/report',
  // '/operacionalFinanceiro',
  '/promocodes',
  '/gestao-oferta',
  '/GeneratormassActivation',
  '/companies',
  '/gestaoFaturamento',
  '/faturamentoManual',
  '/perdidos',
  '/envio-emails',
];

export const useZendeskStore = create((set) => ({
  ...initialState,
  setIsOpenZendesk: (payload) => {
    set((state) => ({ ...state, isOpenZendesk: payload }));
  },
  open: () => {
    window.zE('messenger', 'open');
    set((state) => ({ ...state, isOpenZendesk: true }));
  },
  close: () => {
    window.zE('messenger', 'close');
  },
  verifyShoudHideBtn: (pathname) => {
    const shouldHide = hidePaths.includes(pathname);
    set((state) => ({ ...state, shouldHideBtn: shouldHide }));
  },
}));

window.onload = () => {
  window.zE('messenger', 'close');
  window.zE('messenger:on', 'close', () => {
    useZendeskStore.getState().setIsOpenZendesk(false);
  });
};

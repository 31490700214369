export const getPayloadSize = (payload) => {
  if (!payload) return 0;
  const stringPayload = JSON.stringify(payload);
  const sizeInBytes = new Blob([stringPayload]).size;
  return sizeInBytes;
};

export const checkPayloadMaxSize = (payload) => {
  const sizeInBytes = getPayloadSize(payload);
  const sizeInMB = sizeInBytes / (1024 * 1024);

  const maxMbSize = import.meta.env.REACT_APP_MAX_PAYLOAD_SIZE_IN_MB;

  if (sizeInMB > maxMbSize)
    throw new Error('O arquivo é muito grande, por favor reduzir');
};

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: '55px',
    singleValue: (provided) => ({
      ...provided,
      background: '#4e6a75',
      color: 'white',
    }),
  }),
};

const customNoOptionsMessage = () => 'Sem opções';

const MultiSelect = ({
  options,
  optionsSelected,
  updateData,
  isDisabled,
  isError = [],
  setIsError = () => {},
  name,
}) => {
  const [multiValues, setMultiValues] = useState(optionsSelected);

  const handleMultiChange = (values) => {
    setMultiValues(values);
    updateData(name, values);
  };

  useEffect(() => {
    if (isError[name] === true) {
      setMultiValues(optionsSelected);
      setIsError((prevState) => ({
        ...prevState,
        [name]: false,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError[name]]);

  return (
    <Select
      value={multiValues}
      options={options}
      onChange={handleMultiChange}
      isMulti
      name={name}
      isDisabled={isDisabled}
      className='basic-multi-select'
      classNamePrefix='select'
      isClearable={false}
      noOptionsMessage={customNoOptionsMessage}
      styles={customStyles}
    />
  );
};

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  optionsSelected: PropTypes.array.isRequired,
  updateData: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isError: PropTypes.object,
  setIsError: PropTypes.func,
  name: PropTypes.string,
};

export default MultiSelect;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

import {
  Container,
  IconWrapper,
  ItemLink,
  MenuContainer,
  MenuItemContainer,
  Name,
  Title,
  ToggleButton,
} from './styles';
import { compareObject } from '../../helpers/object';

export const NewDropdown = ({
  title = '',
  options,
  onChange,
  selected = false,
  disabled = false,
}) => {
  const [dropdownState, setDropdownState] = useState('closed');
  const [optionSelected, setOptionSelected] = useState('');
  const dropdownRef = useRef(null);
  const titleRef = useRef(null);

  const handleOpenDropdown = () => {
    setDropdownState('open');
  };

  const handleCloseDropdown = () => {
    setDropdownState('closed');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
        return;
      }
      setDropdownState('closed');
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  useEffect(() => {
    if (!options?.length) return;

    const option = options.find(({ label }) => label === optionSelected);

    if (!option) return;

    setOptionSelected(option.label);
  }, [options, optionSelected]);

  useEffect(() => {
    if (selected && options.length && !optionSelected) {
      const definedValue = selected?.value;
      const definedOption = options.find(({ value }) =>
        typeof value === 'object'
          ? compareObject(value, definedValue)
          : value === definedValue
      );
      if (definedOption) {
        onChange(definedOption);
        setOptionSelected(definedOption.label);
      } else if (selected === true) {
        onChange(options[0]);
        setOptionSelected(options[0].label);
      }
    }
  }, [selected, options, onChange]);

  useEffect(() => {
    if (optionSelected.length && title.length) {
      titleRef.current.style.position = 'absolute';
      titleRef.current.style.top = '0';
      titleRef.current.style.fontSize = '12px';
      titleRef.current.style.fontWeight = '400';
      titleRef.current.style.color = '#605D62';
      titleRef.current.style.transition = '0.2s ease';
    }
  }, [optionSelected, title.length]);

  const toggleMenu = () => {
    if (dropdownState === 'closed') return handleOpenDropdown();

    handleCloseDropdown();
  };

  const handleSelect = (option) => {
    setOptionSelected(option.label);
    onChange(option);
    setDropdownState('closed');
  };

  return (
    <Container
      ref={dropdownRef}
      disabled={disabled}
    >
      {!!title.length && (
        <Title
          disabled={disabled}
          ref={titleRef}
        >
          {title}
        </Title>
      )}

      <ToggleButton
        onClick={toggleMenu}
        noTitle={!title}
      >
        <Name disabled={disabled}>{optionSelected}</Name>

        <IconWrapper>
          {dropdownState === 'closed' ? (
            <MdArrowDropDown size='24px' />
          ) : (
            <MdArrowDropUp size='24px' />
          )}
        </IconWrapper>
      </ToggleButton>

      {dropdownState === 'open' && (
        <MenuContainer hasValue={!!optionSelected.length}>
          {options.map((option) => (
            <MenuItemContainer
              onClick={() => handleSelect(option)}
              key={option.key}
            >
              <ItemLink>{option.label}</ItemLink>
            </MenuItemContainer>
          ))}
        </MenuContainer>
      )}
    </Container>
  );
};

NewDropdown.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.any,
  disabled: PropTypes.bool,
};

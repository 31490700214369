import AddManagementsInPayload from '../../pages/Admin/ContractDetails/helpers/addManagementsInPayload';
import connection from './connections/main';

const get = async (id) => {
  const { err, data } = await connection.get(`/contractDetails/${id}`);

  if (err) {
    throw err;
  }

  return data;
};

const update = async (payload) => {
  if (Object.keys(payload).length !== 0) {
    Object.assign(payload, AddManagementsInPayload(payload));
    const { err, data } = await connection.patch(`/contractDetails/`, payload);

    if (err) {
      throw err;
    }

    return data;
  }
  return {};
};

const validateCredential = async (payload) => {
  const { err, data } = await connection.patch(
    `/contractDetails/validatecredentials/`,
    payload
  );

  if (err) {
    throw err;
  }

  return data;
};

const ContractsService = {
  get,
  update,
  validateCredential,
};

export default ContractsService;

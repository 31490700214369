import styled from 'styled-components';

export const Container = styled.div`
  padding: 1.5rem;

  background: #fffbfe;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;

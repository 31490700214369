import React from 'react';
import { Form } from 'formik';

import {
  FieldWrapper,
  Label,
  CustomErrorMessage,
  CustomField,
} from '../../Interface/Form';
import { StyledLink } from '../../Interface/StyledLink';
import GenericForm from '../GenericForm';
import { FormPropTypes, FormDefaultProps } from '../types';
import FlexColContainer from '../../../componentsStyle/FlexColContainer';

const initialValues = {};

const IdsForm = ({ onSubmit, values }) => {
  return (
    <GenericForm
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      values={values}
    >
      {() => {
        return (
          <Form>
            <FlexColContainer>
              <FieldWrapper>
                <Label
                  htmlFor='userId'
                  style={{ wordWrap: 'nowrap' }}
                >
                  User ID:
                </Label>
                <StyledLink
                  tag={true}
                  to={`/usuarios/${values.userId}`}
                  style={{ color: 'rgba(0,0,0,0.7', marginBottom: '0.3rem' }}
                >
                  {values.userId}
                </StyledLink>
              </FieldWrapper>

              <FieldWrapper>
                <Label
                  htmlFor='contractId'
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Contract ID:
                </Label>
                <CustomField
                  style={{ color: 'rgba(0,0,0,0.7', marginBottom: '0.35rem' }}
                  name='contractId'
                  type='number'
                  disabled={true}
                  fullwidth={1}
                />
                <CustomErrorMessage name='contractId' />
              </FieldWrapper>

              <FieldWrapper>
                <Label
                  htmlFor='locationId'
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Locations IDs:
                </Label>
                <CustomField
                  style={{ color: 'rgba(0,0,0,0.7', marginBottom: '0.35rem' }}
                  name='locationId'
                  disabled={true}
                  fullwidth={1}
                />
                <CustomErrorMessage name='locationsIds' />
              </FieldWrapper>
            </FlexColContainer>
          </Form>
        );
      }}
    </GenericForm>
  );
};

IdsForm.propTypes = FormPropTypes;
IdsForm.defaultProps = FormDefaultProps;

export default IdsForm;

/* eslint-disable no-restricted-syntax */
export const compareObject = (object1, object2) => {
  try {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  } catch (error) {
    return false;
  }
};
export const deepCopy = (obj, keysToCopy) => {
  const result = {};

  for (const key of keysToCopy) {
    const keys = key.split('.');
    let currentObj = obj;
    let currentResult = result;

    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];

      if (i === keys.length - 1) {
        if (currentObj && k in currentObj) {
          currentResult[k] = JSON.parse(JSON.stringify(currentObj[k]));
        }
      } else {
        currentObj = currentObj[k];
        if (!currentResult[k]) {
          currentResult[k] = {};
        }
        currentResult = currentResult[k];
      }
    }
  }

  return result;
};

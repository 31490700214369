import { Grid } from "@mui/material";
import { CustomErrorMessage, CustomField, FieldWrapper, Label } from "../../../../../../components/Interface/Form";
import { Heading3 } from "../../../../../../components/Interface/Text";
import { CONNECTION_TYPES_LABEL } from '../../../../../../helpers/connectionTypes';
import { setOptionsForDropdownlist } from "../../../../../../helpers/form";
import Select from 'react-select';

const QuantitativeData = ({ selectHandler, values, readonly, selectValues }) => {

  const connectionsTypesOptions = setOptionsForDropdownlist({
    CONNECTION_TYPES_LABEL,
  });

  return (
    <Grid container spacing={1} rowSpacing={1}>
      <Grid item xs={12}>
        <Heading3>
          Dados Quantitativos
        </Heading3>
      </Grid>
      <Grid item xs={12} lg={6} xl={4}>
        <FieldWrapper>
          <Label htmlFor='te_liq'>TE Liq:</Label>
          <CustomField
            name='te_liq'
            type='number'
            disabled={readonly}
            fullWidth
          />
          <CustomErrorMessage name='te_liq' />
        </FieldWrapper>
      </Grid>
      <Grid item xs={12} lg={6} xl={4}>
        <FieldWrapper>
          <Label htmlFor='tusd_liq'>TUSD Liq:</Label>
          <CustomField
            name='tusd_liq'
            type='number'
            disabled={readonly}
            fullWidth
          />
          <CustomErrorMessage name='tusd_liq' />
        </FieldWrapper>
      </Grid>
      <Grid item xs={12} lg={6} xl={4}>
        <FieldWrapper>
          <Label htmlFor='icms'>ICMS (%):</Label>
          <CustomField
            name='icms'
            type='number'
            disabled={readonly}
            fullWidth
          />
          <CustomErrorMessage name='icms' />
        </FieldWrapper>
      </Grid>
      <Grid item xs={12} lg={6} xl={4}>
        <FieldWrapper
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Label
            style={{
              width: 'fit-content',
            }}
            htmlFor='connection_type'
          >
            Padrão de Conexão:
          </Label>
          <div style={{ width: '100%' }}>
            <Select
              onChange={(el) =>
                selectHandler({ connection_type: el.value })
              }
              value={connectionsTypesOptions.find(
                (el) => el.value === selectValues.connection_type
              )}
              options={connectionsTypesOptions}
              fullWidth
              isDisabled={readonly}
            />
          </div>
          <CustomErrorMessage name='connection_type' />
        </FieldWrapper>
      </Grid>
      <Grid item xs={12} lg={6} xl={4}>
        <FieldWrapper>
          <Label htmlFor='averageKwh'>Consumo Médio:</Label>
          <CustomField
            name='averageKwh'
            type='number'
            disabled={true}
            fullWidth
          />
          <CustomErrorMessage name='averageKwh' />
        </FieldWrapper>
      </Grid>
      <Grid item xs={12} lg={6} xl={4}>
        <FieldWrapper>
          <Label htmlFor='suggestedVolume'>Volume Sugerido:</Label>
          <CustomField
            name='suggestedVolume'
            type='number'
            value={values.suggestedVolume}
            disabled={true}
            fullWidth
          />
          <CustomErrorMessage name='suggestedVolume' />
        </FieldWrapper>
      </Grid>
    </Grid>
  )
}

export default QuantitativeData;
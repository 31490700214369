import React from 'react';
import { CustomField, Label } from '../components/Interface/Form';

export const setRadioForTypes = (types, readonly) => {
  const objectName = Object.getOwnPropertyNames(types)[0]
    .split('_')[0]
    .toLowerCase();
  const allTypes = Object.values(types)[0];
  if (objectName === 'tag') delete allTypes.no_profile;
  if (objectName === 'profile') delete allTypes.typo;
  return Object.keys(allTypes).map((key) => (
    <Label htmlFor={key}>
      <CustomField
        type='radio'
        name={objectName}
        id={key}
        disabled={readonly}
        value={key}
      />
      {allTypes[key]}
    </Label>
  ));
};
const valuesToDeleteFromObjectName = {
  tag: ['no_profile', 'no_tag', 'no_return'],
  profile: ['typo'],
};

const objectNameHandler = (objectName, allTypes) => {
  const typesToDelete = Object.entries(valuesToDeleteFromObjectName).find(
    ([key]) => key === objectName
  )?.[1];
  if (!typesToDelete) return allTypes;
  const filteredTypesArray = Object.entries(allTypes).filter(
    ([key]) => !typesToDelete.includes(key)
  );
  return Object.fromEntries(filteredTypesArray);
};

export const setOptionsForDropdownlist = (types, removeRetry = false) => {
  const objectName = Object.getOwnPropertyNames(types)[0]
    .split('_')[0]
    .toLowerCase();

  const allTypes = Object.values(types)[0];
  const filteredTypes = objectNameHandler(objectName, allTypes);

  if (objectName === 'invoices' && removeRetry) delete allTypes.retry;

  const statusOptions = Object.keys(filteredTypes).reduce((option, value) => {
    option.push({ value, label: allTypes[value] });
    return option;
  }, []);
  return statusOptions;
};

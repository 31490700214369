export const TAG_TYPES = {
  no_profile: 'no_profile',
  no_return: 'no_return',
  lost: 'lost',
  no_tag: 'no_tag',
  alive: 'alive',
  ownership: 'ownership',
  location: 'location',
  canceled: 'canceled',
};

export const TAG_TYPES_LABEL = {
  [TAG_TYPES.no_profile]: 'Sem Perfil',
  [TAG_TYPES.no_return]: 'Sem Retorno',
  [TAG_TYPES.lost]: 'Perdido',
  [TAG_TYPES.no_tag]: 'Sem Tag',
  [TAG_TYPES.alive]: 'Vivo',
  [TAG_TYPES.ownership]: 'Titularidade',
  [TAG_TYPES.location]: 'Imóvel',
  [TAG_TYPES.canceled]: 'Cancelado',
};

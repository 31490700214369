export const setText = (action) => {
  const texts = {};

  switch (action) {
    case 'resetPassword':
      texts.title = 'Crie uma nova senha.';
      texts.firstLine = 'Não use caracteres repetidos em sequência, utilize letras, números e caracteres especiais.';
      texts.secondLine = '';
      texts.button = 'Redefinir senha';
      break;
    case 'definePassword':
      texts.title = 'Bem-vindo(a)!';
      texts.firstLine = 'Sua assinatura foi concluída com sucesso!';
      texts.secondLine = 'Como é seu primeiro acesso, precisamos definir sua senha.';
      texts.button = 'Definir senha';
      break;
  }
  return texts;
}
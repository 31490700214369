import { colors, Grid } from '@mui/material';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import styled from 'styled-components';

import StagesServices from '../../../../../services/administrator/stages.service';
import InvoicesStagesService from '../../../../../services/administrator/invoicesStages.service';
import { Paragraph } from '../../../../../components/Interface/Text/Paragraph';
import ContentCard from '../../../../../components/ContentCard';
import SearchableTable from '../../../../../components/Interface/SearchableTable';
import { ADMIN_CONTRACT_STATUS_TYPES_PTBR } from '../../../../../helpers/contractStatusTypes';
import { StatusToggle } from '../../../../../components/Interface';
import { TOAST_MESSAGES } from '../../../../../helpers/toastMessages';
import { insertValueToArray } from '../../../../../helpers/data';
import adminStores from '../../../../../store/admin/contractDetails/index.store';
import theme from '../../../../../theme';

const COLUMNS = [
  { name: 'Id' },
  { name: 'Troca' },
  { name: 'Última Mensagem' },
  { name: 'Próxima Mensagem', align: 'center' },
  { name: 'Finalizado', align: 'center' },
];

const ContractStagesSection = ({ type = 'stages' }) => {
  const {
    contract: { contract },
  } = adminStores();

  const [loading, setLoading] = useState(true);
  const [stages, setStages] = useState([]);

  useEffect(() => {
    if (type !== 'stages') return;
    insertValueToArray(COLUMNS, 1, { name: 'Status' });
  }, []);

  const [reload, setReload] = useState(false);
  const updateStagesDone = async (stageId, index) => {
    try {
      switch (type) {
        case 'stages':
          await StagesServices.updateDone(stageId);
          break;
        case 'invoicesStages':
          await InvoicesStagesService.updateDone(stageId);
          break;
        default:
          throw 'Invalid type';
      }

      stages[index].done = true;
      setReload(!reload);
      toast.success(TOAST_MESSAGES.SUCCESS);
    } catch (error) {
      console.log(error);
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const getRows = (stagesArray) => {
    try {
      const setValues = (
        {
          id,
          status,
          created_at: createdAt,
          messaged_at,
          next_message_at,
          done,
        },
        index
      ) => {
        const rows = [
          {
            value: <Paragraph>{id}</Paragraph>,
          },
          {
            value: (
              <Paragraph>
                {moment(createdAt).format('DD/MM/YYYY, HH:mm')}
              </Paragraph>
            ),
          },
          {
            value: (
              <Paragraph>
                {messaged_at
                  ? moment(messaged_at).format('DD/MM/YYYY, HH:mm')
                  : 'Nenhuma'}
              </Paragraph>
            ),
          },
          {
            value: (
              <Paragraph align='center'>
                {next_message_at
                  ? moment(next_message_at).format('DD/MM/YYYY, HH:mm')
                  : 'Nenhuma'}
              </Paragraph>
            ),
          },
          {
            value: (
              <Paragraph align='center'>
                <StatusToggle
                  active={done}
                  labelText={done ? 'Finalizado' : 'Não Finalizado'}
                  statusColors={[
                    theme.palette.secondary.main,
                    theme.palette.gray.main,
                  ]}
                  handleChange={async () => {
                    if (done) {
                      toast.error('Não é possível realizar essa operação');
                      return;
                    }
                    await updateStagesDone(id, index);
                  }}
                />
              </Paragraph>
            ),
          },
        ];
        if (type === 'stages')
          insertValueToArray(rows, 1, {
            value: (
              <Paragraph>{ADMIN_CONTRACT_STATUS_TYPES_PTBR[status]}</Paragraph>
            ),
          });
        return rows;
      };
      return stagesArray.map((value, index) => ({
        values: setValues(value, index),
      }));
    } catch (error) {
      console.log('file: index.js || line 128 || error', error);
    }
  };

  const fecthStages = async () => {
    const { id } = contract;

    let resources;
    switch (type) {
      case 'stages':
        resources = await StagesServices.getAllFromContractId(id);
        break;
      case 'invoicesStages':
        resources = await InvoicesStagesService.getAllFromContractId(id);
        break;
      default:
        throw 'Invalid type';
    }

    setStages(resources);
    setLoading(false);
  };

  useEffect(() => {
    let mounted = true;
    const start = () => {
      if (mounted && loading) {
        fecthStages();
      }
    };
    start();
    return () => (mounted = false);
  }, [contract]);

  return (
    <>
      {!loading && (
        <MainCard loading={loading}>
          <TitleContainer>
            <h5>
              {type === 'stages'
                ? 'Trocas de Status'
                : 'Mensagens de Faturamento'}
            </h5>
          </TitleContainer>

          <ContentCard>
            <SearchableTable
              columns={COLUMNS}
              rowsValues={getRows(stages)}
              noItemsText={
                type === 'stages'
                  ? 'Nenhuma troca de status'
                  : 'Nenhuma mensagem de faturamento'
              }
              rerender={reload}
            />
          </ContentCard>
        </MainCard>
      )}
    </>
  );
};
ContractStagesSection.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ContractStagesSection;

const MainCard = styled.div`
  padding: 1.5rem;
  max-width: 99vw;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const TitleContainer = styled.div`
  margin: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  h5 {
    font-size: 1.5rem;
    color: ${theme.palette.primary.main};
    font-weight: bold;
  }
`;

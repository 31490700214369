/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select';
import { StatusToggle } from '../../../../../../components/Interface';
import {
  CustomErrorMessage,
  CustomField,
  FieldWrapper,
  Label,
} from '../../../../../../components/Interface/Form';
import { Heading3 } from '../../../../../../components/Interface/Text';
import { PROFILE_TYPES } from '../../../../../../helpers/profileTypes';
import adminStores from '../../../../../../store/admin/contractDetails/index.store';
import { colors } from '../../../../../../styles/colors';
import theme from '../../../../../../theme';

const Input = ({
  values,
  readonly,
  handleSelectChange,
  selectValues,
  setValues,
}) => {
  const {
    location: { location },
    producers: { producers },
    providers: { providers },
  } = adminStores();

  const [producersOptions, setProducersOptions] = useState([]);

  useEffect(() => {
    try {
      const selectedProvider = providers.find(
        ({ id }) => id === values.selectedProviderId
      );
      const isOnHold = selectedProvider?.profile === 'on_hold';

      const filteredProducers = producers.filter((prd) => {
        const handledProviderId = isOnHold ? null : values.selectedProviderId;
        return prd.provider_id === handledProviderId;
      });

      filteredProducers.sort((a, b) => a.id - b.id);

      const options = [];
      for (const { name, id } of filteredProducers)
        options.push({ label: name, value: id });

      setProducersOptions(options);
    } catch (err) {
      console.log('producers data', err);
    }
  }, [producers, location, values.selectedProviderId]);

  const handleUseProducerFees = async () => {
    setValues((prevState) => ({
      ...prevState,
      use_producer_fees: !values.use_producer_fees,
    }));
  };

  const [volumeIsSuggested, setVolumeIsSuggested] = useState(
    values?.volume === 0 || !values?.volume
  );

  const [contractedVolumeIsNotSuggested, setContractedVolumeIsNotSuggested] =
    useState(false);

  useEffect(() => {
    if (location?.volume > 0) return setVolumeIsSuggested(false);
    setVolumeIsSuggested(true);
  }, [location.volume]);

  useEffect(() => {
    if (!(location.volume && values.suggestedVolume)) return;
    if (parseFloat(values?.suggestedVolume) !== parseFloat(location?.volume))
      return setContractedVolumeIsNotSuggested(true);
    if (parseFloat(values?.suggestedVolume) === parseFloat(location?.volume))
      return setContractedVolumeIsNotSuggested(false);
  }, [location.volume, values.suggestedVolume]);

  return (
    <Grid
      spacing={2}
      rowSpacing={2}
    >
      <Grid
        item
        xs={12}
      >
        <Heading3>Input</Heading3>
      </Grid>
      <Grid
        container
        item
        spacing={2}
        rowSpacing={2}
        alignItems='center'
      >
        <Grid
          item
          xs={6}
        >
          <FieldWrapper>
            <Label htmlFor='producer'>Gerador:</Label>
            <AsyncSelect
              options={producersOptions}
              value={
                producersOptions.find(
                  (el) => el.value === selectValues.producer_id
                ) || {
                  label: 'Selecione',
                  value: null,
                }
              }
              onChange={(e) => handleSelectChange({ producer_id: e.value })}
              isDisabled={readonly}
            />
          </FieldWrapper>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <StatusToggle
            active={values.use_producer_fees}
            labelText={
              values.use_producer_fees
                ? 'Utilizar Tarifas do Gerador'
                : 'Não Utilizar Tarifas do Gerador'
            }
            statusColors={[
              theme.palette.secondary.main,
              theme.palette.gray.main,
            ]}
            handleChange={() => handleUseProducerFees()}
            style={{ paddingBottom: 25 }}
            readOnly={readonly}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        spacing={2}
        justify='space-between'
      >
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
        >
          <FieldWrapper>
            <Label htmlFor='discount'>Desconto (%):</Label>
            <CustomField
              name='discount'
              type='number'
              disabled={readonly}
              fullwidth={1}
            />
            <CustomErrorMessage name='discount' />
          </FieldWrapper>
        </Grid>

        <Grid
          item
          xs={12}
          md={8}
        >
          {contractedVolumeIsNotSuggested ? (
            <Grid
              container
              item
              spacing={2}
              rowSpacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
              >
                <FieldWrapper>
                  <Label htmlFor='suggestedVolume'>
                    Volume contratado sugerido (kWh/mês)
                  </Label>
                  <CustomField
                    name='suggestedVolume'
                    type='number'
                    disabled={true}
                    fullwidth={1}
                  />
                  <CustomErrorMessage name='suggestedVolume' />
                </FieldWrapper>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <FieldWrapper>
                  <Label htmlFor='volume'>Volume contratado (kWh/mês)</Label>
                  <CustomField
                    name='volume'
                    type='number'
                    disabled={readonly}
                    fullwidth={1}
                  />
                  <CustomErrorMessage name='volume' />
                </FieldWrapper>
              </Grid>
            </Grid>
          ) : volumeIsSuggested ? (
            <Grid
              spacing={2}
              rowSpacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <FieldWrapper>
                  <Label htmlFor='suggestedVolume'>
                    Volume contratado sugerido (kWh/mês)
                  </Label>
                  <CustomField
                    name='suggestedVolume'
                    type='number'
                    disabled={true}
                    fullwidth={1}
                  />
                  <CustomErrorMessage name='suggestedVolume' />
                </FieldWrapper>
              </Grid>
            </Grid>
          ) : (
            <Grid
              spacing={2}
              rowSpacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <FieldWrapper>
                  <Label htmlFor='volume'>Volume contratado (kWh/mês)</Label>
                  <CustomField
                    name='volume'
                    type='number'
                    disabled={readonly}
                    fullwidth={1}
                  />
                  <CustomErrorMessage name='volume' />
                </FieldWrapper>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Input;

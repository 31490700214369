import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { lighten } from 'polished';

import { colors } from '../../../styles/colors';
import theme from '../../../theme';

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const TableRow = styled.tr`
  &:not(:first-child) {
    border-top: 1px solid ${colors.tertiary};
  }
  background-color: ${(props) =>
    props.active ? lighten(0.25)(colors.tertiary) : colors.white};
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.tertiary};
  }
  &:hover {
    background-color: ${colors.tertiary};
  }
`;

export const TableBody = styled.tbody``; // CssHack :before deleted

export const TableHeaderColumn = styled.th`
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.color || theme.palette.primary.main};
  letter-spacing: 0.085em;
  padding: 18px 12px;
  text-align: ${(props) => props.align || 'left'};
`;

export const BaseTableHeader = ({ className, children }) => {
  return (
    <thead className={className}>
      <tr>{children}</tr>
    </thead>
  );
};

BaseTableHeader.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([TableHeaderColumn]),
    })
  ).isRequired,
};

export const TableHeader = styled(BaseTableHeader)`
  position: sticky;
  top: 0;
  background: #fff;
  box-shadow: 0 2px 0 ${theme.palette.purple.light};
  z-index: 1;

  tr {
    border-bottom: 2px solid
      ${(props) => props.color || theme.palette.primary.main};
    margin-bottom: 10px;
  }
`;

export const TableColumn = styled.td`
  position: relative;
  font-size: 16px;
  padding: 12px 12px;
  color: ${theme.palette.black.main};
  text-align: ${(props) => props.align || 'left'};
  letter-spacing: 0.085em;
  max-width: 500px;
`;

export const CONNECTION_TYPES = {
  mono: 'mono',
  bi: 'bi',
  tri: 'tri',
};

export const CONNECTION_TYPES_LABEL = {
  [CONNECTION_TYPES.mono]: 'Monofásico',
  [CONNECTION_TYPES.bi]: 'Bifásico',
  [CONNECTION_TYPES.tri]: 'Trifásico',
};

export const CONTRACT_STATUS_TYPES = {
  CHANGE_CITY: 'changeCity',
  REGISTERED: 'registered',
  PROPOSAL_ACCEPTED: 'proposal_accepted',
  ACTIVE: 'active',
  DRAFT: 'draft',
  ON_HOLD: 'on_hold',
  PENDING_SIGNATURE: 'pending_signature',
  PENDING_ACTIVATION: 'pending_activation',
  BLOCKED: 'suspended',
  PENDING_TRANSFER: 'pending_transfer',
  PENDING_CANCEL: 'pending_cancel',
  CANCELED: 'cancelled',
  BACK_FROM_CONTRACT: 'back_from_contract',
  SIGNER_CHANGE: 'signer_change',
  SIGN_LATER: 'sign_later',
  SIGN_NOW: 'sign_now',
};

export const CONTRACT_STATUS_TYPES_PTBR = {
  [CONTRACT_STATUS_TYPES.CHANGE_CITY]:
    'Finalize sua contratação para receber o benefício',
  [CONTRACT_STATUS_TYPES.REGISTERED]: 'Cadastrado',
  [CONTRACT_STATUS_TYPES.PROPOSAL_ACCEPTED]: 'Proposta aceita',
  [CONTRACT_STATUS_TYPES.ACTIVE]: 'Ativo',
  [CONTRACT_STATUS_TYPES.DRAFT]:
    'Finalize sua contratação para receber o benefício',
  [CONTRACT_STATUS_TYPES.ON_HOLD]:
    'Ainda não temos oferta de energia em sua cidade, mas isso deverá mudar em breve.',
  [CONTRACT_STATUS_TYPES.PENDING_SIGNATURE]: 'Aguardando assinatura',
  [CONTRACT_STATUS_TYPES.PENDING_ACTIVATION]: 'Aguardando ativação',
  [CONTRACT_STATUS_TYPES.BLOCKED]: 'Bloqueado',
  [CONTRACT_STATUS_TYPES.PENDING_TRANSFER]: 'Aguardando transferência',
  [CONTRACT_STATUS_TYPES.PENDING_CANCEL]: 'Aguardando cancelamento',
  [CONTRACT_STATUS_TYPES.CANCELED]: 'Cancelado',
};

export const ADMIN_CONTRACT_STATUS_TYPES_PTBR = {
  [CONTRACT_STATUS_TYPES.CHANGE_CITY]: 'CEP ≠ Cidade',
  [CONTRACT_STATUS_TYPES.REGISTERED]: 'Cadastrado',
  [CONTRACT_STATUS_TYPES.PROPOSAL_ACCEPTED]: 'Proposta Aceita',
  [CONTRACT_STATUS_TYPES.ACTIVE]: 'Ativo',
  [CONTRACT_STATUS_TYPES.DRAFT]: 'Aguardando Proposta',
  [CONTRACT_STATUS_TYPES.ON_HOLD]: 'Cadastrado',
  [CONTRACT_STATUS_TYPES.PENDING_SIGNATURE]: 'Aguardando Assinatura',
  [CONTRACT_STATUS_TYPES.PENDING_ACTIVATION]: 'Aguardando Ativação',
  [CONTRACT_STATUS_TYPES.BLOCKED]: 'Bloqueado',
  [CONTRACT_STATUS_TYPES.PENDING_TRANSFER]: 'Aguardando Transferência',
  [CONTRACT_STATUS_TYPES.PENDING_CANCEL]: 'Aguardando Cancelamento',
  [CONTRACT_STATUS_TYPES.CANCELED]: 'Cancelado',
  [CONTRACT_STATUS_TYPES.BACK_FROM_CONTRACT]: 'Voltou do contrato',
  [CONTRACT_STATUS_TYPES.SIGNER_CHANGE]: 'Trocou Signatário',
  [CONTRACT_STATUS_TYPES.SIGN_LATER]: 'Assinar Depois',
  [CONTRACT_STATUS_TYPES.SIGN_NOW]: 'Assinar Agora',
};

export const USER_CONTRACT_STATUS_TYPES_PTBR = {
  [CONTRACT_STATUS_TYPES.PROPOSAL_ACCEPTED]: 'Proposta Aceita',
  [CONTRACT_STATUS_TYPES.PENDING_SIGNATURE]: 'Aguardando Assinatura',
  [CONTRACT_STATUS_TYPES.DRAFT]: 'Cadastrado',
  [CONTRACT_STATUS_TYPES.ACTIVE]: 'Ativo',
  [CONTRACT_STATUS_TYPES.PENDING_ACTIVATION]: 'Aguardando ativação',
  [CONTRACT_STATUS_TYPES.CANCELED]: 'Cancelado',
};

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import ContentCard from '../../../components/ContentCard';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { Paragraph } from '../../../components/Interface/Text';
import PageView from '../../../containers/PageView';
import ProducersService from '../../../services/administrator/producers.service';
import ProvidersService from '../../../services/administrator/providers.service';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';
import GradientButton from '../../../components/GradientButton';

import CompaniesService from '../../../services/general/companies.service';
import ProducerReportService from '../../../services/administrator/ManagementBases/producerReport.service';
import ProducersForm from '../../../components/Forms/ProducersForm';
import ProducersOccupationForm from '../../../components/Forms/ProducersOccupationForm';

const COLUMNS = [
  { name: 'Gerador' },
  { name: 'Capacidade' },
  { name: 'Mensalidade', align: 'left' },
  { name: 'Ocupação', align: 'left' },
  { name: 'Mês', align: 'left' },
  { name: 'Ano', align: 'left' },
  { name: 'Geração', align: 'left' },
  { name: 'Volume Injetado' },
  { name: 'Compensação', align: 'left' },
  { name: 'Mensalidade Ajustada', align: 'left' },
  { name: 'Gift', align: 'left' },
  { name: 'Mensalidade Coletada', align: 'left' },
];

function ProducerReport() {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    producers: [],
    providers: [],
    companies: [],
    producerReport: [],
  });
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [generatorToEdit, setGeneratorToEdit] = useState(null);
  const [generalLoading, setGeneralLoading] = useState({
    producers: true,
    providers: true,
    companies: true,
    producerReport: true,
  });
  const [loadingg, setLoadingg] = useState(false);
  const [reload, setReload] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setGeneratorToEdit(null);
    setOpen2(false);
  };

  const handleEditGenerator = async (id) => {
    try {
      const generatorToEdit = producerReport.find((el) => {
        return el.id === id;
      });
      console.log(
        'file: index.js || line 74 || generatorToEdit',
        generatorToEdit
      );

      setGeneratorToEdit(generatorToEdit);
      handleOpen2();
    } catch (error) {
      console.log(error);
    }
  };

  const getRows = (generators) => {
    return generators.map(
      ({
        id,
        producer,
        volume_forecast,
        value_cents,
        occupation,
        month,
        volume,
        injected,
        compensated,
        ajustedMonthlyBill,
        collectedMonthlyBill,
        totalGiftValue,
      }) => ({
        values: [
          {
            value: (
              <Inner style={{ display: 'flex', alignItems: 'center' }}>
                <Paragraph>{producer}</Paragraph>
                <EditIcon
                  id='editIcon'
                  onClick={() => handleEditGenerator(id)}
                />
              </Inner>
            ),
          },
          {
            value: (
              <Paragraph>
                {parseFloat((volume_forecast / 1000).toFixed(2)).toLocaleString(
                  'pt-BR'
                ) || '-'}{' '}
                MWh
              </Paragraph>
            ),
            align: 'center',
          },
          {
            value: (
              <Paragraph>
                R$
                {parseFloat((value_cents / 100).toFixed(2)).toLocaleString(
                  'pt-BR'
                )}
              </Paragraph>
            ),
            align: 'center',
          },
          {
            value: (
              <Paragraph>
                {parseFloat((occupation / 1000).toFixed(2)).toLocaleString(
                  'pt-BR'
                ) || '-'}{' '}
                MWh
              </Paragraph>
            ),
            align: 'center',
          },
          {
            value: <Paragraph>{month.toString() || '-'}</Paragraph>,
            align: 'center',
          },
          {
            value: <Paragraph>ANO</Paragraph>,
            align: 'center',
          },
          {
            value: (
              <Paragraph>
                {parseFloat((volume / 1000).toFixed(2)).toLocaleString(
                  'pt-BR'
                ) || '-'}{' '}
                MWh
              </Paragraph>
            ),
            align: 'center',
          },
          {
            value: (
              <Paragraph>
                {parseFloat((injected / 1000).toFixed(2)).toLocaleString(
                  'pt-BR'
                ) || '-'}{' '}
                MWh
              </Paragraph>
            ),
            align: 'center',
          },
          {
            value: (
              <Paragraph>
                {parseFloat((compensated / 1000).toFixed(2)).toLocaleString(
                  'pt-BR'
                ) || '-'}{' '}
                MWh
              </Paragraph>
            ),
            align: 'center',
          },
          {
            value: (
              <Paragraph>
                R$
                {parseFloat(
                  (ajustedMonthlyBill / 100).toFixed(2)
                ).toLocaleString('pt-BR')}
              </Paragraph>
            ),
            align: 'center',
          },
          {
            value: (
              <Paragraph>
                R${(totalGiftValue / 100).toLocaleString('pt-BR')}
              </Paragraph>
            ),
            align: 'center',
          },
          {
            value: (
              <Paragraph>
                R${(collectedMonthlyBill / 100).toLocaleString('pt-BR')}
              </Paragraph>
            ),
            align: 'center',
          },
        ],
      })
    );
  };

  useEffect(() => {
    const fetchProducerReport = async () => {
      try {
        const [resources, totalInvoicesWithErrors] =
          await ProducerReportService.getAll();

        if (totalInvoicesWithErrors?.length > 0)
          toast.error(
            `Não foi possível calcular a mensalidade coletada em cima de ${totalInvoicesWithErrors.length} faturas`
          );
        setPageState((state) => ({
          ...state,
          producerReport: resources,
        }));
        setGeneralLoading((state) => ({ ...state, producerReport: false }));
      } catch (error) {
        setPageState((state) => ({
          ...state,
          loading: false,
          hasError: true,
        }));
        setGeneralLoading((state) => ({ ...state, producerReport: false }));
      }
    };

    const fetchProducers = async () => {
      try {
        const resources = await ProducersService.getAll();
        setPageState((state) => ({
          ...state,
          producers: resources,
        }));
        setGeneralLoading((state) => ({ ...state, producers: false }));
      } catch (error) {
        setPageState((state) => ({
          ...state,
          loading: false,
          hasError: true,
        }));
        setGeneralLoading((state) => ({ ...state, producers: false }));
      }
    };
    const fetchProviders = async () => {
      try {
        const providers = await ProvidersService.getAll();
        setPageState((state) => ({
          ...state,
          hasError: false,
          providers,
        }));
        setGeneralLoading((state) => ({ ...state, providers: false }));
      } catch (error) {
        setPageState((state) => ({
          ...state,
          loading: false,
          hasError: true,
        }));
        setGeneralLoading((state) => ({ ...state, providers: false }));
      }
    };
    const fetchCompanies = async () => {
      try {
        const companies = await CompaniesService.getAll();
        setPageState((state) => ({
          ...state,
          hasError: false,
          companies,
        }));
        setGeneralLoading((state) => ({ ...state, companies: false }));
      } catch (error) {
        setPageState((state) => ({
          ...state,
          loading: false,
          hasError: true,
        }));
        setGeneralLoading((state) => ({ ...state, companies: false }));
      }
    };

    Promise.all([
      fetchProviders(),
      fetchProducers(),
      fetchCompanies(),
      fetchProducerReport(),
    ]);
  }, [reload]);

  useEffect(() => {
    const pageStateLoadingValues = Object.values(generalLoading);
    const hasLoaded = !pageStateLoadingValues.includes(true);

    if (hasLoaded)
      setPageState((state) => ({
        ...state,
        loading: false,
      }));
  }, [generalLoading]);

  const onSubmit = async (data, { setSubmitting }) => {
    const { id } = data;

    try {
      setLoadingg(true);
      let req;
      !generatorToEdit
        ? (req = await ProducerReportService.create({ ...data }))
        : (req = await ProducerReportService.update(id, { ...data }));
      if (req?.error) {
        throw new Error('Erro');
      }
      setLoadingg(false);
      setReload(!reload);
    } catch (err) {
      setLoadingg(false);
      console.log(err);
      toast.error(TOAST_MESSAGES.ERROR);
      const { error: msg, errors } = err.response.data;

      if (errors) {
        const text = Object.keys(errors)
          .map((field) => [field, errors[field].join('\n')])
          .map((partial) => partial.join(' '))
          .join('\n');
      }
      setReload(!reload);
    } finally {
      handleClose();
      handleClose2();
    }
  };

  const { loading, hasError, producers, providers, companies, producerReport } =
    pageState;

  return (
    <PageView
      title='Report Gerador'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Section>
        <GradientButton
          text=''
          type='button'
          handleClick={handleOpen}
        >
          Novo Report
        </GradientButton>
      </Section>
      {producers && (
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <ContentCard>
              <SearchableTable
                columns={COLUMNS}
                rowsValues={getRows(producerReport)}
                noItemsText='Nenhum report gerador'
                dataIsComplete
              />
            </ContentCard>
          </Grid>
        </Grid>
      )}
      <div>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Fade in={open}>
              <ModalDiv>
                <ProducersOccupationForm
                  onSubmit={onSubmit}
                  handleClose={handleClose}
                  providers={providers}
                  companies={companies}
                  producers={producers}
                  loadingg={loadingg}
                  setLoadingg={setLoadingg}
                  setReload={setReload}
                  reload={reload}
                />
              </ModalDiv>
            </Fade>
          </div>
        </Modal>

        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={open2}
          onClose={handleClose2}
          closeAfterTransition
        >
          <div
            style={{
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Fade in={open2}>
              <ModalDiv>
                <ProducersOccupationForm
                  onSubmit={onSubmit}
                  handleClose={handleClose2}
                  providers={providers}
                  producers={producers}
                  companies={companies}
                  producerReport={producerReport}
                  generatorToEdit={generatorToEdit}
                  loadingg={loadingg}
                  setLoadingg={setLoadingg}
                  setReload={setReload}
                  reload={reload}
                />
              </ModalDiv>
            </Fade>
          </div>
        </Modal>
      </div>
    </PageView>
  );
}

export default ProducerReport;

const Inner = styled.div`
  #editIcon {
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
`;

const Section = styled.section`
  display: flex;
  justify-content: flex-end;
  button {
    :hover {
      opacity: 0.7;
    }
  }
`;

const ModalDiv = styled.div`
  background-color: white;
  padding: 3rem;
  border-radius: 2rem;
`;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useStage } from '../../../hooks/useStage';

import { CurrencyInput } from '../../CurrencyInput';

import LogoImg from '../../../assets/images/logo/logo-flora.svg';

import { LoadingCustomModal } from '../../Modals/LoadingCustomModal';
import { PrimaryButton } from '../../PrimaryButton';

import {
  ButtonContainer,
  Container,
  Content,
  FAQWrapper,
  ImageContainer,
  LastTwoLinesContainer,
  LineOneContainer,
  MiddleThreeLinesContainer,
  ParagraphContainer,
  Title,
} from './styles';

import { validationEstimatedElectricBill } from '../../../helpers/form/validations';
import { useStateForProposal } from '../../../hooks/dataForProposal';
import { formatCurrency } from '../../../pages/Customer/NewHome/helpers';
import ContractsService from '../../../services/general/contracts.service';
import UsersService from '../../../services/general/users.service';
import { allCalc } from '../../../utils/calculateProposal';
import { FAQModal } from '../../Modals/FAQModal';
import { VideoModal } from '../../Modals/VideoModal';
import { SecondaryButton } from '../../SecondaryButton';
import theme from '../../../theme';

export const StageTwo = ({ isNewPropertyOrHolder }) => {
  const [openFAQModal, setOpenFAQModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const { stagePayload, changePayload, sendPayloadGlobal } = useStage();
  const { dataForProposal, setDataForProposal } = useStateForProposal();
  const { estimated_electric_bill } = stagePayload;

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const currencyRef = useRef(null);

  const handleBackStage = () => {
    changePayload({ stage: 0 });
  };

  const handleOpenFAQModal = () => {
    setOpenFAQModal(true);
  };

  const handleCloseFAQModal = useCallback(() => {
    setOpenFAQModal(false);
  }, []);

  const handleOpenVideoModal = () => {
    setOpenVideoModal(true);
  };

  const handleCloseVideoModal = useCallback(() => {
    setOpenVideoModal(false);
  }, []);

  const handleEstimatedElectricBill = useCallback(
    (currentValue) => {
      const convertedValue = currentValue / 100;

      let status = 'accepted';

      const isEstimatedElectricBillValid =
        validationEstimatedElectricBill(convertedValue);

      if (!isEstimatedElectricBillValid) {
        status = 'refused';
      }

      changePayload({
        estimated_electric_bill: {
          ...estimated_electric_bill,
          value: currentValue,
          converted_value: convertedValue,
          status,
        },
      });
    },
    [estimated_electric_bill]
  );

  const sendPayload = async (payload) => {
    setLoading(true);

    try {
      const { contract_id } = stagePayload;
      isNewPropertyOrHolder
        ? await ContractsService.newLocationNew(payload, contract_id)
        : await UsersService.signupUpdateCheckNew(payload);

      const updatedStage = {
        ...stagePayload,
        stage: 2,
        max_stage: 2,
      };
      changePayload(updatedStage);
    } catch (err) {
      console.log(err);
      throw err;
      // ABRIR MODAL DE ERRO AQUI
    } finally {
      setLoading(false);
    }
  };

  const handleNextStage = async (event) => {
    event.preventDefault();

    const payloadSubmitted = {
      estimated_electric_bill: Math.ceil(
        estimated_electric_bill.converted_value
      ),
      stage: 2,
    };

    try {
      await sendPayloadGlobal(payloadSubmitted, isNewPropertyOrHolder, {
        setLoading,
      });
    } catch (err) {
      console.log(err);
      toast.error('Houve um erro desconhecido, tente novamente.');
    }
  };

  useEffect(() => {
    const verify = estimated_electric_bill?.status === 'accepted';

    if (verify) {
      setButtonDisabled(false);

      return;
    }

    setButtonDisabled(true);
  }, [estimated_electric_bill.status]);

  useEffect(() => {
    (async () => {
      const { providerData } = dataForProposal;

      const { converted_value: estimatedElectricBill } =
        estimated_electric_bill;

      const proposal = await allCalc(estimatedElectricBill, providerData);

      setDataForProposal((state) => ({ ...state, proposal }));
    })();

    if (!currencyRef.current) return;

    if (String(estimated_electric_bill.value).length <= 1) {
      currencyRef.current.style.width = `${
        String(estimated_electric_bill.value).length + 5
      }ch`;
      return;
    }

    currencyRef.current.style.width = `${
      String(estimated_electric_bill.value).length + 5
    }ch`;
  }, [estimated_electric_bill.value]);

  const { proposal, providerData } = dataForProposal;

  const isProviderTarget = useCallback((providerData) => {
    const isTarget = providerData?.profile === 'target';
    return isTarget;
  }, []);

  return (
    <>
      <LoadingCustomModal isLoading={loading} />
      <FAQModal
        isOpen={openFAQModal}
        onRequestClose={handleCloseFAQModal}
        provider={dataForProposal?.providerData.name}
      />
      <VideoModal
        isOpen={openVideoModal}
        onRequestClose={handleCloseVideoModal}
      />
      {!!Object.values(dataForProposal).length && (
        <>
          <Container>
            <form onSubmit={handleNextStage}>
              <Content isNewPropertyOrHolder={!!isNewPropertyOrHolder}>
                <ImageContainer>
                  <img
                    src={LogoImg}
                    alt='Flora Energia'
                  />
                </ImageContainer>

                <Title>
                  {isProviderTarget(providerData)
                    ? 'Aqui está sua proposta!'
                    : 'Garanta sua vaga!'}
                </Title>

                {!isProviderTarget(providerData) && (
                  <ParagraphContainer>
                    <p>
                      Ainda não atendemos sua região, mas isso deve mudar em
                      breve! Enquanto isso, garanta seu lugar em nossa fila de
                      espera finalizando a contratação.
                    </p>{' '}
                    <p>
                      {' '}
                      Confira abaixo a estimativa de economia para sua
                      distribuidora:
                    </p>
                  </ParagraphContainer>
                )}

                <LineOneContainer>
                  <label
                    htmlFor='value'
                    style={{ color: theme.palette.primary.main }}
                  >
                    Quanto você paga atualmente
                  </label>

                  <CurrencyInput
                    type='text'
                    value={estimated_electric_bill.value}
                    onChange={handleEstimatedElectricBill}
                    hasError={estimated_electric_bill.status === 'refused'}
                    hasTouched={estimated_electric_bill.touched}
                    max={99999999}
                    ref={currencyRef}
                    noIcon
                    isProposal
                    isDisabled={true}
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: 700,
                    }}
                  />
                </LineOneContainer>

                <MiddleThreeLinesContainer>
                  <div>
                    <span>
                      {isProviderTarget(providerData)
                        ? 'Quanto você passará a pagar'
                        : 'Valor médio com desconto'}
                    </span>
                    <span>{formatCurrency(proposal?.totalInvoices)}</span>
                  </div>

                  {isProviderTarget(providerData) && (
                    <>
                      <div>
                        <span>Conta de luz da {providerData?.name}</span>
                        <span>{formatCurrency(proposal?.invoiceProvider)}</span>
                      </div>
                      <div>
                        <span>Fatura Flora</span>
                        <span>{formatCurrency(proposal?.invoiceFlora)}</span>
                      </div>
                    </>
                  )}
                </MiddleThreeLinesContainer>

                <LastTwoLinesContainer isFirstLine>
                  <span>Economia mensal</span>
                  <span>{formatCurrency(proposal?.monthlyEconomy)}</span>
                </LastTwoLinesContainer>

                <LastTwoLinesContainer>
                  <span>Economia anual</span>
                  <span>{formatCurrency(proposal?.yearlyEconomy)}</span>
                </LastTwoLinesContainer>

                <div>
                  <div
                    style={{
                      width: '100%',
                      fontWeight: 700,
                      textDecoration: 'underline',
                      color: theme.palette.primary.main,
                    }}
                  >
                    Garantia de economia
                  </div>
                  <div style={{ color: '#605d62' }}>
                    Sua conta de luz com a distribuidora somada a fatura Flora,
                    será{' '}
                    <strong style={{ color: theme.palette.primary.main }}>
                      sempre menor
                    </strong>{' '}
                    do que a sua conta de luz atual.
                  </div>
                </div>
                <div style={{ margin: '1rem 0 1.5rem 0' }}>
                  <div
                    style={{
                      width: '100%',
                      fontWeight: 700,
                      textDecoration: 'underline',
                      color: theme.palette.primary.main,
                    }}
                  >
                    Sem prazo ou multa contratual
                  </div>
                  <div style={{ color: '#605d62' }}>
                    Basta solicitar seu desligamento com um aviso prévio de 90
                    dias.
                  </div>
                </div>

                <FAQWrapper>
                  <SecondaryButton onClick={handleOpenFAQModal}>
                    Perguntas frequentes
                  </SecondaryButton>
                  <SecondaryButton onClick={handleOpenVideoModal}>
                    Vídeo
                  </SecondaryButton>
                </FAQWrapper>

                <ButtonContainer>
                  <SecondaryButton onClick={handleBackStage}>
                    Voltar
                  </SecondaryButton>
                  <PrimaryButton
                    id={
                      import.meta.env.REACT_APP_ENV === 'prod'
                        ? 'proposta2'
                        : ''
                    }
                    type='submit'
                    disabled={buttonDisabled}
                  >
                    Avançar
                  </PrimaryButton>
                </ButtonContainer>
              </Content>
            </form>
          </Container>
        </>
      )}
    </>
  );
};

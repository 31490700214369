import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { colors } from '../../styles/colors';
import { useGlobalStore, setupNavbar } from '../../store';
import { ThemeContext } from '../../contexts/themeContext';
import theme from '../../theme';
// import { PendencyContext } from '../../contexts/pendencyContext';

const ITEM_HEIGHT = 50;

const BaseNavbar = ({ className, handleNavItemClick, user = false }) => {
  const { navbarItems } = useGlobalStore();
  const [activeItemIndex, setActiveItemIndex] = useState(null);
  const themeValue = useContext(ThemeContext);

  const setItemInfo = (index) => {
    setActiveItemIndex(index);
    if (activeItemIndex !== index) {
      const item = navbarItems[index];
      // setPage(item.mobileText || item.text);
    }
  };

  const itemActive = (index) => activeItemIndex === index;

  useEffect(() => {
    setupNavbar(false, user?.role, user?.company_id);
  }, [user]);

  const createItemIcon = (item, index) => {
    return item.to === '/pendencias' ? (
      <item.Icon
        color='red'
        fontSize={20}
      />
    ) : (
      <item.Icon
        color={
          itemActive(index)
            ? themeValue.navbar_text_color_active
            : themeValue.navbar_text_color
        }
        size='32px'
      />
    );
  };
  const createItem = (item, index) => {
    return (
      <>
        {item?.isImage ? (
          <img
            src={item.Icon}
            height='32px'
            width='32px'
          />
        ) : item?.isCustomIcon ? (
          <item.Icon
            height='32px'
            width='32px'
          />
        ) : (
          createItemIcon(item, index)
        )}

        <span className='margin-icon'>{item.text}</span>
      </>
    );
  };

  return (
    <nav className={className}>
      {/* {activeItemIndex !== null && (
        <span
          className='nav-item-active-tick'
          style={{ top: ITEM_HEIGHT * activeItemIndex }}
        />
      )} */}
      {navbarItems &&
        navbarItems.map((item, index) => {
          return (
            <NavLink
              exact='true'
              to={item.to === '/pendencias' ? '/home' : item.to}
              onClick={(e) => handleNavItemClick(e, item.to)}
              key={'navitem'.concat(index)}
              className='nav-item'
            >
              {createItem(item, index)}
            </NavLink>
          );
        })}
    </nav>
  );
};

BaseNavbar.propTypes = {
  className: PropTypes.string.isRequired,
};

const Navbar = styled(BaseNavbar)`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${theme.palette.primary.main};
    border-radius: 20px;
    border: 5px solid ${(props) => props.themeValue.navbar_bg_color};
  }

  .nav {
    &-item {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 20px;
      font-weight: 700;
      text-decoration: none;
      line-height: 20px;
      color: ${(props) => props.themeValue.navbar_text_color};
      padding-left: 20px;
      padding-top: 20px;

      &:last-child {
        position: absolute;
        padding-bottom: 20px;
        bottom: 0;

        @media (min-width: 992px) {
          /* margin-left: 0; */
        }

        @media (max-height: 316px) {
          position: relative;
        }
      }

      &:hover {
        svg {
          color: ${(props) =>
            props?.themeValue?.navbar_text_color_hover} !important;
        }

        color: ${(props) => props?.themeValue?.navbar_text_color_hover};
      }

      &.active {
        color: ${(props) =>
          props?.themeValue?.navbar_text_color_active} !important;

        &:hover svg {
          color: ${(props) =>
            props?.themeValue?.navbar_text_color_active} !important;
        }
      }

      &-active-tick {
        transition: top 0.2s ease;
        left: 0;
        width: 10px;
        height: ${ITEM_HEIGHT}px;
        position: absolute;
        background-color: ${(props) =>
          props?.themeValue?.navbar_text_color_active};
      }
    }
  }

  .margin-icon {
    vertical-align: middle;
    padding-left: 15px;
    width: 0;

    @media (min-width: 992px) {
      display: none;
    }
  }
`;

export default Navbar;

import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.button`
  width: 100%;
  border-radius: 8px;
  border: none;
  background: ${theme.palette.red.main};
  color: #fbfdff;
  font-size: 20px;
  font-weight: 600;
  transition: all 0.2s;

  &:hover {
    background: ${theme.palette.red.dark};
    /* box-shadow: 0px 1px 3px 1px #00000026; */
    box-shadow: 0px 1px 2px 0px #0000004d;
  }

  &:hover:disabled {
    box-shadow: none;
  }

  &:active {
    background: ${theme.palette.red.light};
  }

  &:disabled {
    color: #989799;
    background: #e4e4e4;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;
    cursor: not-allowed;
  }
`;

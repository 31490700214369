export const stage0ExceptionErrors = (workingPremisses) => {
  const { err, stagePayload, functions, changePayload } = workingPremisses;
  const {
    file,
    setFile,
    setIsOpenUpdloadSuccessButNotPdfFileModal,
    setModalOcrOpen,
    fileInputRef,
    setButtonDisabled,
  } = functions;
  const { status } = err;

  const { email, promocode, contract_id } = stagePayload || { email: {} };

  switch (status) {
    case 401:
      const { alreadyUsed } = email;

      changePayload({
        email: {
          ...email,
          status: 'refused',
          error: 'E-mail já cadastrado',
          alreadyUsed: [...alreadyUsed, email.value],
        },
      });
      return true;
    case 410:
      if (file[0].type !== 'application/pdf') {
        setIsOpenUpdloadSuccessButNotPdfFileModal(true);
      } else {
        setModalOcrOpen(true);
        setFile([]);
        fileInputRef.current.value = '';
        changePayload({ attachment: '', contract_id });
      }
      return true;
    case 411:
      setButtonDisabled(true);
      changePayload({
        promocode: {
          ...promocode,
          status: 'refused',
          touched: true,
        },
      });
      return true;
  }
  return false;
};

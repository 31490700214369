import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { generatePayloadFieldsModifieds } from '../helpers/form/generatePayloadFieldsModifieds';
import { executePreparedPayload } from '../helpers/form/setFieldsPerTable';
import { TOAST_MESSAGES } from '../helpers/toastMessages';
import ContractsAdminService from '../services/administrator/contractDetails.service';
import adminStores from '../store/admin/contractDetails/index.store';
import { updateAdminStore } from '../store/admin/contractDetails/helpers';

export const LocationContext = createContext();

const EDITING_INITIAL_STATE = {
  location: false,
  credential: false,
  measurements: false,
  activation: false,
  analytics: false,
};

const LocationContextProvider = (props) => {
  const {
    location: { location },
    credential: { credential },
    measurements: { measurements },
  } = adminStores();
  const [isEditing, setIsEditing] = useState({ ...EDITING_INITIAL_STATE });

  const updateDataStore = async (payload) => {
    if (Object.keys(payload).length !== 0) {
      const dataUpdated = await ContractsAdminService.update(payload);
      updateAdminStore(dataUpdated);
      toast.success(TOAST_MESSAGES.SUCCESS);
    }
  };

  const onSubmit = async (data, { setSubmitting }) => {
    try {
      if (!(credential && measurements))
        return toast.error('Aguarde o carregamento de todos os dados');

      const preparedPayload = await executePreparedPayload(data);

      const payloadLocation = preparedPayload?.locations
        ? generatePayloadFieldsModifieds(preparedPayload.locations, location)
        : {};
      const payloadCredentials = preparedPayload?.credentials
        ? generatePayloadFieldsModifieds(
            preparedPayload.credentials,
            credential
          )
        : {};
      const payloadMeasurements = preparedPayload?.measurements?.value
        ? generatePayloadFieldsModifieds(
            preparedPayload.measurements.value,
            measurements
          )
        : {};

      const payload = {};
      if (Object.keys(payloadLocation).length > 0)
        Object.assign(payload, {
          locations: { ...payloadLocation, id: location.id },
        });
      if (Object.keys(payloadCredentials).length > 0)
        Object.assign(payload, {
          credentials: { ...payloadCredentials, id: credential.id },
        });
      if (Object.keys(payloadMeasurements).length > 0)
        Object.assign(payload, {
          measurements: Object.values(payloadMeasurements),
        });

      await updateDataStore(payload);

      setIsEditing((state) => ({
        ...state,
        measurements: false,
        analytics: false,
      }));
    } catch (error) {
      console.log(
        '🚀 ~ file: LocationContext.jsx:61 ~ onSubmit ~ error:',
        error
      );
      return toast.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const onValidateCredential = async (payload, setIsSubmiting) => {
    try {
      setIsSubmiting(true);
      await ContractsAdminService.update(payload);
      toast.warning(TOAST_MESSAGES.PROCESS_IN_PROGRESS);
    } catch (err) {
      toast.error(TOAST_MESSAGES.ERROR);
    } finally {
      setIsSubmiting(false);
    }
  };

  return (
    <LocationContext.Provider
      value={{
        ...props,
        isEditing,
        setIsEditing,
        onSubmit,
        updateDataStore,
        onValidateCredential,
      }}
    >
      {props.children}
    </LocationContext.Provider>
  );
};

LocationContextProvider.propTypes = {
  props: PropTypes.object,
};

export default LocationContextProvider;

import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PageView from '../../../containers/PageView';
import ProducerOccupationService from '../../../services/administrator/ManagementBases/producerOccupation.service';
import SearchableTable from '../../../components/Interface/SearchableTable';

import { Paragraph } from '../../../components/Interface/Text/Paragraph';

import ContentCard from '../../../components/ContentCard';
import { PROVIDERS_TYPES_LABEL } from '../../../helpers/providersTypes';

const COLUMNS = [
  { name: 'Gerador' },
  { name: 'Distribuidora' },
  { name: 'Capacidade (kWh/mês)' },
  { name: 'Ocupação (kWh/mês)' },
  { name: 'Ocupação (%)' },
  { name: 'Ativo (#)' },
  { name: 'Ocupação Ativo (%)' },
  { name: 'Aguardando Transferência (#)' },
  { name: 'Ocupação Aguardando Transferência (%)' },
  { name: 'Aguardando Ativação (#)' },
  { name: 'Ocupação Aguardando Ativação (%)' },
  { name: 'Cadastrado (#)' },
  { name: 'Ocupação Cadastrado (%)' },
];

const addCountAndOccupationRate = (values, data) => {
  const { count, occupationRate } = data;
  values.push({
    value: <Paragraph>{(count || 0).toString()}</Paragraph>,
  });
  values.push({
    value: (
      <Paragraph>{`${parseFloat((occupationRate || 0) * 100)
        .toFixed(5)
        .replace('.', ',')}%`}</Paragraph>
    ),
  });
};

const getRows = (datas) => {
  return datas.map((data) => {
    const { all, ...restOfStatus } = data;
    const { producer, provider, volume, occupation, occupationRate } = all;

    const rows = {
      values: [
        {
          value: <Paragraph>{producer}</Paragraph>,
        },
        {
          value: <Paragraph>{PROVIDERS_TYPES_LABEL[provider]}</Paragraph>,
        },
        {
          value: (
            <Paragraph>{`${parseFloat(volume || 0).toLocaleString(
              'pt-BR'
            )} kWh`}</Paragraph>
          ),
        },
        {
          value: (
            <Paragraph>{`${parseFloat(occupation).toLocaleString(
              'pt-BR'
            )} kWh`}</Paragraph>
          ),
        },
        {
          value: (
            <Paragraph>{`${parseFloat((occupationRate || 0) * 100)
              .toFixed(5)
              .replace('.', ',')}%`}</Paragraph>
          ),
        },
      ],
    };

    if (restOfStatus) {
      Object.values(restOfStatus).map((data) => {
        addCountAndOccupationRate(rows.values, data);
      });
    }

    return rows;
  });
};

const ProducerOccupation = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    active: [],
    data: [],
  });

  useEffect(() => {
    const fetchProducerOccupation = async () => {
      try {
        const data = await ProducerOccupationService.getAll();
        setPageState((state) => ({
          ...state,
          loading: false,
          data: Object.values(data),
        }));
      } catch (err) {
        setPageState((state) => ({ ...state, loading: false, hasError: true }));
      }
    };

    fetchProducerOccupation();
  }, []);

  const { loading, hasError, data } = pageState;

  return (
    <PageView
      title='Ocupação de Usinas'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <ContentCard title=''>
            <SearchableTable
              columns={COLUMNS}
              rowsValues={getRows(data, true)}
              noItemsText='Nenhum contrato'
              dataIsComplete
            />
          </ContentCard>
        </Grid>
      </Grid>
    </PageView>
  );
};

export default ProducerOccupation;

import connection from '../connections/main';

const getAll = async () => {
  const { err, data } = await connection.get(`/producerReport`);

  if (err) {
    throw err;
  }

  return data;
};

const create = async (payload) => {
  console.log(payload);
  const { err, data } = await connection.post(`/producerReport`, {
    producerReport: payload,
  });

  if (err) {
    throw err;
  }

  return data;
};

const update = async (id, payload) => {
  delete payload.id;
  const { err, data } = await connection.put(`/producerReport/${id}`, {
    producerReport: payload,
  });

  if (err) {
    throw err;
  }

  return data;
};

const deleteProducerReport = async (id) => {
  const { err, data } = await connection.delete(`/producerReport/${id}`);

  if (err) {
    throw err;
  }

  return data;
};

const ProducerReportService = {
  getAll,
  create,
  update,
  deleteProducerReport,
};

export default ProducerReportService;

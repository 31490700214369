import React from 'react';
import { Container } from './styles';

export const PrimaryButtonRed = ({ children, ...rest }) => (
  <Container
    type='button'
    {...rest}
  >
    {children}
  </Container>
);

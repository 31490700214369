import { utils } from '../../utils/iugu';

export const birthdayMask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const cpfMask = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const cnpjMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const maxMaskPhone = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const minMaskPhone = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const amex = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const diners = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const cvv = [/\d/, /\d/, /\d/];
export const cvv4 = [/\d/, /\d/, /\d/, /\d/];

const creditCardNumber = Array(19)
  .fill(/\d/)
  .map((d, i) => (i > 0 && (i + 1) % 5 === 0 ? ' ' : d));

const creditCardExpDate = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

const postalCode = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
const fullDate = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const formMasks = Object.freeze({
  locale: (length) => {
    return Array(length.length).fill(/[0-9]{1,2}([,.][0-9]{1,2})?/);
  },
  money: (length) => {
    if (!length) throw Error('There is no defined length');
    return Array(length).fill(/[0-9]{1,2}([,.][0-9]{1,2})?/);
    // .match(/[0-9]{1,2}([,.][0-9]{1,2})?/g);
  },
  birthday: () => birthdayMask,
  cpf: () => cpfMask,
  cnpj: () => cnpjMask,
  cpfOrCnpj: (input) => {
    const digits = input.match(/\d/g);
    const cpfOrCnpnj = digits && digits.join('');
    return cpfOrCnpnj && cpfOrCnpnj.length > 11 ? cnpjMask : cpfMask;
  },
  phone: (input) => {
    const digits = input.match(/\d/g);
    const phone = digits && digits.join('');

    if (!phone) {
      return minMaskPhone;
    }
    return phone.length > 10 ? maxMaskPhone : minMaskPhone;
  },
  creditCardNumber: (values) => {
    if (values?.card?.number) {
      const { number } = values.card;
      if (!utils) return creditCardNumber;
      const brand = utils.getBrandByCreditCardNumber(number);

      switch (brand) {
        case 'visa':
          return creditCardNumber;
        case 'mastercard':
          return creditCardNumber;
        case 'amex':
          return amex;
        case 'diners':
          return diners;
        default:
          return creditCardNumber;
      }
    } else {
      return creditCardNumber;
    }
  },
  creditCardExpDate: () => creditCardExpDate,
  cvv: (values) => {
    if (values?.card?.number) {
      const { number } = values.card;
      if (!utils) return cvv;
      const brand = utils?.getBrandByCreditCardNumber(number);

      switch (brand) {
        case 'visa':
          return cvv;
        case 'mastercard':
          return cvv;
        case 'amex':
          return cvv4;
        case 'diners':
          return cvv;
        default:
          return cvv;
      }
    } else {
      return cvv;
    }
  },
  postalCode: () => postalCode,
  onlyNumbers: () => {},
  onlyDigits: (length) => {
    if (!length) throw Error('There is no defined length');
    return Array(length).fill(/\d/);
  },
  fullDate: () => fullDate,
});

export const maskCep = (value) => {
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{5})(\d)/, '$1-$2');
  return value;
};

export const maskPhone = (value) => {
  if (!value) return value;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
  value = value.replace(/(\d)(\d{8})$/, '$1 $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');

  // value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
  // value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
};

export const maskCpf = (value) => {
  if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)$/, '$1-$2');
    // value = value;
  }

  return value;
};

export const maskCnpj = (value) => {
  value = value.replace(/^(\d{2})(\d)/, '$1.$2');
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
  value = value.replace(/(\d{4})(\d)/, '$1-$2');

  return value;
};

export const maskCpfOrCnpj = (value) => {
  try {
    if (!value) return '';
    value = value.replace(/\D/g, '');

    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      value = value.replace(/^(\d{2})(\d)/, '$1.$2');
      value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
      value = value.replace(/(\d{4})(\d)/, '$1-$2');
    }

    return value;
  } catch (error) {
    console.log('file: masks.js || line 278 || error', error);
    return '';
  }
};

export const maskPercentage = (value, multiplier = 100) => {
  try {
    if (!value) value = 0;
    return `${(+value * multiplier).toFixed(2)}%`;
  } catch (error) {
    return value;
  }
};

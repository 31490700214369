import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../styles/colors';
import Loader from '../Loader';

const ContentCardTitle = styled.p`
  font-family: Myriad-Pro-Condensed;
  font-size: 24px;
  color: ${colors.primary};
  margin-bottom: 10px;
`;

const BaseContentCard = ({ className, title = undefined, children = undefined, loading = undefined }) => {
  return (
    <div className={className}>
      {title && <ContentCardTitle>{title}</ContentCardTitle>}
      <div className={'card-box'.concat(loading ? ' loading' : '')}>
        {!loading && children}
        {loading && <Loader />}
      </div>
    </div>
  );
};

BaseContentCard.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
};

const ContentCard = styled(BaseContentCard)`
  .card-box {
    position: relative;
    letter-spacing: 0;
    background: #ffffff;
    border-radius: 4px;
    padding: 0px;

    &.loading {
      min-height: 400px;
    }
  }
`;

export default ContentCard;

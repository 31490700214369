import styled from 'styled-components';

export const ProspectType = styled.div`
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 18px;
  top: -1px;
  left: 4px;
  vertical-align: middle;
  font-size: 12px;

  &.p-1 {
    background: #9abdce;
  }
  &.p-2 {
    background: #ff9900;
  }

  &:before {
    position: absolute;
    left: 0px;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &.p-1:before {
    content: 'U';
    color: #20848c;
  }
  &.p-2:before {
    content: 'P';
    color: #984600;
  }

  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  border-radius: 50%;
  text-align: center;
`;

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import PageView from '../../../containers/PageView';
import { ChromePicker } from 'react-color';
import styled from 'styled-components';
import { Paragraph } from '../../../components/Interface/Text';
import GradientButton from '../../../components/GradientButton';
import { Button, InputAdornment, TextField } from '@mui/material';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';
import { toast } from 'react-toastify';
import { isCompanyFlora, useAuthStore } from '../../../store';
import StyleguidesService from '../../../services/administrator/styleguides.service';
import AttachmentsService from '../../../services/attachments/attachments.service';
import RestoreIcon from '@mui/icons-material/Restore';
import Modal from '@mui/material/Modal';
import HelpIcon from '@mui/icons-material/Help';
import corPrimaria from '../../../assets/images/corprimaria.jpg';
import corFundo from '../../../assets/images/cordefundo.jpg';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../../theme';

const defaultFloraBtnColors = {
  primary_btn_bg: 'orange',
  secondary_btn_bg: '#61787b',
  primary_btn_color: 'white',
  secondary_btn_color: 'white',
};

const defaultFloraColors = {
  primary_color: '#fffd00',
  secondary_color: '#f9af40',
  text_color: '#61787b',
  login_bg_color: '#61787b',
};

const defaultFloraLogo = 'media/flora-logo.png';

function Personalization() {
  let { companyId } = useParams();
  const [styleguideId, setStyleguideId] = useState(null);
  const [pageState, setPageState] = useState({
    loading: false,
    hasError: false,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [colors, setColors] = useState(defaultFloraColors);
  const [openSelectors, setOpenSelectors] = useState({
    primary_color_selector_open: false,
    secondary_color_selector_open: false,
    text_color_selector_open: false,
    background_color_selector_open: false,
    primary_btn_bg_selector_open: false,
    primary_btn_text_color_selector_opened: false,
    secondary_btn_bg_selector_open: false,
    secondary_btn_text_color_selector_open: false,
  });
  const [logo, setLogo] = useState('');
  const userCompany = useAuthStore((state) => state.userAuth?.company_id);

  const [btn, setBtn] = useState(defaultFloraBtnColors);
  const [reload, setReload] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [partToRestore, setPartToRestore] = useState(null);

  const handleChangeComplete = (e, color) => {
    setColors((state) => ({
      ...state,
      [color]: e.hex,
    }));
  };

  const handleClose2 = () => {
    setOpenModal2(false);
  };

  const handleClose = () => {
    setOpenModal(false);
    setPartToRestore(null);
  };

  const openAreYouSureModal = (el) => {
    setOpenModal(true);
    setPartToRestore(el);
  };

  const resetDefault = () => {
    if (!partToRestore) {
      toast.error(TOAST_MESSAGES.ERROR);
    }
    switch (partToRestore) {
      case 'btns':
        setBtn(defaultFloraBtnColors);
        handleSaveBtn(defaultFloraBtnColors).then((res) => {
          handleClose();
        });
        break;
      case 'colors':
        setColors(defaultFloraColors);
        handleSaveColors(defaultFloraColors).then((res) => {
          handleClose();
        });
        break;
      case 'logo':
        setLogo(null);
        handleResetLogo();
        handleClose();
        break;
      default:
        break;
    }
  };

  const handleChangeComplete2 = (e, color) => {
    setBtn((state) => ({
      ...state,
      [color]: e.hex,
    }));
  };

  const handleResetLogo = async () => {
    try {
      const styleguide_id = location.state?.styleguide_id || styleguideId;
      const req = await StyleguidesService.updateOne(styleguide_id, {
        logo_link: defaultFloraLogo,
        id: styleguide_id,
      });
      if (req.success) {
        toast.success(TOAST_MESSAGES.SUCCESS);
      }
      setIsEditing(false);
      setReload(!reload);
    } catch (error) {
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const handleFileChange = async (e) => {
    if (!location.state?.styleguide_id && !styleguideId) return;
    const styleguide_id = location.state?.styleguide_id || styleguideId;
    const file = e?.target?.files?.[0];
    if (!file) return;
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      toast.error(
        'Formato de arquivo invalido. Deve-se enviar somente documentos em formato jpg, jpeg ou png'
      );
      setIsEditing(false);
      return;
    }

    try {
      let attachmentKey = await AttachmentsService.uploadAttachment(file); // need to refactor
      let req = await StyleguidesService.updateOne(styleguide_id, {
        logo_link: attachmentKey,
        id: styleguide_id,
      });
      if (req.success) {
        toast.success(TOAST_MESSAGES.SUCCESS);
      }
      setIsEditing(false);
      setReload(!reload);
    } catch (error) {
      toast.error(TOAST_MESSAGES.ERROR);
      console.log(error);
    }
  };

  const handleSaveBtn = async (vals = false) => {
    if (!location.state?.styleguide_id && !styleguideId) return;
    const styleguide_id = location.state?.styleguide_id || styleguideId;
    const btnsCopy = vals || btn;
    try {
      let req = await StyleguidesService.updateOne(styleguide_id, {
        ...btnsCopy,
        id: styleguide_id,
      });
      if (req.success) {
        toast.success(TOAST_MESSAGES.SUCCESS);
      }
    } catch (error) {
      console.log(error);
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const handleSaveColors = async (vals = false) => {
    if (!location.state?.styleguide_id && !styleguideId) return;
    const styleguide_id = location.state?.styleguide_id || styleguideId;
    const colorsCopy = vals || colors;

    try {
      let req = await StyleguidesService.updateOne(styleguide_id, {
        ...colorsCopy,
        id: styleguide_id,
      });
      if (req.success) {
        toast.success(TOAST_MESSAGES.SUCCESS);
      }
    } catch (error) {
      console.log(error);
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  useEffect(() => {
    const fetchPersonalization = async () => {
      try {
        let req = await StyleguidesService.getByCompanyId(
          isCompanyFlora() ? companyId : userCompany
        );
        let styleguide = req?.[0]?.[0] || req?.[0];
        if (!styleguide) {
          throw Error();
        }
        setStyleguideId(styleguide.id);
        setBtn({
          primary_btn_bg: styleguide.primary_btn_bg,
          secondary_btn_bg: styleguide.secondary_btn_bg,
          primary_btn_color: styleguide.primary_btn_color,
          secondary_btn_color: styleguide.secondary_btn_color,
        });
        setColors({
          primary_color: styleguide.primary_color,
          secondary_color: styleguide.secondary_color,
          text_color: styleguide.text_color,
          login_bg_color: styleguide.login_bg_color,
        });
        setLogo(`${styleguide.logo_link}`);
      } catch (error) {
        console.log(error);
        toast.error('Ocorreu um erro ao buscar as informações.');
      }
    };
    fetchPersonalization();
  }, [reload]);

  const { loading, hasError } = pageState;
  const { primary_color, secondary_color, text_color, login_bg_color } = colors;

  const {
    primary_btn_bg,
    primary_btn_color,
    secondary_btn_bg,
    secondary_btn_color,
  } = btn;

  const {
    primary_btn_bg_selector_open,
    primary_btn_text_color_selector_opened,
    secondary_btn_bg_selector_open,
    secondary_btn_text_color_selector_open,
    primary_color_selector_open,
    secondary_color_selector_open,
    background_color_selector_open,
    text_color_selector_open,
  } = openSelectors;
  return (
    <PageView
      title='Personalização'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      <MainSection>
        <LowerDiv>
          <Box2>
            <TitleContainer>
              <h5>Logo</h5>
              <RestoreIcon
                class='icon'
                style={{ color: 'orange' }}
                onClick={() => openAreYouSureModal('logo')}
              />
            </TitleContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                accept='image/png, image/jpeg'
                className=''
                id='contained-button-filee'
                multiple
                type='file'
                hidden
                onChange={(e) => {
                  handleFileChange(e, 'social_statute_link');
                }}
              />
              <Paragraph>Imagem:</Paragraph>
              <label
                htmlFor='contained-button-filee'
                style={{ marginTop: '1rem' }}
              >
                <Button
                  variant='contained'
                  style={{
                    backgroundColor: '#61787b',
                    color: 'white',
                    marginLeft: '2rem',
                  }}
                  component='span'
                >
                  Alterar Logo
                </Button>
              </label>
              <img
                style={{ marginLeft: '1rem' }}
                src={`${import.meta.env.REACT_APP_AWS_BUCKET_URL}/${logo}`}
                width='125'
                height='75'
              />
            </div>
          </Box2>
        </LowerDiv>
        <UpperDiv>
          <Box>
            <TitleContainer>
              <div>
                {' '}
                <h5>Cores</h5>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '50%',
                }}
              >
                <RestoreIcon
                  className='icon'
                  style={{ color: '#617867b', marginRight: '0.5rem' }}
                  onClick={() => openAreYouSureModal('colors')}
                />
                <HelpIcon
                  className='icon'
                  style={{ color: '#f9af40' }}
                  onClick={() => setOpenModal2(true)}
                />
              </div>
            </TitleContainer>
            <InnerDiv>
              <HorizontalDiv>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Paragraph>Cor Primaria:</Paragraph>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '0.5rem',
                    }}
                  >
                    <TextField
                      className=''
                      variant='outlined'
                      defaultValue={primary_color}
                      value={primary_color}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position='start'
                            onClick={() =>
                              setOpenSelectors((state) => ({
                                ...state,
                                primary_color_selector_open:
                                  !primary_color_selector_open,
                              }))
                            }
                          >
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '1rem',
                                backgroundColor: primary_color,
                                border: '1px solid gray',
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        style: {
                          marginLeft: '1rem',
                        },
                      }}
                    />
                  </div>

                  {primary_color_selector_open && (
                    <div style={{ marginLeft: '2rem' }}>
                      <ChromePicker
                        color={primary_color}
                        onChangeComplete={(e) =>
                          handleChangeComplete(e, 'primary_color')
                        }
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Paragraph>Cor Do Texto:</Paragraph>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '0.5rem',
                    }}
                  >
                    <TextField
                      className=''
                      variant='outlined'
                      defaultValue={text_color}
                      value={text_color}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position='start'
                            onClick={() =>
                              setOpenSelectors((state) => ({
                                ...state,
                                text_color_selector_open:
                                  !text_color_selector_open,
                              }))
                            }
                          >
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '1rem',
                                backgroundColor: text_color,
                                border: '1px solid gray',
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        style: {
                          marginLeft: '1rem',
                        },
                      }}
                    />
                  </div>
                  {text_color_selector_open && (
                    <div style={{ marginLeft: '3rem' }}>
                      <ChromePicker
                        color={text_color}
                        onChangeComplete={(e) =>
                          handleChangeComplete(e, 'text_color')
                        }
                      />
                    </div>
                  )}
                </div>
              </HorizontalDiv>

              <HorizontalDiv style={{ marginTop: '3rem' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Paragraph>Cor Secundária:</Paragraph>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '0.5rem',
                    }}
                  >
                    <TextField
                      className=''
                      variant='outlined'
                      defaultValue={secondary_color}
                      value={secondary_color}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position='start'
                            onClick={() =>
                              setOpenSelectors((state) => ({
                                ...state,
                                secondary_color_selector_open:
                                  !secondary_color_selector_open,
                              }))
                            }
                          >
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '1rem',
                                backgroundColor: secondary_color,
                                border: '1px solid gray',
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        style: {
                          marginLeft: '1rem',
                        },
                      }}
                    />
                  </div>
                  {secondary_color_selector_open && (
                    <div style={{ marginLeft: '2rem' }}>
                      <ChromePicker
                        color={secondary_color}
                        onChangeComplete={(e) =>
                          handleChangeComplete(e, 'secondary_color')
                        }
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Paragraph>Cor de Fundo Login:</Paragraph>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '0.5rem',
                    }}
                  >
                    <TextField
                      className=''
                      variant='outlined'
                      defaultValue={login_bg_color}
                      value={login_bg_color}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position='start'
                            onClick={() =>
                              setOpenSelectors((state) => ({
                                ...state,
                                background_color_selector_open:
                                  !background_color_selector_open,
                              }))
                            }
                          >
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '1rem',
                                backgroundColor: login_bg_color,
                                border: '1px solid gray',
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        style: {
                          marginLeft: '1rem',
                        },
                      }}
                    />
                  </div>
                  {background_color_selector_open && (
                    <div style={{ marginLeft: '2rem' }}>
                      <ChromePicker
                        color={login_bg_color}
                        onChangeComplete={(e) =>
                          handleChangeComplete(e, 'login_bg_color')
                        }
                      />
                    </div>
                  )}
                </div>
              </HorizontalDiv>
            </InnerDiv>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <GradientButton
                text='Salvar'
                handleClick={() => handleSaveColors()}
              />
            </div>
          </Box>
          <Box style={{ marginRight: '6rem' }}>
            <TitleContainer>
              <h5>Botões</h5>
              <RestoreIcon
                class='icon'
                style={{ color: 'orange' }}
                onClick={() => openAreYouSureModal('btns')}
              />
            </TitleContainer>
            <InnerDiv>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Paragraph style={{ color: 'black' }}>
                  Botão Primário:
                </Paragraph>
              </div>
              <HorizontalDiv>
                {/* <Paragraph
                    style={{
                      textAlign: 'center',
                      marginBottom: '1rem',
                      fontWeight: 'bold',
                    }}
                  >
                    Primário
                  </Paragraph> */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Paragraph>Cor de Fundo:</Paragraph>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      className=''
                      variant='outlined'
                      defaultValue={primary_btn_bg}
                      value={primary_btn_bg}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position='start'
                            onClick={() =>
                              setOpenSelectors((state) => ({
                                ...state,
                                primary_btn_bg_selector_open:
                                  !primary_btn_bg_selector_open,
                              }))
                            }
                          >
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '1rem',
                                backgroundColor: primary_btn_bg,
                                border: '1px solid gray',
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        style: {
                          marginLeft: '1rem',
                        },
                      }}
                    />
                  </div>
                  {primary_btn_bg_selector_open && (
                    <div style={{ marginLeft: '2rem' }}>
                      <ChromePicker
                        color={primary_btn_bg}
                        onChangeComplete={(e) =>
                          handleChangeComplete2(e, 'primary_btn_bg')
                        }
                      />
                    </div>
                  )}
                </div>

                <div>
                  <GradientButton
                    text='PREVIEW'
                    color={primary_btn_color}
                    background={primary_btn_bg}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Paragraph>Cor do Texto:</Paragraph>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      className=''
                      variant='outlined'
                      defaultValue={primary_btn_color}
                      value={primary_btn_color}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position='start'
                            onClick={() =>
                              setOpenSelectors((state) => ({
                                ...state,
                                primary_btn_text_color_selector_opened:
                                  !primary_btn_text_color_selector_opened,
                              }))
                            }
                          >
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '1rem',
                                backgroundColor: primary_btn_color,
                                border: '1px solid gray',
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        style: {
                          marginLeft: '1rem',
                        },
                      }}
                    />
                  </div>
                  {primary_btn_text_color_selector_opened && (
                    <div style={{ marginLeft: '2rem' }}>
                      <ChromePicker
                        color={primary_btn_color}
                        onChangeComplete={(e) =>
                          handleChangeComplete2(e, 'primary_btn_color')
                        }
                      />
                    </div>
                  )}
                </div>
              </HorizontalDiv>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '3rem',
                }}
              >
                <Paragraph style={{ color: 'black' }}>
                  Botão Secundário:
                </Paragraph>
              </div>
              <HorizontalDiv>
                {/* <Paragraph
                    style={{
                      textAlign: 'center',
                      marginBottom: '1rem',
                      fontWeight: 'bold',
                    }}
                  >
                    Secundário
                  </Paragraph> */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Paragraph>Cor de Fundo:</Paragraph>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      className=''
                      variant='outlined'
                      defaultValue={secondary_btn_bg}
                      value={secondary_btn_bg}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position='start'
                            onClick={() =>
                              setOpenSelectors((state) => ({
                                ...state,
                                secondary_btn_bg_selector_open:
                                  !secondary_btn_bg_selector_open,
                              }))
                            }
                          >
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '1rem',
                                backgroundColor: secondary_btn_bg,
                                border: '1px solid gray',
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        style: {
                          marginLeft: '1rem',
                        },
                      }}
                    />
                  </div>
                  {secondary_btn_bg_selector_open && (
                    <div style={{ marginLeft: '2rem' }}>
                      <ChromePicker
                        color={secondary_btn_bg}
                        onChangeComplete={(e) =>
                          handleChangeComplete2(e, 'secondary_btn_bg')
                        }
                      />
                    </div>
                  )}
                </div>
                <div>
                  <GradientButton
                    text='PREVIEW'
                    color={secondary_btn_color}
                    background={secondary_btn_bg}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Paragraph>Cor do Texto:</Paragraph>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <TextField
                      className=''
                      variant='outlined'
                      defaultValue={secondary_btn_color}
                      value={secondary_btn_color}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position='start'
                            onClick={() =>
                              setOpenSelectors((state) => ({
                                ...state,
                                secondary_btn_text_color_selector_open:
                                  !secondary_btn_text_color_selector_open,
                              }))
                            }
                          >
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '1rem',
                                backgroundColor: secondary_btn_color,
                                border: '1px solid gray',
                              }}
                            ></div>
                          </InputAdornment>
                        ),
                        style: {
                          marginLeft: '1rem',
                        },
                      }}
                    />
                  </div>
                  {secondary_btn_text_color_selector_open && (
                    <div style={{ marginLeft: '2rem' }}>
                      <ChromePicker
                        color={secondary_btn_color}
                        onChangeComplete={(e) =>
                          handleChangeComplete2(e, 'secondary_btn_color')
                        }
                      />
                    </div>
                  )}
                </div>
              </HorizontalDiv>
            </InnerDiv>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <GradientButton
                text='Salvar'
                handleClick={() => handleSaveBtn()}
              />
            </div>
          </Box>
        </UpperDiv>
        <Modal
          open={openModal}
          onClose={() => handleClose()}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                width: 'fit-content',
                height: 'auto',
                padding: '2rem 4rem',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h2 style={{ color: '#61787b' }}>Tem certeza desta operação?</h2>
              {partToRestore === 'logo' ? (
                <h5>
                  Isso deletará o logo atual e mudará para logo padrão da Flora
                  Energia
                </h5>
              ) : (
                <h5>Isso irá resetar as cores para o padrão da Flora</h5>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: '100%',
                  marginTop: '2rem',
                }}
              >
                <GradientButton
                  paddingg='10px 30px'
                  text='Sim'
                  handleClick={() => resetDefault()}
                />
                <GradientButton
                  paddingg='10px 30px'
                  background='red'
                  text='Nao'
                  handleClick={() => handleClose()}
                />
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={openModal2}
          onClose={handleClose2}
          aria-labelledby='simple-modal-title2'
          aria-describedby='simple-modal-description2'
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                width: 'fit-content',
                height: 'auto',
                maxWidth: '75%',
                padding: '2rem 4rem',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CloseDiv
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <CloseIcon
                  style={{ color: 'red' }}
                  onClick={handleClose2}
                  className='icon'
                />
              </CloseDiv>
              <h5
                style={{
                  color: '#61787b',
                  textAlign: 'center',
                  fontSize: '1.6rem',
                }}
              >
                Guia de Cores
              </h5>
              <InnerModal2Section>
                <Column>
                  <div style={{ width: '100%', height: '100%' }}>
                    <img
                      src={corPrimaria}
                      style={{ width: '95%' }}
                    />
                  </div>
                </Column>

                <Column>
                  <div style={{ width: '100%', height: '100%' }}>
                    <img
                      src={corFundo}
                      style={{ width: '95%' }}
                    />
                  </div>
                </Column>
              </InnerModal2Section>
              <InnerModal2Section></InnerModal2Section>
            </div>
          </div>
        </Modal>
      </MainSection>
    </PageView>
  );
}

export default Personalization;

const InnerModal2Section = styled.section`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-sectiontart;
  justify-content: cemter;
`;

const CloseDiv = styled.div`
  .icon:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const HorizontalDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const MainSection = styled.section`
  height: 100vh;
  width: 100vw;
  padding-right: 1.5rem;
  margin-bottom: 15rem;
`;

const TitleContainer = styled.div`
  margin: 0;
  width: 100%;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  h5 {
    font-size: 1.5rem;
    color: ${theme.palette.primary.main};
    font-weight: bold;
  }
  .icon {
    max-height: 1.5rem;
    :hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
`;

const UpperDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const LowerDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
`;

const Box = styled.div`
  width: 46%;
  border: 1px solid lightgray;
  padding: 1.5rem;
  border-radius: 10px;
`;
const Box2 = styled.div`
  margin-top: 1rem;
  width: 46%;
  border: 1px solid lightgray;
  padding: 1.5rem;
  border-radius: 10px;
`;

const InnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  margin-top: 4rem;
  /* align-items:  */
`;

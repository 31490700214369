import React, { createContext, useState } from 'react';

export const DataForProposalContext = createContext();

export const DataForProposalProvider = ({ children }) => {
  const [dataForProposal, setDataForProposal] = useState({});

  return (
    <DataForProposalContext.Provider
      value={{ dataForProposal, setDataForProposal }}
    >
      {children}
    </DataForProposalContext.Provider>
  );
};

import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import theme from '../../theme';
// import Select from 'react-select';
// import EKOApi from '../../services/general/api';

const BaseInputText = ({
  className,
  label,
  name,
  type,
  mask,
  placeholder,
  onChange,
}) => {
  const maskedInput = (field) => (
    <MaskedInput
      mask={mask}
      {...field}
      id={name}
      placeholder={placeholder}
      onChange={onChange}
    />
  );

  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <Field
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
      >
        {mask ? ({ field }) => maskedInput(field) : null}
      </Field>
    </div>
  );
};

BaseInputText.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  mask: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  fullwidth: PropTypes.bool,
  className: PropTypes.string.isRequired,
  outlined: PropTypes.bool,
  onChange: PropTypes.func,
};
BaseInputText.defaultProps = {
  label: null,
  type: 'text',
  mask: null,
  placeholder: null,
  fullwidth: false,
  outlined: false,
  onChange: null,
};

export const InputText = styled(BaseInputText)`
  label,
  input {
    display: block;
    letter-spacing: 0.085em;
    color: ${(props) => props.color || theme.palette.primary.main};
    font-size: 20px;
  }

  input {
    font-size: 20px;
    display: block;
    padding: 5px 15px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
    outline: none;
    width: ${(props) => (props.fullwidth ? '100%' : 'auto')};
    background-color: ${(props) =>
      props.outlined ? 'transparent' : 'initial'};

    &::placeholder {
      color: ${(props) => props.color || '#c1c1c1'};
      font-family: Myriad-Pro;
      letter-spacing: 0.085em;
    }
  }

  @media screen and (max-width: 768px) {
    label,
    input {
      font-size: 14px;
    }
  }
`;

const BaseInputCheckbox = ({ name, label, className, onChange, ...props }) => (
  <div className={className}>
    <Field
      {...props}
      onChange={onChange}
      name={name}
      type='checkbox'
    />
    <label
      htmlFor={name}
      onClick={(e) => onChange(e)}
      role='presentation'
    >
      {label}
    </label>
  </div>
);
BaseInputCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
BaseInputCheckbox.defaultProps = {
  color: null,
};
export const InputCheckbox = styled(BaseInputCheckbox)`
  display: flex;
  align-items: center;

  label,
  input {
    font-family: Myriad-Pro;
    letter-spacing: 0.085em;
    color: ${(props) => props.color || theme.palette.primary.main};
    font-size: 20px;
    cursor: pointer;
  }

  input[type='checkbox'] {
    display: inline-block;
    position: relative;
    color: ${(props) => props.color || theme.palette.primary.main};
    cursor: pointer;
    margin-right: 15px;
    width: 16px;
    height: 16px;
    -webkit-appearance: initial;
    -moz-appearance: initial;
    appearance: initial;
    outline: none;

    &::before {
      position: absolute;
      content: ' ';
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      border: 1px solid ${(props) => props.color || theme.palette.primary.main};
      border-radius: 2px;
      font-size: 18px;
    }

    &[value='true']::before {
      content: '\\2713';
    }
  }

  @media screen and (max-width: 768px) {
    label,
    input {
      font-size: 14px;
    }
  }
`;

// const unserializeInput = (value) => {
//   return value.substring(0, value.indexOf('-')).trim();
// };

// const serializeInput = ({ nome, uf }) => {
//   return `${nome} - ${uf}`;
// };

// validate if the city appears on json but not in options
// const handleSetvalue = (options, value) => {
//   const mappedCity = options.find((option) => option.value === value);
//   if (!mappedCity) {
//     const rawCityName = unserializeInput(value);
//     let searchedCity = EKOApi.getIBGECities({ searchText: rawCityName })[0];
//     const searializedInfo = serializeInput(searchedCity);

//     searchedCity = {
//       value: searializedInfo,
//       label: searializedInfo,
//     };

//     return !!value ? searchedCity : value;
//   }

//   return mappedCity;
// };

// const ReactSelect = ({
//   options,
//   form: { setFieldValue },
//   field: { name, value },
//   onChange,
//   scrollDownHandle,
//   inputChangeHandle,
//   ...props
// }) => {
//   return (
//     <Select
//       {...props}
//       options={options}
//       hideSelectedOptions
//       value={options ? handleSetvalue(options, value) : null}
//       onChange={(option) => setFieldValue(name, option ? option.value : '')}
//       isClearable
//       onMenuScrollToBottom={scrollDownHandle}
//       onInputChange={inputChangeHandle}
//     />
//   );
// };

// ReactSelect.propTypes = {
//   form: PropTypes.string.isRequired,
//   field: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
//   options: PropTypes.array.isRequired,
//   scrollDownHandle: PropTypes.func.isRequired,
//   inputChangeHandle: PropTypes.func.isRequired,
// };

// const BaseInputSelect = ({
//   className,
//   label,
//   name,
//   placeholder,
//   options,
//   scrollDownHandle,
//   inputChangeHandle,
// }) => {
//   return (
//     <div className={className}>
//       {label && <label htmlFor={name}>{label}</label>}
//       <Field
//         name={name}
//         placeholder={placeholder}
//         component={ReactSelect}
//         options={options}
//         scrollDownHandle={scrollDownHandle}
//         inputChangeHandle={inputChangeHandle}
//       />
//     </div>
//   );
// };

// BaseInputSelect.propTypes = {
//   className: PropTypes.string.isRequired,
//   label: PropTypes.string,
//   name: PropTypes.string.isRequired,
//   placeholder: PropTypes.string,
//   options: PropTypes.array.isRequired,
//   scrollDownHandle: PropTypes.func.isRequired,
//   inputChangeHandle: PropTypes.func.isRequired,
// };
// BaseInputSelect.defaultProps = {
//   label: null,
//   placeholder: null,
// };

// export const InputSelect = styled(BaseInputSelect)`
//   label,
//   input {
//     display: block;
//     font-family: Myriad-Pro;
//     letter-spacing: 0.085em;
//     color: ${(props) => props.color || theme.palette.primary.main};
//     font-size: 20px;
//   }
//   select {
//     position: relative;
//     width: ${(props) => (props.fullwidth ? '100%' : 'auto')};
//     background-color: #ffffff;
//     border-radius: 2px;
//     border: transparent;

//     font-family: 'Myriad-Pro';
//     font-size: 16px;
//     color: #4d5e63;

//     &:hover {
//       cursor: pointer;
//     }
//   }
// `;

import { extractBlobData } from '../../helpers/file';
import unauthenticatedAttachmentsUploadConnection from './connections/unauthenticatedConnection';

const uploadAttachment = async (file, contractId) => {
  const formData = new FormData();
  formData.append('file', file);

  if (contractId) formData.append('contractId', contractId);

  const { err, data } = await unauthenticatedAttachmentsUploadConnection.post(
    '',
    formData
  );

  if (err) throw err;

  return data;
};

const uploadAttachmentAsBinary = async (file) => {
  // oldMethod, can be used via direct upload
  const blobData = await extractBlobData(file);

  const uploadLink = import.meta.env.REACT_APP_UPLOAD_URL;

  await fetch(uploadLink, { method: 'POST', body: blobData });
  return { success: true };
};

const uploadAttachmentAsPresignedUrl = async (file) => {
  // not in use
  const { name: filename, type: mimetype } = file;
  const body = { filename, mimetype };
  const { err, data: uploadLink } =
    await unauthenticatedAttachmentsUploadConnection.put('', body);
  if (err) throw err;

  const blobData = await extractBlobData(file);

  await fetch(uploadLink, { method: 'PUT', body: blobData });
  return { success: true };
};

const AttachmentsService = {
  uploadAttachment,
};

export default AttachmentsService;

import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
} from '@mui/material';
import styled from 'styled-components';
import BasesManagement from '../BasesManagement';
import EditIcon from '@mui/icons-material/Edit';
import PartnerIcon from '@mui/icons-material/AddLink';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import verifyPermissionAction from '../../utils/verifyPermissionAction';

const ModalFormBaseManagement = ({ data, setHasUpdateData }) => {
  const { title, path, icon, onlySuperAdmin } = data;

  const [open, setOpen] = useState(false);
  const hasPermissionAction = onlySuperAdmin ? verifyPermissionAction() : true;

  const openModal = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const defineIcon = () =>
    icon === 'editIcon' ? (
      <EditIcon color={hasPermissionAction ? 'primary' : 'disable'} />
    ) : icon === 'partnerIcon' ? (
      <PartnerIcon color={hasPermissionAction ? 'primary' : 'disable'} />
    ) : (
      <DeleteIcon color={hasPermissionAction ? 'primary' : 'disable'} />
    );
  return (
    <>
      {!hasPermissionAction ? (
        <Tooltip title='Você não tem permissão para utilizar esse recurso'>
          <IconButton>{defineIcon()}</IconButton>
        </Tooltip>
      ) : (
        <IconButton
          aria-label='edit item'
          onClick={openModal}
        >
          {defineIcon()}
        </IconButton>
      )}

      {open && (
        <Dialog
          open={open}
          onRequestClose={handleClose}
          onClose={handleClose}
          fullWidth={true}
          maxWidth='sm'
        >
          <DialogHeader>
            <Button onClick={handleClose}>x</Button>
          </DialogHeader>
          <DialogContent>
            <BasesManagement
              title={title}
              indentifier={path}
              type='editFormRequest'
              maxHeighComponent={window.innerHeight - 400}
              setHasUpdateDataTable={setHasUpdateData}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: end;
  h2 {
    flex-grow: 1;
    text-align: center;
  }
`;

export default ModalFormBaseManagement;

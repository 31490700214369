import _ from 'lodash';

const generatePayloadFieldsModifieds = (formData, data, additionalFormData) => {
  const dataPayload = {};
  if (Array.isArray(data)) {
    //Garante que estarão na mesma ordem na hora de percorrer os dados
    data = _.orderBy(data, ['id'], ['asc']);
    formData = _.orderBy(formData, ['id'], ['asc']);

    const payloadItem = [];
    data.map((item, index) => {
      let payload = {};
      for (const [field, value] of Object.entries(item)) {
        if (
          value !== formData[index][field] &&
          parseFloat(value) !== parseFloat(formData[index][field]) &&
          typeof formData[index] !== 'undefined' &&
          typeof item[field] !== 'undefined'
        ) {
          payload = { ...payload, [field]: formData[index][field] };
        }
      }
      if (Object.keys(payload).length > 0)
        payloadItem.push({ ...payload, id: formData[index]['id'] });
    });
    Object.assign(dataPayload, payloadItem);
  } else {
    for (const [field, value] of Object.entries(data)) {
      if (
        value !== formData[field] &&
        parseFloat(value) !== parseFloat(formData[field]) &&
        typeof formData[field] !== 'undefined' &&
        typeof data[field] !== 'undefined'
      ) {
        dataPayload[field] = formData[field];
      }
    }
  }
  return Object.assign(dataPayload, additionalFormData);
};

export { generatePayloadFieldsModifieds };

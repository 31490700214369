import React, { useContext } from 'react';
import { Form } from 'formik';
import { Grid } from '@mui/material';

import { FormPropTypes, FormDefaultProps } from '../types';
import {
  FieldWrapper,
  Label,
  CustomField,
  CustomErrorMessage,
} from '../../Interface/Form';
import { PAYMENT_METHODS } from '../../../helpers/paymentMethods';
import GenericForm from '../GenericForm';
import PaymentDetailsFormSchema from './schema';
import Divider from '../../Divider';
import { formMasks } from '../../../helpers/form/masks';
import AddressFields from '../AddressFields';
import { Paragraph } from '../../Interface/Text/Paragraph';
import GradientButton from '../../GradientButton';
import { ThemeContext } from '../../../contexts/themeContext';
import theme from '../../../theme';

const initialValues = {
  email: '',
  first_name: '',
  last_name: '',
  street: '',
  number: '',
  complement: '',
  postal_code: '',
  neighborhood: '',
  city: '',
  state: '',
  method: PAYMENT_METHODS.BankSlip,
  card: {
    number: '',
    verification_value: '',
    expiration: '',
  },
};

const PaymentDetailsForm = ({
  onSubmit,
  values,
  readonly,
  isEditing,
  buttonText,
  onCancel,
  expanded,
  isAdminPage,
}) => {
  const handleCancel = (resetForm) => {
    resetForm();
    onCancel();
  };
  const { themeValue } = useContext(ThemeContext);

  return (
    <GenericForm
      initialValues={initialValues}
      values={values}
      schema={PaymentDetailsFormSchema}
      onSubmit={onSubmit}
    >
      {({
        values: formValues,
        isSubmitting,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        resetForm,
      }) => {
        return (
          <>
            <Form
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              style={{ paddingLeft: '1rem' }}
            >
              <CustomField
                type='hidden'
                name='isAdminPage'
              />

              <Grid
                container
                spacing={3}
                style={{ paddingLeft: '0rem' }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <FieldWrapper>
                    <Label htmlFor='email'>Email:</Label>
                    <CustomField
                      type='email'
                      name='email'
                      disabled={readonly}
                      fullwidth={1}
                      style={{
                        color: '#484848',
                        padding: '0',
                        margin: '0',
                      }}
                    />
                    <CustomErrorMessage name='email' />
                  </FieldWrapper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                    <FieldWrapper>
                      <Label htmlFor='method'>Método de Pagamento:</Label>
                        <Paragraph style={{ color: '#484848' }}>{formValues.method !== PAYMENT_METHODS.Card ? 'Bolepix' : 'Cartão de crédito'}</Paragraph>
                    </FieldWrapper>
                </Grid>

                {!isAdminPage ? (
                  <Grid
                    item
                    xs={12}
                  >
                    <FieldWrapper>
                      <Label
                        htmlFor='method'
                        style={{ fontSize: '1rem', color: 'black' }}
                      >
                        <CustomField
                          type='radio'
                          name='method'
                          disabled={readonly}
                          fullwidth={1}
                          value={PAYMENT_METHODS.BankSlip}
                          style={{ color: 'black' }}
                        />
                        Boleto
                      </Label>

                      <Label
                        htmlFor='method'
                        style={{ fontSize: '1rem', color: 'black' }}
                      >
                        <CustomField
                          type='radio'
                          name='method'
                          disabled={readonly}
                          fullwidth={1}
                          value={PAYMENT_METHODS.Card}
                          style={{ color: 'black' }}
                        />
                        Cartão
                      </Label>
                      <Label
                        htmlFor='method'
                        style={{ fontSize: '1rem', color: 'black' }}
                      >
                        <CustomField
                          type='radio'
                          name='method'
                          disabled={readonly}
                          fullwidth={1}
                          value={PAYMENT_METHODS.Pix}
                          style={{ color: 'black' }}
                        />
                        Pix
                      </Label>
                      <CustomErrorMessage name='method' />
                    </FieldWrapper>
                  </Grid>
                ) : null}

                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <FieldWrapper>
                    <Label htmlFor='first_name'>Nome:</Label>
                    <CustomField
                      name='first_name'
                      disabled={readonly}
                      fullwidth={1}
                      style={{
                        color: 'black',
                        padding: '0',
                        margin: '0',
                      }}
                    />
                    <CustomErrorMessage name='first_name' />
                  </FieldWrapper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                >
                  <FieldWrapper>
                    <Label htmlFor='last_name'>Sobrenome:</Label>
                    <CustomField
                      name='last_name'
                      disabled={readonly}
                      fullwidth
                      style={{
                        color: 'black',
                        padding: '0',
                        margin: '0',
                      }}
                    />
                    <CustomErrorMessage name='last_name' />
                  </FieldWrapper>
                </Grid>

                {formValues.method === PAYMENT_METHODS.Card &&
                  expanded &&
                  !isAdminPage && (
                    <>
                      <Grid
                        item
                        xs={12}
                      >
                        <Heading3>Dados do cartão</Heading3>
                      </Grid>
                      <hr
                        style={{
                          color: 'lightgray',
                          height: '1px',
                          marginTop: '-0.2rem',
                          border: 'none',
                          width: '100%',
                          backgroundColor: 'lightgray',
                        }}
                      />

                      {readonly && (
                        <>
                          <Grid
                            item
                            xs={12}
                          >
                            <FieldWrapper>
                              <Label
                                style={{
                                  color: themeValue?.text_color || '#61787b',
                                }}
                              >
                                Número:
                              </Label>
                              <Paragraph style={{ color: 'black' }}>
                                {formValues.display_number}
                              </Paragraph>
                            </FieldWrapper>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                          >
                            <FieldWrapper>
                              <Label
                                style={{ color: '#61787b', letterSpacing: '0' }}
                              >
                                Data de Vencimento:
                              </Label>
                              <Paragraph style={{ color: 'black' }}>
                                {formValues.expiration_date}
                              </Paragraph>
                            </FieldWrapper>
                          </Grid>
                        </>
                      )}

                      {!readonly && (
                        <>
                          <Grid
                            item
                            xs={12}
                          >
                            <FieldWrapper>
                              <Label
                                htmlFor='card.number'
                                style={{
                                  color: themeValue?.text_color || '#61787b',
                                }}
                              >
                                Número:
                              </Label>
                              <CustomField
                                name='card.number'
                                disabled={readonly}
                                fullwidth={1}
                                mask={formMasks.creditCardNumber(formValues)}
                                style={{
                                  color: 'black',
                                  padding: '0',
                                  margin: '0',
                                }}
                              />
                              <CustomErrorMessage name='card.number' />
                            </FieldWrapper>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                          >
                            <FieldWrapper>
                              <Label
                                htmlFor='card.verification_value'
                                style={{
                                  color: themeValue?.text_color || '#61787b',
                                }}
                              >
                                CVV:
                              </Label>
                              <CustomField
                                name='card.verification_value'
                                disabled={readonly}
                                fullwidth={1}
                                mask={formMasks.cvv(formValues)}
                                style={{
                                  color: 'black',
                                  padding: '0',
                                  margin: '0',
                                }}
                              />
                              <CustomErrorMessage name='card.verification_value' />
                            </FieldWrapper>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                          >
                            <FieldWrapper>
                              <Label
                                htmlFor='card.expiration'
                                style={{
                                  color: themeValue?.text_color || '#61787b',
                                }}
                              >
                                Vencimento:
                              </Label>
                              <CustomField
                                name='card.expiration'
                                disabled={readonly}
                                fullwidth={1}
                                mask={formMasks.creditCardExpDate()}
                                style={{
                                  color: 'black',
                                  padding: '0',
                                  margin: '0',
                                }}
                              />
                              <CustomErrorMessage name='card.expiration' />
                            </FieldWrapper>
                          </Grid>
                        </>
                      )}
                    </>
                  )}

                {expanded && (
                  <AddressFields
                    readonly={readonly}
                    isEditing={isEditing}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    values={formValues}
                  />
                )}
              </Grid>
              {expanded && (
                <>
                  <Divider space='medium' />
                  <Grid
                    container
                    justify='flex-end'
                    spacing={2}
                  >
                    {!readonly && onCancel && (
                      <Grid item>
                        <GradientButton
                          paddingg='10px 30px'
                          background='white'
                          color={theme.palette.primary.main}
                          border={`1px solid ${theme.palette.primary.main}`}
                          text='Cancelar'
                          handleClick={() => handleCancel(resetForm)}
                          disabled={isSubmitting}
                          outlined
                          rectangle
                          type='button'
                        />
                      </Grid>
                    )}
                    {!readonly && (
                      <Grid item>
                        <GradientButton
                          paddingg='10px 30px'
                          text={buttonText || 'Salvar'}
                          handleClick={handleSubmit}
                          disabled={isSubmitting}
                          rectangle
                          isLoading={isSubmitting}
                          isSubmitting={isSubmitting}
                        />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </Form>
          </>
        );
      }}
    </GenericForm>
  );
};

PaymentDetailsForm.propTypes = FormPropTypes;
PaymentDetailsForm.defaultProps = FormDefaultProps;

export default PaymentDetailsForm;

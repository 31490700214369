import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Navigate } from 'react-router-dom';
import { isUserAuthenticated, useAuthStore } from '../../store';
import { isAdmin } from '../../helpers/userRoles';

export const AUTH_STORAGE_KEY = 'floraenergia:auth';

const userAllowed = (isUserAuthenticated, roles) => {
  const userRole = useAuthStore.getState().userAuth?.role;

  if (!userRole) {
    localStorage.removeItem(AUTH_STORAGE_KEY);
  }
  return (
    isUserAuthenticated &&
    userRole &&
    (roles.length === 0 || roles.some((role) => userRole === role))
  );
};

const notAllowedCallback = (isUserAuthenticated) => {
  if (isUserAuthenticated) {
    const role = useAuthStore.getState().userAuth?.role;
    return <Navigate to={isAdmin(role) ? '/contratos' : '/home'} />;
  }
  return <Navigate to='/login' />;
}

const PrivateRouter = ({
  component: Component,
  allowedRoles = [],
  path,
  ...rest
}) => {
  const permission = userAllowed(isUserAuthenticated(), allowedRoles);
  const element = permission ? (
    <Component {...rest} />
  ) : (
    notAllowedCallback(isUserAuthenticated())
  );
  return (
    <Route
      {...rest}
      path={path}
      element={element}
      key={path}
    />
  );
};

PrivateRouter.propTypes = {
  component: PropTypes.elementType.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string),
};

export default PrivateRouter;

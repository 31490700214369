import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import styled from 'styled-components';
import ExportingDatabasesService from '../../../../services/administrator/ManagementBases/ExportingDatabasesService';
import { toast } from 'react-toastify';
import { TOAST_MESSAGES } from '../../../../helpers/toastMessages';
import { H1 } from '../../../../components/Interface';
import Filter from '../../../../components/Filter';
import DownloadBases from '../../../../components/DownloadBases';
import { Heading1 } from '../../../../components/Interface/Text';
import verifyPermissionAction from '../../../../utils/verifyPermissionAction';

const AdminExportDatabases = () => {
  const [bases, setBases] = useState(null);
  const [baseSelected, setbaseSelected] = useState(null);
  const [filter, setFilter] = useState([]);
  const [period, setPeriod] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const baseHandle = (option) => {
    const { value } = option.target;
    setbaseSelected(value);
  };

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const result = await ExportingDatabasesService.getAll();
        const { bases } = result;
        setBases(bases);
      } catch (error) {
        toast.error(TOAST_MESSAGES.ERROR);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
    setbaseSelected(null);
  }, []);

  return (
    <Container>
      <Heading1>Download de bases</Heading1>

      {isLoading && bases === null ? (
        <ContainerLoading>
          <CircularProgress />
        </ContainerLoading>
      ) : bases && Object.keys(bases)?.length !== 0 ? (
        <>
          <Filter
            hasPeriod={true}
            columns={[]}
            setPeriod={setPeriod}
            period={period}
          />
          <FormContainer>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Base</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                defaultValue={baseSelected}
                label='Base'
                onChange={baseHandle}
                name='select-base'
              >
                {bases.map((base) => (
                  <MenuItem
                    key={base.id}
                    value={base}
                  >
                    {base.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {baseSelected ? (
              <DownloadBases
                indentifier={baseSelected.id}
                data={baseSelected}
                hasUpdateBase={true}
                filter={filter}
                period={period}
                refreshedAt={baseSelected.refreshed_at}
                style={{ flexDirection: 'column' }}
                hasPermissionToUpdate={
                  baseSelected?.download_options
                    ?.onlySuperAdminHasPermissionToUpdate
                    ? verifyPermissionAction()
                    : true
                }
              />
            ) : null}
          </FormContainer>
        </>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  padding: 10px;
`;

const FormContainer = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-width: 300px;
  gap: 1rem;
`;

const ContainerLoading = styled.div`
  display: flex;
  flex-grow: 1;
  height: 300px;
  justify-content: center;
  align-items: center;
`;
export default AdminExportDatabases;

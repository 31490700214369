import { extractBlobData } from '../../helpers/file';
import uploadDataConnection from '../administrator/connections/S3/uploadData';
import administratorConnection from '../administrator/connections/main';
import authenticatedAttachmentConnection from './connections/authenticatedConnection';

const trigggerPreProcess = async (formData) => {
  const { err, data } = await authenticatedAttachmentConnection.post(
    '/invoicing/preparedAttachments',
    formData
  );
  if (err) throw err;

  return data;
};

const uploadPdf = async (formData) => {
  const { err, data } = await authenticatedAttachmentConnection.post(
    '/invoicing/uploadPdf',
    formData
  );
  if (err) throw err;

  return data;
};

const uploadUnpreparedAttachment = async (file, time) => {
  const { path: filename } = file;
  const body = { filename, time };
  const { err, data: uploadLink } = await uploadDataConnection.put(
    '/batch/attachments/cpfl',
    body
  );
  if (err) throw err;

  const blobData = await extractBlobData(file);

  const data = await fetch(uploadLink, { method: 'PUT', body: blobData });

  return data;
};

const uploadSheetAttachment = async (file, time) => {
  const { path: filename } = file;
  const body = { filename, time };
  const { err, data: uploadLink } = await uploadDataConnection.put(
    '/batch/attachments/sheet',
    body
  );
  if (err) throw err;

  const blobData = await extractBlobData(
    file,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  );

  const data = await fetch(uploadLink, { method: 'PUT', body: blobData });
  return data;
};
const startBatchJob = async (time, attachents) => {
  const body = { time, attachentsLenght: attachents?.length, provider: 'cpfl' };
  const { err, data } = await administratorConnection.post(
    `/invoices/startBatchJob`,
    body
  );

  if (err) throw err;

  return data;
};

const createOcrsFromSheet = async (sheetData, provider) => {
  const { clients, balance } = sheetData;
  const { err, data } = await administratorConnection.post(
    `/manualInvoicesGeneration/sheet`,
    { clients, balance, provider }
  );

  if (err) throw err;

  return data;
};

const generateInvoicesService = {
  trigggerPreProcess,
  uploadUnpreparedAttachment,
  startBatchJob,
  createOcrsFromSheet,
  uploadPdf,
  uploadSheetAttachment,
};

export default generateInvoicesService;

import ReactModal from 'react-modal';
import styled, { css } from 'styled-components';

ReactModal.setAppElement('#root');

const modalCustomStyle = {
  content: {
    padding: 0,
    position: 'relative',
    inset: 0,
    ':focusVisible': { outline: '0' },
    margin: '0.75rem',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    inset: 0,
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const isLoading = css`
  background: transparent;
  border-radius: 0;
  box-shadow: none;
`;

export const Modal = styled(ReactModal).attrs({
  style: modalCustomStyle,
})`
  background: #fbfdff;

  border-radius: 0.25rem;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
  width: calc(100% - 64px);
  height: calc(100vh - 64px);
  ${(props) => props.isLoading && isLoading};
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;

import styled, { css } from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  min-height: 100%;
  background: ${(props) => props.themeValue.page_user_background};
  padding-top: 3.75rem;

  @media (max-width: 479px) {
    margin-top: 1.5rem;
  }
`;

export const Content = styled.div`
  max-width: 428px;
  margin: 0 auto;
  padding: 1.5rem;

  @media (min-width: 992px) {
    max-width: 1128px;
  }
`;

export const TitleWithDropdownContainer = styled.div`
  display: block;
  align-items: end;
  justify-content: space-between;

  > div:nth-child(2) {
    margin-top: 1rem;

    @media (min-width: 992px) {
      max-width: 264px;
    }
  }

  @media (min-width: 992px) {
    display: flex;
  }
`;

export const TitleWithDropdownContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  h2 {
    font-weight: 400;
  }
  svg {
    color: ${theme.palette.primary.main};
  }
`;

export const Separator = styled.div`
  height: 1px;
  background: ${theme.palette.gray.main};
  margin: 1rem 0 1.5rem 0;

  @media (min-width: 992px) {
    margin-top: 1.5rem;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;

  grid-gap: 1.5rem;

  @media (min-width: 992px) {
    /* display: grid; */
    /* grid-template-columns: repeat(2, 1fr); */
    /* grid-template-rows: repeat(2, 1fr); */
  }

  /* > div:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 3;
    align-self: baseline;
  } */

  @media (max-width: 576px) {
    margin: 0;
    /* font-size: 87.5% */
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);

  > p {
    margin: 0;
    margin-block-end: 0.5rem;
    color: #000000;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 1.313rem;
  }

  p + p {
    margin-block-end: 0;
  }
`;

export const CardWithDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:nth-child(2) {
    max-width: 100px;

    @media (min-width: 992px) {
      max-width: 120px;
    }
  }
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  /* margin-bottom: 24px; */

  h2 {
    margin: 0 0 0 0.25rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: ${(props) =>
      props.isPendency ? theme.palette.red.main : theme.palette.primary.main};
  }

  svg {
    color: ${(props) =>
      props.isPendency
        ? theme.palette.red.main
        : theme.palette.primary.main} !important;
  }
`;

export const CardWithEconomyAndSustainabilityContainer = styled.div`
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;

  @media (min-width: 992px) {
    display: grid;
  }
`;

export const EconomyAndSustainabilityContent = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
  }
`;

export const TableContainer = styled.table`
  width: 100%;

  th {
    font-size: 1.125rem;
    font-weight: 500;
    color: ${theme.palette.primary.main};
    padding: 0 0.25rem;
  }

  td {
    font-size: 1.125rem;
    font-weight: 300;
    color: #1f1b1e;

    strong {
      font-weight: 500;
      color: ${theme.palette.primary.main};
    }
  }

  tfoot td {
    font-weight: 500;
  }

  thead,
  tbody {
    border-bottom: 0.5px solid ${theme.palette.purple.main};
  }

  th,
  td {
    padding-bottom: 0.5rem;
  }

  tbody {
    tr:nth-child(1) td {
      padding-top: 0.5rem;
    }
  }

  tfoot tr td {
    padding-top: 0.5rem;
    color: ${theme.palette.primary.main};
  }

  td:nth-child(n + 2):nth-child(-n + 3),
  th {
    text-align: center;
  }

  td:last-child {
    text-align: right;
  }

  td + td {
    padding-left: 0.5rem;
  }
`;

export const SearchButton = styled.a`
  display: inline-flex;
  padding: 0.25rem;
  border-radius: 8px;
  background: ${theme.palette.secondary.main};
  border: none;
  text-align: center;
  color: ${theme.palette.primary.main};
  transition: filter 0.2s !important;

  &:hover {
    color: ${theme.palette.purple.dark};
    background: ${theme.palette.green.dark};
  }
`;

export const IconContainer = styled.div`
  background: ${theme.palette.primary.main};
  border-radius: 50%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px !important;
  min-width: 48px !important;

  svg {
    font-size: 1.8rem;
  }
`;

export const LabelContainer = styled.span`
  font-size: 1.25rem;
  line-height: 1.438rem;
  color: #1f1b1e;

  b {
    font-weight: 700;
    color: ${theme.palette.primary.main};
  }

  strong {
    font-weight: 700;
    color: ${theme.palette.primary.main};
  }

  sub {
    font-size: 0.75rem;
  }
`;

export const FooterCard = styled.div`
  ${(props) =>
    props.showBtnUnderstandYourBill
      ? `> div:nth-child(2) {
    display: none;
    width: 1.5rem;
    height: 1px;
    transform: rotate(90deg);
    background: #88aaab;

    @media (min-width: 992px) {
      display: block;
    }
  }`
      : ''}

  span {
    color: #1f1b1e;
    font-weight: 300;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  a {
    color: ${theme.palette.primary.main};
    text-decoration: underline;
    /* font-size: 14px; */
    font-weight: 500;
    line-height: 1rem;
  }

  a:nth-child(2) {
    display: block;

    @media (min-width: 380px) {
      display: none;
    }
  }

  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }
`;

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const NotificationButton = styled.button`
  width: 100%;
  border: 0;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 1.5rem;
  background: #fbfdff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  h2 {
    font-weight: 700;
    font-size: 1.25rem;
    margin: 0;
    color: ${theme.palette.primary.main};
  }

  svg {
    font-size: 2rem;
    color: ${theme.palette.primary.main};
  }

  ${({ isPendency }) =>
    isPendency &&
    css`
      background: ${theme.palette.secondary.main};

      h2,
      svg {
        color: ${theme.palette.primary.main};
      }
    `}
`;

const isPendency = css`
  h2,
  strong {
    color: ${theme.palette.red.main};
  }
`;

export const Title = styled.div`
  h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
  }

  strong {
    color: ${theme.palette.primary.main};
    font-weight: 700;
  }

  ${(props) => props.isPendency && isPendency}
`;

export const LineWithButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    width: 100%;
    font-size: 1.25rem;
    padding: 0.563rem;
  }

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const UnderstandYourBillButton = styled.button`
  background: transparent;
  border: 0;
  color: ${theme.palette.primary.main};

  text-decoration: underline;
  font-weight: 700;
`;

export const CreditStockButton = styled.button`
  background: #fffbfe;
  border: 0;
  margin-left: 1rem;
  padding: 0.25rem;
  transition: filter 0.2s;
  border-radius: 50%;
  svg {
    color: ${theme.palette.purple.main};
  }

  &:hover {
    filter: brightness(0.8);
  }
`;

export const InvoicesAndConsumptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const CreditStockContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const HasPendenciesOrNotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > p {
    margin: 0;
    font-weight: 300;
    font-size: 1.125rem;
    color: #000000;
  }
`;

export const BodyNotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    margin: 0;
    font-weight: 300;
    font-size: 1.125rem;
    color: #000000;
  }
`;

export const ContinueHiringContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > p {
    margin: 0;
    font-weight: 300;
    font-size: 1.125rem;
    color: #000000;
  }

  strong {
    font-weight: 500;
  }

  button {
    padding: 0.563rem 0;
    font-size: 1.25rem;
  }
`;

export const Tooltip = styled.div`
  h6 {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: #1f1b1e;
  }

  p {
    margin: 0;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    font-weight: 300;
    color: #1f1b1e;
    left: 50%;
    bottom: calc(100% + 20px);

    &::before {
      content: '';
      border-style: solid;
      border-color: #f4eff2 transparent;
      border-width: 16px 0 0 16px;
      bottom: 20px;
      position: absolute;
      top: 100%;
      right: 0;

      @media (min-width: 992px) {
        left: 0;
        border-width: 16px 16px 0 0;
        right: auto;
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h6 {
    color: ${theme.palette.primary.main};
  }

  svg {
    color: ${theme.palette.primary.main};
  }
`;

export const CloseButton = styled.button`
  border: 0;
  background: #f4eff2;
  padding: 0.25rem;
  transition: 0.2s;
  border-radius: 50%;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const TooltipContainer = styled.div`
  padding: 1rem;
  background: #f4eff2;

  position: absolute;
  width: 248px;
  z-index: 300;
  top: -138px;
  right: 10px;
  border-radius: 8px 8px 0 8px;
  box-shadow: 0px 4px 8px 3px rgb(0 0 0 / 15%);
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3));

  @media (min-width: 480px) {
    top: -180px;
    width: 328px;
    right: 14px;
  }

  @media (min-width: 992px) {
    top: -158px;
    right: 0;
    left: 30px;
    width: 405px;
    border-radius: 8px 8px 8px 0px;
  }
`;

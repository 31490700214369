import styled, { css } from 'styled-components';
import theme from '../../theme';

const isAccept = css`
  border-color: ${theme.palette.success.main};
`;

const hasError = css`
  border-color: ${theme.palette.error.main};
`;

const isDisabled = css`
  border-bottom: 1px solid ${theme.palette.gray.main};
  background: ${theme.palette.grey.light};
`;

const isProposal = css`
  border-bottom: 1px solid ${theme.palette.green.main};
`;

export const Container = styled.div`
  p {
    color: ${theme.palette.error.main};
    font-size: 14px;
    font-weight: 300;
    margin: 4px 0 0 16px;
  }
`;

export const ContContent = styled.div`
  width: 100%;
  /* display: flex; */
  align-items: center;
  position: relative;

  & + div {
    margin-top: 8px;
  }
`;

export const Content = styled.div`
  height: 43px;
  /* width: 100%; */
  display: flex;
  /* align-items: center; */
  border-bottom: 1px solid ${theme.palette.secondary.main};
  background: #fbfdff;

  ${(props) => props.isAccept && isAccept}
  ${(props) => props.hasError && hasError}
  ${(props) => props.isDisabled && isDisabled}  
  ${(props) => props.isProposal && isProposal}  
  
 

  label {
    position: absolute;
    top: 20px;
    left: 16px;
    color: #605d62 !important;
    cursor: text;
    font-size: 16px;
    font-weight: 400;
    transition: 0.3s;
  }

  p {
    color: #605d62;
    position: absolute;
    top: 18px;
    left: 4px;
    display: none;
    pointer-events: none;
    font-weight: 300;
    font-size: 16px;
    margin: 0;
  }

  button {
    display: flex;
    background: none;
    border: none;
    margin-right: 16px;
    cursor: pointer;
  }

  &,
  input,
  label,
  button {
    cursor: ${(props) => props.isDisabled && 'not-allowed'};
  }
`;

export const Input = styled.input`
  background: #fbfdff;
  width: 100%;
  border: none;
  margin-top: 16px;
  padding: ${(props) => (props.isProposal ? '0' : '0 16px')};
  ${(props) => (props.isProposal ? `color: ${theme.palette.black.main};` : '')}
  outline: none;
  font-size: 16px;
  font-weight: 300;

  &:focus + label {
    font-size: 12px !important;
    top: -2px !important;
    color: #605d62 !important;
    transition: 0.1s;
  }

  &:focus + label + p {
    display: block;
  }
`;

export const ContIcon = styled.div`
  margin-top: 16px;
  padding-right: 16px;
`;

import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  margin: 0 12px;
  max-width: 42rem;

  div + div {
    margin-top: 1rem;
  }

  textarea {
    width: 100%;
    height: 16rem;
    resize: none;
    color: #000;
    box-sizing: border-box;
    padding: 4px 8px;

    &:focus {
      outline: none;
    }
  }

  h2 {
    margin: 2.5rem 0 0 0;
    padding: 0;
    font-size: 1.5rem;
    color: red;
  }
`;

export const FieldContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;

  label.Mui-focused {
    color: ${(props) => props.color || theme.palette.primary.main};
  }
  .MuiInput-underline:after {
    border-bottom-color: ${(props) =>
      props.color || theme.palette.primary.main};
  }
`;

import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  max-width: 480px;
  padding: 1.5rem;

  h2 {
    font-size: 1.5rem;
    font-weight: 700;

    text-align: center;
    margin: 0;
  }

  /* @media (min-width: 0px) {
  } */

  /* @media (min-width: 576px) {
  } */
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > p {
    font-size: 1rem;
    margin: 0;
    line-height: 1.188rem;
  }

  button {
    padding: 0.563rem 0;
  }
`;

export const Header = styled.div`
  position: relative;
  width: 100%;

  h2 {
    color: ${theme.palette.primary.main};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -0.438rem;
  right: -0.875rem;
  border: 0;
  background: #fbfdff;
  transition: filter 0.2s;
  padding: 0.5rem;
  border-radius: 50%;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const TitleCheckbox = styled.p`
  margin: 0;
  margin-left: 0.5rem;
  font-weight: 300;
  font-size: 1rem;
  color: #1f1b1e;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: 1.125rem;
    height: 2.75rem;

    &:disabled {
      font-size: 1.125rem;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const Info = styled.p`
  font-weight: 300;
  color: #1f1b1e;
`;

export const Error = styled.div`
  color: #6e242b;
  margin-bottom: 10px;
  text-align: center;
`;

export const SpinnerContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
  left: -24px;
  top: -24px;
  pointer-events: none;

  div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  div:after {
    content: '';
    display: block;
    position: absolute;
    top: 23px;
    left: 37px;
    width: 4px;
    height: 10px;
    border-radius: 20%;
    background: #989799;
    /* background: ${({ isSave }) => (isSave ? '#FBFDFF' : '#F9AF40')}; */
  }
  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { StatusLabel } from '../Interface';
import {
  ADMIN_CONTRACT_STATUS_TYPES_PTBR,
  CONTRACT_STATUS_TYPES_PTBR,
} from '../../helpers/contractStatusTypes';
import { getStatusColor } from '../../styles/colors';
import { ADMIN_PROFILE_TYPES_LABEL } from '../../helpers/profileTypes';
// import { isAdmin } from '../../helpers/userRoles';

// Aplicar lógica de role para deixar componente geral

// const isContractAvailableForReview = (contract) => {
//   return (
//     contract.status === CONTRACT_STATUS_TYPES.DRAFT && contract.proposal_ready
//   );
// };

// const getStatusText = (contract, role) => {
//   if (isContractAvailableForReview(contract)) {
//     return isAdmin(role) ? 'AGUARDANDO ACEITE' : 'REVISE A PROPOSTA';
//   } else {
//     return CONTRACT_STATUS_TYPES_PTBR[contract.status].toUpperCase();
//   }
// };

const setCCOrEmissionError = (contract) => {
  try {
    if (contract?.stage || contract?.user_stage) {
      if (contract?.stage === 0) return 'C≠C';
      if (contract?.stage === 3 && contract?.status === 'draft') return 'EEC';
      if (contract?.stage) return;
      if (contract?.user_stage === 0) return 'C≠C';
      if (contract?.user_stage === 3 && contract?.status === 'draft')
        return 'EEC';
    }
  } catch (error) {
    console.log('file: index.js || line 42 || error', error);
  }
};

const getStatusText = (contract, role, flag = false, profile = false) => {
  if (flag) {
    if (contract?.stage || contract?.user_stage) {
      if (contract?.stage === 0) {
        return true;
      } else {
        if (contract?.user_stage) {
          if (contract.user_stage === 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  if (profile) {
    const { profile: profileData } = contract;
    return ADMIN_PROFILE_TYPES_LABEL[profileData];
  }
  if (contract?.locations && !contract?.locations?.length > 0) {
    return role === 'admin'
      ? ADMIN_CONTRACT_STATUS_TYPES_PTBR[contract.status]
      : CONTRACT_STATUS_TYPES_PTBR[contract.status];
  }

  const locationsStatus = () => {
    if (contract?.status !== 'draft') {
      return contract.status;
    }

    const { locations, proposal_ready } = contract;

    // eslint-disable-next-line no-shadow
    const { stage, profile, user_stage } =
      contract?.locations?.length > 0 ? locations?.[0] : contract;

    if (stage === 0) {
      return 'registered';
    }
    if (profile === 'on_hold') {
      return 'on_hold';
    }
    if (stage === 1) {
      return 'registered';
    }
    if (stage === 2 || stage === 3) {
      return 'proposal_accepted';
    }
    if (stage === 4) {
      return 'on_hold';
    }
    if (user_stage === 0) {
      return 'registered';
    }
    if (user_stage === 1) {
      return 'registered';
    }
    if (stage === 2 || stage === 3) {
      return 'proposal_accepted';
    }
    if (user_stage === 4) {
      return 'on_hold';
    }
    if (proposal_ready === true || proposal_ready === false) {
      return 'registered';
    }
  };
  if (contract?.locations?.length <= 1 || !contract?.locations) {
    return role === 'admin'
      ? ADMIN_CONTRACT_STATUS_TYPES_PTBR[locationsStatus()]
      : CONTRACT_STATUS_TYPES_PTBR[locationsStatus()];
  } else {
    return role === 'admin'
      ? `Certifique-se de fazer a proposta para os outros imóveis, ${
          ADMIN_CONTRACT_STATUS_TYPES_PTBR[locationsStatus()]
        }`
      : CONTRACT_STATUS_TYPES_PTBR[locationsStatus()];
  }
};

export const ContractStatusLabel = ({ contract }) => {
  return (
    <div>
      <StatusLabel status={getStatusColor(contract.status)}>
        {ADMIN_CONTRACT_STATUS_TYPES_PTBR[contract.status]}
      </StatusLabel>
    </div>
  );
};

ContractStatusLabel.propTypes = {
  contract: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

export { getStatusText, setCCOrEmissionError };

import styled from 'styled-components';
import theme from '../../../theme';
import { ThemeContext } from '../../../contexts/themeContext';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 1.5rem;
  max-width: 817px;
  max-height: 460px;

  h2 {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
    color: ${theme.palette.primary.main};
  }
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -0.438rem;
  right: -0.875rem;
  border: 0;
  background: #fbfdff;
  transition: filter 0.2s;
  padding: 0.5rem;
  border-radius: 50%;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const Content = styled.div`
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0.75rem;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9f9f9f;
    border-radius: 1.25rem;
    border: 2px solid #fbfdff;
  }

  ul {
    list-style-type: none;
  }

  li + li {
    margin-top: 0.5rem;
  }
`;

export const Title = styled.h4`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.313rem;
  color: ${theme.palette.primary.main};
`;

export const SubItems = styled.div`
  text-indent: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;

  > h6 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.188rem;
    color: ${theme.palette.primary.main} !important;
  }

  > strong {
    color: ${theme.palette.primary.main} !important;
  }
`;

export const Text = styled.p`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.188rem;

  strong {
    color: ${theme.palette.primary.main};
  }
`;

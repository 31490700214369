import React, { useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';

import Divider from '../Divider';
import Button from '../Button';
import FinishRegisterSchema from './schema';
import FloraLeafLogo from '../../assets/images/logo/symbol-flora-purple.svg';
import {
  Label,
  FieldWrapper,
  CustomField,
  CustomErrorMessage,
} from '../Interface/Form';
import AuthService from '../../services/authentication/auth.service';
import { TOAST_MESSAGES } from '../../helpers/toastMessages';
import { H1 } from '../Interface';
import { Paragraph } from '../Interface/Text/Paragraph';
import ContentCard from '../ContentCard';

const initialValues = {};

const BaseFinishRegisterForm = ({ token, className = undefined, navigate }) => {
  const [registerFinished, setRegisterFinished] = useState(false);

  const handleFormSubmit = async (formData, { setSubmitting }) => {
    setSubmitting(true);

    try {
      const payload = {
        ...formData,
        token,
      };

      await AuthService.finishRegister(payload);

      setRegisterFinished(true);
    } catch (err) {
      toast.error(TOAST_MESSAGES.ERROR);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={className}>
      <ContentCard>
        {registerFinished ? (
          <div className='wrapper'>
            <Grid
              container
              direction='column'
              justify='center'
              alignItems='center'
            >
              <img
                src={FloraLeafLogo}
                alt='Logo em formato de folha da Flora'
              />
              <Divider space='large' />
              <H1>Obrigado!</H1>
              <Divider space='large' />
            </Grid>
            <Grid
              container
              direction='row'
              justify='center'
              alignItems='center'
            >
              <Paragraph align='center'>
                Agora você tem um cadastro na Plataforma Flora! Acesse e conheça
                os benefícios que oferecemos para você e para o meio ambiente!
              </Paragraph>
              <Divider space='medium' />
              <Button
                rectangle
                handleClick={() => navigate('/login')}
                text='Acessar a plataforma'
              />
            </Grid>
          </div>
        ) : (
          <div className='wrapper'>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
              >
                <H1>Que bom que possui interesse!</H1>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Paragraph>
                  Cadastre-se para ter acesso à nossa plataforma onde poderá
                  contratar nossa solução e acompanhar seus benefícios
                </Paragraph>
              </Grid>

              <Grid
                item
                xs={12}
              >
                <Formik
                  initialValues={initialValues}
                  validationSchema={FinishRegisterSchema}
                  onSubmit={handleFormSubmit}
                >
                  {({ handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                      <FieldWrapper direction='column'>
                        <Label>Senha:</Label>
                        <CustomField
                          type='password'
                          name='password'
                        />
                        <CustomErrorMessage name='password' />
                      </FieldWrapper>

                      <Divider space='medium' />
                      <FieldWrapper>
                        <Label htmlFor='password_confirmation'>
                          Confirme sua senha:
                        </Label>
                        <CustomField
                          type='password'
                          name='password_confirmation'
                        />
                        <CustomErrorMessage name='password_confirmation' />
                      </FieldWrapper>

                      <Divider space='medium' />
                      <Grid
                        container
                        direction='row'
                        justify='center'
                        alignItems='center'
                      >
                        <Button
                          rectangle
                          isLoading={isSubmitting}
                          text='Criar conta'
                        />
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </div>
        )}
      </ContentCard>
    </div>
  );
};

const FinishRegisterForm = styled(BaseFinishRegisterForm)`
  .wrapper {
    max-width: 700px;
  }
`;

BaseFinishRegisterForm.propTypes = {
  className: PropTypes.string,
  navigate: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
};

export default FinishRegisterForm;

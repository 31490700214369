import React, { useState } from 'react';
import BasesManagement from '../../../../../components/BasesManagement';
import { NewModalFull } from '../../../../../components/NewModalFull';
import { Button, Dialog, DialogContent } from '@mui/material';
import styled from 'styled-components';
import verifyPermissionAction from '../../../../../utils/verifyPermissionAction';

const BillableItems = ({ open, handleClose }) => {
  const [isOpenModalUpdateMass, setIsOpenModalUpdateMass] = useState(false);
  const [triggerUpdateData, setTriggerUpdateData] = useState(true);
  const [dataMassiveUpdate, setDataMassiveUpdate] = useState([]);
  const hasPermissionAction = verifyPermissionAction();

  const updateMass = (payload) => {
    setTriggerUpdateData(false);
    setIsOpenModalUpdateMass(true);
    setDataMassiveUpdate(payload);
  };

  const updatedMassive = () => {
    setIsOpenModalUpdateMass(false);
    setTriggerUpdateData(true);
  };

  const handleCloseMassiveUpdate = () => {
    setIsOpenModalUpdateMass(false);
  };

  return (
    <NewModalFull
      isOpen={open}
      onRequestClose={handleClose}
    >
      <DialogHeader>
        <Button onClick={handleClose}>x</Button>
      </DialogHeader>
      <DialogContent>
        <BasesManagement
          title='Itens faturáveis'
          indentifier={'/billingManagement/bill_items'}
          reciveMode=''
          hasFilter={true}
          hasPeriod={true}
          hasUpdateBase={true}
          hasSelectItems={true}
          externalTriggerUpdateData={triggerUpdateData}
          maxHeighComponent={window.innerHeight - 500}
          type='table'
          buttonSelectItems={{
            text: 'Editar em massa',
            requestFunction: updateMass,
            disabled: !hasPermissionAction,
          }}
        />
      </DialogContent>
      {isOpenModalUpdateMass && (
        <Dialog
          open={isOpenModalUpdateMass}
          onClicknRequestClose={handleCloseMassiveUpdate}
          onClose={handleCloseMassiveUpdate}
          fullWidth={true}
          maxWidth='sm'
        >
          <DialogHeader>
            <Button onClick={handleCloseMassiveUpdate}>x</Button>
          </DialogHeader>
          <DialogContent>
            <BasesManagement
              title='Editar itens em massa'
              indentifier='/billingItems/massive'
              type='editFormRequest'
              maxHeighComponent={window.innerHeight - 400}
              setHasUpdateDataTable={updatedMassive}
              additionalFormData={dataMassiveUpdate}
            />
          </DialogContent>
        </Dialog>
      )}
    </NewModalFull>
  );
};

const DialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: end;
  h2 {
    flex-grow: 1;
    text-align: center;
  }
`;

export default BillableItems;

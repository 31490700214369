import styled, { css } from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  padding: 1.5rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  h2 {
    margin: 0;
    font-weight: 700;
    font-size: 1.5rem;
    color: ${theme.palette.primary.main};
  }
`;

export const Button = styled.button`
  background: #fbfdff;
  border: 0;
  transition: filter 0.3s;
  border-radius: 50%;
  padding: 8px;

  &:hover {
    filter: brightness(0.8);
  }

  ${(props) =>
    props.isShowBigImage &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

export const TextAndImageContainer = styled.div`
  max-width: 1123px;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;

export const TextContainer = styled.div`
  max-width: ${(props) => (props.isFullText ? '100%' : '35%')};
  @media (max-width: 1024px) {
    max-height: 420px;
    max-width: 100%;
  }
  overflow: auto;

  ::-webkit-scrollbar {
    width: 13px;
  }

  ::-webkit-scrollbar-thumb {
    background: #949092;
    border-radius: 20px;
    border: 4px solid #fff;
  }

  h6,
  p {
    color: #1f1b1e;
    margin: 0;
    font-size: 1.125rem;
    font-weight: 300;
    margin-bottom: 14px;
  }

  strong {
    font-weight: 500;
    color: ${theme.palette.primary.main};
  }

  ol {
    margin: 0;
    list-style-type: none; /* oculta as marcas de lista padrão */
    counter-reset: item; /* redefine o contador */
    margin: 14px 0;
    padding: 0;
  }

  ol li::before {
    color: ${theme.palette.primary.main};
  }

  li {
    margin-left: 1.5rem;
    color: #1f1b1e;
    font-size: 1.125rem;
    font-weight: 300;
  }

  .custom-list li:before {
    content: '(' counter(item) ')'; /* exibe os números entre parênteses */
    counter-increment: item; /* incrementa o contador */
    margin-right: 10px; /* adiciona espaço entre o número e o conteúdo */
    font-weight: 600;
  }

  p {
    margin-bottom: 14px;
  }
`;

export const ImageBigContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    max-width: 1123px;
  }
`;

export const ImageButtonBig = styled.button`
  align-self: end;
  background: transparent;
  color: ${theme.palette.primary.main};
  font-size: 1.125rem;
  border: 0;
  margin-top: 0.25rem;
  transition: filter 0.2s;
  font-weight: 700;

  &:hover {
    filter: brightness(0.85);
  }
`;

export const InvoiceAndButtonContainer = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;

export const DownloadButtonContainer = styled.div`
  button {
    font-size: 1.25rem;
    padding: 0.563rem 0;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background: #88aaab;
  margin: 1rem 0;
`;

/* eslint-disable no-plusplus */
const compareDocuments = (documento, document_ocr) => {
  const digits1 = documento.match(/\d/g);
  const digits2 = document_ocr.match(/[\dX]/g);

  for (let i = 0; i < digits1.length; i++) {
    if (digits1[i] !== digits2[i] && digits2[i] !== 'X') {
      return false;
    }
  }

  return true;
};
export default compareDocuments;

import { Grid } from '@mui/material';
import { Form } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import FlexRowContainer from '../../../componentsStyle/FlexRowContainer';
import { LocationContext } from '../../../contexts/LocationContext';
import Input from '../../../pages/Admin/ContractDetails/components/ContractBillSection/ContractBiilLocationCondition/Input';
import Output from '../../../pages/Admin/ContractDetails/components/ContractBillSection/ContractBiilLocationCondition/Output';
import adminStores from '../../../store/admin/contractDetails/index.store';
import GradientButton from '../../GradientButton';
import GenericForm from '../GenericForm';
import { FormPropTypes } from '../types';
import theme from '../../../theme';
import { InputFormSchema } from './schema';

const AnalitycsForm = () => {
  const { isEditing, setIsEditing, onSubmit } = useContext(LocationContext);
  const {
    output: { output },
    pricing: { pricing },
    producers: { producers },
    location: { location: selectedLocation },
  } = adminStores();
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [selectedProducer, setSelectedProducer] = useState(null);

  const [values, setValues] = useState({});
  const [selectValues, setSelectValues] = useState([]);

  const [readonly, setReadonly] = useState(true);

  const handleSelectChange = async (data) => {
    setSelectValues((prevState) => ({ ...prevState, ...data }));
  };

  const handleCancel = (resetForm, formValues) => {
    resetForm();
    handleSelectChange({ producer_id: selectedLocation.producer_id });
    setIsEditing((state) => ({
      ...state,
      analytics: false,
    }));
  };

  useEffect(() => {
    try {
      setSelectedProviderId(selectedLocation.provider_id);
    } catch {
      setSelectedProviderId(null);
    }
  }, [selectedLocation.provider_id]);

  useEffect(() => {
    try {
      const producer = producers.find(
        (producer) => producer.id === selectedLocation.producer_id
      );
      setSelectedProducer(producer);
    } catch {
      setSelectedProducer(null);
    }
  }, [selectedLocation.producer_id]);

  useEffect(() => setReadonly(!isEditing.analytics), [!isEditing.analytics]);

  useEffect(() => {
    const values = {
      ...selectedLocation,
      sale_price: pricing.sale_price,
      selectedProviderId,
      price_floor: selectedProducer?.price_floor,
      value_cents: selectedLocation.value_cents / 100,
      suggestedVolume: output.suggestedVolume,
      selectValues,
    };
    setValues(values);
  }, [selectedLocation, output, pricing, selectedProviderId, selectValues]);

  useEffect(() => {
    handleSelectChange({ producer_id: selectedLocation.producer_id });
  }, [selectedLocation.producer_id]);

  return (
    <GenericForm
      enableReinitialize
      onSubmit={(values, { setSubmitting }) =>
        onSubmit({ ...values, ...selectValues }, { setSubmitting })
      }
      values={values}
      schema={InputFormSchema}
    >
      {({
        isSubmitting,
        handleSubmit,
        resetForm,
        values: formValues,
        setValues,
        isValid,
      }) => {
        return (
          <Form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <Grid
              item
              xs={12}
            >
              <Input
                values={formValues}
                setValues={setValues}
                readonly={readonly}
                formValues={formValues}
                handleCancel={handleCancel}
                resetForm={resetForm}
                handleSelectChange={handleSelectChange}
                selectValues={selectValues}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Output values={formValues} />
            </Grid>
            <Grid
              justify='flex-start'
              spacing={3}
            >
              <FlexRowContainer>
                {!readonly && (
                  <Grid item>
                    <GradientButton
                      paddingg='10px 30px'
                      background='white'
                      color={theme.palette.primary.main}
                      border={`1px solid ${theme.palette.primary.main}`}
                      text='Cancelar'
                      handleClick={() => handleCancel(resetForm, formValues)}
                      disabled={isSubmitting}
                      outlined
                      rectangle
                    />
                  </Grid>
                )}
                {!readonly && (
                  <Grid
                    item
                    style={{ marginLeft: 20 }}
                  >
                    <GradientButton
                      paddingg='10px 30px'
                      text='Salvar'
                      handleClick={handleSubmit}
                      disabled={isSubmitting || !isValid}
                      isLoading={isSubmitting}
                      rectangle
                      isSubmitting={isSubmitting}
                    />
                  </Grid>
                )}
              </FlexRowContainer>
            </Grid>
            {!isEditing.analytics && (
              <Grid justify='flex-start'>
                <GradientButton
                  paddingg='10px 30px'
                  text='Alterar'
                  rectangle
                  handleClick={() =>
                    setIsEditing((state) => ({
                      ...state,
                      analytics: true,
                    }))
                  }
                />
              </Grid>
            )}
          </Form>
        );
      }}
    </GenericForm>
  );
};

AnalitycsForm.propTypes = FormPropTypes;

export default AnalitycsForm;

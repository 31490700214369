import { AUTH_STORAGE_KEY, decode, removeAuthData } from '../../store';

import { isBrowser } from '../../utils';
import connection from './connection';

const login = async (username, password, login = false) => {
  const request = {
    user: {
      username,
    },
  };

  if (password) {
    Object.assign(request.user, { password });
  }

  const { err, headers, data } = await connection.post(`/users/sign_in`, {
    ...request,
  });

  if (err) {
    throw err;
  }
  const cookies = {
    token: data.token,
    token2: data.token2,
  };

  if (login) Object.assign(cookies, { login: true });

  const auth = JSON.stringify(cookies);

  if (isBrowser()) {
    localStorage.setItem(AUTH_STORAGE_KEY, auth);
  }

  return cookies;
};

const refreshToken = async ({ token, token2, ...restOfData }, sign = true) => {
  try {
    const auth = JSON.stringify({ token, token2 });

    if (isBrowser()) {
      localStorage.setItem(AUTH_STORAGE_KEY, auth);
    }

    if (!token2) throw 'No token';

    const { role } = await decode(token2);

    if (sign) return role === 'set_password';

    return restOfData;
  } catch (err) {
    console.log(err);
    localStorage.removeItem('floraenergia:auth');
    return false;
  }
};

const requestPasswordReset = async (username) => {
  const { err, status, data } = await connection.post(`/users/password`, {
    user: {
      username,
    },
  });

  if (status === 201) refreshToken(data);
  else if (status === 202) removeAuthData();

  if (err) {
    throw err;
  }
};

const resetPassword = async (resetPasswordToken, password) => {
  const { err } = await connection.patch(`/users/password`, {
    user: {
      reset_password_token: resetPasswordToken,
      password,
    },
  });

  if (err) {
    throw err;
  }
};

const confirmEmail = async (token) => {
  const { err, data } = await connection.post('/users/validateEmailToken', {
    user: {
      token,
    },
  });

  if (err) {
    throw err;
  }

  return data;
};

const setPassword = async (password) => {
  const { err, data } = await connection.post(`/users/setPassword`, {
    user: {
      password,
    },
  });

  if (err) {
    throw err;
  }
  return data;
};

const finishRegister = async ({ token, ...propsRest }) => {
  const { err } = await connection.patch(`/users/sign_up`, {
    user: {
      ...propsRest,
      invitation_token: token,
    },
  });

  if (err) {
    throw err;
  }
};

const logout = async () => {
  const { err } = await connection.get(`/users/logout`);
  // if (err) {
  //   throw err;
  // }
  removeAuthData();
  emptyCache();
};

const validatePasswordReset = async (token) => {
  const { err } = await connection.put(`/users/password`, {
    user: {
      token,
    },
  });
  removeAuthData();

  if (err) {
    throw err;
  }
};

const confirmResendToken = async (payload) => {
  const { err, data } = await connection.post(
    `/users/confirmResetToken`,
    payload
  );

  if (err) {
    throw err;
  }
  return data;
};

const clearToken = () => {
  localStorage.removeItem('floraenergia:auth');
};

const AuthService = {
  login,
  logout,
  requestPasswordReset,
  resetPassword,
  finishRegister,
  setPassword,
  refreshToken,
  validatePasswordReset,
  confirmEmail,
  confirmResendToken,
  clearToken,
};

export default AuthService;

function emptyCache() {
  if ('caches' in window) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    // window.location.reload(true);
  }
}

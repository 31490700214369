export const colors = {
  primary: '#1c1b1f',
  secondary: '#5D8487',
  tertiary: '#ececec',
  text: '#AFAFAF',
  footer: '#384446',
  orange: '#f9Af40',
  lightGreen: 'rgba(179, 255, 167, 0.3)',
  lightBlue: 'rgba(121,172,173, 0.3)',
  white: '#ffffff',
  gray: '#C4C4C4',
  statusDanger: '#FF3838',
  statusAlert: '#F9AF40',
  disabled: '#f1f1f1',
  toggleActive: 'rgba(112, 167, 102, 0.5)',
  buttonGray: '#D1D0CD',
  textGray: '#7C7C7C',
};

export const statusColors = {
  success: colors.primary,
  danger: colors.statusDanger,
  alert: colors.statusAlert,
  default: colors.primary,
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'active':
      return 'success';
    case 'blocked':
      return 'danger';
    default:
      return 'alert';
  }
};

import styled, { css } from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  min-height: 100%;
  background: ${(props) => props.themeValue.page_user_background};
  padding-top: 3.75rem;

  @media (max-width: 991px) {
    margin-top: 1.5rem;
  }
`;

export const Content = styled.div`
  max-width: 428px;
  margin: 0 auto;
  padding: 1.5rem;

  @media (min-width: 992px) {
    max-width: 1128px;
    /* width: 552px; */
    /* display: flex; */
    /* flex-direction: column; */
  }
`;

export const TitleWithDropdownContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;

export const TitleWithDropdownContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  h1 {
    color: ${theme.palette.primary.main};
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
  }
  h1 strong {
    color: ${theme.palette.primary.main};
  }
  svg {
    color: ${theme.palette.primary.main};
  }
`;

export const Separator = styled.div`
  height: 1px;
  background: ${theme.palette.gray.main};
  margin: 1.5rem 0;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
  }
`;

export const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;

  p {
    margin: 0;
    /* margin-block-end: 8px; */
    color: #000000;
    font-size: 1.125rem;
    font-weight: 300;
  }

  p + p {
    margin-block-end: 0;
  }
`;

export const CardWithDropdownContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  h2 {
    margin: 0 0 0 0.25rem;
    font-size: 1.25rem;
    line-height: 1.438rem;
    font-weight: 700;
    color: ${theme.palette.primary.main};
  }

  svg {
    font-size: 2rem;
    color: ${theme.palette.primary.main};
  }
`;

export const CardWithEconomyAndSustainabilityContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
`;

export const EconomyAndSustainabilityContent = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;
  }
`;

export const TableContainer = styled.table`
  margin-top: 1.5rem;
  width: 100%;

  th {
    font-size: 1.125rem;
    font-weight: 500;
    color: #4d5f63;
  }

  td {
    font-size: 1.125rem;
    font-weight: 300;
    color: #1f1b1e;

    strong {
      font-weight: 500;
    }
  }

  tfoot td {
    font-weight: 500;
  }

  thead,
  tbody {
    border-bottom: 0.5px solid #88aaab;
  }

  th,
  td {
    padding-bottom: 0.5rem;
  }

  tbody {
    tr:nth-child(1) td {
      padding-top: 0.5rem;
    }
  }

  tfoot tr td {
    padding-top: 0.5rem;
  }

  td:nth-child(n + 2):nth-child(-n + 3),
  th {
    text-align: center;
  }

  td:last-child {
    text-align: right;
  }
`;

export const SearchButton = styled.a`
  display: inline-flex;
  padding: 0.25rem;
  border-radius: 8px;
  background: #f9af40;
  border: none;
  text-align: center;
  color: #fbfdff;
  transition: filter 0.2s !important;

  &:hover {
    filter: brightness(0.9);
    color: #fbfdff;
  }
`;

export const IconContainer = styled.span`
  background: #5e8487;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LabelContainer = styled.span`
  font-size: 1.25rem;
  color: #1f1b1e;

  strong {
    font-weight: 700;
  }

  sub {
    font-size: 0.75rem;
  }
`;

export const PendingNotificationButton = styled.button`
  width: 100%;
  border: 0;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 1.5rem;
  background: linear-gradient(
      0deg,
      rgba(179, 38, 30, 0.25),
      rgba(179, 38, 30, 0.25)
    ),
    #fbfdff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom: 1.5rem;

  h2 {
    font-weight: 700;
    font-size: 1.25rem;
    color: #b3261e;
    margin: 0;
  }
`;

const isPendency = css`
  h2,
  strong {
    color: #b3261e;
  }
`;

export const Title = styled.div`
  h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
  }

  strong {
    font-weight: 700;
  }

  ${(props) => props.isPendency && isPendency}
`;

export const CardContent = styled.div`
  /* margin-top: 24px; */
  display: flex;
  flex-direction: column;
  gap: 1rem;

  span {
    font-weight: 300;
    font-size: 1rem;
    color: #1f1b1e;

    strong {
      font-weight: 500;
    }
  }

  p {
    margin: 0;
    font-weight: 300;
    font-size: 1.125rem;
    color: #1f1b1e;
    /* margin-block-end: 0.5rem; */

    a {
      font-weight: 500;
      font-size: 1.125rem;
      color: ${theme.palette.primary.main};
      text-decoration: underline;
    }
  }

  & + div {
    border-top: 1px solid ${theme.palette.gray.main};
    padding-top: 1rem;
    margin-top: 1rem;
  }
`;

export const LineWithButton = styled.div`
  button {
    font-size: 1.25rem;
    padding: 0.75rem 0.375rem;
  }

  /* @media (min-width: 992px) { */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  align-items: center;
  /* } */
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const OverdueContainer = styled.div`
  @media (min-width: 992px) {
    display: flex;
    align-items: baseline;
    gap: 4px;
  }
`;

export const LineWithButtonSendInvoice = styled.div`
  div {
    /* margin-top: 1rem; */
    font-size: 1.25rem;
    /* padding: 0.75rem 1.375rem; */

    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 992px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;

export const SignOrToReplaceButtonContainer = styled.div`
  button {
    font-size: 1.25rem;
    padding: 0.75rem 1.375rem;
  }

  button + button {
    margin-top: 1rem;
  }
`;

export const OpenModalButton = styled.button`
  border: 0;
  background: transparent;
  font-weight: 500;
  font-size: 1.125rem;
  color: ${theme.palette.primary.main};
  text-decoration: underline;
  transition: filter 0.1s;

  &:hover {
    filter: brightness(0.9);
  }
`;

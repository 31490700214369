import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ContentCard from '../../../components/ContentCard';
import SearchableTable from '../../../components/Interface/SearchableTable';
import { Paragraph } from '../../../components/Interface/Text';
import PageView from '../../../containers/PageView';
import { StyledLink } from '../../../components/Interface';
import {
  PROVIDERS_TYPES,
  PROVIDERS_TYPES_LABEL,
} from '../../../helpers/providersTypes';
import { ADMIN_CONTRACT_STATUS_TYPES_PTBR } from '../../../helpers/contractStatusTypes';

//connections
import lostsService from '../../../services/administrator/ManagementBases/losts.service';

const COLUMNS = [
  { name: 'Contrato' },
  { name: 'Status' },
  { name: 'Tag 1' },
  { name: 'Tag 2' },
  { name: 'Descrição' },
  { name: 'Distribuidora' },
];

function ProducerReport() {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState({
    loading: true,
    hasError: false,
    losts: [],
  });

  const getRows = (generators) => {
    return generators.map(
      ({ contract_id, status, tag_1, tag_2, notes, provider }) => ({
        values: [
          {
            value: (
              <StyledLink
                tag='true'
                to={`/contratos/${contract_id}`}
              >{`${contract_id}`}</StyledLink>
            ),
          },
          {
            value: (
              <Paragraph>{ADMIN_CONTRACT_STATUS_TYPES_PTBR[status]}</Paragraph>
            ),
            align: 'center',
          },
          {
            value: <Paragraph>{tag_1 || 'Não Assinou'}</Paragraph>,
            align: 'center',
          },
          {
            value: <Paragraph>{tag_2 || 'Sem Conta'}</Paragraph>,
            align: 'center',
          },
          {
            value: <Paragraph>{notes}</Paragraph>,
            align: 'center',
          },
          {
            value: (
              <Paragraph>
                {PROVIDERS_TYPES_LABEL[PROVIDERS_TYPES[provider]] ||
                  provider?.toUpperCase() ||
                  'On Hold'}
              </Paragraph>
            ),
          },
        ],
      })
    );
  };

  useEffect(() => {
    const fetchLosts = async () => {
      try {
        const resources = await lostsService.getAll();

        setPageState((state) => ({
          ...state,
          losts: resources,
        }));
        setPageState((state) => ({
          ...state,
          loading: false,
          hasError: false,
        }));
      } catch (error) {
        toast.error('Houve um erro desconhecido, tente novamente mais tarde');
        setPageState((state) => ({
          ...state,
          loading: false,
          hasError: true,
        }));
      }
    };

    fetchLosts();
  }, []);

  const fetchAll = async () => {
    const contractss = await lostsService.getAll(0, 100000);
    return getRows(contractss);
  };

  const { loading, hasError, losts } = pageState;

  return (
    <PageView
      title='Perdidos'
      loading={loading}
      hasError={hasError}
      navigate={navigate}
    >
      {losts && (
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <ContentCard>
              <SearchableTable
                columns={COLUMNS}
                fetchAll={fetchAll}
                rowsValues={getRows(losts)}
                noItemsText='Nenhum Perdido'
              />
            </ContentCard>
          </Grid>
        </Grid>
      )}
    </PageView>
  );
}

export default ProducerReport;

const loadScript = (id, src) =>
  new Promise((resolve, reject) => {
    try {
      const existingScript = document.getElementById(id);
      if (!existingScript) {
        const script = document.createElement('script');
        script.src = src;
        script.id = id;
        document.head.appendChild(script);
        script.onload = () => resolve();
      } else {
        resolve();
      }
    } catch (error) {
      reject(error);
    }
  });
export default loadScript;

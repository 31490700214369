import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { Paragraph } from '../../Interface/Text';
import { toast } from 'react-toastify';
import CompaniesService from '../../../services/general/companies.service';
import { useParams } from 'react-router-dom';
import AttachmentsService from '../../../services/attachments/attachments.service';
import { TOAST_MESSAGES } from '../../../helpers/toastMessages';
import GradientButton from '../../GradientButton';
import { isCompanyFlora } from '../../../store';

function DocumentsForm({ data }) {
  const [isEditing, setIsEditing] = useState(false);
  const downloadStatuteRef = useRef(null);
  const privacyPolicyRef = useRef(null);
  const signerModelRef = useRef(null);
  const { companyId } = useParams();

  const handleOpenStatute = () => {
    if (downloadStatuteRef?.current) {
      downloadStatuteRef.current.click();
    }
  };

  const handleOpenSignModel = () => {
    if (signerModelRef?.current) {
      signerModelRef.current.click();
    }
  };

  const handleFileChange = async (e, name) => {
    if (!isCompanyFlora()) {
      toast.error('Você não tem permissão para realizar esta operação.');
      return;
    }
    const file = e?.target?.files?.[0];
    if (!file) return;
    if (
      (file.type !== 'application/pdf' &&
        file.type !==
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document') ||
      !file.name.endsWith('.docx')
    ) {
      toast.error(
        'Formato de arquivo invalido. Deve-se enviar somente documentos em formato .docx'
      );
      setIsEditing(false);
      return;
    }

    try {
      let attachmentKey = await AttachmentsService.uploadAttachment(file); // need to refactor
      let req = await CompaniesService.updateOne(companyId, {
        [name]: attachmentKey,
        id: companyId,
      });
      if (req.success) {
        toast.success(TOAST_MESSAGES.SUCCESS);
      } else {
        setIsEditing(false);
        throw Error();
      }
      setIsEditing(false);
    } catch (error) {
      console.log(error);
      toast.error(TOAST_MESSAGES.ERROR);
    }
  };

  const handleOpenPrivacyPolicy = () => {
    if (privacyPolicyRef?.current) {
      privacyPolicyRef.current.click();
    }
  };

  return (
    <MainContainer>
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <FormDiv>
          {data && data.social_statute_link && !isEditing ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Paragraph>Estatuto Social:</Paragraph>
              <Button
                variant='contained'
                style={{
                  marginTop: '1rem',
                  backgroundColor: 'green',
                  color: 'white',
                }}
                onClick={() => handleOpenStatute()}
              >
                Ver Estatuto Atual
              </Button>
              <a
                ref={downloadStatuteRef}
                href={data?.social_statute_link}
                download
                hidden
              />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <input
                accept='application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                className=''
                id='contained-button-file'
                multiple
                type='file'
                hidden
                onChange={(e) => {
                  handleFileChange(e, 'social_statute_link');
                }}
              />
              <Paragraph>Estatuto Social:</Paragraph>
              <label
                htmlFor='contained-button-file'
                style={{ marginTop: '1rem' }}
              >
                <Button
                  variant='contained'
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                  }}
                  component='span'
                >
                  Inserir Estatuto
                </Button>
              </label>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Paragraph>Termo de Adesão</Paragraph>
            <Button
              variant='contained'
              style={{
                backgroundColor: 'green',
                color: 'white',
                marginTop: '1rem',
              }}
              onClick={() => handleOpenSignModel()}
            >
              Ver Termo
            </Button>
            <a
              href={
                data?.signer_term_link ||
                'https://attachments-flora.s3.sa-east-1.amazonaws.com/docs/e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855/file+(1).docx'
              }
              download
              hidden
              ref={signerModelRef}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {data && data.privacy_policy_link && !isEditing ? (
              <>
                <Paragraph>Política de Privacidade</Paragraph>
                <Button
                  variant='contained'
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    marginTop: '1rem',
                  }}
                  onClick={() => handleOpenPrivacyPolicy()}
                >
                  Ver Política
                </Button>
                <a
                  href={data.privacy_policy_link}
                  download
                  hidden
                  ref={privacyPolicyRef}
                />
              </>
            ) : (
              <>
                <input
                  accept='application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  className=''
                  id='contained-button-file2'
                  multiple
                  type='file'
                  hidden
                  onChange={(e) => {
                    handleFileChange(e, 'privacy_policy_link');
                  }}
                />
                <Paragraph>Política de Privacidade:</Paragraph>
                <label
                  htmlFor='contained-button-file2'
                  style={{ marginTop: '1rem' }}
                >
                  <Button
                    variant='contained'
                    style={{
                      backgroundColor: 'green',
                      color: 'white',
                    }}
                    component='span'
                  >
                    Inserir Política
                  </Button>
                </label>
              </>
            )}
          </div>
        </FormDiv>
      </form>
      {isCompanyFlora() && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '4rem',
          }}
        >
          <GradientButton
            text={isEditing ? 'Cancelar' : 'Alterar'}
            handleClick={() => setIsEditing(!isEditing)}
          />
        </div>
      )}
    </MainContainer>
  );
}

export default DocumentsForm;

const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

const FormDiv = styled.div`
  display: flex;
  justify-content: space-around;
`;

import { maskCpfOrCnpj } from '../../../../helpers/form/masks';

const prepareOcrPayload = (data, stagePayload) => {
  const finalData = {};
  const { user, location } = data;
  const { name: { value: initialName } = {} } = stagePayload || {};

  const dataToHandle = {
    holder: location?.holder || user.name || initialName || '',
    document: location.document ? maskCpfOrCnpj(location.document) : '',
  };

  for (const key in dataToHandle) {
    const value = dataToHandle[key];
    const hasLength = dataToHandle[key].length;
    const newPayload = hasLength
      ? { status: 'accepted', touched: true }
      : { status: 'awaiting', touched: false };
    finalData[key] = {
      ...stagePayload[key],
      ...newPayload,
      value,
      initialValue: value,
    };
  }
  return finalData;
};

export const stage0Exceptions = (
  data,
  isNewPropertyOrHolder,
  functions,
  stagePayload
) => {
  const { setDataForProposal } = functions;
  const { user, location, providerData } = data;
  const { name, estimated_electric_bill, options } = stagePayload;

  if (location) {
    const {
      totalInvoices,
      invoiceProvider,
      invoiceFlora,
      monthlyEconomy,
      yearlyEconomy,
      providerName,
    } = location;

    const proposal = {
      totalInvoices,
      invoiceProvider,
      invoiceFlora,
      monthlyEconomy,
      yearlyEconomy,
      providerName,
    };

    setDataForProposal((oldState) => ({
      ...oldState,
      proposal,
      providerData,
    }));
  }

  const {
    attachment: locationAttachment = '',
    attachment_valid,
    ocr,
  } = location || {};

  const validEstimated = (estimatedElectricBill) => {
    if (estimatedElectricBill >= 40) return 'accepted';
    else return 'refused';
  };

  const ocrPayload = prepareOcrPayload(data, stagePayload);

  const newPayload = {
    name: { ...name, value: user.name },
    estimated_electric_bill: {
      ...estimated_electric_bill,
      value: attachment_valid
        ? parseFloat(location.estimated_electric_bill.toString().concat('00'))
        : estimated_electric_bill.value,
      converted_value: location && location.estimated_electric_bill,
      status:
        location &&
        validEstimated(parseFloat(location.estimated_electric_bill)),
      touched: true,
    },
    ...ocrPayload,
    document_ocr: location.document_ocr,
    attachment: locationAttachment,
    profile: location?.profile,
    provider: location?.provider,
    contract_id: isNewPropertyOrHolder ? location?.contract_id : '',
    options: { ...options, ocrSuccess: ocr === 1 },
  };

  return newPayload;
};

import PropTypes from 'prop-types';
import React from 'react';
import { MdClose } from 'react-icons/md';

import { NewModal } from '../../NewModal';

import {
  URLS_CREATE_ACCOUNT,
  URLS_RECOVER_PASSWORD,
} from '../../../helpers/providersURL';

import {
  AnchorButton,
  ButtonsContainer,
  CloseButton,
  Container,
  Content,
  Header,
} from './styles';

export const HelpCenterModal = ({ isOpen, onRequestClose, data }) => {
  const { provider, provider_name } = data || {};
  return (
    <NewModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Container>
        <Header>
          <h2>Central de ajuda</h2>
          <CloseButton onClick={onRequestClose}>
            <MdClose
              size='1.5rem'
              color='#1F1B1E'
            />
          </CloseButton>
        </Header>
        <Content>
          <p>
            Caso nunca tenha criado seu acesso na plataforma da{' '}
            <strong>{provider_name}</strong>, tenha esquecido ou perdido seu
            acesso, preparamos um breve tutorial para te ajudar criar ou
            localizar esses dados.
          </p>
          <p>
            Acesse um de nossos tutoriais abaixo que melhor enquadra em sua
            necessidade:
          </p>

          <ButtonsContainer>
            <AnchorButton
              href={URLS_CREATE_ACCOUNT[provider]}
              target='_blank'
              disabled={!URLS_CREATE_ACCOUNT[provider]}
            >
              Cadastrar conta
            </AnchorButton>
            <AnchorButton
              href={URLS_RECOVER_PASSWORD[provider]}
              target='_blank'
              disabled={!URLS_RECOVER_PASSWORD[provider]}
            >
              Recuperar senha
            </AnchorButton>
          </ButtonsContainer>
        </Content>
      </Container>
    </NewModal>
  );
};

HelpCenterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  provider: PropTypes.string,
};

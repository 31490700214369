import connection from './connection';

const mountFiltersQuery = (filters) => {
  const mappedFilters = {};

  if (filters) {
    const { userId } = filters;

    if (userId) {
      mappedFilters.users_id_eq = userId;
    }

    return `?q=${JSON.stringify(mappedFilters)}`;
  }

  return '';
};

const getAll = async () => {
  const { err, data } = await connection.get(`/paymentMethods`);

  if (err) {
    throw err;
  }

  return data;
};

const get = async (id) => {
  const { err, data } = await connection.get(`/paymentMethods/${id}`);

  if (err) {
    throw err;
  }

  return data;
};

const create = async (payload) => {
  const { err, data } = await connection.post(`/paymentMethods`, payload);

  if (err) {
    throw err;
  }

  return data;
};

const update = async (id, payload) => {
  const { err, data } = await connection.patch(`/paymentMethods/${id}`, {
    contract: payload,
  });

  if (err) {
    throw err;
  }

  return data;
};

const updateAddress = async (contractId, payload) => {
  const { err, data } = await connection.patch(
    `/paymentMethods/address/${contractId}`,
    payload
  );

  if (err) {
    throw err;
  }

  return data;
};

const PaymentMethodsService = {
  getAll,
  get,
  create,
  update,
  updateAddress,
};

export default PaymentMethodsService;

import { useLayoutEffect, useState } from 'react';

export function useRefSize(sizeValue) {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(sizeValue?.current?.clientWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return { size, setSize };
}

import PropTypes from 'prop-types';

export const FormPropTypes = {
  onSubmit: PropTypes.func,
  readonly: PropTypes.bool,
  values: PropTypes.oneOfType([PropTypes.shape({})]),
  isEditing: PropTypes.bool,
  onCancel: PropTypes.func,
  buttonText: PropTypes.string,
  expanded: PropTypes.bool
};

export const FormDefaultProps = {
  onSubmit: () => {},
  readonly: false,
  values: {},
  isEditing: false,
  onCancel: () => {},
  buttonText: undefined,
  expanded: false
};

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { FormDefaultProps, FormPropTypes } from '../types';
import AttachmentsService from '../../../services/attachments/attachments.service';
import { hasAttachment } from '../../../utils/attachment';
import ContractsAdminService from '../../../services/administrator/contractDetails.service';
import adminStores from '../../../store/admin/contractDetails/index.store';
import { updateAdminStore } from '../../../store/admin/contractDetails/helpers';
import transformBytes from '../../../helpers/transformBytes';
import theme from '../../../theme';

const NewLocationForm = ({ location }) => {
  const {
    contract: { contract },
  } = adminStores();

  const maxSize = parseInt(import.meta.env.REACT_APP_MAX_UPLOAD_SIZE, 10);
  const fileInputRef = useRef();
  const [file, setFile] = useState({});
  const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'application/pdf'];

  const saveAttachment = async () => {
    try {
      const attachmentKey = await AttachmentsService.uploadAttachment(
        file,
        contract.id
      );

      const dataUpdate = await ContractsAdminService.update({
        locations: { id: location.id, attachment: attachmentKey },
      });
      updateAdminStore(dataUpdate);

      toast.success('Conta de luz adicionada com sucesso');

      setFile({});
    } catch (err) {
      toast.error('Ocorreu um erro, tente novamente');
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    let errorMessage = '';

    if (!SUPPORTED_FORMATS.includes(file.type))
      errorMessage =
        'Esse arquivo não é um formato válido. Tente uma imagem PNG ou JPG, ou um PDF.';
    else if (file.size >= maxSize) {
      errorMessage = `O tamanho do arquivo anexado é superior ao suportado (${transformBytes(
        maxSize
      )}).`;
    }

    if (errorMessage) {
      toast.error(errorMessage);
      // eslint-disable-next-line react/prop-types
      fileInputRef.current.value = '';
      return;
    }

    setFile(file);
  };

  useEffect(() => {
    if (file?.name) saveAttachment();
  }, [file]);

  return (
    <UploadButtonContainer>
      <input
        id='file_upload'
        type='file'
        onChange={(event) => handleFileUpload(event)}
        accept={SUPPORTED_FORMATS}
        ref={fileInputRef}
      />
      <label htmlFor='file_upload'>
        {hasAttachment(location) ? 'Trocar conta' : 'Inserir Conta'}
      </label>
    </UploadButtonContainer>
  );
};

NewLocationForm.propTypes = FormPropTypes;
NewLocationForm.propTypes = FormDefaultProps;

const UploadButtonContainer = styled.div`
  input[type='file'] {
    display: none;
  }

  label {
    margin: 0;
    width: 100%;
    padding: 8px 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    /* margin-top: 1rem; */

    cursor: pointer;
    border-radius: 8px;
    border: 1px solid ${theme.palette.primary.main};
    background: none;
    color: ${theme.palette.primary.main};
    font-size: 1.125rem;
    font-weight: 500;
    transition: all 0.3s;

    &:hover {
      background: #ffefd8;
      color: #d89025;
      border-color: #d89025;
    }

    &:active {
      background: #fdd496;
      color: #b57008;
      border-color: #b57008;
    }

    &:disabled {
      width: 25.75rem;
      height: 3rem;
      color: #e4e4e4;
      background: none;
      border: 1px solid #e4e4e4;
      border-radius: 8px;
      font-size: 1.125rem;
      font-weight: 500;
      cursor: not-allowed;
    }

    ${(props) => props.className === 'primary' && isPrimary}
    ${(props) =>
      props.className === 'stageOne' &&
      css`
        padding: 0.625rem 0;
      `}
  }
`;

export default NewLocationForm;

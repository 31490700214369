import React, { useState } from 'react';

import styled from 'styled-components';
import SignerForm from '../../../../../components/SignerForm';
import adminStores from '../../../../../store/admin/contractDetails/index.store';
import theme from '../../../../../theme';

const ContractCompanySection = () => {
  const {
    contract: { contract },
    signer: { signer },
  } = adminStores();

  const [loading] = useState(false);

  const [isVisible] = useState(!!signer?.email && contract?.status !== 'draft');

  return (
    <>
      {!loading && isVisible && (
        <MainCard loading={loading}>
          <TitleContainer>
            <h5>Dados do signatário</h5>
          </TitleContainer>
          <SignerForm
            values={signer}
            isEditing={false}
          />
        </MainCard>
      )}
    </>
  );
};

export default ContractCompanySection;

const MainCard = styled.div`
  padding: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
`;

const TitleContainer = styled.div`
  margin: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  > h5 {
    font-size: 1.5rem;
    color: ${theme.palette.primary.main};
    font-weight: bold;
  }
`;
